import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import emptyStateMessages from '../../../core-utils/messages/emptyState';
import theme from '../../../core-utils/theme';
import Button from '../../atoms/Button';
import IconFromSvg from '../../atoms/IconFromSvg';

export interface IEmptyStateWithIconDividerTextAndButtonProps {
  icon: string;
  text: string;
  width?: string;
  buttonText: string;
  onButtonClick?: () => void;
  handleDrop?: (event: any) => void;
}

const EmptyStateWithIconDividerTextAndButton = ({
  icon,
  text,
  width,
  buttonText,
  onButtonClick,
  handleDrop,
}: IEmptyStateWithIconDividerTextAndButtonProps) => {
  return (
    <Grid
      container
      sx={{
        border: '1px dashed ' + theme.palette.text.disabled,
        borderRadius: theme.spacing(3),
      }}
      width={width ?? 'inherit'}
      alignItems="center"
      justifyContent="center"
      direction="column"
      onDragOver={(event: any) => {
        event.preventDefault();
      }}
      onDrop={handleDrop}
      className="w-full p-20 shadow-md mx-4"
    >
      <IconFromSvg path={icon} alt="icon" />

      <Divider
        sx={{ paddingTop: theme.spacing(5) }}
        orientation="horizontal"
        flexItem
      />

      <Typography
        variant="body1"
        sx={{ color: theme.palette.text.primary, paddingTop: theme.spacing(4) }}
      >
        {text}
      </Typography>

      <Typography
        variant="body1"
        sx={{
          color: theme.palette.text.secondary,
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(2),
        }}
      >
        {emptyStateMessages.OR}
      </Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={onButtonClick}
        sx={{ marginBottom: theme.spacing(2) }}
      >
        {buttonText}
      </Button>
    </Grid>
  );
};

export default EmptyStateWithIconDividerTextAndButton;
