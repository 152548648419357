import {
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import policyBuilderMessages from '../../../core-utils/messages/policyBuilder';
import theme, { customStyles, EXTRA_COLORS, modalStyle  } from '../../../core-utils/theme';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../atoms/Button';
import TextField from '../../atoms/TextField';
import CloseSvg from '../../../../public/assets/close-inactive.svg';
import Dropdown from '../../molecules/Dropdown';
import IconFromSvg from '../../atoms/IconFromSvg';
import { isEmptyValidation } from '../../../core-utils/Helper/helper';
import PolicyService, {
  IAddListForPolicyRequest,
} from '../../../api-service/policy-service/PolicyService';

interface IAddListProps {
  showModal: boolean;
  handleClose: () => void;
  dropDownOptions: any;
}

const AddListModal = ({
  showModal,
  handleClose,
  dropDownOptions,
}: IAddListProps) => {
  const {
    HEADING,
    SUB_HEADING,
    TEXT_FIELD_LABELS: { LIST_NAME, LIST_VALUES, CONDITION_TYPE },
    PLACEHOLDER_LABELS: {
      LIST_NAME_LABEL,
      LIST_VALUES_LABEL,
      CONDITION_TYPE_LABEL,
    },
    REQUIRED,
    ADD_LIST,
    ERROR_MESSAGES: { LIST_VALUE_ALREADY_EXISTS },
  } = policyBuilderMessages.CREATE_LIST;
  const { SAVE, CANCEL } = policyBuilderMessages.BUTTON_LABELS;

  const emptyListParams = {
    [LIST_NAME]: '',
    [CONDITION_TYPE]: null,
    [LIST_VALUES]: [''],
  };

  const [isSaveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [listDetails, setListDetails] = useState<any>(emptyListParams);
  const [errorMessages, setErrorMessages] = useState<any>({
    [LIST_VALUES]: [],
  });

  const handleTextFieldChange = (event: any) => {
    setListDetails((prevState: any) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleConditionTypeSelect = (event: any, value: string) => {
    setListDetails((prevState: any) => ({
      ...prevState,
      [CONDITION_TYPE]: value,
    }));
    setListDetails((prevState: any) => ({
      ...prevState,
      [LIST_VALUES]: [''],
    }));
    setErrorMessages((prevState: any) => ({
      ...prevState,
      [LIST_VALUES]: [],
    }));
  };

  const handleCancel = () => {
    setListDetails(emptyListParams);
    setErrorMessages({ [LIST_VALUES]: [] });
    handleClose();
  };

  const handleAddListValue = () => {
    setListDetails((prevState: any) => ({
      ...prevState,
      [LIST_VALUES]: [...prevState[LIST_VALUES], ''],
    }));
    setErrorMessages((prevState: any) => ({
      ...prevState,
      [LIST_VALUES]: [...prevState[LIST_VALUES], ''],
    }));
  };

  const handleListValueChange = (event: any, index: number) => {
    const enteredValue = event.target.value;
    const requiredListValues = listDetails[LIST_VALUES];
    requiredListValues[index] = enteredValue;
    setListDetails((prevState: any) => ({
      ...prevState,
      [LIST_VALUES]: requiredListValues,
    }));
  };

  const handleRemoveListValue = (index: number) => {
    const tempListValues = listDetails[LIST_VALUES];
    tempListValues.splice(index, 1);
    setListDetails((prevState: any) => ({
      ...prevState,
      [LIST_VALUES]: tempListValues,
    }));
  };

  const validateItemAlreadyExists = (item: string) => {
    if (item === '') {
      return false;
    }
    const filteredValues = listDetails[LIST_VALUES].filter(
      (value: string) => value === item,
    );
    return filteredValues.length > 1 ? true : false;
  };

  const validateListValues = () => {
    return listDetails[LIST_VALUES].some((item: string) => {
      return validateItemAlreadyExists(item);
    });
  };

  const isEmptyValidationForListItems = () => {
    return listDetails[LIST_VALUES].some((item: string) => {
      return isEmptyValidation(item.trim());
    });
  };

  const handleListValueBlur = (index: number) => {
    const currentValue = listDetails[LIST_VALUES][index];
    const tempErrorMessages = errorMessages[LIST_VALUES];
    if (validateItemAlreadyExists(currentValue)) {
      tempErrorMessages[index] = LIST_VALUE_ALREADY_EXISTS;
      setSaveDisabled(true);
    } else {
      tempErrorMessages[index] = '';
      setSaveDisabled(false);
    }
    setErrorMessages((prevState: any) => ({
      ...prevState,
      [LIST_VALUES]: tempErrorMessages,
    }));
    handleValidation();
  };

  const handleValidation = () => {
    const isValid = Object.keys(listDetails).every((key) => {
      if (key === LIST_NAME && isEmptyValidation(listDetails[key].trim())) {
        return false;
      }
      if (key === CONDITION_TYPE && isEmptyValidation(listDetails[key])) {
        return false;
      }
      if (key === LIST_VALUES && listDetails[LIST_VALUES].length === 0) {
        return false;
      }
      if (key === LIST_VALUES && isEmptyValidationForListItems()) {
        return false;
      }
      if (key === LIST_VALUES && validateListValues()) {
        return false;
      }
      return true;
    });

    setSaveDisabled(!isValid);
    return isValid;
  };

  const handleSaveList = async () => {
    if (handleValidation()) {
      const conditionId = listDetails[CONDITION_TYPE].id;
      const listRequest: IAddListForPolicyRequest = {
        name: listDetails[LIST_NAME],
        values: listDetails[LIST_VALUES],
      };
      try {
        await PolicyService.addListForPolicy(conditionId, listRequest);
        handleCancel();
      } catch (error) {
        console.warn(error);
      }
    }
  };

  const shouldSaveBeDisabled = () => {
    if (handleValidation()) {
      setSaveDisabled(false);
    }
  };

  useEffect(() => {
    shouldSaveBeDisabled();
  }, [listDetails]);

  return (
    <Modal open={showModal} onClose={handleClose}>
      <Box
        display="flex"
        flexDirection="row"
        sx={{
          ...modalStyle,
          height: 450,
          width: 645,
          borderRadius: '5px',
        }}
      >
        <Box width="645px" height="inherit">
          <Grid
            container
            rowGap={2}
            alignItems="flex-start"
            sx={{
              height: 'inherit',
              overflowY: 'hidden',
            }}
          >
            <Grid
              justifyContent="space-between"
              item
              container
              sx={{
                paddingTop: 6,
                paddingBottom: 2,
                position: 'sticky',
                background: 'white',
                left: 0,
                top: 0,
                zIndex: 100,
                boxShadow:
                  listDetails[LIST_VALUES].length > 1
                    ? '0px 1px 3px rgba(0, 0, 0, 0.12)'
                    : 'unset',
              }}
            >
              <Stack direction="column">
                <Typography variant="h3">{HEADING}</Typography>
                <Typography
                  sx={{ color: theme.palette.text.disabled }}
                  variant="body2"
                >
                  {SUB_HEADING}
                </Typography>
              </Stack>
              <IconButton onClick={handleCancel}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={theme.spacing(5)}
              sx={{
                maxHeight: '295px',
                overflowY: 'auto',
                ...customStyles.scrollBar,
                pt: 2,
              }}
            >
              <Grid item>
                <TextField
                  name={LIST_NAME}
                  label={LIST_NAME}
                  value={listDetails[LIST_NAME]}
                  placeholder={LIST_NAME_LABEL}
                  inputProps={{ 'data-testid': LIST_NAME }}
                  required
                  autoComplete="off"
                  handleChange={handleTextFieldChange}
                  onBlur={handleValidation}
                />
              </Grid>

              <Grid item>
                <Dropdown
                  placeholder={CONDITION_TYPE_LABEL}
                  disableClearable={false}
                  options={dropDownOptions}
                  inputProps={{ 'data-testid': CONDITION_TYPE }}
                  label={CONDITION_TYPE}
                  value={listDetails[CONDITION_TYPE]}
                  onBlur={handleValidation}
                  handleDropdownChange={handleConditionTypeSelect}
                />
              </Grid>

              <Grid item>
                <TextField
                  name={LIST_VALUES}
                  label={LIST_VALUES}
                  value={listDetails[LIST_VALUES][0]}
                  placeholder={LIST_VALUES_LABEL}
                  inputProps={{ 'data-testid': LIST_VALUES }}
                  required
                  autoComplete="off"
                  handleChange={(event) => handleListValueChange(event, 0)}
                  onBlur={handleValidation}
                />
              </Grid>
              {listDetails[LIST_VALUES].length !== 0 && (
                <Grid item container spacing={theme.spacing(5)}>
                  {listDetails[LIST_VALUES].slice(1).map(
                    (item: any, index: number) => {
                      return (
                        <Grid item key={index}>
                          <TextField
                            name={LIST_VALUES}
                            value={item}
                            placeholder={LIST_VALUES_LABEL}
                            inputProps={{ 'data-testid': LIST_VALUES }}
                            required
                            autoComplete="off"
                            handleChange={(event) => {
                              handleListValueChange(event, index + 1);
                            }}
                            error={
                              errorMessages[LIST_VALUES][index + 1] &&
                              errorMessages[LIST_VALUES][index + 1] !== ''
                            }
                            helperText={errorMessages[LIST_VALUES][index + 1]}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() =>
                                    handleRemoveListValue(index + 1)
                                  }
                                  edge="end"
                                  data-testid="close"
                                >
                                  <IconFromSvg path={CloseSvg} alt="remove" />
                                </IconButton>
                              </InputAdornment>
                            }
                            onBlur={() => handleListValueBlur(index + 1)}
                          />
                        </Grid>
                      );
                    },
                  )}
                </Grid>
              )}
              <Grid item>
                <Button
                  color="primary"
                  variant="text"
                  onClick={handleAddListValue}
                >
                  {ADD_LIST}
                </Button>
              </Grid>
            </Grid>

            <Grid
              alignItems="center"
              container
              height="75px"
              item
              justifyContent="space-between"
              sx={{
                position: 'sticky',
                background: 'white',
                bottom: 0,
                zIndex: 100,
                boxShadow:
                  listDetails[LIST_VALUES].length > 1
                    ? '0px -2px 4px #E0E6F1'
                    : 'unset',
              }}
            >
              <Grid item>
                <Typography
                  sx={{ color: theme.palette.text.disabled }}
                  variant="body2"
                  component="span"
                >
                  {REQUIRED}
                </Typography>
                <Typography component="span" variant="body2" color="error">
                  *
                </Typography>
              </Grid>

              <Grid item>
                <Button
                  sx={{
                    width: theme.spacing(28),
                    height: theme.spacing(10),
                    marginRight: 3,
                    '&:hover': { backgroundColor: EXTRA_COLORS.lightBlue },
                  }}
                  color="primary"
                  variant="text"
                  onClick={handleCancel}
                >
                  {CANCEL}
                </Button>
                <Button
                  sx={{
                    width: theme.spacing(28),
                    height: theme.spacing(10),
                    textAlign: 'center',
                  }}
                  color="primary"
                  variant="contained"
                  disabled={isSaveDisabled}
                  onClick={handleSaveList}
                >
                  {SAVE}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddListModal;
