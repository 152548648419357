import React from 'react';
import { Stack, Box, Typography, TypographyVariant } from '@mui/material';
import { AvatarGroups } from '../AvatarGroup';

const defaultContainer = { border: 0 };

const roundedContainer = {
  bgColor: 'white',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',
  borderRadius: 8,
  padding: 2,
};

const MAX_AVATARS = 3;

type AvatarNameCardProps = {
  name: string;
  avatarGroupData: any;
  variant?: string;
  typographyVariant?: TypographyVariant;
};
const AvatarNameCard = ({
  name,
  avatarGroupData,
  variant = 'default',
  typographyVariant = 'subtitle1',
}: AvatarNameCardProps) => {
  let containerClass: any = defaultContainer;
  if (variant === 'rounded') {
    containerClass = roundedContainer;
  }
  return (
    <Box sx={containerClass}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        spacing={variant === 'default' ? 2 : 4}
      >
        <AvatarGroups
          maxAvatars={MAX_AVATARS}
          componentList={avatarGroupData}
        />
        <Typography color="textSecondary" variant={typographyVariant}>
          {name}
        </Typography>
      </Stack>
    </Box>
  );
};

export default AvatarNameCard;
