import WarningIcon from '@assets/warningIcon.svg';
import {
    Dialog,
    DialogProps,
    Grid,
    IconButton,
    Typography,
    useTheme,
} from '@mui/material';
import React from 'react';
import CloseIcon from '../../../../public/assets/close-inactive.svg';
import messages from '../../../core-utils/messages';
import Button from '../../atoms/Button';
import IconFromSvg from '../../atoms/IconFromSvg';

interface IAppAlreadyExistsPopUp extends DialogProps {
  mainText: string;
  onButtonClick: () => void;
}

const AppAlreadyExistsPopUp = (props: IAppAlreadyExistsPopUp) => {
  const theme = useTheme();
  const { mainText, open, onButtonClick } = props;
  return (
    <Dialog open={open}>
      <Grid
        container
        sx={{
          width: theme.spacing(113),
          height: theme.spacing(56.25),
          background: theme.palette.background.paper,
          boxSizing: 'border-box',
          boxShadow: '0px 4px 20px rgba(40, 40, 40, 0.08)',
          borderRadius: theme.spacing(1.25),
        }}
        direction="column"
        alignItems="center"
      >
        <Grid
          sx={{ marginTop: theme.spacing(8), marginBottom: theme.spacing(2.5) }}
          item
        >
          <IconFromSvg
            width={37.82}
            height={31.25}
            path={WarningIcon}
            alt="warning icon"
          />
          <IconButton
            sx={{
              position: 'absolute',
              top: theme.spacing(1.5),
              right: theme.spacing(1.5),
            }}
            onClick={onButtonClick}
          >
            <IconFromSvg path={CloseIcon} alt="close icon" />
          </IconButton>
        </Grid>

        <Grid
          sx={{
            width: theme.spacing(95),
            height: theme.spacing(10.5),
            color: theme.palette.text.disabled,
            marginTop: theme.spacing(2.7),
            marginBottom: theme.spacing(8),
          }}
          item
        >
          <Typography
            sx={{
              textAlign: 'center',
              paddingRight: 7,
              paddingLeft: 7,
            }}
            variant="body1"
          >
            {mainText}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={onButtonClick}
            sx={{
              width: theme.spacing(34.25),
              height: theme.spacing(10),
              borderRadius: theme.spacing(1.25),
            }}
            variant="contained"
          >
            {messages.OKAY_MESSAGE}
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};
export default AppAlreadyExistsPopUp;
