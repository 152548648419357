import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import messages from '../../../core-utils/messages';
import PasswordTextField from '../../molecules/PasswordTextField';
import TooltipForValidation from '../../molecules/TooltipForValidation';
import { ITooltipValidationParams } from '../../../core-utils/constants';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';

interface IEndUserPasswordFormProps {
  handlePasswordTextFieldChange: (name: string, value: string) => void;
  handlePasswordValidation?: () => void;
  passwordToolTipParams: ITooltipValidationParams[];
  passwordErrorMessages: {
    ['New Password']: string;
    ['Confirm New Password']: string;
    ['Temporary Password']: string;
  };
  passwordFormDetails?: any;
}

const EndUserPasswordForm = (props: IEndUserPasswordFormProps) => {
  const {
    HEADING,
    SUB_HEADING,
    OLD_PASSWORD_LABEL,
    OLD_PASSWORD_PLACEHOLDER,
    NEW_PASSWORD_LABEL,
    NEW_PASSWORD_PLACEHOLDER,
    CONFIRM_PASSWORD,
    CONFIRM_PASSWORD_PLACEHOLDER,
  } = messages.END_USER_PASSWORD_FORM;

  const {
    handlePasswordTextFieldChange,
    handlePasswordValidation,
    passwordToolTipParams,
    passwordErrorMessages,
    passwordFormDetails,
  } = props;

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-evenly"
      sx={{
        background: theme.palette.common.white,
        border: '1px solid',
        borderColor: EXTRA_COLORS.blue_100,
        boxSizing: 'border-box',
        borderRadius: 1,
        boxShadow: theme.shadows[10],
        width: theme.spacing(178),
        height: theme.spacing(108),
        paddingLeft: theme.spacing(18),
        paddingRight: theme.spacing(18),
      }}
    >
      <Grid item container direction="column" textAlign="center">
        <Grid item>
          <Typography variant="h3">{HEADING}</Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
              paddingTop: theme.spacing(1),
            }}
          >
            {SUB_HEADING}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column" rowSpacing={theme.spacing(8)}>
        <Grid item>
          <PasswordTextField
            name={OLD_PASSWORD_LABEL}
            id={'field1'}
            label={OLD_PASSWORD_LABEL}
            value={passwordFormDetails[OLD_PASSWORD_LABEL]}
            placeholder={OLD_PASSWORD_PLACEHOLDER}
            error={passwordErrorMessages['Temporary Password'] !== ''}
            helperText={passwordErrorMessages['Temporary Password']}
            required
            handleChange={(e) => {
              handlePasswordTextFieldChange(e.target.name, e.target.value);
            }}
            inputProps={{
              autoComplete: "new-password",
            }}
            onBlur={handlePasswordValidation}
          />
        </Grid>
        <Grid item>
          <TooltipForValidation
            validationItems={passwordToolTipParams}
            heading={messages.PASSWORD_PARAMETERS_MESSAGE}
          >
            <PasswordTextField
              name={NEW_PASSWORD_LABEL}
              label={NEW_PASSWORD_LABEL}
              value={passwordFormDetails[NEW_PASSWORD_LABEL]}
              placeholder={NEW_PASSWORD_PLACEHOLDER}
              required
              autoComplete='off'
              error={passwordErrorMessages['New Password'] !== ''}
              helperText={passwordErrorMessages['New Password']}
              handleChange={(e) => {
                handlePasswordTextFieldChange(e.target.name, e.target.value);
              }}
              onBlur={handlePasswordValidation}
            />
          </TooltipForValidation>
        </Grid>
        <Grid item>
          <PasswordTextField
            name={CONFIRM_PASSWORD}
            label={CONFIRM_PASSWORD}
            value={passwordFormDetails[CONFIRM_PASSWORD]}
            placeholder={CONFIRM_PASSWORD_PLACEHOLDER}
            required
            autoComplete='off'
            error={passwordErrorMessages['Confirm New Password'] !== ''}
            helperText={passwordErrorMessages['Confirm New Password']}
            handleChange={(e) => {
              handlePasswordTextFieldChange(e.target.name, e.target.value);
            }}
            onBlur={handlePasswordValidation}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EndUserPasswordForm;
