import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import TextField, { ITextFieldProps } from '../../atoms/TextField';

export const style = {
  borderRadius: '4px',
  backgroundColor: theme.palette.common.white,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: EXTRA_COLORS.lightBlue,
      boxShadow: '0px 4px 40px rgba(234, 244, 255, 0.7)',
    },
    '&:hover fieldset': {
      borderColor: EXTRA_COLORS.lightGrayishBlue,
    },
    '&.Mui-focused fieldset': {
      borderColor: 'primary.main',
    },
  },
};

const SearchField = ({ ...rest }: ITextFieldProps) => {
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);

  return (
    <TextField
      sx={style}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      inputProps={{
        onFocus: (_event) => {
          setFocus(true);
        },
        onBlur: (_event) => {
          setFocus(false);
        },
      }}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon
            data-testid="search-icon"
            fontSize="small"
            color={hover || focus ? 'primary' : 'inherit'}
          />
        </InputAdornment>
      }
      InputProps={{
        ...rest.InputProps,
        sx: () => ({
          ...theme.typography.body1,
          color: theme.palette.text.primary,
          height: theme.spacing(10),
          paddingRight: 0.2,
        }),
      }}
      {...rest}
    ></TextField>
  );
};

export default SearchField;
