import { Box, Grid, Typography } from '@mui/material';
import LoginService from '@service/login-service/LoginService';
import LoadingAnimation from '@src/_components/atoms/LoadingAnimation';
import theme from '@src/core-utils/theme';
import { useAuth } from '@utils/Auth/index.hook';
import { IDP_CONNECTION_ID } from '@utils/constants';
import { ROUTES } from '@utils/routes';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MainFooter from 'src/_components/molecules/MainFooter';
import './logout.css';
import store from '@redux/Store/store';
import { useAppSelector } from '@src/Redux/Store/store';
import { RootStateOrAny } from 'react-redux';

function UserLogoutPage() {
  const [currentTrailStep, setCurrentTrailStep] = useState(0);

  const { logout } = useAuth();
  const history = useHistory();
  const authToken = useAppSelector(
    (state: RootStateOrAny) => state?.token?.session?.authToken,
  );
  const logoutTrail = [
    'Cleaning up the user session',
    'Clearing the browser session',
    'Clearing the cookies',
    'Shutting down the cloud resources',
  ];

  useEffect(() => {
    LoginService.deleteAuthToken(authToken)
      .finally(() => {
        logout();
      });

  }, []);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval> | null = setInterval(() => {
      setCurrentTrailStep((currentTrailStep) => {
        if (currentTrailStep >= logoutTrail.length - 1) {
          interval && clearInterval(interval);
          history.push(ROUTES.LOGIN);
          window.location.reload();
        }
        return currentTrailStep + 1;
      });
    }, 2500);

    return () => {
      interval && clearInterval(interval);
      interval = null;
    };
  }, []);

  return (
    <Box>
      <Grid
        sx={{
          // width: theme.spacing(120),
          height: theme.spacing(100),
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: 'auto',
        }}
        container
        direction="column"
        alignItems="center"
        spacing={theme.spacing(4)}
        className="w-full md:max-w-md"
      >
        <Grid justifyContent="center" container item direction="column">
          <Grid item>
            <LoadingAnimation compact={true} />
          </Grid>
          <Grid
            item
            alignSelf="center"
            className="w-full md:max-w-md"
            sx={{ textAlign: 'center' }}
          >
            <Typography
              variant="subtitle1"
              className="text-sm md:text-lg"
              cy-data-id="logout-message"
            >
              Please wait, while we log you out!
            </Typography>
          </Grid>
          <Grid
            item
            alignSelf="center"
            className="w-full md:max-w-md"
            sx={{ height: theme.spacing(40) }}
          >
            <Typography
              className={`method-${currentTrailStep} text-xs md:text-sm`}
              variant="subtitle1"
              sx={{
                justifyContent: 'space-around',
                textAlign: 'center',
                marginTop: theme.spacing(4),
              }}
            >
              {logoutTrail?.[currentTrailStep]}.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Box position="fixed" bottom={0} width="100%">
        <MainFooter logoRequired={true} versionRequired={false}></MainFooter>
      </Box>
    </Box>
  );
}

export default UserLogoutPage;
