import { useLocation } from "react-router-dom";

export const ROUTES = {
  HOME: '/',
  ADD_APPS_FOR_USER: '/users/:id/addapps',
  ADD_APPS_FOR_USERGROUP: '/usergroups/:id/addapps',
  ADD_APPS: '/users/:id/addapps',
  ADMIN: '/admin',
  APP_DETAILS: '/apps/:id',
  APPS: '/apps',
  // APP_GROUPS: '/app-groups',
  CREATE_POLICY: '/createpolicy',
  DASHBOARD: '/dashboard',
  EDIT_POLICY: '/editpolicy/:id',
  HELP_DETAILS: '/help/:id',
  HELP: '/help',
  LOGIN: '/login',
  LOGOUT: '/logout',
  LOGS: '/logs',
  MFA_LOGIN: '/mfalogin',
  MY_APPLICATIONS: '/my-apps',
  NOTIFICATIONS: '/notifications',
  ONBOARDING: '/onboarding',
  POLICIES: '/policies',
  REPORTS: '/reports',
  SESSION_RECORDING: '/session-recordings/:id',
  SESSION_RECORDINGS: '/session-recordings',
  SESSION_TIMEOUT: '/session-timeout',
  SESSION_TOKEN: '/session/:token?',
  SETTINGS: '/settings',
  USER_DETAILS: '/users/:id',
  USER_GROUP_DETAILS: '/usergroups/:id',
  USER_GROUPS: '/usergroups',
  USER_ONBOARDING: '/user-onboarding',
  USERS: '/users',
  SESSION_ACTIVE: '/sessions/active',
  CONNECTION: '/c/:id'
};

// Use this while navigating to components with path params
export const GENERATE_ROUTE_WITH_PARAM = {
  USER_DETAILS_PARAM: (userId: any) => `users/${escape(userId)}`,
  USER_GROUP_DETAILS_PARAM: (userGroupId: any) => `usergroups/${escape(userGroupId)}`,
};

export const useQuery = () => {
  const { search } = useLocation();
  return new URLSearchParams(search);
}

export const escape = (str: string) => {
  return encodeURIComponent(str.replace(
    /[!'()*]/g,
    (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`
  ));
}
