import { useEffect, useState } from 'react';
import { RootStateOrAny } from 'react-redux';
import UserService from 'src/api-service/user-service/UserService';

import { checkPassword, getMimeTypeOfBase64String, getUserId, isAllowedImageMimeType, isEmptyValidation, isPasswordValid } from '@utils/Helper/helper';
import { ACCOUNT_SETTING_LANGUAGE_OPTIONS, ACCOUNT_SETTING_TIMEZONE_OPTIONS, EMAIL, ITooltipValidationParams, MAX_ORGANIZATION_LOGO_SIZE, PASSWORD_PARAMETERS } from '@utils/constants';
import messages from '@utils/messages';
import onBoardingMessages from '@utils/messages/onBoarding';
import ONBOARDING_FLOW_MESSAGES from '@utils/messages/onboarding_flow_messages';
import { useAppSelector } from '../../../Redux/Store/store';

const {
  NAME,
  USERNAME,
  JOB_TITLE,
  ROLE,
} = ONBOARDING_FLOW_MESSAGES.ADMIN_FORM_MESSAGES;

export const {
  ORGANISATION_NAME,
  ORGANISATION_ADDRESS,
  ORGANISATION_PHONE,
  ORGANISATION_LOGO,
  ORGANISATION_PHONE_CODE,
} = onBoardingMessages.ORGANISATION_FORM;

const emptyOrganisationParams = {
  [ORGANISATION_NAME]: '',
  [ORGANISATION_ADDRESS]: '',
  [ORGANISATION_PHONE]: '',
  [ORGANISATION_PHONE_CODE]: '',
  [ORGANISATION_LOGO]: '',
};

const defaultConfigurationData = {
  TIMEZONE: ACCOUNT_SETTING_TIMEZONE_OPTIONS[0],
  LANGUAGE: ACCOUNT_SETTING_LANGUAGE_OPTIONS[0],
};

// Handle all the states , event handlers of the onboarding forms in this hook only
const useOnboardingPageData = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [userDetails, setUserDetails] = useState<any>();
  const [source, setSource] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState<string>();
  const [isValidLogo, setIsValidLogo] = useState<boolean>(true);
  const [logoValidationMessage, setLogoValidationMessage] = useState("");
  const [authenticationData, setAuthenticationData] = useState<{
    verificationCode: string;
    isVerifyButtonEnabled: boolean;
    errorMessage: string;
  }>({
    verificationCode: '',
    isVerifyButtonEnabled: false,
    errorMessage: '',
  });
  const [totpVerificationSuccess, setTotpVerificationSuccess] = useState(false);

  const mainHeaderData = useAppSelector(
    (state: RootStateOrAny) => state.header,
  );
  
  const getUserById = async () => {
    const userId = getUserId();
    const userResponseById = userId && (await UserService.getUserById(userId));
    setUserDetails(userResponseById);
    const { attributes } = userResponseById.attributes;
    const sourceOfUser =
      attributes?.['sonet-source'] && attributes?.['sonet-source'] === 'okta'
        ? 'okta'
        : 'local';
    setSource(sourceOfUser);
  };

  useEffect(() => {
    setLoading(true);
    getUserById()
      .then()
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    UserService.getTotpLogo(getUserId() ?? '').then((logo: string) => {
      setQrCode(logo);
    });
  }, []);

  const getRequiredEmail = () => {
    if (localStorage.getItem(EMAIL) && localStorage.getItem(EMAIL) !== 'null') {
      return localStorage.getItem(EMAIL) ?? '';
    } else {
      return getUserId() ?? '';
    }
  };

  const requiredEmail = localStorage.getItem(EMAIL) ?? getUserId() ?? '';
  const defaultAdminFormData = {
    [NAME]: '',
    [USERNAME]: getRequiredEmail(),
    [JOB_TITLE]: '',
  };
  const [
    adminInformationFormDetails,
    setAdminInformationFormDetails,
  ] = useState(defaultAdminFormData);
  const [adminRole, setAdminRole] = useState('Super Admin');
  const [adminFormCheckbox, setAdminFormCheckbox] = useState(false);
  const [isAgreeTerms, setIsAgreeTerms] = useState(false);

  // Added By Devang
  // Step 2 agree terms and condition
  const setTermsAndConditionAgree = (event: any) => {
    setIsAgreeTerms(event.target?.checked);
  };

  const onBackClick = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  const onNextClick = () => {
    setCurrentStep((prevState) => prevState + 1);
  };

  // Administrator Form Handlers

  const handleAdminFormCheckedValue = (value: any) => {
    setAdminFormCheckbox(value);
  };
  const handleNameChange = (value: string) => {
    setAdminInformationFormDetails((prevState) => ({
      ...prevState,
      [NAME]: value,
    }));
  };
  const handleUsernameChange = (value: string) => {
    setAdminInformationFormDetails((prevState) => ({
      ...prevState,
      [USERNAME]: value,
    }));
  };
  const handleJobTitleChange = (value: string) => {
    setAdminInformationFormDetails((prevState) => ({
      ...prevState,
      [JOB_TITLE]: value,
    }));
  };
  const handleRoleChange = (value: any) => {
    setAdminRole(value);
  };

  const validateAdminInfo = () => {
    const isValid = Object.keys(adminInformationFormDetails).every((key) => {
      if (
        key === NAME &&
        isEmptyValidation(adminInformationFormDetails?.[NAME]?.trim())
      ) {
        return false;
      }
      if (
        key === USERNAME &&
        isEmptyValidation(adminInformationFormDetails[USERNAME])
      ) {
        return false;
      }
      return true;
    });
    return isValid;
  };

  //Organisation Form Handlers
  const [organisationDetails, setOrganisationDetails] = useState(
    emptyOrganisationParams,
  );
  const handlePhoneCodeChange = (value: any) => {
    setOrganisationDetails((prevState: any) => ({
      ...prevState,
      [ORGANISATION_PHONE_CODE]: value,
    }));
  };

  const handleCustomPhoneCodeChange = (value: any) => {
    setOrganisationDetails((prevState: any) => ({
      ...prevState,
      [ORGANISATION_PHONE_CODE]: { id: -1, name: value },
    }));
  };

  const handleLogoChange = (event: any) => {
    let reader = new FileReader();

    const uploadedFile = event.target.files[0];
    if (uploadedFile.size > MAX_ORGANIZATION_LOGO_SIZE) {
      setIsValidLogo(false);
      setLogoValidationMessage(messages.ORGANISATION_FORM.LOGO_TOO_BIG);
      return;
    }

    reader.onload = (e: any) => {
      const mimeType = getMimeTypeOfBase64String(e.target.result) || "";
      if(isAllowedImageMimeType(mimeType)) {
        setOrganisationDetails((prevState) => ({
          ...prevState,
          [ORGANISATION_LOGO]: e.target.result,
        }));
        setIsValidLogo(true);
        setLogoValidationMessage("");
      } else {
        setIsValidLogo(false);
        setLogoValidationMessage(messages.ORGANISATION_FORM.INVALID_LOGO_IMAGE_TYPE);
      }
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const handleOrganisationTextFieldChange = (name: string, value: string) => {
    setOrganisationDetails((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOrganisationValidation = () => {
    const isValid = Object.keys(organisationDetails).every((key) => {
      if (
        key === ORGANISATION_NAME &&
        isEmptyValidation(organisationDetails?.[key]?.trim())
      ) {
        return false;
      }
      return true;
    });
    return isValid;
  };

  //System Configuration Form Handlers
  const [configurationDetails, setConfigurationDetails] = useState(
    defaultConfigurationData,
  );

  const handleTimezoneChange = (value: string) => {
    setConfigurationDetails((prevState: any) => ({
      ...prevState,
      TIMEZONE: value,
    }));
  };

  const handleLanguageChange = (value: string) => {
    setConfigurationDetails((prevState: any) => ({
      ...prevState,
      LANGUAGE: value,
    }));
  };

  // password form
  const {
    PASSWORD_LABEL,
    INVALID_PASSWORD,
    CONFIRM_PASSWORD,
    PASSWORD_NO_MATCH,
  } = messages.PASSWORD_FORM;
  const [passwordTooltipParams, setPasswordTooltipParams] = useState<
    ITooltipValidationParams[]
  >(PASSWORD_PARAMETERS);

  const [onBoardingDetails, setonBoardingDetails] = useState({
    [PASSWORD_LABEL]: '',
    [CONFIRM_PASSWORD]: '',
  });
  const [passwordErrorMessage, setPasswordErrorMessage] = useState({
    [PASSWORD_LABEL]: '',
    [CONFIRM_PASSWORD]: '',
  });

  const handlePasswordChange = (event: any) => {
    setonBoardingDetails({
      ...onBoardingDetails,
      [event.target.name]: event.target.value,
    });
    if (event.target.name === PASSWORD_LABEL) {
      setPasswordTooltipParams(
        checkPassword(event.target.value, passwordTooltipParams),
      );
    }
  };

  const validateOnBoardingPassword = () => {
    if (
      onBoardingDetails[PASSWORD_LABEL] === '' &&
      onBoardingDetails[CONFIRM_PASSWORD] === ''
    ) {
      return false;
    }
    if (!isPasswordValid(onBoardingDetails[PASSWORD_LABEL])) {
      setPasswordErrorMessage({
        ...passwordErrorMessage,
        [PASSWORD_LABEL]: INVALID_PASSWORD,
      });
      return false;
    }
    if (
      onBoardingDetails[PASSWORD_LABEL] !== onBoardingDetails[CONFIRM_PASSWORD]
    ) {
      setPasswordErrorMessage({
        [PASSWORD_LABEL]: '',
        [CONFIRM_PASSWORD]: PASSWORD_NO_MATCH,
      });
      return false;
    } else {
      setPasswordErrorMessage({
        [PASSWORD_LABEL]: '',
        [CONFIRM_PASSWORD]: '',
      });
      return true;
    }
  };

  const validateSystemConfig = () => {
    return true; //TODO:
  };

  const buttonState = (stepValue: number) => {
    switch (stepValue) {
      case 0:
        validateOnBoardingPassword()
          ? setIsButtonDisabled(false)
          : setIsButtonDisabled(true);
        break;
      case 1:
        setIsButtonDisabled(!totpVerificationSuccess);
        break;
      case 2:
        handleOrganisationValidation()
          ? setIsButtonDisabled(false)
          : setIsButtonDisabled(true);
        break;
      case 3:
        validateAdminInfo()
          ? setIsButtonDisabled(false)
          : setIsButtonDisabled(true);
        break;
      case 4:
        validateSystemConfig()
          ? setIsButtonDisabled(false)
          : setIsButtonDisabled(true);
        break;
      default:
        setIsButtonDisabled(true);
    }
  };
  

  const handleChangeVerificationCode = (event: any) => {
    const verificationCode = event.target.value?.trim();
    if (/^[\d]{6}$/g.test(verificationCode)) {
      setAuthenticationData({
        verificationCode: verificationCode,
        isVerifyButtonEnabled: true,
        errorMessage: '',
      });
      //setIsButtonDisabled(false);
    } else {
      setAuthenticationData({
        verificationCode: verificationCode,
        isVerifyButtonEnabled: false,
        errorMessage: 'Only 6 digits allowed',
      });
      setIsButtonDisabled(true);
    }
  };

  const handleVerifyTotpEnrolment = async () => {
    const verificationSuccess = await UserService.verifyTotpEnrolment(
      getUserId() ?? '',
      authenticationData.verificationCode,
    );
    if (verificationSuccess) {
      setIsButtonDisabled(false);
      setTotpVerificationSuccess(true);
      await UserService.setTotpStatus();
    } else {
      setAuthenticationData({
        ...authenticationData,
        errorMessage:
          onBoardingMessages.AUTHENTICATION_SETUP_FORM.VERIFICATION_FAILED,
      });
      setIsButtonDisabled(true);
    }
  };

  const setOrganisationDetail = (orgData: any) => {
    const {organizationAddress, organizationLogo,organizationName, organizationPhone, organizationPhoneCode } = orgData?.entities;
    setOrganisationDetails((prevState: any) => ({
      ...prevState,
      [ORGANISATION_NAME]: organizationName,
      [ORGANISATION_ADDRESS]: organizationAddress,
      [ORGANISATION_PHONE]: organizationPhone,
      [ORGANISATION_PHONE_CODE]: organizationPhoneCode,
      [ORGANISATION_LOGO]: organizationLogo,
    }));
  }

  useEffect(() => {
    buttonState(currentStep);
  }, [
    currentStep,
    onBoardingDetails,
    organisationDetails,
    adminInformationFormDetails,
  ]);

  return {
    isButtonDisabled,
    setIsButtonDisabled,
    currentStep,
    adminInformationFormDetails,
    handlePasswordChange,
    passwordTooltipParams,
    passwordErrorMessage,
    validateOnBoardingPassword,
    onBoardingDetails,
    onBackClick,
    onNextClick,
    handleAdminFormCheckedValue,
    handleNameChange,
    handleJobTitleChange,
    handleUsernameChange,
    handleRoleChange,
    adminRole,
    adminFormCheckbox,
    handleOrganisationValidation,
    handleOrganisationTextFieldChange,
    handlePhoneCodeChange,
    handleCustomPhoneCodeChange,
    handleLogoChange,
    organisationDetails,
    setOrganisationDetail,
    configurationDetails,
    handleLanguageChange,
    handleTimezoneChange,
    // Added By Devang
    isAgreeTerms,
    setTermsAndConditionAgree,
    authenticationData,
    handleChangeVerificationCode,
    handleVerifyTotpEnrolment,
    totpVerificationSuccess,
    isValidLogo,
    setIsValidLogo,
    logoValidationMessage
  };
};

export default useOnboardingPageData;
