const Config = {
  FRONTEND_URL: 'http://localhost:3042',
  NOTIFICATION_URL: process.env.NOTIFICATION_URL ?? 'ws://localhost:3200/',
  BACKEND_BASE_URL:
    process.env.BACKEND_BASE_URL ?? 'https://ram-dev.sonet.io/sonet/',
  LOGS_URL:
    process.env.LOGS_URL ??
    'https://ram-dev.sonet.io/api/v1/settings/loki/api/v1/query_range?limit=1000&start=1642659014000000000&query={job=%22sonetlogs%22}',
  DASHBOARD_API_URL:
    process.env.DASHBOARD_API_URL ?? 'https://ram-dev.sonet.io/api/v1/',
  // Token expiration time in milli seconds
  TOKEN_EXPIRATION_TIME: process.env.TOKEN_EXPIRATION_TIME ?? 45000,
  OKTA_APP_ID: 1,
  GOOGLE_APP_ID: 7,
  SSH_APP_USERNAME: 'dm',
  SSH_APP_PASSWORD: 'dm',
  CHB_CUSTOMERID: 'AzZl4JT2NWDgAAiK',
};

export default Config;
