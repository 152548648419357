import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Button, Collapse, Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';
import CollapseExpandIcon from '../../../../public/assets/collapse-expand.svg';
import DeleteIcon from '../../../../public/assets/delete.svg';
import { DRAGGABLE_POLICY__ITEMS_ID } from '../../../core-utils/constants';
import messages from '../../../core-utils/messages';
import policyBuilderMessages from '../../../core-utils/messages/policyBuilder';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import IconFromSvg from '../../atoms/IconFromSvg';
import PolicyConditionRow from '../../organisms/PolicyConditionRow';
import { IDropdownOptionProps } from '../Dropdown';
import PolicyConditionCell from '../PolicyConditionCell';
import PolicyHelpTooltip from '../PolicyHelpTooltip';
import PolicyIconsHelpTooltip from '../PolicyIconsHelpTooltip';

const {
  DEFAULT_ACTION,
  EXCEPT_WHEN,
  ACTIONS,
  ADD_CONDITION,
} = messages.POLICY_BLOCK;

interface IPolicyBlockProps {
  policyBlockIndex?: number;
  cardId: number;
  policyName: string;
  policyType: string;
  expanded: boolean;
  defaultAction: any;
  defaultActionOptions: any;
  policyConditions: any;
  userGroupDropdown: IDropdownOptionProps[];
  applicationDropdown: IDropdownOptionProps[];
  urlDropdown: IDropdownOptionProps[];
  handlePolicyChange: (cardd: number, id: number, value: any) => void;
  handleEquationChange: (cardId: number, id: number, value: any) => void;
  handleDeleteRow?: (cardId: number, id: number) => void;
  handleDeleteCard?: (cardId: number) => void;
  handleCopyCard?: (cardId: number) => void;
  handleSaveCard?: (cardId: number) => void;
  handlePolicyValueTextChange?: (
    cardId: number,
    id: number,
    event: any,
  ) => void;
  handlePolicyValueDropdownChange?: (
    cardId: number,
    id: number,
    value: any,
  ) => void;
  handlePolicyActionChange?: (cardId: number, value: any, id?: number) => void;
  handleAddCondition: (cardId: number) => void;
  handleJoinCondition: (cardId: number, rowId: number) => void;
  handleToggleCollapse: (cardId: number) => void;
  handleConditionDrop?: (cardId: number, conditionIndex: string) => void;
  handleOpenSnackbar?: () => void;
  handleCloseSnackbar?: () => void;
  focused?: boolean;
  handleClickCard?: (cardItemId: number) => void;
  handleChangeTimeValue?: (
    cardId: number,
    rowId: number,
    startTime: string,
    endTime: string,
  ) => void;
}

const iconDefaultStyles = {
  height: 16.5,
  widht: 16.5,
};
const iconStyles = {
  color: theme.palette.text.disabled,
  ...iconDefaultStyles,
};
const StyledIconButton = styled(IconButton)({
  padding: '4px 8px',
});

const coloredIconStyles = {
  width: 6,
  height: 6,
  borderRadius: '50%',
  display: 'inline-block',
  padding: 2,
  margin: theme.spacing(1, 2),
};

const PolicyBlockItem = ({
  policyBlockIndex,
  focused,
  cardId,
  policyName,
  policyType,
  policyConditions,
  expanded,
  defaultAction,
  defaultActionOptions,
  handleClickCard,
  handlePolicyChange,
  handleEquationChange,
  handlePolicyValueTextChange,
  handlePolicyValueDropdownChange,
  handlePolicyActionChange,
  handleAddCondition,
  handleDeleteRow,
  handleDeleteCard,
  handleCopyCard,
  handleSaveCard,
  handleToggleCollapse,
  userGroupDropdown,
  applicationDropdown,
  urlDropdown,
  handleConditionDrop,
  handleOpenSnackbar,
  handleCloseSnackbar,
  handleChangeTimeValue,
  handleJoinCondition,
}: IPolicyBlockProps) => {
  // const theme = useTheme();
  //TODO : To be replaced with the props during integration

  return (
    <>
      <Grid
        container
        sx={{
          width: '100%',
          minHeight: expanded ? 300 : 56,
          border: focused
            ? `2px solid ${theme.palette.grey[500]}`
            : `1px solid ${EXTRA_COLORS.lightGrayishBlue}`,
          boxShadow: focused ? theme.shadows[13] : 'none',
          borderRadius: 5,
          padding: 4,
          backgroundColor: theme.palette.common.white,
        }}
        onClick={() => handleClickCard && handleClickCard(cardId)}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          sx={{ maxHeight: 24 }}
        >
          <Grid item xs={6}>
            <StyledIconButton>
              <DragIndicatorIcon sx={iconStyles} />
            </StyledIconButton>
            <Typography
              component="span"
              variant="subtitle2"
              sx={{ padding: '8px 4px' }}
            >
              {policyName}
            </Typography>
          </Grid>
          <Grid item xs={6} container justifyContent="flex-end">
            <>
              <PolicyIconsHelpTooltip
                content={policyBuilderMessages.HELP_TOOLTIP.COPY_BLOCK}
                children={
                  <StyledIconButton onClick={() => handleCopyCard?.(cardId)}>
                    <ContentCopyOutlinedIcon
                      sx={iconStyles}
                      {...iconDefaultStyles}
                    />
                  </StyledIconButton>
                }
              />

              <PolicyIconsHelpTooltip
                content={policyBuilderMessages.HELP_TOOLTIP.DELETE_BLOCK}
                children={
                  <StyledIconButton onClick={() => handleDeleteCard?.(cardId)}>
                    <IconFromSvg path={DeleteIcon} alt="Delete" />
                  </StyledIconButton>
                }
              />

              <PolicyIconsHelpTooltip
                content={policyBuilderMessages.HELP_TOOLTIP.COLLAPSE_BLOCK}
                children={
                  <StyledIconButton
                    onClick={() => handleToggleCollapse(cardId)}
                  >
                    <IconFromSvg path={CollapseExpandIcon} alt="Delete" />
                  </StyledIconButton>
                }
              />
            </>
          </Grid>
        </Grid>
        <Collapse in={expanded} unmountOnExit>
          <div style={{ paddingTop: 16 }}>
            <span
              style={{
                ...coloredIconStyles,
                background: EXTRA_COLORS.pinkDark,
              }}
            />
            <Typography component="span" variant="body1">
              {DEFAULT_ACTION}
            </Typography>
            <div style={{ paddingTop: 8 }}>
              <PolicyConditionCell
                cyId={`policy-default-actions-${policyBlockIndex}`}
                variant="dropdown"
                dropdownOptions={defaultActionOptions}
                dropdownValue={defaultAction}
                handleDropdownChange={(event: any, value: any) =>
                  handlePolicyActionChange &&
                  handlePolicyActionChange(cardId, value)
                }
              />
            </div>
          </div>
          {policyConditions.length > 0 && (
            <div style={{ paddingTop: 16 }}>
              <span
                style={{
                  ...coloredIconStyles,
                  background: EXTRA_COLORS.orangeMedium,
                }}
              />
              <Typography component="span" variant="body1">
                {EXCEPT_WHEN}
              </Typography>
              <span style={{ paddingLeft: '55%' }}>
                <span
                  style={{
                    ...coloredIconStyles,
                    background: EXTRA_COLORS.pinkDark,
                  }}
                />
                <Typography component="span" variant="body1">
                  {ACTIONS}
                </Typography>
              </span>
            </div>
          )}
          <Grid
            container
            direction="column"
            // spacing={3}
            sx={{ paddingTop: 4 }}
          >
            {policyConditions.map((policyCondition: any, index: number) => {
              return (
                <Grid item key={policyCondition.id}>
                  {policyCondition.showOr && (
                    <Typography paddingTop={2} paddingBottom={2}>
                      or,
                    </Typography>
                  )}
                  <PolicyConditionRow
                    rowSequence={`${policyBlockIndex}-${index}`}
                    cardId={cardId}
                    id={policyCondition.id}
                    lastRow={index + 1 === policyConditions.length}
                    parameters={policyCondition.parameters}
                    parameterValue={policyCondition.parameterValue}
                    equationValue={policyCondition.equationValue}
                    policyValue={policyCondition.policyValue}
                    allowanceValue={policyCondition.allowanceValue}
                    handlePolicyChange={handlePolicyChange}
                    showJoin={policyCondition.showJoin}
                    handleEquationChange={handleEquationChange}
                    handlePolicyActionChange={handlePolicyActionChange}
                    userGroupDropdownOptions={userGroupDropdown}
                    applicationDropdownOptions={applicationDropdown}
                    urlDropdownOptions={urlDropdown}
                    handleOpenSnackbar={handleOpenSnackbar}
                    handleCloseSnackbar={handleCloseSnackbar}
                    handleDeleteRow={handleDeleteRow}
                    handlePolicyValueTextChange={handlePolicyValueTextChange}
                    handlePolicyValueDropdownChange={
                      handlePolicyValueDropdownChange
                    }
                    handleChangeTimeValue={handleChangeTimeValue}
                    policyValueErrorMessage={
                      policyCondition.policyValueErrorMessage
                    }
                    handleJoinCondition={handleJoinCondition}
                    handleDropCondition={handleConditionDrop}
                  />
                </Grid>
              );
            })}

            <Grid item>
              <PolicyHelpTooltip operation="or">
                <Button
                  sx={{
                    ...theme.typography.body1,
                    textTransform: 'none',
                    borderRadius: '4px',
                  }}
                  onClick={() => handleAddCondition(cardId)}
                  variant="text"
                  onDragOver={(event: any) => {
                    event.preventDefault();
                  }}
                  onDrop={(event: any) => {
                    const droppedItemId = event.dataTransfer.getData('text');

                    if (
                      droppedItemId.startsWith(
                        DRAGGABLE_POLICY__ITEMS_ID.POLICY_CONDITION_ID,
                      )
                    ) {
                      handleConditionDrop?.(
                        cardId,
                        droppedItemId.split('_')[1],
                      );
                    }
                  }}
                >
                  {ADD_CONDITION}
                </Button>
              </PolicyHelpTooltip>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </>
  );
};

export default PolicyBlockItem;
