import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import Button from '../../atoms/Button';
import PlusIcon from '../../../../public/assets/plus.svg';
import IconFromSvg from '../../atoms/IconFromSvg';
import emptyStateMessages from '../../../core-utils/messages/emptyState';

export interface AddPolicyBlockDividerProps {
  width?: string;
  handleDrop?: (event: any) => void;
  handleButtonClick?: () => void;
}

const AddPolicyBlockDivider = ({
  width,
  handleDrop,
  handleButtonClick,
}: AddPolicyBlockDividerProps) => {
  return (
    <Grid
      container
      width={width ?? 'inherit'}
      direction="row"
      data-testid="drag"
      onDragOver={(event: any) => {
        event.preventDefault();
      }}
      onDrop={handleDrop}
    >
      <Box
        height={theme.spacing(12.25)}
        alignSelf="stretch"
        flexGrow={1}
        sx={{ borderBottom: `2px dashed ${theme.palette.grey[600]}` }}
      />
      <Grid
        container
        width="fit-content"
        height="fit-content"
        direction="column"
        alignItems="center"
        flexGrow={0}
        sx={{
          border: `1px solid ${EXTRA_COLORS.blue_400}`,
          borderRadius: 1.25,
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.text.primary,
            paddingTop: 2,
          }}
        >
          {emptyStateMessages.DRAG_AND_DROP}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.disabled, paddingTop: 1.375 }}
        >
          {emptyStateMessages.OR}
        </Typography>
        <Button
          variant="text"
          startIcon={<IconFromSvg path={PlusIcon} alt="add" />}
          color="primary"
          sx={{ margin: 1.375 }}
          onClick={handleButtonClick}
        >
          {emptyStateMessages.ADD_POLICY_BLOCK}
        </Button>
      </Grid>
      <Box
        height="49px"
        alignSelf="stretch"
        flexGrow={1}
        sx={{ borderBottom: `2px dashed ${theme.palette.grey[600]}` }}
      />
    </Grid>
  );
};
export default AddPolicyBlockDivider;
