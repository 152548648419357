import React from 'react';
import LabelWithIcon from '../../atoms/LabelWithIcon';
import { ICell } from '../../molecules/TableHeader';
import Table from '../Table';
import DownArrowInactive from '../../../../public/assets/down-arrow.svg';
import UpArrowInactive from '../../../../public/assets/up-arrow.svg';
import { IPoliciesTableBody } from '../PoliciesTable';
import PoliciesTableBody from '../../molecules/PoliciesTableBody';

export interface IPoliciesTableProps extends IPoliciesTableBody {
  onNameSortClick?: any;
  handleClick?: (id: string | number, policyName: string) => void;
}
const PoliciesTableInPopUp = ({
  tableData,
  onNameSortClick,
  handleClick,
}: IPoliciesTableProps) => {
  const [defaultSorted, setDefaultSorted] = React.useState(true);

  const onNameHeaderClick = () => {
    setDefaultSorted(!defaultSorted);
    onNameSortClick();
  };
  const POLICIES_HEADERS: ICell[] = [
    {
      value: (
        <LabelWithIcon
          label="Name"
          path={defaultSorted ? DownArrowInactive : UpArrowInactive}
          alt="sort"
        />
      ),
      width: '32.96%',
      onClick: onNameHeaderClick,
    },
    {
      value: 'Associated Applications',
      width: '24.44%',
    },
    {
      value: 'Last Updated By',
      width: '21.08%',
    },
    {
      value: 'Last Policy Hit',
      width: '21.52%',
    },
  ];

  return (
    <Table
      headers={POLICIES_HEADERS}
      tableBody={
        <PoliciesTableBody tabledata={tableData} handleClick={handleClick} />
      }
    />
  );
};

export default PoliciesTableInPopUp;
