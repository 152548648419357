import React from 'react';
import LabelWithIcon from '../../atoms/LabelWithIcon';
import PoliciesTableRow, {
  hideAssociatedApps,
  hideAssociatedGroups,
  hideLastPolicyHitTime,
  IPoliciesTableRowProps,
} from '../../molecules/PoliciesTableRow';
import { ICell } from '../../molecules/TableHeader';
import Table from '../Table';
import DownArrowInactive from '../../../../public/assets/down-arrow.svg';
import UpArrowInactive from '../../../../public/assets/up-arrow.svg';

export interface IPoliciesTableBody {
  tableData: IPoliciesTableRowProps[];
}

export interface IPoliciesTableProps extends IPoliciesTableBody {
  onNameSortClick?: any;
  underTab?: 'users' | 'usergroup';
  arrow?: any;
  hideStatus?: boolean;
}
const PoliciesTable = ({
  tableData,
  onNameSortClick,
  underTab,
  arrow,
  hideStatus,
}: IPoliciesTableProps) => {
  const [defaultSorted, setDefaultSorted] = React.useState(true);
  const onNameHeaderClick = () => {
    setDefaultSorted(!defaultSorted);
    onNameSortClick();
  };
  const POLICIES_HEADERS: ICell[] = [
    {
      value: (
        <LabelWithIcon
          label="Name"
          path={defaultSorted ? DownArrowInactive : UpArrowInactive}
          alt="sort"
        />
      ),
      width: '24.80%',
      onClick: onNameHeaderClick,
    },
    {
      value: !hideAssociatedApps ? 'Associated Applications' : '',
      width: '18.08%',
    },
    {
      value: 'Last Updated By',
      width: '16.46%',
    },
    {
      value: !hideLastPolicyHitTime ? 'Last Policy Hit' : '',
      width: '15.53%',
    },
    {
      value: hideStatus ? '' : 'Status',
      width: '11.01%',
    },
    {
      // TODO: Replace this when stats are ready
      value: '',
      // value: 'Stats',
    },
    // options
    {
      value: '',
    },
  ];
  const POLICIES_HEADERS_UNDER_USERGROUPS: ICell[] = [
    {
      value: (
        <LabelWithIcon
          label="Name"
          path={defaultSorted ? DownArrowInactive : UpArrowInactive}
          alt="sort"
        />
      ),
      width: '29.44%',
      onClick: onNameHeaderClick,
    },
    {
      value: !hideAssociatedApps ? 'Associated Applications' : '',
      width: '22.68%',
    },
    {
      value: !hideLastPolicyHitTime ? 'Last Policy Hit' : '',
      width: '22.24%',
    },
    {
      value: hideStatus ? '' : 'Status',
      width: '11.55%',
    },
    {
      value: '',
      // value: 'Stats',
    },
    // options
    {
      value: '',
    },
  ];
  const POLICIES_HEADERS_UNDER_USERS: ICell[] = [
    {
      value: (
        <LabelWithIcon
          label="Name"
          path={defaultSorted ? DownArrowInactive : UpArrowInactive}
          alt="sort"
        />
      ),
      width: '22.02%',
      onClick: onNameHeaderClick,
    },
    {
      value: !hideAssociatedGroups ? 'Groups' : '',
      width: '20.71%',
    },
    {
      value: !hideAssociatedApps ? 'Associated Applications' : '',
      width: '20.06%',
    },
    {
      value: !hideLastPolicyHitTime ? 'Last Policy Hit' : '',
      width: '12.10%',
    },
    {
      value: hideStatus ? '' : 'Status',
      width: '11.01%',
    },
    {
      value: '',
      // value: 'Stats',
    },
    // options
    {
      value: '',
    },
  ];

  React.useEffect(() => {
    setDefaultSorted(true);
  }, [arrow]);

  return (
    <Table
      headers={
        !underTab
          ? POLICIES_HEADERS
          : underTab === 'users'
          ? POLICIES_HEADERS_UNDER_USERS
          : POLICIES_HEADERS_UNDER_USERGROUPS
      }
      inUserActivityTable
      customTableRow={PoliciesTableRow}
      tableData={tableData}
    />
  );
};

export default PoliciesTable;
