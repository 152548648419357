import React from 'react';
import { Grid, Typography } from '@mui/material';
import theme, { customStyles, } from '../../../core-utils/theme';
import helpMessages from '../../../core-utils/messages/';
import FAQListItem from '../../organisms/HelpMinimized/faqListItem';
import { HelpFAQType, FAQType } from '../../../core-utils/helpFAQs';

const { TOUR_HEADING, TOUR_BODY, FREQUENTLY_ASKED } = helpMessages;

interface IHelpMinimizedListProps {
  helpDetails: HelpFAQType;
  handleItemClick: (id: number, questionId: number | string) => void;
}

const HelpMinimizedList = ({
  helpDetails,
  handleItemClick,
}: IHelpMinimizedListProps) => {
  return (
    <>
      {/* TODO : can be enabled later */}
      {/* <Grid item>
        <Paper
          sx={{
            width: '90%',
            height: 94,
            background: theme.palette.common.white,
            borderRadius: '5px',
            border: `1px solid ${EXTRA_COLORS.lightGrayishBlue}`,
            pl: 4,
            pr: 4,
            cursor: 'pointer',
          }}
        >
          <Grid container alignItems="center" direction="row" sx={{ p: 2 }}>
            <Grid item xs={3}>
              <Paper
                sx={{
                  background: EXTRA_COLORS.blusihWhite,
                  width: 60,
                  height: 60,
                  borderRadius: '5px',
                  border: `1px solid ${EXTRA_COLORS.lightGrayishBlue}`,
                }}
              >
                <div style={{ paddingTop: 5, paddingLeft: 15 }}>
                  <IconFromSvg path={SonetInfoSvg} alt="logo" />
                </div>
              </Paper>
            </Grid>
            <Grid item xs={9} sx={{ paddingLeft: 2 }}>
              <Typography variant="body1">{TOUR_HEADING}</Typography>
              <Typography variant="caption">{TOUR_BODY}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid> */}
      <Grid item>
        <Typography variant="body1">{FREQUENTLY_ASKED}</Typography>
      </Grid>
      <Grid
        item
        container
        spacing={4}
        sx={{
          overflow: 'auto',
          maxHeight: 395,
          scrollbarWidth: 'thin',
          scrollbarColor: theme.palette.grey[500],
          ...customStyles.scrollBar,
        }}
      >
        {helpDetails.faqs.map((faqItem: FAQType, index: number) => {
          return (
            <Grid item key={index} xs={12}>
              <FAQListItem
                questionId={faqItem.id}
                id={index}
                faqItem={faqItem}
                handleItemClick={handleItemClick}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default HelpMinimizedList;
