import axios from '../axios';
import qs from 'qs';
import Config from '../../../config/config';
import {
  MOCK_APPS_ACCESSED_WIDGET,
  MOCK_APPS_VIOLATIONS_WIDGET,
  MOCK_CATEGORY_VIOLATIONS_WIDGET,
  MOCK_DASHBOARD_OVERVIEW_ITEMS,
  MOCK_EVENTS_SEVERITY_WIDGET,
  MOCK_USERS_VIOLATIONS_WIDGET,
  MOCK_USER_GROUPS_VIOLATIONS_WIDGET,
  MOCK_USER_LOGIN_DATA,
} from '../../core-utils/test-constants';

const isDashboardAPIValid = () => {
  return Config.DASHBOARD_API_URL ? true : false;
};

const DashboardService = {
  getUserLogins: async (startDate: String, endDate: String) => {
    try {
      if (!isDashboardAPIValid()) {
        return MOCK_USER_LOGIN_DATA;
      }
      const userLogins = await axios.get(
        `${Config.DASHBOARD_API_URL}LoginsPerDay`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return userLogins.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  getAppsViolations: async (startDate: String, endDate: String) => {
    try {
      if (!isDashboardAPIValid()) {
        return MOCK_APPS_VIOLATIONS_WIDGET;
      }
      const appsViolations = await axios.get(
        `${Config.DASHBOARD_API_URL}AppsViolationsCount`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return appsViolations.data.slice(0, 5);
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  getCategoryViolations: async (startDate: String, endDate: String) => {
    try {
      if (!isDashboardAPIValid()) {
        return MOCK_CATEGORY_VIOLATIONS_WIDGET;
      }
      const categoryViolations = await axios.get(
        `${Config.DASHBOARD_API_URL}CategoryViolations`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return categoryViolations.data.slice(0, 10);
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  getUsersViolations: async (startDate: String, endDate: String) => {
    try {
      if (!isDashboardAPIValid()) {
        return MOCK_USERS_VIOLATIONS_WIDGET;
      }
      const usersViolations = await axios.get(
        `${Config.DASHBOARD_API_URL}UsersViolations`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return usersViolations.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  getUserGroupsViolations: async (startDate: String, endDate: String) => {
    try {
      if (!isDashboardAPIValid()) {
        return MOCK_USER_GROUPS_VIOLATIONS_WIDGET;
      }
      const userGroupsViolations = await axios.get(
        `${Config.DASHBOARD_API_URL}UserGroupsViolations`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return userGroupsViolations.data
        .filter((data: any) => data.usergroupName !== 'All Users')
        .slice(0, 7);
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  getAppsAccessed: async (startDate: String, endDate: String) => {
    try {
      if (!isDashboardAPIValid()) {
        return MOCK_APPS_ACCESSED_WIDGET;
      }
      const appsAccessed = await axios.get(
        `${Config.DASHBOARD_API_URL}AppsAccessedCount`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return appsAccessed.data.slice(0, 5);
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  getActiveUsersCount: async (startDate: String, endDate: String) => {
    try {
      const activeUsersCount = await axios.get(
        `${Config.DASHBOARD_API_URL}ActiveUsersCount`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return activeUsersCount.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  getActiveAppsCount: async (startDate: String, endDate: String) => {
    try {
      const activeAppsCount = await axios.get(
        `${Config.DASHBOARD_API_URL}ActiveAppsCount`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return activeAppsCount.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  getPolicyViolationsCount: async (startDate: String, endDate: String) => {
    try {
      const policyViolationsCount = await axios.get(
        `${Config.DASHBOARD_API_URL}PolicyViolationsCount`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return policyViolationsCount.data;
    } catch (error) {
      console.error(error);
      return [0];
    }
  },
  getPolicyChangesCount: async (startDate: String, endDate: String) => {
    try {
      const policyChangesCount = await axios.get(
        `${Config.DASHBOARD_API_URL}PolicyChangesCount`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return policyChangesCount.data;
    } catch (error) {
      console.error(error);
      return [0];
    }
  },
};

export default DashboardService;
