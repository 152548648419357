import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  TooltipProps,
  tooltipClasses,
  Typography,
} from '@mui/material';
import { styled } from '@mui/styles';
import theme, { EXTRA_COLORS } from 'src/core-utils/theme';
import IconFromSvg from 'src/_components/atoms/IconFromSvg';
import redSquare from '@assets/round-red-square.svg';
import oliveGreenSquare from '@assets/round-oliveGreen-square.svg';
import deletedSvg from '@assets/delete.svg';
import deletedActiveSvg from '@assets/delete_primary.svg';
import editSvg from '@assets/edit-inactive.svg';
import editActiveSvg from '@assets/edit-active.svg';
import ApplicationPasswordmanagerModal from 'src/_components/organisms/Passwordmanager';
import RemovePopUp from '../RemovePopUp';
import applicationPasswordManagerModalMessages from '@utils/messages/applicationPasswordManager';

export interface UsersAppCredentials {
  appId: string;
  appName: string;
  appLogo?: string;
  userEmail: string;
  isCredGenerated: boolean;
  editCredentialsHandler: () => void;
  deleteCredentialsHandler: (appId: any, userId: any) => void;
  tabItemName?: string;
}

const UsersAppTableRow = ({
  appId,
  appName,
  appLogo,
  userEmail,
  isCredGenerated,
  tabItemName,
  editCredentialsHandler,
  deleteCredentialsHandler,
}: UsersAppCredentials) => {
  const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: EXTRA_COLORS.greyFont,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: EXTRA_COLORS.greyFont,
    },
  }));

  const [checked, setChecked] = useState(false);
  const [editActive, setEditActive] = useState(false);
  const [deleteActive, setdeleteActive] = useState(false);
  const [isOpenPasswordManager, setIsOpenPasswordManager] = useState(false);

  const handleConfigureUserCredentials = () => {
    setIsOpenPasswordManager(true);
    setEditActive(false);
  };

  const closeConfigureUserCredentials = () => {
    setIsOpenPasswordManager(false);
  };

  const [isDeleteCredOpen, setIsDeleteCredOpen] = useState(false);
  const handleDeleteCredOpen = () => {
    setIsDeleteCredOpen(true);
    setdeleteActive(false);
  };
  const closeDeleteCred = () => {
    setIsDeleteCredOpen(false);
  };

  const removeCredsHandler = () => {
    deleteCredentialsHandler(appId, userEmail);
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '&:hover': {
            backgroundColor: checked
              ? EXTRA_COLORS.lightBlue
              : EXTRA_COLORS.teritary_blue,
            borderLeft: checked
              ? ''
              : `2px solid ${theme.palette.primary.main}`,
          },
          backgroundColor: checked
            ? EXTRA_COLORS.lightBlue
            : theme.palette.common.white,
          boxShadow: `0 0 0 1px ${EXTRA_COLORS.lightGrayishBlue} inset`,
        }}
      >
        <TableCell width={6} sx={{ paddingLeft: 0 }} />
        <TableCell
          sx={{ paddingLeft: 0, cursor: 'pointer' }}
          width={'40%'}
          data-testid="table-row"
        >
          <Grid container alignItems="center" sx={{ height: 35 }}>
            {appLogo ? (
              <IconFromSvg width={35} path={appLogo} alt="logo" />
            ) : (
              ''
            )}
            <Typography
              sx={{ paddingLeft: appLogo ? 4 : '' }}
              variant="body1"
              color={theme.palette.primary.main}
            >
              {appName}
            </Typography>
          </Grid>
        </TableCell>
        <TableCell width={'30%'}>
          <Grid container>
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box sx={{ mr: theme.spacing(2.5) }}>
                {isCredGenerated ? (
                  <IconFromSvg path={oliveGreenSquare} alt="stats-inactive" />
                ) : (
                  <IconFromSvg path={redSquare} alt="stats-inactive" />
                )}
              </Box>

              <Typography variant="body2" color={EXTRA_COLORS.greyFont}>
                {isCredGenerated ? 'Yes' : 'No'}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell width={30} sx={{ paddingLeft: 0 }} />
        <TableCell
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center',
          }}
        >
          <Box>
            <CustomToolTip
              title={
                isCredGenerated ? 'Edit Credentials' : 'Generate Credentials'
              }
            >
              <IconButton
                placeholder="edit"
                onPointerEnter={() => setEditActive(true)}
                onPointerLeave={() => setEditActive(false)}
                onClick={handleConfigureUserCredentials}
                className={'cy-action-edit-app-${appId}'}
              >
                {editActive ? (
                  <IconFromSvg path={editActiveSvg} alt="close-active" />
                ) : (
                  <IconFromSvg path={editSvg} alt="close-inactive" />
                )}
              </IconButton>
            </CustomToolTip>
          </Box>
          <Box>
            {isCredGenerated && (
              <CustomToolTip title="Delete Credentials">
                <IconButton
                  placeholder="close"
                  onPointerEnter={() => setdeleteActive(true)}
                  onPointerLeave={() => setdeleteActive(false)}
                  onClick={handleDeleteCredOpen}
                  className={'cy-action-delete-app-${appId}'}

                >
                  {deleteActive ? (
                    <IconFromSvg path={deletedActiveSvg} alt="close-active" />
                  ) : (
                    <IconFromSvg path={deletedSvg} alt="close-inactive" />
                  )}
                </IconButton>
              </CustomToolTip>
            )}
          </Box>
        </TableCell>
      </TableRow>
      {isOpenPasswordManager && (
        <ApplicationPasswordmanagerModal
          showModal={isOpenPasswordManager}
          onClose={closeConfigureUserCredentials}
          name={userEmail}
          appId={appId}
          onSubmitHandler={editCredentialsHandler}
          clearForm={isOpenPasswordManager}
        />
      )}

      {isDeleteCredOpen && (
        <RemovePopUp
          mainText={
            applicationPasswordManagerModalMessages.CUSTOM_REMOVE_CREDENTIALS
          }
          removeFrom={tabItemName}
          removeItem={appName}
          heading={applicationPasswordManagerModalMessages.DELETE_CREDENTIALS}
          open={isDeleteCredOpen}
          onButtonClick={removeCredsHandler}
          handleClose={closeDeleteCred}
          buttonText={applicationPasswordManagerModalMessages.DELETE}
        />
      )}
    </React.Fragment>
  );
};

export default UsersAppTableRow;
