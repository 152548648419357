import React from 'react';
import { Box, BoxProps, Typography, useTheme } from '@mui/material';

interface INoAssignmentProps extends BoxProps {
  displayText: string;
}

const EmptyAssignmentState = (props: INoAssignmentProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.grey[300],
        border: '1px solid ' + theme.palette.grey[300],
        width: 'inherit',
        height: theme.spacing(13.25),
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography sx={{ color: theme.palette.text.disabled }}>
        {props.displayText}
      </Typography>
    </Box>
  );
};

export default EmptyAssignmentState;
