import * as React from 'react';
import { USER_GROUPS_AND_APPS_HEADERS } from '../../../core-utils/constants';
import LabelWithIcon from '../../atoms/LabelWithIcon';
import { ICell } from '../../molecules/TableHeader';
import { IUserGroupsAndAppsTableBody } from '../../molecules/UserGroupsAndAppsTableBody';
import UserGroupsAndAppsTableRow from '../../molecules/UserGroupsAndAppsTableRow';
import DownArrowInactive from '../../../../public/assets/down-arrow.svg';
import UpArrowInactive from '../../../../public/assets/up-arrow.svg';
import Table from '../Table';

export interface IUserGroupsTableProps extends IUserGroupsAndAppsTableBody {
  onLabelClick?: any;
  arrow?: any;
}

const UserGroupsTable = ({ onLabelClick, ...rest }: IUserGroupsTableProps) => {
  const [defaultSorted, setDefaultSorted] = React.useState(true);
  const onNameHeaderClick = () => {
    setDefaultSorted(!defaultSorted);
    onLabelClick();
  };

  const USER_GROUPS_AND_APPS_HEADERS = (): ICell[] => {
    return [
      {
        value: (
          <LabelWithIcon
            label="Name"
            path={defaultSorted ? DownArrowInactive : UpArrowInactive}
            alt="sort"
          />
        ),
        width: '75.68%',
        onClick: onNameHeaderClick,
      },
      {
        value: 'No. Of Users',
        width: '12%',
      },
      {
        // TODO: Replace this when stats are ready
        value: '',
        // value: 'Stats',
      },
      // options
      {
        value: '',
      },
    ];
  };
  React.useEffect(() => {
    setDefaultSorted(true);
  }, [rest.arrow]);

  return (
    <Table
      headers={USER_GROUPS_AND_APPS_HEADERS()}
      customTableRow={UserGroupsAndAppsTableRow}
      tableData={rest.tabledata}
      inUserActivityTable={true}
    />
  );
};

export default UserGroupsTable;
