import {
  Stack,
  IconButton,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@mui/material';
import React from 'react';
import DropDownMenu from '../../molecules/DropDownMenu';
import IconFromSvg from '../../atoms/IconFromSvg';
import MoreActive from '../../../../public/assets/more-active.svg';
import Button from '../../atoms/Button';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import messages from '../../../core-utils/messages';
import { formatTime } from '../../../core-utils/Helper/helper';
import { PolicyViolationCardsProps } from '../../../core-utils/constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import sessionRecordingMessages from '@src/core-utils/messages/sessionRecordings';

const PolicyViolationCards = ({
  violationsData,
  onViolationClick
}: PolicyViolationCardsProps) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (
    event: React.SyntheticEvent,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        background: theme.palette.background.paper,
        padding: theme.spacing(4),
        border: '1px solid' + EXTRA_COLORS.blue_100,
        boxSizing: 'border-box',
        borderRadius: theme.spacing(2),
        width: '100%',
        boxShadow: theme.shadows[10],
      }}
    >
      <Typography
        variant="subtitle2"
        color={theme.palette.text.primary}
        sx={{paddingLeft: theme.spacing(2)}}
      >
        {
          sessionRecordingMessages.SESSION_RECORDING_PAGE
            .POLICY_VIOLATIONS
        }
      </Typography>
      <Stack spacing={0}>
        {violationsData.map((violationData, index) => {
          return (
            <Accordion
              expanded={expanded === violationData.id}
              onChange={handleChange(violationData.id)}
              key={index}
              className="hover:bg-[#EDF7FF] hover:font-bold ease-in-out duration-500 transition-colors border-transparent rounded-lg overflow-hidden"
              sx={{
                boxSizing: 'border-box',
                width: '100%',
                paddingY: 0,
                borderBottom: 'none',
                '&.MuiAccordion-gutters:before': {
                  height: 0
                }
              }}
              onClick={(e) => {
                onViolationClick && onViolationClick(violationData.offset);
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack
                  sx={{ width: '100%', border: 'none' }}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" color="textSecondary">
                    {violationData.name}
                  </Typography>
                  <Typography variant="h6" color="textSecondary">
                    {formatTime(violationData.offset) + ' min'}
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack spacing={0}>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ pr: 6 }}
                    lineHeight="150%"
                  >
                    {violationData.description}
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ display: 'none' }} //will enable in next release
                  >
                    <Button variant="text">
                      {messages.CREATE_POLICY_MESSAGE}
                    </Button>
                    <DropDownMenu
                      menuItems={violationData.actions}
                      itemForDropdownMenu={
                        <IconButton placeholder="more">
                          <IconFromSvg path={MoreActive} alt="more-active" />
                        </IconButton>
                      }
                    ></DropDownMenu>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Stack>
    </Box>
  );
};

export default PolicyViolationCards;
