import React from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import IconFromSvg from '../../atoms/IconFromSvg';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import CloseIcon from '../../../../public/assets/close-inactive.svg';
import { IPoliciesTableRowProps } from '../PoliciesTableRow';
import policyMessages from '../../../core-utils/messages/policyMessages';
import MyPoliciesTabItem from '../../organisms/MyPoliciesTabItem';

export interface IAttachOrClonePolicyPopUpProps {
  type: 'attach' | 'clone';
  policies: IPoliciesTableRowProps[];
  searchData: string[];
  open: boolean;
  handleClose: () => void;
  handleClick?: (id: string | number, policyName: string) => void;
}

const AttachOrClonePolicyPopUp = ({
  type,
  policies,
  searchData,
  open,
  handleClose,
  handleClick,
}: IAttachOrClonePolicyPopUpProps) => {
  const style = {
    width: 764,
    height: 537,
    bgcolor: EXTRA_COLORS.bluishBackground,
    borderRadius: theme.spacing(2.5),
    boxShadow: `${theme.spacing(0)} ${theme.spacing(5)} ${theme.spacing(
      6,
    )} rgba(0, 0, 0, 0.08)`,
    p: 7,
    px: 6,
    pb: 6,
    overflow: 'scroll',
    position: 'absolute' as 'absolute',
    top: '15%',
    left: '25%',
    transform: 'inherit !important',
  };

  return (
    <Modal open={open}>
      <Box flexDirection="column" rowGap={3} sx={style}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h2">
            {type === 'attach'
              ? policyMessages.ATTACH_EXISTING_POLICY
              : policyMessages.CLONE_EXISTING_POLICY}
          </Typography>

          <IconButton onClick={handleClose}>
            <IconFromSvg path={CloseIcon} alt="close" />
          </IconButton>
        </Box>

        <Typography variant="body2" sx={{ paddingBottom: 3 }}>
          {type === 'attach'
            ? policyMessages.SELECT_EXISTING_POLICY.replace(
                '{action}',
                'attached',
              )
            : policyMessages.SELECT_EXISTING_POLICY.replace(
                '{action}',
                'cloned',
              )}
        </Typography>

        <MyPoliciesTabItem
          policiesData={policies}
          policiesSearchData={searchData}
          inPopUp={true}
          handleClick={handleClick}
        />
      </Box>
    </Modal>
  );
};

export default AttachOrClonePolicyPopUp;
