import { Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import theme, { customStyles, EXTRA_COLORS } from '@utils/theme';
import { styled } from '@mui/material/styles';
import TimeSwitch from '../../atoms/TimeSwitch';
import { convertToHHMM, formatAMPM } from '@utils/Helper/helper';
import policyBuilderMessages from '@utils/messages/policyBuilder';
import { useAppSelector } from '../../../Redux/Store/store';
import { RootStateOrAny } from 'react-redux';
import Button from 'src/_components/atoms/Button';
import { BUTTON_LABEL_CONSTANTS } from '@utils/constants';
import {
  ACCOUNT_SETTING_TIMEZONE_OPTIONS,
} from '@utils/constants';
import Dropdown, { IDropdownOptionProps } from '../Dropdown';
import { getTimezoneIdFromDesc, getTimezoneOptionFromId, getTimezoneIdFromCode, getTimezoneOptionFromCode } from '@utils/Helper/timezones';

const style = {
  minHeight: theme.spacing(152),
  background: 'transparent',
};



const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.common.white,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.common.white,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      boxShadow: theme.shadows[7],
      borderRadius: theme.spacing(1),
    },
    '&.Mui-error fieldset': {
      borderColor: `${theme.palette.error.main} !important`,
    },
    width: theme.spacing(17.5),
    height: theme.spacing(8.75),
  },
});

const StyledTypography = styled(Typography)({
  ...theme.typography.caption,
  color: theme.palette.text.secondary,
  paddingBottom: theme.spacing(1),
});

const StyledOuterBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingBottom: 4,
});

const StyledInnerBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: theme.spacing(37.5),
  height: theme.spacing(9),
  columnGap: theme.spacing(1),
  border: `1px solid ${EXTRA_COLORS.blue_100}`,
  borderRadius: theme.spacing(1),
});


export interface IPolicyBuilderTimePickerProps {
  handleClose: (startTime?: string, endTime?: string, timezone?: string) => void;
  startTime?: string;
  endTime?: string;
  timezoneCode?: string;
  handleCancel?: () => void;
}
const PolicyBuilderTimePicker = ({
  startTime,
  endTime,
  timezoneCode,
  handleClose,
  handleCancel,
}: IPolicyBuilderTimePickerProps) => {
  const [startHourError, setStartHourError] = useState<boolean>(false);
  const [startMinError, setStartMinError] = useState<boolean>(false);
  const [endHourError, setEndHourError] = useState<boolean>(false);
  const [endMinError, setEndMinError] = useState<boolean>(false);
  const [startTimeChecked, setStartTimeChecked] = useState<boolean>(false);
  const [endTimeChecked, setEndTimeChecked] = useState<boolean>(false);
  const [timezoneChecked, setTimezoneChecked] = useState<boolean>(false);
  const mainHeaderData = useAppSelector(
    (state: RootStateOrAny) => state.header,
  );
  const [timeDetails, setTimeDetails] = useState<{
    startHour: string;
    startMin: string;
    endHour: string;
    endMin: string;
    timezone: string;
  }>({
    startHour: '',
    startMin: '',
    endHour: '',
    endMin: '',
    timezone: ''
  });
  const [selectedTimezone, setSelectedTimezone] = useState<IDropdownOptionProps>();


  const isEmptyTimeFields = () => {
    return (
      timeDetails.startHour === '' ||
      timeDetails.startMin === '' ||
      timeDetails.endHour === '' ||
      timeDetails.endMin === ''
    );
  };

  const componentWillUnmount = useRef(false);
  useEffect(() => {
    let defaultTimezone: IDropdownOptionProps | undefined = getTimezoneOptionFromId(1);
    if (timezoneCode) {
      defaultTimezone = getTimezoneOptionFromCode(timezoneCode);
    } else if (mainHeaderData?.entities?.timezone) {
      defaultTimezone = getTimezoneOptionFromId(getTimezoneIdFromDesc(mainHeaderData?.entities?.timezone) || 1);
    }
    if (defaultTimezone) {
      setSelectedTimezone(defaultTimezone);
      setTimeDetails((prevState: any) => ({
        ...prevState,
        timezone: defaultTimezone?.code
      }));
    }
    return () => {
      componentWillUnmount.current = true;
    };
  }, []);

  useEffect(() => {
    if (startTime) {
      const { hours, mins, amOrPm } = formatAMPM(startTime);
      setTimeDetails((prevState: any) => ({
        ...prevState,
        startHour: hours,
        startMin: mins,
      }));
      amOrPm === 'AM' ? setStartTimeChecked(false) : setStartTimeChecked(true);
    }

    if (endTime) {
      const { hours, mins, amOrPm } = formatAMPM(endTime);
      setTimeDetails((prevState: any) => ({
        ...prevState,
        endHour: hours,
        endMin: mins,
      }));
      amOrPm === 'AM' ? setEndTimeChecked(false) : setEndTimeChecked(true);
    }
  }, [startTime, endTime]);

  useEffect(() => {
    return () => {
      // This line only evaluates to true after the componentWillUnmount happens
      if (componentWillUnmount.current) {
        if (
          !startHourError &&
          !startMinError &&
          !endHourError &&
          !endMinError &&
          !isEmptyTimeFields()
        ) {
          var startingTime = convertToHHMM(
            timeDetails.startHour,
            timeDetails.startMin,
            startTimeChecked,
          );
          var endingTime = convertToHHMM(
            timeDetails.endHour,
            timeDetails.endMin,
            endTimeChecked,
          );
          handleClose(startingTime, endingTime, timeDetails.timezone);
        } else {
          handleClose();
        }
      }
    };
  }, [timeDetails, startTimeChecked, endTimeChecked]);

  const handleChangeHour = (event: any, type: 'start' | 'end') => {
    const value = event.target.value;
    var hour = parseInt(value);

    if (
      !isNaN(hour) &&
      value.length <= 2 &&
      value !== '00' &&
      hour >= 0 &&
      hour <= 12
    ) {
      if (type === 'start') {
        setStartHourError(false);
        setTimeDetails({ ...timeDetails, startHour: event.target.value });
      } else {
        setEndHourError(false);
        setTimeDetails({ ...timeDetails, endHour: event.target.value });
      }
    } else {
      if (type === 'start') {
        setStartHourError(true);
        setTimeDetails({ ...timeDetails, startHour: '' });
      } else {
        setEndHourError(true);
        setTimeDetails({ ...timeDetails, endHour: '' });
      }
    }
  };

  const handleChangeMin = (event: any, type: 'start' | 'end') => {
    const value = event.target.value;
    var mins = parseInt(value);
    if (!isNaN(mins) && value.length <= 2 && mins >= 0 && mins <= 59) {
      if (type === 'start') {
        setStartMinError(false);
        setTimeDetails({ ...timeDetails, startMin: event.target.value });
      } else {
        setEndMinError(false);
        setTimeDetails({ ...timeDetails, endMin: event.target.value });
      }
    } else {
      if (type === 'start') {
        setStartMinError(true);
        setTimeDetails({ ...timeDetails, startMin: '' });
      } else {
        setEndMinError(true);
        setTimeDetails({ ...timeDetails, endMin: '' });
      }
    }
  };

  const handleChangeStartTime = (event: any) => {
    setStartTimeChecked(!startTimeChecked);
  };

  const handleChangeEndTime = (event: any) => {
    setEndTimeChecked(!endTimeChecked);
  };

  const handleFormat = (
    event: any,
    type: 'startHour' | 'startMin' | 'endHour' | 'endMin',
  ) => {
    if (type === 'startHour') {
      if (!startHourError) {
        var startHour = timeDetails.startHour;
        if (startHour.length === 1 && startHour !== '0') {
          startHour = '0' + startHour;
          setTimeDetails({ ...timeDetails, startHour: startHour });
        } else if (startHour === '0') {
          setStartHourError(true);
          setTimeDetails({ ...timeDetails, startHour: '' });
        }
      }
    } else if (type === 'startMin') {
      if (!startMinError) {
        var startMin = timeDetails.startMin;
        if (startMin.length === 1) {
          startMin = '0' + startMin;
          setTimeDetails({ ...timeDetails, startMin: startMin });
        }
      }
    } else if (type === 'endHour') {
      if (!endHourError) {
        var endHour = timeDetails.endHour;
        if (endHour.length === 1 && endHour !== '0') {
          endHour = '0' + endHour;
          setTimeDetails({ ...timeDetails, endHour: endHour });
        } else if (endHour === '0') {
          setEndHourError(true);
          setTimeDetails({ ...timeDetails, endHour: '' });
        }
      }
    } else if (type === 'endMin') {
      if (!endMinError) {
        var endMin = timeDetails.endMin;
        if (endMin.length === 1) {
          endMin = '0' + endMin;
          setTimeDetails({ ...timeDetails, endMin: endMin });
        }
      }
    }
  };

  const handleOkClick = () => {
    if (!startHourError && !startMinError && !endHourError && !endMinError) {
      handleCancel && handleCancel();
    }
  };

  const handleCancelClick = () => {
    if (!startTime && !endTime) {
      setTimeDetails({ startHour: '', startMin: '', endHour: '', endMin: '', timezone: '' });
    }
    if (startTime) {
      const { hours, mins, amOrPm } = formatAMPM(startTime);
      setTimeDetails((prevState: any) => ({
        ...prevState,
        startHour: hours,
        startMin: mins,
      }));
      amOrPm === 'AM' ? setStartTimeChecked(false) : setStartTimeChecked(true);
    }
    if (endTime) {
      const { hours, mins, amOrPm } = formatAMPM(endTime);
      setTimeDetails((prevState: any) => ({
        ...prevState,
        endHour: hours,
        endMin: mins,
      }));
      amOrPm === 'AM' ? setEndTimeChecked(false) : setEndTimeChecked(true);
    }
    handleCancel && handleCancel();
  };

  const getTimeZoneFromName = (timeZone: string | undefined) => {
    // TODO: This might be changed once we know how API sends the timeZone
    return ACCOUNT_SETTING_TIMEZONE_OPTIONS.find(
      (zone) => zone.name === timeZone,
    );
  };

  const handleTimeZoneChange = (e: any, value: IDropdownOptionProps) => {
    setSelectedTimezone(value);
    setTimezoneChecked(true);
    setTimeDetails((prevState: any) => ({
      ...prevState,
      timezone: value?.code
    }));
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        ...style,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          border: `1px solid ${theme.palette.grey[200]}`,
          padding: theme.spacing(6, 6),
          width: theme.spacing(80),
          borderRadius: theme.spacing(1.25),
          backgroundColor: theme.palette.common.white
        }}
      >
      <StyledTypography>
        {policyBuilderMessages.TIME_PICKER.START_TIME}
      </StyledTypography>
      <StyledOuterBox>
        <StyledInnerBox>
          <StyledTextField
            error={startHourError}
            value={timeDetails.startHour}
            onChange={(event: any) => handleChangeHour(event, 'start')}
            inputProps={{ style: { textAlign: 'center' } }}
            onBlur={(event) => handleFormat(event, 'startHour')}
          />
          <Typography>:</Typography>
          <StyledTextField
            error={startMinError}
            value={timeDetails.startMin}
            inputProps={{ style: { textAlign: 'center' } }}
            onChange={(event: any) => handleChangeMin(event, 'start')}
            onBlur={(event) => handleFormat(event, 'startMin')}
          />
        </StyledInnerBox>
        <TimeSwitch
          checked={startTimeChecked}
          onChange={handleChangeStartTime}
        />
      </StyledOuterBox>
      {/* ------------------------------------------------------------------------------------------------- */}
      <StyledTypography>
        {policyBuilderMessages.TIME_PICKER.END_TIME}
      </StyledTypography>
      <StyledOuterBox>
        <StyledInnerBox>
          <StyledTextField
            error={endHourError}
            value={timeDetails.endHour}
            inputProps={{ style: { textAlign: 'center' } }}
            onChange={(event: any) => handleChangeHour(event, 'end')}
            onBlur={(event) => handleFormat(event, 'endHour')}
          />
          <Typography>:</Typography>
          <StyledTextField
            error={endMinError}
            value={timeDetails.endMin}
            inputProps={{ style: { textAlign: 'center' } }}
            onChange={(event: any) => handleChangeMin(event, 'end')}
            onBlur={(event) => handleFormat(event, 'endMin')}
          />
        </StyledInnerBox>
        <TimeSwitch checked={endTimeChecked} onChange={handleChangeEndTime} />
      </StyledOuterBox>
      {/* ------------------------------------------------------------------------------------------------- */}
      <StyledTypography paddingTop={4}>
        {policyBuilderMessages.TIME_PICKER.TIME_ZONE}
      </StyledTypography>

      <StyledOuterBox>
        <Dropdown
            sx={{
              input: { color: theme.palette.text.disabled, width: theme.spacing(72) },
              '& .MuiOutlinedInput-root': {
                padding: '2px !important',
                maxWidth: theme.spacing(68)
              }

          }}
            name="timezone"
            value={selectedTimezone}
            options={ACCOUNT_SETTING_TIMEZONE_OPTIONS}
            handleDropdownChange={handleTimeZoneChange}
          />
      </StyledOuterBox>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="right"
        paddingTop={4}
        columnGap={2}
      >
        <Button variant="text" onClick={handleCancelClick}>
          {BUTTON_LABEL_CONSTANTS.CANCEL_BUTTON_LABEL}
        </Button>
        <Button variant="contained" onClick={handleOkClick}>
          {BUTTON_LABEL_CONSTANTS.SAVE_BUTTON_LABEL}
        </Button>
      </Box>
      </Box>
    </Box>
  );
};

export default PolicyBuilderTimePicker;
