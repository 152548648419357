import { Grid, Box, Typography } from '@mui/material';
import React from 'react';
import { GREY_OUT_OVERVIEW_ITEMS } from '../../../core-utils/constants';
import { getDashboardOverviewVariation } from '../../../core-utils/Helper/helper';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import IconFromSvg from '../../atoms/IconFromSvg';
import { IOverviewUsersAndApps } from '../OverviewUsersAndApps';

const boxStyle = {
  width: theme.spacing(10),
  height: theme.spacing(10),
  backgroundColor: EXTRA_COLORS.alphaPrimary,
  borderRadius: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const StatsOverviewItem = ({
  overviewItem,
}: {
  overviewItem: IOverviewUsersAndApps;
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2.5}
      width="23%"
      height="100%"
      sx={{
        mt: 3,
        opacity: GREY_OUT_OVERVIEW_ITEMS.includes(overviewItem.name)
          ? EXTRA_COLORS.greyOut
          : EXTRA_COLORS.greyOutDefault,
      }}
    >
      <Grid item container justifyContent="center">
        <Box sx={boxStyle}>
          <IconFromSvg
            width={26}
            height={26}
            path={overviewItem.itemIcon}
            alt="overview-icon"
          />
        </Box>
      </Grid>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Typography sx={{ color: theme.palette.primary.main }} variant="h1">
            {overviewItem.count}
          </Typography>
        </Grid>
        {overviewItem.overviewType && (
          <Grid item sx={{ marginTop: theme.spacing(5) }}>
            <IconFromSvg
              path={
                getDashboardOverviewVariation(
                  overviewItem.variationCount,
                  overviewItem.overviewType,
                ).icon
              }
              alt="variation-icon"
            />
          </Grid>
        )}
        {overviewItem.variationCount && (
          <Grid item>
            <Typography
              sx={{
                paddingTop: theme.spacing(4.5),
                color:
                  overviewItem.overviewType === 'neutral'
                    ? theme.palette.primary.main
                    : overviewItem.overviewType === 'positive'
                    ? theme.palette.success.main
                    : theme.palette.error.main,
              }}
              variant="body2"
            >
              {Math.abs(overviewItem.variationCount)}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item container justifyContent="center" direction="column">
        {overviewItem.uploadOrDownloadInBytes && (
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.disabled, textAlign: 'center' }}
          >{`${overviewItem.uploadOrDownloadInBytes} bytes`}</Typography>
        )}
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.disabled, textAlign: 'center' }}
        >
          {overviewItem.name}
        </Typography>
      </Grid>
    </Box>
  );
};

export default StatsOverviewItem;
