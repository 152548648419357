import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  List,
  ListItemIcon,
  ListItemText,
  ListItem,
  Tooltip as MUITooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  useTheme,
} from '@mui/material';
import { ITooltipValidationParams } from '../../../core-utils/constants';
import GreenCheckedSvg from '../../../../public/assets/green-checked.svg';
import EmptyCircleSvg from '../../../../public/assets/empty-circle.svg';
import IconFromSvg from '../../atoms/IconFromSvg';

interface ITooltipForValidationProps {
  children: JSX.Element;
  validationItems: ITooltipValidationParams[];
  heading: string;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: `2px solid ${theme.palette.grey[400]}`,
      borderRadius: theme.spacing(0.5),
    },
    boxShadow: `${theme.spacing(0)} ${theme.spacing(5)} ${theme.spacing(
      6,
    )} rgba(0, 0, 0, 0.04)`,
    color: theme.palette.common.white,
    fontSize: 32,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.spacing(1.25),
    width: theme.spacing(64.2),
    boxShadow: `${theme.spacing(0)} ${theme.spacing(5)} ${theme.spacing(
      6,
    )} rgba(0, 0, 0, 0.04)`,
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    marginRight: theme.spacing(117.5),
    marginLeft: theme.spacing(2.5),
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  ...theme?.typography?.subtitle2,
  color: theme.palette.text.primary,
  paddingTop: theme?.spacing(4),
  paddingLeft: theme?.spacing(4),
}));

const TooltipForValidation = ({
  children,
  validationItems,
  heading,
}: ITooltipForValidationProps) => {
  const theme = useTheme();

  return (
    <StyledTooltip
      arrow
      placement="bottom-start"
      disableHoverListener
      title={
        <>
          <StyledTypography>{heading}</StyledTypography>
          <List sx={{ pl: 4 }}>
            {validationItems.map((parameter: ITooltipValidationParams) => (
              <ListItem disablePadding key={parameter?.id}>
                <ListItemIcon style={{ minWidth: theme.spacing(5) }}>
                  {parameter?.satisfied ? (
                    <IconFromSvg path={GreenCheckedSvg} alt="checked" />
                  ) : (
                    <IconFromSvg path={EmptyCircleSvg} alt="unchecked" />
                  )}
                </ListItemIcon>
                <ListItemText
                  sx={{ color: theme.palette.text.disabled }}
                  primaryTypographyProps={{ variant: 'caption' }}
                  primary={parameter?.name}
                />
              </ListItem>
            ))}
          </List>
        </>
      }
    >
      {children}
    </StyledTooltip>
  );
};

export default TooltipForValidation;
