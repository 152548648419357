import { Divider, Grid, Typography, Box } from '@mui/material';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  EMPTY_STATE_ICONS,
  getColorsForBars,
  IAppsAccessedMostWidgetProps,
} from '../../../core-utils/constants';
import dashboardMessages from '../../../core-utils/messages/dashboard';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import IconFromSvg from '../../atoms/IconFromSvg';
import EmptyState from '../EmptyState';

const style = {
  width: '100%',
  height: theme.spacing(135.5),
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[300]}`,
  backgroundColor: theme.palette.common.white,
};

const CustomisedIcon = (props: any) => {
  const { x, value, tab } = props;
  return (
    <svg>
      <foreignObject
        x={tab === 'user' ? x + 12 : x}
        y={353}
        width={100}
        height={200}
      >
        <Box
          sx={{
            width: 38,
            height: 38,
            backgroundColor: theme.palette.grey[300],
            borderRadius: '50%',
          }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <IconFromSvg path={value} alt="app-icon" width={20} />
        </Box>
      </foreignObject>
    </svg>
  );
};

const CustomisedAxisTick = (props: any) => {
  const { x, y, labels, index } = props;
  const requiredLabels = labels();
  return (
    <svg>
      <foreignObject x={x - 18} y={y} width={75} height={25}>
        <Typography
          variant="caption"
          sx={{ color: theme.palette.text.secondary }}
        >
          {requiredLabels[index]}
        </Typography>
      </foreignObject>
    </svg>
  );
};

const AppsAccessedMostWidget = ({
  appsAccessedData,
  tabContext,
}: {
  appsAccessedData: IAppsAccessedMostWidgetProps[];
  tabContext?: 'user' | 'dashboard';
}) => {
  const getRequiredLabels = () => {
    const labels: string[] = [];
    appsAccessedData.forEach((app) => {
      const contentTrimmed =
        app.appName.length > 10
          ? app.appName.substring(0, 10) + '...'
          : app.appName;
      labels.push(contentTrimmed);
    });
    return labels;
  };

  const getMarginLeftPropertyForEmptyState = (
    context: 'user' | 'dashboard' | undefined,
  ) => {
    return context === 'user' ? '40%' : '20%';
  };

  const getBarSizeForBarChart = (context: 'user' | 'dashboard' | undefined) => {
    return context === 'user' ? 65 : 37;
  };

  return (
    <React.Fragment>
      <Grid container sx={style} flexDirection="column">
        <Grid
          item
          sx={{ paddingLeft: theme.spacing(6), paddingTop: theme.spacing(4) }}
        >
          <Typography variant="body1">
            {dashboardMessages.APPS_ACCESSED_WIDGET_MESSAGE}
          </Typography>
        </Grid>
        <Divider sx={{ paddingTop: theme.spacing(4) }} light />
        <Grid
          item
          container
          sx={{ paddingTop: theme.spacing(5), paddingLeft: theme.spacing(1) }}
        >
          {appsAccessedData.length === 0 ? (
            <Grid
              item
              container
              sx={{
                mt: '13%',
                ml: getMarginLeftPropertyForEmptyState(tabContext),
              }}
            >
              <EmptyState
                content={dashboardMessages.NO_INFORMATION_MESSAGE}
                icon={EMPTY_STATE_ICONS.APPS_ACCESSED_ICON}
                width={250}
                height={250}
              />
            </Grid>
          ) : (
            <ResponsiveContainer width="98%" height={420}>
              <BarChart
                barSize={getBarSizeForBarChart(tabContext)}
                data={appsAccessedData}
                margin={{ top: 20, right: 10, bottom: 10 }}
              >
                <CartesianGrid
                  vertical={false}
                  stroke={EXTRA_COLORS.whiteSmoke}
                />
                <XAxis
                  dataKey="appName"
                  axisLine={{ stroke: theme.palette.grey[500] }}
                  tickLine={false}
                  fontSize={13}
                  dy={17}
                  interval={0}
                  tick={<CustomisedAxisTick labels={getRequiredLabels} />}
                />
                <YAxis
                  type="number"
                  dataKey="accessCount"
                  tickCount={11}
                  tick={{ fill: theme.palette.text.disabled }}
                  axisLine={{ stroke: theme.palette.grey[500] }}
                  tickLine={false}
                  allowDecimals={false}
                />
                <Tooltip cursor={false} />
                <Bar
                  dataKey="accessCount"
                  radius={[2, 2, 0, 0]}
                  animationDuration={1600}
                >
                  {appsAccessedData.map((app, index) => {
                    return (
                      <Cell
                        key={app.appName}
                        fill={getColorsForBars[index].color}
                      />
                    );
                  })}
                  <LabelList
                    dataKey="thumbnail"
                    position="insideBottom"
                    content={<CustomisedIcon tab={tabContext} />}
                  />
                  <LabelList
                    dataKey="accessCount"
                    fill={theme.palette.primary.main}
                    position="top"
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AppsAccessedMostWidget;
