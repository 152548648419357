import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  BoxProps,
  Stack,
  IconButton,
  Typography,
  Slider,
} from '@mui/material';
import Guacamole from './guacamole-common.js';
import IconFromSvg from '../../atoms/IconFromSvg';
import playIcon from '../../../../public/assets/play.svg';
import pauseIcon from '../../../../public/assets/pause.svg';
import fullScreenIcon from '../../../../public/assets/full-screen.svg';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import useFullscreen, { useWindowSize } from '../../../core-utils/Hooks/index.hook';
import { getAuthToken } from '../../../core-utils/Helper/helper';

const ViolationTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow placement="top" classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1.5),
    //zIndex: 10,
  },
}));

const getColorOfMark = (type: string) => {
  switch (type) {
    case 'critical':
      return theme.palette.error.main;
    case 'high':
      return EXTRA_COLORS.orange;
    case 'medium':
      return theme.palette.warning.light;
    default:
      return theme.palette.error.main;
  }
};

interface MarkProps extends BoxProps {
  type: string;
  onClick: () => void;
}

const Mark = React.forwardRef((props: MarkProps, ref: any) => {
  return (
    <Box
      {...props}
      sx={{
        ...props.sx,
        display: 'inline-block',
        height: theme.spacing(1.75),
        width: theme.spacing(1.75),
        backgroundColor: getColorOfMark(props.type),
        borderRadius: '50%',
        zIndex: 100,
        mt: '1px',
        cursor: 'pointer',
      }}
      onClick={props.onClick}
      ref={ref}
    ></Box>
  );
});

type TooltipMessageComponentProps = {
  heading: string;
  content: string;
};

const ToolTipMessageComponent = ({
  heading,
  content,
}: TooltipMessageComponentProps) => {
  return (
    <Stack justifyContent="flex-start" spacing={2} sx={{ p: 2, pb: 8 }}>
      <Typography variant="subtitle2" color="textSecondary">
        {heading}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        {content}
      </Typography>
    </Stack>
  );
};

interface ISessionRecordingPlayer {
  filePath: string;
  onDurationChanged?: (millis: number) => void;
  duration?: number;
  marks?: {
    header: string;
    description: string;
    type: string;
    time: number;
  }[];
}

const SessionRecordingPlayer = ({
  filePath,
  marks,
  onDurationChanged,
  ...rest
}: ISessionRecordingPlayer) => {
  const [value, setValue] = useState(0.0);
  const [recording, setRecording] = useState<any>(null);
  const [play, setPlay] = useState(false);
  const [duration, setDuration] = useState(rest.duration ?? 0);
  const [hover, setHover] = useState(false);
  const [width, setWidth] = useState(500);
  const [fileNotFound, setFileNotFound] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onSeek = (newValue: number) => {
    setValue(newValue);
  };

  const playerContainer = useRef<any>();
  const outerPlayerContainer = useRef<any>();

  const [isFullScreenAvailable, isFullScreen, setFullScreen] = useFullscreen(
    outerPlayerContainer,
  );

  useEffect(() => {
    const tunnel: any = new Guacamole.StaticHTTPTunnel(filePath, false, {'token': getAuthToken()});
    const recording = new Guacamole.SessionRecording(tunnel);
    const recordingDisplay = recording.getDisplay();

    const displayElement = recordingDisplay.getElement();
    playerContainer?.current?.appendChild(displayElement);
    recording.connect('');
    setRecording(recording);

    // Fit display within containing div
    recordingDisplay.onresize = function displayResized(
      width: number,
      height: number,
    ) {
      // Do not scale if display has no width
      if (!width) return;

      //update current width state of the player
      setWidth(playerContainer?.current?.clientWidth - 16);
      // Scale display to fit width of container
      recordingDisplay.scale((playerContainer?.current?.clientWidth - 16) / width);
    };

    const resizeDisplay = function displayResized() {
      const currentWidth = playerContainer?.current?.clientWidth;
      setWidth(currentWidth);
      // Scale display to fit width of container
      const displayWidth = currentWidth / recordingDisplay.getWidth();
      recordingDisplay.scale(displayWidth);
    };

    new ResizeObserver(resizeDisplay).observe(playerContainer.current);

    window.onresize = function displayContainerResized() {
      // Do not scale if display has no width
      if (!width) return;
      setWidth(playerContainer?.current?.clientWidth);
      recordingDisplay.scale(
        playerContainer?.current?.clientWidth / recordingDisplay.getWidth(),
      );
    };

    recording.onplay = function () {
      setPlay(true);
    };

    recording.onpause = function () {
      setPlay(false);
    };

    // Update slider and status when playback position changes
    recording.onseek = function positionChanged(millis: number) {
      setValue(millis);
    };

    // Update slider and status when duration changes
    recording.onprogress = function durationChanged(millis: number) {
      //setDuration(millis);
      //   //onDurationChanged?.(millis);
    };
  }, [filePath]);

  useEffect(() => {
    setWidth(playerContainer?.current?.clientWidth);
  }, []);

  const onPlayPause = () => {
    if (play) {
      setPlay(false);
      recording.pause();
    } else {
      setPlay(true);
      recording.play();
    }
  };

  var zeroPad = function zeroPad(num: number, minLength: number) {
    // Convert provided number to string
    var str = num.toString();

    // Add leading zeroes until string is long enough
    while (str.length < minLength) str = '0' + str;

    return str;
  };

  var formatTime = function formatTime(millis: number) {
    // Calculate total number of whole seconds
    var totalSeconds = Math.floor(millis / 1000);

    // Split into seconds and minutes
    var seconds = totalSeconds % 60;
    var minutes = Math.floor(totalSeconds / 60);

    // Format seconds and minutes as MM:SS
    return zeroPad(minutes, 2) + ':' + zeroPad(seconds, 2);
  };

  const getDuration = (min: number, max: number) => {
    return formatTime(max - min);
  };

  const getTimeElapsed = (value: number) => {
    return formatTime(value);
  };

  const sliderPositionChanged = (event: any) => {
    recording.seek(event.target.value);
    setValue(event.target.value);
  };

  const handleEscape = () => {
    document.exitFullscreen()
  }

  const scaleFactor = Math.round(100 * (width - 32) / width);

  return (
    <Box
      ref={outerPlayerContainer}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      sx={{
        borderRadius: theme.spacing(3),
        backgroundColor: 'rgb(231 231 231)',
      }}
      className="rounded-lg shadow-md overflow-hidden"
    >
      <Box ref={playerContainer} width="inherit" onClick={onPlayPause}>
      </Box>
      <Box
        className="sliderClass"
        sx={{ backgroundColor: 'rgb(231 231 231)'}}
      >
        <Stack sx={{ position: 'relative' }} >
          <Stack
            className={`markers scale-[${scaleFactor}]`}
            sx={{ position: 'absolute', mt: theme.spacing(1.5), width: width }}
            direction="row"
          >
            {marks &&
              marks.map((mark) => (
                <>
                  <ViolationTooltip
                    placement="top"
                    title={
                      <ToolTipMessageComponent
                        heading={mark.header}
                        content={mark.description}
                      />
                    }
                  >
                    <Mark
                      key={mark.time}
                      sx={{
                        position: 'absolute',
                        ml: ((mark.time / duration) * width) / 4,
                      }}
                      type={mark.type}
                      onClick={() => {
                        setValue(mark.time);
                        recording.seek(mark.time);
                      }}
                    />
                  </ViolationTooltip>
                </>
              ))}
          </Stack>
          <Slider
            className={`slider scale-[${scaleFactor}]`}
            value={value}
            max={duration}
            min={0}
            onChange={sliderPositionChanged}
            sx={(theme) => ({
              width: width,
              height: theme.spacing(0.75),
              p: 0,
              m: 0,
              mt: theme.spacing(2),
              '& .MuiSlider-track': {
                border: 'none',
                borderRadius: 0,
                backgroundColor: '#3b82f680',
              },
              '& .MuiSlider-rail': {
                //opacity: 0.5,
                backgroundColor: '#ffffff',
                borderRadius: 0,
              },
              '& .MuiSlider-thumb': {
                height: 10,
                width: 10,
                backgroundColor: EXTRA_COLORS.accentBlue_300,
              },
            })}
          ></Slider>
          <Stack
            className="controls"
            sx={{
              width: "100%",
              padding: 0,
              m: 0,
            }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" spacing={4} alignItems="center">
              <IconButton onClick={onPlayPause}>
                <IconFromSvg
                  width={24}
                  height={24}
                  path={play ? pauseIcon : playIcon}
                  alt=""
                />
              </IconButton>

              <Typography className="text-gray-900" variant="body2">
                {getTimeElapsed(value) + '/' + getDuration(0, duration)}
              </Typography>
            </Stack>
            {isFullScreenAvailable && (
              <IconButton
                onClick={() => {
                  isFullScreen ? handleEscape() : setFullScreen()
                }}
              >
                <IconFromSvg
                  width={24}
                  height={24}
                  path={fullScreenIcon}
                  alt=""
                  className="text-gray-900"
                ></IconFromSvg>
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default SessionRecordingPlayer;
