import { Grid } from '@mui/material';
import React from 'react';
import {
  APPLICATION_TYPE_OPTIONS,
  GLOBAL_LABELS,
  ITooltipValidationParams,
} from '../../../core-utils/constants';
import messages from '../../../core-utils/messages';
import TextField from '../../atoms/TextField';
import Dropdown, { IDropdownOptionProps } from '../Dropdown';
import PasswordTextField from '../PasswordTextField';
import CustomDialog from '../../organisms/Dialog';
import TooltipForValidation from '../TooltipForValidation';

const { PASSWORD, PLACEHOLDER } = messages.EDIT_USER;
const { SAVE, CANCEL } = GLOBAL_LABELS;

interface IEditDetailsModalProps {
  open: boolean;
  heading: string;
  isSaveDisabled: boolean;
  textFieldPlaceholder?: string;
  textFieldLabel?: string;
  textFieldValue?: string;
  textFieldError?: string;
  fieldType: 'TEXTFIELD' | 'PASSWORD' | 'DROPDOWN';
  dropdownLabel?: string;
  dropdownOptions?: Array<IDropdownOptionProps>;
  dropdownValue?: any;
  password?: string;
  confirmPassword?: string;
  passwordTooltipParams?: ITooltipValidationParams[];
  passwordError?: string;
  confirmPasswordError?: string;
  name?: string;
  onClose: () => void;
  handleFieldChange?: (name: string | undefined, event: any) => void;
  handleSaveClick: (name: string | undefined) => void;
  handleValidation?: (name: string | undefined) => void;
  handlePasswordChange?: (name: string | undefined, event: any) => void;
  handleDropdownChange?: (event: any, value: any) => void;
  isRequired?: boolean;
}

// TODO: Ad error handling
const EditDetailsModal = ({
  open,
  fieldType,
  textFieldValue,
  textFieldError,
  isSaveDisabled,
  heading,
  textFieldLabel,
  textFieldPlaceholder,
  password,
  confirmPassword,
  passwordTooltipParams,
  passwordError = '',
  confirmPasswordError = '',
  dropdownLabel = '',
  dropdownOptions = [],
  dropdownValue,
  name,
  handleSaveClick,
  handleFieldChange,
  handlePasswordChange,
  handleDropdownChange,
  handleValidation,
  onClose,
  isRequired = true,
}: IEditDetailsModalProps) => {
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      isSaveDisabled={isSaveDisabled}
      heading={heading}
      buttonLabelOne={CANCEL}
      buttonLabelTwo={SAVE}
      handleSaveClick={() => handleSaveClick(name)}
    >
      <Grid item>
        {fieldType === 'TEXTFIELD' && (
          <TextField
            label={textFieldLabel}
            required={isRequired}
            value={textFieldValue}
            handleChange={(event) =>
              handleFieldChange && handleFieldChange(name, event)
            }
            error={textFieldError !== ''}
            helperText={textFieldError}
            testId={textFieldLabel}
            onBlur={() => handleValidation && handleValidation(name)}
            placeholder={textFieldPlaceholder}
          />
        )}
        {fieldType === 'PASSWORD' && (
          <Grid container direction="column" spacing={5}>
            <Grid item>
              <TooltipForValidation
                validationItems={passwordTooltipParams ?? []}
                heading={messages.PASSWORD_PARAMETERS_MESSAGE}
              >
                <PasswordTextField
                  name={PASSWORD.LABEL_1}
                  label={PASSWORD.LABEL_1}
                  value={password}
                  testId={PASSWORD.LABEL_1}
                  placeholder={PLACEHOLDER.PASSWORD}
                  required
                  error={passwordError !== ''}
                  helperText={passwordError}
                  handleChange={(event) =>
                    handlePasswordChange &&
                    handlePasswordChange(PASSWORD.LABEL_1, event)
                  }
                  onBlur={() =>
                    handleValidation && handleValidation(PASSWORD.LABEL_1)
                  }
                />
              </TooltipForValidation>
            </Grid>
            <Grid item>
              <PasswordTextField
                name={PASSWORD.LABEL_2}
                label={PASSWORD.LABEL_2}
                value={confirmPassword}
                testId={PASSWORD.LABEL_2}
                placeholder={PLACEHOLDER.CONFIRM_PASSWORD}
                required
                error={confirmPasswordError !== ''}
                helperText={confirmPasswordError}
                handleChange={(event) =>
                  handlePasswordChange &&
                  handlePasswordChange(PASSWORD.LABEL_2, event)
                }
                onBlur={() =>
                  handleValidation && handleValidation(PASSWORD.LABEL_2)
                }
              />
            </Grid>
          </Grid>
        )}
        {fieldType === 'DROPDOWN' && (
          <Dropdown
            options={dropdownOptions}
            disableOptions={(option: any) =>
              option.name === APPLICATION_TYPE_OPTIONS[2].name ||
              option.name === APPLICATION_TYPE_OPTIONS[3].name ||
              option.name === APPLICATION_TYPE_OPTIONS[4].name
            }
            value={dropdownValue}
            label={dropdownLabel}
            required
            handleDropdownChange={handleDropdownChange}
            onBlur={() => handleValidation && handleValidation(name)}
          />
        )}
      </Grid>
    </CustomDialog>
  );
};

export default EditDetailsModal;
