import {
  IAddPoliciesToGroupOrUserRequestBody,
  IListPolicyResponse,
} from '../../../api-service/policy-service/PolicyService';
import UserGroupService, {
  IListUserGroupsPoliciesResponse,
} from '../../../api-service/user-group-service/UserGroupService';
import { IListUserResponse } from '../../../api-service/user-service/UserService';
import { createOperationRequest } from '../../../core-utils/Helper/helper';
import { OperationTypes } from '../../../core-utils/Interfaces/entityInterfaces';
import { IPoliciesTableRowProps } from '../../molecules/PoliciesTableRow';
import { IUserTableRow } from '../../molecules/UserTableRow';
import {
  removeAppFromUserOrUserGroup,
  removeCreds,
  refreshTabdata,
} from '../UserDetailsPage/index.hook';
import ApplicationService from '../../../api-service/application-service/ApplicationService';
import { useAppDispatch } from 'src/Redux/Store/store';
import { sideNavActions } from 'src/Redux/Reducers/sideNav';

export const constructListUsers = async (
  userslist: IListUserResponse[],
  groupOrAppId: string,
  getUsersOfUserGroupOrApp: any,
  popUpUser: any,
  tabContext: 'usergroup' | 'app',
  appName?: string,
  userGroupUsersList?: IListUserResponse[],
  getAppCredForUser?: boolean,
) => {
  let requiredTableRows: IUserTableRow[] = [];
  if (tabContext === 'usergroup') {
    requiredTableRows = await getUsers(
      userslist,
      groupOrAppId,
      getUsersOfUserGroupOrApp,
      popUpUser,
      tabContext,
      true,
      appName,
    );
  } else {
    const showCloseTableRows = await getUsers(
      userslist,
      groupOrAppId,
      getUsersOfUserGroupOrApp,
      popUpUser,
      tabContext,
      true,
      appName,
      getAppCredForUser, // This is for showing applicationCreds
    );
    const hideCloseTableRows =
      userGroupUsersList &&
      (await getUsers(
        userGroupUsersList,
        groupOrAppId,
        getUsersOfUserGroupOrApp,
        popUpUser,
        tabContext,
        false,
        appName,
        getAppCredForUser, // This is for showing applicationCreds
      ));
    requiredTableRows = hideCloseTableRows
      ? [...showCloseTableRows, ...hideCloseTableRows]
      : [];
  }
  requiredTableRows.sort(function (a, b) {
    if (a.name === b.name) {
      return 0;
    } else if (a.name === null) {
      return 1;
    } else if (b.name === null) {
      return -1;
    } else {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    }
  });
  return requiredTableRows;
};

export const getUsers = async (
  userslist: IListUserResponse[],
  groupOrAppId: string,
  getUsersOfUserGroupOrApp: any,
  popUpUser: any,
  tabContext: 'usergroup' | 'app',
  showClose: boolean,
  appName?: string,
  showAppCredentialsInfo?: boolean,
) => {
  const userTableRows: IUserTableRow[] = [];
  const promises = userslist.map(async (user) => {
    const isUserDisabled = user['attributes']['disabled'];
    userTableRows.push({
      name: user['attributes']['guac-full-name'],
      username: user.username,
      email: user['attributes']['guac-email-address'],
      status: isUserDisabled ? 'Inactive' : 'Active',
      userUnderUserGroupsOrAppsTab: true,
      underTabItems: true,
      showClose: showClose,
      usergroups: userGroupsForUser(user['groups']),
      handleTableRowClick: () => {
        popUpUser(user.username);
      },
      handleClickMore: () => {
        tabContext === 'usergroup'
          ? removeUserFromUserGroup(
            user.username,
            groupOrAppId,
            getUsersOfUserGroupOrApp,
          )
          : removeAppFromUserOrUserGroup(
            groupOrAppId,
            user.username,
            'user',
            getUsersOfUserGroupOrApp,
          );
      },
      tabItemName: tabContext === 'usergroup' ? groupOrAppId : appName,
      context: tabContext,
      showAppCredentialsInfo: showAppCredentialsInfo,
      isCredGenerated: await getCredsDetailsByAppIdAndUid(
        groupOrAppId,
        user['username'],
      ),
      generateCreds: () => {
        refreshTabdata(
          groupOrAppId,
          user['username'],
          getUsersOfUserGroupOrApp,
        );
      },
      handleRemoveCreds: () => {
        removeCreds(groupOrAppId, user['username'], getUsersOfUserGroupOrApp);
      },
    });
  });
  await Promise.all(promises);
  return userTableRows;
};


const removeUserFromUserGroup = async (
  username: string,
  groupId: string,
  getUsersOfUserGroup: any,
) => {
  const requestBody = [createOperationRequest(OperationTypes.remove, groupId)];
  try {
    await UserGroupService.removeUserFromUserGroup(username, requestBody);
  } catch (error) {
    console.warn(error);
  } finally {
    getUsersOfUserGroup();
  }
};

export const userGroupsForUser = (groupsList: string[]) => {
  const requiredUserGroupList: any = [];
  Array.isArray(groupsList) && 
    groupsList.map((usergroup: any) => {
    requiredUserGroupList.push({ usergroupname: usergroup });
  });
  return requiredUserGroupList;
};

// Devang ADDED API Call for getting Cred Data based on APPID and UID
export const getCredsDetailsByAppIdAndUid = async (appId: string, uId: any) => {
  const userCredDetails: any = await ApplicationService.retriveCredsByUserIdAndAppId(
    appId,
    uId,
  );

  return userCredDetails.hasOwnProperty('username') &&
    userCredDetails.hasOwnProperty('password')
    ? true
    : false;
};

export const getUserGroupById = async (groupId: string) => {
  const userGroupByIdResponse = await UserGroupService.getUserGroupById(
    groupId,
  );

  //TODO : Will replace this with data from api response.
  const userGroupPayload = {
    name: userGroupByIdResponse?.identifier,
    numberOfUsers: userGroupByIdResponse?.users?.length || 0,
    createdBy: userGroupByIdResponse?.attributes?.['createdBy'] ?? '',
    createdOn: userGroupByIdResponse?.attributes?.['createdOn'] ?? '',
    // TODO: Hardcoding source because API does not provide it.
    source: userGroupByIdResponse?.attributes?.['sonet-source'] ?? 'local',
    description: userGroupByIdResponse?.attributes?.['description'] ?? '',
  };
  return userGroupPayload;
};

export const getPolicyTableRowsForUserGroup = (
  listPolicies: IListPolicyResponse[],
  userGroupName: string,
  getPoliciesOfUserGroup: any,
) => {
  const resultPolicyTableRows: IPoliciesTableRowProps[] = [];
  listPolicies.forEach((policy) => {
    resultPolicyTableRows.push({
      contextName: userGroupName,
      id: policy.id,
      policyName: policy.policyName,
      createdBy: policy.createdBy,
      showClose: true,
      dateTime: policy.lastPolicyHitTime ?? '',
      applications: policy.applications ? policy.applications : [],
      handleRemove: () => {
        removePolicyForUserGroup(
          userGroupName,
          policy.id,
          getPoliciesOfUserGroup,
        );
      },
      status:
        policy.policyStatus.toLowerCase() === 'active' ? 'Active' : 'Inactive',
      underTab: 'usergroup',
      hideStatus: true,
    });
  });
  resultPolicyTableRows.sort(function (a, b) {
    if (a.policyName === b.policyName) {
      return 0;
    } else if (a.policyName === null) {
      return 1;
    } else if (b.policyName === null) {
      return -1;
    } else {
      return a.policyName.toLowerCase() < b.policyName.toLowerCase() ? -1 : 1;
    }
  });
  return resultPolicyTableRows;
};

export const removePolicyForUserGroup = async (
  userGroupName: string,
  policyId: string | number,
  getPoliciesOfUserGroup: any,
) => {
  const requestBody: IAddPoliciesToGroupOrUserRequestBody[] = [
    createOperationRequest(OperationTypes.remove, userGroupName, '/'),
  ];
  try {
    await UserGroupService.removePolicyFromUserGroup(policyId, requestBody);
  } catch (error) {
    console.warn(error);
  } finally {
    getPoliciesOfUserGroup();
  }
};
