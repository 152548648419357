import React, { useEffect, useState } from 'react';
import Tabs, { ITabProps } from '../Tabs';
import UserDetailsTabItem from '../UserDetailsTabItem';
import Button from '../../atoms/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import messages from '../../../core-utils/messages';
import {
  IUserDetailsType,
  USER_DETAILS_ACTIONS_MENU,
  IUserGroupsAndAppsTableRow,
  GLOBAL_LABELS,
} from '../../../core-utils/constants';
import { Grid, Paper } from '@mui/material';
import AvatarNameCard from '../../molecules/AvatarNameCard';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import { getAvatarName } from '../../../core-utils/Helper/helper';
import DeleteOrLogoutUserPopUp from '../../molecules/DeleteUserPopUp';
import UserService from '../../../api-service/user-service/UserService';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../core-utils/routes';
import AppsOfUserTabItem from '../AppsOfUserTabItem';
import GroupListUserDetails from '../GroupListUserDetails';
import LoadingAnimation from '../../atoms/LoadingAnimation';
import PoliciesUserTabItem from '../PoliciesUserTabItem';
import { IPoliciesTableRowProps } from '../../molecules/PoliciesTableRow';
import UserStatsTabItem from '../UserStatsTabItem';
import { ApplicationSelectCardProps } from '../../molecules/ApplicationSelectCard';
import AppService from '../../../api-service/app-service/AppService';
import UsersCredentialsTabItem from '../UsersCredentialsTabItem';

interface IUserDetailsDashboardProps {
  username: string;
  userDetails: IUserDetailsType;
  appDetails?: IUserGroupsAndAppsTableRow[];
  userGroups: any;
  policies: IPoliciesTableRowProps[];
  updateUserGroupsOfUser?: any;
  updateAppsOfUser?: any;
  updatePoliciesOfUser?: any;
  getUserDetails?: any;
  loading?: boolean;
}

const UserDetailsDashboard = ({
  username,
  userDetails,
  appDetails,
  userGroups,
  policies,
  updateUserGroupsOfUser,
  updateAppsOfUser,
  updatePoliciesOfUser,
  getUserDetails,
  loading,
}: IUserDetailsDashboardProps) => {
  const {
    TAB_NAMES: { USERS, USER_GROUPS, APPLICATIONS, POLICIES, STATS },
    BACK_TO_USERS,
    ACTIONS,
  } = messages.USER_DETAILS;

  const [allApps, setAllApps] = useState<ApplicationSelectCardProps[]>([]);

  const [appDetailResponse, setAppDetailResponse] = useState<any>();

  const loadAllApps = async () => {
    if (appDetailResponse) {
      return appDetailResponse;
    }
    const appsResponse = await AppService.listApps();
    setAppDetailResponse(appsResponse);
    return appsResponse;
  };

  const tabs: Array<ITabProps> = [
    {
      tabId: 1,
      label: USERS,
      tabpanel: (
        <UserDetailsTabItem
          userDetails={userDetails}
          username={username}
          getUserDetails={getUserDetails}
        />
      ),
    },
    {
      tabId: 2,
      label: `${USER_GROUPS}(${userGroups?.length ?? 0})`,
      tabpanel: (
        <GroupListUserDetails
          username={username}
          userGroups={userGroups}
          updateUserGroupsOfUser={updateUserGroupsOfUser}
          updatePoliciesOfUser={updatePoliciesOfUser}
        />
      ),
    },
    {
      tabId: 3,
      label: `${APPLICATIONS}(${appDetails?.length ?? 0})`,
      tabpanel: (
        <AppsOfUserTabItem
          appDetails={appDetails}
          userId={username}
          nameOfUser={userDetails.name}
          updateAppsOfUser={updateAppsOfUser}
          loadAllApps={loadAllApps}
        />
      ),
    },
    {
      tabId: 4,
      label: `${POLICIES}(${policies?.length ?? 0})`,
      tabpanel: (
        <PoliciesUserTabItem
          policies={policies}
          nameOfUser={userDetails.name}
          userId={username}
          updatePoliciesOfUser={updatePoliciesOfUser}
        />
      ),
    },
    {
      tabId: 5,
      label: STATS,
      tabpanel: (
        <UserStatsTabItem
          userId={username}
          // userStatus={userDetails.userStatus}
        />
      ),
    },
    {
      tabId: 6,
      label: `${GLOBAL_LABELS.CREDENTIALS}`,
      tabpanel: <UsersCredentialsTabItem userId={username} userFullName={userDetails.name}/>,
    },
  ];

  const getRequiredTabs = () => {
    if (process.env.RELEASE && process.env.RELEASE === 'beta1R1') {
      return tabs.filter((data) => data.tabId !== 4 && data.tabId !== 5); //disabling policy screen  and stats for release 1
    }
    return tabs;
  };

  const history = useHistory();

  const handleBackRedirect = () => {
    history.replace(ROUTES.USERS);
  };

  const handleActionsClick = () => {
    // TODO: Delete and block user logic
  };

  const routeHistory = useHistory();

  const handleDeleteUser = async () => {
    try {
      await UserService.deleteUser(userDetails.id);
    } catch (error) {
      console.warn(error);
    }
    routeHistory.push(ROUTES.USERS);
  };

  const handleDeleteActionClick = () => {
    setShowDeleteModal(true);
  };

  const ACTIONS_MENU_ITEMS = [
    {
      ...USER_DETAILS_ACTIONS_MENU.delete,
      handleClick: handleDeleteActionClick,
    },
  ];

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  return (
    <Paper
      sx={{
        paddingTop: 8,
        height: '100%',
        backgroundColor: EXTRA_COLORS.bluishBackground,
        paddingLeft: theme.spacing(24.75),
        paddingRight: theme.spacing(25),
      }}
    >
      <Button
        variant="text"
        sx={{
          '&:hover': {
            ...theme.typography.body1,
            backgroundColor: 'unset',
            color: theme.palette.primary.main,
          },
          ...theme.typography.body2,
          color: theme.palette.text.secondary,
        }}
        startIcon={<ArrowBackIcon />}
        onClick={handleBackRedirect}
      >
        {BACK_TO_USERS}
      </Button>
      <Grid
        container
        pt={4}
        pb={4}
        justifyContent="space-between"
        direction="row"
      >
        <Grid item>
          <AvatarNameCard
            typographyVariant="h2"
            name={userDetails.name}
            avatarGroupData={[getAvatarName(userDetails.name)]}
          />
        </Grid>
        <Grid item>
          {/* <DropDownMenu
            menuItems={ACTIONS_MENU_ITEMS}
            itemForDropdownMenu={
              <Button
                variant="outlined"
                endIcon={<ExpandMoreIcon />}
                onClick={handleActionsClick}
              >
                {ACTIONS}
              </Button>
            }
          ></DropDownMenu> */}
          <DeleteOrLogoutUserPopUp
            headingText={messages.DELETE_USER_MESSAGE.HEADING}
            content={messages.DELETE_USER_MESSAGE.CONFIRM_MESSAGE.replace(
              '{user}',
              userDetails.name,
            )}
            open={showDeleteModal}
            handleClose={() => setShowDeleteModal(false)}
            onButtonClick={handleDeleteUser}
          />
        </Grid>
      </Grid>
      {loading && <LoadingAnimation />}
      <Tabs tabs={getRequiredTabs()} />
    </Paper>
  );
};

export default UserDetailsDashboard;
