import SonetLogoSvg from '@assets/sonet-logo.svg';
import MainFooter from '@molecules/MainFooter';
import { Box } from '@mui/material';
import UserLoginForm from '@organisms/UserLoginForm';
import { sideNavActions } from '@redux/Reducers/sideNav';
import store, { useAppDispatch, useAppSelector } from '@redux/Store/store';
import LoginService from '@service/login-service/LoginService';
import SettingsService from '@service/settings-service/SettingsService';
import UserService from '@service/user-service/UserService';
import { sessionActions } from '@src/Redux/Reducers/session';
import { userDetailsActions } from '@src/Redux/Reducers/userDetails';
import UserMfaLoginForm from '@src/_components/organisms/UserMfaLoginForm';
import { useAuth } from '@utils/Auth/index.hook';
import {
  getAuthToken,
  getFreshToolTipItemList,
  getSideNavEndItems,
  getSideNavItems,
  getUserId,
  getUserNameFromEmail,
  isAdminRole
} from '@utils/Helper/helper';
import {
  IDP_CONNECTION_ID,
  ITooltipValidationParams,
  PASSWORD_PARAMETERS,
  REGEX,
  ROLE,
  TOKEN
} from '@utils/constants';
import messages from '@utils/messages';
import mfaMessages from '@utils/messages/mfaLoginMessages';
import { ROUTES, useQuery } from '@utils/routes';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny, connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Config from '../../../../config/config';

enum LoginState {
  USERNAME,
  PASSWORD,
  MFA
}

const NO_OF_CLICKS_TO_EXIT_BLANK_LOGIN = 3;



const UserLoginPage = ({ token }: any) => {
  const history = useHistory();
  const location = useLocation();
  const { login } = useAuth();
  const dispatch = useAppDispatch();
  const headerData = useAppSelector(
    (state: RootStateOrAny) => state.header,
  );
  const queryParams = useQuery();
  const isSsoLoginFailed = queryParams.get('loginFailed');
  const referrer = queryParams.get('referrer');
  let blankScreenLogin = false;
  if (referrer && !window.location.hostname.includes(referrer)) {
    if (headerData?.entities?.idp_url) {
      blankScreenLogin = headerData?.entities?.idp_url.includes(referrer);
    } else {
      blankScreenLogin = true;
    }
  }

  const checkedPasswordParameters = getFreshToolTipItemList(
    PASSWORD_PARAMETERS,
  );

  const [validationItemList, setValidationItemList] = useState<
    Array<ITooltipValidationParams>
  >(checkedPasswordParameters);
  const [userLoginCredentials, setUserLoginCredentials] = useState({
    [messages.LOGIN_TEXT_FIELD_LABEL.USERNAME]: '',
    [messages.LOGIN_TEXT_FIELD_LABEL.PASSWORD]: '',
  });

  const [error, setError] = React.useState('');
  const [loginState, setLoginState] = useState<LoginState>(blankScreenLogin ? LoginState.PASSWORD : LoginState.USERNAME);
  const [totp, setTotp] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [effect, setEffect] = useState(false);
  const [showBlackScreen, setShowBlackScreen] = useState(!!blankScreenLogin);
  const [blackScreenClicks, setBlackScreenClicks] = useState(0);
  const [logo, setLogo] = useState<string>(SonetLogoSvg);

  const role = useAppSelector(
    (state: RootStateOrAny) => state?.token?.role,
  );

  const onBlackScreenClick = (event: any) => {
    if (!!blankScreenLogin) {
      if (showBlackScreen) {
        setBlackScreenClicks(blackScreenClicks + 1);
        if (blackScreenClicks === NO_OF_CLICKS_TO_EXIT_BLANK_LOGIN) {
          setShowBlackScreen(false);
        }
      }
    }
  }

  const handleTotpFieldChange = (event: any) => {
    setError('');
    setTotp(event.target.value);
  };

  const handleMfaValidation = () => {
    return totp.length === 6;
  };

  const handleVerifyMfaClick = async () => {
    if (handleMfaValidation()) {
      const verified = await UserService.authenticateTotp(
        getUserId() ?? '',
        totp,
      );
      if (verified === true) {
        await UserService.setTotpStatus();
        dispatch(sessionActions.setMfaVerficationDone());
        const searchParams = new URLSearchParams(location.search ?? '');
        const redirectAppId = searchParams.get("redirectApp");
        if (redirectAppId) {
          const redirectUrl = `${Config.BACKEND_BASE_URL}#/client/${redirectAppId}`;
          window.location.href = redirectUrl;
          return;
        }

        const redirectUrl = location.search.replace('?redirect=', '');
        if (redirectUrl && !redirectUrl.includes('/login') && !redirectUrl.includes('sr=1')) {
          redirectToUrl(redirectUrl);
        } else {
          history.replace(isAdminRole(role) ? ROUTES.DASHBOARD : ROUTES.MY_APPLICATIONS);
        }
      } else {
        setError('Verification code is incorrect');
      }
    }
  };

  const handleMfaCancelClick = () => {
    const token = getAuthToken();
    LoginService.deleteAuthToken(token)
      .then(() => {
        dispatch(sessionActions.clearSession());
        setLoginState(LoginState.USERNAME);
      })
  };

  const handleMfaKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleVerifyMfaClick();
    }
  };

  const fetchLogoDetails = async () => {
    const accountDetailRes = await SettingsService.getOrgSettings()
    if (accountDetailRes?.organizationLogo) {
      setLogo(accountDetailRes?.organizationLogo);
    }
  }

  const prevPath = history?.location?.pathname ?? '';

  useEffect(() => {
    const gotToken = token?.session?.authToken;

    if (gotToken) {
      localStorage.setItem(TOKEN, gotToken);
    } else {
      localStorage.removeItem(TOKEN);
    }

  }, [token?.session?.authToken]);

  useEffect(() => {
    isSsoLoginFailed && setError(messages.INVALID_LOGIN_CREDENTIALS);
    const gotToken = token?.session?.authToken;
    if (gotToken) {
      LoginService.deleteAuthToken(gotToken)
        .then(() => {
          dispatch(sessionActions.clearSession())
        });
    }
  }, [isSsoLoginFailed]);

  useEffect(() => {
    fetchLogoDetails();
  }, []);

  useEffect(() => {
    const referrer = document.referrer;
    const hostname = window.location.hostname;
    const skipRedirect = queryParams.get('sr');

    const orgData = headerData?.entities || {};

    const { sonet_protected_dashboard, idp_url, idp_name } = orgData;
    const isOkta = "okta" === idp_name?.toLowerCase();

    if (isOkta && !sonet_protected_dashboard) {
      if (!referrer && !skipRedirect) {
        if (idp_url) {
          window.location.href = idp_url;
        }
      }
    }
  }, []);

  const handleFirstTextFieldChange = (event: any) => {
    setError('');
    setUserLoginCredentials({
      ...userLoginCredentials,
      //[event.target.name]: getUserNameFromEmail(event.target.value),
      [event.target.name]: event.target.value,
    });
  };

  const handleSecondTextFieldChange = (event: any) => {
    setError('');
    setUserLoginCredentials({
      ...userLoginCredentials,
      [event.target.name]: event.target.value,
    });
    let resultItemList = JSON.parse(JSON.stringify(validationItemList));
    if (String(event.target.value).trim().length >= 6) {
      resultItemList[0].satisfied = true;
    } else {
      resultItemList[0].satisfied = false;
    }
    resultItemList[1].satisfied = REGEX.UPPERCASE.test(event.target.value);
    resultItemList[2].satisfied = REGEX.DIGIT.test(event.target.value);
    setValidationItemList(resultItemList);
  };

  const handleValidation = () => {
    let isValid = userLoginCredentials.Username
    if (loginState === LoginState.PASSWORD) {
      isValid = isValid && userLoginCredentials.Password;
    }
    if (!isValid) {
      setError(messages.INVALID_LOGIN_CREDENTIALS);
      setIsSubmitting(false);
    }
    return isValid;
  };

  const hasAdminInLogin = (username: String) => {
    //no longer required
    return false;
  };

  const isSSOEnabled = async () => {
    const username = store?.getState()?.token?.session?.username;
    const idpConnectionId = store?.getState()?.token?.session?.[IDP_CONNECTION_ID];
    if (username) {
      const userDetails = await UserService.getUserById(username);
      dispatch(userDetailsActions.setUserDetails(userDetails));
      const source = userDetails?.attributes?.['sonet-source'];
      const role = userDetails?.attributes?.['jobRole'];
      dispatch(sessionActions.setUserRole(role?.toLowerCase()));
      localStorage.setItem(ROLE, role?.toLowerCase());
      dispatch(sideNavActions.loadSideNavAppRoutes());
      if (!source || source?.toLowerCase() === 'local') {
        return [false, 0, role];
      }
      return [true, idpConnectionId, role];
    } else {
      throw ('Username is invalid!')
    }
  };

  const handleLoginClick = () => {
    setIsSubmitting(true);
    const firstLoggedIn = token?.firstTimeLogin;
    const validateFirstLogin = () => firstLoggedIn && firstLoggedIn === 'LOGIN';
    const searchParams = new URLSearchParams(location.search ?? '');
    const redirectAppId = searchParams.get("redirectApp");

    if (handleValidation()) {
      const redirect = async () => {
        try {
          const [ssoEnabled, appId, userRole] = await isSSOEnabled();
          dispatch(sessionActions.setSsoEnabled(ssoEnabled));
          if (ssoEnabled) {
            const authToken = store?.getState()?.token?.session?.authToken;
            localStorage.setItem('ssoUser', 'true');
            const base64encodedID = btoa([appId, 'c', 'postgresql'].join('\x00').toString());
            let url = `${Config.BACKEND_BASE_URL}#/client/${base64encodedID}?lang=en`;
            if (redirectAppId) {
              url = `${url}&redirectApp=${redirectAppId}`;
            }
            window.location.href = url;
            return;
          } else {
            setLoginState(LoginState.PASSWORD);
          }

          if (isAdminRole(userRole)) {
            const isFirstTimeLogin = await UserService.isAdminFirstLogin(getUserId() ?? '');
            dispatch(sessionActions.setFirstTimeLogin(isFirstTimeLogin));
            if (isFirstTimeLogin) {
              redirectToUrl(ROUTES.ONBOARDING)
            } else {
              setLoginState(LoginState.MFA);
            }
          } else {
            const isFirstTimeLogin = await UserService.isUserFirstLogin(getUserId() ?? '');
            dispatch(sessionActions.setFirstTimeLogin(isFirstTimeLogin));
            if (isFirstTimeLogin) {
              redirectToUrl(ROUTES.USER_ONBOARDING);
            } else {
              setLoginState(LoginState.MFA);
            }
          }
        } catch (error: any) {
          console.log(error);
          setError(error.message);
          setIsSubmitting(false);
        }
      };
      login(
        {
          username: hasAdminInLogin(userLoginCredentials.Username)
            ? getUserNameFromEmail(userLoginCredentials.Username)
            : userLoginCredentials.Username,
          password: loginState === LoginState.USERNAME ? 'NO-PASSWORD' : userLoginCredentials.Password,
        },
        async () => {
          setIsSubmitting(false);
          redirect();
        },
        (msg: string, type: string) => {
          if (loginState === LoginState.USERNAME && type === 'INVALID_CREDENTIALS') {
            setLoginState(LoginState.PASSWORD);
          } else {
            setError(msg);
          }
          setIsSubmitting(false);
        }
      );
    }
  };

  const redirectToUrl = (url: string) => {
    const applicationRoutes = [...getSideNavItems(), ...getSideNavEndItems()];
    // dispatch(sideNavActions.loadSideNavAppRoutes());
    const alteredRoute = url.split('/');
    if ([ROUTES.USER_GROUPS, ROUTES.USERS].includes(`/${alteredRoute[1]}`)) {
      const userRouteWithChilds = applicationRoutes.find(
        (route) => route.id === 2,
      );
      const selectedChild = userRouteWithChilds?.children?.find(
        (route: any) => route.routeOnClick?.trim() === `/${alteredRoute[1]}`,
      );
      dispatch(sideNavActions.setSelectedNestedRoute(selectedChild));
    } else {
      const redirectRoute = applicationRoutes.find(
        (route) => route.routeOnClick?.trim() === `/${alteredRoute[1]}`,
      );
      dispatch(sideNavActions.setSelected(redirectRoute?.id));
    }
    history.replace(url);
    history.go(0);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleLoginClick();
    }
  };

  const handleForgotPassword = () => {
    //TODO :
    //Handle the forgot password api
  };

  const renderDirectLink = () => {
    let url = "#";
    const searchParams = new URLSearchParams(location.search ?? '');
    const redirectAppId = searchParams.get("redirectApp");
    if (!redirectAppId) return null;

    const remoteUrlEncrypted = redirectAppId?.split('rurl=')?.[1];
    if (!remoteUrlEncrypted) return null;
    url = decodeURIComponent(atob(remoteUrlEncrypted));

    return (
      <div className="py-2 px-4 pb-16 w-full text-center fixed bottom-8  grid justify-center">
        <div
          className="relative px-2 max-w-xs group"
          onClick={() => {
            navigator.clipboard.writeText(url);
            setEffect(true);
            setTimeout(() => setEffect(false), 500);
          }}
        >
          <p className="group-hover:cursor-pointer duration-300 transition-transform ease-in-out px-2">Copy the <span className="no-underline group-hover:text-blue-800 group-hover:underline text-sonet" > app link</span>.
          </p>
          {effect &&
            <p className=" absolute right-0 top-8 ease-in-out transition-all duration-300"> <span className={` bg-gray-900 py-0.5 rounded-full px-2 text-white text-xs font-light`}>link copied!</span></p>}
        </div>
      </div>
    )
  }

  const getPrimaryButtonText = () => {
    const loginStateToPrimaryButtonLabelMap = {
      [LoginState.USERNAME]: messages.LOGIN_BUTTON_TEXTS.NEXT,
      [LoginState.PASSWORD]: messages.LOGIN_BUTTON_TEXTS.LOGIN,
      [LoginState.MFA]: messages.LOGIN_BUTTON_TEXTS.VERIFY,
    }
    return loginStateToPrimaryButtonLabelMap[loginState] || messages.LOGIN_BUTTON_TEXTS.LOGIN;
  }

  const getSuggestionText = () => {
    const suggestionTextMap = {
      [LoginState.USERNAME]: messages.LOGIN_SUGGESTION_TEXT.USERNAME,
      [LoginState.PASSWORD]: messages.LOGIN_SUGGESTION_TEXT.PASSWORD,
      [LoginState.MFA]: messages.LOGIN_SUGGESTION_TEXT.MFA,
    }
    return suggestionTextMap[loginState] || '';
  }

  if (!headerData?.entities?.fetched) {
    return null;
  }

  return (
    <Box>
      {
        loginState === LoginState.USERNAME || loginState === LoginState.PASSWORD ? (
          <UserLoginForm
            iconHeading={messages.LOGIN_ICON_HEADING}
            icon={logo}
            subHeading={messages.LOGIN_SUBHEADING}
            suggestionText={getSuggestionText()}
            emailTextFieldlabel={messages.LOGIN_TEXT_FIELD_LABEL.USERNAME}
            email={userLoginCredentials.Username}
            passwordTextFieldLabel={messages.LOGIN_TEXT_FIELD_LABEL.PASSWORD}
            password={userLoginCredentials.Password}
            primaryButtonText={getPrimaryButtonText()}
            secondaryButtonText={''}
            handleTextFieldOneChange={handleFirstTextFieldChange}
            handleTextFieldTwoChange={handleSecondTextFieldChange}
            onPrimaryButtonClick={handleLoginClick}
            onSecondaryButtonClick={handleForgotPassword}
            error={error}
            setError={(erroMsg: string) => setError(erroMsg)}
            handleKeyPress={handleKeyPress}
            isSubmitting={isSubmitting}
            showPassword={loginState === LoginState.PASSWORD}
            showBlackScreen={showBlackScreen}
            setShowBlackScreen={(v: boolean) => setShowBlackScreen(v)}
            onBlackScreenClick={onBlackScreenClick}
          />
        ) : (
          <UserMfaLoginForm
            iconHeading={mfaMessages.LOGIN_ICON_HEADING}
            icon={logo}
            subHeading={mfaMessages.LOGIN_SUBHEADING}
            suggestionText={mfaMessages.LOGIN_SUGGESTION_TEXT}
            verificationCodeTextFieldlabel={mfaMessages.VERIFY_TEXT_FIELD_LABEL}
            verifyButtonText={mfaMessages.LOGIN_BUTTON_TEXTS.VERIFY}
            cancelButtonText={mfaMessages.LOGIN_BUTTON_TEXTS.CANCEL}
            handleTextFieldOneChange={handleTotpFieldChange}
            onVerifyButtonClick={handleVerifyMfaClick}
            onCancelButtonClick={handleMfaCancelClick}
            error={error}
            handleKeyPress={handleMfaKeyPress}
            isVerifyButtonEnabled={totp?.length === 6}
          />
        )
      }
      <Box position="fixed" bottom={0} width="100%">
        {renderDirectLink()}
        <MainFooter logoRequired={true} versionRequired={false}></MainFooter>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  token: state?.token
})

export default connect(mapStateToProps)(UserLoginPage);