import {
  Dialog,
  DialogProps,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import IconFromSvg from '../../atoms/IconFromSvg';
import AccessDeniedIcon from '../../../../public/assets/textfield-error.svg';
import Button from '../../atoms/Button';
import { BUTTON_LABEL_CONSTANTS } from '../../../core-utils/constants';
import { EXTRA_COLORS } from '../../../core-utils/theme';
import messages from '../../../core-utils/messages';

export interface IRemovePopUpProps extends DialogProps {
  mainText: string;
  heading: string;
  onButtonClick?: (e: any) => void;
  handleClose?: () => void;
  removeItem?: string;
  removeFrom?: string;
  context?: 'policy' | 'user' | 'idp';
  buttonText?: string;
  disableMainTextFormatting?: boolean;
  hideIcon?: boolean;
  hideConfirmationMsg?: boolean;
}

const RemovePopUp = (props: IRemovePopUpProps) => {
  const theme = useTheme();
  const {
    mainText,
    open,
    heading,
    onButtonClick,
    handleClose,
    removeFrom,
    removeItem,
    context,
    buttonText,
    disableMainTextFormatting,
    hideIcon,
    hideConfirmationMsg
  } = props;

  const getNonHighlightedParts = () => {
    if (disableMainTextFormatting) {
      return {firstPart: mainText, middlePart: ''};
    }
    let firstPart = mainText.substring(0, mainText.indexOf('{removeItem}'));
    firstPart =
      context === 'policy'
        ? firstPart.replaceAll('remove', 'detach')
        : firstPart;
    const middlePart = mainText.substring(
      mainText.lastIndexOf('{removeItem}') + 12,
      mainText.indexOf('{removeFrom}'),
    );
    return { firstPart, middlePart };
  };

  const handleRemove = (e: any) => {
    onButtonClick && onButtonClick(e);
    handleClose && handleClose();
  };

  const { firstPart, middlePart } = getNonHighlightedParts();
  return (
    <Dialog open={open}>
      <Grid
        container
        sx={{
          width: theme.spacing(113),
          height: theme.spacing(73),
          background: theme.palette.background.paper,
          boxSizing: 'border-box',
          boxShadow: '0px 20px 24px rgba(0, 0, 0, 0.08)',
          border: '1px solid ' + theme.palette.grey[300],
          borderRadius: theme.spacing(1.25),
        }}
        direction="column"
        alignItems="center"
      >
        {
          hideIcon ? null : (

        <Grid
          sx={{ marginTop: theme.spacing(6), marginBottom: theme.spacing(2.5) }}
          item
        >
          <IconFromSvg
            width={31.77}
            height={26.25}
            path={AccessDeniedIcon}
            alt="access-denied icon"
          />
        </Grid>
          )
        }
        <Grid sx={{ marginBottom: theme.spacing(3) }} item>
          <Typography variant="h2">{heading}</Typography>
        </Grid>
        <Grid item>
          <Divider
            sx={{
              width: theme.spacing(95),
            }}
          />
        </Grid>

        <Grid
          sx={{
            width: theme.spacing(95),
            height: theme.spacing(10),
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(7.5),
          }}
          item
          container
          direction="row"
          gap={theme.spacing(1)}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.text.secondary }}
            >
              {firstPart}
            </Typography>
          </Grid>
          {removeItem && (
            <Grid item>
              <Typography variant="body1">{`"${removeItem}"`}</Typography>
            </Grid>
          )}
          {
            middlePart && (
          <Grid item>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.text.secondary }}
            >
              {middlePart}
            </Typography>
          </Grid>)

          }
          {removeFrom && (
          <Grid item>
            <Typography variant="body1">{`"${removeFrom}"`}</Typography>
          </Grid>

          )}
        </Grid>
        {hideConfirmationMsg ? null : (
          
        <Grid item sx={{ marginTop: -3 }}>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.text.secondary }}
          >
            {messages.CONIFRM_REMOVE_MESSAGE}
          </Typography>
        </Grid>
        )}
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          gap={theme.spacing(6)}
          sx={{ marginTop: 5 }}
        >
          <Grid item>
            <Button
              variant="text"
              color="primary"
              onClick={handleClose}
              sx={{
                '&:hover': { backgroundColor: EXTRA_COLORS.lightBlue },
                width: theme.spacing(28),
                height: theme.spacing(10),
              }}
            >
              {BUTTON_LABEL_CONSTANTS.CANCEL_BUTTON_LABEL}
            </Button>
          </Grid>
          <Grid item>
            <Button
              sx={{ width: theme.spacing(28), height: theme.spacing(10) }}
              variant="contained"
              color="error"
              onClick={handleRemove}
            >
              {buttonText
                ? buttonText
                : context === 'policy'
                ? BUTTON_LABEL_CONSTANTS.DETACH_BUTTON_LABEL
                : BUTTON_LABEL_CONSTANTS.REMOVE_BUTTON_LABEL}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default RemovePopUp;
