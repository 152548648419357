const ONBOARDING_FLOW_MESSAGES = {
  ADMIN_FORM_MESSAGES: {
    HEADING: 'Contact Information',
    SUBHEADING: 'Update contact information',
    NAME: 'Name',
    USERNAME: 'Email Address',
    JOB_TITLE: 'Job Title',
    ROLE: 'Role',
    IS_CHECKED: 'Is Checked',
    PLACEHOLDERS: {
      NAME_PLACEHOLDER: 'Enter your name here',
      USERNAME_PLACEHOLDER: 'Enter your email address or username here',
      JOB_TITLE_PLACEHOLDER: 'Enter your job title here, Eg -"Developer"',
    },
    CHECKBOX_TEXT: 'Receive notifications to the above email ID',
  },
};

export default ONBOARDING_FLOW_MESSAGES;
