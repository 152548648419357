import { Box, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/styles';
import React from 'react';
import ChevronLeftIcon from '../../../../public/assets/chevron-left-active.svg';
import { useShowOrHideElement } from '../../../core-utils/Hooks/index.hook';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import IconFromSvg from '../../atoms/IconFromSvg';
import policyBuilderMessages from '../../../core-utils/messages/policyBuilder';

export interface IExpandCollapseStateProps {
  handleOpen: () => void;
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: EXTRA_COLORS.grey,
    height: theme.spacing(9.77),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: EXTRA_COLORS.grey,
  },
}));

const displayStyles = {
  width: theme.spacing(7),
  height: theme.spacing(9),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const borderStyles = {
  boxShadow: theme.shadows[13],
  border: `1px solid ${theme.palette.grey[500]}`,
  borderRadius: `${theme.spacing(5.5, 0, 0, 5.5)}`,
};

const RightPanelExpandCollapseState = ({
  handleOpen,
}: IExpandCollapseStateProps) => {
  const { open, showElement, hideElement } = useShowOrHideElement();
  return open ? (
    <LightTooltip
      title={policyBuilderMessages.OPEN_CONDITIONS_PANEL}
      placement="bottom-start"
      arrow
    >
      <Box
        display="flex"
        sx={{ ...borderStyles }}
        flexDirection="row"
        width={theme.spacing(15)}
        onMouseLeave={hideElement}
      >
        <Box {...displayStyles} onClick={handleOpen}>
          <IconFromSvg path={ChevronLeftIcon} alt="chevron-left" />
        </Box>
        <Box
          width={theme.spacing(8)}
          height={theme.spacing(9)}
          data-testid="expanded"
          onClick={handleOpen}
        />
      </Box>
    </LightTooltip>
  ) : (
    <Box {...displayStyles} onMouseEnter={showElement} sx={{ ...borderStyles }}>
      <IconFromSvg path={ChevronLeftIcon} alt="chevron-left" />
    </Box>
  );
};

export default RightPanelExpandCollapseState;
