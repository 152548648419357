import DuplicateActiveIcon from '@assets/clone-active.svg';
import DuplicateIcon from '@assets/clone.svg';
import CloseActive from '@assets/close-active.svg';
import CloseInactive from '@assets/close-inactive.svg';
import DeleteRedSvg from '@assets/delete-red.svg';
import MoreActive from '@assets/more-active.svg';
import MoreInactive from '@assets/more-inactive.svg';
import { Grid, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import PolicyService from '../../../api-service/policy-service/PolicyService';
import { EMPTY_FUNCTION, IDropDownMenuItem, POLICIES_HEADERS, POLICIES_HEADERS_UNDER_USERS, POLICIES_TABLE_ICONS_WIDTH } from '../../../core-utils/constants';
import { getAppsOrGroupsForTable, getChipsWithViolationType, getDateAndTimeFromISO, getWidthsForPoliciesTable } from '../../../core-utils/Helper/helper';
import { useShowOrHideElement } from '@utils/Hooks/index.hook';
import policyMessages from '@utils/messages/policyMessages';
import userModuleMessages from '@utils/messages/user';
import userGroupMessages from '@utils/messages/usergroup';
import theme, { EXTRA_COLORS } from '@utils/theme';
import IconFromSvg from '../../atoms/IconFromSvg';
import ActivateOrDeactivatePolicyPopUp from '../ActivateOrDeactivatePolicyPopUp';
import ChipsGroup from '../ChipsGroup';
import DropDownMenu from '../DropDownMenu';
import RemovePopUp from '../RemovePopUp';

export interface IPoliciesTableRowProps {
  id?: string;
  policyName: string;
  createdBy: string;
  applications: string[];
  dateTime: string;
  status: 'Active' | 'Inactive';
  userGroups?: string[];
  showClose?: boolean;
  handleTableRowClick?: () => void;
  handleStats?: () => void;
  handleRemove?: () => void;
  handleDuplicate?: () => void;
  underTab?: 'users' | 'usergroup';
  updatedBy?: {
    name: string;
    dateTime: string;
  };
  inPopUp?: boolean;
  disablePolicy?: boolean;
  contextName?: string;
  hideStatus?: boolean;
  timeZone?: string;
}

const StyledTypography = styled(Typography)({
  color: theme.palette.text.disabled,
});

// TODO: When the corresponding data is present, make this false
export const hideAssociatedApps: boolean = true;
export const hideLastPolicyHitTime: boolean = true;
export const hideAssociatedGroups: boolean = false;

const PoliciesTableRow = (props: IPoliciesTableRowProps) => {
  const {
    id,
    policyName,
    contextName,
    createdBy,
    applications,
    dateTime,
    status,
    userGroups,
    underTab,
    updatedBy,
    handleRemove,
    handleDuplicate,
    handleTableRowClick,
    inPopUp,
    hideStatus,
    disablePolicy,
    showClose,
  } = props;

  const [moreOrCloseHover, setMoreOrCloseHover] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState(status);
  const [isSwitchActive, setSwitchActive] = useState(
    status === 'Active' ? true : false,
  );
  const [isOpen, setIsOpen] = useState(false);
  const { open, showElement, hideElement } = useShowOrHideElement();

  const handleSwitchStatus = () => {
    if (updatedStatus === 'Active') {
      setUpdatedStatus('Inactive');
    } else {
      setUpdatedStatus('Active');
    }
    setSwitchActive(!isSwitchActive);
  };

  const handleYesClick = async () => {
    const policy = id && (await PolicyService.getPolicyById(id));
    let policyStatus = '';
    if (updatedStatus === 'Active') {
      policyStatus = 'INACTIVE';
    } else {
      policyStatus = 'ACTIVE';
    }
    const updatePolicyRequestBody = {
      ...policy,
      policyStatus: policyStatus,
      policyContent: JSON.parse(policy.policyContent),
    };
    // TODO: uncomment it when api is availabel
    id && (await PolicyService.updatePolicy(updatePolicyRequestBody, id));
    hideElement();
    handleSwitchStatus();
  };

  const handleCancelClick = () => {
    hideElement();
  };

  const handleChangeSwitch = () => {
    showElement();
  };

  const openRemovePopUp = () => {
    setIsOpen(true);
  };

  const closeRemovePopUp = () => {
    setIsOpen(false);
  };

  const DROPDOWN_ITEMS: IDropDownMenuItem[] = [
    {
      text: 'Clone',
      icon: DuplicateIcon,
      activeIcon: DuplicateActiveIcon,
      handleClick: handleDuplicate ?? EMPTY_FUNCTION,
    },
    {
      text: 'Delete',
      icon: DeleteRedSvg,
      activeIcon: DeleteRedSvg,
      isDanger: true,
      handleClick: handleRemove ?? EMPTY_FUNCTION,
    },
  ];

  const { date = '', time = '' } = updatedBy?.dateTime ? getDateAndTimeFromISO(updatedBy?.dateTime, props.timeZone) : {}

  return (
    <>
      <TableRow
        sx={{
          border: `1px solid ${EXTRA_COLORS.lightGrayishBlue}`,
          backgroundColor: theme.palette.common.white,
          boxShadow: `0 0 0 1px ${EXTRA_COLORS.lightGrayishBlue} inset`,
          opacity: disablePolicy
            ? EXTRA_COLORS.greyOut
            : EXTRA_COLORS.greyOutDefault,
          cursor: disablePolicy ? 'not-allowed' : 'pointer',
        }}
      >
        <ActivateOrDeactivatePolicyPopUp
          type={updatedStatus === 'Active' ? 'deactivate' : 'activate'}
          policyName={policyName}
          userOrGroup={contextName}
          open={open}
          onYesClick={handleYesClick}
          onCancelClick={handleCancelClick}
        />
        <TableCell width={6} sx={{ padding: 0 }}></TableCell>
        <TableCell
          sx={{
            paddingLeft: 0,
            cursor: disablePolicy ? 'not-allowed' : 'pointer',
          }}
          width={getWidthsForPoliciesTable(underTab, 0, 0, 0)}
          data-testid="table-row"
          onClick={!disablePolicy ? handleTableRowClick : EMPTY_FUNCTION}
        >
          <Typography
            variant="body1"
            sx={{ color: theme.palette.primary.main }}
          >
            {policyName}
          </Typography>
          <StyledTypography variant="caption">{`${policyMessages.CREATED_BY} ${createdBy}`}</StyledTypography>
        </TableCell>

        {userGroups && (
          <TableCell
            sx={{ paddingLeft: 0 }}
            width={POLICIES_HEADERS_UNDER_USERS[1].width}
          >
            {!hideAssociatedGroups && (
              <ChipsGroup
                showAllChips={false}
                displayAsGreyChips={true}
                chips={getChipsWithViolationType(userGroups)}
                noOfChips={1}
              />
            )}
          </TableCell>
        )}
        <TableCell
          sx={{ paddingLeft: 0 }}
          width={getWidthsForPoliciesTable(underTab, 1, 2, 1)}
        >
          {!hideAssociatedApps && (
            <StyledTypography variant="body2">
              {getAppsOrGroupsForTable(applications, 3)}
            </StyledTypography>
          )}
        </TableCell>
        {updatedBy && (
          <TableCell sx={{ paddingLeft: 0 }} width={POLICIES_HEADERS[2].width}>
            <StyledTypography variant="body1">
              {updatedBy.name}
            </StyledTypography>
            {updatedBy.dateTime !== '' && (
              <StyledTypography variant="caption">
                {`${date} ${time}`}
              </StyledTypography>
            )}
          </TableCell>
        )}
        <TableCell
          sx={{ paddingLeft: 0 }}
          width={getWidthsForPoliciesTable(underTab, 3, 3, 2)}
        >
          {!hideLastPolicyHitTime && dateTime !== '' && (
            <>
              <StyledTypography variant="body2">
                {date}
              </StyledTypography>
              <StyledTypography variant="caption">
                {time}
              </StyledTypography>
            </>
          )}
        </TableCell>
        {!inPopUp && (
          <>
            <TableCell
              sx={{ paddingLeft: 0 }}
              width={getWidthsForPoliciesTable(underTab, 4, 4, 3)}
            >
              {!hideStatus && (
                <Grid container>
                  {/* TODO : enable when functionality is there for toggle status */}
                  {/* <Switch
                placeholder="switch"
                checked={isSwitchActive}
                onClick={handleChangeSwitch}
              /> */}
                  <StyledTypography variant="body2" sx={{ paddingLeft: 1 }}>
                    {updatedStatus}
                  </StyledTypography>
                </Grid>
              )}
            </TableCell>

            {/* Add this when stats are ready */}
            <TableCell
              sx={{ paddingLeft: 0 }}
              width={POLICIES_TABLE_ICONS_WIDTH}
            >
              {/* <IconButton
              placeholder="stats"
              onPointerEnter={() => setStatsHover(true)}
              onPointerLeave={() => setStatsHover(false)}
              //TODO: Enable when stats are ready
              disabled
              onClick={handleStats}
            >
              {statsHover ? (
                <IconFromSvg path={StatsActive} alt="stats-active" />
              ) : (
                <IconFromSvg path={StatsInactive} alt="stats-inactive" />
              )}
            </IconButton> */}
            </TableCell>
            <TableCell
              sx={{ paddingLeft: 0 }}
              width={POLICIES_TABLE_ICONS_WIDTH}
            >
              {!underTab ? (
                <DropDownMenu
                  menuItems={DROPDOWN_ITEMS}
                  itemForDropdownMenu={
                    <IconButton
                      placeholder="more"
                      onPointerEnter={() => setMoreOrCloseHover(true)}
                      onPointerLeave={() => setMoreOrCloseHover(false)}
                    >
                      {moreOrCloseHover ? (
                        <IconFromSvg path={MoreActive} alt="more-active" />
                      ) : (
                        <IconFromSvg path={MoreInactive} alt="more-inactive" />
                      )}
                    </IconButton>
                  }
                />
              ) : (
                showClose && (
                  <IconButton
                    placeholder="close"
                    onPointerEnter={() => setMoreOrCloseHover(true)}
                    onPointerLeave={() => setMoreOrCloseHover(false)}
                    onClick={openRemovePopUp}
                  >
                    {moreOrCloseHover ? (
                      <IconFromSvg path={CloseActive} alt="close-active" />
                    ) : (
                      <IconFromSvg path={CloseInactive} alt="close-inactive" />
                    )}
                  </IconButton>
                )
              )}
            </TableCell>
          </>
        )}
      </TableRow>
      {underTab && (
        <RemovePopUp
          mainText={
            underTab === 'users'
              ? userModuleMessages.CUSTOM_REMOVE_UNDER_TABS_USER
              : userGroupMessages.CUSTOM_REMOVE_UNDER_TABS_USERGROUPS
          }
          removeFrom={contextName}
          removeItem={policyName}
          heading={policyMessages.DETACH_POLICY}
          open={isOpen}
          onButtonClick={handleRemove}
          handleClose={closeRemovePopUp}
          context="policy"
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  timeZone: state?.header?.entities?.timeZone ?? ''

})
export default connect(mapStateToProps)(PoliciesTableRow);
