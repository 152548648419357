import WarningIcon from '@assets/warningIcon.svg';
import {
    Dialog,
    DialogProps,
    Divider,
    Grid,
    Typography,
    useTheme,
} from '@mui/material';
import React from 'react';
import { BUTTON_LABEL_CONSTANTS } from '../../../core-utils/constants';
import policyMessages from '../../../core-utils/messages/policyMessages';
import { EXTRA_COLORS } from '../../../core-utils/theme';
import Button from '../../atoms/Button';
import IconFromSvg from '../../atoms/IconFromSvg';

interface IActivateOrDeactivatePolicyPopUpProps extends DialogProps {
  type: 'activate' | 'deactivate';
  policyName: string;
  open: boolean;
  onCancelClick?: any;
  onYesClick?: any;
  userOrGroup?: any;
}

const ActivateOrDeactivatePolicyPopUp = (
  props: IActivateOrDeactivatePolicyPopUpProps,
) => {
  const theme = useTheme();
  const {
    onCancelClick,
    onYesClick,
    open,
    policyName,
    type,
    userOrGroup,
  } = props;
  return (
    <Dialog open={open}>
      <Grid
        container
        sx={{
          background: theme.palette.background.paper,
          boxSizing: 'border-box',
          boxShadow: '0px 4px 20px rgba(40, 40, 40, 0.08)',
          borderRadius: theme.spacing(1.25),
          p: `${theme.spacing(7.75, 13, 6, 13)}`,
        }}
        display="flex"
        direction="column"
        alignItems="center"
      >
        <Grid item paddingBottom={3}>
          <IconFromSvg
            width={37.82}
            height={31.25}
            path={WarningIcon}
            alt="warning icon"
          />
        </Grid>
        <Grid item paddingBottom={3}>
          <Typography variant="h3" sx={{ color: theme.palette.text.primary }}>
            {type === 'activate' ? 'Activate Policy' : 'Deactivate Policy'}
          </Typography>
        </Grid>

        <Divider variant="middle" flexItem />

        <Grid
          sx={{
            width: theme.spacing(95),
            height: theme.spacing(10),
            marginTop: 3,
          }}
          item
          container
          direction="row"
          gap={theme.spacing(1)}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.text.secondary }}
            >
              {type === 'deactivate'
                ? policyMessages.POP_UP_MESSAGES_TO_ACTIVATE_OR_DEACTIVATE_POLICY.SUB_TEXT_FIRST_PART.replace(
                    '{action}',
                    'deactivate',
                  )
                : policyMessages.POP_UP_MESSAGES_TO_ACTIVATE_OR_DEACTIVATE_POLICY.SUB_TEXT_FIRST_PART.replace(
                    '{action}',
                    'activate',
                  )}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{`"${policyName}"`}</Typography>
          </Grid>
          {userOrGroup ? (
            <>
              <Grid item>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {
                    policyMessages
                      .POP_UP_MESSAGES_TO_ACTIVATE_OR_DEACTIVATE_POLICY
                      .SUB_TEXT_USER_POLICY_SECOND_PART
                  }
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{`"${userOrGroup}"`}</Typography>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Typography
                variant="body1"
                sx={{ color: theme.palette.text.secondary }}
              >
                {
                  policyMessages
                    .POP_UP_MESSAGES_TO_ACTIVATE_OR_DEACTIVATE_POLICY
                    .SUB_TEXT_SECOND_PART
                }
              </Typography>
            </Grid>
          )}
          {userOrGroup && (
            <Grid item>
              <Typography
                variant="body1"
                sx={{ color: theme.palette.text.secondary }}
              >
                {
                  policyMessages
                    .POP_UP_MESSAGES_TO_ACTIVATE_OR_DEACTIVATE_POLICY
                    .SUB_TEXT_USER_POLICY_THIRD_PART
                }
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          marginTop={12}
        >
          <Grid item marginRight={2}>
            <Button
              onClick={onCancelClick}
              sx={{
                '&:hover': { backgroundColor: EXTRA_COLORS.lightBlue },
                width: 137,
                height: 40,
                borderRadius: theme.spacing(1.25),
              }}
              variant="text"
            >
              {BUTTON_LABEL_CONSTANTS.CANCEL_BUTTON_LABEL}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={onYesClick}
              sx={{
                width: 137,
                height: 40,
                borderRadius: theme.spacing(1.25),
              }}
              variant="contained"
              color="primary"
            >
              {BUTTON_LABEL_CONSTANTS.YES_BUTTON_LABEL}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
export default ActivateOrDeactivatePolicyPopUp;
