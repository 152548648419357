import { Grid, GridProps, Typography } from '@mui/material';
import React, { useState } from 'react';
import DragAndDropIcon from '../../../../public/assets/drag-drop.svg';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import IconFromSvg from '../IconFromSvg';

export interface IPolicyBlockProps extends GridProps {
  type: 'action' | 'condition' | 'list' | 'default';
  children: string;
  width?: number;
  handleDragStart?: any;
  handleDragEnd?: any;
  sx?: any;
  elementId?: string;
}
const PolicyBlock = ({
  type,
  children,
  width,
  handleDragStart,
  handleDragEnd,
  elementId,
}: IPolicyBlockProps) => {
  const [hover, setHover] = useState<boolean>(false);

  const getBackGroundColor = () => {
    if (hover) {
      if (type === 'action') {
        return EXTRA_COLORS.lightPink;
      } else if (type === 'condition') {
        return EXTRA_COLORS.lightAqua;
      } else if (type === 'list') {
        return theme.palette.grey[200];
      } else {
        return theme.palette.grey[100];
      }
    } else {
      return theme.palette.common.white;
    }
  };

  const getBorder = () => {
    if (hover) {
      if (type === 'action') {
        return `1px solid ${EXTRA_COLORS.pinkDark}`;
      } else if (type === 'condition') {
        return `1px solid ${EXTRA_COLORS.aqua}`;
      } else if (type === 'list') {
        return theme.palette.grey[300];
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  const getBorderLeft = () => {
    if (type === 'action') {
      return `4px solid ${EXTRA_COLORS.pinkDark}`;
    } else if (type === 'condition') {
      return `4px solid ${EXTRA_COLORS.aqua}`;
    } else if (type === 'list') {
      return `4px solid ${EXTRA_COLORS.accentGreen_900}`;
    } else {
      if (hover) {
        return `4px solid ${theme.palette.text.disabled}`;
      } else {
        return '';
      }
    }
  };

  return (
    <Grid
      container
      draggable
      direction="row"
      alignItems="center"
      width={width ?? 'inherit'}
      height={47}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sx={{
        backgroundColor: getBackGroundColor(),
        border: getBorder(),
        borderLeft: getBorderLeft(),
        cursor: 'pointer',
      }}
      id={elementId}
    >
      <Grid item paddingLeft={3}>
        <IconFromSvg path={DragAndDropIcon} alt="drag-drop" />
      </Grid>
      <Grid item paddingLeft={1}>
        {hover ? (
          <Typography
            data-testid="dark"
            variant="body1"
            color={theme.palette.text.primary}
          >
            {children}
          </Typography>
        ) : (
          <Typography
            data-testid="light"
            variant="body2"
            color={theme.palette.text.secondary}
          >
            {children}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
export default PolicyBlock;
