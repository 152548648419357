import React, { useState } from 'react';
import {
  Grid,
  IconButton,
  TableRow,
  Typography,
  TableCell,
  Box,
} from '@mui/material';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import IconFromSvg from '../../atoms/IconFromSvg';
import MoreInactive from '../../../../public/assets/more-inactive.svg';
import MoreActive from '../../../../public/assets/more-active.svg';
import { Avatars } from '../../atoms/Avatar';
import {
  getAvatarName,
  getChipsWithUserGroupNames,
  getStatusIcon,
} from '../../../core-utils/Helper/helper';
import ChipsGroup from '../ChipsGroup';
import {
  AVATARS_SIZE,
  ADMIN,
  TABLE_FIRST_ITEM_WIDTH,
  USERS_TABLE_GROUPS_ITEM_WIDTH,
  USERS_TABLE_ICONS_WIDTH,
  USERS_TABLE_NAME_ITEM_WIDTH,
  USERS_TABLE_STATUS_ITEM_WIDTH,
} from '../../../core-utils/constants';
import DropDownMenu from '../DropDownMenu';
import DeleteRedSvg from '../../../../public/assets/delete-red.svg';
import configureUserSvg from '../../../../public/assets/configure-user.svg';
import closeRedActiveSvg from '../../../../public/assets/close-red-active.svg';
import redSquare from '../../../../public/assets/round-red-square.svg';
import oliveGreenSquare from '../../../../public/assets/round-oliveGreen-square.svg';
import deleteSvg from '../../../../public/assets/notification-delete.svg';
import DeleteOrLogoutUserPopUp from '../DeleteUserPopUp';
import messages from '../../../core-utils/messages';
import RemovePopUp from '../RemovePopUp';
import userGroupMessages from '../../../core-utils/messages/usergroup';
import appModuleMessages from '../../../core-utils/messages/app';
import ApplicationPasswordmanagerModal from '../../organisms/Passwordmanager';
import { getWidthsOfUserTable } from 'src/_components/organisms/UsersTable';
import applicationPasswordManagerModalMessages from '@utils/messages/applicationPasswordManager';
import { useLocation } from 'react-router-dom';
import { ROUTES } from '@utils/routes';
import { useParams } from 'react-router-dom'

export interface IUserTableRow {
  name: string;
  username?: string;
  email: string;
  status: 'Active' | 'Inactive' | 'Suspended';
  profileImage?: string;
  usergroups: Array<{
    id?: number;
    usergroupname: string;
  }>;
  userUnderUserGroupsOrAppsTab?: boolean;
  underTabItems?: boolean;
  handleClickMore?: () => void;
  handleClickStats?: () => void;
  handleTableRowClick?: () => any;
  handleNameCellClick?: () => void;
  tabItemName?: string;
  context?: 'app' | 'usergroup';
  organisationalRole?: string;
  showClose?: boolean;
  showAppCredentialsInfo?: boolean;
  isCredGenerated?: boolean;
  generateCreds?: () => void;
  handleRemoveCreds?: () => void;
}

export const getChild = (name: string) => {
  if (name) {
    return getAvatarName(name);
  }
  return '';
};

const UserTableRow = ({
  name,
  email,
  status,
  profileImage,
  usergroups,
  userUnderUserGroupsOrAppsTab: userUnderUserGroups,
  underTabItems,
  handleClickStats,
  handleTableRowClick,
  handleClickMore,
  handleNameCellClick,
  username,
  context,
  tabItemName,
  organisationalRole,
  showClose,
  showAppCredentialsInfo,
  isCredGenerated,
  generateCreds,
  handleRemoveCreds,
}: IUserTableRow) => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const [checked, setChecked] = useState(false);
  const [statsHover, setStatsHover] = React.useState(false);
  const [moreOrCloseHover, setMoreOrCloseHover] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPasswordManager, setIsOpenPasswordManager] = React.useState(
    false,
  );
  const [isDeleteCredOpen, setIsDeleteCredOpen] = React.useState(false);
  const heading = messages.DELETE_USER_MESSAGE.HEADING;

  const handleDeleteCredOpen = () => {
    setIsDeleteCredOpen(true);
  };

  const closeDeleteCred = () => {
    setIsDeleteCredOpen(false);
  };

  const handleDropDownClick = () => {
    setIsOpen(true);
  };
  const closeDeletePopUp = () => {
    setIsOpen(false);
  };

  const handleConfigureUserCredentials = () => {
    setIsOpenPasswordManager(true);
  };

  const closeConfigureUserCredentials = () => {
    setIsOpenPasswordManager(false);
    generateCreds && generateCreds();
  };

  /**
   * <IconButton
        placeholder="more"
        onPointerEnter={() => setMoreOrCloseHover(true)}
        onPointerLeave={() => setMoreOrCloseHover(false)}
        onClick={handleDropDownClick}
      >
        {moreOrCloseHover ? (
          <IconFromSvg path={CloseActive} alt="close-active" />
        ) : (
          <IconFromSvg path={CloseInactive} alt="close-inactive" />
        )}
      </IconButton>
   */
  const ACTIONS_TABLE_ROWS = [
    {
      cyId: 'action-delete',
      text: underTabItems ? messages.REMOVE_MESSAGE : messages.DELETE_MESSAGE,
      icon: DeleteRedSvg,
      activeIcon: DeleteRedSvg,
      isDanger: true,
      handleClick: handleDropDownClick,
    },
  ];

  const INAPPS_ACTIONS_TABLE_ROWS = [
    {
      cyId: 'action-configure-creds',
      text: messages.CONFIGURE_CREDS,
      icon: configureUserSvg,
      activeIcon: configureUserSvg,
      isDanger: false,
      handleClick: handleConfigureUserCredentials,
    },
    {
      cyId: 'action-delete',
      text: messages.DELETE_CREDS,
      icon: deleteSvg,
      activeIcon: deleteSvg,
      isDanger: false,
      handleClick: handleDeleteCredOpen,
    },
    {
      cyId: 'action-remove',
      text: messages.REMOVE_USER,
      icon: closeRedActiveSvg,
      activeIcon: closeRedActiveSvg,
      isDanger: true,
      handleClick: handleDropDownClick,
    },
  ];

  const getTabItems = () => {
    if (!location.pathname.includes(`${ROUTES.USER_GROUPS}/`)) {
      const actions = !showClose
        ? INAPPS_ACTIONS_TABLE_ROWS.filter((action) => !action.isDanger)
        : INAPPS_ACTIONS_TABLE_ROWS;
      return isCredGenerated
        ? actions
        : actions.filter((action) => action.text !== messages.DELETE_CREDS);
    }
    return ACTIONS_TABLE_ROWS;
  };

  /**
   * 
   * <IconButton
      placeholder="more"
      onPointerEnter={() => setMoreOrCloseHover(true)}
      onPointerLeave={() => setMoreOrCloseHover(false)}
      onClick={handleDropDownClick}
    >
      {moreOrCloseHover ? (
        <IconFromSvg path={CloseActive} alt="close-active" />
      ) : (
        <IconFromSvg path={CloseInactive} alt="close-inactive" />
      )}
    </IconButton>
   */

  const widths = getWidthsOfUserTable(
    true,
    showAppCredentialsInfo,
    underTabItems,
  );
  return (
    <React.Fragment>
      <TableRow
        sx={{
          '&:hover': {
            backgroundColor: checked
              ? EXTRA_COLORS.lightBlue
              : EXTRA_COLORS.teritary_blue,
            borderLeft: checked
              ? ''
              : `2px solid ${theme.palette.primary.main}`,
          },
          backgroundColor: checked
            ? EXTRA_COLORS.lightBlue
            : theme.palette.common.white,
          boxShadow: `0 0 0 1px ${EXTRA_COLORS.lightGrayishBlue} inset`,
        }}
      >
        {/* TODO: uncomment it when we have functionality for multiple table rows select and remove next table cell */}

        {/* <TableCell align="center" width={TABLE_FIRST_ITEM_WIDTH}>
        <CheckBox onClick={() => setChecked(!checked)} checked={checked} />
      </TableCell> */}

        <TableCell width={6} sx={{ padding: 0 }}></TableCell>
        <TableCell
          sx={{
            paddingLeft: 0,
            cursor: 'pointer',
          }}
          width={widths[0]}
          data-testid="table-row"
          onClick={handleTableRowClick}
        >
          <Grid
            container
            alignItems="flex-start"
            width="100%"
            flexWrap="nowrap"
          >
            <Grid item paddingRight={theme.spacing(4)}>
              {profileImage ? (
                <Avatars
                  isImage={true}
                  src={profileImage}
                  size={AVATARS_SIZE.large}
                />
              ) : (
                <Avatars
                  isImage={false}
                  children={getChild(name)}
                  size={AVATARS_SIZE.large}
                />
              )}
            </Grid>
            <Grid
              item
              container
              direction="column"
              maxWidth="85%"
              minWidth="74%"
            >
              <Typography
                variant="body1"
                color={theme.palette.primary.main}
                width="100%"
                noWrap={true}
              >
                {name}
              </Typography>

              <Typography
                variant="caption"
                color={theme.palette.text.secondary}
                width="100%"
                noWrap={true}
              >
                {email || username}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="left" sx={{ paddingLeft: 0 }} width={widths[1]}>
          {/* uncomment the following once api is available */}
          {/* <Grid container>
            <Grid item paddingRight={2}>
              <IconFromSvg path={getStatusIcon(status)} alt="status" />
            </Grid>
            <Typography variant="body2" color={theme.palette.text.secondary}>
              {status}
            </Typography>
          </Grid> */}
        </TableCell>
        <TableCell align="left" sx={{ paddingLeft: 0 }} width={widths[2]}>
          <ChipsGroup
            showAllChips={false}
            displayAsGreyChips={true}
            chips={getChipsWithUserGroupNames(usergroups)}
          />
        </TableCell>
        {/* TODO: Enable when stats are ready */}
        {/* <TableCell
          align="center"
          sx={{ paddingLeft: 0 }}
          width={USERS_TABLE_ICONS_WIDTH}
        >
          {!userUnderUserGroups && (
            <IconButton
              placeholder="stats"
              onPointerEnter={() => setStatsHover(true)}
              onPointerLeave={() => setStatsHover(false)}
              onClick={handleClickStats}
            >
              {statsHover ? (
                <IconFromSvg path={StatsActive} alt="stats-active" />
              ) : (
                <IconFromSvg path={StatsInactive} alt="stats-inactive" />
              )}
            </IconButton>
          )}
        </TableCell> */}

        <TableCell align="left" sx={{ paddingLeft: 0 }} width={widths[3]}>
          {showAppCredentialsInfo && (
            <Grid container>
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box sx={{ mr: theme.spacing(2.5) }}>
                  {isCredGenerated ? (
                    <IconFromSvg path={oliveGreenSquare} alt="stats-inactive" />
                  ) : (
                    <IconFromSvg path={redSquare} alt="stats-inactive" />
                  )}
                </Box>

                <Typography variant="body2" color={EXTRA_COLORS.greyFont}>
                  {isCredGenerated ? 'Yes' : 'No'}
                </Typography>
              </Grid>
            </Grid>
          )}
        </TableCell>

        <TableCell
          align="center"
          sx={{ paddingLeft: 0 }}
          width={USERS_TABLE_ICONS_WIDTH}
        >
          {underTabItems
            ? tabItemName?.trim().toLowerCase() !==
            userGroupMessages.ALL_USERS_GROUP.trim().toLowerCase() && (
              <DropDownMenu
                cyId={`cy-actions-dropdown-${username}`}
                menuItems={getTabItems()}
                itemForDropdownMenu={
                  <IconButton
                    placeholder="more"
                    onPointerEnter={() => setMoreOrCloseHover(true)}
                    onPointerLeave={() => setMoreOrCloseHover(false)}
                  >
                    {moreOrCloseHover ? (
                      <IconFromSvg path={MoreActive} alt="more-active" />
                    ) : (
                      <IconFromSvg path={MoreInactive} alt="more-inactive" />
                    )}
                  </IconButton>
                }
              />
            )
            : organisationalRole?.trim().toLowerCase() !==
            ADMIN.trim().toLowerCase() && (
              <DropDownMenu
                cyId={`cy-actions-dropdown-${username}`}
                menuItems={ACTIONS_TABLE_ROWS}
                itemForDropdownMenu={
                  <IconButton
                    placeholder="more"
                    onPointerEnter={() => setMoreOrCloseHover(true)}
                    onPointerLeave={() => setMoreOrCloseHover(false)}
                  >
                    {moreOrCloseHover ? (
                      <IconFromSvg path={MoreActive} alt="more-active" />
                    ) : (
                      <IconFromSvg path={MoreInactive} alt="more-inactive" />
                    )}
                  </IconButton>
                }
              />
            )}
        </TableCell>
      </TableRow>
      {underTabItems ? (
        <RemovePopUp
          mainText={
            context === 'app'
              ? appModuleMessages.CUSTOM_REMOVE_UNDER_TABS_FOR_APPS
              : userGroupMessages.CUSTOM_REMOVE_UNDER_TABS_USERGROUPS
          }
          removeFrom={context === 'app' ? name : tabItemName}
          removeItem={context === 'app' ? tabItemName : name ? name : username}
          heading={heading.replace('Delete', 'Remove')}
          open={isOpen}
          onButtonClick={handleClickMore}
          handleClose={closeDeletePopUp}
        />
      ) : (
        <DeleteOrLogoutUserPopUp
          headingText={heading}
          open={isOpen}
          content={messages.CUSTOM_DELETE_MESSAGE.replace(
            '{"The User"}',
            `"${name}"`,
          )}
          handleClose={closeDeletePopUp}
          onButtonClick={handleClickMore}
        />
      )}
      {isOpenPasswordManager && (
        <ApplicationPasswordmanagerModal
          showModal={isOpenPasswordManager}
          onClose={closeConfigureUserCredentials}
          name={email || username || ""}
          clearForm={isOpenPasswordManager}
          appId={id}
        />
      )}
      {isDeleteCredOpen && (
        <RemovePopUp
          mainText={
            applicationPasswordManagerModalMessages.CUSTOM_REMOVE_CREDENTIALS
          }
          removeFrom={name ? name : username}
          removeItem={tabItemName}
          heading={applicationPasswordManagerModalMessages.DELETE_CREDENTIALS}
          open={isDeleteCredOpen}
          onButtonClick={handleRemoveCreds}
          handleClose={closeDeleteCred}
          buttonText={applicationPasswordManagerModalMessages.DELETE}
        />
      )}
    </React.Fragment>
  );
};

export default UserTableRow;
