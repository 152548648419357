
import TeamsIcon from '@assets/teams.svg';
import IconFromSvg from '@atoms/IconFromSvg';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import React from 'react';
import { Action, Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

export default function FloatingVideoConfMenu() {

  const mainButtonStyles = {
    backgroundColor: '#f1f5f9',
  };
  const actionButtonStyles = {
    backgroundColor: '#006ee5',
  };
  return (
    <div className="floatingMenu">
      <Fab
        mainButtonStyles={mainButtonStyles}
        // icon={<VideoCameraFrontIcon />}
        icon={<IconFromSvg path={TeamsIcon} alt="azure teams" height={28} />}
        // alwaysShowTitle={true}
        style={{
          bottom: 72,
          right: 0,
        }}
        // icon="+"
        event="hover"
        key={-1}
        alwaysShowTitle={false}
        onClick={() => {
          window.open(`${window.location.origin}/teams/?calltype=new`, '_blank');
        }}
        text="Make a teams call"
      >
        <Action
          text="start a new meeting"
          onClick={() => {
            window.open(`${window.location.origin}/teams/?calltype=new`, '_blank');
          }}
          style={actionButtonStyles}
        >
          <VideoCallIcon />
        </Action>
        <Action
          text="join an existing meeting"
          onClick={() => {
            window.open(`${window.location.origin}/teams/?calltype=join`, '_blank');
          }}
          style={actionButtonStyles}
        >
          <VideoCameraFrontIcon />
        </Action>
      </Fab>
    </div>
  );
}
