import React, { useState } from 'react';
import {
  Divider,
  Grid,
  IconButton,
  Menu,
  Typography,
  Box,
} from '@mui/material';
import DropDownItem, { StyledMenuItem } from '../DropDownItem';
import { AVATARS_SIZE, IDropDownMenuItem } from '../../../core-utils/constants';
import { styled } from '@mui/material/styles';
import { Avatars } from '../../atoms/Avatar';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import { getChild } from '../UserTableRow';

const StyledMenu = styled(Menu)({
  '& .MuiPaper-root': {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    border: '1px solid #EEEEEE',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 20px rgba(40, 40, 40, 0.08)',
    borderRadius: theme.spacing(1.25),
  },
});

export interface IDropDownMenuProps {
  menuItems: Array<IDropDownMenuItem>;
  itemForDropdownMenu?: JSX.Element;
  menuWidth?: string | number;
  onClose?: () => void;
  handleProfileClick?: () => void;
  headerData?: { profileImage?: string; name: any; email: any };
  cyId?: string;
}

const MainHeaderDropDown = (
  headerData: { profileImage?: string; name: string; email: string },
  handleProfileClick: any,
  handleClose: any,
) => {
  return (
    <Box>
      <StyledMenuItem onClick={handleProfileClick} onClickCapture={handleClose}>
        <Grid container gap={2} alignItems="flex-start">
          {headerData.profileImage ? (
            <Avatars
              isImage={true}
              src={headerData.profileImage}
              size={AVATARS_SIZE.large}
            />
          ) : (
            <Avatars
              isImage={false}
              children={getChild(headerData.name)}
              size={AVATARS_SIZE.large}
            />
          )}
          <Grid item sx={{ pl: 3 }}>
            <Typography variant="body1" color={theme.palette.common.black}>
              {headerData.name}
            </Typography>
            <Typography variant="caption" color={theme.palette.text.secondary}>
              {headerData.email}
            </Typography>
          </Grid>
        </Grid>
      </StyledMenuItem>
      <Divider
        sx={{ ml: 4, mr: 4 }}
        style={{
          width: 'inherit',
          border: '0.5px solid ' + EXTRA_COLORS.blue_100,
        }}
      />
    </Box>
  );
};

const DropDownMenu = ({
  menuItems,
  itemForDropdownMenu,
  menuWidth,
  onClose,
  headerData,
  handleProfileClick,
  cyId
}: IDropDownMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    onClose && onClose();
  };
  return (
    <div data-testid={cyId || "dropdown"} >
      <IconButton
        sx={{
          padding: 0,
          width: menuWidth,
          '&:hover': { backgroundColor: 'unset' },
        }}
        onClick={handleClick}
        disableRipple
      >
        {itemForDropdownMenu}
      </IconButton>
      <StyledMenu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            width: menuWidth ? menuWidth : '22%',
          },
        }}
      >
        {headerData &&
          MainHeaderDropDown(headerData, handleProfileClick, handleClose)}
        {menuItems.map((item, index) => (
          <DropDownItem
            key={index}
            text={item.text}
            icon={item?.icon}
            activeIcon={item?.activeIcon}
            handleClick={item.handleClick}
            handleClose={handleClose}
            isDanger={item.isDanger}
            cyId={item?.cyId}
          ></DropDownItem>
        ))}
      </StyledMenu>
    </div>
  );
};
export default DropDownMenu;
