import { Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import policyMessages from '../../../core-utils/messages/policyMessages';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import { IPoliciesTableRowProps } from '../../molecules/PoliciesTableRow';
import PolicyListEmptyScreen from '../../molecules/PolicyListEmptyScreen';
import PoliciesTable from '../PoliciesTable';
import PoliciesTableInPopUp from '../PoliciesTableInPopUp';
import SearchBar from '../SearchBar';

export interface IPoliciesTabItemProps {
  policiesData: IPoliciesTableRowProps[];
  policiesSearchData: string[];
  inPopUp?: boolean;
  handleClick?: (id: string | number, policyName: string) => void;
}
const MyPoliciesTabItem = ({
  policiesData,
  policiesSearchData,
  inPopUp,
  handleClick,
}: IPoliciesTabItemProps) => {
  const [searchData, setSearchData] = useState<string[]>(policiesSearchData);
  const [searchResults, setSearchResults] = useState<IPoliciesTableRowProps[]>(
    [],
  );
  const [policies, setPolicies] = useState<IPoliciesTableRowProps[]>(
    policiesData,
  );

  const handleEnter = (searchResultsList: string[]) => {
    const resultTableRows: any[] = [];
    policies?.forEach((policy) => {
      if (
        searchResultsList.includes(policy.policyName) ||
        policy.applications.some((app: any) =>
          searchResultsList.includes(app),
        ) ||
        (policy.updatedBy && searchResultsList.includes(policy.updatedBy?.name))
      ) {
        resultTableRows.push(policy);
      }
    });
    setSearchResults(resultTableRows);
  };

  const reverseSearchResultsList = () => {
    searchResults && setSearchResults([...searchResults].reverse());
  };

  const reversePoliciesList = () => {
    setPolicies([...policies].reverse());
  };

  const handleClear = () => {
    setSearchResults(policies);
  };

  const resultPolicies = inPopUp ? (
    searchResults.length ? (
      <PoliciesTableInPopUp
        handleClick={handleClick}
        tableData={searchResults}
        onNameSortClick={reverseSearchResultsList}
      />
    ) : (
      <PoliciesTableInPopUp
        handleClick={handleClick}
        tableData={policies}
        onNameSortClick={reversePoliciesList}
      />
    )
  ) : searchResults.length ? (
    <PoliciesTable
      hideStatus={true}
      tableData={searchResults}
      onNameSortClick={reverseSearchResultsList}
    />
  ) : (
    <PoliciesTable
      tableData={policies}
      onNameSortClick={reversePoliciesList}
      hideStatus={true}
    />
  );

  useEffect(() => {
    setPolicies(policiesData);
    setSearchData(policiesSearchData);
  }, [policiesData]);

  return (
    <Stack
      sx={{
        height: inPopUp ? 'fit-content' : policies.length ? '98%' : '90%',
        width: '100%',
      }}
    >
      {policies?.length && (
        <>
          <Grid item sx={{ paddingBottom: theme.spacing(5) }}>
            <SearchBar
              placeholder={policyMessages.SEARCH_PLACEHOLDER_FOR_POLICIES}
              dataToSearch={searchData}
              handleEnter={handleEnter}
              handleClear={handleClear}
            />
          </Grid>
          {resultPolicies}
        </>
      )}
    </Stack>
  );
};
export default MyPoliciesTabItem;
