import React from 'react';
import UserGroupService, {
  IListUserGroupsResponse,
} from '../../../api-service/user-group-service/UserGroupService';
import { IUserGroupsAndAppsTableRow } from '../../../core-utils/constants';

export const constructUserGroupTableRow = async (
  usergroup: IListUserGroupsResponse,
  handleTableRow: () => void,
  handleClickMore: () => void,
) => {
  const userGroupTableRow: IUserGroupsAndAppsTableRow = {
    name: usergroup.identifier,
    userLength: await usersForUserGroup(usergroup.identifier),
    handleTableRowClick: handleTableRow,
    handleClickMore: handleClickMore,
    target: 'usergroup',
  };
  return userGroupTableRow;
};

export const usersForUserGroup = async (id: string) => {
  const usersforgroups: any = await UserGroupService.getUsersInUserGroup(id);
  return usersforgroups.length;
};
