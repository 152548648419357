const reportMessages = {
    REPORTS_DASHBOARD: {
        SEARCH_REPORTS_PLACEHOLDER: 'Search for users and usergroups.',
        HEADING: 'Reports',
        DOWNLOAD: 'Download',
    },
    REPORT_FIELDS: {
      HEADING: 'Report Fields',
      SUBHEADING: 'Choose fields to be displayed',
    }
};
export default reportMessages;
  