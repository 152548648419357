const mfaLoginMessages = {
  LOGIN_ICON_HEADING: 'sonet.io',
  LOGIN_SUBHEADING: 'Verify Your Identity',
  LOGIN_SUGGESTION_TEXT:
    'Use the authenticator app on your phone or device to get the 6-digit verification code. ',
  VERIFY_TEXT_FIELD_LABEL: 'Verification code',
  LOGIN_BUTTON_TEXTS: {
    VERIFY: 'Verify',
    CANCEL: 'Cancel',
  },
  INVALID_LOGIN_CREDENTIALS: 'Invalid username or password',
};

export default mfaLoginMessages;
