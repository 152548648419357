import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  EMPTY_STATE_ICONS,
  getColorsForBars,
  IMostActiveUserGroupsWidgetProps,
} from '../../../core-utils/constants';
import dashboardMessages from '../../../core-utils/messages/dashboard';
import theme from '../../../core-utils/theme';
import EmptyState from '../EmptyState';

const style = {
  width: '100%',
  height: 'fit-content',
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[300]}`,
  marginTop: theme.spacing(5.5),
};

const MostActiveUserGroupsWidget = ({
  mostActiveUserGroupsData,
}: {
  mostActiveUserGroupsData: IMostActiveUserGroupsWidgetProps[];
}) => {
  return (
    <React.Fragment>
      <Grid container sx={style} flexDirection="column">
        <Grid
          item
          sx={{ paddingLeft: theme.spacing(3.5), paddingTop: theme.spacing(4) }}
        >
          <Typography variant="body1">
            {dashboardMessages.MOSTACTIVE_USERGROUPS_WIDGET_MESSAGE}
          </Typography>
        </Grid>
        <Divider sx={{ paddingTop: theme.spacing(4) }} light />
        <Grid
          item
          container
          sx={{
            paddingTop: theme.spacing(6),
            paddingLeft: theme.spacing(3),
          }}
        >
          {mostActiveUserGroupsData.length === 0 ? (
            <Grid item container sx={{ mt: '10%' }}>
              <EmptyState
                content={dashboardMessages.NO_INFORMATION_MESSAGE}
                icon={EMPTY_STATE_ICONS.USERGROUP_WITH_VIOLATIONS_ICON}
              />
            </Grid>
          ) : (
            <ResponsiveContainer
              width="98%"
              height={mostActiveUserGroupsData.length * 64}
            >
              <BarChart
                margin={{
                  right: 20,
                }}
                data={mostActiveUserGroupsData}
                layout="vertical"
              >
                <XAxis
                  type="number"
                  dataKey="active"
                  tickCount={7}
                  hide={true}
                />
                <YAxis type="category" dataKey="usergroupName" hide={true} />
                <Bar
                  dataKey="active"
                  radius={[0, 5, 5, 0]}
                  barSize={46}
                  animationDuration={1600}
                >
                  {mostActiveUserGroupsData.map((usergroup, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Cell
                          key={usergroup.usergroupName}
                          fill={getColorsForBars[index].color}
                        />
                      </React.Fragment>
                    );
                  })}
                  <LabelList
                    dataKey="usergroupName"
                    position="insideLeft"
                    fill={theme.palette.background.paper}
                    fontSize={14}
                  />
                </Bar>
                <Tooltip cursor={false} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default MostActiveUserGroupsWidget;
