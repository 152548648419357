import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Tooltip as MUITooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  Grid,
} from '@mui/material';
import theme from '../../../core-utils/theme';

interface ITooltipForPolicyBlockProps {
  children: JSX.Element;
  heading: string;
  icon: JSX.Element;
  body: string;
}

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} arrow classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: `2px solid ${theme.palette.grey[400]}`,
      borderRadius: theme.spacing(0.5),
    },
    boxShadow: `${theme.spacing(0)} ${theme.spacing(5)} ${theme.spacing(
      6,
    )} rgba(0, 0, 0, 0.04)`,
    color: theme.palette.common.white,
    fontSize: 32,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    border: `2px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.spacing(1.25),
    width: theme.spacing(62),
    boxShadow: `${theme.spacing(0)} ${theme.spacing(5)} ${theme.spacing(
      6,
    )} rgba(0, 0, 0, 0.04)`,
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    marginRight: theme.spacing(117.5),
    marginLeft: theme.spacing(2.5),
  },
});

const StyledTypographyHeading = styled(Typography)({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  paddingLeft: theme.spacing(2),
});

const StyledTypographyBody = styled(Typography)({
  ...theme.typography.caption,
  color: theme.palette.text.secondary,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  paddingBottom: theme.spacing(5),
});

const PolicyBlockTooltipGuide = ({
  children,
  heading,
  icon,
  body,
}: ITooltipForPolicyBlockProps) => {
  return (
    <StyledTooltip
      arrow
      placement="left-start"
      title={
        <Grid container>
          <Grid container item sx={{ paddingTop: '14px', paddingLeft: '15px' }}>
            {icon}
            <StyledTypographyHeading>{heading}</StyledTypographyHeading>
          </Grid>
          <Grid item>
            <StyledTypographyBody sx={{ lineHeight: '18px' }}>
              {body}
            </StyledTypographyBody>
          </Grid>
        </Grid>
      }
    >
      {children}
    </StyledTooltip>
  );
};

export default PolicyBlockTooltipGuide;
