import { Grid, styled, Button as MuiButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import messages from '@utils/messages';
import TextField from '../../atoms/TextField';
import CustomDialog from '../Dialog';
import {
  ALLOWED_FILE_TYPES,
  BASE64_STRING_LENGTH,
  GLOBAL_LABELS,
  MAX_APP_ICON_FILE_SIZE,
} from '@utils/constants';

import { formatFileSize, replacePlaceholders } from '@utils/Helper/helper';
import theme from '@utils/theme';
import CloseInactive from '../../../../public/assets/close-inactive.svg';
import IconButton from '../../atoms/IconButton';
import IconFromSvg from '../../atoms/IconFromSvg';
import UploadSvg from '../../../../public/assets/upload.svg';
import { Box } from '@mui/material';

const { PASSWORD, PLACEHOLDER } = messages.EDIT_USER;
const { SAVE, CANCEL } = GLOBAL_LABELS;

interface IEditIconModalProps {
  open: boolean;
  heading: string;
  isSaveDisabled: boolean;
  textFieldLabel?: string;
  textFieldValue?: string;
  name?: string;
  onClose: () => void;
  handleFieldChange?: (name: string | undefined, event: any) => void;
  handleSaveClick: (name: string | undefined) => void;
  handleValidation: (
    name: string | undefined,
    logoFile: string | undefined,
  ) => void;
}

const EditIconModal = ({
  open,
  textFieldValue,
  isSaveDisabled,
  heading,
  textFieldLabel,
  name,
  handleSaveClick,
  handleFieldChange,
  handleValidation,
  onClose,
}: IEditIconModalProps) => {
  const {
    TEXT_FIELD_LABELS: {
      MAX_FILE_SIZE,
      ALLOWED_FILE_TYPES_TOOLTIP
    },
    BUTTON_LABELS: { UPLOAD_FILE },
    ERROR_MESSAGES: { UPLOAD_FILE_LENGTH_EXCEED },
  } = messages.ADD_APPLICATION;

  const Input = styled('input')({
    display: 'none',
  });
  const [logoFile, setLogoFile] = useState<string | undefined>(textFieldValue);
  const [logoFileName, setLogoFileName] = useState<string>('');
  const [logoFileType, setLogoFileType] = useState<string>('');
  const [logoFileSize, setLogoFileSize] = useState<string>('');
  const [fileSizeError, setFileSizeError] = useState<string>('');

  const handleFileChange = (event: any) => {
    const uploadedFile = event.target.files[0];

    if (ALLOWED_FILE_TYPES.includes(uploadedFile.type.split('/')[1])) {
      setLogoFileType('.' + uploadedFile.type.split('/')[1]);
      if (uploadedFile.size <= MAX_APP_ICON_FILE_SIZE) {
        setLogoFileName(uploadedFile.name);
        const size = formatFileSize(uploadedFile.size, 2);
        setLogoFileSize(size);
        let reader = new FileReader();
        reader.onload = (e: any) => {
          if (e.target.result.length <= BASE64_STRING_LENGTH) {
            setLogoFile(e.target.result);
            setFileSizeError('');
          } else {
            setFileSizeError(replacePlaceholders(UPLOAD_FILE_LENGTH_EXCEED, { maxFileSize: formatFileSize(MAX_APP_ICON_FILE_SIZE, 2) }));
            handleRemoveLogo();
          }
        };
        reader.readAsDataURL(uploadedFile);
      } else {
        setFileSizeError(MAX_FILE_SIZE);
      }
    }
  };

  const handleRemoveLogo = () => {
    setLogoFile('');
    setLogoFileName('');
    setLogoFileSize('');
  };

  const FileUpload = () => {
    return logoFile ? (
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid
          item
          container
          direction="row"
          sx={{ p: 4, width: 'fit-content' }}
        >
          <img src={logoFile} style={{ height: 50 }} />
          <Grid item alignSelf="center" sx={{ pl: 4 }}>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.text.primary }}
            >
              {logoFileName.substring(0, 20)}
              {logoFileName.length > 20 && '.'.repeat(4) + logoFileType}
            </Typography>
            <Typography
              variant="caption"
              sx={{ color: theme.palette.text.disabled }}
            >
              {logoFileSize}
            </Typography>
          </Grid>
        </Grid>
        <Grid item sx={{ pr: 2 }}>
          <IconButton placeholder="close" onClick={handleRemoveLogo}>
            <IconFromSvg path={CloseInactive} alt="close-active" />
          </IconButton>
        </Grid>
      </Grid>
    ) : (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 1,
          m: 5,
          width: `calc(100% - ${theme.spacing(4)})`,
        }}
      >
        <Grid
          container
          direction="column"
          sx={{ alignItems: 'center', justifyContent: 'center' }}
        >
          <Grid item>
            <Input
              accept="image/*"
              id="upload-file"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="upload-file">
              <MuiButton
                variant="text"
                component="span"
                sx={{
                  textTransform: 'none',
                  borderRadius: theme.spacing(1),
                  p: 0,
                }}
                startIcon={<IconFromSvg path={UploadSvg} alt={UPLOAD_FILE} />}
              >
                {UPLOAD_FILE}
              </MuiButton>
            </label>
          </Grid>
          <Grid item>
            <Typography
              variant="caption"
              sx={{ color: theme.palette.text.disabled }}
            >
              {MAX_FILE_SIZE}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );
  };

  useEffect(() => {
    if (logoFile) {
      handleFieldChange && handleFieldChange(textFieldLabel, logoFile);
    }
  }, [logoFile]);

  useEffect(() => {
    handleValidation(textFieldLabel, logoFile);
  }, [logoFile]);

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      isSaveDisabled={isSaveDisabled}
      heading={heading}
      buttonLabelOne={CANCEL}
      buttonLabelTwo={SAVE}
      handleSaveClick={() => handleSaveClick(name)}
    >
      <Grid container sx={{ flexDirection: 'column', display: 'flex', pl: theme.spacing(6), py: theme.spacing(2) }}>
        <TextField
          label={textFieldLabel}
          required
          value={textFieldValue}
          error={fileSizeError !== ''}
          helperText={fileSizeError}
          testId={textFieldLabel}
          InputProps={{
            style: { height: 80 },
            inputComponent: FileUpload,
          }}
        />
        <Typography
          variant="caption"
          sx={{ color: theme.palette.text.disabled, fontSize: '0.8em', pl: theme.spacing(1), pt: theme.spacing(1) }}
        >
          {ALLOWED_FILE_TYPES_TOOLTIP}
        </Typography>
      </Grid>
    </CustomDialog>
  );
};

export default EditIconModal;
