import UserGroupService from '../../../api-service/user-group-service/UserGroupService';
import UserService from '../../../api-service/user-service/UserService';

export const policyAlreadyExistsForUserGroupOrUser = async (
  policyId: any,
  groupOrUserId: string,
  context: 'usergroup' | 'user',
) => {
  // TODO: remove this return statement once the apis for get polices of user group or user are functional
  // return false;
  const policiesOfUserGroupOrUser: any =
    context === 'usergroup'
      ? await UserGroupService.getPoliciesOfUserGroup(groupOrUserId)
      : await UserService.getPoliciesOfUser(groupOrUserId);
  return policiesOfUserGroupOrUser.indexOf(policyId) !== -1;
};
