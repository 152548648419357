import Config from '../../../config/config';
import {
  SESSION_RECORDING_SETTINGS_MOCK_DATA
} from '../../core-utils/constants';
import axios from '../axios';

export interface ISessionRecordingSettingsResponse {
  administratorUserSessionsEnabled: boolean;
  saveCredsAllowed: boolean;
  linesPerPage: Number | null;
  authenticationTimeout: Number | null;
  rotationPeriod: 'time' | 'disk-space';
  timePeriod?: 'day' | 'week' | 'month' | 'year';
  retentionPeriod: '90' | '365';
}

const SessionRecordingService = {
  listConnectionHistory: async () => {
    try {
      const listActivitiesResponse = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/history/connections`,
        {
          params: {
            order: '-startDate',
          },
        },
      );
      return listActivitiesResponse.data;
    } catch (error) {
      return error;
    }
  },
  getUserSession: async (historyId: number) => {
    try {
      return await axios.get(
        `${Config.DASHBOARD_API_URL}UserSessions/${historyId}`,
      );
    } catch (error) {
      return error;
    }
  },

  getUserSessions: async (
    pageOffset: number,
    limit: number,
    orderBy: string,
    sorted: boolean,
    searchText?: string,
  ) => {
    try {
      const userSessionsData = await axios.get(
        `${Config.DASHBOARD_API_URL}UserSessions`,
        {
          params: {
            pageOffset: pageOffset,
            limit: limit,
            orderBy: orderBy,
            asc: sorted,
            searchQuery: searchText,
          },
        },
      );
      return userSessionsData.data;
    } catch (error) {
      return error;
    }
  },

  getUserSessionsSuggestions: async (searchText: string) => {
    try {
      const userSessionsSuggestionsData = await axios.get(
        `${Config.DASHBOARD_API_URL}UserSessionsSearchSuggestions`,
        {
          params: {
            searchQuery: searchText,
          },
        },
      );
      return userSessionsSuggestionsData.data;
    } catch (error) {
      return error;
    }
  },

  listViolationsOfHistory: async (historyId: string) => {
    try {
      const listViolationsResponse = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/history/connections/${historyId}/violations`,
      );
      return listViolationsResponse.data;
    } catch (error) {
      return error;
    }
  },

  getSessionRecording: async (recordingId: string, config?: any) => {
    try {
      const sessionRecordingResponse = await axios.get(
        `${Config.BACKEND_BASE_URL}api/sonet/recordings/${recordingId}`,
        { ...config },
      );
      return sessionRecordingResponse.data;
    } catch (error) {
      throw error;
    }
  },

  deleteSessionRecording: async (recordingId: string, config?: any) => {
    try {
      const sessionRecordingResponse = await axios.delete(
        `${Config.BACKEND_BASE_URL}api/sonet/recordings/${recordingId}`,
        { ...config },
      );
      return sessionRecordingResponse.data;
    } catch (error) {
      throw error;
    }
  },

  getSettingsOfSessionRecordings: async () => {
    // replace this once we get api
    return SESSION_RECORDING_SETTINGS_MOCK_DATA;
    // try {
    //   const sessionRecordingsSettings = await axios.get(
    //     `${Config.BACKEND_BASE_URL}api/session/data/postgresql/history/connections/settings`,
    //     );
    //     return sessionRecordingsSettings.data as ISessionRecordingSettingsResponse;
    // } catch (error) {
    //   return error;
    // }
  },

  updateSettingsOfSessionRecordings: async (updatedSettings: any) => {
    // replace this once we get api
    // try {
    //   const updatedSettingsResponse = await axios.put(
    //     `${Config.BACKEND_BASE_URL}api/session/data/postgresql/history/connections/settings`,
    //     updatedSettings,
    //   );
    //   return updatedSettingsResponse.data;
    // } catch (error) {}
  },

  getActiveSessionList: async () => {
    try {
      const activeSessionResponse = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/activeConnections`,
      );
      return activeSessionResponse.data;
    } catch (error) {
      throw error;
    } 
  },

  killActiveSession: async (username: string) => {
    try {
      const result = await axios.delete(
        `${Config.BACKEND_BASE_URL}api/session/active/${username}`,
      );
      return result.data;
    } catch (error) {
      throw error;
    } 
  }
};

export default SessionRecordingService;
