import WarningSvg from '@assets/warningIcon.svg';
import { useEffect, useState } from 'react';
import Config from '../../../../config/config';
import AppManagementSvg from '@assets/app-management-active.svg';
import PolicyChangesSvg from '@assets/policy-changes.svg';
import UserManagementSvg from '@assets/user-management-active.svg';
import DashboardService from '../../../api-service/dashboard-service/DashboardService';
import { getDateAndTimeFromISO } from '@utils/Helper/helper';
import { MOCK_DASHBOARD_OVERVIEW_ITEMS, MOCK_USER_GROUPS_VIOLATIONS_WIDGET } from '@utils/test-constants';

export const getDate = () => {
  return new Date();
};

export const useTimeSelector = () => {
  const [dateRange, setDateRange] = useState('1W');
  const handleClick = (_timePeriod: string) => {
    // TODO: Make a network call when the APIS are available
    let fromDate;
    if (_timePeriod === '1D') {
      const currentDate = getDate();
      fromDate = getDate();
      fromDate.setDate(currentDate.getDate() - 1);
      setToDate(currentDate.toISOString());
      setFromDate(fromDate.toISOString());
      setPrevToDate(fromDate.toISOString());
      fromDate.setDate(fromDate.getDate() - 1);
      setPrevFromDate(fromDate.toISOString());
      setDateRange(_timePeriod);
    }
    if (_timePeriod === '1W') {
      const currentDate = getDate();
      fromDate = getDate();
      fromDate.setDate(currentDate.getDate() - 7);
      setToDate(currentDate.toISOString());
      setFromDate(fromDate.toISOString());
      setPrevToDate(fromDate.toISOString());
      fromDate.setDate(fromDate.getDate() - 7);
      setPrevFromDate(fromDate.toISOString());
      setDateRange(_timePeriod);
    }
    if (_timePeriod === '1M') {
      const currentDate = getDate();
      fromDate = getDate();
      fromDate.setDate(currentDate.getDate() - 30);
      setToDate(currentDate.toISOString());
      setFromDate(fromDate.toISOString());
      setPrevToDate(fromDate.toISOString());
      fromDate.setDate(fromDate.getDate() - 30);
      setPrevFromDate(fromDate.toISOString());
      setDateRange(_timePeriod);
    }
    if (_timePeriod === '3M') {
      const currentDate = getDate();
      fromDate = getDate();
      fromDate.setDate(currentDate.getDate() - 90);
      setToDate(currentDate.toISOString());
      setFromDate(fromDate.toISOString());
      setPrevToDate(fromDate.toISOString());
      fromDate.setDate(fromDate.getDate() - 90);
      setPrevFromDate(fromDate.toISOString());
      setDateRange(_timePeriod);
    }
    if (_timePeriod === '1Y') {
      const currentDate = getDate();
      fromDate = getDate();
      fromDate.setDate(currentDate.getDate() - 365);
      setToDate(currentDate.toISOString());
      setFromDate(fromDate.toISOString());
      setPrevToDate(fromDate.toISOString());
      fromDate.setDate(fromDate.getDate() - 365);
      setPrevFromDate(fromDate.toISOString());
      setDateRange(_timePeriod);
    }
  };

  const now = getDate();
  now.setDate(now.getDate() - 7); //default selection will be 1 week
  var initialPrevToDate = new Date(now.getTime());
  let initialPrevFromDate = new Date(initialPrevToDate.getTime());
  initialPrevFromDate.setDate(initialPrevFromDate.getDate() - 7);
  const [fromDate, setFromDate] = useState(now.toISOString());
  const [toDate, setToDate] = useState(getDate().toISOString());
  const [prevFromDate, setPrevFromDate] = useState(
    initialPrevFromDate.toISOString(),
  );
  const [prevToDate, setPrevToDate] = useState(initialPrevToDate.toISOString());

  const TIME_SELECTION_ITEMS = [
    {
      id: 1,
      timePeriod: '1D',
      handleClick: () => {
        handleClick('1D');
      },
    },
    {
      id: 2,
      timePeriod: '1W',
      handleClick: () => {
        handleClick('1W');
      },
    },
    {
      id: 3,
      timePeriod: '1M',
      handleClick: () => {
        handleClick('1M');
      },
    },
    {
      id: 4,
      timePeriod: '3M',
      handleClick: () => {
        handleClick('3M');
      },
    },
    {
      id: 5,
      timePeriod: '1Y',
      handleClick: () => {
        handleClick('1Y');
      },
    },
  ];
  const handleDateSelect = (dates: any) => {
    if (dates[0] && dates[1]) {
      setFromDate(dates[0].toISOString());
      setToDate(dates[1].toISOString());
      const timeDiff = dates[1].getTime() - dates[0].getTime();
      setPrevToDate(dates[0].toISOString());
      setPrevFromDate(new Date(dates[0].getTime() - timeDiff).toISOString());
      setDateRange('CustomRange');
    }
  };
  return {
    TIME_SELECTION_ITEMS,
    fromDate,
    toDate,
    prevFromDate,
    prevToDate,
    handleDateSelect,
    dateRange,
  };
};

const constructOverviewData = async (
  fromDate: String,
  toDate: String,
  prevFromDate: String,
  prevToDate: String,
) => {
  if (!Config.DASHBOARD_API_URL) {
    return MOCK_DASHBOARD_OVERVIEW_ITEMS;
  }

  let result = [];

  const activeUsersCount = await DashboardService.getActiveUsersCount(
    fromDate,
    toDate,
  );
  const prevActiveUsersCount = await DashboardService.getActiveUsersCount(
    prevFromDate,
    prevToDate,
  );
  result.push({
    id: 3,
    name: 'Active Users',
    count: activeUsersCount,
    overviewItemicon: UserManagementSvg,
    itemOverviewType:
      activeUsersCount - prevActiveUsersCount !== 0 && 'neutral',
    variationCount:
      activeUsersCount - prevActiveUsersCount !== 0 &&
      activeUsersCount - prevActiveUsersCount,
  });

  const policyViolationsCount = await DashboardService.getPolicyViolationsCount(
    fromDate,
    toDate,
  );
  const prevPolicyViolationsCount = await DashboardService.getPolicyViolationsCount(
    prevFromDate,
    prevToDate,
  );

  result.push({
    id: 1,
    name: 'Policy Violations',
    count: policyViolationsCount,
    overviewItemicon: WarningSvg,
    itemOverviewType:
      policyViolationsCount - prevPolicyViolationsCount !== 0 && 'negative',
    variationCount:
      policyViolationsCount - prevPolicyViolationsCount !== 0 &&
      policyViolationsCount - prevPolicyViolationsCount,
  });

  const policyChangesCount = await DashboardService.getPolicyChangesCount(
    fromDate,
    toDate,
  );
  const prevPolicyChangesCount = await DashboardService.getPolicyChangesCount(
    prevFromDate,
    prevToDate,
  );

  result.push({
    id: 2,
    name: 'Policy Changes',
    count: policyChangesCount,
    overviewItemicon: PolicyChangesSvg,
    itemOverviewType:
      policyChangesCount - prevPolicyChangesCount !== 0 && 'neutral',
    variationCount:
      policyChangesCount - prevPolicyChangesCount !== 0 &&
      policyChangesCount - prevPolicyChangesCount,
  });

  const activeAppsCount = await DashboardService.getActiveAppsCount(
    fromDate,
    toDate,
  );
  const prevActiveAppsCount = await DashboardService.getActiveAppsCount(
    prevFromDate,
    prevToDate,
  );
  result.push({
    id: 4,
    name: 'Active Apps',
    count: activeAppsCount,
    overviewItemicon: AppManagementSvg,
    itemOverviewType: activeAppsCount - prevActiveAppsCount !== 0 && 'neutral',
    variationCount:
      activeAppsCount - prevActiveAppsCount !== 0 &&
      activeAppsCount - prevActiveAppsCount,
  });

  return result;
};

const getPrevViolations = (usergroupName: String, violationsData: any) => {
  for (const violationData of violationsData) {
    if (violationData['usergroupName'] === usergroupName) {
      return violationData['violations'];
    }
  }
  return 0;
};

const constructUserGroupViolationsData = async (
  fromDate: String,
  toDate: String,
  prevFromDate: String,
  prevToDate: String,
) => {
  if (!Config.DASHBOARD_API_URL) {
    return MOCK_USER_GROUPS_VIOLATIONS_WIDGET;
  }

  let result = [];

  const userGroupsViolationsCurrentData = await DashboardService.getUserGroupsViolations(
    fromDate,
    toDate,
  );

  const userGroupsViolationsPrevData = await DashboardService.getUserGroupsViolations(
    prevFromDate,
    prevToDate,
  );

  for (const violationData of userGroupsViolationsCurrentData) {
    const currentViolations = violationData['violations'];
    const prevViolations = getPrevViolations(
      violationData['usergroupName'],
      userGroupsViolationsPrevData,
    );
    const increment = currentViolations - prevViolations;
    const data = { ...violationData, increment: increment };
    result.push(data);
  }
  return result;
};

export const useDashboardData = (
  fromDate: String,
  toDate: String,
  prevFromDate: String,
  prevToDate: String,
) => {
  const [userLoginsPerDay, setUserLoginsPerDay] = useState<
    { date: string; logins: number }[]
  >([]);
  const [appsViolations, setAppsViolations] = useState([]);
  const [categoryViolations, setCategoryViolations] = useState([]);
  const [usersViolations, setUsersViolations] = useState([]);
  const [userGroupsViolations, setUserGroupsViolations] = useState<any>([]);
  const [appsAccessed, setAppsAccessed] = useState([]);
  const [overviewData, setOverviewData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    DashboardService.getUserLogins(fromDate, toDate).then((response) => {
      const requiredUserLoginsData: { date: string; logins: number }[] = [];
      response.map((item: any) => {
        const requiredDate = new Date(item.date).toISOString();
        requiredUserLoginsData.push({
          date: getDateAndTimeFromISO(requiredDate).date,
          logins: item.logins,
        });
      });
      setUserLoginsPerDay(requiredUserLoginsData);
    });
    DashboardService.getAppsViolations(fromDate, toDate).then((response) => {
      response.sort((a: any, b: any) => b.violations - a.violations);
      setAppsViolations(response);
    });
    DashboardService.getCategoryViolations(fromDate, toDate).then(
      (response) => {
        response.sort((a: any, b: any) => b.violations - a.violations);
        setCategoryViolations(response);
      },
    );
    DashboardService.getUsersViolations(fromDate, toDate).then((response) =>
      setUsersViolations(response),
    );
    DashboardService.getAppsAccessed(fromDate, toDate).then((response) =>
      setAppsAccessed(response),
    );
    constructUserGroupViolationsData(
      fromDate,
      toDate,
      prevFromDate,
      prevToDate,
    ).then((response) => setUserGroupsViolations(response));

    constructOverviewData(fromDate, toDate, prevFromDate, prevToDate)
      .then((response) => setOverviewData(response))
      .finally(() => setLoading(false));
  }, [fromDate, toDate]);
  return {
    userLoginsPerDay,
    appsViolations,
    categoryViolations,
    usersViolations,
    userGroupsViolations,
    appsAccessed,
    overviewData,
    loading,
  };
};
