import React, { useEffect, useState } from 'react';
import HelpService from '../../../api-service/help-service/Helpservice';
import { IDetailPageProps } from '../../../core-utils/constants';
import HTMLRenderer from '../../organisms/HTMLRenderer';

const HelpDetailsPage = ({ match }: IDetailPageProps) => {
  const questionId = match.params.id;
  const [htmlContent, setHtmlContent] = useState();
  const getHtmlContent = async () => {
    const detailHtmlContent = await HelpService.getHtmlContent(questionId);
    setHtmlContent(detailHtmlContent);
  };

  useEffect(() => {
    getHtmlContent();
  }, []);

  return <HTMLRenderer html={htmlContent} />;
};

export default HelpDetailsPage;
