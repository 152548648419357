import * as React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import theme, { EXTRA_COLORS } from '@utils/theme';
import Button from '@atoms/Button';
import {
  BUTTON_LABEL_CONSTANTS,
  IDeleteUserPopUp,
} from '@utils/constants';
import { TypographyHeading } from '../ApplicationEmptyState';
import IconButton from '@atoms/IconButton';
import CloseActive from '@assets/close-active.svg';
import CloseInactive from '@assets/close-inactive.svg';
import IconFromSvg from '@atoms/IconFromSvg';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: theme.spacing(100),
  outerHeight: theme.spacing(48.5),
  bgcolor: theme.palette.common.white,
  borderRadius: theme.spacing(1.25),
  boxShadow: `${theme.spacing(0)} ${theme.spacing(5)} ${theme.spacing(
    6,
  )} rgba(0, 0, 0, 0.08)`,
  p: 4,
  px: 6,
  pb: 6,
};

const ConfirmPopUp = ({
  content,
  open,
  handleClose,
  headingText,
  buttonText,
  onButtonClick,
}: IDeleteUserPopUp) => {
  const [hover, setHover] = React.useState(false);

  const handleButtonClick = (e: any) => {
    onButtonClick && onButtonClick(e);
    handleClose();
  };
  return (
    <React.Fragment>
      <Modal open={open}>
        <Box
          sx={style}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          gap={theme.spacing(2.5)}
          className="w-full max-w-xs mx-4"
        >
          <Box display="flex" justifyContent="space-between">
            <TypographyHeading>{headingText}</TypographyHeading>
            <IconButton
              placeholder="close"
              onPointerEnter={() => setHover(true)}
              onPointerLeave={() => setHover(false)}
              onClick={handleClose}
              onClickCapture={() => setHover(false)}
              sx={{
                padding: `${theme.spacing(0)} ${theme.spacing(0)}`,
                minWidth: 32,
              }}
            >
              {hover ? (
                <IconFromSvg path={CloseActive} alt="close-active" />
              ) : (
                <IconFromSvg path={CloseInactive} alt="close-inactive" />
              )}
            </IconButton>
          </Box>

          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
              paddingRight: theme.spacing(12.5),
            }}
          >
            {content}
          </Typography>
          <Box
            display="flex"
            alignItems="flex-end"
            flexDirection="row-reverse"
            gap={theme.spacing(2.5)}
          >
            <Button
              variant="contained"
              color="error"
              onClick={handleButtonClick}
            >
              {buttonText
                ? buttonText
                : BUTTON_LABEL_CONSTANTS.DELETE_BUTTON_LABEL}
            </Button>
            <Button
              variant="text"
              color="primary"
              onClick={handleClose}
              sx={{ '&:hover': { backgroundColor: EXTRA_COLORS.lightBlue } }}
            >
              {BUTTON_LABEL_CONSTANTS.CANCEL_BUTTON_LABEL}
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default ConfirmPopUp;
