import React, { useEffect } from 'react';
import { EMPTY_FUNCTION, IDropDownItem } from '../../../core-utils/constants';

export const useMultipleSelections = (
  items: Array<IDropDownItem>,
  selectedItems: Array<IDropDownItem>,
  handleSaveButton?: () => void,
  editDetails?: boolean,
) => {
  const [displayedItems, setDisplayedItems] = React.useState<
    Array<IDropDownItem>
  >(items);

  const [currentSelectedItems, setCurrentSelectedItems] = React.useState<
    Array<IDropDownItem>
  >(selectedItems);


  useEffect(() => {
    handleSaveButton ? handleSaveButton() : EMPTY_FUNCTION();
  }, [currentSelectedItems]);

  editDetails &&
    useEffect(() => {
      setCurrentSelectedItems(selectedItems);
    }, [selectedItems]);

  useEffect(() => {
    !displayedItems.length && setDisplayedItems(items);
  }, [items]);

  const removeItem = (id: string) => {
    const tempSelectedItems = currentSelectedItems.filter(
      (item) => item.id !== id,
    );

    setCurrentSelectedItems(tempSelectedItems);
  };

  const handleChange = (id: string) => {
    const selectedItemIndex = currentSelectedItems.findIndex(
      (item) => item.id === id,
    );

    if (selectedItemIndex != -1) {
      // Item already exists - removing it from selected items
      removeItem(id);
    } else {
      const currentSelectedItem = items.find((item) => item.id === id);
      if (currentSelectedItem) {
        setCurrentSelectedItems([...currentSelectedItems, currentSelectedItem]);
      }
    }
  };

  const sortBySelectedItems = () => {
    const tempUnselectedItems = displayedItems.filter(function (displayedItem) {
      return !currentSelectedItems.some(
        (selectedItem) => selectedItem.id === displayedItem.id,
      );
    });
    const tempDisplayedItems = [
      ...currentSelectedItems,
      ...tempUnselectedItems,
    ];
    setDisplayedItems(tempDisplayedItems);
  };

  const resetSelectedItems = () => {
    setCurrentSelectedItems(selectedItems);
  };

  return {
    currentSelectedItems,
    handleChange,
    removeItem,
    displayedItems,
    sortBySelectedItems,
    resetSelectedItems,
  };
};
