import * as React from 'react';
import { Tabs as MuiTabs, TabsProps } from '@mui/material';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import TabItem, { ITabItemProps } from '../../atoms/TabItem';
import { useTabs } from './index.hook';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import PolicyBuilderConfirmation from '../../molecules/PolicyBuilderConfirmationPopup';
import { unlockTabSwitch } from '../../../core-utils/Helper/helper';
import { BUTTON_LABEL_CONSTANTS } from '../../../core-utils/constants';
import settingsMessages from '../../../core-utils/messages/settings';
import { useShowOrHideElement } from '../../../core-utils/Hooks/index.hook';

export interface ITabProps extends ITabItemProps {
  // tabpanel prop holds the component that will be rendered when the tab is clicked.
  tabpanel?: React.ReactNode;
  tabId?: number;
  tabHash?: string;
}
export interface ITabsProps extends TabsProps {
  tabs: ITabProps[];
  selectedindex?: number;
  tabIdChangeHandler?: any;
}

const StyledTab = styled(MuiTabs)<ITabsProps>(() => ({
  '.MuiTabs-indicator': {
    background: theme.palette.common.black,
  },
}));

const Tabs = ({ ...rest }: ITabsProps) => {
  const { open, showElement, hideElement } = useShowOrHideElement();

  const [tabChangeParameters, setTabChangeParameters] = React.useState<{
    event: React.SyntheticEvent<Element, Event>;
    nextValue: any;
  }>();

  const handleSwitchDenied = (
    event: React.SyntheticEvent<Element, Event>,
    nextValue: any,
  ) => {
    setTabChangeParameters({ event: event, nextValue: nextValue });
    showElement();
  };

  const { activeTabValue, handleTabChange } = useTabs(
    rest.selectedindex,
    handleSwitchDenied,
    rest.tabIdChangeHandler,
  );

  const handleLeaveClick = () => {
    hideElement();
    unlockTabSwitch();
    tabChangeParameters &&
      handleTabChange(
        tabChangeParameters?.event,
        tabChangeParameters?.nextValue,
      );
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          borderBottom: `${theme.spacing(0.25)} solid ${
            EXTRA_COLORS.lightGrayishBlue
          }`,
        }}
      >
        <StyledTab
          textColor="inherit"
          aria-label="tabs"
          value={activeTabValue}
          onChange={handleTabChange}
          {...rest}
        >
          {rest.tabs.map((tab, index) => {
            return <TabItem key={`tab${index}`} {...tab} />;
          })}
        </StyledTab>
      </Box>
      <PolicyBuilderConfirmation
        open={open}
        leaveButtonLabel={BUTTON_LABEL_CONSTANTS.LEAVE_BUTTON_LABEL}
        cancelButtonLabel={BUTTON_LABEL_CONSTANTS.CANCEL_BUTTON_LABEL}
        heading={settingsMessages.SAVE_CHANGES}
        subHeading={settingsMessages.LEAVE_CONFIRMATION_MESSAGE}
        onCancel={hideElement}
        onLeave={handleLeaveClick}
      />
      {rest.tabs.map((tab, index) => {
        return index == activeTabValue ? (
          <Box width="100%" mt={5} key={`restTab${index}`} sx={{ fontFamily: 'Manrope' }}>
            {tab.tabpanel}
          </Box>
        ) : null;
      })}
    </Box>
  );
};

export default Tabs;
