import * as React from 'react';
import { Button as MUIButton, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import theme from '../../../core-utils/theme';

const StyledIconButton = styled(MUIButton)({
  ...theme.typography.body2,
  textTransform: 'none',
  color: theme.palette.text.disabled,
  borderRadius: '0px',
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
});
export interface IIconButtonProps extends ButtonProps {
  icon?: JSX.Element;
}

const IconButton = ({ children, icon, ...rest }: IIconButtonProps) => {
  return (
    <StyledIconButton startIcon={icon} {...rest}>
      {children}
    </StyledIconButton>
  );
};

export default IconButton;
