import axios from '../axios';
import Config from '../../../config/config';
import { MOCK_POLICIES } from '../../core-utils/constants';
import {
  MOCK_POLICY_PANEL_RESPONSE,
  SAMPLE_APPLICATION_DROPDOWN,
  SAMPLE_OPTION_DROPDOWN,
  SAMPLE_POLICY_ACTIONS,
  SAMPLE_POLICY_CONDITIONS,
  // SAMPLE_POLICY_CONDITIONS,
  SAMPLE_POLICY_LISTS,
  SAMPLE_URL_DROPDOWN,
} from '../../core-utils/test-constants';
import { MOCK_POLICY_BLOCKS_DATA } from '../../_components/organisms/PolicyBlocksPanel/testData';
import { getAuthToken } from '../../core-utils/Helper/helper';
import { policyBlocksPanel } from '../../_components/organisms/PolicyBlocksPanel/index.stories';

export interface IListPolicyResponse {
  id: string;
  policyName: string;
  createdBy: string;
  applications: string[];
  lastPolicyHitTime: string;
  policyStatus: 'active' | 'inactive';
  lastUpdatedBy: string;
  lastUpdated: string;
  userGroups?: string[];
  disablePolicy?: boolean;
  showClose?: boolean;
  usergroupsOfPolicy?: any;
}

export interface IListPolicyBlockResponse {
  name: string;
}

export interface IListPolicyBlocksResponse {
  [key: string]: IListPolicyBlockResponse;
}

export interface IConditionBlock {
  name: string;
  operator: string;
  value: string;
  action: string;
}
export interface IPolicyCondition {
  type: string;
  conditionBlock: IConditionBlock[];
}
export interface IPolicyContent {
  policyBlockType: string;
  defaultAction: string;
  conditionList: IPolicyCondition[];
}

export interface IAddPolicyRequest {
  policyName: string;
  policyContent: IPolicyContent[];
}

export interface IAddListForPolicyRequest {
  name: string;
  values: string[];
}

export interface IAddPoliciesToGroupOrUserRequestBody {
  op: string;
  path: string;
  value: string;
}

const POLICY_BASE_URL = 'api/v1/policydef';
const POLICY_BLOCK_URL = 'api/v1/policyblock';
const POLICY_CONDITION_URL = 'api/sonet/v1/ConditionValues';

const PolicyService = {
  getAllPolicies: async () => {
    try {
      const policyList = await axios.get(
        `${Config.BACKEND_BASE_URL}${POLICY_BASE_URL}`,
      );
      return policyList.data;
    } catch (error: any) {
      return error;
    }
  },

  getAllPolicyBlocks: async () => {
    try {
      //return MOCK_POLICY_PANEL_RESPONSE;
      const policyBlocks = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/policyBlock`,
      );

      return policyBlocks.data;
    } catch (error) {
      return error;
    }
  },

  getPolicyBlockConditionMembers: async (policyBlockId: string | number) => {
    try {
      const conditionList = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/policyBlock/${policyBlockId}/memberConditions`,
      );
      return conditionList.data;
    } catch (error) {
      return error;
    }
  },

  getPolicyById: async (id: string) => {
    try {
      const policyResponse = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/policyDef/${id}`,
      );
      return policyResponse.data;
    } catch (error: any) {
      return error.response.status;
    }
  },

  getConditionById: async (conditionId: string) => {
    try {
      const conditionResponse = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/condition/${conditionId}`,
      );
      return conditionResponse.data;
    } catch (error: any) {
      return error;
    }
  },

  addListForPolicy: async (
    conditionId: string,
    listDetails: IAddListForPolicyRequest,
  ) => {
    try {
      const addListResponse = await axios.post(
        `${Config.BACKEND_BASE_URL}${POLICY_CONDITION_URL}/${conditionId}`,
        listDetails,
      );
      return addListResponse.data;
    } catch (error) {
      return error;
    }
  },

  getPolicyActions: async () => {
    try {
      return SAMPLE_POLICY_ACTIONS;
      // TODO :Repalace the url with actual api endpoints once we get that.
    } catch (error) {
      return error;
    }
  },
  getPolicyConditions: async (policyBlockId: string) => {
    try {
      return SAMPLE_POLICY_CONDITIONS;
      // TODO :Repalace the url with actual api endpoints once we get that.
    } catch (error) {
      return error;
    }
  },
  getPolicyLists: async () => {
    try {
      return SAMPLE_POLICY_LISTS;
      // TODO :Repalace the url with actual api endpoints once we get that.
    } catch (error) {
      return error;
    }
  },

  getGeolocationConditionList: async () => {
    try {
      return SAMPLE_OPTION_DROPDOWN;
    } catch (error) {
      return error;
    }
  },
  getApplicationConditionList: async () => {
    try {
      return SAMPLE_APPLICATION_DROPDOWN;
    } catch (error) {
      return error;
    }
  },
  getUrlConditionList: async () => {
    try {
      return SAMPLE_URL_DROPDOWN;
    } catch (error) {
      return error;
    }
  },
  addPolicy: async (policyData: IAddPolicyRequest) => {
    // TODO: Revamp this API once it is developed
    try {
      const addApplicationResponse = await axios.post(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/policyDef`,
        policyData,
      );
      return addApplicationResponse.data;
    } catch (error: any) {
      throw new Error(error);
    }
  },

  updatePolicy: async (policyData: any, policyId: string) => {
    try {
      const updateApplicationResponse = await axios.put(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/policyDef/${policyId}`,
        policyData,
      );
      return updateApplicationResponse.config.data;
    } catch (error) {
      return error;
    }
  },
  deletePolicy: async (policyId: string) => {
    try {
      await axios.delete(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/policyDef/${policyId}`,
      );
    } catch (error) {
      return error;
    }
  },
  listPolicies: async () => {
    // Replace this when we have apis
    //return MOCK_POLICIES;
    try {
      const listPoliciesResponse = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/policyDef`,
      );
      let result = [];
      const policiesResponseObject = listPoliciesResponse.data;
      for (const id in policiesResponseObject) {
        policiesResponseObject[id].policyContent = JSON.parse(
          policiesResponseObject[id].policyContent,
        );
        result.push({
          ...policiesResponseObject[id],
          id: id,
          applications: [],
        });
      }
      return result;
      //return listPoliciesResponse.data as IListPolicyResponse;
    } catch (error: any) {
      console.log(error);
      return error;
    }
  },
  isAppAllowedByPolicy: async (appId: Number | String) => {
    try {
      const allowedResponse = await axios.post(
        `${Config.BACKEND_BASE_URL}api/sonet/policy/appAccess`,
         {
          appId: appId.toString(),
          token: getAuthToken() ?? '',
        }
      );
      return allowedResponse.data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  },
  isScannerAllowedByPolicy: async () => {
    try {
      const allowedResponse = await axios.post(
        `${Config.BACKEND_BASE_URL}api/sonet/policy/deviceAccess`,
        {
          appId: 1,
          token: getAuthToken() ?? '',
          deviceAccessSource: "localscan",
          policyBlockType: "deviceAccess"
        }
      );
      return allowedResponse.data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  },
  getCitiesList: async (query: string) => {
    try {
      const response = await axios.get(
        `${Config.DASHBOARD_API_URL}cities2?query=${query}`,
      );
      return response.data;
    } catch (error: any) {
      console.error(error);
      return SAMPLE_OPTION_DROPDOWN;
    }
  },
  getAppsList: async () => {
    try {
      const response = await axios.get(`${Config.DASHBOARD_API_URL}apps`);
      return response.data;
    } catch (error: any) {
      console.error(error);
      return [];
    }
  },
  getDeviceAccessList: async () => {
    try {
      const response = await axios.get(`${Config.BACKEND_BASE_URL}api/v1/device/accessInfo`);
      return response.data;
    } catch (error: any) {
      console.error(error);
      return [];
    }
  },
  getContentInspectionValuesList: async () => {
    try {
      const response = await axios.get(
        `${Config.BACKEND_BASE_URL}api/v1/clouddlp/contentInfo`,
      );
      return response.data;
    } catch (error: any) {
      console.error(error);
      return [];
    }
  },
};

export default PolicyService;
