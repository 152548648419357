const dashboardMessages = {
  USER_LOGIN_WIDGET_MESSAGE: 'User logins per day',
  OVERVIEW: 'Overview',
  DASHBOARD: 'Dashboard',
  USERGROUPS_VIOLATIONS_WIDGET_MESSAGE: 'User groups with most violations',
  MOSTACTIVE_USERGROUPS_WIDGET_MESSAGE: 'Most active user groups',
  MOST_ACTIVE_USERS_WIDGET_MESSAGE: 'Most active users',
  USERS_WIDGET_APPLICATIONS: 'No. of times applications used',
  APPS_ACCESSED_WIDGET_MESSAGE: 'Apps accessed the most',
  APPS_VIOLATIONS_WIDGET_MESSAGE: 'Apps with most policy violations',
  USERS_VIOLATIONS_WIDGET_MESSAGE: 'Top users with policy violations',
  USERS_WIDGET_NAME: 'Name',
  USERS_WIDGET_VIOLATIONS: 'No.of violations',
  VIEW_ALL: 'View all',
  CATEGORY_VIOLATIONS_WIDGET_MESSAGE: 'Top violations by category',
  EVENTS_SEVERITY_WIDGET: 'Log events by severity level',
  NO_INFORMATION_MESSAGE: 'There is no information for the selected date range',
  TOTAL_CATEGORY_VIOLATIONS_MESSAGE: 'Total violations by category',
};
export default dashboardMessages;
