import { Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { USERS_IN_GROUPS_HEADERS } from '../../../core-utils/constants';
import { getHeight } from '../../../core-utils/Helper/helper';
import {
  useShowOrHideElement,
  useWindowSize,
} from '../../../core-utils/Hooks/index.hook';
import messages from '../../../core-utils/messages';
import userGroupMessages from '../../../core-utils/messages/usergroup';
import Button from '../../atoms/Button';
import EmptyAssignmentState from '../../molecules/NoAssignmentState';
import { IUserTableRow } from '../../molecules/UserTableRow';
import AddUser from '../AddUser';
import Table from '../Table';
import UsersTable from '../UsersTable';

interface IUserGroupUsersTabItemProps {
  users?: IUserTableRow[];
  userGroupName?: string;
  getUsersOfUserGroup?: any;
}

const UserGroupUsersTabItem = ({
  users,
  userGroupName,
  getUsersOfUserGroup,
}: IUserGroupUsersTabItemProps) => {
  const theme = useTheme();
  const { open, hideElement, showElement } = useShowOrHideElement();
  const [arrow, setArrow] = useState<boolean>(false);
  const [tabItemUsers, setTabItemUsers] = useState(users);

  const reverseUsersList = () => {
    tabItemUsers && setTabItemUsers([...tabItemUsers].reverse());
  };

  useEffect(() => {
    setTabItemUsers(users);
    setArrow(!arrow);
  }, [users]);

  const { height } = useWindowSize();

  return (
    <Grid container>
      <Grid
        container
        item
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
        sx={{ marginBottom: theme.spacing(4) }}
      >
        <Typography sx={{ color: theme.palette.text.disabled }}>
          {messages.USER_GROUP_ACCESS_HEADING}
        </Typography>
        <AddUser
          isOpen={open}
          onClose={hideElement}
          usergroupOrAppId={userGroupName}
          usersAssignedToGroup={users}
          getUsers={getUsersOfUserGroup}
        />
        {userGroupName?.trim().toLowerCase() !==
          userGroupMessages.ALL_USERS_GROUP.trim().toLowerCase() && (
          <Button variant="contained" onClick={showElement}>
            {messages.ADD_USER}
          </Button>
        )}
      </Grid>
      <Grid
        item
        sx={{
          height: tabItemUsers?.length ? getHeight(height) : 'fit-content',
        }}
        xs={12}
        container
      >
        {tabItemUsers?.length ? (
          <UsersTable
            underUserGroupsOrAppsTab={true}
            tabledata={tabItemUsers}
            onNameSortClick={reverseUsersList}
            arrow={arrow}
            hideUserStatus={true}
          />
        ) : (
          <>
            <Table headers={USERS_IN_GROUPS_HEADERS} />
            <EmptyAssignmentState displayText={messages.NO_USERS_ASSIGNED} />
          </>
        )}
      </Grid>
    </Grid>
  );
};
export default UserGroupUsersTabItem;
