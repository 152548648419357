import {
  Dialog,
  DialogProps,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import theme from '../../../core-utils/theme';
import Button from '../../atoms/Button';

export interface IEntityPopulation extends DialogProps {
  heading: string;
  subHeading?: string;
  onClose: () => void;
  isSaveDisabled: boolean;
  children?: any;
  buttonLabelOne: string;
  buttonLabelTwo: string;
  handleSaveClick?: () => void;
}

const CustomDialog = (props: IEntityPopulation) => {
  const {
    heading,
    subHeading,
    onClose,
    children,
    isSaveDisabled,
    open,
    handleSaveClick,
    buttonLabelOne,
    buttonLabelTwo,
  } = props;

  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose} sx={{ ...props.sx }}>
      <Grid
        container
        sx={{
          padding: theme.spacing(6),
          boxShadow: ' 0px 10px 10px rgba(0, 0, 0, 0.06)',
        }}
        direction="column"
        spacing={6}
      >
        <Grid item container justifyContent="space-between">
          <Stack direction="column">
            <Typography variant="h3">{heading}</Typography>
            <Typography
              sx={{ color: theme.palette.text.disabled }}
              variant="body2"
            >
              {subHeading}
            </Typography>
          </Stack>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>

        {children}
        <Grid container item justifyContent="flex-end">
          <Grid item>
            <Button
              sx={{
                width: theme.spacing(28),
                height: theme.spacing(10),
              }}
              color="primary"
              variant="text"
              onClick={onClose}
            >
              {buttonLabelOne}
            </Button>
            <Button
              sx={{
                width: theme.spacing(28),
                height: theme.spacing(10),
                textAlign: 'center',
              }}
              color="primary"
              variant="contained"
              disabled={isSaveDisabled}
              onClick={handleSaveClick}
            >
              {buttonLabelTwo}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CustomDialog;
