import React from 'react';
import { IDropdownOptionProps } from '../../molecules/Dropdown';
import PolicyBuilder from '../../organisms/PolicyBuilder';
import { EXTRA_COLORS } from '../../../core-utils/theme';
import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import { IPolicyBlockProps } from '../../atoms/DraggablePolicyBlock';
import policyMessages from '../../../core-utils/messages/policyMessages';
import IconFromSvg from '../../atoms/IconFromSvg';
import CollapseAllIcon from '../../../../public/assets/collapse-expand.svg';
import { IPolicyMultipleSelectChip } from '../../organisms/PolicyMultipleChipDropdown';
import PolicyIconsHelpTooltip from '../../molecules/PolicyIconsHelpTooltip';
import policyBuilderMessages from '../../../core-utils/messages/policyBuilder';

export interface IPolicyDashboardProps {
  policyCardData: any;
  userGroupDropdown: IDropdownOptionProps[];
  applicationDropdown: IDropdownOptionProps[];
  urlDropdown: IDropdownOptionProps[];
  handlePolicyChange: (cardd: number, id: number, value: any) => void;
  handleEquationChange: (cardId: number, id: number, value: any) => void;
  handleDeleteRow?: (cardId: number, id: number) => void;
  handlePolicyValueTextChange?: (
    cardId: number,
    id: number,
    event: any,
  ) => void;
  handlePolicyValueDropdownChange?: (
    cardId: number,
    id: number,
    value: any,
  ) => void;
  handlePolicyActionChange?: (cardId: number, value: any, id?: number) => void;
  handleAddCondition: (cardId: number) => void;
  handleJoinCondition: (cardId: number, rowId: number) => void;
  handleDeleteCard?: (cardId: number) => void;
  handleCopyCard?: (cardId: number) => void;
  handleSaveCard?: (cardId: number) => void;
  handleToggleCollapse: (cardId: number) => void;
  addPolicyBlockMolecule?: JSX.Element;
  addPolicyBlockEmptyMolecule?: JSX.Element;
  showSelectPolicyBlock?: boolean;
  handleSelectPolicyBlock: any;
  policyBlocks: IPolicyBlockProps[];
  handleCollapseAll: () => void;
  handleDropConditions: (cardId: number, conditionIndex: string) => void;
  handleOpenSnackbar?: () => void;
  handleCloseSnackbar?: () => void;
  focusedCardId?: number;
  handleClickCard?: (cardItemId: number) => void;
  handleChangeTimeValue?: (
    cardId: number,
    rowId: number,
    startTime: string,
    endTime: string,
  ) => void;
}

const PolicyBuilderDashboard = ({
  focusedCardId,
  policyCardData,
  userGroupDropdown,
  urlDropdown,
  applicationDropdown,
  handlePolicyChange,
  handleEquationChange,
  handlePolicyActionChange,
  handlePolicyValueTextChange,
  handlePolicyValueDropdownChange,
  handleAddCondition,
  handleDeleteRow,
  handleDeleteCard,
  handleCopyCard,
  handleSaveCard,
  handleToggleCollapse,
  addPolicyBlockMolecule,
  addPolicyBlockEmptyMolecule,
  showSelectPolicyBlock,
  handleSelectPolicyBlock,
  policyBlocks,
  handleCollapseAll,
  handleDropConditions,
  handleOpenSnackbar,
  handleCloseSnackbar,
  handleClickCard,
  handleChangeTimeValue,
  handleJoinCondition,
}: IPolicyDashboardProps) => {
  const theme = useTheme();
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        // height: '100%',
        width: '100%',
        backgroundColor: EXTRA_COLORS.bluishBackground,
        pl: 0,
        pt: 8,
        pr: 0,
      }}
    >
      {/* TODO: Currently only has functionality of policy cards */}
      {policyCardData?.length > 0 && (
        <Grid
          sx={{
            color: theme.palette.text.disabled,
            pr: theme.spacing(8),
          }}
          item
          container
          alignItems="center"
          justifyContent="flex-end"
        >
          <Grid item>
            <Typography variant="body2">
              {policyMessages.COLLAPSE_ALL}
            </Typography>
          </Grid>
          <PolicyIconsHelpTooltip
            content={policyBuilderMessages.HELP_TOOLTIP.COLLAPSE_ALL_BLOCKS}
            children={
              <Grid item>
                <IconButton onClick={handleCollapseAll}>
                  <IconFromSvg path={CollapseAllIcon} alt="collapse" />
                </IconButton>
              </Grid>
            }
          />
        </Grid>
      )}
      <Grid item width="100%">
        <PolicyBuilder
          focusedCardId={focusedCardId}
          policyCardData={policyCardData}
          handleClickCard={handleClickCard}
          handlePolicyChange={handlePolicyChange}
          handleToggleCollapse={handleToggleCollapse}
          handleEquationChange={handleEquationChange}
          handlePolicyActionChange={handlePolicyActionChange}
          userGroupDropdown={userGroupDropdown}
          applicationDropdown={applicationDropdown}
          urlDropdown={urlDropdown}
          handleDeleteRow={handleDeleteRow}
          handlePolicyValueTextChange={handlePolicyValueTextChange}
          handlePolicyValueDropdownChange={handlePolicyValueDropdownChange}
          handleAddCondition={handleAddCondition}
          handleDeleteCard={handleDeleteCard}
          handleCopyCard={handleCopyCard}
          handleSaveCard={handleSaveCard}
          addPolicyBlockMolecule={addPolicyBlockMolecule}
          addPolicyBlockEmptyMolecule={addPolicyBlockEmptyMolecule}
          showSelectPolicyBlock={showSelectPolicyBlock}
          handleSelectPolicyBlock={handleSelectPolicyBlock}
          policyBlocks={policyBlocks}
          handleDropConditions={handleDropConditions}
          handleOpenSnackbar={handleOpenSnackbar}
          handleCloseSnackbar={handleCloseSnackbar}
          handleChangeTimeValue={handleChangeTimeValue}
          handleJoinCondition={handleJoinCondition}
        />
      </Grid>
    </Grid>
  );
};

export default PolicyBuilderDashboard;
