import {
  Dialog,
  DialogProps,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { BUTTON_LABEL_CONSTANTS } from '../../../core-utils/constants';
import appModuleMessages from '../../../core-utils/messages/app';
import { EXTRA_COLORS } from '../../../core-utils/theme';
import Button from '../../atoms/Button';

interface IApplicationAccessPopUp extends DialogProps {
  open: boolean;
  mainText: string;
  onCancelClick?: any;
  onAllowClick?: any;
  heading?: string;
  buttonText?: string;
}

const ApplicationAccessPopUp = (props: IApplicationAccessPopUp) => {
  const theme = useTheme();
  const {
    onCancelClick,
    onAllowClick,
    open,
    mainText,
    buttonText,
    heading,
  } = props;
  return (
    <Dialog open={open}>
      <Grid
        container
        sx={{
          width: 449,
          height: 265,
          background: theme.palette.background.paper,
          boxSizing: 'border-box',
          boxShadow: '0px 4px 20px rgba(40, 40, 40, 0.08)',
          borderRadius: theme.spacing(1.25),
          padding: `${theme.spacing(9, 12.5)}`,
        }}
        display="flex"
        direction="column"
        alignItems="center"
      >
        <Grid item>
          <Typography
            variant="h2"
            sx={{ color: theme.palette.text.primary, paddingBottom: 3 }}
          >
            {heading ? heading : appModuleMessages.APP_ACCESS_HEADING}
          </Typography>
        </Grid>

        <Divider orientation="horizontal" flexItem />

        <Grid
          sx={{
            color: theme.palette.text.disabled,
            paddingTop: 5,
            paddingBottom: 5,
          }}
          item
        >
          <Typography
            sx={{
              textAlign: 'center',
            }}
            variant="body1"
          >
            {mainText}
          </Typography>
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item marginRight={2}>
            <Button
              onClick={onCancelClick}
              sx={{
                '&:hover': { backgroundColor: EXTRA_COLORS.lightBlue },
                width: 137,
                height: 40,
                borderRadius: theme.spacing(1.25),
              }}
              variant="text"
            >
              {BUTTON_LABEL_CONSTANTS.CANCEL_BUTTON_LABEL}
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={onAllowClick}
              sx={{
                width: 137,
                height: 40,
                borderRadius: theme.spacing(1.25),
              }}
              variant="contained"
              color="primary"
            >
              {buttonText
                ? buttonText
                : BUTTON_LABEL_CONSTANTS.ALLOW_ACCESS_BUTTON_LABEL}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};
export default ApplicationAccessPopUp;
