import React, { useState } from 'react';
import PolicyConditionCell from '../../molecules/PolicyConditionCell';
import { Box, Grid, IconButton } from '@mui/material';
import {
  DRAGGABLE_POLICY__ITEMS_ID,
  POLICY_ALLOWANCE_OPTIONS,
  POLICY_EQUATION_OPTIONS,
  POLICY_PARAMETER_OPTIONS,
} from '../../../core-utils/constants';
import IconFromSvg from '../../atoms/IconFromSvg';
import CloseIcon from '../../../../public/assets/close-inactive.svg';
import PolicyValueItem from './policyValueItem';
import JoinIcon from '../../../../public/assets/add-compound.svg';
import JoinActiveIcon from '../../../../public/assets/add-compound-active.svg';

import theme from '../../../core-utils/theme';
import TextField from '../../atoms/TextField';
import PolicyHelpTooltip from '../../molecules/PolicyHelpTooltip';

interface IPolicyConditionRowProps {
  cardId: number;
  id: number;
  parameters?: any;
  parameterValue: any;
  equationValue: any;
  allowanceValue: any;
  policyValue: any;
  userGroupDropdownOptions: any;
  applicationDropdownOptions: any;
  urlDropdownOptions: any;
  handlePolicyChange: (cardd: number, id: number, value: any) => void;
  handleEquationChange: (cardId: number, id: number, value: any) => void;
  handleDeleteRow?: (cardId: number, id: number) => void;
  handleJoinCondition: (cardId: number, rowId: number) => void;
  handlePolicyValueTextChange?: (
    cardId: number,
    id: number,
    event: any,
  ) => void;
  handlePolicyValueDropdownChange?: (
    cardId: number,
    id: number,
    value: any,
  ) => void;
  handlePolicyActionChange?: (cardId: number, value: any, id?: number) => void;
  handleDropCondition?: (
    cardId: number,
    conditionIndex: string,
    rowId?: number,
  ) => void;
  handleOpenSnackbar?: () => void;
  handleCloseSnackbar?: () => void;
  lastRow?: boolean;
  handleChangeTimeValue?: (
    cardId: number,
    rowId: number,
    startTime: string,
    endTime: string,
    timezone?: string,
  ) => void;
  policyValueErrorMessage?: string;
  showJoin?: boolean;
  rowSequence?: string;
}

const PolicyConditionRow = ({
  cardId,
  id,
  parameters,
  parameterValue,
  equationValue,
  policyValue,
  allowanceValue,
  userGroupDropdownOptions,
  applicationDropdownOptions,
  urlDropdownOptions,
  lastRow,
  handleOpenSnackbar,
  handleCloseSnackbar,
  handleDeleteRow,
  handleJoinCondition,
  handlePolicyChange,
  handleEquationChange,
  handlePolicyActionChange,
  handlePolicyValueTextChange,
  handlePolicyValueDropdownChange,
  handleChangeTimeValue,
  handleDropCondition,
  policyValueErrorMessage,
  showJoin,
  rowSequence = '',
}: IPolicyConditionRowProps) => {
  const [hover, setHover] = useState<boolean>(false);
  const [hoverAddIcon, setHoverAddIcon] = useState<boolean>(false);
  return (
    <Grid
      container
      onPointerEnter={() => setHover(true)}
      onPointerLeave={() => setHover(false)}
      className="py-2 mt-4"
    >
      <PolicyConditionCell
        cyId={`policy-conditions-${rowSequence}`}
        variant="dropdown"
        isParameter={true}
        dropdownOptions={parameters.parametersList}
        dropdownValue={parameterValue}
        handleDropdownChange={(event: any, value: any) =>
          handlePolicyChange(cardId, id, value)
        }
      />
      <PolicyConditionCell
        cyId={`policy-options-${rowSequence}`}
        variant="dropdown"
        dropdownOptions={parameters.equationsList}
        dropdownValue={equationValue}
        handleDropdownChange={(_, value: any) =>
          handleEquationChange(cardId, id, value)
        }
      />
      <PolicyValueItem
        cardId={cardId}
        id={id}
        parameterValue={parameterValue}
        userGroupDropdownOptions={userGroupDropdownOptions}
        applicationDropdownOptions={applicationDropdownOptions}
        urlDropdownOptions={urlDropdownOptions}
        policyValue={policyValue}
        handleTextfieldChange={(policyId: number, event: any) =>
          handlePolicyValueTextChange?.(cardId, policyId, event)
        }
        handleDropdownChange={(policyId: number, event: any) =>
          handlePolicyValueDropdownChange?.(cardId, policyId, event)
        }
        handleChangeTimeValue={handleChangeTimeValue}
        policyValueErrorMessage={policyValueErrorMessage}
        sequenceRow={rowSequence}
      />
      {showJoin ? (
        <PolicyConditionCell
          cyId={`policy-actions-${rowSequence}`}
          cardId={cardId}
          rowId={id}
          handleOpenSnackbar={handleOpenSnackbar}
          handleCloseSnackbar={handleCloseSnackbar}
          handlePolicyActionChange={handlePolicyActionChange}
          variant="actions-dropdown"
          actions={parameters.actionsList}
          dropdownValue={allowanceValue}
          disableAnd={lastRow}
        />
      ) : (
        <TextField value="and" sx={{ width: 173 }} />
      )}

      <PolicyHelpTooltip operation="and">
        <Grid
          item
          alignSelf="center"
          visibility={showJoin ? 'visible' : 'hidden'}
          marginLeft={theme.spacing(3)}
          marginRight={theme.spacing(6)}
          onClick={() => handleJoinCondition(cardId, id)}
          onPointerEnter={() => setHoverAddIcon(true)}
          onPointerLeave={() => setHoverAddIcon(false)}
          sx={{ cursor: 'pointer' }}
          onDragOver={(event: any) => {
            event.preventDefault();
          }}
          onDrop={(event: any) => {
            const droppedItemId = event.dataTransfer.getData('text');

            if (
              droppedItemId.startsWith(
                DRAGGABLE_POLICY__ITEMS_ID.POLICY_CONDITION_ID,
              )
            ) {
              handleDropCondition?.(cardId, droppedItemId.split('_')[1], id);
            }
          }}
        >
          {hoverAddIcon ? (
            <IconFromSvg path={JoinActiveIcon} alt="join-active" />
          ) : (
            <IconFromSvg path={JoinIcon} alt="join" />
          )}
        </Grid>
      </PolicyHelpTooltip>

      <Grid
        item
        alignSelf="center"
        sx={{ cursor: 'pointer' }}
        visibility={hover ? 'visible' : 'hidden'}
        onClick={() => handleDeleteRow?.(cardId, id)}
      >
        <IconFromSvg path={CloseIcon} alt="Delete" />
      </Grid>
    </Grid>
  );
};

export default PolicyConditionRow;
