import { Box, Grid } from '@mui/material';
import React from 'react';
import { useWindowSize } from '../../../core-utils/Hooks/index.hook';
import theme, { customStyles } from '../../../core-utils/theme';

interface IOnBoardingTemplate {
  leftPanel?: any;
  header?: any;
  content?: any;
  footer?: any;
  target?: 'admin' | 'user';
}

const OnBoardingTemplate: React.FC<IOnBoardingTemplate> = ({
  leftPanel,
  header,
  content,
  footer,
  target,
}) => {
  const { height } = useWindowSize();
  const getHeight = (value: number) => {
    return height - value;
  };
  return (
    <Box height={height} sx={{ mb: 0 }}>
      <Box position="sticky" sx={{ top: 0 }} zIndex={1}>
        {header}
      </Box>
      <Box
        display="flex"
        overflow="hidden"
        position="relative"
        width="100%"
        height={getHeight(65)}
      >
        {leftPanel}
        <Grid
          container
          direction="column"
          width="100%"
          height="100%"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Grid
            item
            sx={{
              pl: 15,
              pr: 15,
              height: getHeight(140),
              ...customStyles.scrollBar,
              overflowY: 'scroll',
            }}
            alignItems="center"
            display="flex"
            justifyContent="center"
            container
          >
            {content}
          </Grid>
          <Grid
            item
            container
            sx={{
              width: '100%',
              height: '75px',
              position: 'sticky',
              background: 'white',
              bottom: 0,
              zIndex: 100,
              boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.08)',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Grid item width="72%">
              {footer}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OnBoardingTemplate;
