import React from 'react';

export const usePagination = () => {
  const [page, setPage] = React.useState(0);
  const tableRef = React.useRef<HTMLDivElement>(null);
  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };
  return { page, handleChangePage, tableRef };
};
