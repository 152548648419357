import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  EMPTY_STATE_ICONS,
  getColorsForCategoryWidget,
  ICategoryViolationsWidgetProps,
} from '../../../core-utils/constants';
import dashboardMessages from '../../../core-utils/messages/dashboard';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import EmptyState from '../EmptyState';

const style = {
  width: '100%',
  height: theme.spacing(150.5),
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[300]}`,
  backgroundColor: theme.palette.common.white,
};

const CustomisedAxisTick = (props: any) => {
  const { x, y, labels, index } = props;
  const requiredLabels = labels();
  return (
    <svg>
      <foreignObject x={x - 25} y={y} width={100} height={25}>
        <Typography
          variant="caption"
          sx={{ color: theme.palette.text.secondary }}
        >
          {requiredLabels[index]}
        </Typography>
      </foreignObject>
    </svg>
  );
};

const CategoryViolationsWidget = ({
  categoryViolationsData,
  title,
}: ICategoryViolationsWidgetProps) => {
  const getRequiredLabels = () => {
    const labels: string[] = [];
    categoryViolationsData.forEach((category) => {
      const contentTrimmed =
        category.name.length > 12
          ? category.name.substring(0, 12) + '...'
          : category.name;
      labels.push(contentTrimmed);
    });
    return labels;
  };
  return (
    <React.Fragment>
      <Grid container sx={style} flexDirection="column">
        <Grid
          item
          sx={{ paddingLeft: theme.spacing(6), paddingTop: theme.spacing(4) }}
        >
          <Typography>
            {title
              ? title
              : dashboardMessages.CATEGORY_VIOLATIONS_WIDGET_MESSAGE}
          </Typography>
        </Grid>
        <Divider sx={{ paddingTop: theme.spacing(4) }} light />
        <Grid
          item
          container
          sx={{ paddingTop: theme.spacing(5), paddingLeft: theme.spacing(1) }}
        >
          {categoryViolationsData.length === 0 ? (
            <Grid item container sx={{ mt: '15%' }}>
              <EmptyState
                content={dashboardMessages.NO_INFORMATION_MESSAGE}
                icon={EMPTY_STATE_ICONS.VIOLATIONS_BY_CATEGORY_ICON}
              />
            </Grid>
          ) : (
            <ResponsiveContainer width="98%" height={500}>
              <BarChart
                data={categoryViolationsData}
                barSize={46}
                margin={{ bottom: 20 }}
              >
                <CartesianGrid
                  vertical={false}
                  stroke={EXTRA_COLORS.whiteSmoke}
                />
                <XAxis
                  dataKey="name"
                  tick={
                    getRequiredLabels().length <= 5 ? (
                      { fill: theme.palette.text.disabled }
                    ) : (
                      <CustomisedAxisTick labels={getRequiredLabels} />
                    )
                  }
                  axisLine={{ stroke: theme.palette.grey[500] }}
                  fontSize={12}
                  interval={0}
                />
                <YAxis
                  type="number"
                  dataKey="violations"
                  tickCount={10}
                  tick={{ fill: theme.palette.text.disabled }}
                  axisLine={{ stroke: theme.palette.grey[500] }}
                  tickLine={false}
                  allowDecimals={false}
                />
                <Tooltip cursor={false} />
                <Bar
                  dataKey="violations"
                  radius={[2, 2, 0, 0]}
                  animationDuration={1600}
                >
                  {categoryViolationsData.map((category, index) => {
                    return (
                      <Cell
                        key={category.name}
                        fill={getColorsForCategoryWidget[index].color}
                      />
                    );
                  })}
                  <LabelList
                    dataKey="violations"
                    fill={theme.palette.error.main}
                    position="top"
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CategoryViolationsWidget;
