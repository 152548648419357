import React, { Fragment, useState } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import {
  Collapse,
  DrawerProps,
  Grid,
  Box,
  ListItemButton,
  useTheme,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {
  ISideNavigationItem,
  SIDE_NAV,
  INestedSideNavItem,
} from '../../../core-utils/constants';
import RigthExpandSvg from '../../../../public/assets/right-expand.svg';
import LeftExpandSvg from '../../../../public/assets/left-expand.svg';
import IconFromSvg from '../../atoms/IconFromSvg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import sideNavImageGetter from './SideNavImageGetter';
import { useHistory, useLocation } from 'react-router-dom';
import { EXTRA_COLORS } from '../../../core-utils/theme';
import HelpMinimized from '../HelpMinimized';
import { HelpFAQType } from '../../../core-utils/helpFAQs';
import { getHelpFaqs } from './index.hook';
import { useAppDispatch, useAppSelector } from '../../../Redux/Store/store';
import { sideNavActions } from '../../../Redux/Reducers/sideNav';
import { ROUTES } from '../../../core-utils/routes';

const drawerWidth = SIDE_NAV.DRAWER_WIDTH;

interface ISideNavigationDrawerProps extends DrawerProps {
  sideNavItems: Array<ISideNavigationItem>;
  sideNavEndItems: ISideNavigationItem[];
}

export const sideNavLocalStorageKeys = {
  sideNav: 'selectedSideNav',
  childItem: 'childItem',
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  top: theme.spacing(16.25),
  maxHeight: `calc(100% - ${theme.spacing(16.25)})`,
  borderRight: `1px solid ${EXTRA_COLORS.blue_100}`,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(14)} + 1px)`,
  },
  top: theme.spacing(16.25),
  maxHeight: `calc(100% - ${theme.spacing(16.25)})`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(1, 1),
}));

const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  border: `1px solid ${EXTRA_COLORS.blue_100}`,
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const StyledListItemButton = styled(ListItemButton)(({ theme, ...props }) => ({
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
  },
  ...(props.selected ? {
    borderLeft: '3px solid ' + theme.palette.primary.main,
    backgroundColor: `${theme.palette.background.paper} !important`,
  } : {
    borderLeft: '3px solid ' + theme.palette.background.paper,
    backgroundColor: `${theme.palette.background.paper} !important`,
  }),
}));

const StyledNestedListItemButton = styled(ListItemButton)(
  ({ theme, ...props }) => ({
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
    ...(props.selected && {
      backgroundColor: `${theme.palette.background.paper} !important`,
    }),
  }),
);

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 0,
  paddingRight: theme.spacing(3),
}));

const NestedSideNavigationDrawer = (
  item: any,
  selectedNestedRoute: INestedSideNavItem,
  handleNestedItemClick: (nestedItem: INestedSideNavItem) => void,
  toggleUDSubmenu: boolean,
) => {
  const theme = useTheme();
  return (
    <Collapse in={toggleUDSubmenu} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {item?.children?.map((nestedItem: any) => (
          <StyledNestedListItemButton
            key={nestedItem?.id}
            selected={selectedNestedRoute?.id === nestedItem?.id}
            sx={{ pl: 14 }}
            data-testid={nestedItem.name}
            onClick={() => {
              handleNestedItemClick(nestedItem);
            }}
          >
            <ListItemText
              primaryTypographyProps={{
                variant: 'caption',
                color:
                  selectedNestedRoute?.id === nestedItem?.id
                    ? theme.palette.primary.main
                    : theme.palette.text.disabled,
              }}
              primary={nestedItem?.name}
            />
          </StyledNestedListItemButton>
        ))}
      </List>
    </Collapse>
  );
};

const getColorForIcon = (isSelected: boolean) => {
  return isSelected ? 'primary' : 'inherit';
};

const SideNavigationDrawer = ({
  sideNavItems,
  sideNavEndItems,
}: ISideNavigationDrawerProps) => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  // Added Redux state for sideNave Open and close action
  const dispatch = useAppDispatch();
  const isDrawerOpen = useAppSelector(
    (state) => state.sideNavDrawer.isSideNavDrawerOpen,
  );
  const sideNavAppRoutes = useAppSelector(
    (state) => state.sideNavDrawer.applicationRoutes,
  );
  const selectedAppRoute = useAppSelector(
    (state) => state.sideNavDrawer.selectedRoutes,
  );
  const selectedNestedRoute = useAppSelector(
    (state) => state.sideNavDrawer.selectedNestedRoute,
  );
  // Added Redux state for sideNave Open and close action

  const [isSideNavOpen, setSideNavOpen] = useState<boolean>(isDrawerOpen);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [helpDetails, setHelpDetails] = useState<HelpFAQType>();
  const [allHelpFaqs, setAllHelpFaqs] = useState<HelpFAQType>();
  const [toggleUDSubmenu, setToggleUDSubmenu] = useState<boolean>(false);

  React.useEffect(() => {
    const { pathname } = location;
    const alteredRoute = pathname.split('/');
    if (pathname !== ROUTES.NOTIFICATIONS) {
      if ([ROUTES.USER_GROUPS, ROUTES.USERS].includes(`/${alteredRoute[1]}`)) {
        const userRouteWithChilds = sideNavAppRoutes.find(
          (route) => route.id === 2,
        );
        const selectedChild = userRouteWithChilds?.children?.find(
          (route: any) => route.routeOnClick?.trim() === `/${alteredRoute[1]}`,
        );
        dispatch(sideNavActions.setSelected(userRouteWithChilds?.id));
        dispatch(sideNavActions.setSelectedNestedRoute(selectedChild));
        !toggleUDSubmenu &&
          setToggleUDSubmenu((prevState) => (prevState = !prevState));
      } else {
        const redirectRoute = sideNavAppRoutes.find(
          (route) => route.routeOnClick?.trim().includes(`/${alteredRoute[1]}`),
        );

        dispatch(sideNavActions.setSelected(redirectRoute?.id));
        dispatch(sideNavActions.setSelectedNestedRoute({}));
      }
    } else {
      // dispatch(sideNavActions.resetSelected());
    }
  }, [location]);

  React.useEffect(() => {
    setSideNavOpen(isDrawerOpen);
  }, [isDrawerOpen]);

  const handleNestedItemClick = (selectedNestedRoute: INestedSideNavItem) => {
    dispatch(sideNavActions.setSelectedNestedRoute(selectedNestedRoute));
    const { pathname } = location;
    pathname !== selectedNestedRoute.routeOnClick &&
      history.push(selectedNestedRoute.routeOnClick);
    setIsHelpOpen(false);
  };

  const handleListItemClick = (selectedRoute: ISideNavigationItem) => {
    selectedRoute.id &&
      dispatch(sideNavActions.setSelected(selectedRoute.id)) &&
      dispatch(sideNavActions.setSelectedNestedRoute({}));
    toggleUDSubmenu &&
      setToggleUDSubmenu((prevState) => (prevState = !prevState));
    if (
      selectedRoute.routeOnClick &&
      selectedRoute.routeOnClick != location.pathname
    ) {
      history.push(selectedRoute?.routeOnClick);
    }
    if (selectedRoute?.children?.length) {
      dispatch(
        sideNavActions.setSelectedNestedRoute(selectedRoute.children[0]),
      );
      !toggleUDSubmenu &&
        setToggleUDSubmenu((prevState) => (prevState = !prevState));

      history.push(selectedRoute.children[0].routeOnClick);
    }
  };

  const handleHelpClick = async (item: any) => {
    const response = await getHelpFaqs();
    const requiredHelpDetails: HelpFAQType = { id: 1, faqs: response.faqs };
    setHelpDetails(requiredHelpDetails);
    const allHelpDetails: HelpFAQType = { id: 2, faqs: response.allFaqs };
    setAllHelpFaqs(allHelpDetails);
    dispatch(sideNavActions.setSelected(item?.id));
    setIsHelpOpen(true);
  };

  const handleHelpClose = () => {
    setIsHelpOpen(false);
    //console.log(sideNavEndItems);
    sideNavEndItems[1].isSelected = false;
  };

  return (
    <>
      <StyledDrawer
        variant="permanent"
        open={isSideNavOpen}
        data-testid="side-nav"
      >
        <DrawerHeader>
          <IconButton
            sx={{ pl: theme.spacing(3) }}
            data-testid="collapse-icon"
            onClick={() => dispatch(sideNavActions.toggle())}
          >
            {isSideNavOpen ? (
              <IconFromSvg path={RigthExpandSvg} alt="right" />
            ) : (
              <IconFromSvg path={LeftExpandSvg} alt="left" />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ border: `1px solid ${EXTRA_COLORS.blue_100}` }} />
        <Grid container sx={{ height: 'inherit' }}>
          <Grid item>
            <List>
              {sideNavAppRoutes.map((item: ISideNavigationItem) =>
                ![8, 9, 10].includes(item.id) ? (
                  <Fragment key={item?.id}>
                    <StyledListItemButton
                      data-testid={item.name}
                      selected={item.id === selectedAppRoute?.id ? true : false}
                      onClick={() => handleListItemClick(item)}
                    >
                      <StyledListItemIcon
                        sx={{ minWidth: 0, pr: theme.spacing(4) }}
                      >
                        <IconFromSvg
                          path={sideNavImageGetter(
                            item.name,
                            item.id === selectedAppRoute?.id ? true : false,
                          )}
                          alt={item?.name}
                        />
                      </StyledListItemIcon>
                      <ListItemText
                        primaryTypographyProps={{
                          color:
                            item.id === selectedAppRoute?.id
                              ? theme.palette.primary.main
                              : theme.palette.text.disabled,
                        }}
                        primary={item?.name}
                      />
                      {item?.children && (
                        <IconButton
                          sx={{ p: 0, zIndex: 10, marginLeft: 8 }}
                          disableRipple
                          onClick={(e) => {
                            e.stopPropagation();
                            setToggleUDSubmenu(
                              (prevState) => (prevState = !prevState),
                            );
                          }}
                        >
                          {toggleUDSubmenu ? (
                            <KeyboardArrowUpIcon
                              color={getColorForIcon(toggleUDSubmenu)}
                            />
                          ) : (
                            <KeyboardArrowDownIcon
                              sx={{ p: 0 }}
                              color={getColorForIcon(toggleUDSubmenu)}
                            />
                          )}
                        </IconButton>
                      )}
                    </StyledListItemButton>
                    {item?.children &&
                      NestedSideNavigationDrawer(
                        item,
                        selectedNestedRoute,
                        handleNestedItemClick,
                        toggleUDSubmenu,
                      )}
                  </Fragment>
                ) : null,
              )}
            </List>
          </Grid>
          <Grid item container direction="row" alignItems="flex-end">
            <List>
              {sideNavAppRoutes.map((item) =>
                [8, 9].includes(item.id) ? (
                  <StyledListItemButton
                    key={item.id}
                    selected={item.id === selectedAppRoute?.id ? true : false}
                    onClick={() =>
                      item?.id === 9
                        ? handleHelpClick(item)
                        : handleListItemClick(item)
                    }
                  >
                    <StyledListItemIcon sx={{ pr: theme.spacing(5) }}>
                      <IconFromSvg
                        path={sideNavImageGetter(
                          item.name,
                          item.id === selectedAppRoute?.id,
                        )}
                        alt={item?.name}
                      />
                    </StyledListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        color:
                          item.id === selectedAppRoute?.id
                            ? theme.palette.primary.main
                            : theme.palette.text.disabled,
                      }}
                      primary={item?.name}
                    />
                  </StyledListItemButton>
                ) : null,
              )}
            </List>
          </Grid>
        </Grid>
      </StyledDrawer>
      {isHelpOpen && (
        <Box sx={{ position: 'fixed', zIndex: 9999, left: '8%', bottom: '8%' }}>
          {helpDetails && allHelpFaqs && (
            <HelpMinimized
              helpDetails={helpDetails}
              handleClose={handleHelpClose}
              allHelpFaqs={allHelpFaqs}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default SideNavigationDrawer;
