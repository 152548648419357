import { createSlice, current } from '@reduxjs/toolkit';
import { ISideNavigationItem } from '../../core-utils/constants';
import {
  getSideNavEndItems,
  getSideNavItems,
} from '../../core-utils/Helper/helper';

// Define a type for the slice state
interface SideNavState {
  isSideNavDrawerOpen: boolean;
  applicationRoutes: ISideNavigationItem[];
  selectedRoutes: any;
  selectedNestedRoute: any;
  isDropDownOpen: boolean;
  isReloadusersData: boolean;
  selectedTab: number;
}

// Define the initial state using that type
const initialState: SideNavState = {
  isSideNavDrawerOpen: true,
  applicationRoutes: [],
  isDropDownOpen: false,
  selectedRoutes: {},
  selectedNestedRoute: {},
  isReloadusersData: false,
  selectedTab: 0,
};

const sideNavSlice = createSlice({
  name: 'sidenavSlice',
  initialState: initialState,
  reducers: {
    toggle(state) {
      state.isSideNavDrawerOpen = !state.isSideNavDrawerOpen;
    },
    loadSideNavAppRoutes(state) {
      state.applicationRoutes = [...getSideNavItems(), ...getSideNavEndItems()];
    },
    setSelected(state, action) {
      const id = action.payload;
      let selected = state.applicationRoutes.find((route) => route.id === id);
      state.selectedRoutes = selected;
    },
    setSelectedNestedRoute(state, action) {
      state.selectedNestedRoute = action.payload;
    },
    setSelectedAppRoute(state, action: any) {
      const id = action.payload;
      let { applicationRoutes } = current(state);
      applicationRoutes.forEach((route) => {
        if (route.id === id) route.isSelected = true;
        else route.isSelected = false;
      });
    },
    reloadUsersData(state) {
      state.isReloadusersData = !state.isReloadusersData;
    },
    setApplicationDashboardSelectedTab(state, action) {
      state.selectedTab = action.payload;
    },
  },
});

export const sideNavActions = sideNavSlice.actions;

export default sideNavSlice;
