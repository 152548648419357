import axios from '../axios';
import Config from '../../../config/config';
import {
  MOCK_USERS_VIOLATIONS_WIDGET,
  MOCK_ACTIVE_USER_GROUPS_WIDGET,
  APP_MOCK_USERGROUPS_API,
  APP_MOCK_USERS_API,
  MOCK_CATEGORY_VIOLATIONS_WIDGET,
} from '../../core-utils/test-constants';
import UserManagementIcon from '../../../public/assets/user-management-active.svg';

export interface IListAppsResponse {
  id: string;
  name: string;
  attributes: {
    thumbnail: string;
    content: string;
  };
}

export interface IAppsOfUserResponse {
  connectionPermissions: {};
  connectionGroupPermissions: {};
  sharingProfilePermissions: {};
  activeConnectionPermissions: {};
  userPermissions: {};
  userGroupPermissions: {};
  systemPermissions: Array<any>;
}

export interface IAddOrRemoveAppsToUserRequestBody {
  op: string;
  path: string;
  value: string;
}

const isDashboardAPIValid = () => {
  return Config.DASHBOARD_API_URL ? true : false;
};

const AppService = {
  addNewApplication: async (appDetails: any) => {
    try {
      const addApplicationResponse = await axios.post(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/connections`,
        appDetails,
      );
      return addApplicationResponse.data;
    } catch (error) {
      //TODO: Handle exception
    }
  },

  listApps: async () => {
    try {
      const listAppsResponse = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/connectionGroups/ROOT/tree`,
      );
      return listAppsResponse.data;
    } catch (error) {
      return error;
    }
  },
  getAllApps: async () => {
    try {
      const listAppsResponse = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/connections`,
      );
      return listAppsResponse.data;
    } catch (error) {
      return error;
    }
  },
  addUserGroupsToApp: async (groupId: string, requestBody: any) => {
    try {
      await axios.patch(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/userGroups/${groupId}/permissions`,
        requestBody,
      );
    } catch (error) {}
  },
  addUsersToApp: async (username: string, requestBody: any) => {
    try {
      await axios.patch(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/users/${username}/permissions`,
        requestBody,
      );
    } catch (error) {}
  },
  getUsersForApp: async (appId: number | string) => {
    return [];
  },
  getAppById: async (appId: string | number) => {
    // return MOCK_APP_DETAILS
    try {
      const appResponse = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/connections/${encodeURIComponent(appId)}`,
      );
      // appResponse.data.userGroupMembers = APP_MOCK_USERGROUPS_API;
      // appResponse.data.userMembers = APP_MOCK_USERS_API;
      // appResponse.data.attributes.idpAppUrl = 'https://google.com';
      // enable this for testing app with IDPAPPURL
      return appResponse.data;
    } catch (error) {
      return error;
    }
  },
  getAppParameters: async (appId: string | number) => {
    try {
      const appParamsResponse = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/connections/${appId}/parameters`,
      );
      return appParamsResponse.data;
    } catch (error) {
      return error;
    }
  },
  updateApp: async (appId: string | number, appDetailsPayload: any) => {
    try {
      await axios.put(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/connections/${appId}`,
        appDetailsPayload,
      );
    } catch (error) {
      // TODO : Handle the exception during updation of app
    }
  },

  getUserGroupsForApp: async (appId: number | string) => {
    return [];
    // TODO: This API is not yet provided.
  },

  deleteAppById: async (appId: string | number) => {
    try {
      await axios.delete(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/connections/${appId}`,
      );
    } catch (error) {
      // TODO : Handle the exception during updation of app
    }
  },
  getMostActiveUsers: async (
    startDate: String,
    endDate: String,
    appId: String | number,
  ) => {
    // TODO: remove return once api is functional
    // return MOCK_USERS_VIOLATIONS_WIDGET;
    try {
      if (!isDashboardAPIValid()) {
        return MOCK_USERS_VIOLATIONS_WIDGET;
      }
      const activeUsers = await axios.get(
        `${Config.DASHBOARD_API_URL}applications/${appId}/MostActiveUsers`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return activeUsers.data;
    } catch (error) {
      return [];
    }
  },
  getMostActiveUserGroups: async (
    startDate: String,
    endDate: String,
    appId: String | number,
  ) => {
    // return MOCK_ACTIVE_USER_GROUPS_WIDGET;
    try {
      if (!isDashboardAPIValid()) {
        return MOCK_ACTIVE_USER_GROUPS_WIDGET;
      }
      //adding this as mock data as backend returns this type
      if (Config.DASHBOARD_API_URL === 'https://dev.sonet.io/dashboardAPI/') {
        return { AllUsers: 9, contractors: 8 };
      }
      const activeUserGroups = await axios.get(
        `${Config.DASHBOARD_API_URL}applications/${appId}/ActiveUserGroups`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return activeUserGroups.data;
    } catch (error) {
      return [];
    }
  },
  getTotalUsersCount: async (
    startDate: String,
    endDate: String,
    appId: String | number,
  ) => {
    try {
      const totalUsersCount = await axios.get(
        `${Config.DASHBOARD_API_URL}applications/${appId}/TotalUsers`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return totalUsersCount.data;
    } catch (error) {
      return 0;
    }
  },
  getTotalUserGroupsCount: async (
    startDate: String,
    endDate: String,
    appId: String | number,
  ) => {
    try {
      const totalUserGroupsCount = await axios.get(
        `${Config.DASHBOARD_API_URL}applications/${appId}/TotalUserGroups`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return totalUserGroupsCount.data;
    } catch (error) {
      return 0;
    }
  },
  getCurrentUsersCount: async (
    startDate: String,
    endDate: String,
    appId: String | number,
  ) => {
    try {
      const currentUsersCount = await axios.get(
        `${Config.DASHBOARD_API_URL}applications/${appId}/CurrentUsers`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return currentUsersCount.data;
    } catch (error) {
      return 0;
    }
  },
  getPolicyViolationsCount: async (
    startDate: String,
    endDate: String,
    appId: String | number,
  ) => {
    try {
      const policyViolationsCount = await axios.get(
        `${Config.DASHBOARD_API_URL}applications/${appId}/PolicyViolations`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return policyViolationsCount.data;
    } catch (error) {
      return 0;
    }
  },
  getUploadsCount: async (
    startDate: String,
    endDate: String,
    appId: String | number,
  ) => {
    //Remove this once  we have functional apis
    return 14;
  },
  getDownloadsCount: async (
    startDate: String,
    endDate: String,
    appId: String | number,
  ) => {
    //Remove this once  we have functional apis
    return 12;
  },
};

export default AppService;
