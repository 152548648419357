import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import theme, { EXTRA_COLORS } from '@utils/theme';
import IconFromSvg from '@atoms/IconFromSvg';
import copyImage from '@assets/copy.svg';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import sessionRecordingMessages from '@utils/messages/sessionRecordings';
import { copyText, formatTime } from '@utils/Helper/helper';
import { ViolationData } from '@utils/constants';

interface ISessionRecordingSummaryCard {
  ipAddress: string;
  duration: string;
  apps: { name: string; image: string }[];
  numberOfViolations: number;
  violations: ViolationData[];
  location?: string;
  fileUploads?: number;
  fileDownloads?: number;
  totalBandwidth?: string;
}

export const TypographyHeading = styled(Typography)({
  ...theme.typography.body2,
  fontWeight: 400,
  color: theme.palette.text.secondary,
});

export const TypographyBody = styled(Typography)({
  ...theme.typography.body2,
  fontWeight: 400,
  color: theme.palette.text.primary,
});

const constructCopyText = (violationData: ISessionRecordingSummaryCard) => {
  return (
    sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD.IP_ADDRESS +
    ' - ' +
    violationData.ipAddress +
    '\n' +
    sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD.DURATION +
    ' - ' +
    violationData.duration +
    '\n' +
    sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD
      .APPS_ACCESSED_MESSAGE +
    ': ' +
    violationData.apps || '' +
    '\n' +
    sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD.VIOLAIONS_MESSAGE +
    ' - ' +
    violationData.numberOfViolations +
    '\n' +
    sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD
      .VIOLAIONS_DETAILS_MESSAGE +
    ': ' +
    violationData.violations
      .map((violation) => {
        return (
          '\n\t' +
          sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD
            .VIOLATION_CATEGORY +
          ': ' +
          violation.name +
          '\n\t' +
          sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD
            .VIOLATION_DESCRIPTION +
          ': ' +
          violation.description +
          '\n\t' +
          sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD
            .VIOLATION_ACTIONS +
          ': ' +
          violation.actions +
          '\n\t' +
          sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD
            .VIOLATION_TIMESTAMP +
          ': ' +
          formatTime(violation.offset)
        );
      })
      .join('\n')
  );
};

const SessionRecordingSummaryCard = ({
  ipAddress,
  duration,
  apps,
  violations,
  location,
  fileUploads,
  fileDownloads,
  totalBandwidth,
}: ISessionRecordingSummaryCard) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };


  const ViolationsSummary = () => {
    const violationTypeCount: any = {};
    violations?.forEach(v => {
      violationTypeCount[v.name] = (violationTypeCount[v.name] || 0) + 1;
    });
    const violationSummary = Object.keys(violationTypeCount)?.map((violationName) => `${violationTypeCount[violationName]} ${violationName} ${violationTypeCount[violationName] === 1 ? 'violation' : 'violations'}`)?.join(', ');
    return (
        <span className="text-gray-800" style={{ fontFamily: 'Manrope' }}>
          There were {violationSummary}.
        </span>
    )
  }

  return (
    <Accordion
      className="bg-white rounded-t-lg rounded-b-md w-full shadow-sm pb-8 border border-solid border-[#EDEDF9]"
      sx={{
        boxSizing: 'border-box',
        '&.MuiAccordion-gutters:before': {
          height: 0
        },
        paddingLeft: theme.spacing(2),
      }}
      defaultExpanded
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" spacing={2} px={3}>
          <Typography variant="subtitle2">
            {sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD.SUMMARY}
          </Typography>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={tooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD
                  .COPY_TOOLTIP
              }
            >
              <IconButton
              className="group bg-transparent border-0"
                sx={{ p: 0, zIndex: 10 }}
                onClick={(e) => {
                  e.stopPropagation();
                  copyText(
                    constructCopyText({
                      ipAddress: ipAddress,
                      duration: duration,
                      apps: apps,
                      numberOfViolations: violations.length,
                      violations: violations,
                    }),
                    handleTooltipOpen,
                  );
                }}
              >
                <IconFromSvg className="group-hover:scale-105 opacity-10 group-hover:opacity-100 transition-all duration-300 ease-in-out" path={copyImage} alt="copy"></IconFromSvg>
              </IconButton>
            </Tooltip>
          </ClickAwayListener>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack spacing={1.5} px={3}>
          <Box display="flex" flexDirection="row">
            <TypographyHeading>
              {
                sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD
                  .IP_ADDRESS
              }
              {' - '}
            </TypographyHeading>
            <TypographyBody>{ipAddress}</TypographyBody>
          </Box>
          {location && (
            <Box display="flex" flexDirection="row">
              <TypographyHeading>
                {
                  sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD
                    .LOCATION
                }
                {' - '}
              </TypographyHeading>
              <TypographyBody>{location}</TypographyBody>
            </Box>
          )}
          <Box display="flex" flexDirection="row">
            <TypographyHeading>
              {sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD.DURATION}{' '}
              -{' '}
            </TypographyHeading>
            <TypographyBody>{duration}</TypographyBody>
          </Box>
          {apps && (
            <React.Fragment>
              <TypographyHeading>
                {
                  sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD
                    .APPS_ACCESSED_MESSAGE
                }
              </TypographyHeading>
              <TypographyBody>{apps}</TypographyBody>
            </React.Fragment>
          )}

          <Box display="flex" flexDirection="row" columnGap={8}>
            {fileDownloads && (
              <Box display="flex" flexDirection="row">
                <TypographyHeading>
                  {
                    sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD
                      .FILES_DOWNLOADED
                  }{' '}
                  -{' '}
                </TypographyHeading>
                <TypographyBody sx={{ color: theme.palette.primary.main }}>
                  {fileDownloads}
                </TypographyBody>
              </Box>
            )}
            {fileUploads && (
              <Box display="flex" flexDirection="row">
                <TypographyHeading>
                  {
                    sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD
                      .FILES_UPLOADED
                  }{' '}
                  -{' '}
                </TypographyHeading>
                <TypographyBody sx={{ color: theme.palette.primary.main }}>
                  {fileUploads}
                </TypographyBody>
              </Box>
            )}
          </Box>
          {totalBandwidth && (
            <Box display="flex" flexDirection="row">
              <TypographyHeading>
                {
                  sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD
                    .TOTAL_BANDWIDTH
                }
                {' - '}
              </TypographyHeading>
              <TypographyBody>{totalBandwidth}</TypographyBody>
            </Box>
          )}

          <>
            <TypographyHeading>
              {
                sessionRecordingMessages.SESSION_RECORDING_SUMMARY_CARD
                  .VIOLAIONS_MESSAGE
              }
            </TypographyHeading>
            <TypographyBody>
              {violations.length ? (
                <ViolationsSummary />
              ) : (
                <TypographyHeading>-</TypographyHeading>
              )}
            </TypographyBody>
          </>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default SessionRecordingSummaryCard;
