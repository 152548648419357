const policyMessages = {
  POLICY_BLOCK: {
    TOOLTIP_HEADING: 'Policy Block',
    TOOLTIP_BODY:
      'Policy blocks are named templates that you can use as a starting point to tailor to build specific policies',
    DEFAULT_ACTION: 'Default Action',
    EXCEPT_WHEN: 'Except when',
    ACTIONS: 'Actions',
    ADD_CONDITION: 'Add Condition',
    POLICY_BLOCK_CHOICE: 'Select a policy block',
  },
  CREATED_BY: 'Created by',
  COLLAPSE_ALL: 'Collapse All',
  POLICIES_DASHBOARD_TABS: {
    MY_POLICIES: 'My Policies',
    PREDEFINED_POLICIES: 'Predefined Policies',
    COMMUNITY_POLICIES: 'Community Policies',
    RECENTLY_DELETED: 'Recently Deleted',
  },
  SEARCH_PLACEHOLDER_FOR_POLICIES: 'Search for policies',
  POP_UP_MESSAGES_TO_ACTIVATE_OR_DEACTIVATE_POLICY: {
    SUB_TEXT_FIRST_PART: 'Are you sure you want to {action}',
    SUB_TEXT_SECOND_PART: 'policy. This will impact several users access.',
    SUB_TEXT_USER_POLICY_SECOND_PART: ' policy for ',
    SUB_TEXT_USER_POLICY_THIRD_PART: 'This will impact users access.',
  },
  POLICIES: 'Policies',
  ADD_POLICY: 'Add Policy',
  ASSIGN_POLICY: 'Assign Policy',
  EMPTY_SCREEN_MESSAGES: {
    HEADING: "You don't have any policies yet.",
    SUB_HEADING:
      'Create, clone or attach a policy by clicking the button below',
  },
  SELECT_EXISTING_POLICY: 'Select an existing policy to be {action}',
  ATTACH_EXISTING_POLICY: 'Attach Existing Policy',
  CLONE_EXISTING_POLICY: 'Clone Existing Policy',
  ATTACH: 'Attach',
  DETACH_POLICY: 'Detach Policy',
  DUPLICATE_POLICY: {
    DUPLICATE_POLICYNAME_HEADING: 'Copy Policy Name',
    DUPLICATE_POLICYNAME_LABEL: 'Policy Name',
    POLICY_NAME_CANNOT_BE_EMPTY: `Policy name can't be empty`,
  },
  DROPDOWN_ERROR_MESSAGE: 'Select a value',
};

export default policyMessages;
