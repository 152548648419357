import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import { Divider, Grid, Typography, Box } from '@mui/material';
import * as React from 'react';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import { ITimeSelectionProps } from '../../../core-utils/constants';
import { useShowOrHideElement } from '../../../core-utils/Hooks/index.hook';
import DateRangePicker from '../../molecules/DateRangePicker';
import { convertEndDateToEOD } from '../../../core-utils/Helper/helper';

const style = {
  width: theme.spacing(100),
  height: theme.spacing(10),
  border: `1px solid ${EXTRA_COLORS.lightGrayishBlue}`,
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[10],
};

const DashboardTimeSelection = ({
  timeSelectionItems,
  handleDateSelect,
}: {
  timeSelectionItems: ITimeSelectionProps[];
  handleDateSelect?: any;
}) => {
  const { open, showElement, hideElement } = useShowOrHideElement();
  const [dates, setDates] = React.useState<DateRange<Date>>([null, null]);
  const [activeIndex, setActiveIndex] = React.useState(2);

  return (
    <Grid container sx={style} alignItems="center">
      {timeSelectionItems.map((item, index) => (
        <Grid
          item
          key={index}
          sx={{
            '&:hover': {
              backgroundColor: theme.palette.grey[100],
              cursor: 'pointer',
            },
            paddingLeft: theme.spacing(5),
          }}
        >
          <Typography
            data-testid={item.id}
            key={item.id}
            variant="caption"
            onClick={item.handleClick}
            onClickCapture={() => {
              setActiveIndex(item.id);
              setDates([null, null]);
            }}
            color={
              item.id === activeIndex
                ? theme.palette.background.paper
                : theme.palette.text.secondary
            }
            sx={{
              fontWeight: item.id === activeIndex ? 'bold' : 'normal',
              fontSize: item.id === activeIndex ? '14px' : '13px',
            }}
          >
            {item.id === activeIndex && (
              <Box
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: theme.spacing(0.75),
                }}
                position="absolute"
                width={theme.spacing(6)}
                height={theme.spacing(5.5)}
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                {item.timePeriod}
              </Box>
            )}
            {item.timePeriod}
          </Typography>
        </Grid>
      ))}
      <Divider
        sx={{ marginLeft: theme.spacing(3.75) }}
        orientation="vertical"
        variant="middle"
        flexItem
      />
      <Grid item>
        <DateRangePicker
          open={open}
          dates={dates}
          handleChange={(dates) => {
            if (dates[1]) {
              const endDateConverted = convertEndDateToEOD(dates[1]);
              setDates([dates[0], endDateConverted]);
            } else {
              setDates(dates);
            }
            handleDateSelect && handleDateSelect(dates);
          }}
          handleClose={hideElement}
          handleOpen={showElement}
          handleClickCapture={() => setActiveIndex(-1)}
        />
      </Grid>
    </Grid>
  );
};

export default DashboardTimeSelection;
