import React, { useState, useEffect } from 'react';
import SessionRecordingService from '../../../api-service/session-recording-service/sessionRecordingService';
import { getAuthToken } from '../../../core-utils/Helper/helper';
import Config from '../../../../config/config';

export const getViolationsOfHistory = async (historyId: number) => {
  const listViolations: any = await SessionRecordingService.listViolationsOfHistory(
    historyId.toString(),
  );
  return listViolations;
};

export const useSessionRecordingData = (historyID: number) => {
  const [sessionHistoryDetails, setSessionHistoryDetails] = useState<any>();
  const [violations, setViolations] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getSessionHistory(historyID),
      getViolationsOfHistory(historyID),
    ])
      .then(([sessionHistoryResponse, violationsResponse]) => {
        setSessionHistoryDetails(sessionHistoryResponse);
        setViolations(violationsResponse);
      })
      .finally(() => setLoading(false));
  }, []);

  return [sessionHistoryDetails, violations, loading];
};

const getSessionHistory = async (historyID: number) => {
  let recordingDetail: any = await SessionRecordingService.getUserSession(
    historyID,
  );
  recordingDetail = recordingDetail.data;
  let userFullName = '';
  userFullName = recordingDetail.fullName
    ? recordingDetail.fullName
    : recordingDetail.username;
  const recordingResponse = await SessionRecordingService.getSessionRecording(
    recordingDetail.recordingIdentifier,
  );
  const recordingExists =
    recordingResponse.code === 'ECONNABORTED' ||
    !recordingResponse.startsWith('java.nio.file.NoSuchFileException');

  const recordingStartDate = new Date(recordingDetail.startDate);
  const recordingEndDate = recordingDetail.endDate
    ? new Date(recordingDetail.endDate)
    : new Date(recordingDetail.startDate);
  console.log(recordingEndDate);
  console.log(recordingStartDate);
  return {
    ipAddress: recordingDetail.remoteHost,
    //TODO: ADD THESE WHEN WE HAVE FIELDS IN API
    // location: 'Estonia',
    // fileUploads: 3,
    // fileDownloads: 2,
    // totalBandwidth: '1456 kbps',
    username: recordingDetail.username,
    userFullName: userFullName,
    historyID: recordingDetail.historyId ?? recordingDetail.historyID,
    appID: recordingDetail.connectionIdentifier,
    appName: recordingDetail.connectionDisplayName || recordingDetail.connectionName,
    recordingIdentifier: recordingDetail.recordingIdentifier,
    recordingURL:
      Config.BACKEND_BASE_URL +
      'api/sonet/recordings/' +
      recordingDetail.recordingIdentifier,
    recordingExists: recordingExists,
    numPolicyViolations: recordingDetail.numPolicyViolations,
    duration: recordingEndDate.getTime() - recordingStartDate.getTime(),
  };
};
