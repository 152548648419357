import { Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { ADMIN_FORM_ROLE_OPTIONS } from '../../../core-utils/constants';
import ONBOARDING_FLOW_MESSAGES from '../../../core-utils/messages/onboarding_flow_messages';
import { EXTRA_COLORS } from '../../../core-utils/theme';
import CheckBox from '../../atoms/CheckBox';
import TextField from '../../atoms/TextField';
import Dropdown from '../../molecules/Dropdown';

interface IAdministratorFormProps {
  adminFormCheckbox: boolean;
  adminInformationFormDetails: any;
  handleCheckboxChange: (value: any) => void;
  handleNameChange: (value: string) => void;
  handleUsernameChange: (value: string) => void;
  handleJobTitleChange: (value: string) => void;
}

const AdministratorInformationForm = (props: IAdministratorFormProps) => {
  const theme = useTheme();

  const {
    adminFormCheckbox,
    adminInformationFormDetails,
    handleCheckboxChange,
    handleJobTitleChange,
    handleNameChange,
    handleUsernameChange,
  } = props;

  const {
    HEADING,
    SUBHEADING,
    CHECKBOX_TEXT,
    NAME,
    USERNAME,
    JOB_TITLE,
    ROLE,
    PLACEHOLDERS: {
      NAME_PLACEHOLDER,
      USERNAME_PLACEHOLDER,
      JOB_TITLE_PLACEHOLDER,
    },
  } = ONBOARDING_FLOW_MESSAGES.ADMIN_FORM_MESSAGES;

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        width: theme.spacing(178),
        height: theme.spacing(136),
        boxSizing: 'border-box',
        border: `1px solid ${EXTRA_COLORS.blue_100}`,
        borderRadius: theme.spacing(1),
        boxShadow: theme.shadows[10],
        padding: theme.spacing(16),
      }}
    >
      <Grid item container direction="column" textAlign="center">
        <Grid item>
          <Typography variant="h3">{HEADING}</Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
              paddingTop: theme.spacing(1),
            }}
          >
            {SUBHEADING}
          </Typography>
        </Grid>
      </Grid>
      <TextField
        name={NAME}
        label={NAME}
        value={adminInformationFormDetails[NAME]}
        required
        autoComplete="off"
        placeholder={NAME_PLACEHOLDER}
        handleChange={(e) => {
          handleNameChange(e.target.value);
        }}
      />
      <TextField
        name={USERNAME}
        label={USERNAME}
        value={adminInformationFormDetails[USERNAME]}
        required
        autoComplete="off"
        placeholder={USERNAME_PLACEHOLDER}
        handleChange={(e) => {
          handleUsernameChange(e.target.value);
        }}
      />
      <TextField
        name={JOB_TITLE}
        testId={JOB_TITLE}
        label={JOB_TITLE}
        value={adminInformationFormDetails[JOB_TITLE]}
        autoComplete="off"
        placeholder={JOB_TITLE_PLACEHOLDER}
        handleChange={(e) => {
          handleJobTitleChange(e.target.value);
        }}
      />
      <Grid container item direction="row" alignItems="center">
        <CheckBox
          data-testid={'admin-form-checkbox'}
          checked={adminFormCheckbox}
          onChange={(e) => {
            handleCheckboxChange(e.target.checked);
          }}
        />
        <Typography color={theme.palette.text.secondary}>
          {CHECKBOX_TEXT}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AdministratorInformationForm;
