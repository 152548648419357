import {
  Step,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  StepperProps,
  useTheme,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { styled } from '@mui/material/styles';
import { StepConnector } from '@mui/material';
import React from 'react';
import { EXTRA_COLORS } from '../../../core-utils/theme';

export interface IStepperProps extends StepperProps {
  listOfLabels: Array<any>;
  currentlyActiveStep: number;
}

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark'
        ? theme.palette.primary.main
        : EXTRA_COLORS.blue_100,
    borderWidth: 2,
    borderRadius: 2,
  },
  height: theme.spacing(20),
  '.MuiStepConnector-line': {
    height: theme.spacing(25),
  },
}));

const ProgressBar = (props: IStepperProps) => {
  const theme = useTheme();
  const { listOfLabels, currentlyActiveStep } = props;
  return (
    <>
      <Stepper
        activeStep={currentlyActiveStep}
        orientation="vertical"
        connector={<CustomConnector />}
      >
        {listOfLabels.map((stepItem, index) => {
          return (
            <Step key={index}>
              <StepLabel
                sx={{
                  '.Mui-active': {
                    color: `${theme.palette.primary.main}  !important`,
                  },
                  '.Mui-disabled': {
                    color: theme.palette.text.disabled,
                  },
                  '.Mui-completed': {
                    color: `${theme.palette.text.disabled}  !important`,
                  },
                  '.MuiStepLabel-iconContainer': {
                    padding: 0,
                    margin: 0,
                  },
                  fontStyle: 'normal',
                  padding: 0,
                }}
                icon={
                  <CircleIcon
                    sx={{
                      color:
                        currentlyActiveStep >= index
                          ? theme.palette.primary.main
                          : EXTRA_COLORS.blue_100,
                      marginRight: theme.spacing(4),
                    }}
                  />
                }
              >
                {stepItem?.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </>
  );
};

export default ProgressBar;
