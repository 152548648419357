import React from 'react';
import {
  TextField as MuiTextField,
  BaseTextFieldProps,
  InputAdornment,
  IconButton,
} from '@mui/material';
import TextFieldErrorSvg from '../../../../public/assets/textfield-error.svg';
import IconFromSvg from '../../atoms/IconFromSvg';
import { styled } from '@mui/material/styles';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';

export interface ITextFieldProps extends BaseTextFieldProps {
  textFieldSize?: 'large' | 'small' | 'inherit' | 'full';
  endIcon?: string;
  showText?: boolean;
  endAdornment?: any;
  startAdornment?: any;
  InputProps?: any;
  testId?: string;
  handleEndIconClick?: () => void;
  handleChange?: (event: any) => void;
}

const StyledTextField = styled(MuiTextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: EXTRA_COLORS.lightBlue_100,
    },
    '&:hover fieldset': {
      borderColor: EXTRA_COLORS.lightBlue_100,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiFormLabel-root': {
    backgroundColor: theme.palette.common.white,
  },
  '& input::placeholder': {
    fontSize: '13.5px',
    color: theme.palette.text.primary,
  },
});

const getWidth = (size: ITextFieldProps['textFieldSize']) => {
  switch (size) {
    case 'inherit':
      return 'inherit';
    case 'small':
      return '332px';
    case 'full':
      return '100%';
    case 'large':
    default:
      return '574px';
  }
};
const ErrorEndIcon = () => {
  return (
    <InputAdornment position="end">
      <IconButton aria-label="toggle password visibility" edge="end">
        <IconFromSvg path={TextFieldErrorSvg} alt="error" />
      </IconButton>
    </InputAdornment>
  );
};

const TextField = ({
  variant = 'outlined',
  required = false,
  textFieldSize = 'large',
  endIcon,
  endAdornment,
  startAdornment,
  testId,
  handleChange,
  handleEndIconClick,
  ...rest
}: ITextFieldProps) => {
  return (
    <StyledTextField
      {...rest}
      data-testid={testId}
      required={required}
      InputLabelProps={{
        shrink: true,
        sx: {
          // Marking this important so that Mui does not override the color of label during error state
          color: `${theme.palette.text.disabled} !important`,
          fontSize: 17,
        },
      }}
      FormHelperTextProps={{
        sx: {
          ...theme.typography.caption,
        },
      }}
      sx={{
        width: getWidth(textFieldSize),
        '.MuiInputLabel-asterisk': {
          color: theme.palette.error.main,
        },
        ...rest?.sx,
      }}
      InputProps={{
        endAdornment:
          rest.error && !endAdornment ? ErrorEndIcon() : endAdornment,
        startAdornment: startAdornment,
        ...rest?.InputProps,
      }}
      inputProps={{
        ...rest.inputProps,
      }}
      onChange={handleChange}
    />
  );
};

export default TextField;
