import { Box, Divider, Grid, InputAdornment, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import AddIconSvg from '../../../../public/assets/addIcon.svg';
import { PHONE_CODE_OPTIONS } from '../../../core-utils/constants';
import onBoardingMessages from '../../../core-utils/messages/onBoarding';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import IconFromSvg from '../../atoms/IconFromSvg';
import TextField from '../../atoms/TextField';
import StyledDropdown from '../../molecules/OrganisationFormDropdown';

const style = {
  background: theme.palette.common.white,
  border: `1px solid ${EXTRA_COLORS.blue_100}`,
  borderRadius: '4px',
  boxShadow: theme.shadows[10],
  width: theme.spacing(187),
  height: theme.spacing(130),
  paddingLeft: theme.spacing(19),
  paddingRight: theme.spacing(19),
};

const boxStyle = {
  width: '42px',
  height: '42px',
  border: `1px solid ${EXTRA_COLORS.lightGrayishBlue}`,
  boxShadow: theme.shadows[12],
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.common.white,
};

const Input = styled('input')({
  display: 'none',
});

interface IOrganisationFormProps {
  handlePhoneCodeChange: (value: any) => void;
  handleLogoChange: (event: any) => void;
  organisationDetails: any;
  handleOrganisationTextFieldChange: (name: string, value: string) => void;
  handleOrganisationValidation?: () => void;
  handleCustomPhoneCodeChange?: (value: any) => void;
  isValidLogo?: boolean;
  logoValidationMessage?: string;
}

const OnboardingOrganisationForm = (props: IOrganisationFormProps) => {
  const {
    HEADING,
    SUB_HEADING,
    ORGANISATION_NAME,
    ORGANISATION_ADDRESS,
    ORGANISATION_PHONE,
    ORGANISATION_LOGO,
    ORGANISATION_PHONE_CODE,
    LOGO_TOO_BIG,
    PLACEHOLDERS: { PLACEHOLDER_NAME, PLACEHOLDER_ADDRESS, PLACEHOLDER_PHONE },
  } = onBoardingMessages.ORGANISATION_FORM;

  const {
    handlePhoneCodeChange,
    handleCustomPhoneCodeChange,
    handleLogoChange,
    handleOrganisationTextFieldChange,
    handleOrganisationValidation,
    organisationDetails,
    isValidLogo,
    logoValidationMessage
  } = props;

  return (
    <Grid container direction="column" justifyContent="space-evenly" sx={style}>
      <Grid item container direction="column" textAlign="center">
        <Grid item>
          <Typography variant="h3">{HEADING}</Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
              paddingTop: theme.spacing(1),
            }}
          >
            {SUB_HEADING}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        sx={{ flexDirection: "column", position: "relative" }}
      >
        <Grid item container>
        <Grid item>
          {organisationDetails[ORGANISATION_LOGO] ? (
            <label htmlFor="upload-file">
              <Box
                sx={{
                  width: '98px',
                  height: '98px',
                    border: isValidLogo ? `1px dashed ${EXTRA_COLORS.lightGrayishBlue}` : `1px solid ${EXTRA_COLORS.redishOrange}`,
                  borderRadius: '4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  overflow: 'hidden',
                }}
              >
                <IconFromSvg
                  path={organisationDetails[ORGANISATION_LOGO]}
                  alt="org-logo"
                  width={96}
                />
              </Box>
            </label>
          ) : (
            <Box
              sx={{
                width: '98px',
                height: '98px',
                    border: isValidLogo ? `1px dashed ${EXTRA_COLORS.mediumBlue_100}` : `1px solid ${EXTRA_COLORS.redishOrange}`,
                borderRadius: '3px',
                backgroundColor: EXTRA_COLORS.teritary_blue,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="caption"
                sx={{ color: theme.palette.text.secondary }}
              >
                {onBoardingMessages.ADD_LOGO}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item sx={{ mt: '67px' }}>
          <Input
            accept="image/*"
            id="upload-file"
            type="file"
            onChange={(event) => {
              handleLogoChange(event);
            }}
          />
          <label htmlFor="upload-file">
            <Box
              sx={{
                ...boxStyle,
                position: 'absolute',
                ml: -6,
                zIndex: 9999,
                cursor: 'pointer',
              }}
            >
              <IconFromSvg path={AddIconSvg} alt="add-icon" />
            </Box>
          </label>
        </Grid>
        </Grid>
        {
          isValidLogo
            ? null
            : <Typography
              sx={{
                marginTop: theme.spacing(-5),
                marginBottom: theme.spacing(2),
                overflowWrap: 'break-word',
                wordBreak: 'break-all',
                position: 'absolute',
                bottom: theme.spacing(-8)
              }}
              variant="body2" color="error"
            >
              {logoValidationMessage}
            </Typography>
        }
      </Grid>
      <Grid item>
        <TextField
          name={ORGANISATION_NAME}
          label={ORGANISATION_NAME}
          value={organisationDetails[ORGANISATION_NAME]}
          testId={ORGANISATION_NAME}
          placeholder={PLACEHOLDER_NAME}
          required
          autoComplete="off"
          handleChange={(e) => {
            handleOrganisationTextFieldChange(e.target.name, e.target.value);
          }}
          onBlur={handleOrganisationValidation}
        />
      </Grid>
      <Grid item>
        <TextField
          name={ORGANISATION_ADDRESS}
          label={ORGANISATION_ADDRESS}
          value={organisationDetails[ORGANISATION_ADDRESS]}
          testId={ORGANISATION_ADDRESS}
          placeholder={PLACEHOLDER_ADDRESS}
          autoComplete="off"
          handleChange={(e) => {
            handleOrganisationTextFieldChange(e.target.name, e.target.value);
          }}
          onBlur={handleOrganisationValidation}
        />
      </Grid>
      <Grid item>
        <TextField
          name={ORGANISATION_PHONE}
          label={ORGANISATION_PHONE}
          testId={ORGANISATION_PHONE}
          value={organisationDetails[ORGANISATION_PHONE]}
          placeholder={PLACEHOLDER_PHONE}
          autoComplete="off"
          handleChange={(e) => {
            handleOrganisationTextFieldChange(e.target.name, e.target.value);
          }}
          onBlur={handleOrganisationValidation}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Grid container sx={{ width: 105 }}>
                  <Grid item sx={{ ml: -3 }}>
                    <StyledDropdown
                      value={organisationDetails[ORGANISATION_PHONE_CODE] || PHONE_CODE_OPTIONS?.[0]}
                      options={PHONE_CODE_OPTIONS}
                      data-testid="Organisation Phone Code"
                      required
                      handleDropdownChange={(e, v) => {
                        handlePhoneCodeChange(v);
                      }}
                      handleInputChange={(e, v) => {
                        handleCustomPhoneCodeChange &&
                          handleCustomPhoneCodeChange(v);
                      }}
                      onBlur={handleOrganisationValidation}
                    />
                  </Grid>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ border: '0.5px solid ' + EXTRA_COLORS.blue_100 }}
                  />
                </Grid>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};
export default OnboardingOrganisationForm;
