import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  createOperationRequest,
  getDropDownItemsToAddPolicy,
  getDropDownItemToAttachPolicy,
  getHeight,
} from '../../../core-utils/Helper/helper';
import {
  policiesHandleAddAttachClone,
  useShowOrHideElement,
  useWindowSize,
} from '../../../core-utils/Hooks/index.hook';
import theme from '../../../core-utils/theme';
import Button from '../../atoms/Button';
import IconFromSvg from '../../atoms/IconFromSvg';
import AttachOrClonePolicyPopUp from '../../molecules/AttachOrClonePolicyPopUp';
import DropDownMenu from '../../molecules/DropDownMenu';
import { IPoliciesTableRowProps } from '../../molecules/PoliciesTableRow';
import UpTriangleIcon from '../../../../public/assets/UpTriangle.svg';
import DownTriangleIcon from '../../../../public/assets/DownTriangle.svg';
import policyMessages from '../../../core-utils/messages/policyMessages';
import PoliciesTable from '../PoliciesTable';
import { getPolicies } from '../PoliciesDashboard/index.hook';
import LoadingAnimation from '../../atoms/LoadingAnimation';
import userGroupMessages from '../../../core-utils/messages/usergroup';
import ApplicationAccessPopUp from '../../molecules/ApplicationAccessPopUp';
import AppAlreadyExistsPopUp from '../../molecules/AppAlreadyExistsPopUp';
import appModuleMessages from '../../../core-utils/messages/app';
import { IAddPoliciesToGroupOrUserRequestBody } from '../../../api-service/policy-service/PolicyService';
import { OperationTypes } from '../../../core-utils/Interfaces/entityInterfaces';
import UserGroupService from '../../../api-service/user-group-service/UserGroupService';
import { policyAlreadyExistsForUserGroupOrUser } from './index.hook';

export interface IPolicyUserGroupTabItem {
  policies: IPoliciesTableRowProps[];
  nameOfUserGroup: string;
  updatePoliciesOfUserGroup?: any;
}

const PoliciesUserGroupTabItem = ({
  policies,
  nameOfUserGroup,
  updatePoliciesOfUserGroup,
}: IPolicyUserGroupTabItem) => {
  const [tabItemPolicies, setTabItemPolicies] = useState(policies);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<string[]>([]);
  const [allPolicies, setAllPolicies] = useState<IPoliciesTableRowProps[]>([]);
  const [openAccessWindow, setOpenAccessWindow] = useState<boolean>(false);
  const [openAlreadyExistsPopUp, setOpenAlreadyExistsPopUp] = useState<boolean>(
    false,
  );
  const [requestBody, setRequestBody] = useState<
    IAddPoliciesToGroupOrUserRequestBody[]
  >();
  const [policyId, setPolicyId] = useState<string | number>();
  const [arrow, setArrow] = useState<boolean>(false);
  const [content, setContent] = useState<string>(
    userGroupMessages.POLICIES_ACCESS_MESSAGE_TO_USERGROUP.replaceAll(
      '{groupName}',
      nameOfUserGroup,
    ),
  );

  const {
    handleAttachPolicy,
    toggleActive,
    type,
    isActive,
    showElement,
    open,
    hideElement,
  } = policiesHandleAddAttachClone();

  const reversePoliciesList = () => {
    setTabItemPolicies([...tabItemPolicies].reverse());
  };

  useEffect(() => {
    setLoading(true);
    getPolicies()
      .then((response) => {
        setAllPolicies(response.resultPolicyTableRows);
        setSearchData(response.resultSearchData);
      })
      .finally(() => setLoading(false));
  }, []);

  const { height } = useWindowSize();

  const handleCancelClick = () => {
    setOpenAccessWindow(false);
    setContent(
      userGroupMessages.POLICIES_ACCESS_MESSAGE_TO_USERGROUP.replaceAll(
        '{groupName}',
        nameOfUserGroup,
      ),
    );
    showElement();
  };

  const handleAllowClick = async () => {
    try {
      policyId &&
        (await UserGroupService.addPolicyToUserGroup(policyId, requestBody));
    } catch (error) {
      console.warn(error);
    } finally {
      updatePoliciesOfUserGroup && updatePoliciesOfUserGroup();
    }
    setOpenAccessWindow(false);
    setContent(
      userGroupMessages.POLICIES_ACCESS_MESSAGE_TO_USERGROUP.replaceAll(
        '{groupName}',
        nameOfUserGroup,
      ),
    );
  };

  const handleClick = (policyId: string | number, policyName: string) => {
    const requestBody: IAddPoliciesToGroupOrUserRequestBody[] = [
      createOperationRequest(OperationTypes.add, nameOfUserGroup, '/'),
    ];
    addPolicyToUserGroup(policyId, requestBody, policyName);
  };

  const addPolicyToUserGroup = async (
    policyId: string | number,
    requestBody: IAddPoliciesToGroupOrUserRequestBody[],
    policyName: string,
  ) => {
    const policyExists = await policyAlreadyExistsForUserGroupOrUser(
      policyId,
      nameOfUserGroup,
      'usergroup',
    );
    if (policyExists) {
      setOpenAlreadyExistsPopUp(true);
    } else {
      setRequestBody(requestBody);
      setOpenAccessWindow(true);
      setContent(content.replaceAll('{policyName}', policyName));
      setPolicyId(policyId);
    }
    hideElement();
  };

  useEffect(() => {
    setTabItemPolicies(policies);
    setArrow(!arrow);
  }, [policies]);

  return (
    <>
      {loading && <LoadingAnimation />}
      <AttachOrClonePolicyPopUp
        type={type}
        open={open}
        handleClose={hideElement}
        policies={allPolicies}
        searchData={searchData}
        handleClick={handleClick}
      />
      <ApplicationAccessPopUp
        open={openAccessWindow}
        onCancelClick={handleCancelClick}
        onAllowClick={handleAllowClick}
        mainText={content}
        heading={policyMessages.ATTACH_EXISTING_POLICY}
        buttonText={policyMessages.ATTACH}
      />
      <AppAlreadyExistsPopUp
        open={openAlreadyExistsPopUp}
        onButtonClick={() => setOpenAlreadyExistsPopUp(false)}
        mainText={userGroupMessages.POLICY_ALREADY_EXISTS_FOR_USERGROUP}
      />
      <Grid item container justifyContent="space-between">
        <Grid item>
          <Typography variant="body1" color={theme.palette.text.disabled}>
            {userGroupMessages.POLICIES_HEADING}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{ height: theme.spacing(10) }}
            onClick={handleAttachPolicy}
          >
            {policyMessages.ASSIGN_POLICY}
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ height: getHeight(height, 'policies'), mt: 4 }}
        xs={12}
      >
        <PoliciesTable
          onNameSortClick={reversePoliciesList}
          tableData={tabItemPolicies}
          underTab="usergroup"
          arrow={arrow}
          hideStatus={true}
        />
      </Grid>
    </>
  );
};

export default PoliciesUserGroupTabItem;
