import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  IUserGroupsAndAppsTableRow,
  USER_GROUPS_AND_APPS_HEADERS,
} from '../../../core-utils/constants';
import appModuleMessages from '../../../core-utils/messages/app';
import theme from '../../../core-utils/theme';
import Button from '../../atoms/Button';

import AppsTable from '../AppsTable';
import Table from '../Table';
import EmptyAssignmentState from '../../molecules/NoAssignmentState';
import {
  createOperationRequest,
  getHeight,
  handleError,
} from '../../../core-utils/Helper/helper';
import { ApplicationSelectCardProps } from '../../molecules/ApplicationSelectCard';
import {
  useShowOrHideElement,
  useWindowSize,
} from '../../../core-utils/Hooks/index.hook';
import userGroupMessages from '../../../core-utils/messages/usergroup';
import AppService, {
  IAddOrRemoveAppsToUserRequestBody,
} from '../../../api-service/app-service/AppService';
import { constructAppCards } from '../AppsOfUserTabItem/index.hook';
import UserGroupService from '../../../api-service/user-group-service/UserGroupService';
import { OperationTypes } from '../../../core-utils/Interfaces/entityInterfaces';
import LoadingAnimation from '../../atoms/LoadingAnimation';
import ApplicationAccessPopUp from '../../molecules/ApplicationAccessPopUp';
import AppAlreadyExistsPopUp from '../../molecules/AppAlreadyExistsPopUp';
import SelectApplication from '../SelectApplication';
import EmptyStateForErrors from '../../molecules/EmptyStateForErrors';
import { appAlreadyExistsForUserGroup } from './index.hook';

interface IAppsOfUserTabItemProps {
  appDetails?: IUserGroupsAndAppsTableRow[];
  usergroupId: string;
  loadAllApps: any;
  updateAppsOfUserGroup?: any;
}

const AppsOfUserGroupTabItem = ({
  appDetails,
  usergroupId,
  updateAppsOfUserGroup,
  loadAllApps,
}: IAppsOfUserTabItemProps) => {
  const [allApps, setAllApps] = useState<ApplicationSelectCardProps[]>();
  const { open, showElement, hideElement } = useShowOrHideElement();
  const [openAccessWindow, setOpenAccessWindow] = useState<boolean>(false);
  const [openAlreadyExistsPopUp, setOpenAlreadyExistsPopUp] = useState<boolean>(
    false,
  );
  const [content, setContent] = useState<string>(
    userGroupMessages.APP_ACCESS_MESSAGE_TO_USERGROUP.replaceAll(
      '{usergroupName}',
      usergroupId,
    ),
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [requestBody, setRequestBody] = useState<
    IAddOrRemoveAppsToUserRequestBody[]
  >();
  const [tabItemApps, setTabItemApps] = useState(appDetails);
  const [arrow, setArrow] = useState<boolean>(false);
  const [appResponse, setAppResponse] = useState<any>();

  const [errorProps, setErrorProps] = React.useState<{
    imagePath: string;
    text: string;
    helperText: string;
    helperSubText?: string;
    actionName: string;
    secondActionName?: string;
    onActionClick: () => void;
    onSecondActionClick?: () => void;
  }>();

  const handleCancelClick = () => {
    setOpenAccessWindow(false);
    setContent(
      userGroupMessages.APP_ACCESS_MESSAGE_TO_USERGROUP.replaceAll(
        '{usergroupName}',
        usergroupId,
      ),
    );
    showElement();
  };

  const handleAllowClick = async () => {
    try {
      await UserGroupService.addAppsToUserGroup(usergroupId, requestBody);
    } catch (error: any) {
      const {
        imagePath,
        HEADING,
        TEXT,
        SUB_TEXT,
        BACK,
        REFRESH,
        handleBackClick,
        handleRefreshClick,
      } = handleError(error.response.status);
      setErrorProps({
        imagePath: imagePath,
        text: HEADING,
        helperText: TEXT,
        helperSubText: SUB_TEXT,
        actionName: BACK,
        secondActionName: REFRESH,
        onActionClick: handleBackClick,
        onSecondActionClick: handleRefreshClick,
      });
    } finally {
      updateAppsOfUserGroup && updateAppsOfUserGroup();
    }

    setOpenAccessWindow(false);
    setContent(
      userGroupMessages.APP_ACCESS_MESSAGE_TO_USERGROUP.replaceAll(
        '{usergroupName}',
        usergroupId,
      ),
    );
  };

  const reverseAppList = () => {
    tabItemApps && setTabItemApps([...tabItemApps].reverse());
  };

  const handleClick = (appId: string, appName: string) => {
    const requestBody: IAddOrRemoveAppsToUserRequestBody[] = [
      createOperationRequest(
        OperationTypes.add,
        'READ',
        `/connectionPermissions/${appId}`,
      ),
    ];
    addAppToUserGroup(usergroupId, appId, requestBody, appName);
  };

  const addAppToUserGroup = async (
    usergroupId: string,
    appId: string,
    requestBody: IAddOrRemoveAppsToUserRequestBody[],
    appName: string,
  ) => {
    const appExists = await appAlreadyExistsForUserGroup(appId, usergroupId);
    if (appExists) {
      setOpenAlreadyExistsPopUp(true);
    } else {
      setContent(content.replaceAll('{appName}', appName));
      setRequestBody(requestBody);
      setOpenAccessWindow(true);
    }
    hideElement();
  };

  useEffect(() => {
    setLoading(true);
    loadAllApps()
      .then((response: any) => {
        setAppResponse(response);
        setAllApps(
          constructAppCards(response.childConnections ?? [], appDetails),
        );
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const appCards =
      appResponse &&
      constructAppCards(appResponse.childConnections ?? [], appDetails);
    setAllApps(appCards);
  }, [appDetails]);

  useEffect(() => {
    setTabItemApps(appDetails);
    setArrow(!arrow);
  }, [appDetails]);

  const { height } = useWindowSize();
  return (
    <>
      {errorProps ? (
        <EmptyStateForErrors {...errorProps} />
      ) : (
        <Grid
          sx={{
            width: '100%',
            height: `calc(100% - ${theme.spacing(16.25)})`,
          }}
          container
          direction="row"
          alignItems="stretch"
          gap={6}
        >
          {loading && <LoadingAnimation />}
          <ApplicationAccessPopUp
            open={openAccessWindow}
            onCancelClick={handleCancelClick}
            onAllowClick={handleAllowClick}
            mainText={content}
          />
          <AppAlreadyExistsPopUp
            open={openAlreadyExistsPopUp}
            onButtonClick={() => setOpenAlreadyExistsPopUp(false)}
            mainText={appModuleMessages.APP_ALREADY_EXISTS_FOR_USERGROUP}
          />
          {allApps && (
            <SelectApplication
              open={open}
              apps={allApps}
              handleClose={hideElement}
              handleClickAppCard={handleClick}
            />
          )}

          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
          >
            <Typography variant="body1" color={theme.palette.text.disabled}>
              {appModuleMessages.APPLICATIONS_ACCESS_MESSAGE_UNDER_USERGROUP}
            </Typography>
            <Button
              variant="contained"
              sx={{ height: theme.spacing(10) }}
              onClick={showElement}
            >
              {appModuleMessages.ADD_APPLICATION.ASSIGN_APPLICATION}
            </Button>
          </Grid>
          <Grid
            item
            sx={{
              height:
                tabItemApps && tabItemApps.length
                  ? getHeight(height, 'apps')
                  : 'fit-content',
            }}
            xs={12}
            container
          >
            {tabItemApps?.length ? (
              <>
                <AppsTable
                  tabledata={tabItemApps}
                  onLabelClick={reverseAppList}
                  arrow={arrow}
                  hideNoOfUsers={true}
                />
              </>
            ) : (
              <>
                <Table headers={USER_GROUPS_AND_APPS_HEADERS(true)} />
                <EmptyAssignmentState
                  displayText={
                    appModuleMessages.APPLICATIONS_EMPTY_STATE_UNDER_USERGROUP
                  }
                />
              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AppsOfUserGroupTabItem;
