import { IconButton, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { IDropDownMenuItem } from '../../../core-utils/constants';
import DropDownItem from '../DropDownItem';

const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    border: '1px solid ' + theme.palette.grey[400],
    boxSizing: 'border-box',
    boxShadow: theme.shadows[12],
    borderRadius: theme.spacing(1.25),
  },
}));

export interface IGenericSelectedDropDownProps {
  menuItems: Array<IDropDownMenuItem>;
  itemForDropdownMenu?: JSX.Element;
  menuWidth?: number;
}

const PolicyBlockSelectDropdown = (props: IGenericSelectedDropDownProps) => {
  const { menuItems, menuWidth, itemForDropdownMenu } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <IconButton
        sx={{
          padding: 0,
        }}
        onClick={handleOpenMenu}
        disableRipple
      >
        {itemForDropdownMenu}
      </IconButton>
      <StyledMenu
        open={open}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        PaperProps={{
          style: {
            width: menuWidth ? menuWidth : '17%',
          },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 20 }}
      >
        {menuItems.map((item, index) => (
          <DropDownItem
            key={index}
            text={item.text}
            icon={item?.icon}
            activeIcon={item?.activeIcon}
            handleClick={item.handleClick}
            handleClose={handleCloseMenu}
            isDanger={item.isDanger}
          />
        ))}
      </StyledMenu>
    </div>
  );
};

export default PolicyBlockSelectDropdown;
