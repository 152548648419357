import { useTheme } from '@mui/material';
import React, { useState } from 'react';
import { ICheckBoxTextItemProps } from '.';

function useCheckwithText(props: ICheckBoxTextItemProps) {
  const theme = useTheme();
  const { item, handleCheckedItem } = props;
  const [color, setColor] = useState(theme.palette.text.disabled);

  React.useEffect(() => {
    if (props.isDisabled) {
      setColor(theme.palette.text.disabled);
    } else if (props.isChecked) {
      setColor(theme.palette.primary.main);
    } else {
      setColor(theme.palette.text.disabled);
    }
  }, [props.isChecked]);

  const handleChange = (
    _event:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    handleCheckedItem(item.id);
    props.clearInputField && props.clearInputField();
  };

  return { color, handleChange };
}

export default useCheckwithText;
