import React, { useContext, useEffect, useState } from 'react';
import { IDropDownItem } from '../../../core-utils/constants';
import policyBuilderMessages from '../../../core-utils/messages/policyBuilder';
import { SnackBarContext } from '../../pages/PolicyBuilder';

export const useColoredPolicyChipSelection = (
  items: Array<IDropDownItem>,
  selectedItems: Array<IDropDownItem>,
  handleOpenSnackbar?: () => void,
  handleCloseSnackbar?: () => void,
  disableAnd?: boolean,
  inCell?: boolean,
  cardId?: number,
  handlePolicyActionChange?: (cardId: number, value: any, id?: number) => void,
  rowId?: number,
) => {
  const [displayedItems, setDisplayedItems] = React.useState<
    Array<IDropDownItem>
  >(items);

  const [currentSelectedItems, setCurrentSelectedItems] = React.useState<
    Array<IDropDownItem>
  >(selectedItems);

  const [currentlyDisabledItems, setCurrentlyDisabledItems] = React.useState<
    Array<IDropDownItem>
  >([]);

  const setSnackBarMessages = useContext(SnackBarContext);

  useEffect(() => {
    if (currentSelectedItems.length === 0) {
      if (disableAnd) {
        disableAndAction(disableAnd);
      } else setCurrentlyDisabledItems([]);
    } else if (
      currentSelectedItems.some((item) => item.name.toUpperCase() === 'ALLOW')
    ) {
      setCurrentlyDisabledItems(handleDisability('ALLOW'));
    } else if (
      currentSelectedItems.some((item) => item.name.toUpperCase() === 'DENY')
    ) {
      setCurrentlyDisabledItems(handleDisability('DENY'));
    } else if (
      currentSelectedItems.some((item) => item.name.toUpperCase() === 'NOTIFY')
    ) {
      setCurrentlyDisabledItems(handleDisability('NOTIFY'));
    } else {
      setCurrentlyDisabledItems(handleDisability('AND'));
    }
    cardId !== undefined &&
      handlePolicyActionChange &&
      handlePolicyActionChange(cardId, currentSelectedItems, rowId);
  }, [currentSelectedItems]);

  useEffect(() => {
    disableAndAction(disableAnd);
  }, [disableAnd]);

  inCell &&
    useEffect(() => {
      setDisplayedItems(items);
      setCurrentSelectedItems([...selectedItems]);
    }, [items]);

  const sortedItems = () => {
    const sortedItems = [...currentSelectedItems].sort((one, two) =>
      one.name.localeCompare(two.name),
    );
    const andElement = sortedItems.find(
      (item) => item.name.toUpperCase() === 'AND',
    );
    const sortedItemsWithoutAnd = sortedItems.filter(
      (item) => item.name.toUpperCase() !== 'AND',
    );

    if (andElement) return [...sortedItemsWithoutAnd, andElement];
    else return sortedItemsWithoutAnd;
  };

  const disableAndAction = (value: boolean | undefined) => {
    if (value)
      setCurrentlyDisabledItems(
        items.filter((item) => item.name.toUpperCase() === 'AND'),
      );
    else
      setCurrentlyDisabledItems(
        currentlyDisabledItems.filter(
          (disableItem) => disableItem.id !== 'AND',
        ), //**** */
      );
  };
  const handleDisability = (id: string) => {
    if (id.toUpperCase() === 'ALLOW') {
      return items.filter(
        (item) =>
          item.name.toUpperCase() === 'DENY' ||
          (disableAnd && item.name.toUpperCase() === 'AND'),
      );
    } else if (id.toUpperCase() === 'DENY') {
      return items.filter(
        (item) =>
          item.name.toUpperCase() === 'ALLOW' ||
          (disableAnd && item.name.toUpperCase() === 'AND'),
      );
    } else if (id.toUpperCase() === 'NOTIFY') {
      if (disableAnd)
        return items.filter((item) => item.name.toUpperCase() === 'AND');
      else return [];
    } else if (id.toUpperCase() === 'AND') {
      return [];
    }
    return [];
  };

  const handleChange = (id: string, drop?: boolean) => {
    if (drop) {
      var droppedItem = displayedItems.filter(
        (item) => item.name.toUpperCase() === id.toUpperCase(),
      )[0];
      var droppedId = droppedItem.id;
    } else {
      var droppedId = id;
    }

    const selectedItemIndex = currentSelectedItems.findIndex(
      (item) => item.id === droppedId,
    );

    if (selectedItemIndex !== -1) {
      // Item already exists - removing it from selected items
      removeItem(droppedId);
    } else {
      const currentlySelectedItem = items.find((item) => item.id === droppedId);
      if (
        currentlySelectedItem &&
        currentlyDisabledItems.some(
          (disabledItem) => disabledItem.id === currentlySelectedItem.id,
        )
      ) {
        if (disableAnd && currentlySelectedItem.name.toUpperCase() == 'AND') {
          setSnackBarMessages({
            title: policyBuilderMessages.SNACKBAR_MESSAGES.DISABLE_AND_TITLE,
            subtitle:
              policyBuilderMessages.SNACKBAR_MESSAGES.DISABLE_AND_SUBTITLE,
          });
        } else {
          setSnackBarMessages({
            title: policyBuilderMessages.SNACKBAR_MESSAGES.TITLE,
            subtitle: policyBuilderMessages.SNACKBAR_MESSAGES.SUBHEADING,
          });
        }
        handleOpenSnackbar?.();
      } else if (currentSelectedItems.length === 0 && currentlySelectedItem) {
        if (disableAnd && currentlySelectedItem.name.toUpperCase() == 'AND')
          return;
        else
          setCurrentSelectedItems([
            ...currentSelectedItems,
            currentlySelectedItem,
          ]);
      } else if (currentSelectedItems.length > 3) {
        return;
      } else if (currentSelectedItems.length <= 2) {
        //When only one item is selected in the dropdown.

        /* 'Allow' and 'Deny' can either be alone or used with notfiy.
           'And' will always be used alone.
           We can't choose more than two options at once. 
            */
        if (
          currentSelectedItems[0].name.toUpperCase() === 'AND' &&
          currentlySelectedItem
        ) {
          setCurrentSelectedItems([
            ...currentSelectedItems,
            currentlySelectedItem,
          ]);
        } else if (currentSelectedItems[0].name.toUpperCase() === 'ALLOW') {
          if (
            currentlySelectedItem?.name.toUpperCase() === 'NOTIFY' ||
            (currentlySelectedItem?.name.toUpperCase() === 'AND' &&
              !disableAnd &&
              currentlySelectedItem)
          ) {
            setCurrentSelectedItems([
              ...currentSelectedItems,
              currentlySelectedItem,
            ]);
          }
        } else if (currentSelectedItems[0].name.toUpperCase() === 'DENY') {
          if (
            currentlySelectedItem?.name.toUpperCase() === 'NOTIFY' ||
            (currentlySelectedItem?.name.toUpperCase() === 'AND' &&
              !disableAnd &&
              currentlySelectedItem)
          ) {
            setCurrentSelectedItems([
              ...currentSelectedItems,
              currentlySelectedItem,
            ]);
          }
        } else if (currentSelectedItems[0].name.toUpperCase() === 'NOTIFY') {
          if (
            currentlySelectedItem?.name.toUpperCase() === 'ALLOW' ||
            currentlySelectedItem?.name.toUpperCase() === 'DENY' ||
            (currentlySelectedItem?.name.toUpperCase() === 'AND' &&
              !disableAnd &&
              currentlySelectedItem)
          ) {
            setCurrentSelectedItems([
              ...currentSelectedItems,
              currentlySelectedItem,
            ]);
          }
        }
      }
    }
  };

  const removeItem = (id: string) => {
    const tempSelectedItems = currentSelectedItems.filter(
      (item) => item.id !== id,
    );

    setCurrentSelectedItems(tempSelectedItems);
  };

  const sortBySelectedItems = () => {
    const tempUnselectedItems = displayedItems.filter(function (displayedItem) {
      return !currentSelectedItems.some(
        (selectedItem) => selectedItem.id === displayedItem.id,
      );
    });
    const tempDisplayedItems = [
      ...currentSelectedItems,
      ...tempUnselectedItems,
    ];
    setDisplayedItems(tempDisplayedItems);
  };

  const resetSelectedItems = () => {
    setCurrentSelectedItems(selectedItems);
  };

  return {
    currentSelectedItems,
    handleChange,
    removeItem,
    displayedItems,
    sortBySelectedItems,
    resetSelectedItems,
    sortedItems,
    currentlyDisabledItems,
  };
};
