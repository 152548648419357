import React from 'react';
import { styled } from '@mui/styles';
import {
  Box,
  Grid,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';
import theme from '../../../core-utils/theme';
import InfoIcon from '../../../../public/assets/info-inactive.svg';
import IconFromSvg from '../../atoms/IconFromSvg';
import policyBuilderMessages from '../../../core-utils/messages/policyBuilder';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} enterDelay={1000} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    height: 'fit-content',
    width: theme.spacing(62),
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(1.25),
    boxShadow: theme.shadows[12],
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    fontSize: 24,
    '&:before': {
      border: `2px solid ${theme.palette.grey[400]}`,
      borderRadius: theme.spacing(0.5),
    },
  },
}));

export interface IPolicyHelpTooltipProps {
  children: JSX.Element;
  operation?: 'and' | 'or';
  heading?: string;
  subHeading?: string;
}

const PolicyHelpTooltip = ({
  operation,
  children,
  heading,
  subHeading,
}: IPolicyHelpTooltipProps) => {
  const { AND, OR } = policyBuilderMessages.HELP_TOOLTIP;
  const title = heading
    ? heading
    : operation === 'and'
    ? AND.HEADING
    : OR.HEADING;
  const subtitle = subHeading
    ? subHeading
    : operation === 'and'
    ? AND.SUBHEADING
    : OR.SUBHEADING;
  return (
    <StyledTooltip
      arrow
      placement="bottom-start"
      title={
        <Box
          display="flex"
          flexDirection="row"
          columnGap={1}
          // pl={3}
          // pr={5}
          pt={3}
          pb={4.25}
          alignItems="flex-start"
        >
          <IconFromSvg path={InfoIcon} alt="info" />
          <Box flexDirection="column" rowGap={1}>
            <Typography variant="subtitle2" color={theme.palette.text.primary}>
              {title}
            </Typography>
            <Typography variant="caption" color={theme.palette.text.secondary}>
              {subtitle}
            </Typography>
          </Box>
        </Box>
      }
    >
      {children}
    </StyledTooltip>
  );
};

export default PolicyHelpTooltip;
