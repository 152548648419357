import { createSlice } from '@reduxjs/toolkit';

interface notification {
  message: string;
  type: string;
  viewStatus: boolean;
  notificationId: number;
  heading: string;
  subheading: string;
  subject: string;
  sentDate: string;
}
// Define a type for the slice state
interface notificationState {
  newNotification: notification[];
  allNotification: notification[];
  isConnected: boolean;
}

// Define the initial state using that type
const initialState: notificationState = {
  newNotification: [],
  allNotification: [],
  isConnected: false,
};

const notificationSlice = createSlice({
  name: 'sidenavSlice',
  initialState: initialState,
  reducers: {
    setNewNotification(state, action) {
      state.newNotification = action.payload;
    },
    resetNewNotification(state, action) {
      state.newNotification = [];
    },
    setConnection(state, action) {
      state.isConnected = !state.isConnected;
    },
    setNotificationAsViewed(state, action) {
      state.newNotification = state.newNotification.filter(
        (notification) => notification.notificationId !== action.payload,
      );
    },
  },
});

export const notificationAction = notificationSlice.actions;

export default notificationSlice;
