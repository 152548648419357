import { Grid, Typography, Box, InputAdornment, Divider } from '@mui/material';
import React, { useState } from 'react';
import { getAvatarName } from '../../../core-utils/Helper/helper';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import { Avatars } from '../../atoms/Avatar';
import IconFromSvg from '../../atoms/IconFromSvg';
import { styled } from '@mui/material/styles';
import CameraSvg from '../../../../public/assets/camera-active.svg';
import TextField from '../../atoms/TextField';
import StyledDropdown from '../../molecules/OrganisationFormDropdown';
import { PHONE_CODE_OPTIONS } from '../../../core-utils/constants';
import Button from '../../atoms/Button';
import onBoardingMessages from '../../../core-utils/messages/onBoarding';

export interface IUserInformatipnProps {
  handlePhoneCodeChange: (value: any) => void;
  handleLogoChange: (event: any) => void;
  handleInformationTextFieldChange: (name: string, value: string) => void;
  handleInformationFormValidation?: () => void;
  handleUserLogoRemove?: () => void;
  userInformationDetails?: any;
}
const style = {
  background: theme.palette.common.white,
  border: `1px solid ${EXTRA_COLORS.blue_100}`,
  borderRadius: '4px',
  boxShadow: theme.shadows[10],
  width: theme.spacing(179),
  height: theme.spacing(110),
  paddingLeft: theme.spacing(19),
  paddingRight: theme.spacing(19),
};

const boxStyle = {
  width: theme.spacing(10.5),
  height: theme.spacing(10.5),
  border: `1px solid ${EXTRA_COLORS.lightGrayishBlue}`,
  boxShadow: theme.shadows[12],
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.common.white,
};

const Input = styled('input')({
  display: 'none',
});

const UserInformationForm = (props: IUserInformatipnProps) => {
  const {
    HEADING,
    SUB_HEADING,
    USER_NAME,
    USER_PHONE,
    USER_PHONE_CODE,
    USER_LOGO,
    REMOVE,
    PLACEHOLDERS: { PLACEHOLDER_NAME, PLACEHOLDER_PHONE },
  } = onBoardingMessages.INFORMATION_FORM;

  const {
    handlePhoneCodeChange,
    handleLogoChange,
    handleInformationTextFieldChange,
    handleInformationFormValidation,
    handleUserLogoRemove,
    userInformationDetails,
  } = props;

  return (
    <Grid container direction="column" justifyContent="space-evenly" sx={style}>
      <Grid item container direction="column" textAlign="center">
        <Grid item>
          <Typography variant="h3">{HEADING}</Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
              paddingTop: theme.spacing(1),
            }}
          >
            {SUB_HEADING}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item>
          {userInformationDetails[USER_LOGO] ? (
            <IconFromSvg
              path={userInformationDetails[USER_LOGO]}
              alt="User-Logo"
              height={96}
              width={100}
            />
          ) : (
            <Avatars
              isImage={false}
              children={
                userInformationDetails[USER_NAME]
                  ? getAvatarName(userInformationDetails[USER_NAME])
                  : ' '
              }
              size={96}
            />
          )}
        </Grid>
        {/* <Grid item sx={{ mt: 13.5, ml: -1 }}>
          <Input
            accept="image/*"
            id="upload-file"
            type="file"
            onChange={(event) => {
              handleLogoChange(event);
            }}
          />
          <label htmlFor="upload-file">
            <Box
              sx={{
                ...boxStyle,
                position: 'absolute',
                ml: -6,
                zIndex: 9999,
                cursor: 'pointer',
              }}
            >
              <IconFromSvg path={CameraSvg} alt="add-icon" />
            </Box>
          </label>
        </Grid> */}
        {userInformationDetails[USER_LOGO] && (
          <Grid item sx={{ mt: 15.5, ml: 7 }}>
            <Button
              variant="text"
              sx={{
                color: theme.palette.text.secondary,
                '&:hover': {
                  backgroundColor: 'unset',
                },
              }}
              onClick={handleUserLogoRemove}
            >
              {REMOVE}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <TextField
          name={USER_NAME}
          label={USER_NAME}
          testId={USER_NAME}
          value={userInformationDetails[USER_NAME]}
          placeholder={PLACEHOLDER_NAME}
          required
          autoComplete="off"
          handleChange={(e) => {
            handleInformationTextFieldChange(e.target.name, e.target.value);
          }}
          onBlur={handleInformationFormValidation}
        />
      </Grid>
      {/* <Grid item>
        <TextField
          name={USER_PHONE}
          label={USER_PHONE}
          testId={USER_PHONE}
          value={userInformationDetails[USER_PHONE]}
          placeholder={PLACEHOLDER_PHONE}
          autoComplete="off"
          handleChange={(e) => {
            handleInformationTextFieldChange(e.target.name, e.target.value);
          }}
          onBlur={handleInformationFormValidation}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Grid container sx={{ width: 105 }}>
                  <Grid item sx={{ ml: -3 }}>
                    <StyledDropdown
                      value={userInformationDetails[USER_PHONE_CODE]}
                      options={PHONE_CODE_OPTIONS}
                      data-testid="Organisation Phone Code"
                      required
                      handleDropdownChange={(e, v) => {
                        handlePhoneCodeChange(v);
                      }}
                      onBlur={handleInformationFormValidation}
                    />
                  </Grid>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ border: '0.5px solid ' + EXTRA_COLORS.blue_100 }}
                  />
                </Grid>
              </InputAdornment>
            ),
          }}
        />
      </Grid> */}
    </Grid>
  );
};

export default UserInformationForm;
