import { createSlice, current } from '@reduxjs/toolkit';
// Define a type for the slice state
interface connectionsState {
  details: any[]
}
// Define the initial state using that type
const initialState: connectionsState = {
    details: [],
};

const connectionsSlice = createSlice({
    name: 'connectionsSlice',
    initialState: initialState,
    reducers: {
      setconnectionDetails(state, action) {
        const connectionDetails = action?.payload;
        const existingConnectionIndex: number = state.details.findIndex((e: any) => e.identifier === connectionDetails.identifier);
        if (existingConnectionIndex === -1) {
          state.details.push(connectionDetails);
        } else {
          state.details = state.details.map((e, index) => index === existingConnectionIndex ? { ...connectionDetails } : e);
        }
      },
      clearconnections(state) {
        state.details = [];
      }
    },
});
export const connectionsActions = connectionsSlice.actions;
export default connectionsSlice;