import { Grid, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import React from 'react';
import loadingAnimation from './loadingAnimation.json';
import { makeStyles } from '@mui/styles';
import theme, { EXTRA_COLORS } from '@utils/theme';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: EXTRA_COLORS.whiteOverlay,
    // placing the loader above all other elements
    zIndex: 99999,
  },
  loader: {
    width: theme.spacing(32),
  },
}));

const LoadingAnimation = ({ compact = false, msg = '' }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={compact ? '' : classes.container}
      alignItems="center"
      justifyContent="center"
      flexDirection='column'
    >
      <Grid item className={classes.loader}>
        <Lottie animationData={loadingAnimation} />
      </Grid>
      {msg ? <Typography
        variant="body2"
        sx={{ color: theme.palette.text.disabled, pt: theme.spacing(1), textAlign: 'center' }}
      >
        {msg}
      </Typography> : null}
    </Grid>
  );
};

export default LoadingAnimation;
