import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import dashboardMessages from '../../../core-utils/messages/dashboard';
import theme from '../../../core-utils/theme';
import StatsOverviewItem from '../StatsOverviewItem';

const style = {
  width: '100%',
  height: theme.spacing(106),
  borderRadius: theme.spacing(2.5),
  boxShadow: theme.shadows[11],
  backgroundColor: theme.palette.common.white,
};

export interface IOverviewUsersAndApps {
  name: string;
  count: number;
  itemIcon: string;
  overviewType?: 'neutral' | 'positive' | 'negative';
  variationCount?: number;
  uploadOrDownloadInBytes?: string;
}

const OverviewUsersAndApps = ({
  overviewItems,
  statusType,
}: {
  overviewItems: IOverviewUsersAndApps[];
  statusType?: { status: string; loginTime: string };
}) => {
  return (
    <Grid container sx={style} direction="column">
      <Grid
        container
        sx={{ px: theme.spacing(6), pt: theme.spacing(3) }}
        justifyContent="space-between"
      >
        <Grid item>
          <Typography variant="subtitle2">
            {dashboardMessages.OVERVIEW}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ mt: theme.spacing(2.5) }} />
      <Grid
        item
        container
        alignItems="center"
        xs={true}
        justifyContent="space-between"
        sx={{ px: 20, mt: 2 }}
        gap={5}
      >
        {overviewItems.slice(0, 3).map((item, index) => (
          <React.Fragment key={index}>
            <StatsOverviewItem overviewItem={item} />
          </React.Fragment>
        ))}
      </Grid>
      <Grid item sx={{ ml: 15 }}>
        <Divider sx={{ width: '92%' }} />
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        xs={true}
        justifyContent="space-between"
        sx={{ px: 20, mt: 2 }}
        gap={5}
      >
        {overviewItems.slice(3).map((item, index) => (
          <React.Fragment key={index}>
            <StatsOverviewItem overviewItem={item} />
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
};

export default OverviewUsersAndApps;
