import { useState } from 'react';
import HelpService from '../../../api-service/help-service/Helpservice';
import { FAQType, HelpFAQType } from '../../../core-utils/helpFAQs';
import { ROUTES } from '../../../core-utils/routes';

export const getHelpFaqs = async () => {
  const currentPath = window.location.pathname;
  const response = await HelpService.getHelpFaqs();
  const faqs: FAQType[] = [];
  let context: any = '';
  const allFaqs: FAQType[] = [];
  const keys = Object.keys(response);
  keys.forEach((key) => {
    response[key].forEach((faq: any) => {
      allFaqs.push({
        id: faq.questionId,
        title: faq.question,
        body: faq.answer,
        videoLink: faq.videoLink,
      });
    });
  });
  if (
    currentPath.includes(ROUTES.USERS) ||
    currentPath.includes(ROUTES.USER_DETAILS)
  ) {
    context = response['Users'];
  } else if (
    currentPath.includes(ROUTES.USER_GROUPS) ||
    currentPath.includes(ROUTES.USER_GROUP_DETAILS)
  ) {
    context = response['User Groups'];
  } else if (
    currentPath.includes(ROUTES.APPS) ||
    currentPath.includes(ROUTES.APP_DETAILS)
  ) {
    context = response['Application Management'];
  } else if (currentPath.includes(ROUTES.MY_APPLICATIONS)) {
    context = response['My Applications'];
  } else if (
    currentPath.includes(ROUTES.SESSION_RECORDINGS) ||
    currentPath.includes(ROUTES.SESSION_RECORDING)
  ) {
    context = response['User Sessions'];
  } else if (currentPath.includes(ROUTES.LOGS)) {
    context = response['Logs'];
  } else if (currentPath.includes(ROUTES.SETTINGS)) {
    context = response['Settings'];
  } else if (currentPath.includes(ROUTES.POLICIES)) {
    context = response['Policy List'];
  } else if (
    currentPath.includes(ROUTES.CREATE_POLICY) ||
    currentPath.includes(ROUTES.EDIT_POLICY)
  ) {
    context = response['Policy Builder'];
  } else if (currentPath.includes(ROUTES.DASHBOARD)) {
    context = response['Dashboard'];
  } else {
    context = response.global;
  }
  context.forEach((faq: any) => {
    faqs.push({
      id: faq.questionId,
      title: faq.question,
      body: faq.answer,
      videoLink: faq.videoLink,
    });
  });
  return { faqs, allFaqs };
};
