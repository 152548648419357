import { BoxProps, Theme, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { makeStyles } from '@mui/styles';
import CheckboxTextItem from '../../molecules/CheckboxTextItem';
import { EMPTY_FUNCTION, IDropDownItem } from '../../../core-utils/constants';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { customStyles, EXTRA_COLORS } from '../../../core-utils/theme';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: theme.palette.grey[300],
    border: theme.spacing(0.25) + ' solid ' + theme.palette.grey[300],
  },
}));

export interface IDropDown extends BoxProps {
  dropDownList: IDropDownItem[];
  checkedItems: IDropDownItem[];
  currentlyDisabledItems?: IDropDownItem[];
  handleCheckedItems: (id: string) => void;
  handleClickAway?: (event: MouseEvent | TouchEvent) => void;
  category?: 'users' | 'usergroups';
  policyComponent?: boolean;
  usergroups?: Array<IDropDownItem>;
  clearInputField?: () => void;
  cyId?: string;
}

const SearchDropDown = ({
  policyComponent = false,
  usergroups,
  clearInputField,
  ...props
}: IDropDown) => {
  const theme = useTheme();
  const {
    dropDownList,
    checkedItems,
    handleCheckedItems,
    currentlyDisabledItems,
    category,
  } = props;

  const isCheckboxItemDisabled = (item: IDropDownItem) => {
    if (currentlyDisabledItems)
      return currentlyDisabledItems.some(
        (disabledItem) => disabledItem.id === item.id,
      );
    else {
      return false;
    }
  };

  const ResultList =
    usergroups &&
    usergroups.map((item: IDropDownItem, index: number) => {
      return (
        <CheckboxTextItem
          key={index}
          item={item}
          isChecked={checkedItems.some(
            (checkedItem) => checkedItem.id == item.id,
          )}
          handleCheckedItem={handleCheckedItems}
          isDisabled={isCheckboxItemDisabled(item)}
          clearInputField={clearInputField}
        />
      );
    });

  return (
    <ClickAwayListener
      onClickAway={
        props.handleClickAway ? props.handleClickAway : EMPTY_FUNCTION
      }
    >
      <Box
        data-testid="input-text-field"
        cy-data-id={props?.cyId}
        sx={{
          width: policyComponent
            ? 205
            : category === 'users' || 'usergroups'
            ? theme.spacing(90)
            : theme.spacing(143.5),
          boxShadow: '00px 12px 16px rgba(0, 0, 0, 0.08)',
          borderRadius: theme.spacing(1),
          border: `1px solid ${EXTRA_COLORS.lightGrayishBlue}`,
          zIndex: 10,
          position: policyComponent ? 'absolute' : 'fixed',
        }}
        display="flex"
        flexDirection="column"
      >
        {policyComponent && <Box children={ResultList} />}
        {!policyComponent && usergroups && usergroups.length <= 3 && (
          <Box children={ResultList} />
        )}

        {!policyComponent && usergroups && usergroups.length > 3 && (
          <Box
            sx={{
              height: theme.spacing(36.875),
              overflowY: 'scroll',
              ...customStyles.scrollBar,
            }}
            children={ResultList}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
};

export default SearchDropDown;
