import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import theme from '../../../core-utils/theme';
import IconFromSvg from '../../atoms/IconFromSvg';
import Button from '../../atoms/Button';

export interface IEmptyStateForErrors {
  imagePath: string;
  alt?: string;
  text: string;
  helperText: string;
  helperSubText?: string;
  actionName: string;
  secondActionName?: string;
  onActionClick: any;
  onSecondActionClick?: any;
}

const EmptyStateForErrors = ({
  imagePath,
  alt,
  text,
  helperText,
  helperSubText,
  actionName,
  secondActionName,
  onActionClick,
  onSecondActionClick,
}: IEmptyStateForErrors) => {
  return (
    <Grid
      container
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      position="absolute"
      top="50%"
      left="50%"
      sx={{ transform: 'translate(-50%,-50%)' }}
    >
      <Grid item>
        <IconFromSvg path={imagePath} alt={alt ? alt : ''} />
      </Grid>
      <Grid item paddingBottom={theme.spacing(3)}>
        <Typography variant="h1" sx={{ color: theme.palette.text.primary }}>
          {text}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" sx={{ color: theme.palette.text.disabled }}>
          {helperText}
        </Typography>
      </Grid>

      {helperSubText && (
        <Grid item>
          <Typography
            variant="body1"
            sx={{ color: theme.palette.text.disabled }}
          >
            {helperSubText}
          </Typography>
        </Grid>
      )}

      <Box
        flexDirection="row"
        alignItems="center"
        paddingTop={theme.spacing(6)}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={onActionClick}
          data-testid={'action-button'}
          sx={{ height: theme.spacing(8.25) }}
        >
          {actionName}
        </Button>
        {secondActionName && (
          <Button
            variant="outlined"
            color="primary"
            onClick={onSecondActionClick}
            data-testid={'action-button'}
            sx={{ height: theme.spacing(8.25), marginLeft: theme.spacing(3) }}
          >
            {secondActionName}
          </Button>
        )}
      </Box>
    </Grid>
  );
};

export default EmptyStateForErrors;
