import axios from '../axios';
import Config from '../../../config/config';
import {
  filterAppsWithReadPermission,
  getAuthToken,
} from '../../core-utils/Helper/helper';
import {
  MOCK_APPS_ACCESSED_WIDGET,
  MOCK_USERS_VIOLATIONS_WIDGET,
} from '../../core-utils/test-constants';
import { MOCK_POLICIES_UNDER_USERGROUPS_TAB_ITEM } from '../../core-utils/test-constants';
import { IAddPoliciesToGroupOrUserRequestBody } from '../policy-service/PolicyService';

export interface IListUserGroupsResponse {
  identifier: string;
  attributes: {
    disabled: boolean;
    description?: string;
    'sonet-source'?: string | null;
  };
}

// IAssignUserToUserGroupReqObj example:
// "op": "add",
// "path": "/",
// "value": "{{user_group}}"

export interface IAddOrRemoveUserFromUserGroupReqObj {
  op: string;
  path: string;
  value: string;
}

export interface IListUserGroupsPoliciesResponse {
  id: string;
  policyName: string;
  createdBy: string;
  applications: string[];
  lastPolicyHitTime: string;
  status: 'active' | 'inactive';
}

const isDashboardAPIValid = () => {
  return Config.DASHBOARD_API_URL ? true : false;
};

const UserGroupService = {
  addNewUserGroup: async (userGroupPayload: any) => {
    try {
      const userGroupResponse = await axios.post(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/userGroups`,
        userGroupPayload,
      );
      return userGroupResponse.data;
    } catch (error) {
      //TODO Exception Handling
    }
  },
  addUserToUserGroup: async (userGroupPayload: any) => {
    try {
      const response = await axios.post(
        `${Config.BACKEND_BASE_URL}userGroups`,
        userGroupPayload,
      );

      return response.data;
    } catch (error) {
      //TODO Exception Handling
    }
  },
  getUserGroupById: async (groupId: string | number) => {
    try {
      const userGroupResponse = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/userGroups/${encodeURIComponent(groupId)}`,
      );
      return userGroupResponse.data;
    } catch (error) {
      //TODO :Exception Handling
    }
  },

  updateUserGroup: async (id: string | number, userGroupPayload: any) => {
    try {
      //TODO :  Might be updated when we get the exact function and request body
      await axios.put(
        `${Config.BACKEND_BASE_URL}/api/session/data/postgresql/userGroups/${encodeURIComponent(id)}`,
        userGroupPayload,
      );
    } catch (error) {
      //TODO : Exception Handling
    }
  },
  deleteUserGroup: async (groupId: string | number) => {
    try {
      await axios.delete(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/userGroups/${encodeURIComponent(groupId)}`,
      );
    } catch (error) {
      //TODO : Exception Handling
    }
  },

  getAllUserGroups: async () => {
    try {
      const response = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/usergroups`,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },
  getUserGroupsForUser: async (username: string) => {
    try {
      const response = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/users/${encodeURIComponent(username)}/userGroups`,
      );
      return response.data;
    } catch (error) {
      return error;
    }
  },
  listUserGroups: async () => {
    try {
      const listUserGroupsResponse = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/userGroups`,
      );
      return listUserGroupsResponse.data;
    } catch (error) {
      return error;
    }
  },
  getUsersInUserGroup: async (groupname: string) => {
    try {
      const userList = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/userGroups/${encodeURIComponent(groupname)}/memberUsers`,
      );
      return userList.data;
    } catch (error) {
      return error;
    }
  },
  getAppsOfUserGroup: async (groupId: number | string) => {
    try {
      const appsByUserGroupId = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/userGroups/${encodeURIComponent(groupId?.toString())}/permissions`,
      );
      return filterAppsWithReadPermission(appsByUserGroupId.data);
    } catch (error) {
      return error;
    }
  },
  addAppsToUserGroup: async (groupId: string, requestBody: any) => {
    try {
      await axios.patch(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/userGroups/${encodeURIComponent(groupId)}/permissions`,
        requestBody,
      );
    } catch (error) { }
  },
  removeAppsFromUserGroup: async (groupId: string, requestBody: any) => {
    try {
      await axios.patch(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/userGroups/${encodeURIComponent(groupId)}/permissions`,
        requestBody,
      );
    } catch (error) { }
  },
  assignUserToUserGroup: async (
    username: string,
    requestBody: IAddOrRemoveUserFromUserGroupReqObj[],
  ) => {
    try {
      await axios.patch(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/users/${encodeURIComponent(username)}/userGroups`,
        requestBody,
      );
    } catch (error) {
      console.log(error);
    }
  },

  addPolicyToUserGroup: async (policyId: string | number, requestBody: any) => {
    try {
      await axios.patch(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/policyDef/${policyId}/memberUserGroups`,
        requestBody,
      );
    } catch (error) {
      console.error(error);
    }
  },
  removePolicyFromUserGroup: async (
    policyId: string | number,
    requestBody: any,
  ) => {
    try {
      await axios.patch(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/policyDef/${policyId}/memberUserGroups`,
        requestBody,
      );
    } catch (error) {
      console.error(error);
    }
  },
  removeUserFromUserGroup: async (
    username: string,
    requestBody: IAddOrRemoveUserFromUserGroupReqObj[],
  ) => {
    try {
      await axios.patch(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/users/${encodeURIComponent(username)}/userGroups`,
        requestBody,
      );
    } catch (error) {
      console.log(error);
    }
  },

  getUsersViolationsInUserGroup: async (
    groupId: string,
    startDate: String,
    endDate: String,
  ) => {
    //Remove this when we get apis
    // return MOCK_USERS_VIOLATIONS_WIDGET.slice(0, 5);
    try {
      if (!isDashboardAPIValid()) {
        return MOCK_USERS_VIOLATIONS_WIDGET.slice(0, 5);
      }
      const usersViolations = await axios.get(
        `${Config.DASHBOARD_API_URL}usergroups/${encodeURIComponent(groupId)}/UsersPolicyViolations`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return usersViolations.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  },
  getAppsAccessedInUserGroup: async (
    groupId: string,
    startDate: String,
    endDate: String,
  ) => {
    try {
      if (!isDashboardAPIValid()) {
        return MOCK_APPS_ACCESSED_WIDGET;
      }
      const appsAccessed = await axios.get(
        `${Config.DASHBOARD_API_URL}usergroups/${encodeURIComponent(groupId)}/AppsAccessedCount`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return appsAccessed.data.slice(0, 5);
    } catch (error) {
      console.error(error);
      return [];
    }
  },

  getActiveUsersCountInUserGroup: async (
    groupId: string,
    startDate: String,
    endDate: String,
  ) => {
    try {
      const activeUsersCount = await axios.get(
        `${Config.DASHBOARD_API_URL}usergroups/${encodeURIComponent(groupId)}/activeUsersCount`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return activeUsersCount.data;
    } catch (error) {
      console.error(error);
      return 0;
    }
  },

  getAverageDailyUsersCount: async (
    groupId: string,
    startDate: String,
    endDate: String,
  ) => {
    try {
      const avgDailyUsersCount = await axios.get(
        `${Config.DASHBOARD_API_URL}usergroups/${encodeURIComponent(groupId)}/AverageDailyUsers`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return avgDailyUsersCount.data;
    } catch (error) {
      console.error(error);
      return 0;
    }
  },

  getAverageDailyAppsCount: async (
    groupId: string,
    startDate: String,
    endDate: String,
  ) => {
    try {
      if (!isDashboardAPIValid()) {
        return 1;
      }
      const avgDailyAppsCount = await axios.get(
        `${Config.DASHBOARD_API_URL}usergroups/${encodeURIComponent(groupId)}/AverageDailyApps`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return avgDailyAppsCount.data;
    } catch (error) {
      console.error(error);
      return 0;
    }
  },

  getPolicyViolationsCount: async (
    groupId: string,
    startDate: String,
    endDate: String,
  ) => {
    try {
      const policyViolationsCount = await axios.get(
        `${Config.DASHBOARD_API_URL}usergroups/${encodeURIComponent(groupId)}/PolicyViolations`,
        { params: { startDate: startDate, endDate: endDate } },
      );
      return policyViolationsCount.data;
    } catch (error) {
      console.error(error);
      return 0;
    }
  },
  getPoliciesOfUserGroup: async (groupId: string) => {
    // TODO:
    // return ['1', '2', '5', '4'];
    try {
      const allPolicies = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/userGroups/${encodeURIComponent(groupId)}/policies`,
      );
      return allPolicies.data;
    } catch (error) {
      return error;
    }
  },
};

export default UserGroupService;
