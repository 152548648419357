import { GLOBAL_LABELS } from '../constants';

const appModuleMessages = {
  DELETE_APPLICATION_HEADING: 'Delete Application',
  ADD_APPLICATION: {
    HEADING: 'Add Application',
    ASSIGN_APPLICATION: 'Assign Application',
    SUB_HEADING: 'These settings will only affect this particular integration.',
    URL_PARAMETERS: 'URL Parameters',
    TEXT_FIELD_LABELS: {
      APP_NAME: 'Name',
      APPLICATION_DOMAIN_PLACEHOLDER: 'URL to launch the app with',
      APPLICATION_LAUNCH_PATH: 'Application Launch Path',
      APPLICATION_LOGO: 'Application Logo',
      APPLICATION_TYPE: 'Protocol',
      APPLICATION_URLS_PLACEHOLDER: 'URLs that belong to the applications',
      CUSTOM_DOMAIN_HELPER: 'Copy & Paste the custom prefix for your URL here. Format : (https://{domain}github.com/).',
      CUSTOM_DOMAIN_PLACEHOLDER: '{domain}github.com/',
      CUSTOM_DOMAIN: 'Application URL(s)',
      DESCRIPTION: 'Description',
      HOSTNAME_PORT: 'Hostname',
      HTTP: 'http://',
      HTTPS: 'https://',
      IDP_APP_URL: 'Launch Application URL',
      MAX_FILE_SIZE: 'Max File Size: 50KB',
      ALLOWED_FILE_TYPES_TOOLTIP: 'Only images of type png, jpg, svg, bmp, webp and gif are allowed.',
      REQUIRED: GLOBAL_LABELS.REQUIRED,
      UNSUPPORTED_FILE: 'Unsupported file type: {fileType}',
      
    },
    PLACEHOLDER: {
      APP_NAME: 'Enter name here',
      PROTOCOL: 'Select a protocol',
      DESCRIPTION: 'Enter description here',
    },
    BUTTON_LABELS: {
      SAVE: GLOBAL_LABELS.SAVE,
      CANCEL: GLOBAL_LABELS.CANCEL,
      ADD_URL: '+ Add URL',
      REQUEST_ACCESS: 'Request Access',
      UPLOAD_FILE: 'Upload File',
    },
    ERROR_MESSAGES: {
      INVALID_URL:
        'URL Invalid. Please follow the URL parameters under setup guide',
      DENIED_ACCESS_HEADING: 'Access Denied',
      DENIED_LOCATION_ACCESS:
        'Access denied due to the location restrictions,Git Hub is not accessable from your place',
      UPLOAD_FILE_LENGTH_EXCEED: 'File size must not exceed {maxFileSize}',
    },
  },
  APPS_DASHBOARD: {
    HEADING: 'Applications',
    ADD_APPLICATION_LABEL: 'Add Application',
    EMPTY_STATE: {
      HEADING: "You don't have any applications yet.",
      SUB_HEADING:
        'Adding an application allows you to control access through Sonet',
    },
    APPS_SEARCH: 'Search for apps',
  },
  SELECT_APPLICATION_CARD: {
    SELECT_CARD_HOVER_MESSAGE:
      'Provide access to {AppName} by defining a policy around it.',
  },
  APPLICATIONS_ACCESS_MESSAGE_UNDER_USER:
    'Applications that this user has access to',
  APPLICATIONS_EMPTY_STATE_UNDER_USER:
    'There are no applications assigned to this user yet',
  BACK_TO_APPLICATIONS: 'Back to Applications',
  EDIT_APPLICATION_DETAILS: {
    EDIT_APP_NAME: 'Edit App Name',
    EDIT_DESCRIPTION: 'Edit Description',
    APP_NAME_LABEL: 'Name',
    EDIT_CUSTOM_DOMAIN: 'Edit Application URL',
    CUSTOM_DOMAIN_LABEL: 'Application URL(s)',
    EDIT_APPLICATION_TYPE: 'Change Application Type',
    APP_TYPE_LABEL: 'Protocol',
    HOSTNAME_LABEL: 'Hostname',
    DOMAIN_URLS: 'Domain Url(s)',
    EDIT_DOMAIN_URLS: 'Edit Domain Url(s)',
    EDIT_APP_ICON: 'Edit Application Logo',
    APP_ICON_LABEL: 'Application Logo',
    DESCRIPTION_LABEL: 'Description',
  },

  LAUNCHER: {
    TITLE: '{appName} Credentials',
    SUB_TITLE: 'Specify the cedentials for accessing the {serverType} server. Credentials can be saved for autofilling in the future.',
    LAUNCH: 'launch',
    REPLACE_PRIVATE_KEY: 'Replace Private Key',
    SAVE: 'save',
    USERNAME: 'Username',
    PASSWORD: 'Password',
    PRIVATE_KEY: 'Private Key',
    PRIVATE_KEY_CONTENT: 'Private Key File Content',
    PLACEHOLDER: '{for} for server',
    TOGGLE_CREDENTIAL_MODE: 'use {credentialMode} instead?',
    ERROR: {
      USERNAME_EMPTY: 'Username is empty.',
      PASSWORD_EMPTY: 'Password is empty',
      PRIVATE_KEY_EMPTY: 'Private key is empty',
      ONE_OF_PASSWORD_OR_PRIVATE_KEY_EMPTY: 'Password or a private key is required.',
      FAILED_TO_SAVE: 'Unable to save credentials',
      FAILED_TO_DELETE: 'Unable to delete credentials',
      ONLY_RSA_OR_OPEN_SSH_KEYS: 'Only PEM or OpenSSH formatted RSA private keys are supported.',
      DEFAULT_ERROR_MESSAGE: 'Failed to save credentials'
    },
    TOOLTIP: {
      SAVE: 'Saves the credentials on the server for future autofills',
      FORGET: 'Deletes the saved credentials on the system',
      CONNECT: 'Connects to the application',
      EDIT: 'Edit the autofilled credentials'
    },
    SUCCESS: {
      SAVED_CREDENTIAL: 'Saved.',
      DELETED_CREDENTIAL: 'Deleted.'
    },
    PEM_FILE_HELP_TEXT: 'Only certificate files of max file size: 4KB',
    CHANGE_PEM_FILE_TYPE: 'Please retry with a different PEM or OpenSSH formatted RSA private key.',
    DOMAIN: 'Domain',
    OPTIONAL: 'Optional'
  },
  APP_ALREADY_EXISTS: 'App already exists',
  APPLICATIONS_ACCESS_MESSAGE_UNDER_USERGROUP:
    'Applications that this user group has access to',
  APPLICATIONS_EMPTY_STATE_UNDER_USERGROUP:
    'There are no applications assigned to this user group yet',
  SELECT_APPLICATION_FOR_NAME: 'Select an application for {name}',
  APP_ALREADY_EXISTS_FOR_USER:
    'This application has already been assigned to this user',
  APP_ALREADY_EXISTS_FOR_USERGROUP:
    'This application has already been assigned to this user group',
  APP_ACCESS_HEADING: 'Application Access',
  CUSTOM_REMOVE_UNDER_TABS_FOR_APPS:
    'This action will remove {removeItem} access for {removeFrom}',
};
export default appModuleMessages;
