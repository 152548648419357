import { Grid, useTheme } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import AppService, {
  IAddOrRemoveAppsToUserRequestBody,
} from '../../../api-service/app-service/AppService';
import UserGroupService from '../../../api-service/user-group-service/UserGroupService';
import UserService from '../../../api-service/user-service/UserService';
import { IDropDownItem } from '../../../core-utils/constants';
import {
  createOperationRequest,
  getNamesFromObject,
} from '../../../core-utils/Helper/helper';
import {
  IUser,
  IUserGroup,
  OperationTypes,
} from '../../../core-utils/Interfaces/entityInterfaces';
import messages from '../../../core-utils/messages';
import userModuleMessages from '../../../core-utils/messages/user';
import LoadingAnimation from '../../atoms/LoadingAnimation';
import { IUserTableRow } from '../../molecules/UserTableRow';
import { getAppById } from '../../pages/AppDetailsPage/index.hook';
import CustomDialog from '../Dialog';
import MultipleSelectChip from '../MultipleSelectChip';
import { useMultipleSelections } from '../MultipleSelectChip/index.hook';

export interface IAddUserProps {
  isOpen: boolean;
  onClose: () => void;
  usergroupOrAppId?: any;
  usersAssignedToGroup?: IUserTableRow[];
  getUsers?: any;
  context?: 'usergroup' | 'app';
}

const getFormattedUserResponse = (allUsers: any[], usersOfUserGroup: any) => {
  const allUsersKeys: any[] = Object.values(allUsers).map((item) => {
    return { id: item?.username, name: item?.attributes?.['guac-full-name'] };
  });

  const currentUsersKeys = usersOfUserGroup.map((user: any) => {
    return { id: user?.username, name: user?.name };
  });
  const selectedKeys = currentUsersKeys.map((item: any) => item?.id);
  const unselectedUsersList = allUsersKeys.filter(
    (item) => selectedKeys.indexOf(item?.id) === -1,
  );
  return unselectedUsersList.map((key) => {
    return {
      id: key?.id,
      name: key?.name,
    };
  });
};

const AddUser = (props: IAddUserProps) => {
  const [dropDownUsersList, setDropDownUsersList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const theme = useTheme();
  const { isOpen, onClose, getUsers, usersAssignedToGroup, context, usergroupOrAppId } = props;
  const [saveDisabled, setSaveDisabled] = useState(true);

  const getAllUsers = async () => {
    const allUsers: any = await UserService.listUsers();
    const formattedUserResponse = getFormattedUserResponse(
      allUsers,
      usersAssignedToGroup,
    );
    return formattedUserResponse;
  };

  const handleSaveState = () => {
    if (currentSelectedItems.length > 0) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
    return true;
  };

  const handleClose = () => {
    onClose();
    resetSelectedItems();
  };

  const {
    currentSelectedItems,
    handleChange,
    removeItem,
    sortBySelectedItems,
    resetSelectedItems,
  } = useMultipleSelections(dropDownUsersList, [], handleSaveState);

  React.useEffect(() => {
    getAllUsers();
  }, []);

  const handleSaveFromContextUserGroup = async () => {
    const currentlySelectedUsers = currentSelectedItems.map((item) => item?.id);
    props.usergroupOrAppId &&
      Promise.all(
        currentlySelectedUsers.map(async (user) => {
          await UserGroupService.assignUserToUserGroup(user, [
            {
              op: 'add',
              path: '/',
              value: props.usergroupOrAppId ? props.usergroupOrAppId : '',
            },
          ]);
        }),
      )
        .catch((error) => {
          console.warn(error);
        })
        .finally(() => {
          getUsers && getUsers();
          handleClose();
        });
  };

  const handleSaveFromContextApp = async () => {
    const currentlySelectedUsers = currentSelectedItems.map((item) => item?.id);
    const requestBody: IAddOrRemoveAppsToUserRequestBody[] = [
      createOperationRequest(
        OperationTypes.add,
        'READ',
        `/connectionPermissions/${props.usergroupOrAppId}`,
      ),
    ];
    try {
      const promises = currentlySelectedUsers.map(async (userId) => {
        await AppService.addUsersToApp(userId, requestBody);
      });
      await Promise.all(promises);
      getAppById(usergroupOrAppId).then((response) => {
        getUsers(response.userMembers,response.name,response.userGroupMembers)
      })
    } catch (error) {
      console.warn(error);
    } finally {
      handleClose();
    }
  };

  const handleSave = async () => {
    context === 'app'
      ? handleSaveFromContextApp()
      : handleSaveFromContextUserGroup();
  };

  React.useEffect(() => {
    setLoading(true);
    getAllUsers()
      .then((response) => setDropDownUsersList(response))
      .finally(() => setLoading(false));
  }, [usersAssignedToGroup]);

  return (
    <>
      {loading && <LoadingAnimation />}
      <CustomDialog
        heading={messages.USERS_DASHBOARD.ADD_USER}
        subHeading={
          context === 'app'
            ? messages.USERS_DASHBOARD.SUB_HEADING_APP
            : messages.USERS_DASHBOARD.SUBHEADING
        }
        open={isOpen}
        onClose={handleClose}
        isSaveDisabled={saveDisabled}
        buttonLabelOne={messages.CREATE_USER.BUTTON_LABELS.CANCEL}
        buttonLabelTwo={messages.CREATE_USER.BUTTON_LABELS.SAVE}
        handleSaveClick={handleSave}
        sx={{
          maxHeight: theme.spacing(76),
          margin: 'auto',
          position: 'absolute',
          top: 0,
          bottom: 0,
          overflow: 'hidden',
        }}
        children={
          <Grid item>
            <MultipleSelectChip
              dropDownList={dropDownUsersList}
              checkedItems={currentSelectedItems}
              handleCheckedItems={handleChange}
              onChipDelete={removeItem}
              textFieldLabel={userModuleMessages.SELECT_USERS}
              sortSelected={sortBySelectedItems}
              category="users"
            />
          </Grid>
        }
      />
    </>
  );
};

export default AddUser;
