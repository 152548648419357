import { Grid, Typography, useTheme } from '@mui/material';
import { EXTRA_COLORS } from '../../../core-utils/theme';
import DragAndDropIcon from '../../../../public/assets/drag-drop.svg';
import DownArrow from '../../../../public/assets/chevronDown-inactive.svg';
import UpArrow from '../../../../public/assets/chevronDown-inactive.svg';
import React from 'react';
import IconFromSvg from '../../atoms/IconFromSvg';

export interface ISelectPolicyBlock {
  selectedItemText: string;
  requiredIcon: string;
}
const SelectPolicyBlock = (props: ISelectPolicyBlock) => {
  const theme = useTheme();
  return (
    <Grid
      sx={{
        width: theme.spacing(170),
        height: theme.spacing(12),
        cursor: 'pointer',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid' + EXTRA_COLORS.lightGrayishBlue,
        borderRadius: theme.spacing(1),
        boxSizing: 'border-box',
        boxShadow: theme.shadows[12],
        padding: theme.spacing(3),
      }}
      container
      alignItems="center"
      columnSpacing={2}
    >
      <Grid item>
        <IconFromSvg path={DragAndDropIcon} alt="icon" />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2">{props.selectedItemText}</Typography>
      </Grid>
      <Grid item>
        <IconFromSvg
          height={16}
          width={17}
          path={props.requiredIcon}
          alt="arrow-icon"
        />
      </Grid>
    </Grid>
  );
};
export default SelectPolicyBlock;
