import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Chip as MUIChip, ChipProps } from '@mui/material';
import { CHIP_MAX_WIDTH, COMMON_SHADES } from '../../../core-utils/constants';
import ClearIcon from '@mui/icons-material/Clear';
import { EXTRA_COLORS } from '../../../core-utils/theme';

export interface IChipProps extends ChipProps {
  backgroundColor?: string;
  textColor?: string;
  shade?: 'blue' | 'green' | 'orange' | 'red' | 'violet' | 'grey';
  hasBorder?: boolean;
  chipMaxWidth?: number;
}

const StyledChip = styled(MUIChip)<IChipProps>(({ theme, ...props }) => ({
  borderRadius: 14,
  color:
    props.shade && !props.onDelete
      ? COMMON_SHADES[props.shade].main
      : props.onDelete
        ? theme.palette.common.black
        : props.textColor,
  backgroundColor: props.shade
    ? COMMON_SHADES[props.shade].light
    : props.backgroundColor,
  ...theme.typography.body2,
  textTransform: 'none',
  '.MuiChip-label': {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  border: props.hasBorder ? `1px solid ${EXTRA_COLORS.blue_100}` : '',
  height: 28,
  '.MuiChip-deleteIcon': {
    color: EXTRA_COLORS.grey,
    height: 16,
    opacity: 0.1
  },
  '.MuiChip-deleteIcon:hover': {
    opacity: 1
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: props.chipMaxWidth
    ? theme.spacing(props.chipMaxWidth)
    : CHIP_MAX_WIDTH,
  paddingRight: 8,
  paddingLeft: 8,
  fontSize: '0.72em',
  letterSpacing: '1px'
}));

const Chip = ({ ...rest }: IChipProps) => {
  return <StyledChip className='shadow-sm hover:shadow-inner hover:cursor-pointer' {...rest} deleteIcon={<ClearIcon />} />;
};

export default Chip;
