import React, { useEffect, useState } from 'react';
import UserGroupService, {
  IListUserGroupsPoliciesResponse,
  IListUserGroupsResponse,
} from '../../../api-service/user-group-service/UserGroupService';
import UserService, {
  IListUserResponse,
} from '../../../api-service/user-service/UserService';
import {
  ADMIN_SIDE_NAVIGATION_ITEMS,
  APPLICATION_DETAILS_TAB,
  IDetailPageProps,
  IKeyValueObject,
  IUserGroupsAndAppsTableRow,
  USER_DETAILS_TAB,
} from '../../../core-utils/constants';
import { IUserTableRow } from '../../molecules/UserTableRow';
import MainHeader from '../../organisms/MainHeader';
import SideNavigationDrawer, {
  sideNavLocalStorageKeys,
} from '../../organisms/SideNavigationDrawer';
import UserGroupDetailDashboard from '../../organisms/UserGroupDetailDashboard';
import HomeTemplate from '../../templates/HomeTemplate';
import {
  constructListUsers,
  getPolicyTableRowsForUserGroup,
  getUserGroupById,
} from './index.hook';
import { constructListApps, getUserById } from '../UserDetailsPage/index.hook';
import {
  getApplicationType,
  getCustomDomainName,
  getOrganization,
  getSideNavEndItems,
  getSideNavItems,
  getUserFullNameOrUsername,
} from '../../../core-utils/Helper/helper';
import LoadingAnimation from '../../atoms/LoadingAnimation';
import InnerDetailSummaryModal from '../../organisms/InnerDetailSummaryModal';
import { useShowOrHideElement } from '../../../core-utils/Hooks/index.hook';
import { getAppById } from '../AppDetailsPage/index.hook';
import AppService from '../../../api-service/app-service/AppService';
import { IPoliciesTableRowProps } from '../../molecules/PoliciesTableRow';
import PolicyService, {
  IListPolicyResponse,
} from '../../../api-service/policy-service/PolicyService';
import messages from '../../../core-utils/messages';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../core-utils/routes';

const UserGroupDetailPage = ({ match }: IDetailPageProps) => {
  const [
    userGroupResponse,
    setUserGroupResponse,
  ] = useState<IListUserGroupsResponse>();
  const [appDetails, setAppDetails] = useState<IUserGroupsAndAppsTableRow[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const { open, hideElement, showElement } = useShowOrHideElement();
  const [popUpData, setPopUpData] = useState<IKeyValueObject[]>([]);
  const [popUpRedirection, setPopUpRedirection] = useState<{
    tabItemType: 'user' | 'app';
    redirectionId: string;
  }>();

  var history = useHistory();
  const [policies, setPolicies] = useState<IPoliciesTableRowProps[]>([]);

  const [userGroupDetails, setUserGroupDetails] = useState({
    name: '',
    numberOfUsers: '',
    createdBy: '',
    description: '',
    source: '',
    createdOn: '',
  });

  const [users, setUsers] = useState<IUserTableRow[]>([]);

  const groupId = unescape(match.params.id);

  const popUpUser = async (username: string) => {
    const userDetails = await getUserById(username);
    const {
      NAME,
      EMAIL,
      JOB_TITLE,
      PASSWORD,
      USER_STATUS,
      SOURCE,
    } = USER_DETAILS_TAB();
    const requiredDetails = [
      { key: NAME.label, value: userDetails.name },
      { key: EMAIL.label, value: userDetails.email },
      { key: JOB_TITLE.label, value: userDetails.jobTitle },
      { key: PASSWORD.label, value: PASSWORD.value },
      // { key: USER_STATUS.label, value: userDetails.userStatus },
      { key: SOURCE.label, value: userDetails.source },
    ];
    setPopUpData(requiredDetails);
    setPopUpRedirection({ tabItemType: 'user', redirectionId: username });
    showElement();
  };

  const popUpApp = async (appId: string) => {
    const appDetails = await getAppById(appId);
    const appParams = await AppService.getAppParameters(appId);
    const {
      APP_NAME,
      CREATED_ON,
      CREATED_BY,
      APPLICATION_TYPE,
      CUSTOM_DOMAIN,
      IDP_APP_URL,
    } = APPLICATION_DETAILS_TAB(appDetails.applicationType);
    const requiredAppDetails = [
      { key: APP_NAME.label, value: appDetails.name },
      {
        key: CUSTOM_DOMAIN.label,
        value: getCustomDomainName(appParams),
      },
      { key: CREATED_ON.label, value: appDetails.createdOn },
      { key: CREATED_BY.label, value: appDetails.createdBy },
      {
        key: APPLICATION_TYPE.label,
        value: getApplicationType(appParams, appDetails.applicationType)
      },
    ];

    if(appDetails.applicationType === 'browser-app') {
      requiredAppDetails.splice(2, 0, { key: IDP_APP_URL.label, value: appParams?.domainurls })
    }
    setPopUpData(requiredAppDetails);
    setPopUpRedirection({ tabItemType: 'app', redirectionId: appId });
    showElement();
  };

  const getUsersOfUserGroup = async () => {
    const userNameslist: any = await UserGroupService.getUsersInUserGroup(
      groupId,
    );
    const userslist: IListUserResponse[] = [];
    const promises = userNameslist.map(async (username: string) => {
      const user: IListUserResponse = await UserService.getUserById(username);
      userslist.push(user);
    });
    await Promise.all(promises);
    const usersOfUserGroup = await constructListUsers(
      userslist,
      groupId,
      getUsersOfUserGroup,
      popUpUser,
      'usergroup',
    );
    setUsers(usersOfUserGroup);
  };

  const getAppsByUserGroupId = async () => {
    const appsByUserGroupId: any = await UserGroupService.getAppsOfUserGroup(
      groupId,
    );
    const appsOfUserGroup: IUserGroupsAndAppsTableRow[] = await constructListApps(
      appsByUserGroupId,
      groupId,
      'usergroup',
      getAppsByUserGroupId,
      groupId,
      popUpApp,
    );
    setAppDetails(appsOfUserGroup);
  };

  const getPoliciesByUserGroupId = async () => {
    const userGroupDetails = await getUserGroupById(groupId);
    const listPolicies: string[] = await UserGroupService.getPoliciesOfUserGroup(
      groupId,
    );
    let requiredPolicies: IListPolicyResponse[] = [];
    const promises = listPolicies.map(async (policyId: string) => {
      const policy = await PolicyService.getPolicyById(policyId);
      if (policy !== messages.ERROR_STATUS) {
        requiredPolicies.push({
          id: policyId,
          ...policy,
        });
      }
    });
    await Promise.all(promises);
    const policyTableRows = getPolicyTableRowsForUserGroup(
      requiredPolicies,
      userGroupDetails.name,
      getPoliciesByUserGroupId,
    );
    const updatedPolicyTableRows: IPoliciesTableRowProps[] = [];
    policyTableRows.forEach((tableRow: any) => {
      updatedPolicyTableRows.push({
        ...tableRow,
        handleTableRowClick: () => {
          history.push(ROUTES.EDIT_POLICY.replace(':id', tableRow.id));
        },
      });
    });
    setPolicies(updatedPolicyTableRows);
  };

  const handleRedirection = () => {
    if (popUpRedirection?.tabItemType === 'user') {
      location.pathname = `/users/${popUpRedirection.redirectionId}`;
      // localStorage.setItem(sideNavLocalStorageKeys.childItem, '2');
    } else {
      location.pathname = `/apps/${popUpRedirection?.redirectionId}`;
      // localStorage.setItem(
      //   sideNavLocalStorageKeys.sideNav,
      //   ADMIN_SIDE_NAVIGATION_ITEMS[5].id.toString(),
      // );
      // localStorage.setItem(sideNavLocalStorageKeys.childItem, '-1');
    }
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getUsersOfUserGroup(),
      getAppsByUserGroupId(),
      getUserGroupById(groupId).then((response) =>
        setUserGroupDetails(response),
      ),
      getPoliciesByUserGroupId(),
    ])
      .then()
      .finally(() => setLoading(false));
  }, []);

  const sideNavItems = getSideNavItems();
  const sideNavEndItems = getSideNavEndItems();

  const getUserGroupDetails = async () => {
    const detailsOfUserGroup = await getUserGroupById(groupId);
    setUserGroupDetails(detailsOfUserGroup);
  }
  
  return (
    <HomeTemplate
      leftNavComponent={
        <SideNavigationDrawer
          sideNavItems={sideNavItems}
          sideNavEndItems={sideNavEndItems}
        />
      }
      header={
        <MainHeader
          username={getUserFullNameOrUsername()}
          isImage={false}
          usercompany={getOrganization()}
        />
      }
      content={
        loading ? (
          <LoadingAnimation />
        ) : (
          <>
            <UserGroupDetailDashboard
              actualUserGroupResponse={userGroupResponse}
              userGroupDetails={userGroupDetails}
              users={users}
              appDetails={appDetails}
              policies={policies}
              getUsersOfUserGroup={getUsersOfUserGroup}
              updateAppsOfUserGroup={getAppsByUserGroupId}
              updatePoliciesOfUserGroup={getPoliciesByUserGroupId}
              getUserGroupDetails={getUserGroupDetails}
              userGroupName={groupId}
            />
            {popUpData.length > 0 && (
              <InnerDetailSummaryModal
                open={open}
                onClose={hideElement}
                nameHeading={popUpData[0].value}
                detailItemValues={popUpData}
                clickViewDetails={handleRedirection}
              />
            )}
          </>
        )
      }
    />
  );
};

export default UserGroupDetailPage;
