const loginMessages = {
  LOGIN_ICON_HEADING: 'Sonet.io',
  LOGIN_SUBHEADING: 'Welcome back',
  LOGIN_SUGGESTION_TEXT: {
    USERNAME: 'Enter your username to begin',
    PASSWORD: 'Enter your password to login',
    MFA: 'Enter MFA code to verify'
  },
  LOGIN_TEXT_FIELD_LABEL: {
    USERNAME: 'Username',
    PASSWORD: 'Password',
  },
  LOGIN_BUTTON_TEXTS: {
    LOGIN: 'Login',
    NEXT: 'Next',
    VERIFY: 'Verify',
    FORGET_PASSWORD: 'Forgot Password?',
  },
  INVALID_LOGIN_CREDENTIALS: 'Invalid username or password',
  CONFIRM_LOGOUT_HEADING: 'Confirm Logout',
  CONFIRM_LOGOUT_MESSAGE:
    'Are you sure you want to logout? Your session will end and you will have to login again.',
  LOGOUT_BUTTON_TEXT: 'Logout',
};

export default loginMessages;
