import * as React from 'react';
import { Box, Table as MUITable, TablePagination, Typography } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHeader, { ITableHeader } from '../../molecules/TableHeader';
import theme, { customStyles, EXTRA_COLORS } from '../../../core-utils/theme';
import { usePagination } from './index.hook';

export interface ITableProps extends ITableHeader {
  tableBody?: React.ReactNode;
  customTableRow?: any;
  tableData?: any[];
  rowsPerPage?: number | null;
  inUserActivityTable?: boolean;
  getPage?: (page: number) => void;
  pageNumber?: number;
  target?: 'sessions' | 'others';
  fixTableLayout?: boolean;
  isPaginationDisplay?: boolean;
  tableFooterText?: string;
  isFirstColumnBlank?: boolean;
  onPageChange?: (page: number) => void;
}

const Table = ({ isPaginationDisplay = true, isFirstColumnBlank = true, ...rest }: ITableProps) => {
  const { page, handleChangePage, tableRef } = usePagination();

  const rowsPerPage = rest.rowsPerPage ? rest.rowsPerPage : 10;

  React.useEffect(() => {
    const newPage = rest.pageNumber ? rest.pageNumber : 0;
    handleChangePage(null, newPage);
  }, [rest.tableData]);

  const CustomTableRow: React.FunctionComponent = rest.customTableRow
    ? rest.customTableRow
    : null;

  React.useEffect(() => {
    rest.getPage && rest.getPage(page);
    rest.onPageChange && rest.onPageChange(page);
  }, [page]);

  return (
    <React.Fragment>
      <TableContainer
        component={Paper}
        sx={{
          minWidth: theme.spacing(175),
          maxHeight: '100%',
          backgroundColor: 'unset',
          ...customStyles.scrollBar,
        }}
        ref={tableRef}
      >
        <MUITable
          stickyHeader
          aria-label="customized table"
          data-testid="users-table"
          sx={{ tableLayout: rest.fixTableLayout ? 'fixed' : 'unset' }}
        >
          <TableHead
            sx={{
              border: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <TableRow>
              <TableHeader
                headers={rest.headers}
                inUserActivityTable={rest.inUserActivityTable}
                isFirstColumnBlank={isFirstColumnBlank}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {rest.tableBody
              ? rest.tableBody
              : rest.tableData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((data: any, index: number) => {
                    return <CustomTableRow {...data} key={index} />;
                  })}
          </TableBody>
        </MUITable>
      </TableContainer>
      {rest.tableData && isPaginationDisplay && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <TablePagination
            data-testid="pagination"
            rowsPerPageOptions={[rowsPerPage]}
            component="div"
            count={rest.tableData.length}
            labelDisplayedRows={({ from, to, count }) => {
              return rest.target === 'sessions'
                ? to === count
                  ? `${from}–${to}`
                  : `${from}–${to} of many`
                : `${from}–${to} of ${count}`;
            }}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            sx={{
              '&:MuiTableCell-root': {
                borderBottom: `1px solid ${EXTRA_COLORS.blue_100}`,
              },
            }}
          />
        </Box>
      )}
      {rest?.tableFooterText ? <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: theme.spacing(2)
          }}>
        <Typography variant="caption" color={theme.palette.text.disabled}>
          {rest?.tableFooterText}
          </Typography>
      </Box> : null}
    </React.Fragment>
  );
};

export default Table;