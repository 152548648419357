import * as React from 'react';
import { AppBar, AppBarProps, Grid, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import messages from '../../../core-utils/messages';
import IconFromSvg from '../../atoms/IconFromSvg';
import SonetLogoSvg from '@assets/sonet-logo.svg';
import HelpActive from '../../../../public/assets/help-active.svg';
import theme from '../../../core-utils/theme';

interface IHeaderProps extends AppBarProps {
  handleHelp?: () => void;
}

const StyledHeader = styled(AppBar)({
  backgroundColor: theme.palette.common.white,
  borderBottom: '1px solid rgba(238, 238, 238, 1)',
  height: theme.spacing(16.25),
  width: '100%',
  paddingLeft: theme.spacing(3.25),
  color: theme.palette.common.black,
});

const OnBoardingHeader = ({ handleHelp, ...rest }: IHeaderProps) => {
  return (
    <Grid container justifyContent="space-between">
      <StyledHeader position="static" {...rest}>
        <Toolbar>
          <Grid item container direction="row">
            <Grid item sx={{ cursor: 'pointer' }}>
              <IconFromSvg path={SonetLogoSvg} alt="sonet-logo" className="h-16" />
            </Grid>
            <Grid item sx={{ cursor: 'pointer' }}>
              <Typography
                variant="subtitle1"
                sx={{
                  paddingLeft: theme.spacing(2.25),
                  paddingTop: theme.spacing(1.25),
                }}
              >
                {messages.SONET_HEADING}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container justifyContent="flex-end" onClick={handleHelp}>
            <Grid item sx={{ cursor: 'pointer' }}>
              <IconFromSvg path={HelpActive} alt="help-icon" />
            </Grid>
            <Grid item sx={{ cursor: 'pointer' }}>
              <Typography
                variant="caption"
                sx={{
                  paddingLeft: theme.spacing(2),
                  color: theme.palette.primary.main,
                }}
              >
                {messages.HELP}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </StyledHeader>
    </Grid>
  );
};

export default OnBoardingHeader;
