import { IMultipleSelectChip } from '../MultipleSelectChip';
import * as React from 'react';
import Box from '@mui/material/Box';
import SearchDropDown, { IDropDown } from '../SearchDropDown';
import { IChipProps } from '../../atoms/Chip';
import IconFromSvg from '../../atoms/IconFromSvg';
import { CHEVRON_UP_ICON, DROP_DOWN_ICON } from '../../../core-utils/constants';
import ChevronDownSvg from '../../../../public/assets/chevronDown-inactive.svg';
import ChevronUpSvg from '../../../../public/assets/chevron-up.svg';
import TextField from '../../atoms/TextField';
import ChipsGroup from '../../molecules/ChipsGroup';
import theme from '../../../core-utils/theme';
import { DRAGGABLE_POLICY__ITEMS_ID } from '../../../core-utils/constants';
import { useShowOrHideElement } from '../../../core-utils/Hooks/index.hook';
import { getChipColorFromLabel } from '../../../core-utils/Helper/helper';

export interface IPolicyMultipleSelectChip extends IDropDown {
  onChipDelete: (id: string) => void;
  textFieldLabel?: string;
  sortSelected?: Function;
  category?: 'users' | 'usergroups';
  handleDrop: (id: string) => void;
  cyId?: string;
}

const PolicyActionChipDropdown = (props: IPolicyMultipleSelectChip) => {
  const { open, toggleDisplay, hideElement } = useShowOrHideElement();

  const onTextFieldClick = (
    _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    toggleDisplay();
    props.sortSelected && props.sortSelected();
  };

  const onClickAway = (_event: MouseEvent | TouchEvent) => {
    hideElement();
    props.sortSelected && props.sortSelected();
  };

  const chips: IChipProps[] = props.checkedItems.map((item) => {
    return {
      label: item.name,
      hasBorder: true,
      shade: getChipColorFromLabel(item.name)?.[0],
      onDelete: () => {
        props.onChipDelete(item.id);
      },
      sx: { color: getChipColorFromLabel(item.name)?.[1], fontSize: '1em' },
    } as IChipProps;
  });

  const SelectedChips = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          m: 2,
          width: `calc(100% - ${theme.spacing(4)})`,
        }}
      >
        {<ChipsGroup noOfChips={2} chips={chips} />}
        {props.checkedItems.length == 0 && <Box height={29} />}
      </Box>
    );
  };

  return (
    <Box
      onDragOver={(event: any) => {
        event.preventDefault();
      }}
      onDrop={(event: any) => {
        // TODO
        // props.handleDrop();
      }}
    >
      <TextField
        label={props.textFieldLabel}
        endAdornment={
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {open ? (
              <IconFromSvg path={ChevronUpSvg} alt={CHEVRON_UP_ICON} />
            ) : (
              <IconFromSvg path={ChevronDownSvg} alt={DROP_DOWN_ICON} />
            )}
          </Box>
        }
        onClick={onTextFieldClick}
        InputProps={{
          inputComponent: SelectedChips,
        }}
        sx={{
          pb: 1,
          '& .MuiOutlinedInput-root': {
            height: 53.11,
          },
          width: 173,
        }}
        testId="policy-multiple-chip"
        onDragOver={(event: any) => {
          event.preventDefault();
        }}
        onDrop={(event: any) => {
          const droppedItemId = event.dataTransfer.getData('text');
          if (
            droppedItemId.startsWith(
              DRAGGABLE_POLICY__ITEMS_ID.POLICY_ACTION_ID,
            )
          ) {
            props.handleDrop(droppedItemId.split('_')[1]);
          }
        }}
        cy-data-id={props?.cyId}
      />
      {open && (
        <SearchDropDown
          dropDownList={props.dropDownList}
          checkedItems={props.checkedItems}
          handleCheckedItems={props.handleCheckedItems}
          handleClickAway={onClickAway}
          category={props.category}
          currentlyDisabledItems={props?.currentlyDisabledItems}
          usergroups={props.dropDownList}
          policyComponent
        />
      )}
    </Box>
  );
};

export default PolicyActionChipDropdown;
