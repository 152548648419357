import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import SettingsService from '../../api-service/settings-service/SettingsService';

// Header thunk api calls

export const getHeaderDataThunk = createAsyncThunk(
  'getHeaderData',
  async () => {
    const response = await SettingsService.getAccountSettings();
    return response;
  },
);
export const updateHeaderDataThunk = createAsyncThunk(
  'putHeaderData',
  async (payload) => {
    await SettingsService.updateAccountSettings(payload);
  },
);

const initialHeaderData: any = {
  entities: {
    userName: '',
    organizationName: '',
    organizationLogo: '',
    organizationPhone: '',
    organizationPhoneCode: '',
    organizationAddress: '',
    suppportEmailAddress: '',
    language: '',
    timeZone: '',
    idp_name: '',
    sonet_protected_dashboard: true,
    idp_url: '',
    is_private_key_masked: true,
  },
};
const initialLoadingValue = {
  loading: false,
};

// Single source of truth for loading
export const loadingSlice = createSlice({
  name: 'Loading Slice',
  initialState: initialLoadingValue,
  reducers: {
    updateLoadingState: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const headerSlice = createSlice({
  name: 'Header Data',
  initialState: initialHeaderData,
  reducers: {
    updateHeaderData: (state, action) => {
      state.entities = { ...state.entities, ...action.payload, fetched: true }
    },
    resetHeaderData: (state, action) => {
      state.entities = initialHeaderData;
    },
  },
  extraReducers: {
    [getHeaderDataThunk.fulfilled as any]: (state, { payload }) => {
      state.entities = payload;
    },
  },
});

/**
 *********    For consuming the data in the actual component use it as follows   ********


const count = useSelector((state: RootStateOrAny) => state.header);
const dispatch = useDispatch();

          ********    Dispatch the thunk here for making the api call     ********       
useEffect(() => {
  dispatch(getHeaderDataThunk()); 
}, []);  
 
 */

export const { updateHeaderData } = headerSlice.actions;

export const headerAction = headerSlice.actions;
export default headerSlice;
