import { Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  IUserGroupsAndAppsTableRow,
  USER_GROUPS_AND_APPS_HEADERS,
} from '../../../core-utils/constants';
import { getHeight } from '../../../core-utils/Helper/helper';
import {
  useShowOrHideElement,
  useWindowSize,
} from '../../../core-utils/Hooks/index.hook';
import userGroupMessages from '../../../core-utils/messages/usergroup';
import Button from '../../atoms/Button';
import EmptyAssignmentState from '../../molecules/NoAssignmentState';
import AddGroupsToUserOrAppModal from '../AddGroupsToUserOrAppModal';
import Table from '../Table';
import UserGroupsTable from '../UserGroupsTable';

interface IUserGroupUsersTabItemProps {
  usergroups?: IUserGroupsAndAppsTableRow[];
  appId: string | number;
  updateUsergroupsOfApp?: any;
  updateUsersOfApp?: any;
}

const UserGroupsOfAppTabItem = ({
  usergroups,
  appId,
  updateUsergroupsOfApp,
  updateUsersOfApp,
}: IUserGroupUsersTabItemProps) => {
  const theme = useTheme();
  const { open, hideElement, showElement } = useShowOrHideElement();
  const [tabItemUserGroups, setTabItemUserGroups] = useState(usergroups);
  const [arrow, setArrow] = useState<boolean>(false);

  const reverseUserGroups = () => {
    tabItemUserGroups && setTabItemUserGroups([...tabItemUserGroups].reverse());
  };

  useEffect(() => {
    setTabItemUserGroups(usergroups);
    setArrow(!arrow);
  }, [usergroups]);

  const { height } = useWindowSize();

  return (
    <Grid container>
      <Grid
        container
        item
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
        sx={{ marginBottom: theme.spacing(4) }}
      >
        <Typography sx={{ color: theme.palette.text.disabled }}>
          {userGroupMessages.USERGROUPS_ACCESS_MESSAGE_UNDER_APPS}
        </Typography>
        <AddGroupsToUserOrAppModal
          userGroupsOfUserOrApp={usergroups}
          context="app"
          userOrappId={appId}
          open={open}
          handleClose={hideElement}
          updateUserGroups={updateUsergroupsOfApp}
          updateUsers={updateUsersOfApp}
        />
        <Button variant="contained" onClick={showElement}>
          {userGroupMessages.USERGROUPS_DASHBOARD.ADD_USERGROUP}
        </Button>
      </Grid>
      <Grid
        item
        sx={{
          height: tabItemUserGroups?.length ? getHeight(height) : 'fit-content',
        }}
        xs={12}
        container
      >
        {tabItemUserGroups?.length ? (
          <UserGroupsTable
            tabledata={tabItemUserGroups}
            onLabelClick={reverseUserGroups}
            arrow={arrow}
          />
        ) : (
          <>
            <Table headers={USER_GROUPS_AND_APPS_HEADERS()} />
            <EmptyAssignmentState
              displayText={userGroupMessages.USERGROUPS_EMPTY_STATE_UNDER_APPS}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};
export default UserGroupsOfAppTabItem;
