import axios from 'axios';
import { getAuthToken } from '../core-utils/Helper/helper';

const axiosInstance = axios.create({});

axiosInstance.interceptors.request.use((config) => {
  config.headers = { ...config.headers, token: getAuthToken() };
  return config;
});

axiosInstance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if(error?.response?.status === 403 && getAuthToken()) {
    window.location.href = '/logout'; 
  } 
  return Promise.reject(error);
});

export default axiosInstance;
