import React from 'react';
import {
  Autocomplete as MuiAutoComplete,
  TextField as MuiTextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import { styled } from '@mui/material/styles';
import ChevronDownIcon from '../../../../public/assets/chevronDown-active.svg';
import IconFromSvg from '../../atoms/IconFromSvg';
import { IDropdownProps } from '../Dropdown';

const useStyles = makeStyles(() => ({
  paper: {
    border: '1px solid' + EXTRA_COLORS.blue_100,
    borderRadius: '0px 0px 4px 4px',
    boxShadow: theme.shadows[7],
    color: theme.palette.text.secondary,
  },
}));

const StyledTextField = styled(MuiTextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: `${theme.palette.common.white} !important`,
    },
    '&:hover fieldset': {
      borderColor: `${theme.palette.common.white} !important`,
    },
    '&.Mui-focused fieldset': {
      borderColor: `${theme.palette.common.white} !important`,
    },
  },
  '& .MuiFormLabel-root': {
    backgroundColor: theme.palette.common.white,
  },
  '& input::placeholder': {
    fontSize: '13.5px',
    color: theme.palette.text.primary,
  },
});

const OrganisationFormDropDown = ({
  label,
  inputValue,
  value,
  options,
  disableClearable = true,
  disableOptions,
  handleDropdownChange,
  handleInputChange,
  ...rest
}: IDropdownProps) => {
  const classes = useStyles();

  return (
    <MuiAutoComplete
      disablePortal
      disableClearable={disableClearable}
      options={options}
      getOptionDisabled={disableOptions}
      getOptionLabel={(option) => (option.name ? option.name : '')}
      onInputChange={handleInputChange}
      value={value}
      freeSolo={true}
      onChange={handleDropdownChange}
      autoHighlight={false}
      filterSelectedOptions
      classes={{
        paper: classes.paper,
      }}
      forcePopupIcon={true}
      popupIcon={
        <IconFromSvg
          path={ChevronDownIcon}
          alt="chevron-icon"
          width={20}
          height={20}
        />
      }
      sx={{ width: 100 }}
      ListboxProps={{ style: { maxHeight: '150px' } }}
      renderInput={({ inputProps, ...restParams }) => (
        <StyledTextField
          required
          inputProps={inputProps}
          autoComplete="on"
          {...restParams}
          {...rest}
          sx={{
            width: 100,
            input: { color: theme.palette.text.disabled, fontWeight: 'normal' },
          }}
          variant="outlined"
        />
      )}
    />
  );
};

export default OrganisationFormDropDown;
