import React, { useEffect, useState } from 'react';
import AppService from '../../../api-service/app-service/AppService';
import UserService, {
  IListUserResponse,
} from '../../../api-service/user-service/UserService';
import {
  ADMIN_SIDE_NAVIGATION_ITEMS,
  IDetailPageProps,
  IKeyValueObject,
  IUserGroupsAndAppsTableRow,
  USER_DETAILS_TAB,
  USER_GROUP_DETAILS_TAB,
} from '../../../core-utils/constants';
import { IUserTableRow } from '../../molecules/UserTableRow';
import AppDetailsDashboard from '../../organisms/AppDetailsDashboard';
import MainHeader from '../../organisms/MainHeader';
import SideNavigationDrawer, {
  sideNavLocalStorageKeys,
} from '../../organisms/SideNavigationDrawer';
import HomeTemplate from '../../templates/HomeTemplate';
import { constructUserGroupRowsForApps, getAppById } from './index.hook';
import {
  constructListUsers,
  getUserGroupById,
} from '../UserGroupDetailPage/index.hook';
import {
  getCustomDomainName,
  getOrganization,
  getSideNavEndItems,
  getSideNavItems,
  getUserFullNameOrUsername,
} from '../../../core-utils/Helper/helper';
import LoadingAnimation from '../../atoms/LoadingAnimation';
import InnerDetailSummaryModal from '../../organisms/InnerDetailSummaryModal';
import { useShowOrHideElement } from '../../../core-utils/Hooks/index.hook';
import { getUserById } from '../UserDetailsPage/index.hook';
import UserGroupService from '../../../api-service/user-group-service/UserGroupService';
import { useAppSelector, useAppDispatch } from 'src/Redux/Store/store';
import { RootStateOrAny } from 'react-redux';
import { sideNavActions } from 'src/Redux/Reducers/sideNav';
import { connectionsActions } from 'src/Redux/Reducers/connections';

const AppDetailsPage = ({ match }: IDetailPageProps) => {
  const reloadUserData = useAppSelector(
    (state: RootStateOrAny) => state.sideNavDrawer.isReloadusersData,
  );
  const dispatch = useAppDispatch();
  const [appDetails, setAppDetails] = useState({
    name: '',
    createdBy: '',
    createdOn: '',
    description: '',
    applicationType: '',
    appLogo: '',
    userGroupMembers: '',
    userMembers: '',
    idpAppUrl: '',
    source: '',
  });

  const [usergroups, setUserGroups] = useState<IUserGroupsAndAppsTableRow[]>();
  const [userDetails, setUserDetails] = useState<IUserTableRow[]>();
  const [customDomain, setCustomDomain] = useState('');
  const [rdpAppPath, setRDPAppPath] = useState('');
  const [domainUrls, setDomainUrls] = useState('');
  const [loading, setLoading] = useState<boolean>(true);
  const { open, hideElement, showElement } = useShowOrHideElement();
  const [popUpData, setPopUpData] = useState<IKeyValueObject[]>([]);
  const [popUpRedirection, setPopUpRedirection] = useState<{
    tabItemType: 'user' | 'usergroup';
    redirectionId: string;
  }>();

  const popUpUser = async (username: string) => {
    const userDetails = await getUserById(username);
    const {
      NAME,
      EMAIL,
      JOB_TITLE,
      PASSWORD,
      USER_STATUS,
      SOURCE,
    } = USER_DETAILS_TAB();
    const requiredDetails = [
      { key: NAME.label, value: userDetails.name },
      { key: EMAIL.label, value: userDetails.email },
      { key: JOB_TITLE.label, value: userDetails.jobTitle },
      { key: PASSWORD.label, value: PASSWORD.value },
      // { key: USER_STATUS.label, value: userDetails.userStatus },
      { key: SOURCE.label, value: userDetails.source },
    ];
    setPopUpData(requiredDetails);
    setPopUpRedirection({ tabItemType: 'user', redirectionId: username });
    showElement();
  };

  const popUpUserGroup = async (groupId: string, userLength: number) => {
    const userGroupDetails = await getUserGroupById(groupId);
    const {
      GROUP_NAME,
      NUMBER_OF_USERS,
      CREATED_BY,
      DESCRIPTION,
      SOURCE,
    } = USER_GROUP_DETAILS_TAB;
    const requiredUserGroupDetails = [
      { key: GROUP_NAME.label, value: userGroupDetails.name },
      { key: NUMBER_OF_USERS.label, value: userLength },
      { key: CREATED_BY.label, value: userGroupDetails.createdBy },
      { key: DESCRIPTION.label, value: userGroupDetails.description },
      { key: SOURCE.label, value: userGroupDetails.source },
    ];
    setPopUpData(requiredUserGroupDetails);
    setPopUpRedirection({ tabItemType: 'usergroup', redirectionId: groupId });
    showElement();
  };

  const getUserGroupsOfApp = async (
    userGroupMembers: string[],
    appName: string,
  ) => {
    // const groupResponse = await AppService.getUserGroupsForApp(match.params.id);
    const userGroupsTableRows = await constructUserGroupRowsForApps(
      userGroupMembers,
      appName,
      popUpUserGroup,
      match.params.id,
      getUserGroupsOfApp,
      getUsersOfApp,
    );
    setUserGroups(userGroupsTableRows);
  };

  const getUsersOfApp = async (
    userMembers: string[],
    appName: string,
    userGroupMembers?: string[],
  ) => {
    const userGroupUsersNamesList: string[] = [];
    const userNamesPromises = userGroupMembers?.map(async (usergroup) => {
      const list = await UserGroupService.getUsersInUserGroup(usergroup);
      userGroupUsersNamesList.push(...list);
    });
    userNamesPromises && (await Promise.all(userNamesPromises));

    const filteredUserGroupUsersNames = userGroupUsersNamesList.filter(
      (usergroup, index) =>
        userGroupUsersNamesList.indexOf(usergroup) === index,
    );
    const userGroupUsersList: IListUserResponse[] = [];
    const userGroupUsersPromises = filteredUserGroupUsersNames.map(
      async (username: string) => {
        const user: IListUserResponse = await UserService.getUserById(username);
        userGroupUsersList.push(user);
      },
    );
    await Promise.all(userGroupUsersPromises);
    // console.log('UserMembers===================', userMembers);
    const filteredUsers = userMembers.filter(
      (user) => !filteredUserGroupUsersNames.includes(user),
    );
    const userslist: IListUserResponse[] = [];
    const promises = filteredUsers.map(async (username: string) => {
      const user: IListUserResponse = await UserService.getUserById(username);
      userslist.push(user);
    });
    await Promise.all(promises);

    const usersOfApp = await constructListUsers(
      userslist,
      match.params.id,
      getUsersOfApp,
      popUpUser,
      'app',
      appName,
      userGroupUsersList,
      // Added By Devang this check will use to get CredsDetails and sow Application credentials on AppDetailsDashboard
      true, // THis is for showing applicationCreds
    );
    setUserDetails(usersOfApp);
  };

  const getAppParameters = async () => {
    const appParams = await AppService.getAppParameters(match.params.id);
    setDomainUrls(appParams?.domainurls ?? '');
    setCustomDomain(getCustomDomainName(appParams));
    setRDPAppPath(appParams?.appPath);
  };

  const handleRedirection = () => {
    localStorage.setItem(
      sideNavLocalStorageKeys.sideNav,
      ADMIN_SIDE_NAVIGATION_ITEMS[4].id.toString(),
    );
    if (popUpRedirection?.tabItemType === 'user') {
      location.pathname = `/users/${popUpRedirection.redirectionId}`;
      // localStorage.setItem(sideNavLocalStorageKeys.childItem, '2');
    } else {
      location.pathname = `/usergroups/${popUpRedirection?.redirectionId}`;
      // localStorage.setItem(sideNavLocalStorageKeys.childItem, '1');
    }
  };

  const getAppDetails = async () => {
    const detailsOfApp = await getAppById(match.params.id);
    setAppDetails(detailsOfApp);
    dispatch(connectionsActions.setconnectionDetails(detailsOfApp));
    getAppParameters();
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getAppById(match.params.id).then((response) => {
        setAppDetails(response);
        dispatch(connectionsActions.setconnectionDetails({...response, identifier: match.params.id}));
        getUsersOfApp(
          response.userMembers,
          response.name,
          response.userGroupMembers,
        );
        getUserGroupsOfApp(response.userGroupMembers, response.name);
      }),
      getAppParameters(),
    ])
      .then()
      .finally(() => setLoading(false));
    return () => {
      dispatch(sideNavActions.setApplicationDashboardSelectedTab(0));
    };
  }, [reloadUserData]);

  const sideNavItems = getSideNavItems();
  const sideNavEndItems = getSideNavEndItems();

  return (
    <HomeTemplate
      leftNavComponent={
        <SideNavigationDrawer
          sideNavItems={sideNavItems}
          sideNavEndItems={sideNavEndItems}
        />
      }
      header={
        <MainHeader
          username={getUserFullNameOrUsername()}
          isImage={false}
          usercompany={getOrganization()}
        />
      }
      content={
        loading ? (
          <LoadingAnimation />
        ) : (
          <>
            <AppDetailsDashboard
              appId={match.params.id}
              appsDetails={appDetails}
              usergroups={usergroups}
              userDetails={userDetails}
              customDomain={customDomain}
              updateUsersOfApp={getUsersOfApp}
              updateUsergroupsOfApp={getUserGroupsOfApp}
              domainUrls={domainUrls}
              getAppDetails={getAppDetails}
              rdpAppPath={rdpAppPath}
            />
            {popUpData.length > 0 && (
              <InnerDetailSummaryModal
                open={open}
                onClose={hideElement}
                nameHeading={popUpData[0].value}
                detailItemValues={popUpData}
                clickViewDetails={handleRedirection}
              />
            )}
          </>
        )
      }
    />
  );
};

export default AppDetailsPage;
