import * as React from 'react';
import PoliciesTableRow, { IPoliciesTableRowProps } from '../PoliciesTableRow';

export interface IPoliciesTableBody {
  tabledata: IPoliciesTableRowProps[];
  handleClick?: (id: string | number, policyName: string) => void;
}

const PoliciesTableBody = ({ tabledata, handleClick }: IPoliciesTableBody) => {
  return (
    <>
      {tabledata.map((data: IPoliciesTableRowProps, index) => {
        return (
          <PoliciesTableRow
            key={index}
            {...data}
            inPopUp={true}
            handleTableRowClick={
              data.id && handleClick
                ? () => handleClick(data.id ?? '', data.policyName)
                : data?.handleTableRowClick
            }
          />
        );
      })}
    </>
  );
};
export default PoliciesTableBody;
