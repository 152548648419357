import React, { useState } from 'react';
import { POLICY_ACTION_DROPDOWN_OPTIONS } from '../../../core-utils/constants';
import { EXTRA_COLORS } from '../../../core-utils/theme';
import TextField from '../../atoms/TextField';
import PolicyActionChipDropdown from '../../organisms/PolicyMultipleChipDropdown';
import { useColoredPolicyChipSelection } from '../../organisms/PolicyMultipleChipDropdown/index.hook';
import Dropdown, { IDropdownOptionProps } from '../Dropdown';

interface IPolicyConditionCellProps {
  variant: 'dropdown' | 'time-select' | 'text-field' | 'actions-dropdown';
  dropdownOptions?: Array<IDropdownOptionProps>;
  dropdownValue?: any;
  textfieldValue?: any;
  timeSelectValue?: any;
  timeselectDefaultValue?: string;
  disableAnd?: boolean;
  actions?: any;
  cardId?: number;
  rowId?: number;
  handleDropdownChange?: (event: any, value: any) => void;
  handlePolicyActionChange?: (cardId: number, value: any, id?: number) => void;
  handleTextFieldChange?: (event: any) => void;
  handleOpenSnackbar?: () => void;
  handleCloseSnackbar?: () => void;
  policyValueErrorMessage?: string;
  isParameter?: boolean;
  cyId?: string;
}

const CellStyle = {
  width: 173,
};

const radiusStyle = {
  ...CellStyle,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '0px !important',
    },
  },
};

const borderStyle = {
  ...radiusStyle,
  borderLeft: `3px solid ${EXTRA_COLORS.aqua}`,
};

const PolicyConditionCell = ({
  variant,
  dropdownOptions,
  dropdownValue,
  textfieldValue,
  timeSelectValue,
  timeselectDefaultValue,
  disableAnd,
  actions,
  cardId,
  rowId,
  handleDropdownChange,
  handleTextFieldChange,
  handlePolicyActionChange,
  handleOpenSnackbar,
  handleCloseSnackbar,
  policyValueErrorMessage,
  isParameter,
  cyId = '',
}: IPolicyConditionCellProps) => {
  //TODO : Need to create a logic in cutom hook so accordingly the respective card item actions get updated

  const getSelectedItems = () => {
    var selectedItems: any = [];
    if (dropdownValue && actions) {
      const actionsDict: any = {};
      actions.map((action: any) => (actionsDict[action.name] = action.id));
      dropdownValue.map((value: any) => {
        if (Object.keys(actionsDict).includes(value)) {
          selectedItems.push({ id: actionsDict[value], name: value });
        }
      });
    }
    return selectedItems;
  };

  if (variant === 'actions-dropdown') {
    var {
      handleChange,
      sortedItems,
      removeItem,
      displayedItems,
      sortBySelectedItems,
      currentlyDisabledItems,
    } = useColoredPolicyChipSelection(
      actions,
      getSelectedItems(),
      handleOpenSnackbar,
      handleCloseSnackbar,
      disableAnd,
      true,
      cardId,
      handlePolicyActionChange,
      rowId,
    );
  }
  var options: any = dropdownOptions;
  if (dropdownOptions && dropdownValue) {
    options = dropdownOptions.filter(
      (option: any) => option.name !== dropdownValue.name,
    );
  }

  return (
    <div style={{ borderRadius: 0 }}>
      {variant === 'dropdown' && (
        <Dropdown
          options={options ?? []}
          handleDropdownChange={handleDropdownChange}
          value={dropdownValue}
          sx={isParameter ? borderStyle : radiusStyle}
          disabled={dropdownValue?.disabled ?? false}
          cyId={cyId}
        />
      )}
      {variant === 'text-field' && (
        <TextField
          value={textfieldValue}
          sx={radiusStyle}
          handleChange={handleTextFieldChange}
          autoComplete="off"
          error={policyValueErrorMessage !== ''}
          helperText={policyValueErrorMessage}
          cy-data-id={cyId}
        />
      )}
      {variant === 'time-select' && (
        <TextField
          value={timeSelectValue}
          defaultValue={timeselectDefaultValue}
          sx={radiusStyle}
        />
      )}
      {variant === 'actions-dropdown' && (
        <PolicyActionChipDropdown
          dropDownList={displayedItems}
          checkedItems={sortedItems()}
          handleCheckedItems={handleChange}
          onChipDelete={removeItem}
          sortSelected={sortBySelectedItems}
          currentlyDisabledItems={currentlyDisabledItems}
          handleDrop={(id: string) => {
            handleChange(id, true);
          }}
          cyId={cyId}
        />
      )}
    </div>
  );
};

export default PolicyConditionCell;
