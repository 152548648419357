import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Modal, Typography } from '@mui/material';
import IconFromSvg from '../../atoms/IconFromSvg';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import CloseIcon from '../../../../public/assets/close-inactive.svg';
import SearchField from '../../molecules/SearchInput';
import ApplicationSelectCard, {
  ApplicationSelectCardProps,
} from '../../molecules/ApplicationSelectCard';
import { getApps } from '../../../core-utils/Helper/helper';
import selectApplicationMessages from '../../../core-utils/messages/selectApplicationMessages';
import { POPUP_STYLE } from '../../../core-utils/constants';

export interface ISelectApplication {
  heading?: string;
  apps: ApplicationSelectCardProps[];
  open: boolean;
  handleClose: () => void;
  handleClickAppCard: (id: string, name: string) => void;
}

const SelectApplication = ({
  heading,
  apps,
  open,
  handleClose,
  handleClickAppCard,
}: ISelectApplication) => {
  const [searchResults, setSearchResults] = useState<
    ApplicationSelectCardProps[]
  >(apps);

  const resultBox = (
    <Grid container direction="row" flexWrap="wrap" spacing={3}>
      {searchResults.map((app, index) => {
        return (
          <Grid
            item
            key={index}
            xs={6}
            sm={4}
            lg={3}
            onClick={() =>
              !app.disable && handleClickAppCard(app.id, app.heading)
            }
          >
            <ApplicationSelectCard {...app} height="100%" />
          </Grid>
        );
      })}
    </Grid>
  );

  const handleChange = (event: any) => {
    const searchText = event.target.value;
    if (searchText == '') {
      setSearchResults(apps);
    } else {
      setSearchResults(getApps(searchText, apps));
    }
  };

  useEffect(() => {
    setSearchResults(apps);
  }, [open]);

  const style = {
    ...POPUP_STYLE,
    width: 1012,
    height: 428,
    bgcolor: EXTRA_COLORS.bluishBackground,
    borderRadius: theme.spacing(2.5),
    boxShadow: `${theme.spacing(0)} ${theme.spacing(5)} ${theme.spacing(
      6,
    )} rgba(0, 0, 0, 0.08)`,
    p: 7,
    px: 6,
    pb: 6,
    overflow: 'scroll',
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box flexDirection="column" rowGap={3} sx={style}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h2">
            {heading
              ? heading
              : selectApplicationMessages.SELECT_APPLICATION_HEADING}
          </Typography>

          <IconButton onClick={handleClose}>
            <IconFromSvg path={CloseIcon} alt="close" />
          </IconButton>
        </Box>

        <Typography variant="body2" sx={{ paddingBottom: 3 }}>
          {selectApplicationMessages.SELECT_APPLICATION_SUB_HEADING}
        </Typography>

        <SearchField
          placeholder={selectApplicationMessages.SEARCH_BAR_PLACEHOLDER}
          sx={{ width: '100%', paddingBottom: 3 }}
          handleChange={handleChange}
        />

        {resultBox}
      </Box>
    </Modal>
  );
};

export default SelectApplication;
