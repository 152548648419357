import { ConstructionOutlined } from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import PolicyService, {
  IAddPolicyRequest,
  IPolicyContent,
  IPolicyCondition,
  IConditionBlock,
} from '../../../api-service/policy-service/PolicyService';
import {
  DRAGGABLE_POLICY__ITEMS_ID,
  IDropDownItem,
  POLICY_ACTION_DROPDOWN_OPTIONS,
  POLICY_ALLOWANCE_OPTIONS,
  POLICY_EQUATION_OPTIONS,
  POLICY_PARAMETER_OPTIONS,
  REGEX,
} from '../../../core-utils/constants';
import policyBuilderMessages from '../../../core-utils/messages/policyBuilder';
import { IPolicyBlockProps } from '../../atoms/DraggablePolicyBlock';
import { Conditions } from '../../atoms/DraggablePolicyBlock/index.stories';
import { IDropdownOptionProps } from '../../molecules/Dropdown';
import { useColoredPolicyChipSelection } from '../../organisms/PolicyMultipleChipDropdown/index.hook';
import { SnackBarContext } from '../PolicyBuilder';

const emptyFields: any = {
  parameterValue: null,
  equationValue: null,
  allowanceValue: null,
  policyValue: null,
  policyValueErrorMessage: '',
  showJoin: true,
  showOr: false,
};

export interface IPolicyBuilderHookProps {
  handleSelectPolicyBlock: (value: boolean) => void;
  policyBlockItemResponse: any;
  policyId?: string;
}
const usePolicyBuilder = ({
  handleSelectPolicyBlock,
  policyBlockItemResponse,
  policyId,
}: IPolicyBuilderHookProps) => {
  // TODO : Make data according to the api call

  //initialize policy card content in edit mode

  const pushData = (
    tempConditions: any[],
    parametersList: any[],
    condition: any,
    options: any = {},
    showJoin: boolean,
    showOr: boolean,
    defaultAction: string | null,
  ) => {
    const parameterId = Object.keys(options)?.find(
      (conditionId: any) => options[conditionId].name === condition.name,
    );
    if (parameterId) {
      var { equations, actions } = getActionsAndEquations(
        parameterId,
        options,
        defaultAction,
      );
    }
    tempConditions.push({
      id: tempConditions.length,
      parameterValue: {
        id: parameterId,
        name: parameterId && options[parameterId]?.attributes?.displayName,
      },
      equationValue: { id: 0, name: condition.operator },
      allowanceValue: condition.action,
      policyValue: formatTime(condition.name, condition.value, 'remove'),
      policyValueErrorMessage: '',
      parameters: {
        parametersList: parametersList,
        equationsList: equations,
        actionsList: actions,
      },
      showJoin: showJoin,
      showOr: showOr,
    });
  };
  const initializePolicyCardData = async (policyData: any) => {
    const policyContent = JSON.parse(policyData?.policyContent || "[]");
    const initialPolicyCardsData: any[] = [];
    for (let i = 0; i < policyContent.length; i++) {
      const policyBlockContent = policyContent[i];

      const policyType = policyBlockContent?.policyBlockType;
      const policyBlockId = Object.keys(policyBlockItemResponse)?.find(
        (id: any) =>
          policyBlockItemResponse?.[id]?.name === policyType ||
          policyBlockItemResponse?.[id]?.type === policyType,
      );
      if (policyBlockId) {
        var options = await getAllConditionsDataOfPolicyBlock(policyBlockId);
      }
      const tempConditions: any[] = [];
      const parametersList = getAllParameters(options);
      for (let j = 0; j < policyBlockContent?.conditionList?.length ?? 0; j++) {
        if (policyBlockContent?.conditionList?.[j]?.type === 'simple') {
          var showOr = true;
          var showJoin = true;
          if (j === 0) {
            showOr = false;
          }
          const condition =
            policyBlockContent?.conditionList?.[j]?.conditionBlock?.[0];
          pushData(
            tempConditions,
            parametersList,
            condition,
            options,
            showJoin,
            showOr,
            policyBlockContent?.defaultAction,
          );
        } else {
          for (
            let i = 0;
            i < policyBlockContent?.conditionList?.[j]?.conditionBlock?.length ?? 0;
            i++
          ) {
            var showOr = false;
            var showJoin = false;
            if (i == 0 && j !== 0) {
              showOr = true;
            }
            if (
              i ===
              policyBlockContent?.conditionList?.[j]?.conditionBlock?.length - 1
            ) {
              showJoin = true;
            }
            const condition =
              policyBlockContent?.conditionList?.[j]?.conditionBlock?.[i];
            var params = parametersList;
            var k = 0;
            while (
              k < policyBlockContent?.conditionList?.[j]?.conditionBlock?.length
            ) {
              if (k !== i) {
                var parameters: any = [];
                params.map((param: any) => {
                  if (
                    options[param.id]?.name !==
                    policyBlockContent?.conditionList?.[j]?.conditionBlock?.[k]?.name
                  ) {
                    parameters.push(param);
                  }
                });
                params = [...parameters];
              }
              k += 1;
            }
            pushData(
              tempConditions,
              Array.from(new Set(params)),
              condition,
              options,
              showJoin,
              showOr,
              policyBlockContent.defaultAction,
            );
          }
        }
      }

      if (policyBlockId) {
        const defaultActions = policyBlockItemResponse?.[
          policyBlockId
        ]?.defaultActionOptions?.map((action: any, index: number) => {
          return { id: index, name: action };
        });
        const defaultAction = defaultActions?.find(
          (action: any) =>
            action?.name?.toLowerCase() ===
            policyBlockContent?.defaultAction?.toLowerCase(),
        );
        initialPolicyCardsData.push({
          id: i,
          name: policyBlockItemResponse?.[policyBlockId]?.name,
          type: policyType,
          expanded: true,
          policyBlockId: policyBlockId,
          defaultAction: defaultAction,
          defaultActionOptions: defaultActions,
          optionsData: options,
          conditionsActionsListData: getAllPolicyConditionsAndActions(options),
          policyConditions: tempConditions,
        });
      }
    }
    if (initialPolicyCardsData) {
      setPolicyCardData(initialPolicyCardsData);
      setFocusedCardId(initialPolicyCardsData.length - 1);
      setPolicyConditionsActionsLists(
        initialPolicyCardsData[initialPolicyCardsData.length - 1]
          ?.conditionsActionsListData,
      );
    }
  };

  const [isExpanded, setIsExpanded] = useState(true);
  const [policyCardData, setPolicyCardData] = useState<any>([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isSaveEnabled, setSaveEnabled] = useState<boolean>(true);
  const [userGroupDropdown, setUserGroupDropdown] = useState<
    IDropdownOptionProps[]
  >([]);

  const [applicationDropdown, setApplicationDropdown] = useState<
    IDropdownOptionProps[]
  >([]);

  const [urlDropdown, setUrlDropdown] = useState<IDropdownOptionProps[]>([]);
  const [focusedCardId, setFocusedCardId] = useState<number>(0);
  const [
    policyConditionsActionsLists,
    setPolicyConditionsActionsLists,
  ] = useState<any>({});

  const [snackBarMessage, setSnackBarMessage] = useState<any>();
  const onCloseSnackbar = () => {
    setIsSnackbarOpen(false);
  };

  const onOpenSnackbar = () => {
    setIsSnackbarOpen(true);
  };

  const constructUserGroupDropdown = async () => {
    const geolocationList = await PolicyService.getGeolocationConditionList();
    return geolocationList;
  };
  const constructApplicationDropdown = async () => {
    const applicationList = await PolicyService.getApplicationConditionList();
    return applicationList;
  };
  const constructUrlDropdown = async () => {
    const urlList = await PolicyService.getUrlConditionList();
    return urlList;
  };

  useEffect(() => {
    Promise.all([
      constructUserGroupDropdown(),
      constructApplicationDropdown(),
      constructUrlDropdown(),
    ]).then((result) => {
      setUserGroupDropdown(result[0] as IDropdownOptionProps[]);
      setApplicationDropdown(result[1] as IDropdownOptionProps[]);
      setUrlDropdown(result[2] as IDropdownOptionProps[]);
    });
  }, []);

  const handlePolicyChange = async (
    cardItemId: number,
    id: number,
    value: any,
  ) => {
    const cardItems = [...policyCardData];
    const cardItem = cardItems[cardItemId];
    const tempConditions: any = [...cardItem.policyConditions];
    const { equations, actions } = getActionsAndEquations(
      value.id,
      cardItem.optionsData,
      cardItem.defaultAction ? cardItem.defaultAction.name : null,
    );
    var prevParameterValue = tempConditions?.[id]?.parameterValue;
    var tempId = id;
    tempConditions[id] = {
      ...tempConditions[id],
      parameterValue: value,
      equationValue: null,
      allowanceValue:
        tempConditions[id]?.allowanceValue.length &&
          tempConditions[id]?.allowanceValue?.[0]?.toLowerCase() === 'and'
          ? tempConditions[id]?.allowanceValue
          : null,
      // Here we are setting the default value of policy value to this if parameter is time related
      policyValue: null,
      parameters: {
        ...tempConditions[id]?.parameters,
        equationsList: equations,
        actionsList: actions,
      },
    };
    while (id > 0 && !tempConditions[id]?.showOr) {
      id -= 1;
    }
    while (!tempConditions[id]?.showJoin) {
      var params = [
        ...tempConditions[id]?.parameters?.parametersList?.filter(
          (parameter: any) => parameter.name !== value.name,
        ),
      ];
      prevParameterValue && params.push(prevParameterValue);
      tempConditions[id] = {
        ...tempConditions[id],
        parameters: {
          ...tempConditions[id]?.parameters,
          parametersList: Array.from(new Set(params)),
        },
      };
      id += 1;
    }
    if (id !== tempId) {
      var params = [
        ...tempConditions[id]?.parameters?.parametersList?.filter(
          (parameter: any) => parameter.name !== value.name,
        ),
      ];
      prevParameterValue && params.push(prevParameterValue);
      tempConditions[id] = {
        ...tempConditions[id],
        parameters: {
          ...tempConditions[id]?.parameters,
          parametersList: Array.from(new Set(params)),
        },
      };
    }

    cardItem.policyConditions = tempConditions;
    setPolicyCardData(cardItems);
  };


  const handleChangeTimeValue = (
    cardId: number,
    rowId: number,
    startTime: string,
    endTime: string,
    timezone?: string
  ) => {
    const cardItems = [...policyCardData];
    const cardItem = cardItems[cardId];
    const tempConditions: any = [...cardItem.policyConditions];
    tempConditions[rowId] = {
      ...tempConditions[rowId],
      policyValue: `${startTime} - ${endTime} ${timezone}`,
    };
    cardItem.policyConditions = tempConditions;
    setPolicyCardData(cardItems);
  };

  const getActionsAndEquations = (
    key: string,
    data: any,
    excludeAction?: string | null,
  ) => {
    const actions: any = [];
    const equations: any = [];
    data[key]?.operations?.map((operation: string, index: number) => {
      equations.push({ id: index, name: operation });
    });
    data[key]?.actions?.map((action: string, index: number) => {
      if (excludeAction !== action) {
        actions.push({ id: index, name: action });
      }
    });
    return { equations, actions };
  };

  const handleEquationChange = (cardItemId: number, id: number, value: any) => {
    const cardItems = [...policyCardData];
    const cardItem = cardItems[cardItemId];

    const tempConditions: any = [...cardItem.policyConditions];
    tempConditions[id] = { ...tempConditions[id], equationValue: value };
    cardItem.policyConditions = tempConditions;
    setPolicyCardData(cardItems);
  };

  const handlePolicyValueTextChange = (
    cardItemId: number,
    id: number,
    event: any,
  ) => {
    const cardItems = [...policyCardData];
    const cardItem = cardItems[cardItemId];
    const tempConditions: any = [...cardItem.policyConditions];
    var flag = 0;
    if (
      tempConditions?.[id]?.parameterValue?.name?.toLowerCase().includes('ip')
    ) {
      flag = 1;
      if (event?.target?.value.match(REGEX.SERVER_IP)) {
        tempConditions[id] = {
          ...tempConditions[id],
          policyValue: event?.target?.value,
          policyValueErrorMessage: '',
        };
      } else {
        tempConditions[id] = {
          ...tempConditions[id],
          policyValue: event?.target?.value,
          policyValueErrorMessage: 'Invalid IP Address',
        };
        setSaveEnabled(false);
      }
      cardItem.policyConditions = tempConditions;
      setPolicyCardData(cardItems);
    } else if (
      tempConditions?.[id]?.parameterValue?.name?.toLowerCase().includes('content')
    ) {
      if (isNaN(event?.target?.value)) {
        flag = 1;
        if (/[^A-Za-z0-9_\s]/g.test(event?.target?.value?.trim())) {
          tempConditions[id] = {
            ...tempConditions[id],
            policyValue: {
              id: -1,
              name: event?.target?.value,
              displayName: event?.target?.value,
            },
            policyValueErrorMessage: 'Invalid content',
          };
          setSaveEnabled(false);
        } else {
          tempConditions[id] = {
            ...tempConditions[id],
            policyValue: {
              id: -1,
              name: event?.target?.value,
              displayName: event?.target?.value,
            },
            policyValueErrorMessage: '',
          };
        }
        cardItem.policyConditions = tempConditions;
        setPolicyCardData(cardItems);
      }
    } else if (
      tempConditions?.[id]?.parameterValue &&
      (tempConditions?.[id]?.parameterValue?.name
        .toLowerCase()
        .includes('location') ||
        tempConditions?.[id]?.parameterValue?.name?.toLowerCase().includes('app') ||
        tempConditions?.[id]?.parameterValue?.name
          .toLowerCase()
          .includes('content'))
    ) {
    } else {
      tempConditions[id] = {
        ...tempConditions[id],
        policyValue: event?.target?.value,
      };
      cardItem.policyConditions = tempConditions;
      setPolicyCardData(cardItems);
    }
    if (flag) {
      var error = false;
      for (var i = 0; i < cardItems.length; i++) {
        const tempConditions = [...cardItems[i].policyConditions];
        for (var j = 0; j < tempConditions.length; j++) {
          const condition = tempConditions[j];
          if (
            condition.policyValueErrorMessage &&
            condition.policyValueErrorMessage !== ''
          ) {
            error = true;
          }
          if (error) {
            break;
          }
        }
        if (error) {
          break;
        }
      }
      if (!error) {
        setSaveEnabled(true);
      }
    }
  };

  const handlePolicyValueDropdownChange = (
    cardItemId: number,
    id: number,
    value: any,
  ) => {
    const cardItems = [...policyCardData];
    const cardItem = cardItems[cardItemId];
    const tempConditions: any = [...cardItem.policyConditions];

    if (
      tempConditions?.[id].parameterValue?.name?.toLowerCase().includes('location')
    ) {
      tempConditions[id] = {
        ...tempConditions[id],
        policyValue: {
          id: value?.metadata['id'],
          type: value?.metadata['type'],
          name: value?.name,
        },
      };
      cardItem.policyConditions = tempConditions;
      setPolicyCardData(cardItems);
    } else if (
      tempConditions?.[id]?.parameterValue?.name?.toLowerCase().includes('content')
    ) {
      tempConditions[id] = {
        ...tempConditions[id],
        policyValue: {
          id: value?.id,
          name: value?.name,
          displayName: value?.displayName,
        },
      };
      cardItem.policyConditions = tempConditions;
      setPolicyCardData(cardItems);
    } else {
      tempConditions[id] = { ...tempConditions[id], policyValue: value };
      cardItem.policyConditions = tempConditions;
      setPolicyCardData(cardItems);
    }
  };

  const handlePolicyActionsChange = (
    cardItemId: number,
    value: any = [],
    id?: number,
  ) => {
    const cardItems = [...policyCardData];
    const cardItem = cardItems[cardItemId];
    if (value) {
      if (id === undefined) {
        cardItem.defaultAction = value;
        const tempConditions: any = [...cardItem.policyConditions];
        for (let i = 0; i < tempConditions.length; i++) {
          const allowances = tempConditions[i].allowanceValue.filter(
            (action: string) => action !== value.name,
          );
          if (tempConditions[i].parameterValue) {
            const { equations, actions } = getActionsAndEquations(
              tempConditions[i].parameterValue.id,
              cardItem.optionsData,
              value.name,
            );
            tempConditions[i] = {
              ...tempConditions[i],
              allowanceValue: allowances,
              parameters: {
                ...tempConditions[i]?.parameters,
                actionsList: actions,
              },
            };
          }
        }
        cardItem.policyConditions = tempConditions;
        setPolicyCardData(cardItems);
      } else {
        const tempConditions: any = [...cardItem.policyConditions];
        const actions = value.map((action: any) => action.name);
        tempConditions[id] = { ...tempConditions[id], allowanceValue: actions };
        cardItem.policyConditions = tempConditions;
        setPolicyCardData(cardItems);
      }
    }
  };

  const handleToggleCollapse = (cardId: number) => {
    const cardItems = [...policyCardData];
    const cardItem = cardItems[cardId];
    cardItem.expanded = !cardItem.expanded;
    setPolicyCardData(cardItems);
  };

  const handleAddCondition = async (cardItemId: number) => {
    const cardItems = [...policyCardData];
    const cardItem = cardItems[cardItemId];
    const tempConditions: any = [...cardItem.policyConditions];
    const parametersList = getAllParameters(cardItem.optionsData);
    const tempEmptyFields = { ...emptyFields };
    const actionsAndEquations = { actions: [], equations: [] };
    if (cardItem.type === 'contentinspection') {
      const appOption = parametersList.find((p: { name: string; }) => p.name === 'app');
      tempEmptyFields.parameterValue = { id: appOption?.id, name: appOption?.name, disabled: true };
      tempEmptyFields.equationValue = { id: 0, name: appOption?.operations?.[0] }
      const { actions = [], equations = [] } = getActionsAndEquations(
        appOption?.id,
        cardItem.optionsData,
        null
      );
      actionsAndEquations.actions = actions;
      actionsAndEquations.equations = equations;
    }

    tempConditions.push({
      ...tempEmptyFields,
      id: tempConditions.length,
      parameters: {
        parametersList: parametersList.map((p: { id: any; name: any; }) => ({ id: p.id, name: p.name })),
        equationsList: actionsAndEquations.equations,
        actionsList: actionsAndEquations.actions,
      },
      showOr: tempConditions.length ? true : false,
    });

    cardItem.policyConditions = tempConditions;
    setPolicyCardData(cardItems);
  };

  const handleJoinCondition = async (
    cardItemId: number,
    rowId: number,
    conditionBlockId?: string,
  ) => {
    const cardItems = [...policyCardData];
    const cardItem = cardItems[cardItemId];
    const tempConditions = [...cardItem.policyConditions];
    const conditions = [];
    tempConditions.slice(0, rowId).forEach((condition: any) => {
      conditions.push(condition);
    });

    const condition = {
      ...tempConditions[rowId],
      allowanceValue: ['and'],
      showJoin: false,
    };
    conditions.push(condition);
    var parameters = tempConditions[rowId]?.parameters?.parametersList?.filter(
      (parameter: any) =>
        parameter.name !== tempConditions[rowId]?.parameterValue?.name,
    );
    if (conditionBlockId) {
      var paramValue = parameters?.find(
        (param: any) => param.id == conditionBlockId,
      );
      if (paramValue) {
        conditions.push({
          ...emptyFields,
          allowanceValue: [],
          id: conditions.length,
          parameters: {
            parametersList: parameters,
            equationsList: [],
            actionsList: [],
          },
        });
      } else {
        const condition = {
          ...tempConditions[rowId],
          allowanceValue: [],
          showJoin: true,
        };
        conditions.splice(rowId, 1);
        conditions.push(condition);

        setSnackBarMessage({
          title:
            policyBuilderMessages.SNACKBAR_MESSAGES
              .CONDITION_NOT_APPLICABLE_TITLE,
          subtitle:
            policyBuilderMessages.SNACKBAR_MESSAGES
              .CONDITION_NOT_APPLICABLE_SUBTITLE,
        });
        onOpenSnackbar();
      }
    } else {
      conditions.push({
        ...emptyFields,
        id: conditions.length,
        parameters: {
          parametersList: parameters,
          equationsList: [],
          actionsList: [],
        },
      });
    }

    tempConditions.length > rowId + 1 &&
      tempConditions.slice(rowId + 1).map((condition) => {
        conditions.push({
          ...condition,
          id: condition.id + 1,
        });
      });
    cardItem.policyConditions = conditions;
    setPolicyCardData(cardItems);
    if (conditionBlockId && paramValue !== -1) {
      handlePolicyChange(cardItemId, rowId + 1, paramValue);
    }
  };

  const getAllParameters = (conditionsData: any = {}) => {
    const parametersList: any = [];
    Object.keys(conditionsData)?.forEach((key: string) => {
      parametersList.push({
        id: key,
        name: conditionsData[key]?.attributes?.displayName,
        operations: conditionsData[key]?.operations,
        actions: conditionsData[key]?.actions
      });
    });
    return parametersList;
  };

  const handleDeleteRow = (cardItemId: number, id: number) => {
    const cardItems = [...policyCardData];
    const cardItem = cardItems[cardItemId];
    const tempConditions = [...cardItem.policyConditions];
    if (id === 0) {
      if (tempConditions.length === 1) {
        cardItem.policyConditions = [];
      } else {
        if (tempConditions[1]?.showOr) {
          const conditions = [];
          conditions.push({
            ...tempConditions[1],
            showOr: false,
            id: 0,
          });
          tempConditions.length > 2 &&
            tempConditions
              .slice(2)
              .map((condition) =>
                conditions.push({ ...condition, id: condition.id - 1 }),
              );
          cardItem.policyConditions = conditions;
        } else {
          if (!tempConditions[0]?.showJoin) {
            changeDropdownOptions(1, 0, tempConditions);
          }
          const conditions: any[] = [];
          tempConditions
            .slice(1)
            .forEach((condition) =>
              conditions.push({ ...condition, id: condition.id - 1 }),
            );
          cardItem.policyConditions = conditions;
        }
      }
    } else {
      if (tempConditions[id]?.showOr && !tempConditions[id]?.showJoin) {
        changeDropdownOptions(id + 1, id, tempConditions);
      } else if (!tempConditions[id]?.showOr) {
        var i = id;
        while (i > 0 && !tempConditions[i]?.showOr) {
          i -= 1;
        }
        changeDropdownOptions(i, id, tempConditions);
      }
      const conditions = [...tempConditions.slice(0, id - 1)];
      if (!tempConditions[id - 1]?.showJoin) {
        if (
          (tempConditions.length > id + 1 && tempConditions[id + 1]?.showOr) ||
          tempConditions.length === id + 1
        ) {
          conditions.push({
            ...tempConditions[id - 1],
            showJoin: true,
            allowanceValue: null,
          });
        } else {
          conditions.push(tempConditions[id - 1]);
        }
      } else {
        conditions.push(tempConditions[id - 1]);
      }
      if (tempConditions.length > id + 1) {
        if (tempConditions[id]?.showOr && !tempConditions[id]?.showJoin) {
          conditions.push({
            ...tempConditions[id + 1],
            id: id,
            showOr: true,
          });
        } else {
          conditions.push({ ...tempConditions[id + 1], id: id });
        }
      }
      tempConditions.length > id + 2 &&
        tempConditions
          .slice(id + 2)
          .map((condition) =>
            conditions.push({ ...condition, id: condition.id - 1 }),
          );
      cardItem.policyConditions = conditions;
    }
    if (!isSaveEnabled) {
      var flag = 0;
      cardItem.policyConditions.map((condition: any) => {
        if (
          condition.policyValueErrorMessage &&
          condition.policyValueErrorMessage !== ''
        ) {
          flag = 1;
        }
      });
      if (!flag) {
        setSaveEnabled(true);
      }
    }
    setPolicyCardData(cardItems);
  };

  const changeDropdownOptions = (
    i: number, // start value
    id: number,
    tempConditions: any,
  ) => {
    while (!tempConditions[i]?.showJoin) {
      if (id !== i) {
        var parameters = tempConditions[i]?.parameters?.parametersList;
        tempConditions[id]?.parameterValue &&
          parameters.push(tempConditions[id]?.parameterValue);
        tempConditions[i] = {
          ...tempConditions[i],
          parameters: {
            ...tempConditions[i]?.parameters,
            parametersList: parameters,
          },
        };
      }

      i += 1;
    }
    if (id !== i) {
      var parameters = tempConditions[i]?.parameters?.parametersList;
      tempConditions[id]?.parameterValue &&
        parameters.push(tempConditions[id]?.parameterValue);
      tempConditions[i] = {
        ...tempConditions[i],
        parameters: {
          ...tempConditions[i]?.parameters,
          parametersList: parameters,
        },
      };
    }
  };

  const handleDeleteCard = (cardId: number) => {
    const cardItems = [...policyCardData];
    if (cardId === cardItems.length - 1) cardItems.splice(cardId, 1);
    else {
      cardItems.splice(cardId, 1);
      for (var i = cardId; i < cardItems.length; i++) {
        cardItems[i].id = i;
      }
    }
    if (cardItems.length === 0) {
      handleSelectPolicyBlock(false);
    }
    if (!isSaveEnabled) {
      var flag = 0;
      for (var i = 0; i < cardItems.length; i++) {
        const tempConditions = [...cardItems[i]?.policyConditions];
        for (var j = 0; j < tempConditions.length; j++) {
          const condition = tempConditions[j];
          if (
            condition.policyValueErrorMessage &&
            condition.policyValueErrorMessage !== ''
          ) {
            flag = 1;
          }
          if (flag) {
            break;
          }
        }
        if (flag) {
          break;
        }
      }
      if (!flag) {
        setSaveEnabled(true);
      }
    }
    setPolicyCardData(cardItems);
  };

  const handleCopyCard = (cardId: number) => {
    const cardItems = JSON.parse(JSON.stringify(policyCardData));
    cardItems.push({ ...cardItems[cardId], id: cardItems.length });
    setPolicyCardData(cardItems);
  };

  const handleSaveCard = async (cardId: number) => {
    const cardDetails = policyCardData[cardId];
    await PolicyService.addPolicy(cardDetails);
  };

  const getPolicyConditonBlocks = (policyConditions: any, optionsData: any) => {
    const conditions: IPolicyCondition[] = [];
    var i = 0;
    var flag = 0;

    while (i < policyConditions.length) {
      if (flag === 1) {
        if (!policyConditions?.[i]?.showJoin) {
          flag = 1;
        } else {
          flag = 0;
        }
        conditions[conditions.length - 1]?.conditionBlock?.push({
          name: optionsData[policyConditions?.[i]?.parameterValue?.id]?.name,
          operator: policyConditions?.[i]?.equationValue?.name,
          value: formatTime(
            policyConditions?.[i]?.parameterValue?.name,
            policyConditions?.[i]?.policyValue,
            'append',
          ),
          action: policyConditions?.[i]?.allowanceValue,
        });
        i += 1;
        continue;
      } else {
        if (!policyConditions?.[i]?.showJoin) {
          conditions.push({
            type: 'compound',
            conditionBlock: [
              {
                name: optionsData[policyConditions?.[i]?.parameterValue?.id]?.name,
                operator: policyConditions?.[i]?.equationValue?.name,
                value: formatTime(
                  policyConditions?.[i]?.parameterValue?.name,
                  policyConditions?.[i]?.policyValue,
                  'append',
                ),
                action: policyConditions?.[i]?.allowanceValue,
              },
            ],
          });
          flag = 1;
        } else {
          conditions.push({
            type: 'simple',
            conditionBlock: [
              {
                name: optionsData[policyConditions?.[i]?.parameterValue?.id]?.name,
                operator: policyConditions?.[i]?.equationValue?.name,
                value: formatTime(
                  policyConditions?.[i]?.parameterValue?.name,
                  policyConditions?.[i]?.policyValue,
                  'append',
                ),
                action: policyConditions?.[i]?.allowanceValue,
              },
            ],
          });
        }
        i += 1;
      }
    }
    return conditions;
  };

  const formatTime = (
    parameterValue: string,
    policyValue: string,
    type: 'append' | 'remove',
  ) => {
    var value = policyValue;
    if (parameterValue?.toLowerCase().includes('time')) {
      value =
        type === 'append'
          ? policyValue
            ?.replaceAll(' AM', ':00 AM')
            ?.replaceAll(' PM', ':00 PM')
          : policyValue
            ?.replaceAll(':00 AM', ' AM')
            ?.replaceAll(':00 PM', ' PM');
    }
    return value;
  };

  const getPolicyConditionList = (policyComponent: any) => {
    const condition: IPolicyCondition[] = getPolicyConditonBlocks(
      policyComponent?.policyConditions,
      policyComponent?.optionsData,
    );
    return condition;
  };

  const getPolicyRequestContent = () => {
    return policyCardData.map((policyComponent: any) => {
      const policyContent: IPolicyContent = {
        policyBlockType: policyComponent?.type,
        defaultAction: policyComponent?.defaultAction?.name,
        conditionList: getPolicyConditionList(policyComponent),
      };
      return policyContent;
    });
  };

  const getAddPolicyRequest = (name: string) => {
    let addPolicyRequest: IAddPolicyRequest = {
      policyName: name,
      policyContent: getPolicyRequestContent(),
    };
    return addPolicyRequest;
  };

  const handleCollapseAll = () => {
    let cardItems = JSON.parse(JSON.stringify(policyCardData));
    cardItems = cardItems.map((cardItem: any) => {
      return { ...cardItem, expanded: !isExpanded };
    });
    setPolicyCardData(cardItems);
    setIsExpanded(!isExpanded);
  };

  const handleDropPolicyBlock = async (index: number) => {
    const cardItems = JSON.parse(JSON.stringify(policyCardData));
    const options = await getAllConditionsDataOfPolicyBlock(index);
    const defaultActions = policyBlockItemResponse[
      index
    ]?.defaultActionOptions?.map((action: any, index: number) => {
      return { id: index, name: action };
    });
    cardItems.push({
      ...policyBlockItemResponse[index],
      id: cardItems.length,
      policyBlockId: index,
      optionsData: options,
      defaultActionOptions: defaultActions,
      conditionsActionsListData: getAllPolicyConditionsAndActions(options),
    });
    setPolicyCardData(cardItems);
    setFocusedCardId(cardItems.length - 1);
    setPolicyConditionsActionsLists(
      cardItems[cardItems.length - 1]?.conditionsActionsListData,
    );
  };

  const getAllConditionsDataOfPolicyBlock = async (
    policyBlockId: string | number,
  ) => {
    const conditionIdsList = await PolicyService.getPolicyBlockConditionMembers(
      policyBlockId,
    );
    const data: any = {};
    const promises = conditionIdsList.map(async (conditionId: string) => {
      const conditionResponse = await PolicyService.getConditionById(
        conditionId,
      );
      data[conditionId] = {
        name: conditionResponse.name,
        operations: conditionResponse.operations,
        actions: conditionResponse.actions.filter(
          (action: string) => action.toLowerCase() !== 'and',
        ),
        attributes: conditionResponse.attributes,
      };
    });
    await Promise.all(promises);
    return data;
  };

  const handleDropConditions = (
    index: number, // cardId
    conditionId: string, // condition block id
    rowId?: number, // condition row id
  ) => {
    // TODO : handle adding conditions according to drop value.

    const cardItems = [...policyCardData];
    const cardItem = cardItems[index];
    if (Object.keys(cardItem?.optionsData || {})?.includes(conditionId)) {
      if (rowId !== undefined) {
        handleJoinCondition(index, rowId, conditionId);
      } else {
        const tempConditions: any = [...cardItem.policyConditions];
        const { equations, actions } = getActionsAndEquations(
          conditionId,
          cardItem.optionsData,
          cardItem.defaultAction ? cardItem.defaultAction.name : null,
        );
        const parameters = getAllParameters(cardItem.optionsData);
        tempConditions.push({
          ...emptyFields,
          id: tempConditions.length,
          showOr: tempConditions.length ? true : false,
          parameterValue: {
            id: conditionId,
            name: cardItem.optionsData[conditionId]?.attributes?.displayName,
          },
          policyValue: null,
          parameters: {
            parametersList: parameters.filter(
              (item: any) => item.id !== conditionId,
            ),
            equationsList: equations,
            actionsList: actions,
          },
        });
        cardItem.policyConditions = tempConditions;
        setPolicyCardData(cardItems);
      }
    } else {
      setSnackBarMessage({
        title:
          policyBuilderMessages.SNACKBAR_MESSAGES
            .CONDITION_NOT_APPLICABLE_TITLE,
        subtitle:
          policyBuilderMessages.SNACKBAR_MESSAGES
            .CONDITION_NOT_APPLICABLE_SUBTITLE,
      });
      onOpenSnackbar();
    }
  };

  const handleClickCard = (cardItemId: number) => {
    setFocusedCardId(cardItemId);
    setPolicyConditionsActionsLists(
      policyCardData[cardItemId]?.conditionsActionsListData,
    );
  };

  const getAllPolicyConditionsAndActions = (conditionsData: any) => {
    // let lists = await PolicyService.getPolicyLists();
    const lists: any = [];
    const conditions: any = [];
    Object.keys(conditionsData)?.forEach((key: string) => {
      conditions.push({
        type: 'condition',
        children: conditionsData[key]?.attributes?.displayName,
        handleDragStart: (event: any) => {
          event.dataTransfer.setData('text', event.target.id);
        },
        elementId: `${DRAGGABLE_POLICY__ITEMS_ID.POLICY_CONDITION_ID}_${key}`,
      } as IPolicyBlockProps);
    });

    const actionsSet: Set<string> = new Set();
    Object.keys(conditionsData)?.forEach((key: string) => {
      conditionsData[key]?.actions?.forEach((action: string) =>
        actionsSet.add(action),
      );
    });
    const actions: any = [];
    Array.from(actionsSet).forEach((action: string, index: number) => {
      actions.push({
        type: 'action',
        children: action,
        handleDragStart: (event: any) => {
          event.dataTransfer.setData('text', event.target.id);
        },
        elementId: `${DRAGGABLE_POLICY__ITEMS_ID.POLICY_ACTION_ID}_${action}`,
      } as IPolicyBlockProps);
    });

    // TODO: Integrate this with lists of the policy block.
    // lists = lists.map((item: any, index: number) => {
    //   return {
    //     type: 'list',
    //     children: item
    //     handleDragStart: (event: any) => {
    //       event.dataTransfer.setData('text', event.target.id);
    //     },
    //     elementId: `${DRAGGABLE_POLICY__ITEMS_ID.POLICY_ACTION_ID}_${index}`,
    //   } as IPolicyBlockProps;
    // });
    return [...conditions, ...actions, ...lists];
  };

  return {
    policyCardData,
    userGroupDropdown,
    applicationDropdown,
    urlDropdown,
    handleAddCondition,
    handleCopyCard,
    handleDeleteCard,
    handleDeleteRow,
    handlePolicyChange,
    handlePolicyValueDropdownChange,
    handlePolicyValueTextChange,
    handleSaveCard,
    handleToggleCollapse,
    handleEquationChange,
    handleCollapseAll,
    handleDropPolicyBlock,
    handleDropConditions,
    isSnackbarOpen,
    onOpenSnackbar,
    onCloseSnackbar,
    getAddPolicyRequest,
    focusedCardId,
    policyConditionsActionsLists,
    handleClickCard,
    snackBarMessage,
    handlePolicyActionsChange,
    handleChangeTimeValue,
    initializePolicyCardData,
    isSaveEnabled,
    handleJoinCondition,
  };
};

export default usePolicyBuilder;
