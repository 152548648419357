import React, { useState } from 'react';
import {
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import CloseIcon from '@mui/icons-material/Close';
import SearchField from '../../molecules/SearchInput';
import helpMessages from '../../../core-utils/messages/index';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { FAQType, HelpFAQType } from '../../../core-utils/helpFAQs';
import HelpMinimizedDetailed from '../../molecules/HelpMinimizedDetailed';
import HelpMinimizedList from '../../molecules/HelpMinimizedList';
import Draggable from 'react-draggable';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import HTMLRenderer from '../HTMLRenderer';
import HelpService from '../../../api-service/help-service/Helpservice';
import Config from '../../../../config/config';
import { getSearchHelpDetails } from '../../../core-utils/Helper/helper';
import LoadingAnimation from '../../atoms/LoadingAnimation';
import IconFromSvg from '../../atoms/IconFromSvg';
import ClearIcon from '../../../../public/assets/close-inactive.svg';

const { SEARCH_PLACEHOLDER, HEADING } = helpMessages;

interface IHelpMinimizedProps {
  helpDetails: HelpFAQType;
  handleClose?: () => void;
  allHelpFaqs: HelpFAQType;
}

const HelpMinimized = ({
  helpDetails,
  handleClose,
  allHelpFaqs,
}: IHelpMinimizedProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<HelpFAQType>(helpDetails);
  const [showDescription, setShowDescription] = useState<boolean>(false);
  const [descriptionDetails, setDescriptionDetails] = useState<FAQType>(
    helpDetails.faqs[0],
  );
  const [questionId, setQuestionId] = useState<string | number>();
  const [htmlContent, setHtmlContent] = useState<any>();
  const [searchValue, setSearchValue] = useState('');

  const getHtmlContent = async (idOfQuestion: string | number) => {
    const detailHtmlContent = await HelpService.getHtmlContent(idOfQuestion);
    setHtmlContent(detailHtmlContent);
  };

  const handleItemClick = (id: number, idOfQuestion: number | string) => {
    setDescriptionDetails(helpDetails.faqs[id]);
    setQuestionId(idOfQuestion);
    setShowDescription(true);
    getHtmlContent(idOfQuestion);
  };

  const handleBackClick = () => {
    setShowDescription(false);
    setHtmlContent('');
  };

  const handleOpenInNewTab = () => {
    //showDescription && window.open(`${ROUTES.HELP}/${questionId}`, '_blank');
    showDescription &&
      window.open(
        `${Config.DASHBOARD_API_URL}help/htmlFile/${questionId}`,
        '_blank',
      );
  };

  const handleChange = (event: any) => {
    const searchText = event.target.value;
    setSearchValue(searchText);
    if (searchText.trim() === '') {
      setSearchResults(helpDetails);
    } else {
      setSearchResults(getSearchHelpDetails(searchText, allHelpFaqs));
    }
  };

  const handleClear = () => {
    setSearchValue('');
    setSearchResults(helpDetails);
  };

  return (
    <Draggable>
      <Paper
        sx={{
          width: 344,
          height: 624,
          padding: 2,
          borderRadius: '5px',
          border: `1px solid ${EXTRA_COLORS.lightGrayishBlue}`,
          background: theme.palette.background.default,
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
        }}
        color="primary"
      >
        <Grid container spacing={6} direction="column">
          <Grid
            item
            container
            direction="row"
            justifyContent="space-between"
            sx={{ pt: 2 }}
          >
            <Grid item>
              <IconButton sx={{ p: 0, ml: 4 }}>
                <DragIndicatorIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" component="span">
                {HEADING}
              </Typography>
              {showDescription &&
                <IconButton sx={{ p: 0, ml: 2 }}>
                  <OpenInNewIcon
                    fontSize="small"
                    sx={{ color: theme.palette.primary.main }}
                    onClick={handleOpenInNewTab}
                  />
                </IconButton>
              }
            </Grid>
            <Grid item>
              <IconButton sx={{ p: 0, mr: 4 }} onClick={handleClose}>
                <CloseIcon />
              </IconButton>

            </Grid>
          </Grid>
          {!showDescription && (
            <Grid item>
              <SearchField
                value={searchValue}
                placeholder={SEARCH_PLACEHOLDER}
                sx={{ width: '90%', pl: 4, pr: 4 }}
                handleChange={handleChange}
                endAdornment={
                  searchValue.trim() !== '' && (
                    <InputAdornment
                      position="end"
                      sx={{
                        cursor: 'pointer',
                        paddingRight: theme.spacing(3.5),
                      }}
                      onClick={handleClear}
                    >
                      <IconFromSvg path={ClearIcon} alt="clear" />
                    </InputAdornment>
                  )
                }
              />
            </Grid>
          )}
          {showDescription ? (
            <>
              <Grid item>
                <IconButton
                  onClick={handleBackClick}
                  sx={{
                    padding: 0,
                    // margin: theme.spacing(4, 0),
                    '&:hover': {
                      backgroundColor: 'inherit',
                    },
                  }}
                >
                  <ArrowBackIosNewIcon color="primary" />
                  <Typography
                    variant="body2"
                    color={theme.palette.primary.main}
                  >
                    {helpMessages.BACK}
                  </Typography>
                </IconButton>
              </Grid>
              <Divider
                variant="fullWidth"
                sx={{ mt: 6, ml: 10, width: theme.spacing(65) }}
              />
              {htmlContent && (
                <Grid
                  item
                  container
                  sx={{
                    mt: 0,
                    //flexWrap: 'wrap',
                    //display: 'flex',
                    maxHeight: 450,
                    //height: '100%',
                    maxWidth: 380,
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                  }}
                >
                  {/* <HelpMinimizedDetailed
                  handleBackClick={handleBackClick}
                  faqDetails={descriptionDetails}
                /> */}
                  <HTMLRenderer html={htmlContent} isMinimized={true} />
                </Grid>
              )}
            </>
          ) : (
            <>
              <HelpMinimizedList
                helpDetails={searchResults}
                handleItemClick={handleItemClick}
              />
            </>
          )}
        </Grid>
      </Paper>
    </Draggable>
  );
};
export default HelpMinimized;
