import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Tab as MUITabItem, TabProps } from '@mui/material';

export interface ITabItemProps extends TabProps {}

const StyledTabItem = styled(MUITabItem)<ITabItemProps>(({ theme }) => ({
  textTransform: 'none',
  ...theme.typography.body2,
  color: theme.palette.grey[700],
  '&.Mui-selected': {
    color: theme.palette.common.black,
    ...theme.typography.body1,
  },
}));

const TabItem = ({ ...rest }: ITabItemProps) => {
  return <StyledTabItem {...rest} />;
};

export default TabItem;
