import axios from '../axios';
// import axios from 'axios';
import Config from '../../../config/config';

const ApplicationService = {
  createNewCredentials: async (appId: any, data: any) => {
    try {
      const credentials = await axios.post(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/connections/${appId}/creds`,
        data,
      );
      return credentials.data;
    } catch (error) {
      return { error };
    }
  },
  retriveCredsByUserIdAndAppId: async (appId: any, userId: string) => {
    try {
      const credentials = await axios.get(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/connections/${appId}/creds/${userId}`,
      );
      return credentials.data;
    } catch (error) {
      return { error };
    }
  },
  deleteCredentials: async (appId: any, userId: string) => {
    try {
      const credentials = await axios.delete(
        `${Config.BACKEND_BASE_URL}api/session/data/postgresql/connections/${appId}/creds/${userId}`,
      );
      return credentials.data;
    } catch (error) {
      return { error };
    }
  },
};
export default ApplicationService;
