import { Box, BoxProps, Typography, useTheme } from '@mui/material';
import React from 'react';
import { IDropDownItem } from '../../../core-utils/constants';
import CheckBox from '../../atoms/CheckBox';
import useCheckwithText from './index.customhook';
import DisabledCheckIcon from '../../../../public/assets/Disabled_Checkbox.svg';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IconFromSvg from '../../atoms/IconFromSvg';

export interface ICheckBoxTextItemProps extends BoxProps {
  item: IDropDownItem;
  isChecked: boolean;
  handleCheckedItem: (id: string) => void;
  isDisabled?: boolean;
  clearInputField?: () => void;
}

const CheckboxTextItem = (props: ICheckBoxTextItemProps) => {
  const theme = useTheme();
  const { item, isChecked } = props;
  const { color, handleChange } = useCheckwithText(props);
  return (
    <Box
      display="flex"
      sx={{
        color: color,
        height: theme.spacing(12.415),
        cursor: 'pointer',
        backgroundColor: theme.palette.common.white,
      }}
      alignItems="center"
      justifyContent="left"
      onClick={handleChange}
    >
      <CheckBox
        data-testid="checkbox"
        sx={{ color: theme.palette.text.disabled }}
        icon={
          props.isDisabled ? (
            <IconFromSvg
              path={DisabledCheckIcon}
              alt="disabled"
              width={21}
              height={21}
            />
          ) : (
            <CheckBoxOutlineBlankIcon sx={{ width: 26, height: 26 }} />
          )
        }
        checkedIcon={
          props.isDisabled ? (
            <IconFromSvg
              path={DisabledCheckIcon}
              alt="disabled"
              width={21}
              height={21}
            />
          ) : (
            <CheckBoxIcon sx={{ width: 26, height: 26 }} />
          )
        }
        checked={isChecked}
        disabled={props.isDisabled}
      />
      <Typography sx={{ color: { color } }}>{item.name}</Typography>
    </Box>
  );
};

export default CheckboxTextItem;
