import { createSlice, current } from '@reduxjs/toolkit';
// Define a type for the slice state
interface userDetailsState {
    username: string,
    attributes: any,
    lastActive: number,
    isLocalUser: boolean,

}
// Define the initial state using that type
const initialState: userDetailsState = {
    username: '',
    attributes: {},
    lastActive: 0,
    isLocalUser: false
};
const userDetailsSlice = createSlice({
    name: 'userDetailsSlice',
    initialState: initialState,
    reducers: {
        setUserDetails(state, action) {
            state.username = action?.payload?.username;
            state.attributes = action?.payload?.attributes;
            state.lastActive = action?.payload?.lastActive;
            state.isLocalUser = action?.payload?.attributes?.['sonet-source'] === 'local';
        },
        clearUserDetails(state) {
            state.username = '';
            state.attributes = {};
            state.lastActive = 0;
            state.isLocalUser = false;
        }
    },
});
export const userDetailsActions = userDetailsSlice.actions;
export default userDetailsSlice;