import { Box, Divider, Grid, InputAdornment, Typography } from '@mui/material';
import { addSpacesForSecretKey } from '@utils/Helper/helper';
import endUserDashboardMessages from '@utils/messages/endUserDashboard';
import onBoardingMessages from '@utils/messages/onBoarding';
import theme, { EXTRA_COLORS } from '@utils/theme';
import React from 'react';
import Button from 'src/_components/atoms/Button';
import IconFromSvg from 'src/_components/atoms/IconFromSvg';
import TextField from 'src/_components/atoms/TextField';

export interface IAuthenticationSetupFormProps {
  qrCodeImg: any;
  textKey?: string;
  authenticationData: {
    verificationCode: string;
    isVerifyButtonEnabled: boolean;
    errorMessage: string;
  };
  handleChangeVerificationCode: (e: any) => void;
  handleClickVerify: () => void;
  verificationStatus?: boolean
}
const AuthenticationSetupForm = (props: IAuthenticationSetupFormProps) => {
  const {
    qrCodeImg,
    textKey,
    authenticationData,
    handleChangeVerificationCode,
    handleClickVerify,
    verificationStatus
  } = props;

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        width: theme.spacing(178.5),
        height: 'fit-content',
        pt: 12,
        pl: 15,
        pr: 16,
        pb: 12,
        border: `1px solid ${EXTRA_COLORS.lightGrayishBlue}`,

        boxShadow: '0px 4px 40px rgba(237, 243, 250, 0.8)',
        borderRadius: 1,
      }}
    >
      <Typography variant="h3" sx={{ pb: 1 }}>
        {onBoardingMessages.AUTHENTICATION_SETUP_FORM.HEADING}
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: theme.palette.text.secondary, pb: 5 }}
      >
        {onBoardingMessages.AUTHENTICATION_SETUP_FORM.SUB_HEADING}
      </Typography>
      <Divider sx={{ border: `0.5px solid ${EXTRA_COLORS.blue_100}`, mb: 5 }} />
      <Typography variant="body1" sx={{ pb: 1 }}>
        {onBoardingMessages.AUTHENTICATION_SETUP_FORM.SCAN_CODE}
      </Typography>
      <Typography
        variant="body2"
        sx={{ color: theme.palette.text.secondary, pb: 5 }}
      >
        {onBoardingMessages.AUTHENTICATION_SETUP_FORM.SCAN_CODE_HELPER}
      </Typography>
      <IconFromSvg path={qrCodeImg} alt="qrCode" width={352} height={352} />
      <Typography
        variant="caption"
        sx={{ color: theme.palette.text.secondary, pt: -4, pb: 7, lineHeight: '1.4em' }}
      >
        If you can't scan this QR code, enter this text key [
        <span style={{ fontWeight: 'bold', fontSize: '1.2em'}}>{addSpacesForSecretKey(textKey ?? '')}</span>] instead.
      </Typography>
      <Typography variant="body1" sx={{ pb: 1 }}>
        {onBoardingMessages.AUTHENTICATION_SETUP_FORM.VERIFICATION_CODE}
      </Typography>
      <Box display="flex" flexDirection="row">
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.secondary }}
        >
          {
            onBoardingMessages.AUTHENTICATION_SETUP_FORM
              .VERIFICATION_CODE_HELPER
          }
        </Typography>
      </Box>
      <Box mt={3}>
        <TextField
          name="Verification Code"
          label="Verification Code"
          placeholder={
            onBoardingMessages.AUTHENTICATION_SETUP_FORM.TEXT_FIELD_PLACEHOLDER
          }
          autoComplete="off"
          value={authenticationData.verificationCode}
          handleChange={handleChangeVerificationCode}
          error={authenticationData.errorMessage !== ''}
          helperText={authenticationData.errorMessage}
          endAdornment={
            <InputAdornment position="end">
              <Button
                variant="contained"
                sx={{
                  mr: -2,
                  borderRadius: theme.spacing(1),
                  width: theme.spacing(29.75),
                  height: theme.spacing(10),
                }}
                disabled={!authenticationData.isVerifyButtonEnabled}
                onClick={handleClickVerify}
              >
                {
                  onBoardingMessages.AUTHENTICATION_SETUP_FORM
                    .VERIFY_BUTTON_LABEL
                }
              </Button>
            </InputAdornment>
          }
          type="number"
          sx={{
            width: '100%',
            height: theme.spacing(12),
            mt: 3,
          }}
        />
      </Box>
      {(verificationStatus && !authenticationData.errorMessage) ? (<Typography
          variant="body2"
          sx={{ ...theme.typography.caption, color: theme.palette.success.main, pt: 2}}
        >
          {onBoardingMessages.AUTHENTICATION_SETUP_FORM.VERIFICATION_SUCCESS}
        </Typography>): null}
    </Box>
  );
};

export default AuthenticationSetupForm;
