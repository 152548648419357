import { useState, useEffect } from 'react';
import AppService from '../../../api-service/app-service/AppService';
import Config from '../../../../config/config';
import UserIcon from '../../../../public/assets/total-users.svg';
import UserManagementIcon from '../../../../public/assets/user-management-active.svg';
import PolicyChangesSvgIcon from '../../../../public/assets/policies-active.svg';
import DownloadSvgIcon from '../../../../public/assets/download.svg';
import UploadSvgIcon from '../../../../public/assets/upload.svg';
import { MOCK_OVERVIEW_APPS_DATA } from '../../molecules/OverviewUsersAndApps/testData';
import { IMostActiveUserGroupsWidgetProps } from '../../../core-utils/constants';

const constructOverviewData = async (
  fromDate: String,
  toDate: String,
  prevFromDate: String,
  prevToDate: String,
  appId: String | number,
) => {
  // TODO : remove this return once we have apis
  // return MOCK_OVERVIEW_APPS_DATA;

  let result = [];

  const totalUsersCount = await AppService.getTotalUsersCount(
    fromDate,
    toDate,
    appId,
  );
  const prevTotalUsersCount = await AppService.getTotalUsersCount(
    prevFromDate,
    prevToDate,
    appId,
  );
  const totalUsersVariationCount = totalUsersCount - prevTotalUsersCount;
  result.push({
    name: 'Total Users',
    count: totalUsersCount,
    itemIcon: UserIcon,
    overviewType: totalUsersVariationCount !== 0 && 'neutral',
    variationCount: totalUsersVariationCount !== 0 && totalUsersVariationCount,
  });

  const totalUserGroupsCount = await AppService.getTotalUserGroupsCount(
    fromDate,
    toDate,
    appId,
  );
  const prevTotalUserGroupsCount = await AppService.getTotalUserGroupsCount(
    prevFromDate,
    prevToDate,
    appId,
  );
  const totalUserGroupsVariationCount =
    totalUserGroupsCount - prevTotalUserGroupsCount;
  result.push({
    name: 'Total User Groups',
    count: totalUserGroupsCount,
    itemIcon: UserManagementIcon,
    overviewType: totalUserGroupsVariationCount !== 0 && 'neutral',
    variationCount:
      totalUserGroupsVariationCount !== 0 && totalUserGroupsVariationCount,
  });
  const currentUsersCount = await AppService.getCurrentUsersCount(
    fromDate,
    toDate,
    appId,
  );
  const prevCurrentUsersCount = await AppService.getCurrentUsersCount(
    prevFromDate,
    prevToDate,
    appId,
  );
  const currentUsersVariationCount = currentUsersCount - prevCurrentUsersCount;
  result.push({
    name: 'Current Users',
    count: currentUsersCount,
    itemIcon: UserManagementIcon,
    overviewType: currentUsersVariationCount !== 0 && 'neutral',
    variationCount:
      currentUsersVariationCount !== 0 && currentUsersVariationCount,
  });
  const policyViolationsCount = await AppService.getPolicyViolationsCount(
    fromDate,
    toDate,
    appId,
  );

  result.push({
    name: 'Policy Violations',
    count: policyViolationsCount,
    itemIcon: PolicyChangesSvgIcon,
  });
  const uploadsCount = await AppService.getUploadsCount(
    fromDate,
    toDate,
    appId,
  );
  result.push({
    name: 'Uploads',
    count: uploadsCount,
    itemIcon: UploadSvgIcon,
    uploadOrDownloadInBytes: '12,048,576',
  });
  const downloadsCount = await AppService.getDownloadsCount(
    fromDate,
    toDate,
    appId,
  );
  result.push({
    name: 'Download',
    count: downloadsCount,
    itemIcon: DownloadSvgIcon,
    uploadOrDownloadInBytes: '19,048,576',
  });

  return result;
};

export const useStatsData = (
  fromDate: String,
  toDate: String,
  prevFromDate: String,
  prevToDate: String,
  appId: String | number,
) => {
  const [activeUsers, setActiveUsers] = useState([]);
  const [activeUserGroups, setActiveUserGroups] = useState<
    IMostActiveUserGroupsWidgetProps[]
  >([]);
  const [overviewData, setOverviewData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    AppService.getMostActiveUsers(fromDate, toDate, appId).then((response) => {
      const requiredUsers: any = [];
      const keys = Object.keys(response);
      const values: number[] = Object.values(response);
      keys.forEach((key, index) => {
        requiredUsers.push({ userName: key, violations: values[index] });
      });
      const filteredUsers = requiredUsers.filter(
        (user: any) => user.violations !== 0,
      );
      filteredUsers.sort((a: any, b: any) => b.violations - a.violations);
      const slicedUsers = filteredUsers.slice(0, 10);
      setActiveUsers(slicedUsers);
    });
    AppService.getMostActiveUserGroups(fromDate, toDate, appId).then(
      (response) => {
        const requiredUserGroups: IMostActiveUserGroupsWidgetProps[] = [];
        const keys = Object.keys(response);
        const values: number[] = Object.values(response);
        keys.forEach((key, index) => {
          requiredUserGroups.push({
            usergroupName: key,
            active: values[index],
          });
        });
        const filteredUserGroups = requiredUserGroups.filter(
          (group) => group.active !== 0,
        );
        filteredUserGroups.sort((a: any, b: any) => b.active - a.active);
        const slicedUserGroups = filteredUserGroups.slice(0, 7);
        setActiveUserGroups(slicedUserGroups);
      },
    );
    constructOverviewData(fromDate, toDate, prevFromDate, prevToDate, appId)
      .then((response) => setOverviewData(response))
      .finally(() => setLoading(false));
  }, [fromDate, toDate]);

  return {
    activeUsers,
    activeUserGroups,
    overviewData,
    loading,
  };
};
