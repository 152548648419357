import { AppwriteException, Client, Databases } from "appwrite";



const DATABASE_ID = '66d2171d001cdd4ae180';
const INVITATION_COLLECTION_ID = '66d4773a003b2a7be748';
const VITE_APPWRITE_URL= 'https://cloud.appwrite.io/v1';
const VITE_APPWRITE_PROJECT= '66d2131a00344f6026e3';

const client = new Client()
  .setEndpoint(VITE_APPWRITE_URL)
  .setProject(VITE_APPWRITE_PROJECT);


export const cleanEmail = (email: string) => {
  return email.replaceAll(/\./g, '-d-').replaceAll('@', '-a-').replaceAll('#', '').slice(0, 35);
}

export const addActiveUser = async ({name, email, displayName}: {name: string, email: string, displayName: string}) => {
  try {
    // add activeUser to database collection
    const databases = new Databases(client);
    return databases.createDocument('66d2171d001cdd4ae180', '66d2177500399f81424d', cleanEmail(email), {
      name,
      email,
      displayName
    })
  } catch (error) {
    const appwriteError = error as AppwriteException;
    throw new Error(appwriteError.message)
  }
}

export const getActiveUsers = async () => {
  try {
    const databases = new Databases(client);
    return databases.listDocuments('66d2171d001cdd4ae180', '66d2177500399f81424d')
  } catch (error) {
    const appwriteError = error as AppwriteException;
    throw new Error(appwriteError.message)
  }
}

export const deleteActiveUser = async (email: string) => {
  try {
    const databases = new Databases(client);
    return databases.deleteDocument('66d2171d001cdd4ae180', '66d2177500399f81424d', cleanEmail(email))
  } catch (error) {
    const appwriteError = error as AppwriteException;
    throw new Error(appwriteError.message)
  }
}

export const addMeeting = async ({meetingName, meetingUrl, invitedBy}: {meetingName: string, meetingUrl: string, invitedBy: string}) => {
  try {
    const databases = new Databases(client);
    return databases.createDocument('66d2171d001cdd4ae180', '66d22a06000e0feda83f', 'unique()', {
      meetingName,
      meetingUrl,
      invitedBy
    })
  } catch (error) {
    const appwriteError = error as AppwriteException;
    throw new Error(appwriteError.message)
  }
}

export const addUserToMeeting = async ({userId, meetingId, meetingName, meetingUrl, invitedBy}: {userId: string, meetingId: string, meetingName: string, meetingUrl: string, invitedBy: string }) => {
  try {
    const databases = new Databases(client);
    console.log("DOC ID", {docId: cleanEmail(userId), length: cleanEmail(userId).length});

    databases.getDocument(DATABASE_ID, INVITATION_COLLECTION_ID, cleanEmail(userId)).then((response) => {
      console.log("GET IS  SUCCESSFUL", response);
      return databases.updateDocument(DATABASE_ID, INVITATION_COLLECTION_ID, cleanEmail(userId), {
        userId,
        meetingId,
        meetingName,
        meetingUrl,
        invitedBy
      })
    }).catch((error) => {
      console.log("GET IS  FAILED", error);
      return databases.createDocument(DATABASE_ID, INVITATION_COLLECTION_ID, cleanEmail(userId), {
        userId,
        meetingId,
        meetingName,
        meetingUrl,
        invitedBy
      })
    })

  } catch (error) {
    const appwriteError = error as AppwriteException;
    throw new Error(appwriteError.message)
  }

}

export const getMeetings = async () => {
  try {
    const databases = new Databases(client);
    return databases.listDocuments('66d2171d001cdd4ae180', '66d22a06000e0feda83f')
  } catch (error) {
    const appwriteError = error as AppwriteException;
    throw new Error(appwriteError.message)
  }
}

export const deleteMeeting = async (meetingId: string) => {
  try {
    const databases = new Databases(client);
    return databases.deleteDocument('66d2171d001cdd4ae180', '66d22a06000e0feda83f', meetingId)
  } catch (error) {
    const appwriteError = error as AppwriteException;
    throw new Error(appwriteError.message)
  }
}

export default client;