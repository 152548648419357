const errorMessages = {
  BACK: 'Go Back',
  REFRESH: 'Refresh',
  GENERIC_ERROR: {
    HEADING: 'Looks like something went wrong!',
    TEXT: 'We are sorry for the inconvenience.',
    SUB_TEXT: 'Please refresh to try again.',
    TRY_AGAIN: 'Please try again later.',
  },
  ERROR_404: {
    HEADING: "Something isn't right!",
    TEXT: 'The requested URL was not found on this server',
    SUB_TEXT: 'Please refresh or go back to a different page',
  },
  ERROR_401: {
    HEADING: '401 Unauthorised!',
    TEXT: 'Sorry your request could not be processed',
  },
  ERROR_500: {
    HEADING: '500 Internal Server Error',
    TEXT: 'This server encountered an internal error and unable to',
    SUB_TEXT: 'complete your request. Please refresh or go back.',
  },
};
export default errorMessages;
