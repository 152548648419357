import React, { useState } from 'react';
import SearchField, { style } from '../../molecules/SearchInput';
import SearchResultsCard from '../SearchResultsCard';
import { InputAdornment } from '@mui/material';
import SearchService from '../../../api-service/search-service/SearchService';
import IconFromSvg from '../../atoms/IconFromSvg';
import ClearIcon from '../../../../public/assets/close-inactive.svg';
import theme from '../../../core-utils/theme';

type SearchBarProps = {
  placeholder: string;
  dataToSearch: string[];
  handleChange?: (event: any) => void;
  handleEnter: (searchResults: any) => void;
  handleClear: () => void;
  endAdornment?: any;
  customSearchResults?: Array<string>;
  recentSearches?: Array<string>;
  id?: string | number;
  searchSuggestions?: Array<string>;
  searchValue?: string | null;
};
const SearchBar = ({
  placeholder,
  dataToSearch,
  handleChange,
  handleEnter,
  handleClear,
  endAdornment,
  customSearchResults,
  recentSearches,
  searchSuggestions,
  searchValue,
}: SearchBarProps) => {
  const [searchResults, setSearchResults] = useState<any>([]);
  const [expand, setExpand] = useState(false);
  const [value, setValue] = useState<string | null | undefined>('');

  React.useEffect(() => {
    setValue(searchValue)
  }, [searchValue])

  const onChange = (event: any) => {
    const searchText = event.target.value;
    setValue(event.target.value);
    if (searchText === '') {
      setExpand(false);
      handleClear();
    } else {
      if (handleChange) {
        handleChange(event);
      } else {
        setSearchResults(SearchService.searchEntity(searchText, dataToSearch));
      }
      setExpand(true);
    }
  };

  const onClick = (searchValue: string) => {
    setExpand(false);
    //If redirected to details page, set the searchbar value to empty
    setValue(searchValue);
    handleEnter([searchValue]);
  };

  const onClear = () => {
    setValue('');
    setSearchResults([]);
    handleClear();
    setExpand(false);
  };

  const searchResultsCard = (
    <>
      <SearchResultsCard
        searchResults={
          searchSuggestions
            ? searchSuggestions
            : customSearchResults ?? searchResults
        }
        handleResultClick={onClick}
        recentSearches={recentSearches}
      />
    </>
  );
  const handleKeyPress = (event: any) => {
    if (event.key == 'Enter') {
      customSearchResults
        ? handleEnter(customSearchResults)
        : handleEnter(searchResults);
      setExpand(false);
    }
  };

  return (
    <>
      <SearchField
        autoComplete="off"
        id={placeholder.trim()}
        placeholder={placeholder}
        value={value}
        handleChange={onChange}
        sx={{
          ...style,
          width: '100%',
        }}
        onKeyPress={handleKeyPress}
        endAdornment={
          <>
            {value !== '' && (
              <InputAdornment
                position="end"
                sx={{ cursor: 'pointer', paddingRight: theme.spacing(3.5) }}
                onClick={onClear}
              >
                <IconFromSvg path={ClearIcon} alt="clear" />
              </InputAdornment>
            )}
            {endAdornment ? (
              endAdornment
            ) : (
              <></>
              // We can add this in future.
              // <InputAdornment position="end">
              //   <FilterButton />
              // </InputAdornment>
            )}
          </>
        }
      ></SearchField>
      {(searchResults.length > 0 ||
        (customSearchResults && customSearchResults?.length > 0)) &&
        expand &&
        searchResultsCard}
    </>
  );
};

export default SearchBar;
