import React, { useState } from 'react';
import {
  TableCell,
  Grid,
  IconButton,
  TableRow,
  Typography,
} from '@mui/material';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import CheckBox from '../../atoms/CheckBox';
import IconFromSvg from '../../atoms/IconFromSvg';
import StatsInactive from '../../../../public/assets/stats-inactive.svg';
import StatsActive from '../../../../public/assets/stats-active.svg';
import CloseInactive from '../../../../public/assets/close-inactive.svg';
import CloseActive from '../../../../public/assets/close-active.svg';
import MoreInactive from '../../../../public/assets/more-inactive.svg';
import MoreActive from '../../../../public/assets/more-active.svg';
import { AvatarGroups } from '../AvatarGroup';
import {
  AVATARS_SIZE,
  IUserGroupsAndAppsTableRow,
  TABLE_FIRST_ITEM_WIDTH,
  USERGROUPS_APPS_TABLE_GROUPS_WIDTH,
  USERGROUPS_APPS_TABLE_ICONS_WIDTH,
  USERGROUPS_APPS_TABLE_NAME_ITEM_WIDTH,
  USERGROUPS_APPS_TABLE_NO_OF_USERS_WIDTH,
} from '../../../core-utils/constants';
import DropDownMenu from '../DropDownMenu';
import {
  avatarcomponentlist,
  getChipsWithViolationType,
  isUsergroupALLUsers,
} from '../../../core-utils/Helper/helper';
import { Avatars } from '../../atoms/Avatar';
import DeleteRedSvg from '../../../../public/assets/delete-red.svg';
import DeleteOrLogoutUserPopUp from '../DeleteUserPopUp';
import messages from '../../../core-utils/messages';
import RemovePopUp from '../RemovePopUp';
import userGroupMessages from '../../../core-utils/messages/usergroup';
import appModuleMessages from '../../../core-utils/messages/app';
import userModuleMessages from '../../../core-utils/messages/user';
import ChipsGroup from '../ChipsGroup';

const UserGroupsAndAppsTableRow = ({
  name,
  logo,
  userLength,
  users,
  underTabItems,
  handleClickStats,
  handleClickMore,
  handleTableRowClick,
  target,
  tabItemName,
  showClose,
  context,
  usergroupsOfApps,
}: IUserGroupsAndAppsTableRow) => {
  const [checked, setChecked] = useState(false);
  const [statsHover, setStatsHover] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const heading =
    target === 'usergroup'
      ? messages.DELETE_USER_GROUP_HEADING
      : messages.DELETE_APPLICATION_HEADING;

  const mainText =
    target === 'usergroup'
      ? userModuleMessages.CUSTOM_REMOVE_UNDER_TABS_USER
      : appModuleMessages.CUSTOM_REMOVE_UNDER_TABS_FOR_APPS;

  const handleDropDownClick = () => {
    setIsOpen(true);
  };
  const closeDeletePopUp = () => {
    setIsOpen(false);
  };
  const ACTIONS_TABLE_ROWS = [
    {
      text: underTabItems ? messages.REMOVE_MESSAGE : messages.DELETE_MESSAGE,
      icon: DeleteRedSvg,
      activeIcon: DeleteRedSvg,
      isDanger: true,
      handleClick: handleDropDownClick,
    },
  ];
  return (
    <React.Fragment>
      <TableRow
        sx={{
          '&:hover': {
            backgroundColor: checked
              ? EXTRA_COLORS.lightBlue
              : EXTRA_COLORS.teritary_blue,
            borderLeft: checked
              ? ''
              : `2px solid ${theme.palette.primary.main}`,
          },
          backgroundColor: checked
            ? EXTRA_COLORS.lightBlue
            : theme.palette.common.white,
          boxShadow: `0 0 0 1px ${EXTRA_COLORS.lightGrayishBlue} inset`,
        }}
      >
        {/* TODO: uncomment it when we have functionality for multiple table rows select and remove next table cell */}

        {/* <TableCell align="center" width={TABLE_FIRST_ITEM_WIDTH}>
        <CheckBox onClick={() => setChecked(!checked)} checked={checked} />
      </TableCell> */}

        <TableCell width={6} sx={{ padding: 0 }}></TableCell>
        <TableCell
          sx={{ paddingLeft: 0, cursor: 'pointer' }}
          width={
            target === 'app' && context === 'user'
              ? '22.01%'
              : USERGROUPS_APPS_TABLE_NAME_ITEM_WIDTH
          }
          data-testid="table-row"
          onClick={handleTableRowClick}
        >
          <Grid container alignItems="center" sx={{ height: 35 }}>
            {logo ? <IconFromSvg width={35} path={logo} alt="logo" /> : ''}
            <Typography
              sx={{ paddingLeft: logo ? 4 : '' }}
              variant="body1"
              color={theme.palette.primary.main}
            >
              {name}
            </Typography>
          </Grid>
        </TableCell>

        {target === 'app' && context === 'user' && (
          <TableCell
            sx={{ paddingLeft: 0 }}
            width={USERGROUPS_APPS_TABLE_GROUPS_WIDTH}
            align="left"
          >
            {usergroupsOfApps && (
              <ChipsGroup
                showAllChips={false}
                displayAsGreyChips={true}
                chips={getChipsWithViolationType(usergroupsOfApps)}
                noOfChips={1}
              />
            )}
          </TableCell>
        )}

        <TableCell
          align="left"
          sx={users && { paddingLeft: 0 }}
          width={USERGROUPS_APPS_TABLE_NO_OF_USERS_WIDTH}
        >
          {target === 'app' ? null : users ? (
            <AvatarGroups
              size={AVATARS_SIZE.small}
              componentList={avatarcomponentlist(users)}
              sx={{
                paddingLeft: 0,
                '&.MuiAvatarGroup-root': {
                  justifyContent: 'flex-end',
                },
              }}
              maxAvatars={4}
            />
          ) : (
            <Avatars
              sx={{
                height: AVATARS_SIZE.large,
                width: AVATARS_SIZE.large,
                color: EXTRA_COLORS.grey,
                fontSize: 12.6,
                fontWeight: 600,
              }}
              isImage={false}
              children={userLength?.toString()}
            />
          )}
        </TableCell>
        <TableCell
          align="center"
          sx={{ paddingLeft: 0 }}
          width={USERGROUPS_APPS_TABLE_ICONS_WIDTH}
        >
          {/* TODO: Enable when stats are ready */}
          {/* <IconButton
            placeholder="stats"
            onPointerEnter={() => setStatsHover(true)}
            onPointerLeave={() => setStatsHover(false)}
            onClick={handleClickStats}
          >
            {statsHover ? (
              <IconFromSvg path={StatsActive} alt="stats-active" />
            ) : (
              <IconFromSvg path={StatsInactive} alt="stats-inactive" />
            )}
          </IconButton> */}
        </TableCell>
        <TableCell
          align="center"
          sx={{ paddingLeft: 0 }}
          width={USERGROUPS_APPS_TABLE_ICONS_WIDTH}
        >
          {underTabItems ? (
            showClose && (
              <IconButton
                placeholder="close"
                onPointerEnter={() => setIsActive(true)}
                onPointerLeave={() => setIsActive(false)}
                onClick={handleDropDownClick}
              >
                {isActive ? (
                  <IconFromSvg path={CloseActive} alt="close-active" />
                ) : (
                  <IconFromSvg path={CloseInactive} alt="close-inactive" />
                )}
              </IconButton>
            )
          ) : !isUsergroupALLUsers(name) ? (
            <DropDownMenu
              menuItems={ACTIONS_TABLE_ROWS}
              itemForDropdownMenu={
                <IconButton
                  placeholder="more"
                  onPointerEnter={() => setIsActive(true)}
                  onPointerLeave={() => setIsActive(false)}
                >
                  {isActive ? (
                    <IconFromSvg path={MoreActive} alt="more-active" />
                  ) : (
                    <IconFromSvg path={MoreInactive} alt="more-inactive" />
                  )}
                </IconButton>
              }
            />
          ) : null}
        </TableCell>
      </TableRow>
      {underTabItems ? (
        <RemovePopUp
          mainText={
            context === 'app'
              ? appModuleMessages.CUSTOM_REMOVE_UNDER_TABS_FOR_APPS
              : mainText
          }
          heading={heading.replace('Delete', 'Remove')}
          open={isOpen}
          removeItem={context === 'app' ? tabItemName : name}
          removeFrom={context === 'app' ? name : tabItemName}
          onButtonClick={handleClickMore}
          handleClose={closeDeletePopUp}
        />
      ) : (
        <DeleteOrLogoutUserPopUp
          headingText={heading}
          open={isOpen}
          content={messages.CUSTOM_DELETE_MESSAGE.replace(
            '{"The User"}',
            `"${name}"`,
          )}
          handleClose={closeDeletePopUp}
          onButtonClick={handleClickMore}
        />
      )}
    </React.Fragment>
  );
};

export default UserGroupsAndAppsTableRow;
