const userSettings = {
  profileMessages: {
    UPLOAD_IMAGE: 'Upload Image',
    NAME: 'Name',
    NAME_ERROR: 'Name cannot be empty',
    NAME_PLACEHOLDER: 'Enter name here',
    PASSWORD: 'Password',
    CHANGE_PASSWORD: 'Change Password',
    SAVE: 'Save',
  },
  changePasswordModalMessages: {
    PASSWORD_CRITERIA: 'Password does not meet the required criteria',
    CHANGE_PASSWORD: 'Change Password',
    CURRENT_PASSWORD: 'Current Password',
    CURRENT_PASSWORD_ERROR:
      'The password does not match our records. Please try again.',
    PLACEHOLDER_CURRENT_PASSWORD: 'Enter current password here',
    NEW_PASSWORD: 'New Password',
    NEW_PASSWORD_ERROR:
      'The new password cannot be the same as your current password. Please try again.',
    PLACEHOLDER_NEW_PASSWORD: 'Enter new password here',
    CONFIRM_NEW_PASSWORD: 'Confirm New Password',
    CONFIRM_NEW_PASSWORD_ERROR:
      'The new password and confirmation password do not match. Please try again.',
    PLACEHOLDER_CONFIRM_NEW_PASSWORD: 'Confirm new password here',
    REQUIRED: 'Required',
    SAVE: 'Save',
    CANCEL: 'Cancel',
  },
  sessionTabMessages: {
    CHECK_BOX_CONTENT: 'Optimized for Speed',
    INFO_MESSAGE: 'Please logout for the change to take effect',
    TOOLTIP_MESSAGE: 'Turning it off will improve resolution of text but will reduce speed',
    ERROR_MESSAGE: 'Unable to save the setting.',
    SAVE: 'Save',
  }
};
export default userSettings;
