import React from 'react';
import Box from '@mui/material/Box';
import theme from '../../../core-utils/theme';
import { Typography } from '@mui/material';
import IconFromSvg from '../../atoms/IconFromSvg';

export interface IEmptyState {
  icon: string;
  content: string;
  width?: number;
  height?: number;
  textInBold?: boolean;
}

const EmptyState = ({
  icon,
  content,
  width,
  height,
  textInBold,
}: IEmptyState) => {
  const boxWidth = width ? width : 'inherit';
  const boxHeight = height ? height : 'inherit';
  return (
    <Box
      sx={{ width: boxWidth, height: boxHeight }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={theme.spacing(2.5)}
    >
      <IconFromSvg path={icon} alt="center-icon" />
      {textInBold ? (
        <Typography
          variant="subtitle2"
          sx={{ color: theme.palette.text.secondary, paddingTop: 4 }}
        >
          {content}
        </Typography>
      ) : (
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.disabled, textAlign: 'center' }}
        >
          {content}
        </Typography>
      )}
    </Box>
  );
};

export default EmptyState;
