import React, { useEffect, lazy } from 'react';
import MainHeader from '@organisms/MainHeader';
import SideNavigationDrawer from '@organisms/SideNavigationDrawer';
import HomeTemplate from '../../templates/HomeTemplate';
import { Switch, useHistory } from 'react-router-dom';
import { ROUTES } from '@utils/routes';
import { PrivateRoute } from '@organisms/PrivateRoute';
import {
  getSideNavEndItems,
  getSideNavItems,
  getUserFullNameOrUsername,
  isAdminRole,
} from '@utils/Helper/helper';
import { connect, RootStateOrAny, useDispatch } from 'react-redux';
import { updateHeaderData } from '../../../Redux/Reducers/header';
import SettingsService from '../../../api-service/settings-service/SettingsService';
import { useAppSelector } from '../../../Redux/Store/store';

export interface IComponentOnRoute {
  path: string;
  Component: any;
}

const Home = (props: any) => {
  const history = useHistory();
  const mainHeaderData = useAppSelector(
    (state: RootStateOrAny) => state.header,
  );
  const dispatch = useDispatch();
  const { firstTimeLogin, firstTimeUserOnboardingDone, role } = props?.authStatus;
  useEffect(() => {
    if (firstTimeLogin && !firstTimeUserOnboardingDone) {
      history.push(isAdminRole(role) ? ROUTES.ONBOARDING : ROUTES.USER_ONBOARDING);
    }

  }, [firstTimeLogin, firstTimeUserOnboardingDone]);

  const HOME_COMPONENTS_ON_ROUTES: Array<IComponentOnRoute> = [
    {
      path: ROUTES.DASHBOARD,
      Component: lazy(() => import('@organisms/Dashboard')),
    },
    {
      path: ROUTES.USER_GROUPS,
      Component: lazy(() => import('@organisms/UserGroupsDashboard')),
    },
    {
      path: ROUTES.USERS,
      Component: lazy(() => import('@organisms/UsersDashboard')),
    },
    // we dont need app groups for release 1
    // {
    //   path: ROUTES.APP_GROUPS,
    //   Component: <div>App Groups</div>,
    // },
    {
      path: ROUTES.APPS,
      Component: lazy(() => import('@organisms/AppsDashboard')),
    },
    {
      path: ROUTES.POLICIES,
      Component: lazy(() => import('@organisms/PoliciesDashboard')),
    },
    {
      path: ROUTES.SESSION_RECORDINGS,
      Component: lazy(() => import('@organisms/SessionRecordingDashboard')),
    },
    {
      path: ROUTES.LOGS,
      Component: lazy(() => import('@organisms/LogsDashboard')),
    },
    {
      path: ROUTES.REPORTS,
      Component: lazy(() => import('@organisms/ReportDashboard')),
    },
    {
      path: ROUTES.MY_APPLICATIONS,
      Component: lazy(() => import('@organisms/EndUserDashboard')),
    },
    {
      path: ROUTES.SETTINGS,
      Component: lazy(() => import('@src/_components/pages/SettingsPage')),
    },
    {
      path: ROUTES.NOTIFICATIONS,
      Component: lazy(() => import('@src/_components/pages/NotificationDashboardPage')),
    },
    {
      path: ROUTES.SESSION_ACTIVE,
      Component: lazy(() => import('@organisms/ActiveSessionsDashboard')),
    },
  ];
  const sideNavItems = getSideNavItems();
  const sideNavEndItems = getSideNavEndItems();

  const initializeHeaderData = async () => {
    const accountSettingsResponse = await SettingsService.getAccountSettings();
    dispatch(
      updateHeaderData({
        userName: getUserFullNameOrUsername(),
        organizationName: accountSettingsResponse?.organizationName,
        organizationLogo: accountSettingsResponse?.organizationLogo,
        suppportEmailAddress: accountSettingsResponse?.suppportEmailAddress,
        language: accountSettingsResponse?.language,
        timeZone: accountSettingsResponse?.timeZone,
      }),
    );
  };

  const { userName, organizationName } = mainHeaderData?.entities;

  useEffect(() => {
    initializeHeaderData();
  }, []);

  return (
    <HomeTemplate
      leftNavComponent={
        <SideNavigationDrawer
          sideNavItems={sideNavItems}
          sideNavEndItems={sideNavEndItems}
        />
      }
      header={
        <MainHeader
          username={userName}
          isImage={false}
          usercompany={organizationName}
        />
      }
      content={
        <Switch>
          {HOME_COMPONENTS_ON_ROUTES.map(
            (componentOnRoute: IComponentOnRoute, index) => {
              return (
                <PrivateRoute
                  key={index}
                  exact
                  path={componentOnRoute.path}
                  component={componentOnRoute.Component}
                />
              );
            },
          )}
        </Switch>
      }
    />
  );
};

const mapStateToProps = (state: any) => ({
  authStatus: state?.token
})

export default connect(mapStateToProps)(Home);
