import * as React from 'react';
import LabelWithIcon from '../../atoms/LabelWithIcon';
import { ICell } from '../../molecules/TableHeader';
import { IUserTableBody } from '../../molecules/UserTableBody';
import UserTableRow from '../../molecules/UserTableRow';
import Table from '../Table';
import DownArrowInactive from '../../../../public/assets/down-arrow.svg';
import UpArrowInactive from '../../../../public/assets/up-arrow.svg';
export interface IUsersTableProps extends IUserTableBody {
  onNameSortClick?: any;
  underUserGroupsOrAppsTab?: boolean;
  arrow?: any;
  hideUserStatus?: boolean;
  showAppCredentialsStatus?: boolean;
}
export const getWidthsOfUserTable = (
  hideUserStatus: boolean,
  showAppCredentialsStatus: any,
  underUserGroupsOrAppsTab: any,
) => {
  if (hideUserStatus) {
    if (showAppCredentialsStatus) {
      if (underUserGroupsOrAppsTab) {
        const widths = ['28%', '0%', '52%', '15%'];
        return widths;
      } else {
        const widths = ['34%', '0%', '43.86%', '17.14%'];
        return widths;
      }
    } else {
      const widths = ['33%', '0%', '62%', '0%'];
      return widths;
    }
  } else {
    if (showAppCredentialsStatus) {
      if (underUserGroupsOrAppsTab) {
        const widths = ['28%', '10%', '42%', '15%'];
        return widths;
      } else {
        const widths = ['34%', '15%', '33.86%', '12.14%'];
        return widths;
      }
    } else {
      const widths = ['33%', '15%', '47%', '0%'];
      return widths;
    }
  }
};

const UsersTable = ({ ...rest }: IUsersTableProps) => {
  const [defaultSorted, setDefaultSorted] = React.useState(true);

  const onNameHeaderClick = () => {
    setDefaultSorted(!defaultSorted);
    rest.onNameSortClick();
  };

  const widths = getWidthsOfUserTable(
    true,
    rest.showAppCredentialsStatus,
    rest.underUserGroupsOrAppsTab,
  );

  const USERS_HEADERS = (hideUserStatus?: boolean): ICell[] => {
    return [
      {
        value: (
          <LabelWithIcon
            label="Name"
            path={defaultSorted ? DownArrowInactive : UpArrowInactive}
            alt="sort"
          />
        ),
        width: widths[0],
        onClick: onNameHeaderClick,
      },
      {
        value: hideUserStatus ? '' : 'Status',
        width: widths[1],
      },
      {
        value: 'Groups',
        width: widths[2],
      },
      {
        value: rest.showAppCredentialsStatus ? 'Application Credentials' : '',
        width: widths[3],
      },
      // TODO: Replace this when stats are ready

      // {
      //   value: !rest.underUserGroupsOrAppsTab && 'Stats',
      // },
      // options
      {
        value: '',
        width: '5%',
      },
    ];
  };
  React.useEffect(() => {
    setDefaultSorted(true);
  }, [rest.arrow]);

  return (
    <Table
      headers={USERS_HEADERS(true)}
      customTableRow={UserTableRow}
      tableData={rest.tabledata}
      inUserActivityTable={true}
      fixTableLayout={true}
    />
  );
};

export default UsersTable;
