import { Box, Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import messages from '../../../core-utils/messages';
import Button from '../../atoms/Button';
import IconFromSvg from '../../atoms/IconFromSvg';
import BackArrowIcon from '../../../../public/assets/back-arrow.svg';
import DeleteRedIcon from '../../../../public/assets/delete-red.svg';
import { EXTRA_COLORS } from '../../../core-utils/theme';
import { useHistory } from 'react-router';
import Tabs from '../Tabs';
import UserGroupDetailTabItem from '../UserGroupDetailsTabItem';
import {
  GLOBAL_LABELS,
  IUserGroupDetails,
  IUserGroupsAndAppsTableRow,
} from '../../../core-utils/constants';

import UserGroupUsersTabItem from '../UserGroupUsersTabItem';
import DeleteOrLogoutUserPopUp from '../../molecules/DeleteUserPopUp';
import UserGroupService, {
  IListUserGroupsResponse,
} from '../../../api-service/user-group-service/UserGroupService';
import { ROUTES } from '../../../core-utils/routes';
import { IUserTableRow } from '../../molecules/UserTableRow';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AppsOfUserGroupTabItem from '../AppsOfUserGroupTabItem';
import LoadingAnimation from '../../atoms/LoadingAnimation';
import UserGroupStatsTabItem from '../UserGroupStatsTabItem';
import { IPoliciesTableRowProps } from '../../molecules/PoliciesTableRow';
import PoliciesUserGroupTabItem from '../PoliciesUserGroupTabItem';
import AppService from '../../../api-service/app-service/AppService';
import { constructAppCards } from '../AppsOfUserTabItem/index.hook';
import { ApplicationSelectCardProps } from '../../molecules/ApplicationSelectCard';
import UsersCredentialsTabItem from '../UsersCredentialsTabItem';

interface IUserGroupDetailsDashboardProps {
  userGroupDetails: IUserGroupDetails;
  actualUserGroupResponse?: IListUserGroupsResponse;
  users?: IUserTableRow[];
  appDetails?: IUserGroupsAndAppsTableRow[];
  getUsersOfUserGroup?: any;
  updateAppsOfUserGroup?: any;
  updatePoliciesOfUserGroup?: any;
  policies: IPoliciesTableRowProps[];
  loading?: boolean;
  getUserGroupDetails?: any;
  userGroupName: string;
}

const UserGroupDetailDashboard = (props: IUserGroupDetailsDashboardProps) => {
  const theme = useTheme();
  const history = useHistory();
  const { DELETE_USER_GROUP_HEADING, ACTIONS, BACK_TO_USER_GROUPS } = messages;
  const {
    userGroupDetails,
    actualUserGroupResponse,
    appDetails,
    users,
    policies,
    getUsersOfUserGroup,
    loading,
    updateAppsOfUserGroup,
    updatePoliciesOfUserGroup,
    getUserGroupDetails,
    userGroupName
  } = props;

  const [isOpen, setIsOpen] = useState({
    [DELETE_USER_GROUP_HEADING]: false,
  });

  const handleBackClick = () => {
    // Might change after the api integrates
    history.replace(ROUTES.USER_GROUPS);
  };

  const handleDeleteDropDownClick = () => {
    setIsOpen({
      ...isOpen,
      [DELETE_USER_GROUP_HEADING]: !isOpen[DELETE_USER_GROUP_HEADING],
    });
  };

  const closeDeletePopUp = () => {
    setIsOpen({
      ...isOpen,
      [DELETE_USER_GROUP_HEADING]: !isOpen[DELETE_USER_GROUP_HEADING],
    });
  };

  const confirmDeleteClick = async () => {
    try {
      await UserGroupService.deleteUserGroup(userGroupName);
    } catch (error) {
      console.warn(error);
    }
    closeDeletePopUp();
    history.replace(ROUTES.USER_GROUPS);
  };

  const USER_GROUP_ACTION_DROPDOWN = [
    {
      text: 'Delete',
      icon: DeleteRedIcon,
      activeIcon: DeleteRedIcon,
      isDanger: true,
      handleClick: handleDeleteDropDownClick,
    },
  ];

  const [allApps, setAllApps] = useState<ApplicationSelectCardProps[]>([]);
  const [appDetailResponse, setAppDetailResponse] = useState<any>();

  const loadAllApps = async () => {
    if (appDetailResponse) {
      return appDetailResponse;
    }
    const appsResponse = await AppService.listApps();
    setAppDetailResponse(appsResponse);
    return appsResponse;
  };

  const UserGroupTabData = [
    {
      tabId: 1,
      label: `${GLOBAL_LABELS.DETAILS}`,
      tabpanel: (
        <UserGroupDetailTabItem
          actualUserGroupResponse={actualUserGroupResponse}
          userGroupDetails={userGroupDetails}
          userGroupName={userGroupName}
          getUserGroupDetails={getUserGroupDetails}
        />
      ),
    },
    {
      tabId: 2,
      label: `${GLOBAL_LABELS.USERS}(${users?.length ?? 0})`,
      tabpanel: (
        <UserGroupUsersTabItem
          users={users}
          userGroupName={userGroupDetails?.name}
          getUsersOfUserGroup={getUsersOfUserGroup}
        />
      ),
    },
    {
      tabId: 3,
      label: `${GLOBAL_LABELS.APPLICATIONS}(${appDetails?.length ?? 0})`,
      tabpanel: (
        <AppsOfUserGroupTabItem
          appDetails={appDetails}
          usergroupId={userGroupName}
          updateAppsOfUserGroup={updateAppsOfUserGroup}
          loadAllApps={loadAllApps}
        />
      ),
    },

    {
      tabId: 4,
      label: `${GLOBAL_LABELS.POLICIES}(${policies?.length ?? 0})`,
      tabpanel: (
        <PoliciesUserGroupTabItem
          policies={policies}
          nameOfUserGroup={userGroupName}
          updatePoliciesOfUserGroup={updatePoliciesOfUserGroup}
        />
      ),
    },
    {
      tabId: 5,
      label: 'Stats',
      tabpanel: <UserGroupStatsTabItem groupId={userGroupName} />,
    },
  ];

  const getRequiredTabs = () => {
    if (process.env.RELEASE && process.env.RELEASE === 'beta1R1') {
      return UserGroupTabData.filter(
        (data) => data.tabId !== 4 && data.tabId !== 5,
      ); //disabling policy screen  and stats for release 1
    }
    return UserGroupTabData;
  };
  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: EXTRA_COLORS.bluishBackground,
        paddingLeft: theme.spacing(24.75),
        paddingRight: theme.spacing(25),
        pt: 8,
      }}
    >
      <Grid item>
        <Button
          sx={{
            '&:hover': {
              ...theme.typography.body1,
              backgroundColor: 'unset',
              color: theme.palette.primary.main,
            },
            ...theme.typography.body2,
            color: theme.palette.text.secondary,
          }}
          startIcon={<ArrowBackIcon />}
          variant="text"
          onClick={handleBackClick}
        >
          {BACK_TO_USER_GROUPS}
        </Button>
      </Grid>
      <Grid
        pt={4}
        pb={4}
        container
        item
        direction="row"
        justifyContent="space-between"
      >
        <Grid
          sx={{
            position: 'relative',
            left: '6px',
          }}
          item
          alignSelf="center"
        >
          <Typography>{userGroupName}</Typography>
        </Grid>
        {/* <Grid item>
          <DropDownMenu
            menuItems={USER_GROUP_ACTION_DROPDOWN}
            itemForDropdownMenu={
              <Button variant="outlined" endIcon={<ExpandMoreIcon />}>
                {ACTIONS}
              </Button>
            }
          />
        </Grid> */}
      </Grid>
      {loading && <LoadingAnimation />}
      <Tabs tabs={getRequiredTabs()}></Tabs>
      <DeleteOrLogoutUserPopUp
        headingText={DELETE_USER_GROUP_HEADING}
        open={isOpen[DELETE_USER_GROUP_HEADING]}
        content={messages.CUSTOM_DELETE_MESSAGE.replace(
          '{"The User"}',
          `"${userGroupName}"`,
        )}
        handleClose={closeDeletePopUp}
        onButtonClick={confirmDeleteClick}
      />
    </Box>
  );
};

export default UserGroupDetailDashboard;
