import { Grid, GridProps, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import Button from '../../atoms/Button';
import IconFromSvg from '../../atoms/IconFromSvg';
import TextField from '../../atoms/TextField';

interface ILoginProps extends GridProps {
  icon: string;
  iconHeading: string;
  subHeading: string;
  suggestionText: string;
  verificationCodeTextFieldlabel: string;
  verifyButtonText: string;
  cancelButtonText: string;
  handleTextFieldOneChange: (event: any) => void;
  onVerifyButtonClick: () => void;
  onCancelButtonClick: () => void;
  handleKeyPress?: (event: any) => void;
  error: string;
  isVerifyButtonEnabled?: boolean;
}

const UserMfaLoginForm = (props: ILoginProps) => {
  const [verificationCodeError, setVerificationCodeError] = useState('');
  const theme = useTheme();
  const {
    icon,
    iconHeading,
    subHeading,
    suggestionText,
    verificationCodeTextFieldlabel,
    verifyButtonText,
    cancelButtonText,
    handleTextFieldOneChange,
    onVerifyButtonClick,
    onCancelButtonClick,
    handleKeyPress,
    error,
    isVerifyButtonEnabled = false
  } = props;


  const onVerificationCodeChange = (event: any) => {
    if (event.target.value.length > 6) {
      setVerificationCodeError('Verification code must be 6 digits');
    } else {
      setVerificationCodeError('');
    }
    handleTextFieldOneChange && handleTextFieldOneChange(event);
  }

  const handleVerifyButtonClick = (event: any) => {
    setVerificationCodeError('');
    onVerifyButtonClick && onVerifyButtonClick();
  }


  return (
    <Grid
      sx={{
        // width: theme.spacing(123.5),
        height: theme.spacing(120),
        // boxShadow: theme.shadows[10],
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        backgroundColor: 'white'
      }}
      container
      alignItems="center"
      spacing={theme.spacing(4)}
      onKeyPress={handleKeyPress}
      borderRadius={2}
      className="w-full md:max-w-md shadow-none md:shadow-sm"
    >
      <div className="text-center relative h-full w-full px-8">

        <Grid justifyContent="center" item>
          <Grid item
            sx={{
              marginTop: theme.spacing(10),
              marginBottom: theme.spacing(6)
            }}
            className="flex justify-center"
          >
            <IconFromSvg height={36 * 2} path={icon} alt="Sonet Logo" className="drop-shadow-md h-16" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" variant="h3">
            {subHeading}
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sx={{ color: theme.palette.text.secondary, pl: 8, pr: 8, pt: 2 }}
          item
          className="pb-8"
        >
          <Typography cy-data-id="verify-code-suggestion" variant="body2" align="center">
            {suggestionText}
          </Typography>
        </Grid>
        <Grid item xs={12} alignContent="center" sx={{ pr: 2, pb: 8, position: 'relative' }}>
          <TextField
            sx={{ width: '100%' }}
            label={verificationCodeTextFieldlabel}
            name={verificationCodeTextFieldlabel}
            handleChange={onVerificationCodeChange}
            testId={verificationCodeTextFieldlabel}
            type="number"
            inputProps={{ maxLength: 6 }}
            error={!!verificationCodeError}
            autoFocus={true}
            className="my-2 w-full max-w-md md:w-full"
            cy-data-id="verify-code"
          />
          <Typography cy-data-id="verify-code-error" sx={{ color: theme.palette.error.main, bottom: 0, position: 'absolute' }}>
            {verificationCodeError}
          </Typography>
        </Grid>

        <Grid item alignContent="center" className="absolute inset-x-0 bottom-6 px-8"  >
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item xs={6}>
              <Button
                onClick={onCancelButtonClick}
                sx={{ height: theme.spacing(10), width: '100%' }}
                variant="outlined"
                color="primary"
              >
                {cancelButtonText}
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                onClick={handleVerifyButtonClick}
                sx={{ height: theme.spacing(10), width: '100%' }}
                variant="contained"
                color="primary"
                disabled={!isVerifyButtonEnabled}
              >
                {verifyButtonText}
              </Button>
            </Grid>

            <Grid item xs={12} sx={{ pt: 2, pb: 8, height: 10, textAlign: 'center' }}>
              <Typography sx={{ color: theme.palette.error.main }}>
                {error}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export default UserMfaLoginForm;
