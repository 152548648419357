import Button from '@atoms/Button';
import IconFromSvg from '@atoms/IconFromSvg';
import TextField from '@atoms/TextField';
import PasswordTextField from '@molecules/PasswordTextField';
import { Grid, GridProps, Typography, useTheme } from '@mui/material';
import Loader from '@src/_components/atoms/Loader';
import messages from '@utils/messages';
import React, { useEffect, useRef } from 'react';

interface ILoginProps extends GridProps {
  icon: string;
  iconHeading: string;
  subHeading: string;
  suggestionText: string;
  emailTextFieldlabel: string;
  email?: string;
  passwordTextFieldLabel: string;
  password?: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  handleTextFieldOneChange: (event: any) => void;
  handleTextFieldTwoChange: (event: any) => void;
  onPrimaryButtonClick: () => void;
  onSecondaryButtonClick: () => void;
  handleKeyPress?: (event: any) => void;
  error: string;
  setError?: (m: string) => void;
  isSubmitting?: boolean;
  showPassword?: boolean;
  showBlackScreen?: boolean;
  setShowBlackScreen?: (b: boolean) => void;
  onBlackScreenClick?: (event: any) => void;
}

const UserLoginForm = (props: ILoginProps) => {
  const theme = useTheme();
  const {
    icon,
    iconHeading,
    subHeading,
    suggestionText,
    emailTextFieldlabel,
    email,
    passwordTextFieldLabel,
    password,
    primaryButtonText,
    secondaryButtonText,
    handleTextFieldOneChange,
    handleTextFieldTwoChange,
    onPrimaryButtonClick,
    onSecondaryButtonClick,
    handleKeyPress,
    error,
    setError,
    isSubmitting,
    showPassword,
    showBlackScreen,
    setShowBlackScreen,
    onBlackScreenClick
  } = props;
  const passwordRef = useRef<HTMLInputElement>(null);


  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    const cleanTimeout = () => {
      timeout && clearTimeout(timeout);
      timeout = null;
    }

    if (showBlackScreen) {
      timeout = setTimeout(() => {
        if (!email || !password) {
          setError && setError(messages.AUTOFILL_TIMED_OUT);
        }
      }, 5000);
    } else {
      cleanTimeout();
    }

    return cleanTimeout;
  }, [showBlackScreen])

  useEffect(() => {
    if (error) {
      setShowBlackScreen && setShowBlackScreen(false);
    }

  }, [error]);

  useEffect(() => {
    if (showPassword && passwordRef.current) {
      if (email && !password) {
        passwordRef.current.focus();
      }
    }
  }, [showPassword]);


  return (
    <div className={`h-screen w-screen ${showBlackScreen ? 'bg-black' : ''}`} onClick={onBlackScreenClick}>
      <Grid
        sx={{
          // width: theme.spacing(120),
          height: showBlackScreen ? theme.spacing(0) : showPassword ? theme.spacing(124) : theme.spacing(100),
          // boxShadow: theme.shadows[10],
          margin: 'auto',
          backgroundColor: 'white'
        }}
        container
        direction="column"
        alignItems="center"
        spacing={theme.spacing(4)}
        onKeyPress={handleKeyPress}
        borderRadius={2}
        className={`w-full md:max-w-md shadow-none md:shadow-sm absolute inset-0 transition-transform duration-300 ease-in-out overflow-hidden`}
      >
        <form className={`text-center relative h-full w-full px-8`}>
          <Grid justifyContent="center" item>
            <Grid item
              sx={{
                marginTop: theme.spacing(10),
                marginBottom: theme.spacing(6)
              }}
              className="flex justify-center"
            >
              <IconFromSvg height={36 * 2} path={icon} alt="Sonet Logo" className="drop-shadow-md h-16" />
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h3">{subHeading}</Typography>
          </Grid>
          <Grid sx={{ color: theme.palette.text.secondary, paddingBottom: theme.spacing(4) }} item>
            <Typography variant="body2">{suggestionText}</Typography>
          </Grid>
          <Grid item>
            <TextField
              // sx={{ width: theme.spacing(109) }}
              label={emailTextFieldlabel}
              name={emailTextFieldlabel}
              handleChange={handleTextFieldOneChange}
              testId={emailTextFieldlabel}
              value={email}
              className="my-2 w-full max-w-md md:w-full"
              cy-data-id="username"
            />
          </Grid>

          <Grid item className={`${showBlackScreen ? '' : showPassword ? '' : 'hidden'}`}>
            <PasswordTextField
              // sx={{ width: theme.spacing(109) }}
              label={passwordTextFieldLabel}
              name={passwordTextFieldLabel}
              handleChange={handleTextFieldTwoChange}
              testId={passwordTextFieldLabel}
              value={password}
              cy-data-id="password"
              className="my-2 w-full max-w-md md:w-full"
              inputRef={passwordRef}
            />
          </Grid>
          <Grid
            sx={{ position: 'relative', right: theme.spacing(4) }}
            item
            alignSelf="flex-end"
          >
            {secondaryButtonText && (
              <Button
                onClick={onSecondaryButtonClick}
                variant="text"
                color="primary"
              >
                {secondaryButtonText}
              </Button>
            )}
          </Grid>
          {error && (
            <Typography
              cy-data-id="login-error"
              sx={{
                color: theme.palette.error.main,
                textAlign: 'center'
              }}
              className="text-center px-2 max-w-md mx-0 py-2"
            >
              {error}
            </Typography>
          )}
          <Grid item className="absolute inset-x-0 bottom-10 px-8">
            <Button
              onClick={onPrimaryButtonClick}
              variant="contained"
              color="primary"
              className="w-full h-12 text-md py-2 max-w-md md:w-full"
              disabled={isSubmitting}
              cy-data-id="login-btn"
            >
              {
                isSubmitting ? <Loader /> :
                  <span className="tracking-widest font-semibold text-md">{primaryButtonText}</span>}          </Button>
          </Grid>
        </form>
      </Grid>
    </div>
  );
};

export default UserLoginForm;