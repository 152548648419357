import {
  Dialog,
  DialogProps,
  Divider,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import messages from '../../../core-utils/messages';
import Button from '../../atoms/Button';
import CloseInactive from '../../../../public/assets/close-inactive.svg';
import IconFromSvg from '../../atoms/IconFromSvg';
import { IKeyValueObject } from '../../../core-utils/constants';
import { EXTRA_COLORS } from '../../../core-utils/theme';

export interface IInnerDetailModalProps extends DialogProps {
  open: boolean;
  onClose: () => void;
  variant?: 'user' | 'usergroup' | 'application';
  nameHeading: string;
  detailItemValues: IKeyValueObject[];
  clickViewDetails?: () => void;
}

const InnerDetailSummaryModal = (props: IInnerDetailModalProps) => {
  const theme = useTheme();
  const {
    open,
    nameHeading,
    onClose,
    detailItemValues,
    clickViewDetails,
  } = props;
  const modalDetailItemsList = detailItemValues.map((object, index) => {
    return (
      // remove this check once status api is available
      object.key !== 'User Status' && (
        <Grid key={index} item container direction="column">
          <Grid item>
            <Typography
              variant="caption"
              color={theme.palette.text.secondary}
              sx={{ fontSize: '14px' }}
            >
              {object.key}
            </Typography>
          </Grid>
          <Grid item sx={{ marginTop: theme.spacing(1) }}>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.text.primary }}
            >
              {object.value}
            </Typography>
          </Grid>
          <Divider
            sx={{ marginBottom: theme.spacing(4), marginTop: theme.spacing(4) }}
            orientation="horizontal"
            style={{
              border: `0.5px solid ${EXTRA_COLORS.blue_100}`,
            }}
          />
        </Grid>
      )
    );
  });
  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose}>
      <Grid
        sx={{
          width: theme.spacing(156),
          padding: theme.spacing(6),
        }}
        container
        direction="column"
      >
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: theme.spacing(5) }}
        >
          <Typography sx={{ color: theme.palette.text.primary }} variant="h3">
            {nameHeading}
          </Typography>
          <Grid sx={{ minWidth: theme.spacing(35) }} item alignItems="center">
            <Button
              sx={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                marginRight: theme.spacing(5),
              }}
              variant="text"
              onClick={clickViewDetails}
              data-testid="ViewDetailsButtons"
            >
              {messages.POPUP_VIEW_ALL_DETAILS}
            </Button>
            <IconButton onClick={onClose}>
              <IconFromSvg
                width={20}
                height={20}
                path={CloseInactive}
                alt="close"
              />
            </IconButton>
          </Grid>
        </Grid>
        {modalDetailItemsList}
      </Grid>
    </Dialog>
  );
};

export default InnerDetailSummaryModal;
