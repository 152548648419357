import * as React from 'react';
import { Checkbox as MUICheckBox, CheckboxProps } from '@mui/material';

interface ICheckBoxProps extends CheckboxProps {}

const CheckBox = ({ ...rest }: ICheckBoxProps) => {
  return <MUICheckBox {...rest} sx={{ color: 'grey.600' }} />;
};

export default CheckBox;
