import * as React from 'react';
import { Avatars } from '../../atoms/Avatar';
import { Grid, IconButton, Typography } from '@mui/material';
import IconFromSvg from '../../atoms/IconFromSvg';
import DropDownSvg from '../../../../public/assets/headerDropdown-inactive.svg';
import theme from '../../../core-utils/theme';
import { getAvatarName } from '../../../core-utils/Helper/helper';

interface IHeaderUserProfile {
  username?: string | null;
  usercompany?: string | null;
  handleUserProfileClick?: () => void;
  isImage: boolean;
  imageSrc?: string;
}
const style = {
  width: 'fit-content',
  height: 'fit-content',
  border: '2px solid rgba(237, 244, 252, 1)',
  borderRadius: theme.spacing(1.25),
  px: theme.spacing(3),
  gap: theme.spacing(2.5),
  pt: theme.spacing(1.75),
  pb: theme.spacing(1.75),
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
    cursor: 'pointer',
  },
};

const HeaderUserProfile = ({
  username,
  usercompany,
  handleUserProfileClick,
  isImage,
  imageSrc,
}: IHeaderUserProfile) => {
  const avtarName = username ? getAvatarName(username) : '';
  return (
    <Grid
      container
      direction="row"
      sx={style}
      alignItems="center"
      onClick={handleUserProfileClick}
    >
      <Grid item>
        {isImage ? (
          <Avatars isImage={isImage} src={imageSrc} />
        ) : (
          avtarName && <Avatars isImage={isImage} children={avtarName} />
        )}
      </Grid>
      <Grid item sx={{ paddingLeft: theme.spacing(2.5) }}>
        {username && (
          <Grid item>
            <Typography variant="body1">{username}</Typography>
          </Grid>
        )}
        {usercompany && (
          <Grid item>
            <Typography
              sx={{
                color: theme.palette.text.disabled,
              }}
            >
              {usercompany}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <IconButton sx={{ marginLeft: theme.spacing(8) }}>
          <IconFromSvg path={DropDownSvg} alt="dropdown" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default HeaderUserProfile;
