import React, { useState } from 'react';
import { Grid, IconButton, Paper, Typography } from '@mui/material';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import { FAQType } from '../../../core-utils/helpFAQs';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';

interface IFaqListItemProps {
  faqItem: FAQType;
  id: number;
  questionId: number;
  handleItemClick: (id: number, questionId: number | string) => void;
}

const FAQListItem = ({
  faqItem,
  id,
  handleItemClick,
  questionId,
}: IFaqListItemProps) => {
  const [isHovered, setHovered] = useState(false);

  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => handleItemClick(id, questionId)}
    >
      <Paper
        sx={{
          background: isHovered ? EXTRA_COLORS.skyBlue : 'white',
          minHeight: 44,
          border: `1px solid ${EXTRA_COLORS.lightGrayishBlue}`,
          borderRadius: '3px',
          cursor: 'pointer',
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={10}>
            <Typography
              variant="body2"
              sx={{
                padding: 2,
                color: isHovered
                  ? theme.palette.common.white
                  : theme.palette.text.secondary,
              }}
            >
              {faqItem.title}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton>
              <ChevronRightOutlinedIcon
                sx={{
                  color: isHovered
                    ? theme.palette.common.white
                    : EXTRA_COLORS.grey,
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default FAQListItem;
