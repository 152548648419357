import React from 'react';
import {
  Alert,
  AlertProps,
  AlertTitle,
  Snackbar,
  Typography,
} from '@mui/material';

import theme, { EXTRA_COLORS } from '../../../core-utils/theme';

export interface IAlertInSnackBarProps extends AlertProps {
  isSnackBarOpen: boolean;
  handleCloseSnackBar: () => void;
  title: string;
  subtitle?: string;
}

const AlertInSnackBar = (props: IAlertInSnackBarProps) => {
  const {
    severity,
    isSnackBarOpen,
    handleCloseSnackBar,
    title,
    subtitle,
  } = props;

  const style = {
    width: severity === 'success' ? theme.spacing(80) : theme.spacing(131.5),
    height: severity === 'success' ? theme.spacing(14.25) : theme.spacing(20),
    borderRadius: theme.spacing(1.5),
    color: theme.palette.text.disabled,
    boxShadow: theme.shadows[7],
  };

  const successStyles = {
    ...style,
    borderLeft: `1px solid ${theme.palette.success.main}`,
    backgroundColor: EXTRA_COLORS.fauxMintCream,
    border: theme.spacing(0.25) + ' solid ' + EXTRA_COLORS.lightGreenCyan,
    '	.MuiAlert-action': {
      color: EXTRA_COLORS.greenCyan,
    },
    '	.MuiAlert-icon': {
      color: theme.palette.success.main,
      height: theme.spacing(5),
    },
  };

  const errorStyles = {
    ...style,
    borderLeft: `1px solid ${EXTRA_COLORS.redBackground}`,
    backgroundColor: EXTRA_COLORS.paleRed,
    border: theme.spacing(0.25) + ' solid ' + EXTRA_COLORS.paleRed,
    '	.MuiAlert-action': {
      color: theme.palette.text.disabled,
    },
    '	.MuiAlert-icon': {
      color: EXTRA_COLORS.redBackground,
      height: theme.spacing(4.5),
    },
  };

  const warningStyles = {
    ...style,
    borderLeft: theme.spacing(0.25) + ' solid ' + EXTRA_COLORS.darkYellowBorder,
    backgroundColor: EXTRA_COLORS.lightYellowBackground,
    border: theme.spacing(0.25) + ' solid ' + EXTRA_COLORS.lightYellowBorder,
    '	.MuiAlert-action': {
      color: theme.palette.text.disabled,
    },
    '	.MuiAlert-icon': {
      color: EXTRA_COLORS.darkYellowBorder,
      height: theme.spacing(4.5),
    },
  };

  return (
    <Snackbar
      open={isSnackBarOpen}
      onClose={handleCloseSnackBar}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={3000}
    >
      <Alert
        severity={severity}
        onClose={handleCloseSnackBar}
        sx={
          severity === 'success'
            ? { ...successStyles }
            : severity === 'error'
            ? { ...errorStyles }
            : { ...warningStyles }
        }
      >
        {severity === 'success' ? (
          <Typography
            sx={{
              color: theme.palette.success.main,
              ...theme.typography.body1,
            }}
          >
            {title}
          </Typography>
        ) : (
          <>
            <AlertTitle
              sx={{
                color: theme.palette.common.black,
                fontWeight: 600,
              }}
            >
              {title}
            </AlertTitle>
            {subtitle}
          </>
        )}
      </Alert>
    </Snackbar>
  );
};

export default AlertInSnackBar;
