import { Grid, Box, Typography } from '@mui/material';
import React from 'react';
import { GLOBAL_LABELS } from '../../../core-utils/constants';
import messages from '../../../core-utils/messages';
import theme from '../../../core-utils/theme';
import LoadingAnimation from '../../atoms/LoadingAnimation';
import AppsAccessedMostWidget from '../../molecules/AppsAccessedMostWidget';
import CategoryViolationsWidget from '../../molecules/CategoryViolationsWidget';
import EmptyStateForErrors from '../../molecules/EmptyStateForErrors';
import OverviewUsersAndApps from '../../molecules/OverviewUsersAndApps';
import { useTimeSelector } from '../Dashboard/index.hook';
import DashboardTimeSelection from '../DashboardTimeSelection';
import { useStatsData } from './index.hook';

const STATS_UPDATE_PERIOD = 5;

export interface IUserStatsTabItem {
  userId: string;
  userStatus?: string;
}

const UserStatsTabItem = ({ userId, userStatus }: IUserStatsTabItem) => {
  const {
    TIME_SELECTION_ITEMS,
    fromDate,
    toDate,
    prevFromDate,
    prevToDate,
    handleDateSelect,
    dateRange,
  } = useTimeSelector();

  const {
    appsAccessed,
    categoryViolations,
    loading,
    overviewData,
    lastLoginTime,
    errorProps,
  } = useStatsData(fromDate, toDate, prevFromDate, prevToDate, userId);
  return errorProps ? (
    <EmptyStateForErrors {...errorProps} />
  ) : (
    <Grid sx={{ pt: 8, pb: 10 }} container direction="row" rowGap={5}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{ mb: 7 }}
      >
        <Box display="flex" flex-direction="row" alignItems="center">
          <Typography variant="h2" sx={{ mr: 1 }}>
            {GLOBAL_LABELS.STATS}
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: theme.palette.text.disabled, mt: 1 }}
          >
            {messages.UPDATED +
              ' ' +
              STATS_UPDATE_PERIOD +
              ' ' +
              messages.MINS_AGO}
          </Typography>
        </Box>
        <Grid item>
          <DashboardTimeSelection
            timeSelectionItems={TIME_SELECTION_ITEMS}
            handleDateSelect={handleDateSelect}
          />
        </Grid>
      </Grid>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <>
          <Grid container>
            <OverviewUsersAndApps
              overviewItems={overviewData}
              statusType={{
                status: '', // add userStatus later
                loginTime: lastLoginTime,
              }}
            />
          </Grid>
          <Grid container>
            <AppsAccessedMostWidget
              appsAccessedData={appsAccessed}
              tabContext="user"
            />
          </Grid>
          <Grid container>
            <CategoryViolationsWidget
              categoryViolationsData={categoryViolations}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default UserStatsTabItem;
