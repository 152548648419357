import React from 'react';
import { styled } from '@mui/styles';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import theme from '../../../core-utils/theme';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.text.disabled,
    height: 'fit-content',
    width: 'fit-content',
    padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
    maxWidth: theme.spacing(62),
    borderRadius: theme.spacing(1.25),
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.text.disabled,
    fontSize: 14,
    '&:before': {
      borderRadius: theme.spacing(0.5),
    },
  },
}));

export interface IPolicyIconsHelpTooltipProps {
  children: JSX.Element;
  content: string;
}
const PolicyIconsHelpTooltip = ({
  children,
  content,
}: IPolicyIconsHelpTooltipProps) => {
  return (
    <StyledTooltip arrow placement="bottom-start" title={content}>
      {children}
    </StyledTooltip>
  );
};
export default PolicyIconsHelpTooltip;
