import { Avatar, AvatarProps } from '@mui/material';
import React from 'react';
import { AVATARS_SIZE } from '../../../core-utils/constants';
import { getColorForText } from '../../../core-utils/Helper/helper';

interface IAvatarProps extends AvatarProps {
  children?: string;
  isImage: boolean;
  size?: number;
}

export const Avatars = (props: IAvatarProps) => {
  const { children, isImage, size, ...rest } = props;
  if (isImage) {
    return (
      <Avatar
        sx={{
          height: size ?? AVATARS_SIZE.large,
          width: size ?? AVATARS_SIZE.large,
        }}
        {...rest}
      />
    );
  } else {
    if (children) {
      const returnedObject = getColorForText(children);
      const backgroundColor = returnedObject['light'];
      const textColor = returnedObject['main'];

      return (
        <Avatar
          children={children}
          sx={{
            height: size ?? AVATARS_SIZE.large,
            width: size ?? AVATARS_SIZE.large,
            bgcolor: backgroundColor,
            color: textColor,
            fontSize: size ? size / 3 : 12.6,
            fontWeight: 600,
            letterSpacing: 0.8,
          }}
          {...rest}
        />
      );
    } else {
      return <Avatar {...rest} />;
    }
  }
};
