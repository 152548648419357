import React from 'react';

const IconFromSvg = ({
  path,
  alt,
  width,
  height,
  className
}: {
  path: string;
  alt: string;
  width?: number;
  height?: number;
  className?: string;
}) => {
  return (
    <img
      src={path}
      alt={alt}
      width={width ? width : 'inherit'}
      height={height ? height : 'inherit'}
      className={className || ''}
      title={alt}
    />
  );
};

export default IconFromSvg;
