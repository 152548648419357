import { Grid, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { USERS_IN_GROUPS_HEADERS } from '../../../core-utils/constants';
import { getHeight } from '../../../core-utils/Helper/helper';
import {
  useShowOrHideElement,
  useWindowSize,
} from '../../../core-utils/Hooks/index.hook';
import userModuleMessages from '../../../core-utils/messages/user';
import Button from '../../atoms/Button';
import EmptyAssignmentState from '../../molecules/NoAssignmentState';
import { IUserTableRow } from '../../molecules/UserTableRow';
import AddUser from '../AddUser';
import Table from '../Table';
import UsersTable from '../UsersTable';

interface IUsersOfAppTabItemProps {
  userDetails?: IUserTableRow[];
  appId: string | number;
  updateUsersOfApp?: any;
}

const UsersOfAppTabItem = ({
  userDetails,
  appId,
  updateUsersOfApp,
}: IUsersOfAppTabItemProps) => {
  const theme = useTheme();
  const { open, hideElement, showElement } = useShowOrHideElement();
  const [tabItemUsers, setTabItemUsers] = useState(userDetails);
  const [arrow, setArrow] = useState<boolean>(false);

  const reverseUsersList = () => {
    tabItemUsers && setTabItemUsers([...tabItemUsers].reverse());
  };

  useEffect(() => {
    setTabItemUsers(userDetails);
    setArrow(!arrow);
  }, [userDetails]);

  const { height } = useWindowSize();

  return (
    <Grid
      sx={{
        width: '100%',
        height: `calc(100% - ${theme.spacing(16.25)})`,
      }}
      container
      direction="row"
      alignItems="stretch"
      gap={6}
    >
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Typography variant="body1" color={theme.palette.text.disabled}>
          {userModuleMessages.USERS_ACCESS_MESSAGE_UNDER_APP}
        </Typography>
        <AddUser
          isOpen={open}
          onClose={hideElement}
          usergroupOrAppId={appId}
          usersAssignedToGroup={userDetails}
          context="app"
          getUsers={updateUsersOfApp}
        />
        <Button
          variant="contained"
          sx={{ height: theme.spacing(10) }}
          onClick={showElement}
        >
          {userModuleMessages.ADD_USER}
        </Button>
      </Grid>
      <Grid
        item
        sx={{
          height: tabItemUsers?.length ? getHeight(height) : 'fit-content',
        }}
        xs={12}
        container
      >
        {tabItemUsers?.length ? (
          <>
            <UsersTable
              underUserGroupsOrAppsTab={true}
              tabledata={tabItemUsers}
              onNameSortClick={reverseUsersList}
              arrow={arrow}
              hideUserStatus={true}
              showAppCredentialsStatus={true}
            />
          </>
        ) : (
          <>
            <Table headers={USERS_IN_GROUPS_HEADERS} />
            <EmptyAssignmentState
              displayText={userModuleMessages.USERS_EMPTY_STATE_UNDER_APP}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};
export default UsersOfAppTabItem;
