import React from 'react';
import { Card, Divider, Typography, Box, Stack } from '@mui/material';
import IconFromSvg from '../../atoms/IconFromSvg';
import messages from '../../../core-utils/messages';
import { SELECT_APP_CARD } from '../../../core-utils/constants';
import { EXTRA_COLORS } from '../../../core-utils/theme';
export type ApplicationSelectCardProps = {
  id: string;
  logo?: string;
  heading: string;
  content: string;
  width?: string;
  height?: string;
  disable?: boolean;
};
const ApplicationSelectCard = ({
  logo,
  heading,
  content,
  width,
  height,
  disable,
}: ApplicationSelectCardProps) => {
  const hover = false;
  const contentOnHover = messages.SELECT_APPLICATION_CARD.SELECT_CARD_HOVER_MESSAGE.replace(
    '{AppName}',
    heading,
  );
  const contentTrimmed =
    content.length > SELECT_APP_CARD.MAX_CONTENT_LENGTH
      ? content.substring(0, SELECT_APP_CARD.MAX_CONTENT_LENGTH) + '...'
      : content;
  return (
    <Card
      sx={{
        background: 'common.white',
        border: '1px solid',
        borderColor: disable ? EXTRA_COLORS.mediumGray : 'grey.400',
        boxSizing: 'border-box',
        borderRadius: 1.25,
        width: width ? width : 'inherit',
        height: height ? height : 'inherit',
        cursor: disable ? 'not-allowed' : 'pointer',
        opacity: disable ? EXTRA_COLORS.greyOut : EXTRA_COLORS.greyOutDefault,
      }}
      // onMouseEnter & onMouseLeave can be enabled later
    >
      <Stack
        direction="column"
        spacing={3}
        justifyContent="flex-start"
        sx={{ height: 'inherit', p: 3 }}
      >
        <Box p={1}>
          <IconFromSvg
            path={logo ? logo : SELECT_APP_CARD.DEFAULT_LOGO}
            alt="App Logo"
            height={SELECT_APP_CARD.LOGO_WIDTH}
            width={SELECT_APP_CARD.LOGO_HEIGHT}
          ></IconFromSvg>
        </Box>
        <Divider sx={{ color: 'grey.400' }}></Divider>
        <Stack>
          <Typography variant="subtitle2">{heading}</Typography>
          <Box
            sx={{
              overflow: 'hidden',
            }}
            data-testid="contentBox"
          >
            <Typography
              variant="caption"
              sx={{ color: hover ? 'text.disabled' : 'text.secondary' }}
            >
              {hover ? contentOnHover : contentTrimmed}
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Card>
  );
};
export default ApplicationSelectCard;
