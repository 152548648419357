import React from 'react';

export const formattedPolicyBlockListResponse = async (
  policyBlocksList: any,
) => {
  let result: {
    id: string;
    name: any;
    type: string;
    defaultActions: string[];
    conditionList: any;
  }[] = [];
  const promises = Object.keys(policyBlocksList).map(
    async (policyBlockId: string) => {
      result.push({
        id: policyBlockId,
        name: policyBlocksList[policyBlockId].name,
        type: policyBlocksList[policyBlockId].policyBlockType,
        defaultActions: policyBlocksList[policyBlockId].actions,
        conditionList: [],
      });
    },
  );
  await Promise.all(promises);
  return result;
};
