import { Divider, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import mainFooterMessages from '../../../core-utils/messages/mainFooter';
import sonetLogo from '../../../../public/assets/sonet-logo.svg';
import IconFromSvg from 'src/_components/atoms/IconFromSvg';
import { useAppSelector } from '@src/Redux/Store/store';
import { RootStateOrAny } from 'react-redux';

interface IMainFooter {
  versionRequired: boolean;
  logoRequired: boolean;
}

const MainFooter = (props: IMainFooter) => {
  const role = useAppSelector(
    (state: RootStateOrAny) => state?.token?.role,
  );
  const versionRequired = ['admin', 'superadmin'].includes(role) && props.versionRequired && process.env.VERSION;
  const logoRequired = props.logoRequired == true;

  const version = process.env.VERSION ?? '';

  return (
    <Grid container rowGap={2} sx={{ pl: 10, pr: 10, pb: 2, pt: 2 }}>
      <Grid item xs={12}>
        <Divider orientation="horizontal" variant="middle"></Divider>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        sx={{ pl: 5, pr: 5 }}
      >
        <Grid item>
          {logoRequired && (
            <Grid container className="flex justify-center items-center space-x-1">
                <IconFromSvg
                  path={sonetLogo}
                  height={24}
                  alt={''}
                  className="h-4"
                ></IconFromSvg>
                <Typography
                  variant="subtitle2"
                  fontWeight={500}
                  className="text-sonet"
                >
                  {mainFooterMessages.SONET_NAME}
                </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <Grid container className="flex justify-center items-center space-x-1">
              <Typography variant="body1" color="text.disabled">
                {mainFooterMessages.COPYRIGHT}
              </Typography>

            {versionRequired && (
                <Divider
                  orientation="vertical"
                  variant="fullWidth"
                  flexItem={true}
                  sx={{ height: '24px' }}
                ></Divider>
            )}

            {versionRequired && (
                <Typography variant="body1" color="text.disabled">
                  Version {version}
                </Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MainFooter;
