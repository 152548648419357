import appModuleMessages from './app';
import applicationPasswordManager from './applicationPasswordManager';
import dashboardMessages from './dashboard';
import helpMessages from './helpMessages';
import homeScreenMessages from './home';
import loginMessages from './loginMessages';
import logsModuleMessages from './logs';
import notificationMessages from './notification';
import onBoardingMessages from './onBoarding';
import policyMessages from './policyMessages';
import reportMessages from './report';
import searchModuleMessages from './searchModuleMessages';
import settingsMessages from './settings';
import appSetupGuideMessages from './setupGuideMessages';
import uploadManagerRow from './uploadManagerRow';
import userModuleMessages from './user';
import userGroupMessages from './usergroup';
import sessionRecordingMessages from './sessionRecordings';

//your in app text messages go here. This file can be later replaced by external source
const messages = {
  ...homeScreenMessages,
  ...userModuleMessages,
  ...searchModuleMessages,
  ...userGroupMessages,
  ...appModuleMessages,
  ...loginMessages,
  ...dashboardMessages,
  ...logsModuleMessages,
  ...policyMessages,
  ...helpMessages,
  ...uploadManagerRow,
  ...onBoardingMessages,
  ...notificationMessages,
  ...reportMessages,
  ...applicationPasswordManager,
  ...settingsMessages,
  ...sessionRecordingMessages,
  APP_SETUP_GUIDE_MESSAGES: { ...appSetupGuideMessages },
  INNER_SCREEN_TABLES: 'There are no groups assigned to this user yet.',
  PASSWORD_PARAMETERS_MESSAGE: 'Password must',
  APPLICATION_EMPTY_STATE: "You don't have any applications yet.",
  ADD_APPLICATION_MESSAGE:
    'Adding an application allows you to control access through Sonet',
  DELETE_USER: 'Delete User',
  SONET_HEADING: 'sonet.io',
  ADD_APPS_MESSAGE: 'Add apps to get the data here',
  ADD_USERS_MESSAGE: 'Add users to get the data here',
  ADD_POLICIES_MESSAGE: 'Add policies to get the data here',
  ADD_USERGROUPS_MESSAGE: 'Add user groups to get the data here',
  ADD_MORE: 'Add More',
  ADD_TO_PLAN: 'Add to Plan',
  ADDED_TO_PLAN: 'Already Added',
  REMOVE: 'Remove',
  SETUP_GUIDE_HEADING: 'Setup Guide',
  PICK_DATE_MESSAGE: 'Pick a date',
  COMING_SOON: 'Coming soon!',
  PAGE_UNDER_CONSTRUCTION: 'This page is under construction.',
  SEARCH_RESULTS: 'Search Results',
  RECENT_SEARCHES: 'Recent Searches',
  OKAY_MESSAGE: 'Ok',
  LOGOUT: 'Logout',
  SESSION_TIMED_OUT: 'Looks like your session has timed out !',
  SESSION_TIMED_OUT_DESCRIPTION:
    'You are timed-out due to inactivity or because you logged out. The login link expires every 24 hours. You can request for another link or use the same login link that was mailed to you',
  AUTOFILL_TIMED_OUT: 'Authentication failed. Please make sure Okta browser plugin is installed.',
  CREATE_POLICY_MESSAGE: 'Create a Policy',
  UPDATED: 'Updated',
  MINS_AGO: 'mins ago',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  CONIFRM_REMOVE_MESSAGE: 'Are you sure you want to continue?',
  DELETE_MESSAGE: 'Delete',
  REMOVE_MESSAGE: 'Remove',
  POPUP_VIEW_ALL_DETAILS: 'View All Details',
  STATS: 'Stats',
  ERROR_STATUS: 404,
  HELP: 'Help',
  CURRENT_PLAN: 'CURRENT PLAN',
  UPGRADE: 'Upgrade',
  LICENSE: 'LICENSE',
  VIEW_DETAILS: 'View Details',
  ADD_ONS: 'Add-Ons',
  USERS: 'Users',
  CONFIGURE_CREDS: 'Configure User Credentials',
  DELETE_CREDS: 'Delete Credentials',
  REMOVE_USER: 'Remove User',
  NO_RECORD_FOUND: 'No Records Found !!',
  GENERIC_ERROR_MESSAGE: 'Something went wrong.',
  ERROR_FILE_DOWNLOAD: 'File download failed.',
};

export default messages;
