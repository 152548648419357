const applicationPasswordManagerModalMessages = {
  MODAL_HEADER: 'Configure User Credentials',
  MODAL_SUBHEADER: 'Enter the username and password.',
  MODAL_HEADER_NOTE:
    'Note: Sharing these credentials with users gives them direct access to the application.',
  // Modal Form Messages
  USER_NAME: 'Username',
  USER_NAME_PLACEHOLDER: 'Enter username here',
  PASSWORD_CRITERIA: 'Password does not meet the required criteria',
  CHANGE_PASSWORD: 'Change Password',
  CURRENT_PASSWORD: 'Current Password',
  CURRENT_PASSWORD_ERROR:
    'The password does not match our records. Please try again.',
  PLACEHOLDER_CURRENT_PASSWORD: 'Enter password here',
  NEW_PASSWORD: 'Password',
  REQUIRED_FIELD: 'This is required field',
  PLACEHOLDER_NEW_PASSWORD: 'Enter password here',
  CONFIRM_PASSWORD: 'Confirm Password',
  CONFIRM_PASSWORD_ERROR:
    'The password and confirm password do not match. Please try again.',
  PLACEHOLDER_CONFIRM_PASSWORD: 'Confirm password here',
  REQUIRED: 'Required',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  CUSTOM_REMOVE_CREDENTIALS:
    'This action will delete the credentials for {removeItem} for user {removeFrom}',
  DELETE_CREDENTIALS : 'Delete User Credentials',
  DELETE : 'Delete',
  SFTP_CREDENTIAL : 'Configure SFTP credentials for file transfer',
  SYSTEM_CREDENTIAL : 'Use same system credentials',
  SPECIFY_SFTP_CREDENTIAL : 'Specify SFTP credentials',
  PLACEHOLDER_SFTP_PASSWORD: 'Enter SFTP password here',
  PLACEHOLDER_SFTP_CONFIRM_PASSWORD: 'Enter SFTP confirm password here',
  PLACEHOLDER_SFTP_USERNAME: 'Enter SFTP username here',
  PLACEHOLDER_SFTP_DOMAIN_NAME: 'Domain for server',
  SFTP_PASSWORD: 'SFTP Password',
  SFTP_CONFIRM_PASSWORD: 'SFTP Confirm Password',
  SFTP_USERNAME: 'SFTP Username',
  DOMAIN: 'Domain (Optional)',
  SFTP_CONFIRM_PASSWORD_ERROR:
    'The SFTP password and confirm password do not match. Please try again.',
};

export default applicationPasswordManagerModalMessages;
