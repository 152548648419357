import * as React from 'react';
import LabelWithIcon from '../../atoms/LabelWithIcon';
import { ICell } from '../../molecules/TableHeader';
import { IUserGroupsAndAppsTableBody } from '../../molecules/UserGroupsAndAppsTableBody';
import UserGroupsAndAppsTableRow from '../../molecules/UserGroupsAndAppsTableRow';
import DownArrowInactive from '../../../../public/assets/down-arrow.svg';
import UpArrowInactive from '../../../../public/assets/up-arrow.svg';
import Table from '../Table';

export interface IAppsTableProps extends IUserGroupsAndAppsTableBody {
  onLabelClick?: any;
  arrow?: any;
  hideNoOfUsers?: boolean;
  showGroups?: boolean;
}

const AppsTable = ({
  onLabelClick,
  hideNoOfUsers,
  showGroups,
  ...rest
}: IAppsTableProps) => {
  const [defaultSorted, setDefaultSorted] = React.useState(true);
  const onNameHeaderClick = () => {
    setDefaultSorted(!defaultSorted);
    onLabelClick();
  };

  const getColumns = () => {
    return showGroups
      ? [
          {
            value: (
              <LabelWithIcon
                label="Name"
                path={defaultSorted ? DownArrowInactive : UpArrowInactive}
                alt="sort"
              />
            ),
            width: '22.01%',
            onClick: onNameHeaderClick,
          },
          {
            value: 'Groups',
            width: '53.67%',
          },
        ]
      : [
          {
            value: (
              <LabelWithIcon
                label="Name"
                path={defaultSorted ? DownArrowInactive : UpArrowInactive}
                alt="sort"
              />
            ),
            width: '75.68%',
            onClick: onNameHeaderClick,
          },
        ];
  };

  const USER_GROUPS_AND_APPS_HEADERS = (
    hideNumberOfUsers?: boolean,
  ): ICell[] => {
    return [
      ...getColumns(),
      {
        value: hideNumberOfUsers ? '' : 'No. Of Users',
        width: '12%',
      },
      {
        // TODO: Replace this when stats are ready
        value: '',
        // value: 'Stats',
      },
      // options
      {
        value: '',
      },
    ];
  };

  React.useEffect(() => {
    setDefaultSorted(true);
  }, [rest.arrow]);

  return (
    <Table
      headers={USER_GROUPS_AND_APPS_HEADERS(hideNoOfUsers)}
      customTableRow={UserGroupsAndAppsTableRow}
      tableData={rest.tabledata}
      inUserActivityTable={true}
    />
  );
};

export default AppsTable;
