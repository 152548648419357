import * as React from 'react';
import { styled } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import {
  EMPTY_FUNCTION,
  NOTIFICATION_TABLE_FIRST_ITEM_WIDTH,
  TABLE_FIRST_ITEM_WIDTH,
  USER_ACTIVITY_TABLE_FIRST_ITEM_PADDING,
} from '../../../core-utils/constants';

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  ...theme.typography.body2,
  color: theme.palette.text.secondary,
  border: 'none',
}));

export interface ICell {
  value: string | React.ReactNode;
  width?: string;
  onClick?: any;
  id?: string;
}

export interface ITableHeader {
  headers: ICell[];
  inUserActivityTable?: boolean;
  isFirstColumnBlank?: boolean;
}

const TableHeader = ({ headers, inUserActivityTable, isFirstColumnBlank = true}: ITableHeader) => {
  return (
    <React.Fragment>
      {isFirstColumnBlank && (inUserActivityTable ? (
        <StyledHeaderCell
          sx={{ width: USER_ACTIVITY_TABLE_FIRST_ITEM_PADDING }}
        />
      ) : (
        <StyledHeaderCell sx={{ width: TABLE_FIRST_ITEM_WIDTH }} />
      ))}

      {headers.map((header, index) => {
        return (
          <StyledHeaderCell
            sx={{
              width: header.width,
              paddingLeft: 0,
            }}
            key={index}
            onClick={header.onClick ? header.onClick : EMPTY_FUNCTION}
          >
            {header.value}
          </StyledHeaderCell>
        );
      })}
    </React.Fragment>
  );
};

export default TableHeader;
