
import ScannerIcon from '@assets/scanner.svg';
import IconFromSvg from '@atoms/IconFromSvg';
import React from 'react';
import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';

export interface FloatingLocalDeviceMenuProps {
  onClick: () => void;
}

export default function FloatingLocalDeviceMenu({ onClick }: FloatingLocalDeviceMenuProps) {

  const mainButtonStyles = {
    backgroundColor: '#f1f5f9',
  };
  const actionButtonStyles = {
    backgroundColor: '#006ee5',
  };
  return (
    <div className="floatingMenu">
      <Fab
        mainButtonStyles={mainButtonStyles}
        icon={<IconFromSvg path={ScannerIcon} alt="scan a file" height={28} />}
        style={{
          bottom: 0,
          right: 0,
        }}
        event="hover"
        key={-1}
        alwaysShowTitle={false}
        onClick={onClick}
        text="Scan a file"
      >
      </Fab>
    </div>
  );
}
