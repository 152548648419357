const logModuleMessages = {
  LOGS_DASHBOARD: {
    HEADING: 'Logs',
    DOWNLOAD: 'Download',
    SEARCH_PLACEHOLDER: 'Search for logs',
    SEARCH: 'Search',
  },

  DOWNLOAD_LOGS: {
    HEADING: 'Download Logs',
    FIELD_LABELS: {
      FILE_NAME: 'File Name',
      FORMAT: 'Select a Format',
      FIELDS: 'Select Fields to Include',
      DATE_RANGE: 'Date Range',
    },
    BUTTON_LABELS: {
      DOWNLOAD: 'Download',
    },
    PLACEHOLDER: {
      FILE_NAME: 'Enter file name here',
      FORMAT: 'Select a format',
    },
    DOWNLOAD_SUCCESS: {
      LOGS: 'Logs',
      MESSAGE: 'Successfully downloaded',
    },
  },
  LOG_FIELDS: {
    HEADING: 'Log Fields',
    SUBHEADING: 'Choose fields to be displayed',
  },
  LOG_BUTTON_LABELS: {
    CANCEL: 'Cancel',
    SAVE: 'Save',
  },
};

export default logModuleMessages;
