import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import IconFromSvg from '../../atoms/IconFromSvg';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import Box from '@mui/material/Box';
import { IDashboardOverviewProps } from '../../../core-utils/constants';
import { getDashboardOverviewVariation } from '../../../core-utils/Helper/helper';

const style = {
  width: '100%',
  height: theme.spacing(55),
  borderRadius: theme.spacing(2.5),
  boxShadow: theme.shadows[11],
  backgroundColor: theme.palette.common.white,
};
const boxStyle = {
  width: theme.spacing(8.5),
  height: theme.spacing(8.5),
  backgroundColor: EXTRA_COLORS.alphaPrimary,
  borderRadius: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const DashboardOverview = ({
  dashboardOverviewItems,
}: {
  dashboardOverviewItems: IDashboardOverviewProps[];
}) => {
  const itemColor = (overviewType: string | undefined) => {
    if (overviewType === 'neutral') return theme.palette.primary.main;
    if (overviewType === 'positive') return theme.palette.success.main;
    else return theme.palette.error.main;
  };
  return (
    <Grid container sx={style} direction="column">
      <Grid
        item
        sx={{ paddingLeft: theme.spacing(6), paddingTop: theme.spacing(3) }}
      >
        <Typography variant="subtitle2">Overview</Typography>
      </Grid>
      <Divider sx={{ marginTop: theme.spacing(2.5) }} />
      <Grid
        item
        container
        alignItems="center"
        xs={true}
        justifyContent="space-between"
        sx={{ px: 13.5 }}
      >
        {dashboardOverviewItems.map((item) => (
          <React.Fragment key={item.id}>
            <Box
              display="flex"
              flexDirection="column"
              gap={2.5}
              width="23%"
              textAlign="center"
              // width={theme.spacing(90.26)}
            >
              <Grid item container justifyContent="center">
                <Box sx={boxStyle}>
                  <IconFromSvg
                    width={30}
                    height={30}
                    path={item.overviewItemicon}
                    alt="user-management"
                  />
                </Box>
              </Grid>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <Typography
                    sx={{ color: theme.palette.primary.main }}
                    variant="h1"
                  >
                    {item.count}
                  </Typography>
                </Grid>
                {item.itemOverviewType && (
                  <Grid item sx={{ marginTop: theme.spacing(5) }}>
                    <IconFromSvg
                      path={
                        getDashboardOverviewVariation(
                          item.variationCount,
                          item.itemOverviewType,
                        ).icon
                      }
                      alt="variation-icon"
                    />
                  </Grid>
                )}
                {item.variationCount && (
                  <Grid item>
                    <Typography
                      sx={{
                        paddingTop: theme.spacing(4.5),
                        color: itemColor(item.itemOverviewType),
                      }}
                      variant="body2"
                    >
                      {Math.abs(item.variationCount)}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item container justifyContent="center">
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.text.disabled }}
                >
                  {item.name}
                </Typography>
              </Grid>
            </Box>
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  );
};

export default DashboardOverview;
