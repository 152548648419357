import { Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import { ISwitchProps } from '../../atoms/Switch';

const useStyles: any = makeStyles({
  root: {
    width: theme.spacing(28),
    height: theme.spacing(9),
    padding: 0,
    overflow: 'hidden',
    borderRadius: theme.spacing(1.25),
  },
  switchBase: {
    padding: theme.spacing(0.25),
    '&$checked': {
      '& + $track': {
        backgroundColor: '#F2FAFF',
      },
    },
  },
  thumb: {
    backgroundColor: theme.palette.common.white,
    // border: `0.5px solid ${EXTRA_COLORS.blue_100}`,
    width: theme.spacing(14),
    height: theme.spacing(8.5),
    boxShadow: theme.shadows[14],
    borderRadius: theme.spacing(1.25),
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1)',

    '&:after': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Manrope',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '150%',
      color: theme.palette.text.primary,
      content: (checked: boolean) => (checked ? "'PM'" : "'AM'"),

      width: '100%',
      height: '100%',
    },
  },
  track: {
    borderRadius: theme.spacing(1.25),
    backgroundColor: '#F2FAFF',
    border: `1px solid ${EXTRA_COLORS.blue_100}`,
    width: '100%',
    height: '100%',
    display: 'block',
    boxShadow: 'inset 1px 1px 2px 2px #F2FAFF',

    '&:before': {
      content: (checked: boolean) => (checked ? "'AM'" : "'PM'"),
      display: 'flex',
      paddingTop: theme.spacing(2),
      paddingLeft: (checked: boolean) =>
        checked ? theme.spacing(3.75) : theme.spacing(18.75),
      fontFamily: 'Manrope',
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '150%',
      color: theme.palette.text.primary,
    },
  },
  checked: {
    color: `${theme.palette.common.white} !important`,
    transform: 'translateX(54px) !important',
  },
});

const TimeSwitch = (props: ISwitchProps) => {
  const classes = useStyles(props.checked);

  return (
    <Switch
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
};

export default TimeSwitch;
