import { Props, useEffect } from "react";
import Tracker from '@openreplay/tracker';


const RemoteMonitor = (props: { children: any; }) => {
  useEffect(() => {
    let tracker: Tracker | null = null;
    if (localStorage.getItem('monitoringOn') == 'true') {
      tracker = new Tracker({
        projectKey: "KYbd6KJH3xj53FOoo7Xx",
        __DISABLE_SECURE_MODE: process.env.NODE_ENV === 'production' ? false : true,
      });
      tracker.start();
    }

    return () => {
      if(tracker) tracker.stop();
    }
  }, [])
    return props.children;
}

export default RemoteMonitor;