import { Grid, Typography } from '@mui/material';
import React from 'react';
import IconFromSvg from '../../atoms/IconFromSvg';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import Box from '@mui/material/Box';
import { IDashboardOverviewProps } from '../../../core-utils/constants';
import { getDashboardOverviewVariation } from '../../../core-utils/Helper/helper';

const style = {
  width: '100%',
  height: theme.spacing(56),
  borderRadius: theme.spacing(2.5),
  boxShadow: theme.shadows[11],
  padding: theme.spacing(10),
  backgroundColor: theme.palette.common.white,
  opacity: EXTRA_COLORS.greyOut, //remove opacity once this api is provided
};

const boxStyle = {
  width: theme.spacing(11),
  height: theme.spacing(11),
  backgroundColor: EXTRA_COLORS.accentGreen_400,
  borderRadius: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const CurrentlyActiveUsers = ({
  currentlyActiveUsersItems,
}: {
  currentlyActiveUsersItems: IDashboardOverviewProps[];
}) => {
  const getColor = (type?: 'neutral' | 'positive' | 'negative') => {
    if (type === 'neutral') {
      return theme.palette.primary.main;
    } else if (type === 'positive') {
      return theme.palette.success.main;
    } else {
      return theme.palette.error.main;
    }
  };

  return (
    <Grid item container sx={style} direction="column">
      {currentlyActiveUsersItems.map((item) => (
        <React.Fragment key={item.id}>
          <Box display="flex" flexDirection="column" gap={2.5}>
            <Grid item container justifyContent="center">
              <Box sx={boxStyle}>
                <IconFromSvg path={item.overviewItemicon} alt="active-users" />
              </Box>
            </Grid>
            <Grid container spacing={2} justifyContent="center">
              <Grid item spacing={0}>
                <Typography
                  sx={{
                    color: EXTRA_COLORS.accentGreen_900,
                    fontSize: '42px',
                    lineHeight: '58px',
                  }}
                  variant="h1"
                >
                  {item.count}
                </Typography>
              </Grid>
              <Grid item sx={{ marginTop: theme.spacing(5.5) }}>
                {item.itemOverviewType && (
                  <IconFromSvg
                    path={
                      getDashboardOverviewVariation(
                        item.variationCount,
                        item.itemOverviewType,
                      ).icon
                    }
                    alt="variation-icon"
                  />
                )}
              </Grid>
              <Grid item>
                {item.variationCount && (
                  <Typography
                    sx={{
                      paddingTop: theme.spacing(5.5),
                      color: getColor(item.itemOverviewType),
                    }}
                    variant="body2"
                  >
                    {Math.abs(item.variationCount)}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid item container justifyContent="center">
              <Typography variant="body2" sx={{ color: EXTRA_COLORS.black }}>
                {item.name}
              </Typography>
            </Grid>
          </Box>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default CurrentlyActiveUsers;
