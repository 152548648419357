import { Grid, Box, Button } from '@mui/material';
import { useWindowSize } from '@utils/Hooks/index.hook';
import { customStyles } from '@utils/theme';
import React, { useEffect, useState } from 'react';
import UserService from '../../../api-service/user-service/UserService';
import {
  ITooltipValidationParams,
  IUserDetailsType,
  PASSWORD_PARAMETERS,
  ADMIN,
  USER_DETAILS_TAB,
  USER_STATUS_OPTIONS,
  SECONDARY_ADMIN,
} from '../../../core-utils/constants';
import {
  checkPassword,
  createPermissionDeleteRequest,
  createPermissionRequest,
  getHeight,
  isEmailValid,
  isEmptyValidation,
  isObjectValid,
  isPasswordValid,
  replacePlaceholders,
} from '../../../core-utils/Helper/helper';
import messages from '../../../core-utils/messages';
import EditDetailsModal from '../../molecules/EditDetailsModal';
import { getUserById } from '../../pages/UserDetailsPage/index.hook';
import DetailList from '../DetailList';
import errorMessages from '@src/core-utils/messages/error';
import editActive from '../../../../public/assets/edit-active.svg';
import IconFromSvg from '@src/_components/atoms/IconFromSvg';
import settingsMessages from '@src/core-utils/messages/settings';
import UpdateUserModal from '../UpdateUserModal';
import ConfirmPopUp from '@src/_components/molecules/ConfirmPopUp';
import { isUndefined } from 'cypress/types/lodash';
import { useAppSelector } from '@src/Redux/Store/store';
import { RootStateOrAny } from 'react-redux';


interface IUserDetailTabItemProps {
  userDetails: IUserDetailsType;
  username: string;
  getUserDetails?: any;
}

const {
  NAME: EDIT_NAME,
  EMAIL: EDIT_EMAIL,
  JOB_TITLE: EDIT_JOB,
  PASSWORD: EDIT_PASSWORD,
  USER_STATUS: EDIT_USER_STATUS,
  DESCRIPTION: EDIT_DESCRIPTION,
} = messages.EDIT_USER;
const {
  INVALID_EMAIL,
  INVALID_PASSWORD,
  PASSWORD_NO_MATCH,
} = messages.CREATE_USER.ERROR_MESSAGES;

const {
  TEXT_FIELD_LABELS: {
    FULL_NAME,
    USER_NAME,
    JOB_TITLE,
    PASSWORD,
    CONFIRM_PASSWORD,
    DESCRIPTION,
    USER_ROLE,
  }
} = messages.CREATE_USER;


const getUserStatusFromName = (userStatus: string | undefined) => {
  // TODO: This might change once we know how backend API sends the user status
  return USER_STATUS_OPTIONS.find((status) => status.name === userStatus);
};

const USER_DETAIL_KEYS = [
  'name',
  'email',
  'jobTitle',
  'role',
  'password',
  // 'userStatus', uncomment this when api provided
  'source',
  'createdBy',
  'createdOn',
  'description',
];

const emptyPasswordErrorDetails = {
  passwordError: '',
  confirmPasswordError: '',
};

const UserDetailsTabItem = ({
  userDetails,
  username,
  getUserDetails,
}: IUserDetailTabItemProps) => {

  const [errorMsg, setErrorMsg] = useState<string>('');
  const [successMsg, setSuccessMsg] = useState<string>('');

  const currentUser = useAppSelector(
    (state: RootStateOrAny) => state?.userDetails?.username
  );


  const {
    NAME,
    EMAIL,
    JOB_TITLE,
    PASSWORD,
    USER_STATUS,
    SOURCE,
    ROLE,
    CREATED_BY,
    CREATED_ON,
    DESCRIPTION,
  } = USER_DETAILS_TAB(userDetails.source === 'okta', userDetails?.role);

  const getObjectFromKey = (key: string) => {
    switch (key) {
      case 'email':
        return EMAIL;
      case 'jobTitle':
        return JOB_TITLE;
      case 'password':
        return PASSWORD;
      case 'userStatus':
        return USER_STATUS;
      case 'source':
        return SOURCE;
      case 'role':
        return ROLE;
      case 'createdOn':
        return CREATED_ON;
      case 'createdBy':
        return CREATED_BY;
      case 'description':
        return DESCRIPTION;
      case 'name':
      default:
        return NAME;
    }
  };

  const getDetailsListFromUserDetails = (
    userDetails: IUserDetailsType,
    handleButtonClick: any,
  ) => {
    const list = ['createdBy', 'createdOn', 'description'];
    const filteredUserDetails = USER_DETAIL_KEYS.filter(
      (value: string) => !list.includes(value),
    );
    const requiredUserDetailKeys =
      userDetails.source === 'okta' ? filteredUserDetails : USER_DETAIL_KEYS;
    const requiredUserDetails = requiredUserDetailKeys.map((key: string) => {
      const keyDetail = { ...getObjectFromKey(key), handleButtonClick, buttonLabel: '' };
      if (key !== 'password') {
        keyDetail.value = userDetails[key as keyof IUserDetailsType];
      }
      if (key === 'role') {
        if (userDetails.id === currentUser || [ADMIN].includes(userDetails?.role?.toLowerCase())) {
          keyDetail.showButton = false;
        } else {
          keyDetail.buttonLabel = [SECONDARY_ADMIN].includes(keyDetail.value?.toLowerCase()) ? "Demote from Admin" : "Promote as Admin";
          keyDetail.showButton = true;
          keyDetail.handleButtonClick = () => {
            setShowRoleChangeConfirmModal(true);
          }
        }
      }
      return keyDetail;
    });
    //Temporary fix till we get clarity on sso users
    // if (process.env.ONLY_OKTA && process.env.ONLY_OKTA === 'true') {
    //   const convertedDetails = requiredUserDetails.filter(
    //     (item) => item.label !== 'Source',
    //   );
    //   return convertedDetails;
    // }
    return requiredUserDetails;
  };

  const falsifiedModal = {
    [NAME.label]: false,
    [EMAIL.label]: false,
    [JOB_TITLE.label]: false,
    [PASSWORD.label]: false,
    [USER_STATUS.label]: false,
    [DESCRIPTION.label]: false,
  };

  const actualUserDetails = {
    [NAME.label]: userDetails.name,
    [EMAIL.label]: userDetails.email,
    [JOB_TITLE.label]: userDetails.jobTitle,
    // [USER_STATUS.label]: getUserStatusFromName(userDetails.userStatus),
    [SOURCE.label]: userDetails.source,
    [DESCRIPTION.label]: userDetails.description,
    [EDIT_PASSWORD.LABEL_1]: '',
    [EDIT_PASSWORD.LABEL_2]: '',
  };

  const [showModal, setShowModal] = useState(falsifiedModal);
  const [modalProps, setModalProps] = useState({
    isSaveDisabled: true,
    errorMessage: '',
  });
  const [updatedDetails, setUpdatedDetails] = useState<any>(actualUserDetails);
  const [passwordErrorDetails, setPasswordErrorDetails] = useState(
    emptyPasswordErrorDetails,
  );
  const [passwordTooltipParams, setPasswordTooltipParams] = useState<
    ITooltipValidationParams[]
  >(PASSWORD_PARAMETERS);

  const [openUpdateModal, setOpenUpdateModal] = useState<boolean>(false);
  const [showRoleChangeConfirmModal, setShowRoleChangeConfirmModal] = useState<boolean>(false);


  useEffect(() => {
    setUpdatedDetails(actualUserDetails);
  }, [userDetails]);

  const handleFieldChange = (name: any, event: any) => {
    if (name) {
      setUpdatedDetails((prevState: any) => ({
        ...prevState,
        [name]: event.target.value,
      }));
    }
  };

  const handleUserStatusSelect = (event: any, value: any) => {
    setUpdatedDetails((prevState: any) => ({
      ...prevState,
      [USER_STATUS.label]: value,
    }));
  };

  const handlePasswordChange = (name: string | undefined, event: any) => {
    if (name === EDIT_PASSWORD.LABEL_1) {
      setUpdatedDetails((prevState: any) => ({
        ...prevState,
        [name]: event.target.value,
      }));
      setPasswordTooltipParams(
        checkPassword(event.target.value, passwordTooltipParams),
      );
    }
    if (name === EDIT_PASSWORD.LABEL_2) {
      setUpdatedDetails((prevState: any) => ({
        ...prevState,
        [name]: event.target.value,
      }));
    }
  };

  const handleButtonClick = (name: string) => {
    setShowModal({ ...falsifiedModal, [name]: true });
    if (name === DESCRIPTION.label) {
      setModalProps({ isSaveDisabled: false, errorMessage: '' });
    }
  };

  const validateNewPassword = () => {
    if (!isPasswordValid(updatedDetails[EDIT_PASSWORD.LABEL_1])) {
      setPasswordErrorDetails({
        ...passwordErrorDetails,
        passwordError: INVALID_PASSWORD,
      });
      return false;
    } else {
      setPasswordErrorDetails({
        ...passwordErrorDetails,
        passwordError: '',
      });
      return true;
    }
  };

  const validateConfirmPassword = () => {
    if (
      updatedDetails[EDIT_PASSWORD.LABEL_1] !==
      updatedDetails[EDIT_PASSWORD.LABEL_2]
    ) {
      setPasswordErrorDetails((prevState) => ({
        ...prevState,
        confirmPasswordError: PASSWORD_NO_MATCH,
      }));
      setModalProps({ isSaveDisabled: true, errorMessage: '' });
      return false;
    } else {
      setPasswordErrorDetails((prevState) => ({
        ...prevState,
        confirmPasswordError: '',
      }));
      return true;
    }
  };

  const handleValidation = (name: string | undefined) => {
    if (name === DESCRIPTION.label) {
      setModalProps({ isSaveDisabled: false, errorMessage: '' });
      return true;
    }

    /* Username Validation */
    if(name === EMAIL.label && !isEmailValid(updatedDetails[name])) {
      setModalProps({ isSaveDisabled: true, errorMessage: INVALID_EMAIL });
      return false;
    }

    if (
      (name === NAME.label || name === JOB_TITLE.label) &&
      isEmptyValidation(updatedDetails[name])
    ) {
      setModalProps({ isSaveDisabled: true, errorMessage: '' });
      return false;
    }
    if (name === EDIT_PASSWORD.LABEL_1 && !validateNewPassword()) {
      return false;
    }
    if (
      (name === EDIT_PASSWORD.LABEL_2 || name === EDIT_PASSWORD.LABEL_1) &&
      !validateConfirmPassword()
    ) {
      return false;
    }
    if (name === USER_STATUS.label && !isObjectValid(updatedDetails[name])) {
      return false;
    }
    setModalProps({ isSaveDisabled: false, errorMessage: '' });
    return true;
  };

  const shouldSaveBeDisabled = () => {
    if (showModal[NAME.label]) {
      if (handleValidation(NAME.label)) {
        setModalProps({ isSaveDisabled: false, errorMessage: '' });
      }
    } else if(showModal[EMAIL.label]) {
      if (handleValidation(EMAIL.label)) {
        setModalProps({ isSaveDisabled: false, errorMessage: '' });
      }
    } else if (showModal[JOB_TITLE.label]) {
      if (handleValidation(JOB_TITLE.label)) {
        setModalProps({ isSaveDisabled: false, errorMessage: '' });
      }
    } else if (showModal[PASSWORD.label]) {
      if (handleValidation(EDIT_PASSWORD.LABEL_1)) {
        setModalProps({ isSaveDisabled: false, errorMessage: '' });
      }
    } else if (showModal[DESCRIPTION.label]) {
      if (handleValidation(DESCRIPTION.label)) {
        setModalProps({ isSaveDisabled: false, errorMessage: '' });
      }
    }
  };

  useEffect(() => {
    shouldSaveBeDisabled();
  }, [updatedDetails]);

  const { height } = useWindowSize();

  const handleRoleChangeRequest = () => {
    setErrorMsg('');
    setSuccessMsg('');

    const requestBody: any = {
      attributes: {
        'guac-email-address': isAdmin() ? userDetails.email : undefined,
        'guac-organizational-role': userDetails.jobTitle,
        'guac-full-name': userDetails.name || '',
        'jobRole': isAdmin() ? "" : 'Admin',
        'description': userDetails.description || '',
      },
    };
    UserService.updateUser(username, requestBody)
      .then(r => {
        const requestBody = isAdmin() ? createPermissionDeleteRequest(username) : createPermissionRequest(username);
        UserService.addPermissionToUser(username, requestBody)
          .then(resp => {
            setSuccessMsg(replacePlaceholders(isAdmin() ? settingsMessages.CHANGE_ROLE.SUCCESS_MSG.DEMOTE : settingsMessages.CHANGE_ROLE.SUCCESS_MSG.PROMOTE, { userName: username }));
            getUserDetails(username);
          });
      }).catch((e: any) => {
        console.error(`Unable to ${isAdmin() ? 'demote' : 'promote'} user`, e);
        setErrorMsg(replacePlaceholders(isAdmin() ? settingsMessages.CHANGE_ROLE.ERROR_MSG.DEMOTE : settingsMessages.CHANGE_ROLE.ERROR_MSG.PROMOTE, { errorMsg: e.message }));
      });
  }

  const isAdmin = () => [ADMIN, SECONDARY_ADMIN].includes(userDetails?.role?.toLowerCase());
  const renderErrorMessage = (msg: string) => <p className="error-msg animate-fadeOut my-2 break-words break-all text-center text-red-600">{msg}</p>;
  const renderSuccessMessage = (msg: string) => <p className="success-msg animate-fadeOut w-full my-2 break-words break-all text-green-700 text-center text-sm">{msg}</p>;


  return (
    <>
      {openUpdateModal && <UpdateUserModal 
          showModal={openUpdateModal}
          username={username}
          handleToggleModal={()=> setOpenUpdateModal(false)}
          users={userDetails}
          getUserDetails={getUserDetails}
      />}

      {
        showRoleChangeConfirmModal
          ? <ConfirmPopUp
            open={showRoleChangeConfirmModal}
            content={replacePlaceholders(settingsMessages.CHANGE_ROLE.CONFIRM_MSG, { action: (isAdmin() ? settingsMessages.CHANGE_ROLE.ACTION.DEMOTE : settingsMessages.CHANGE_ROLE.ACTION.PROMOTE)?.toLowerCase(), userName: username })}
            headingText={isAdmin() ? settingsMessages.CHANGE_ROLE.TITLE.DEMOTE : settingsMessages.CHANGE_ROLE.TITLE.PROMOTE}
            handleClose={() => setShowRoleChangeConfirmModal(false)}
            onButtonClick={handleRoleChangeRequest}
            buttonText={isAdmin() ? settingsMessages.CHANGE_ROLE.ACTION.DEMOTE : settingsMessages.CHANGE_ROLE.ACTION.PROMOTE}
          />
          : null
      }

      <Grid container className='mb-4'>
        <Grid item xs={12} sx={{ px: 2 }} onClick={() => setOpenUpdateModal(true)}>
          <IconFromSvg
            path={editActive}
            alt={settingsMessages.ICON_TEXT.EDIT}
            className='float-right'
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          ...customStyles.scrollBar,
          overflowY: 'scroll',
          height: 'fit-content',
          maxHeight: getHeight(height, 'details'),
        }}
      >
        <DetailList
          detailsList={getDetailsListFromUserDetails(
            userDetails,
            handleButtonClick,
          )}
        />
        {errorMsg ? renderErrorMessage(errorMsg) : successMsg ? renderSuccessMessage(successMsg) : null}
      </Box>
    </>
  );
};

export default UserDetailsTabItem;
