import { IOverviewUsersAndApps } from '.';
import AppSvgIcon from '../../../../public/assets/app-management-active.svg';
import UserManagementIcon from '../../../../public/assets/user-management-active.svg';
import UserIcon from '../../../../public/assets/total-users.svg';
import DurationSvgIcon from '../../../../public/assets/duration-active.svg';
import PolicyChangesSvgIcon from '../../../../public/assets/policies-active.svg';
import DownloadSvgIcon from '../../../../public/assets/download.svg';
import UploadSvgIcon from '../../../../public/assets/upload.svg';

export const MOCK_OVERVIEW_USERS_DATA: IOverviewUsersAndApps[] = [
  {
    name: 'Average Daily Applications',
    count: 14,
    itemIcon: AppSvgIcon,
    overviewType: 'neutral',
    variationCount: 2,
  },
  {
    name: 'Policy Violations',
    count: 54,
    itemIcon: PolicyChangesSvgIcon,
    overviewType: 'neutral',
    variationCount: 5,
  },
  {
    name: 'Number Of Logins',
    itemIcon: UserManagementIcon,
    count: 20,
  },
  {
    name: 'Total Sessions',
    count: 54,
    itemIcon: DurationSvgIcon,
    overviewType: 'neutral',
    variationCount: 5,
  },
  {
    name: 'Average session duration',
    count: 12,
    itemIcon: DurationSvgIcon,
  },
  {
    name: 'Total sessions duration',
    count: 20,
    itemIcon: DurationSvgIcon,
  },
];

export const MOCK_OVERVIEW_APPS_DATA: IOverviewUsersAndApps[] = [
  {
    name: 'Total Users',
    count: 54,
    itemIcon: UserIcon,
    overviewType: 'neutral',
    variationCount: 5,
  },
  {
    name: 'Total User Groups',
    count: 14,
    itemIcon: UserManagementIcon,
    overviewType: 'neutral',
    variationCount: 2,
  },
  {
    name: 'Current Users',
    count: 54,
    itemIcon: UserManagementIcon,
    overviewType: 'neutral',
    variationCount: 5,
  },
  {
    name: 'Policy Violations',
    count: 20,
    itemIcon: PolicyChangesSvgIcon,
  },
  {
    name: 'Uploads',
    count: 12,
    itemIcon: UploadSvgIcon,
    uploadOrDownloadInBytes: '12,048,576',
  },
  {
    name: 'Download',
    count: 24,
    itemIcon: DownloadSvgIcon,
    uploadOrDownloadInBytes: '19,048,576',
  },
];
