import React, { useEffect, useState } from 'react';
import LabelWithIcon from 'src/_components/atoms/LabelWithIcon';
import DownArrowInactive from '@assets/down-arrow.svg';
import UpArrowInactive from '@assets/up-arrow.svg';

import UserService from 'src/api-service/user-service/UserService';
import AppService from 'src/api-service/app-service/AppService';
import ApplicationService from 'src/api-service/application-service/ApplicationService';
import UsersAppsTable from '../UsersAppCredsTable';
import LoadingAnimation from 'src/_components/atoms/LoadingAnimation';
import { useAppDispatch } from '@src/Redux/Store/store';
import { connectionsActions } from '@src/Redux/Reducers/connections';

export interface UsersAppCredentials {
  appId: string;
  appName: string;
  appLogo?: string;
  isCredGenerated: boolean;
  editCredentialsHandler: () => void;
  deleteCredentialsHandler: () => void;
}
export interface UsersCredentialsTabItemProps {
  userId: string;
  userFullName?: string;
}

const UsersCredentialsTabItem = ({
  userId,
  userFullName,
}: UsersCredentialsTabItemProps) => {
  const [defaultSorted, setDefaultSorted] = React.useState(true);
  const [reloadData, setReloaddata] = useState(false);
  const [appsdata, setAppsData] = React.useState<any[]>([]);
  const onNameHeaderClick = () => {};
  const [isLoading, setIsloading] = useState(false);
  const dispatch = useAppDispatch();
  
  const TABLEHEADERS = [
    {
      value: (
        <LabelWithIcon
          label="Name"
          path={defaultSorted ? DownArrowInactive : UpArrowInactive}
          alt="sort"
        />
      ),
      width: '50%',
      onClick: onNameHeaderClick,
    },
    {
      value: 'Application Credentials',
      width: '30%',
    },
    {
      value: '',
    },
    {
      value: '',
    },
  ];

  const getCredsDetailsByAppIdAndUid = async (appId: string, uId: any) => {
    const userCredDetails: any = await ApplicationService.retriveCredsByUserIdAndAppId(
      appId,
      uId,
    );

    return userCredDetails.hasOwnProperty('username') &&
      userCredDetails.hasOwnProperty('password')
      ? true
      : false;
  };

  const reverseAppList = () => {
    appsdata && setAppsData([...appsdata].reverse());
  };

  useEffect(() => {
    setIsloading(true);
    const getAllAppsForUser = async () => {
      const effectiveAppsOfUser: any = await UserService.getEffectiveAppsOfUser(
        userId,
      );
      const allAppsOfUser = await UserService.getAppsOfUser(userId);
      const userGroupsAppsOfUser: any = await UserService.getUserGroupsAppsOfUser(
        userId,
      );
      const effectiveAppIds = Object.keys(
        effectiveAppsOfUser.connectionPermissions,
      );
      const allAppsIds = Object.keys(allAppsOfUser.connectionPermissions);

      const filteredEffectiveApps = effectiveAppIds.filter(
        (appId) => !allAppsIds.includes(appId),
      );
      const userGroupsAppsOfUserIds = Object.keys(userGroupsAppsOfUser);
      allAppsIds.forEach((appId) => {
        if (userGroupsAppsOfUserIds.includes(appId)) {
          filteredEffectiveApps.push(appId);
        }
      });

      const filteredApps = allAppsIds.filter(
        (id) => !userGroupsAppsOfUserIds.includes(id),
      );
      const appsDataTableDetails = [
        ...filteredApps,
        ...filteredEffectiveApps,
      ].map(async (appId) => {
        const appDetails = await AppService.getAppById(appId);
        dispatch(connectionsActions.setconnectionDetails(appDetails));
        if (['true'].includes(appDetails.attributes.displayFlag)) {
          return {
            appId: appDetails.identifier,
            appName: appDetails.name,
            appLogo: appDetails.attributes.thumbnail,
            userEmail: userId,
            tabItemName: userFullName,
            isCredGenerated: await getCredsDetailsByAppIdAndUid(
              appDetails.identifier,
              userId,
            ),
            editCredentialsHandler: editCredHandler,
            deleteCredentialsHandler: deleteCredHandler,
          };
        }
        return null;
      });
      const data = await Promise.all(appsDataTableDetails);
      setAppsData(data.filter((el) => el != null));
      setIsloading(false);
    };
    getAllAppsForUser();
  }, [reloadData]);

  const editCredHandler = (reload: boolean) => {
    reload ? setReloaddata((prevState) => (prevState = !prevState)) : null;
  };

  const deleteCredHandler = async (appId: string, userid: string) => {
    if (appId && userId) {
      setIsloading(true);
      const apiResponse = await ApplicationService.deleteCredentials(
        appId,
        userId,
      );
      apiResponse.hasOwnProperty('userId')
        ? setReloaddata((prevState) => (prevState = !prevState))
        : null;
      setIsloading(false);
    }
  };

  return isLoading ? (
    <LoadingAnimation compact={true} />
  ) : (
    <UsersAppsTable
      headers={TABLEHEADERS}
      tableData={appsdata}
      onLableclick={reverseAppList}
      arrow={true}
    />
  );
};

export default UsersCredentialsTabItem;
