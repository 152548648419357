const appSetupGuideMessages = {
  appType: {
    heading: 'Application Type',
    content:
      'The type of application will determine which settings you can configure from the applilcation details',
  },
  customDomain: {
    heading: 'Custom Domain',
    content: 'Domain of your Gmail URL. Format: (https://{sub-domain}.github.com/)',
  },
  urlParameters: {
    heading: 'URL Parameters',
    validationList: [
      'May have wildcard characters',
      'May have IP address',
      'Remove session IDs from URLs',
      'Use hyphens to separate keywords',
      'Fix your URL canonicalization',
      'URL must not contain spaces',
    ],
  },
};

export default appSetupGuideMessages;
