import { Box } from '@mui/material';
import { useWindowSize } from '@utils/Hooks/index.hook';
import { customStyles } from '@utils/theme';
import React, { useEffect, useState } from 'react';
import UserGroupService, {
  IListUserGroupsResponse,
} from '../../../api-service/user-group-service/UserGroupService';
import {
  IUserGroupDetails,
  USER_GROUP_DETAILS_TAB,
} from '../../../core-utils/constants';
import {
  getHeight,
  isEmptyValidation,
} from '../../../core-utils/Helper/helper';
import messages from '../../../core-utils/messages';
import EditDetailsModal from '../../molecules/EditDetailsModal';

import DetailList from '../DetailList';

interface IUserGroupDetailsTabItem {
  userGroupDetails: IUserGroupDetails;
  actualUserGroupResponse?: IListUserGroupsResponse;
  userGroupName?: string;
  getUserGroupDetails?: any;
}

const {
  GROUP_NAME,
  NUMBER_OF_USERS,
  CREATED_BY,
  DESCRIPTION,
  SOURCE,
  CREATED_ON,
} = USER_GROUP_DETAILS_TAB;

const {
  EDIT_GROUP_NAME,
  EDIT_DESCRIPTION,
  DESCRIPTION_LABEL,
  GROUP_NAME_LABEL,
} = messages.EDIT_USER_GROUP;

const fetchObjectFromKeys = (key: string) => {
  switch (key) {
    case 'name':
      return GROUP_NAME;
    case 'numberOfUsers':
      return NUMBER_OF_USERS;
    case 'createdBy':
      return CREATED_BY;
    case 'description':
      return DESCRIPTION;
    case 'source':
      return SOURCE;
    case 'createdOn':
      return CREATED_ON;
    default:
      return '';
  }
};
// Add new keys here when we get a new detail item in the API
export const USER_GROUP_DETAIL_KEYS = [
  'name',
  'numberOfUsers',
  'createdBy',
  'createdOn',
  'description',
  'source',
];

const fetchDetailsListFromUserGroupResponse = (
  userGroupDetailResponse: IUserGroupDetails,
  handleButtonClick: (label: string) => void,
) => {
  return USER_GROUP_DETAIL_KEYS.map((key: string) => {
    const keyDetail = { ...fetchObjectFromKeys(key), handleButtonClick };
    if (key === 'name') {
      keyDetail.value = userGroupDetailResponse.name;
    } else if (key === 'numberOfUsers') {
      keyDetail.value = userGroupDetailResponse.numberOfUsers;
    } else if (key === 'createdBy') {
      keyDetail.value = userGroupDetailResponse.createdBy;
    } else if (key === 'description') {
      keyDetail.value = userGroupDetailResponse.description;
    } else if (key === 'source') {
      keyDetail.value = userGroupDetailResponse.source;
    } else if (key === 'createdOn') {
      keyDetail.value = userGroupDetailResponse.createdOn;
    }
    // Add new if-else comparisons as we add new detail items in the API.
    return keyDetail;
  });
};

const modalsFlagList = {
  [GROUP_NAME.label]: false,
  [DESCRIPTION.label]: false,
};

const UserGroupDetailTabItem = (props: IUserGroupDetailsTabItem) => {
  const {
    userGroupDetails,
    actualUserGroupResponse,
    userGroupName,
    getUserGroupDetails,
  } = props;
  const initialUserGroupDetails = {
    [GROUP_NAME.label]: userGroupDetails.name,
    [DESCRIPTION.label]: userGroupDetails.description,
  };
  const [updatedUserGroupData, setUpdatedUserGroupData] = useState(
    initialUserGroupDetails,
  );

  const [editModals, setEditModals] = useState(modalsFlagList);
  const [modalProps, setModalProps] = useState({
    isSaveDisabled: true,
  });
  useEffect(() => {
    setUpdatedUserGroupData(initialUserGroupDetails);
  }, [userGroupDetails]);

  const handleTextFieldChange = (name: any, event: any) => {
    if (name) {
      setUpdatedUserGroupData((prevState) => ({
        ...prevState,
        [name]: event.target.value,
      }));
    }
  };

  const handleDetailValidation = (name: string | undefined) => {
    // Add all validation and SaveEnabled state logic here .
    if (
      name === GROUP_NAME.label &&
      isEmptyValidation(updatedUserGroupData[name])
    ) {
      setModalProps({ isSaveDisabled: true });
      return false;
    }
    setModalProps({ isSaveDisabled: false });
    return true;
  };

  const shouldSaveBeDisabled = () => {
    if (editModals[GROUP_NAME.label]) {
      if (handleDetailValidation(GROUP_NAME.label)) {
        setModalProps({ isSaveDisabled: false });
      }
    }
    if (editModals[DESCRIPTION.label]) {
      if (handleDetailValidation(DESCRIPTION.label)) {
        setModalProps({ isSaveDisabled: false });
      }
    }
  };

  useEffect(() => {
    shouldSaveBeDisabled();
  }, [updatedUserGroupData]);

  const handleDetailUpdate = async (name: string | undefined) => {
    if (handleDetailValidation(name)) {
      // Might get changed during integration when we get the exact body
      const requestBody = {
        identifier: userGroupDetails.name,
        attributes: {
          'sonet-source': userGroupDetails.source,
          description: updatedUserGroupData[DESCRIPTION.label],
          disabled: null,
        },
      };
      try {
        await UserGroupService.updateUserGroup(
          userGroupDetails.name,
          requestBody,
        );
      } catch (error) {
        console.warn(error);
      } finally {
        setEditModals(modalsFlagList);
        setModalProps({ isSaveDisabled: true });
        handleCloseModal();
        getUserGroupDetails(userGroupName);
      }
    }
  };

  const handleCloseModal = () => {
    setEditModals(modalsFlagList);
    setModalProps({ isSaveDisabled: true });
    setUpdatedUserGroupData(initialUserGroupDetails);
  };

  const handleButtonClick = (name: string) => {
    setEditModals({ ...editModals, [name]: true });
    if (name === DESCRIPTION.label) {
      setModalProps({ isSaveDisabled: false });
    }
  };

  const commonProps = {
    isSaveDisabled: modalProps.isSaveDisabled,
    handleSaveClick: handleDetailUpdate,
    handleFieldChange: handleTextFieldChange,
    handleValidation: handleDetailValidation,
    onClose: handleCloseModal,
  };

  const { height } = useWindowSize();

  return (
    <React.Fragment>
      <Box
        sx={{
          ...customStyles.scrollBar,
          overflowY: 'scroll',
          height: 'fit-content',
          maxHeight: getHeight(height, 'details'),
        }}
      >
        <DetailList
          detailsList={fetchDetailsListFromUserGroupResponse(
            userGroupDetails,
            handleButtonClick,
          )}
        />
      </Box>

      <EditDetailsModal
        open={editModals[GROUP_NAME.label]}
        fieldType="TEXTFIELD"
        textFieldValue={updatedUserGroupData[GROUP_NAME.label]}
        name={GROUP_NAME.label}
        heading={EDIT_GROUP_NAME}
        textFieldLabel={GROUP_NAME_LABEL}
        textFieldError=""
        {...commonProps}
      />
      <EditDetailsModal
        open={editModals[DESCRIPTION.label]}
        fieldType="TEXTFIELD"
        textFieldValue={updatedUserGroupData[DESCRIPTION.label]}
        name={DESCRIPTION.label}
        isRequired={false}
        heading={EDIT_DESCRIPTION}
        textFieldLabel={DESCRIPTION_LABEL}
        textFieldError=""
        {...commonProps}
      />
    </React.Fragment>
  );
};

export default UserGroupDetailTabItem;
