import React, { useEffect, useState } from 'react';
import {
  ADMIN_SIDE_NAVIGATION_ITEMS,
  APPLICATION_DETAILS_TAB,
  IKeyValueObject,
  IUserGroupsAndAppsTableRow,
  MatchParams,
  USER_GROUP_DETAILS_TAB,
} from '../../../core-utils/constants';
import MainHeader from '../../organisms/MainHeader';
import SideNavigationDrawer, {
  sideNavLocalStorageKeys,
} from '../../organisms/SideNavigationDrawer';
import HomeTemplate from '../../templates/HomeTemplate';
import UserDetailsDashboard from '../../organisms/UserDetailsDashboard';
import UserService, {
  IListUsersPoliciesResponse,
} from '../../../api-service/user-service/UserService';
import { RouteComponentProps, useHistory } from 'react-router';
import {
  constructListApps,
  getPolicyTableRowsForUsers,
  getUserById,
  getUserGroupsFormatted,
} from './index.hook';
import {
  getApplicationType,
  getCustomDomainName,
  getOrganization,
  getSideNavEndItems,
  getSideNavItems,
  getUserFullNameOrUsername,
  getUserId,
} from '../../../core-utils/Helper/helper';
import LoadingAnimation from '../../atoms/LoadingAnimation';
import { getAppById } from '../AppDetailsPage/index.hook';
import { useShowOrHideElement } from '../../../core-utils/Hooks/index.hook';
import InnerDetailSummaryModal from '../../organisms/InnerDetailSummaryModal';
import { getUserGroupById } from '../UserGroupDetailPage/index.hook';
import AppService from '../../../api-service/app-service/AppService';
import { IPoliciesTableRowProps } from '../../molecules/PoliciesTableRow';
import PolicyService, {
  IListPolicyResponse,
} from '../../../api-service/policy-service/PolicyService';
import messages from '../../../core-utils/messages';
import { ROUTES } from '../../../core-utils/routes';
import UserGroupService from '../../../api-service/user-group-service/UserGroupService';
import { connectionsActions } from '@src/Redux/Reducers/connections';
import { useAppDispatch } from '@src/Redux/Store/store';
interface IUserDetailsPageProps extends RouteComponentProps<MatchParams> {}

const UserDetailsPage = ({ match }: IUserDetailsPageProps) => {
  const username = decodeURIComponent(match.params.id);
  var history = useHistory();
  const dispatch = useAppDispatch();

  const [userDetails, setUserDetails] = useState({
    id: '',
    name: '',
    email: '',
    jobTitle: '',
    password: '',
    // userStatus: '',
    source: '',
    createdBy: '',
    createdOn: '',
    description: '',
    role: '',
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [appDetails, setAppDetails] = useState<IUserGroupsAndAppsTableRow[]>();
  const [userGroups, setUserGroups] = useState<any>([]);
  const [popUpData, setPopUpData] = useState<IKeyValueObject[]>([]);
  const [popUpRedirection, setPopUpRedirection] = useState<{
    tabItemType: 'usergroup' | 'app';
    redirectionId: string;
  }>();
  const { open, hideElement, showElement } = useShowOrHideElement();
  const [policies, setPolicies] = useState<IPoliciesTableRowProps[]>([]);

  const popUpUserGroup = async (groupId: string, userLength: number) => {
    const userGroupDetails = await getUserGroupById(groupId);
    const {
      GROUP_NAME,
      NUMBER_OF_USERS,
      CREATED_BY,
      DESCRIPTION,
      SOURCE,
    } = USER_GROUP_DETAILS_TAB;
    const requiredUserGroupDetails = [
      { key: GROUP_NAME.label, value: userGroupDetails.name },
      { key: NUMBER_OF_USERS.label, value: userLength },
      { key: CREATED_BY.label, value: userGroupDetails.createdBy },
      { key: DESCRIPTION.label, value: userGroupDetails.description },
      { key: SOURCE.label, value: userGroupDetails.source },
    ];
    setPopUpData(requiredUserGroupDetails);
    setPopUpRedirection({ tabItemType: 'usergroup', redirectionId: groupId });
    showElement();
  };

  const popUpApp = async (appId: string) => {
    const appDetails: any = await getAppById(appId);
    dispatch(connectionsActions.setconnectionDetails(appDetails));
    const appParams = await AppService.getAppParameters(appId);
    const {
      APP_NAME,
      CREATED_ON,
      CREATED_BY,
      APPLICATION_TYPE,
      CUSTOM_DOMAIN,
      IDP_APP_URL,
    } = APPLICATION_DETAILS_TAB(appDetails.applicationType);
    const requiredAppDetails = [
      { key: APP_NAME.label, value: appDetails.name },
      {
        key: CUSTOM_DOMAIN.label,
        value: getCustomDomainName(appParams),
      },
      { key: CREATED_ON.label, value: appDetails.createdOn },
      { key: CREATED_BY.label, value: appDetails.createdBy },
      {
        key: APPLICATION_TYPE.label,
        value: getApplicationType(appParams, appDetails.applicationType)
      },
    ];

    if(appDetails.applicationType === 'browser-app') {
      requiredAppDetails.splice(2, 0, { key: IDP_APP_URL.label, value: appParams?.domainurls })
    }
    setPopUpData(requiredAppDetails);
    setPopUpRedirection({ tabItemType: 'app', redirectionId: appId });
    showElement();
  };

  const getUserGroupsForUser = async () => {
    const userDetails = await getUserById(username);
    const userGroupTableRows = await getUserGroupsFormatted(
      username,
      userDetails.name,
      getUserGroupsForUser,
      popUpUserGroup,
      getAppsByUserId,
      getPoliciesByUserId,
    );
    setUserGroups(userGroupTableRows);
  };

  const getAppsByUserId = async () => {
    const effectiveAppsByUserId: any = await UserService.getEffectiveAppsOfUser(
      username,
    );
    const userDetails = await getUserById(username);
    const appsOfUser: IUserGroupsAndAppsTableRow[] = await constructListApps(
      effectiveAppsByUserId,
      username,
      'user',
      getAppsByUserId,
      userDetails.name,
      popUpApp,
    );
    setAppDetails(appsOfUser);
  };

  const getPoliciesByUserId = async () => {
    const userDetails = await getUserById(username);
    const userPolicies: string[] = await UserService.getPoliciesOfUser(
      username,
    );
    const userGroupsOfUser = await UserGroupService.getUserGroupsForUser(
      username,
    );
    const userGroupsPolicies: string[] = [];
    const dict: any = {};
    const policiesPromises = userGroupsOfUser.map(async (usergroup: string) => {
      const policies = await UserGroupService.getPoliciesOfUserGroup(usergroup);
      policies.map((policyId: string) => {
        if (!Object.keys(dict).includes(policyId)) {
          dict[policyId] = [usergroup];
        } else {
          dict[policyId].push(usergroup);
        }
      });
      userGroupsPolicies.push(...policies);
    });
    await Promise.all(policiesPromises);
    const uniqueUserGroupsPolicies = userGroupsPolicies.filter(
      (policy, index) => userGroupsPolicies.indexOf(policy) === index,
    );
    const filteredUserGroupPolicies = uniqueUserGroupsPolicies.filter(
      (id) => !userPolicies.includes(id),
    );

    const requiredPolicies: IListPolicyResponse[] = [];
    const promises = userPolicies.map(async (policyId: string) => {
      const policy = await PolicyService.getPolicyById(policyId);
      if (policy !== messages.ERROR_STATUS) {
        requiredPolicies.push({
          id: policyId,
          showClose: true,
          disablePolicy: false,
          ...policy,
        });
      }
    });

    const userGroupRequiredPolicies: IListPolicyResponse[] = [];
    const userGroupPromises = filteredUserGroupPolicies.map(
      async (policyId: string) => {
        const policy = await PolicyService.getPolicyById(policyId);
        if (policy !== messages.ERROR_STATUS) {
          userGroupRequiredPolicies.push({
            id: policyId,
            showClose: false,
            ...policy,
            disablePolicy: userPolicies.length === 0 ? false : true,
            usergroupsOfPolicy: dict[policyId],
          });
        }
      },
    );
    await Promise.all(promises);
    await Promise.all(userGroupPromises);

    const allRequiredPolicies = [
      ...requiredPolicies,
      ...userGroupRequiredPolicies,
    ];
    const policyTableRows = getPolicyTableRowsForUsers(
      allRequiredPolicies,
      userDetails.name,
      username,
      getPoliciesByUserId,
    );

    const updatedPolicyTableRows: IPoliciesTableRowProps[] = [];
    policyTableRows.forEach((tableRow: any) => {
      updatedPolicyTableRows.push({
        ...tableRow,
        handleTableRowClick: () => {
          history.push(ROUTES.EDIT_POLICY.replace(':id', tableRow.id));
        },
      });
    });
    setPolicies(updatedPolicyTableRows);
  };

  const handleRedirection = () => {
    if (popUpRedirection?.tabItemType === 'usergroup') {
      location.pathname = `/usergroups/${popUpRedirection.redirectionId}`;
    } else {
      location.pathname = `/apps/${popUpRedirection?.redirectionId}`;
    }
  };

  const getUserDetails = async (userId: string, isUserNameUpdate: boolean = false) => {
    if(isUserNameUpdate) {
      history.push(`/users/${encodeURIComponent(userId)}`);
    } 
    const detailsOfUser = await getUserById(userId);
    setUserDetails(detailsOfUser);
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getUserDetails(username),
      getUserGroupsForUser(),
      getAppsByUserId(),
      getPoliciesByUserId(),
    ])
      .then()
      .finally(() => setLoading(false));
  }, []);

  const sideNavItems = getSideNavItems();
  const sideNavEndItems = getSideNavEndItems();

  return (
    <>
      <HomeTemplate
        leftNavComponent={
          <SideNavigationDrawer
            sideNavItems={sideNavItems}
            sideNavEndItems={sideNavEndItems}
          />
        }
        header={
          <MainHeader
            username={getUserFullNameOrUsername()}
            isImage={false}
            usercompany={getOrganization()}
          />
        }
        content={
          loading ? (
            <LoadingAnimation />
          ) : (
            <>
              <UserDetailsDashboard
                userDetails={userDetails}
                appDetails={appDetails}
                userGroups={userGroups}
                username={username}
                policies={policies}
                updateUserGroupsOfUser={getUserGroupsForUser}
                updateAppsOfUser={getAppsByUserId}
                updatePoliciesOfUser={getPoliciesByUserId}
                getUserDetails={getUserDetails}
              />
              {popUpData.length > 0 && (
                <InnerDetailSummaryModal
                  open={open}
                  onClose={hideElement}
                  nameHeading={popUpData[0].value}
                  detailItemValues={popUpData}
                  clickViewDetails={handleRedirection}
                />
              )}
            </>
          )
        }
      />
    </>
  );
};

export default UserDetailsPage;
