import { IListUsersResponse } from '../../api-service/user-service/UserService';
import { StringToStringMapLoose } from '../constants';

export interface UserGroupResponse {
  identifier: string;
  attributes: StringToStringMapLoose;
}

export interface IUserGroup {
  [key: string]: UserGroupResponse;
}

export interface IUser {
  [key: string]: IListUsersResponse;
}

export enum OperationTypes {
  add = 'add',
  remove = 'remove',
}

export interface IAppChildAttributes {
  thumbnail: string;
  displayFlag: string;
  displayName?: string;
}

export interface IAppChildConnections {
  name: string;
  identifier: number;
  parentIdentifier: string;
  protocol: string;
  attributes: IAppChildAttributes;
}

export interface IApps {
  name: string;
  identifier: string;
  activeConnections: number;
  childConnections: Array<IAppChildConnections>;
}

export interface ILogs {
  timestamp: string;
  userId: string;
  location: string;
  sessionId: string;
  severity: string;
  category: string;
  description: string;
  ipAddress: string;
  applicationName: string;
  browser: string;
  violation: string;
  output: string;
}
