import * as React from 'react';
import { Box, Typography } from '@mui/material';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import IconFromSvg from '../../atoms/IconFromSvg';
import AppEmptyStateSvg from '../../../../public/assets/app-emptystate.svg';
import Button from '../../atoms/Button';
import { styled } from '@mui/material/styles';
import messages from '../../../core-utils/messages';
import {
  BUTTON_LABEL_CONSTANTS,
  IApplicationEmptyState,
} from '../../../core-utils/constants';

export const TypographyHeading = styled(Typography)({
  ...theme.typography.h3,
  color: theme.palette.common.black,
});

const ApplicationEmptyState = ({ handleClick }: IApplicationEmptyState) => {
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${EXTRA_COLORS.lightGrayishBlue}`,
        borderRadius: theme.spacing(1.25),
        width: theme.spacing(229.25),
        height: theme.spacing(112.25),
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      gap={theme.spacing(2)}
    >
      <IconFromSvg path={AppEmptyStateSvg} alt="application empty state" />
      <TypographyHeading>{messages.APPLICATION_EMPTY_STATE}</TypographyHeading>
      <Typography sx={{ color: theme.palette.text.disabled }}>
        {messages.ADD_APPLICATION_MESSAGE}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        sx={{ marginTop: theme.spacing(2) }}
      >
        {BUTTON_LABEL_CONSTANTS.ADD_APPLICATION}
      </Button>
    </Box>
  );
};

export default ApplicationEmptyState;
