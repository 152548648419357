import { createSlice, current } from '@reduxjs/toolkit';
import { findIntervalForUsersLogin } from '@src/core-utils/Helper/helper';


// Define a type for the slice state
interface sessionState {
  session: any;
  firstTimeLogin?: boolean,
  firstTimeUserOnboardingDone?: boolean,
  mfaVerificationDone?: boolean,
  role?: string,
  isSsoUser?: boolean,
  hash: string,
}

// Define the initial state using that type
const initialState: sessionState = {
  session: null,
  firstTimeLogin: false,
  firstTimeUserOnboardingDone: false,
  mfaVerificationDone: false,
  role: '',
  isSsoUser: false,
  hash: ''
};

const sessionSlice = createSlice({
  name: 'sessionSlice',
  initialState: initialState,
  reducers: {
    setSession(state, action) {
      state.session = action.payload;
      localStorage.setItem('GUAC_AUTH', JSON.stringify(action.payload));
    },
    clearSession(state) {
      state.session = null;
      state.mfaVerificationDone = false;
      state.role = '';
    },
    setFirstTimeLogin(state, action) {
      state.firstTimeLogin = action.payload;
    },
    setFirstTimeUserOnboardingDone(state) {
      state.firstTimeUserOnboardingDone = true;
    },
    setMfaVerficationDone(state) {
      state.mfaVerificationDone = true;
    },
    setUserRole(state, action) {
      state.role = action.payload;
    },
    setSsoEnabled(state, action) {
      state.isSsoUser = action.payload;
    },
    setPasswordHash(state, action) {
      state.hash = action.payload
    }
  },
});

export const sessionActions = sessionSlice.actions;

export default sessionSlice;
