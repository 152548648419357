import React, { useEffect, useState } from 'react';
import {
  EMPTY_FUNCTION,
  FULL_NAME,
  PASSWORD_PARAMETERS,
  USER_ONBOARDING_PROGRESS_BAR_LABELS,
} from '../../../core-utils/constants';
import OnBoardingHeader from '../../organisms/OnBoardingHeader';
import OnBoardingLeftPanel from '../../organisms/OnBoardingLeftPanel';
import UserInformationForm from '../../organisms/UserInformationForm';
import OnBoardingFooter from '../../organisms/OnBoardingFooter';
import OnBoardingTemplate from '../../templates/OnBoardingTemplate';
import useEndUserOnboardingPageData from './index.hook';
import onBoardingMessages from '../../../core-utils/messages/onBoarding';
import {
  getUserId,
  isEmptyValidation,
  isPasswordValid,
  validatePhone,
} from '../../../core-utils/Helper/helper';
import EndUserPasswordForm from '../../organisms/EndUserPasswordForm';
import { useAppDispatch, useAppSelector } from '../../../Redux/Store/store';
import { RootStateOrAny } from 'react-redux';
import { EndUserInformationForm } from '../../organisms/UserInformationForm/index.stories';
import EndUserSystemConfigurationForm from '../../molecules/EndUserSystemConfigurationForm';
import UserService from '../../../api-service/user-service/UserService';
import { updateHeaderData } from '../../../Redux/Reducers/header';
import { useHistory } from 'react-router';
import { ROUTES } from '../../../core-utils/routes';
import LoadingAnimation from '../../atoms/LoadingAnimation';
import AuthenticationSetupForm from 'src/_components/organisms/AuthenticationSetupForm';
import { sessionActions } from '@src/Redux/Reducers/session';

const {
  OLD_PASSWORD_LABEL,
  NEW_PASSWORD_LABEL,
  CONFIRM_PASSWORD,
} = onBoardingMessages.END_USER_PASSWORD_FORM;

const {
  USER_NAME,
  USER_PHONE,
  USER_LOGO,
  USER_PHONE_CODE,
} = onBoardingMessages.INFORMATION_FORM;

const UserOnboardingPage = () => {
  const mainHeaderData = useAppSelector(
    (state: RootStateOrAny) => state.header,
  );
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [userDetails, setUserDetails] = useState<any>();
  const [qrCode, setQrCode] = useState<string>();
  const [secretKey, setSecretKey] = useState<string>('');
  const [source, setSource] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const getUserById = async () => {
    const userId = getUserId();
    const userResponseById = userId && (await UserService.getUserById(userId));
    setUserDetails(userResponseById);
    const { attributes } = userResponseById.attributes;
    const sourceOfUser =
      attributes?.['sonet-source'] && attributes?.['sonet-source'] === 'okta'
        ? 'okta'
        : 'local';
    setSource(sourceOfUser);
  };

  useEffect(() => {
    setLoading(true);
    getUserById()
      .then()
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const userId = getUserId() ?? '';

    UserService.getTotpLogo(userId)
      .then((logo: string) => {
        setQrCode(logo);
      });
    UserService.getTotpSecretKey(userId)
      .then((r) => {
        setSecretKey(r);
      })
  }, []);

  const TOTAL_STEPS = source === 'okta' ? 1 : 3;

  const {
    currentStep,
    onBackClick,
    onNextClick,
    isButtonDisabled,
    handleRemoveImage,
    handleInformationTextFieldChange,
    handleLogoChange,
    handlePhoneCodeChange,
    handleUserInformationValidation,
    userInformationDetails,
    passwordFormDetails,
    passwordTooltipParams,
    handlePasswordTextFieldChange,
    handlePasswordFormValidation,
    passwordErrorMessages,
    newPasswordErrorMessage,
    authenticationData,
    handleChangeVerificationCode,
    handleVerifyTotpEnrolment,
  } = useEndUserOnboardingPageData(source);

  const handleUserPasswordSave = async () => {
    try {
      await UserService.updatePasswordForEndUser(
        getUserId() ?? '',
        passwordFormDetails[NEW_PASSWORD_LABEL],
        passwordFormDetails[OLD_PASSWORD_LABEL],
      );
    } catch (error) {
      return error;
    }
  };

  const handleUserInformationSave = async () => {
    try {
      const userId = getUserId();
      const requestBody: any = {
        attributes: {
          ...userDetails.attributes,
          'guac-full-name': userInformationDetails[USER_NAME],
          'guac-phone-code': userInformationDetails[USER_PHONE_CODE].name,
          'guac-phone-number': userInformationDetails[USER_PHONE],
        },
        lastActive: userDetails.lastActive,
      };
      userId &&
        (await UserService.updateUserInformationForEndUser(
          userId,
          requestBody,
        ));
      localStorage.setItem(FULL_NAME, userInformationDetails[USER_NAME]);
      dispatch(
        updateHeaderData({
          ...mainHeaderData.entities,
          userName: userInformationDetails[USER_NAME],
        }),
      );
    } catch (error) {
      return error;
    }
  };

  const handleSaveClick = async () => {
    if (source === 'okta') {
      handleUserInformationSave()
        .then(
          async () =>
            await UserService.setUserFirstTimeLoginStatus(
              getUserId() ?? '',
              false,
            ),
        )
        .finally(() => history.replace(ROUTES.MY_APPLICATIONS));
    } else {
      handleUserPasswordSave()
        .then(() => handleUserInformationSave())
        .then(
          async () =>
            await UserService.setUserFirstTimeLoginStatus(
              getUserId() ?? '',
              false,
            ),
        )
        .finally(() => {
          dispatch(sessionActions.setFirstTimeUserOnboardingDone())
          history.replace(ROUTES.MY_APPLICATIONS);
        });
    }
  };

  const formComponentList = {
    0: (
      <EndUserPasswordForm
        passwordToolTipParams={passwordTooltipParams}
        handlePasswordTextFieldChange={handlePasswordTextFieldChange}
        passwordErrorMessages={{
          'New Password': newPasswordErrorMessage,
          'Confirm New Password': passwordErrorMessages[CONFIRM_PASSWORD],
          'Temporary Password': passwordErrorMessages[OLD_PASSWORD_LABEL]
        }}
        passwordFormDetails={passwordFormDetails}
        handlePasswordValidation={handlePasswordFormValidation}
      />
    ),
    1: (
      <AuthenticationSetupForm
        qrCodeImg={qrCode}
        textKey={secretKey}
        authenticationData={authenticationData}
        handleChangeVerificationCode={handleChangeVerificationCode}
        handleClickVerify={handleVerifyTotpEnrolment}
      />
    ),
    2: (
      <UserInformationForm
        handleLogoChange={handleLogoChange}
        handleInformationTextFieldChange={handleInformationTextFieldChange}
        handlePhoneCodeChange={handlePhoneCodeChange}
        handleInformationFormValidation={handleUserInformationValidation}
        handleUserLogoRemove={handleRemoveImage}
        userInformationDetails={userInformationDetails}
      />
    ),
  };

  const getFormWithRespectToStepValue = (stepValue: number) => {
    switch (stepValue) {
      case 0:
        return formComponentList[0];
      case 1:
        return formComponentList[1];
      case 2:
        return formComponentList[2];
      default:
        return formComponentList[0];
    }
  };

  const getRequiredProgressBarLables = () => {
    if (source === 'okta') {
      const labels = USER_ONBOARDING_PROGRESS_BAR_LABELS.filter(
        (item) => item.id !== '1',
      );
      return labels;
    } else {
      return USER_ONBOARDING_PROGRESS_BAR_LABELS;
    }
  };
  // const REQUIRED_PROGESS_BAR_LABELS
  const buttonText = currentStep + 1 === TOTAL_STEPS ? 'Get Started' : 'Next';
  const requiredActionCall =
    currentStep + 1 === TOTAL_STEPS ? handleSaveClick : onNextClick;

  const requiredCurrentStepForUser =
    source === 'okta' ? currentStep + 1 : currentStep;

  return (
    <OnBoardingTemplate
      target="user"
      header={<OnBoardingHeader />}
      leftPanel={
        <OnBoardingLeftPanel
          listOfLabels={getRequiredProgressBarLables()}
          organizationName={mainHeaderData?.entities?.userName}
          currentlyActiveStep={currentStep}
        />
      }
      content={
        loading ? (
          <LoadingAnimation />
        ) : (
          getFormWithRespectToStepValue(requiredCurrentStepForUser)
        )
      }
      footer={
        <OnBoardingFooter
          totalSteps={TOTAL_STEPS}
          currentStep={currentStep + 1}
          backButtonClick={onBackClick}
          actions={[
            {
              actionName: buttonText,
              actionCall: requiredActionCall,
              buttonVariant: 'contained',
              isDisabled: isButtonDisabled,
            },
          ]}
        />
      }
    />
  );
};

export default UserOnboardingPage;
