import React from 'react';
import { Grid, Typography } from '@mui/material';
import messages from '../../../core-utils/messages';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import ProgressBar, { IStepperProps } from '../ProgressBar';
import { useWindowSize } from 'src/core-utils/Hooks/index.hook';
import { replacePlaceholders } from '@src/core-utils/Helper/helper';

interface ILeftPanelProps extends IStepperProps {
  organizationName: string;
}

const style = {
  background: EXTRA_COLORS.lightBluishBackground,
  border: '1px solid',
  borderColor: EXTRA_COLORS.blue_100,
  width: theme.spacing(140),
  margin: 0,
  paddingTop: theme.spacing(6),
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(13),
};

const OnBoardingLeftPanel = (props: ILeftPanelProps) => {
  const { HEADING1, HEADING2, SUB_HEADING, USER } = messages.LEFT_PANEL;
  const { organizationName, listOfLabels, currentlyActiveStep } = props;
  const { height } = useWindowSize();
  return (
    <Grid container direction="column" height='100%' sx={style} >
      <Grid item>
        <Typography variant="h1">
          {replacePlaceholders(HEADING1, { name: organizationName || USER })}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h1">{HEADING2}</Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="subtitle2"
          sx={{
            color: theme.palette.text.disabled,
            paddingTop: theme.spacing(4),
          }}
        >
          {SUB_HEADING}
        </Typography>
      </Grid>
      <Grid item sx={{ mt: 10 }}>
        <ProgressBar
          listOfLabels={listOfLabels}
          currentlyActiveStep={currentlyActiveStep}
        />
      </Grid>
    </Grid>
  );
};

export default OnBoardingLeftPanel;
