import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Button as MUIButton, ButtonProps } from '@mui/material';
import theme from '../../../core-utils/theme';

const StyledButton = styled(MUIButton)({
  ...theme.typography.body1,
  textTransform: 'none',
  borderRadius: '4px',
});

interface IButtonProps extends ButtonProps {}

const Button = ({ children, ...rest }: IButtonProps) => {
  return (
    <StyledButton {...rest} data-testid={children}>
      {children}
    </StyledButton>
  );
};

export default Button;
