import LoadingAnimation from '@atoms/LoadingAnimation';
import MainHeader from '@organisms/MainHeader';
import SideNavigationDrawer from '@organisms/SideNavigationDrawer';
import {
  formatTime,
  getOrganization,
  getSideNavEndItems,
  getSideNavItems,
  getUserFullNameOrUsername,
} from '@utils/Helper/helper';
import { MatchParams } from '@utils/constants';
import { ROUTES } from '@utils/routes';
import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import HomeTemplate from '../../templates/HomeTemplate';
import SessionDetails from '../SessionDetail';
import { useSessionRecordingData } from './index.hook';

export interface ISessionRecordingDetailPage
  extends RouteComponentProps<MatchParams> { }

const SessionRecordingDetailPage = (props?: ISessionRecordingDetailPage) => {
  const history = useHistory();
  const historyID = parseInt(props?.match?.params?.id ?? '');
  const [sessionHistoryDetails, violations, loading] = useSessionRecordingData(historyID);

  const handleBack = () => {
    history.push(ROUTES.SESSION_RECORDINGS);
  };

  const getSummaryData = () => {
    return {
      ipAddress: getIpAddress(),
      duration: formatTime(sessionHistoryDetails.duration) + ' min',
      numberOfViolations: sessionHistoryDetails.numPolicyViolations,
      apps: sessionHistoryDetails.appName,
      violations: getPolicyViolationsData(),
      location: sessionHistoryDetails.location,
      fileUploads: sessionHistoryDetails.fileUploads,
      fileDownloads: sessionHistoryDetails.fileDownloads,
      totalBandwidth: sessionHistoryDetails.totalBandwidth,
    };
  };


  const getPolicyViolationsData = () => {
    let policyViolations: any = [];
    violations?.forEach((violation: any) => {
      policyViolations.push({
        id: violation.offset.toString(),
        name: violation.category,
        description: violation.reason + '(' + violation.details + ')',
        actions: [],
        offset: violation.offset,
      });
    });
    return policyViolations;
  };

  const getUser = () => {
    return sessionHistoryDetails.userFullName;
  };

  const getIpAddress = () => {
    return sessionHistoryDetails.ipAddress;
  };

  const getLocation = () => {
    return sessionHistoryDetails.location;
  };

  const sideNavItems = getSideNavItems();
  const sideNavEndItems = getSideNavEndItems();

  return (
    <HomeTemplate
      leftNavComponent={<SideNavigationDrawer sideNavItems={sideNavItems} sideNavEndItems={sideNavEndItems} />}
      header={
        <MainHeader
          username={getUserFullNameOrUsername()}
          isImage={false}
          usercompany={getOrganization()}
        />
      }
      content={
        loading ? (
          <LoadingAnimation compact={true} />
        ) : <SessionDetails
          handleBack={handleBack}
          user={getUser()}
          location={getLocation()}
          sessionHistoryDetails={sessionHistoryDetails}
          summary={getSummaryData()}
          violations={violations}
        />
      }
    />
  );
};

export default SessionRecordingDetailPage;
