import { Divider, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import messages from '../../../core-utils/messages';
import { EXTRA_COLORS } from '../../../core-utils/theme';
import Button from '../../atoms/Button';

export interface IDetailsListItemProps {
  label: string;
  value: string;
  buttonLabel?: string;
  showButton?: boolean;
  handleButtonClick?: (label: string) => void;
  valueStartIcon?: any;
}

const DetailsListItem = ({
  label,
  value,
  buttonLabel,
  showButton = true,
  valueStartIcon = null,
  handleButtonClick,
}: IDetailsListItemProps) => {
  const theme = useTheme();
  const {
    TEXT_FIELD_LABELS: { APPLICATION_LOGO },
  } = messages.ADD_APPLICATION;

  return (
    <>
      <Grid
        container
        direction="row"
        p={4}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid
          item
          container
          direction="column"
          xs={9}
          sx={{ minHeight: '40px' }}
        >
          <Typography variant="caption" color={theme.palette.text.disabled}>
            {label}
          </Typography>
          {label === APPLICATION_LOGO ? (
            <Grid item container sx={{ p: 4 }}>
              <img src={value} style={{ height: 40 }} />
            </Grid>
          ) : (
            <Typography
              sx={{ paddingTop: 2 }}
              variant="body2"
              color={theme.palette.common.black}
            >
              {valueStartIcon}
              {value}
            </Typography>
          )}
        </Grid>
        <Grid item>
          {showButton && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleButtonClick && handleButtonClick(label)}
            >
              {buttonLabel}
            </Button>
          )}
        </Grid>
      </Grid>
      <Divider
        variant="middle"
        style={{
          border: `0.5px solid ${EXTRA_COLORS.blue_100}`,
        }}
      />
    </>
  );
};

export default DetailsListItem;
