import React, { useState } from 'react';
import TextField, { ITextFieldProps } from '@atoms/TextField';
import ShowPasswordSvg from '@assets/show-password.svg';
import HidePasswordSvg from '@assets/hide-password.svg';
import IconFromSvg from '@atoms/IconFromSvg';
import { InputAdornment, IconButton } from '@mui/material';

interface IPasswordFieldProps extends ITextFieldProps {
  validPassword?: boolean;
}

const PasswordTextField = ({ validPassword, testId, ...rest }: IPasswordFieldProps) => {
  const [showText, setShowText] = useState(false);
  const handleTogglePassword = () => {
    setShowText(!showText);
  };
  return (
    <TextField
      {...rest}
      testId={testId}
      type={showText ? 'text' : 'password'}
      color={validPassword ? 'success' : 'primary'}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            data-testid="toggle-password"
            aria-label="toggle password visibility"
            onClick={handleTogglePassword}
            edge="end"
          >
            {showText ? (
              <IconFromSvg path={HidePasswordSvg} alt="hide" />
            ) : (
              <IconFromSvg path={ShowPasswordSvg} alt="show" />
            )}
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

export default PasswordTextField;
