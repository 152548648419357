const userGroupMessages = {
  USER_GROUP_PLACEHOLDER: 'Search for user groups',
  INNER_SCREEN_TABLES: 'There are no groups assigned to this user yet',
  CREATE_USER_GROUP: {
    HEADING: 'Add User Group',
    SUBHEADING: 'Add basic details of the user group',
    TEXT_FIELD_LABELS: {
      NAME: 'Name',
      DESCRIPTION: 'Description',
    },
    PLACEHOLDER: {
      GROUP_NAME: 'Enter user group name here',
      GROUP_DESCRIPTION: 'Enter group description here',
    },
    BUTTON_TEXT: {
      SAVE: 'Save',
      CANCEL: 'Cancel',
      EDIT: 'Edit',
    },
    REQUIRED: 'Required',
  },
  USERGROUPS_DASHBOARD: {
    ADD_USERGROUP: 'Add User Group',
  },
  LINK_TO_USER_GROUPS: 'Link to User Group(s)',
  SEARCH_FOR_USERGROUPS: 'Search for user groups',
  USERGROUPS: 'User Groups',
  BACK_TO_USER_GROUPS: 'Back to User Groups',
  USER_GROUP_ALREADY_EXISTS: 'Usergroup already exists',
  ACTIONS: 'Actions',
  USER_GROUP_DETAIL_TAB: {
    GROUP_NAME: 'Group Name',
    NUMBER_OF_USERS: 'Number of Users',
    CREATED_BY: 'Created By',
    GROUP_DESCRIPTION: 'Description',
  },
  NO_USERS_ASSIGNED: 'There are no users assigned to this group yet',
  USER_GROUP_ACCESS_HEADING: 'Users in this group',
  DELETE_USER_GROUP_HEADING: 'Delete User Group',
  CUSTOM_DELETE_MESSAGE: `Are you really sure you want to delete {"The User"}? This cannot be undone!`,
  EDIT_USER_GROUP: {
    GROUP_NAME_LABEL: 'Group Name',
    DESCRIPTION_LABEL: 'Description',
    EDIT_GROUP_NAME: 'Edit Group Name',
    EDIT_DESCRIPTION: 'Edit Description',
  },
  USERGROUPS_EMPTY_STATE_UNDER_APPS:
    'There are no groups assigned to this app yet',
  USERGROUPS_ACCESS_MESSAGE_UNDER_APPS: 'Groups that have access to this App',
  APP_ACCESS_MESSAGE_TO_USERGROUP:
    '{usergroupName} will get access to {appName}. All relevant policies associated with {appName} will apply.',
  CUSTOM_REMOVE_UNDER_TABS_USERGROUPS:
    'This action will remove {removeItem} from usergroup {removeFrom}',
  ALL_USERS_GROUP: 'All Users',
  POLICIES_HEADING: 'Policies used by this group',
  POLICIES_ACCESS_MESSAGE_TO_USERGROUP:
    '{policyName} will get added to  the list of policies under usergroup {groupName}',
  POLICY_ALREADY_EXISTS_FOR_USERGROUP:
    'This policy has already been assigned to this user group',
};
export default userGroupMessages;
