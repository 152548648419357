import Table from '../Table';
import React, { useEffect, useState } from 'react';
import UsersAppTableRow from 'src/_components/molecules/UsersAppCredsTableRow';

export interface UserAppsTableProps {
  headers: any[];
  tableData: any[];
  onLableclick: any;
  arrow: boolean;
  openCredsModal?: boolean;
}

const UsersAppsTable = ({
  headers,
  tableData,
  onLableclick,
  arrow,
}: UserAppsTableProps) => {
  // React.useEffect(() => {
  //   setDefaultSorted(true);
  // }, [rest.arrow]);

  return (
    <Table
      headers={headers}
      customTableRow={UsersAppTableRow}
      tableData={tableData}
      inUserActivityTable={true}
    />
  );
};

export default UsersAppsTable;
