import { Box, Menu } from '@mui/material';
import React, { useState } from 'react';
import { CONTENT_POLICY_VALUE_OPTIONS } from '../../../core-utils/test-constants';
import PolicyBuilderTimePicker from '../../molecules/PolicyBuilderTimePicker';
import PolicyConditionCell from '../../molecules/PolicyConditionCell';
import PolicyBuilderValueDropdown from '../PolicyBuilderValueDropdown';
import theme from '@src/core-utils/theme';

interface IPolicyValueItemProps {
  id: number;
  parameterValue: any;
  userGroupDropdownOptions: any;
  applicationDropdownOptions: any;
  urlDropdownOptions: any;
  policyValue?: any;
  handleGeoLocationChange?: (id: number, event: any) => void;
  handleTextfieldChange?: (id: number, event: any) => void;
  handleDropdownChange?: (id: number, value: any) => void;
  handleChangePolicyValue?: (startTime: string, endTime: string) => void;
  handleChangeTimeValue?: (
    cardId: number,
    rowId: number,
    startTime: string,
    endTime: string,
    timezone?: string
  ) => void;
  cardId?: number;
  policyValueErrorMessage?: string;
  sequenceRow?: string;
}

const PolicyValueItem = ({
  cardId,
  id,
  parameterValue,
  userGroupDropdownOptions,
  applicationDropdownOptions,
  urlDropdownOptions,
  policyValue = "",
  handleTextfieldChange,
  handleDropdownChange,
  handleChangePolicyValue,
  handleChangeTimeValue,
  policyValueErrorMessage,
  sequenceRow,
}: IPolicyValueItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (startTime?: string, endTime?: string, timezone?: string) => {
    if (startTime && endTime && cardId !== undefined) {
      handleChangeTimeValue &&
        handleChangeTimeValue(cardId, id, startTime, endTime, timezone);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  var defaultReturn = true;
  if (parameterValue) {
    const parameter = parameterValue.name.toLowerCase();
    ['location', 'app', 'time', 'ip', 'url', 'content', 'access'].forEach(
      (value: string) => {
        if (parameter.includes(value)) {
          defaultReturn = false;
        }
      },
    );
  }


  const parseTimestamp = (policyValue: string = "") => {
    let startTime = "", endTime = "", timezoneCode = "";
    if (policyValue) {
      const [aStartTime, aEndTimeAndZone] = policyValue?.split(' - ');
      const [aEndTime, aAmOrPm, aTimezoneCode] = aEndTimeAndZone?.split(' ');
      startTime = aStartTime;
      endTime = `${aEndTime} ${aAmOrPm}`;
      timezoneCode = aTimezoneCode;
    }
    return { startTime, endTime, timezoneCode }
  }

  let parsedTimeRange: any = {};
  if (parameterValue && parameterValue.name.toLowerCase().includes('time')) {
    parsedTimeRange = parseTimestamp(policyValue);
  }
  

  return (
    <>
      {parameterValue &&
        parameterValue.name.toLowerCase().includes('location') && (
          <PolicyBuilderValueDropdown
            options={userGroupDropdownOptions}
            value={policyValue}
            handleDropdownChange={(_: any, value: any) =>
              handleDropdownChange?.(id, value)
            }
            handleInputChange={() => handleTextfieldChange?.(id, event)}
            sx={{ width: 173 }}
            type="location"
            cyId={`options-policy-location-value-${sequenceRow}`}
          />
        )}
      {parameterValue && parameterValue.name.toLowerCase().includes('app') && (
        <PolicyBuilderValueDropdown
          options={userGroupDropdownOptions}
          value={policyValue}
          handleDropdownChange={(_: any, value: any) =>
            handleDropdownChange?.(id, value)
          }
          sx={{ width: 173 }}
          type="app"
          cyId={`options-policy-app-value-${sequenceRow}`}
        />
      )}
      {parameterValue && parameterValue.name.toLowerCase().includes('access') && (
        <PolicyBuilderValueDropdown
          options={userGroupDropdownOptions}
          value={policyValue}
          handleDropdownChange={(_: any, value: any) =>
            handleDropdownChange?.(id, value)
          }
          sx={{ width: 173 }}
          type="access"
          cyId={`options-policy-access-value-${sequenceRow}`}
        />
      )}
      {parameterValue && parameterValue.name.toLowerCase().includes('time') && (
        // TODO : Will be replaced with the new time component when developed.
        <>
          <Box onClick={handleClick}>
            <PolicyConditionCell
              variant="time-select"
              timeSelectValue={policyValue}
              timeselectDefaultValue=""
            />
          </Box>
          <Menu
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            sx={{
              width: theme.spacing(120), 
              height: theme.spacing(172), 
              backgroundColor: 'transparent', 
              p: 0, 
              m: 0,
              '& .MuiPopover-paper': {
                backgroundColor: 'transparent',
              },
              '& . MuiMenu-list': {
                paddingTop: 0,
                paddingBottom: 0,
              }
            }}
          >
            <PolicyBuilderTimePicker
              startTime={parsedTimeRange.startTime}
              endTime={parsedTimeRange.endTime}
              timezoneCode={parsedTimeRange.timezoneCode}
              handleClose={handleClose}
              handleCancel={handleCloseMenu}
            />
          </Menu>
        </>
      )}
      {parameterValue &&
        parameterValue.name.toLowerCase().includes('application') === 3 && (
          <PolicyBuilderValueDropdown
            options={applicationDropdownOptions}
            value={policyValue}
            handleDropdownChange={(_: any, value: any) =>
              handleDropdownChange?.(id, value)
            }
            sx={{ width: 173 }}
            cyId={`options-policy-application-value-${sequenceRow}`}
          />
        )}
      {parameterValue && parameterValue.name.toLowerCase().includes('ip') && (
        <PolicyConditionCell
          variant="text-field"
          handleTextFieldChange={(event) => handleTextfieldChange?.(id, event)}
          textfieldValue={policyValue}
          policyValueErrorMessage={policyValueErrorMessage}
        />
      )}
      {parameterValue && parameterValue.name.toLowerCase().includes('url') && (
        <PolicyBuilderValueDropdown
          options={urlDropdownOptions}
          value={policyValue}
          handleDropdownChange={(_: any, value: any) =>
            handleDropdownChange?.(id, value)
          }
          sx={{ width: 173 }}
          cyId={`options-policy-url-value-${sequenceRow}`}
        />
      )}
      {parameterValue && parameterValue.name.toLowerCase().includes('content') && (
        <PolicyBuilderValueDropdown
          options={CONTENT_POLICY_VALUE_OPTIONS}
          value={policyValue}
          handleDropdownChange={(_: any, value: any) =>
            handleDropdownChange?.(id, value)
          }
          handleInputChange={(e: any) => {
            handleTextfieldChange?.(id, e);
          }}
          policyValueErrorMessage={policyValueErrorMessage}
          sx={{ width: 173 }}
          type="content"
          cyId={`options-policy-content-value-${sequenceRow}`}
        />
      )}
      {parameterValue === null && (
        <PolicyConditionCell
          variant="dropdown"
          dropdownOptions={[]}
          dropdownValue={null}
        />
      )}
      {parameterValue && defaultReturn && (
        <PolicyConditionCell
          variant="text-field"
          handleTextFieldChange={(event) => handleTextfieldChange?.(id, event)}
          textfieldValue={policyValue}
        />
      )}
    </>
  );
};

export default PolicyValueItem;
