import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import headerSlice from '../Reducers/header';
import sideNavSlice from '../Reducers/sideNav';
import sessionSlice from '../Reducers/session';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import notificationSlice from '../Reducers/notification';
import userDetailsSlice from '../Reducers/userDetails'
import connectionsSlice from '../Reducers/connections'; 

const appReducers = combineReducers({
  header: headerSlice.reducer,
  sideNavDrawer: sideNavSlice.reducer,
  notification: notificationSlice.reducer,
  token: sessionSlice.reducer,
  userDetails: userDetailsSlice.reducer,
  connections: connectionsSlice.reducer
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducers = persistReducer(persistConfig, appReducers);

const store = configureStore({
  reducer: persistedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        // FLUSH,REHYDRATE,PAUSE,PERSIST,PURGE,REGISTER
        // added ignore action because of serialize check in constant file.
      },
    }),
});

export default store;

/**
 * Added Typescript support for the useAppDispatch as useDispatch
 * and useAppSelector as useSelector
 */
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
