import { Avatar, AvatarGroup, AvatarGroupProps } from '@mui/material';
import React from 'react';
import { EXTRA_COLORS } from '../../../core-utils/theme';
import { Avatars } from '../../atoms/Avatar';
import {
  AVATARS_SIZE,
  AVATAR_GROUP_MAX_SIZE,
} from '../../../core-utils/constants';

interface IAvatarGroupProps extends AvatarGroupProps {
  componentList: string[];
  maxAvatars?: number;
  size?: number;
}

export const AvatarGroups = (props: IAvatarGroupProps) => {
  const {
    componentList,
    maxAvatars = AVATAR_GROUP_MAX_SIZE,
    size,
    ...rest
  } = props;
  let resultList = componentList.map((item: any) => {
    if (item.length == 2) {
      return <Avatars isImage={false} children={item} size={size} />;
    } else {
      return <Avatars isImage={true} src={item} size={size} />;
    }
  });
  if (componentList.length <= maxAvatars) {
    return <AvatarGroup {...rest} children={resultList} />;
  } else {
    resultList = resultList.slice(0, maxAvatars - 1);
    resultList.push(
      <Avatar
        sx={{
          height: size ? size : AVATARS_SIZE.large,
          width: size ? size : AVATARS_SIZE.large,
          color: EXTRA_COLORS.grey,
          fontSize: size ? size / 3 : 12.6,
          fontWeight: 600,
        }}
        children={'+' + (componentList.length - (maxAvatars - 1))}
      />,
    );
    return <AvatarGroup {...rest} children={resultList} />;
  }
};
