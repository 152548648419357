const sessionRecordingMessages = {
  SESSION_RECORDINGS_DASHBOARD: {
    SESSION_RECORDINGS_HEADING: 'Session History',
    SEARCH_BAR_PLACEHOLDER: 'Search for users, apps and violations',
    EMPTY_STATE_MESSAGE: "You don't have any recordings yet.",
  },
  ACTIVE_SESSION_DASHBOARD: {
    SESSION_HEADING: 'Active Sessions',
    TERMINATE_LOGOUT: 'Terminate and Logout',
    EMPTY_STATE_MESSAGE: "There are no active sessions at the moment.",
    EMPTY_SEARCH_MESSAGE: "No search results found.",
    SEARCH_BAR_PLACEHOLDER: 'Search active sessions username and ip',
    SESSION: 'active session',
    COLUMN_HEADER_NAMES :{
      USERNAME: 'Username',
      DATE_TIME: 'Date & Time',
      IP_METADATA: 'IP & Device Metadata',
      DURATION: 'Duration',
      HOURS_MINS_SEC: 'hh:mm:ss',
    },
    TERMINATE: {
      CONFIRMATION: 'Are you certain you wish to terminate and log out {username}? This action will promptly log the user(s) out, resulting in the loss of access and any unsaved changes in all open applications.',
      HEADING: 'Terminate User Session',
      ACTION: "Proceed",
    }

  },
  SESSION_RECORDING_SUMMARY_CARD: {
    SUMMARY: 'Summary',
    COPY_TOOLTIP: 'Copied',
    IP_ADDRESS: 'IP Address',
    DURATION: 'Duration',
    LOCATION: 'Location',
    FILES_DOWNLOADED: 'Files Downloaded',
    FILES_UPLOADED: 'Files Uploaded',
    TOTAL_BANDWIDTH: 'Total Bandwidth',
    APPS_ACCESSED_MESSAGE: 'Apps accessed during the session',
    VIOLAIONS_MESSAGE: 'Violations',
    VIOLAIONS_DETAILS_MESSAGE: 'Violations details',
    VIOLATION_CATEGORY: 'category',
    VIOLATION_DESCRIPTION: 'description',
    VIOLATION_ACTIONS: 'actions',
    VIOLATION_TIMESTAMP: 'timestamp',
  },
  DELETE: {
    ACTION: 'Delete',
    CONFIRM_DELETE_MESSAGE: 'Are you sure you wish to delete this recording? Deletion is permanent and irreversible.',
    DELETE_SESSION_RECORDING: 'Delete Session Recording',
    SUCCESS_MESSAGE: 'Session recording is deleted successfully.',
    ERROR_MESSAGE: 'Unable to delete session recording. Please try again later.',
  },
  SESSION_RECORDING_PAGE: {
    HEADING: 'User Sessions',
    POLICY_VIOLATIONS: 'Policy Violations',
    NO_POLICIES: 'No policies violated',
  },
  SESSION_RECORDING_PLAYER: {
    FILE_NOT_FOUND: 'File not found',
  },
  SETTINGS: {
    HEADING: 'User Session Recordings',
    OFF: 'Off',
    ON: 'On',
    TURN_ON_OR_OFF_TEXT: 'Turn user session recordings on or off',
    ENABLE: 'Enable',
    DISABLE: 'Disable',
    APPLICATION_CREDENTIALS_HEADING: 'User Application Credentials',
    APPLICATION_CREDENTIALS_TEXT: 'Allow user to save their application credentials',
    SESSION_IDLE_TIMEOUT_HEADING: 'Session Login Idle Timeout',
    SESSION_IDLE_TIMEOUT_TEXT: 'Specify user session idle timout in minutes.The default value is {defaultValue} minutes',
    SESSION_IDLE_TIMEOUT_LABEL: 'Authentication Idle Timeout',
    LINES_PER_PAGE_HEADING: 'User Sessions per page',
    LINES_PER_PAGE_TEXT:
      'Specify the number of values per page.The default value is 100.',
    ROTATION_PERIOD_HEADING: 'Rotation Period',
    ROTATION_PERIOD_TEXT: 'Specify the rotation period.',
    TIME: 'Time',
    DISK_SPACE: 'Disk Space (Default 16 MB)',
    RETENTION_PERIOD_HEADING: 'Retention Period',
    RETENTION_PERIOD_TEXT: 'Specify the duration for archiving user sessions.',
    RECORDINGS_FOR_90_DAYS: 'Keep recordings for the last 90 days',
    RECORDINGS_FOR_1_YEAR: 'Keep recordings for the last 1 year',
    DELETE: 'Delete',
  },
};
export default sessionRecordingMessages;
