import * as React from 'react';
import { AppBar, AppBarProps, Grid, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import IconFromSvg from '../../atoms/IconFromSvg';
import NotificationWithBadgeSvg from '../../../../public/assets/notification-icon-badge.svg';
import NotificationWithOutBadgeSvg from '../../../../public/assets/notification-icon-without-badge.svg';
import theme, { EXTRA_COLORS } from '@utils/theme';
import HeaderUserProfile from '../../molecules/HeaderUserProfile';
import messages from '@utils/messages';
import { useShowOrHideElement } from '@utils/Hooks/index.hook';
import { useAuth } from '@utils/Auth/index.hook';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@utils/routes';
import DeleteOrLogoutUserPopUp from '../../molecules/DeleteUserPopUp';
import loginMessages from '@utils/messages/loginMessages';
import LogoutSvg from '../../../../public/assets/logout.svg';
import DropDownMenu from '../../molecules/DropDownMenu';
import { EMAIL, IDP_CONNECTION_ID, ROLE, USER_ID } from '@utils/constants';
import { defaultAppIcon } from '@utils/test-constants';
import { connect, RootStateOrAny } from 'react-redux';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Redux/Store/store';
import NotificationDashboardService from '../../../api-service/notification-dashboard-service/NotificationDashboardService';
import NotificationIconTooltip from '../../molecules/NotificationIconTooltip';
import socketIOClient from 'socket.io-client';
import Config from '../../../../config/config';
import { notificationAction } from '../../../Redux/Reducers/notification';
import { getUserId, getRole, DEFAULT_APP_ICON} from 'src/core-utils/Helper/helper';
import { sessionActions } from 'src/Redux/Reducers/session';

interface IHeaderProps extends AppBarProps {
  username?: string | null;
  usercompany?: string | null;
  isImage?: boolean;
  imageSrc?: string;
  hideDetailsAndLogout?: boolean;
  authToken: string;
}
const StyledHeader = styled(AppBar)({
  backgroundColor: theme.palette.common.white,
  borderBottom: '1px solid rgba(238, 238, 238, 1)',
  height: theme.spacing(16.25),
  width: '100%',
  paddingLeft: theme.spacing(3.25),
  color: theme.palette.common.black,
});

const MainHeader = ({
  username,
  usercompany,
  isImage,
  imageSrc,
  hideDetailsAndLogout,
  authToken,
  ...rest
}: IHeaderProps) => {
  const { hideElement, toggleDisplay } = useShowOrHideElement();
  const history = useHistory();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const { logout } = useAuth();
  const headerData = useAppSelector((state: RootStateOrAny) => state.header);

  const [notificationBadge, setNotificationBadge] = useState<boolean>(false);
  const [newNotificationsCount, setNewNotificationsCount] = useState<number>(0);
  const dispatch = useAppDispatch();

  const newNotifications = useAppSelector(
    (state) => state.notification.newNotification,
  );

  const notificationChannelConnected = useAppSelector(
    (state) => state.notification.isConnected,
  );

  const role = useAppSelector(
    (state: RootStateOrAny) => state?.token?.role,
  );

  const getNewNotification = async () => {
    const userId = getUserId();
    const notifications = await NotificationDashboardService.getNewNotifications();
    dispatch(notificationAction.setNewNotification(notifications));
    setNewNotificationsCount(notifications?.length ?? 0);
  };

  const { location } = history;

  const handleNotificationClick = () => {
    // if (location.pathname.localeCompare(ROUTES.NOTIFICATIONS))
    history.push(ROUTES.NOTIFICATIONS);
  };

  const onLogoutClick = async () => {
    history.push(ROUTES.LOGOUT);
  };

  const getRequiredEmail = () => {
    if (localStorage.getItem(EMAIL) && localStorage.getItem(EMAIL) !== 'null') {
      return localStorage.getItem(EMAIL) ?? '';
    } else {
      return getUserId() ?? '';
    }
  };

  const getHeaderDropDownData = () => {
    const fullName = headerData?.entities?.userName;
    const email = getRequiredEmail();
    return { name: fullName, email: email };
  };

  const handleToggleModal = () => {
    setOpenModal(!openModal);
    hideElement();
  };

  const handleProfileClick = () => {
    history.replace(ROUTES.SETTINGS);
    hideElement();
  };

  const ACTIONS_HEADER = [
    {
      text: messages.LOGOUT,
      icon: LogoutSvg,
      activeIcon: LogoutSvg,
      handleClick: handleToggleModal,
    },
  ];

  const getNotificationOnInterval = () => {};

  useEffect(() => {
    // const socket = socketIOClient(`${Config.NOTIFICATION_URL}`);
    // if (!notificationChannelConnected) {
    //   socket.on('notification', (notification: any) => {
    //     const { action, data } = notification;
    //     dispatch(notificationAction.setNewNotification(data));
    //   });
    // }
    // const pushInterval = setInterval(async () => {
    //   const notifications = await NotificationDashboardService.getNotificationFromChannel();
    // }, 5000);
    getNewNotification();
    // return () => {
    //   clearInterval(pushInterval);
    //   socket.off('notification');
    // };
  }, []);

  return (
    <Grid container justifyContent="space-between">
      <DeleteOrLogoutUserPopUp
        headingText={loginMessages.CONFIRM_LOGOUT_HEADING}
        content={loginMessages.CONFIRM_LOGOUT_MESSAGE}
        buttonText={loginMessages.LOGOUT_BUTTON_TEXT}
        open={openModal}
        onButtonClick={onLogoutClick}
        handleClose={handleToggleModal}
      />
      <StyledHeader position="static" {...rest}>
        <Toolbar>
          <Grid item container direction="row">
            <Grid
              item
              sx={{ cursor: 'pointer', overflow: 'hidden' }}
              onClick={() => history.push(ROUTES.MY_APPLICATIONS)}
            >
              <IconFromSvg
                path={headerData?.entities?.organizationLogo || DEFAULT_APP_ICON}
                height={36}
                alt="organization-logo"
              />
            </Grid>
            <Grid
              item
              sx={{ cursor: 'pointer' }}
              onClick={() => history.push(ROUTES.MY_APPLICATIONS)}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  paddingLeft: theme.spacing(2.25),
                  paddingTop: theme.spacing(1.25),
                }}
              >
                {headerData?.entities.organizationName ??
                  messages.SONET_HEADING}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container justifyContent="flex-end" alignItems="center">
            {/* <NotificationIconTooltip
              newNotificationsCount={
                newNotifications.length ? newNotifications.length : 0
              }
            > */}
            {['admin', 'superadmin'].includes(role?.toLowerCase()) && (
              <Grid
                item
                container
                justifyContent="center"
                onClick={handleNotificationClick}
                sx={{
                  width: theme.spacing(9),
                  height: theme.spacing(9),
                  border: `1px solid ${EXTRA_COLORS.alphaPrimary_100}`,
                  borderRadius: '50%',
                  backgroundColor: EXTRA_COLORS.alphaPrimary_100,
                  mr: theme.spacing(5),
                  cursor: 'pointer',
                }}
              >
                {newNotifications.length > 0 ? (
                  <IconFromSvg
                    path={NotificationWithBadgeSvg}
                    alt="notification"
                  />
                ) : (
                  <IconFromSvg
                    path={NotificationWithOutBadgeSvg}
                    alt="notification"
                  />
                )}
              </Grid>
            )}
            {/* </NotificationIconTooltip> */}
            <Grid item>
              {!hideDetailsAndLogout && (
                <DropDownMenu
                  menuItems={ACTIONS_HEADER}
                  headerData={getHeaderDropDownData()}
                  handleProfileClick={handleProfileClick}
                  menuWidth="fit-content"
                  itemForDropdownMenu={
                    <HeaderUserProfile
                      isImage={isImage ? isImage : false}
                      imageSrc={imageSrc}
                      username={username}
                      usercompany={
                        usercompany === (null || undefined || 'null')
                          ? ''
                          : usercompany
                      }
                      handleUserProfileClick={toggleDisplay}
                    />
                  }
                />
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </StyledHeader>
    </Grid>
  );
};


const mapStateToProps = (state: any) => ({
  authToken: state?.token?.session?.authToken
})

export default connect(mapStateToProps)(MainHeader);
