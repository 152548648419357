import { Grid, Typography } from '@mui/material';
import { ITooltipValidationParams } from '@utils/constants';
import { isPasswordValid } from '@utils/Helper/helper';
import theme, { EXTRA_COLORS } from '@utils/theme';
import React from 'react';

import messages from '../../../core-utils/messages';
import PasswordTextField from '../../molecules/PasswordTextField';
import TooltipForValidation from '../../molecules/TooltipForValidation';

export type OnBoardingPasswordFormProps = {
  handleTextFieldChange: (value: any) => void;
  passwordTooltipParams: ITooltipValidationParams[];
  onBoardingDetails: any;
  passwordErrorMessage: any;
  validateOnBoardingPassword: () => void;
};

const OnBoardingPasswordForm = ({
  handleTextFieldChange,
  passwordTooltipParams,
  onBoardingDetails,
  passwordErrorMessage,
  validateOnBoardingPassword,
}: OnBoardingPasswordFormProps) => {
  const {
    HEADING,
    SUB_HEADING,
    PASSWORD_PLACEHOLDER,
    PASSWORD_LABEL,
    CONFIRM_PASSWORD,
    CONFIRM_PASSWORD_PLACEHOLDER,
  } = messages.PASSWORD_FORM;

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-evenly"
      sx={{
        background: theme.palette.common.white,
        border: '1px solid',
        borderColor: EXTRA_COLORS.blue_100,
        boxSizing: 'border-box',
        borderRadius: 1,
        boxShadow: theme.shadows[10],
        width: theme.spacing(178),
        height: theme.spacing(68),
        paddingLeft: theme.spacing(17),
        paddingRight: theme.spacing(17),
      }}
    >
      <Grid item container direction="column" textAlign="center">
        <Grid item>
          <Typography variant="h3">{HEADING}</Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            sx={{
              color: theme.palette.text.secondary,
              paddingTop: theme.spacing(1),
            }}
          >
            {SUB_HEADING}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <TooltipForValidation
          validationItems={passwordTooltipParams}
          heading={messages.PASSWORD_PARAMETERS_MESSAGE}
        >
          <PasswordTextField
            name={PASSWORD_LABEL}
            label={PASSWORD_LABEL}
            value={onBoardingDetails[PASSWORD_LABEL]}
            placeholder={PASSWORD_PLACEHOLDER}
            inputProps={{ 'data-testid': 'content-input' }}
            required
            autoComplete={'new-password'}
            error={passwordErrorMessage[PASSWORD_LABEL] !== ''}
            helperText={passwordErrorMessage[PASSWORD_LABEL]}
            handleChange={handleTextFieldChange}
            onBlur={validateOnBoardingPassword}
            validPassword={isPasswordValid(onBoardingDetails[PASSWORD_LABEL])}
          />
        </TooltipForValidation>
      </Grid>
      <Grid item>
        <PasswordTextField
          name={CONFIRM_PASSWORD}
          label={CONFIRM_PASSWORD}
          value={onBoardingDetails[CONFIRM_PASSWORD]}
          inputProps={{ 'data-testid': CONFIRM_PASSWORD }}
          placeholder={CONFIRM_PASSWORD_PLACEHOLDER}
          required
          autoComplete="off"
          error={passwordErrorMessage[CONFIRM_PASSWORD] !== ''}
          helperText={passwordErrorMessage[CONFIRM_PASSWORD]}
          handleChange={handleTextFieldChange}
          onBlur={validateOnBoardingPassword}
        />
      </Grid>
    </Grid>
  );
};

export default OnBoardingPasswordForm;
