const onBoardingMessages = {
  PASSWORD_FORM: {
    HEADING: 'Set Password',
    SUB_HEADING: 'This password will be used to login to your account',
    PASSWORD_LABEL: 'Password',
    PASSWORD_PLACEHOLDER: 'Enter password here',
    INVALID_PASSWORD: 'Password does not meet the required criteria',
    CONFIRM_PASSWORD: 'Confirm Password',
    PASSWORD_NO_MATCH: 'Passwords do not match',
    CONFIRM_PASSWORD_PLACEHOLDER: 'Confirm Password here',
  },
  LEFT_PANEL: {
    USER: 'User',
    HEADING1: 'Welcome {name},',
    HEADING2: 'to the sonet.io service!',
    SUB_HEADING:
      'This initial onboarding process will help you setup your account in a few simple steps.',
  },
  EUSA_STEP2: {
    HEADING1: 'End User Subscription Agreement',
    COUNTRY_ORIGIN: [
      {
        id: '1',
        name: 'English(North America)',
        value: 'English(North America)',
      },
    ],
    SUBSCRIPTION_TEXT_P1: `This End User Subscription Agreement is a legal agreement between the person or
      organisation who has ordered the services for sonet.io. Please read the `,
    SUBSCRIPTION_TEXT_P2: ` of this agreement before accessing or using the services.`,
    HEADING2: `SONET`,
    TERMS_AND_CONDITION: 'Terms & Conditions',
    TERMS_AGREE:
      'Check here to indicate that you have agreed to the terms of the Sonet EUSA agreement.',
    TERM_OF_USE: `BY USING THE SERVICES, YOU ARE ACCEPTING AND AGREEING TO BECOME
    BOUND BY THE TERMS OF THIS AGREEMENT. IF YOU ARE NOT WILLING TO BE
    BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT, OR DO NOT HAVE
    AUTHORITY TO BIND CUSTOMER TO THIS AGREEMENT, THEN SELECT THE
    “Decline” BUTTON AND DO NOT ACCESS OR USE THE SERVICES. You may not,
    or permit anyone to: (i) copy, reverse engineer, decompile,
    disassemble or otherwise attempt to discover the source code, object
    code or underlying structure, ideas or algorithms of the Services or
    any software, documentation or data related to or provided with the
    Services; (ii) translate, modify, make derivative work of, the
    Services or any of its components and/or outputs; (iii) assign,
    (i) copy, reverse engineer, decompile,
    disassemble or otherwise attempt to discover the source code, object
    code or underlying structure, ideas or algorithms of the Services or
    any software, documentation or data related to or provided with the
    Services; (ii) translate, modify, make derivative work of, the
    Services or any of its components and/or outputs; (iii) assign,`,
  },
  INFORMATION_FORM: {
    HEADING: 'User Information',
    SUB_HEADING: 'Update your user account information',
    PLACEHOLDERS: {
      PLACEHOLDER_NAME: 'Enter your name here',
      PLACEHOLDER_PHONE: 'Enter your phone number here',
    },
    USER_LOGO: 'User Logo',
    USER_NAME: 'Name',
    USER_PHONE: 'Phone Number',
    USER_PHONE_CODE: 'User Phone Code',
    REMOVE: 'Remove Image',
  },
  ORGANISATION_FORM: {
    HEADING: 'Organization Information',
    SUB_HEADING: 'Update your organization information',
    ORGANISATION_NAME: 'Organization Name',
    ORGANISATION_ADDRESS: 'Organization Address',
    ORGANISATION_PHONE: 'Organization Phone',
    ORGANISATION_LOGO: 'Organization Logo',
    INVALID_LOGO_IMAGE_TYPE: 'Logo image of type {mimeType} is not supported.',
    LOGO_TOO_BIG: 'Please select an image less than 5MB.',
    ORGANISATION_PHONE_CODE: 'Organization Phone code',
    PLACEHOLDERS: {
      PLACEHOLDER_NAME: 'Enter your organization name here',
      PLACEHOLDER_ADDRESS: 'Enter your organization address here',
      PLACEHOLDER_PHONE: 'Enter your organization phone here',
    },
  },
  ADD_LOGO: 'Add Logo',
  STEP_INFO: `STEP {currentStep} OUT OF {totalSteps}`,
  SYSTEM_CONFIGURATION_FORM: {
    HEADING: 'Settings',
    SUB_HEADING: 'Configure initial settings',
    TIMEZONE: 'Timezone',
    LANGUAGE: 'Language',
  },
  END_USER_PASSWORD_FORM: {
    HEADING: 'Set New Password',
    SUB_HEADING: 'This password will be used to login to your account',
    OLD_PASSWORD_LABEL: 'Temporary Password',
    OLD_PASSWORD_PLACEHOLDER: 'Enter temporary password here',
    NEW_PASSWORD_LABEL: 'New Password',
    NEW_PASSWORD_PLACEHOLDER: 'Enter password here',
    CONFIRM_PASSWORD: 'Confirm New Password',
    CONFIRM_PASSWORD_PLACEHOLDER: 'Confirm new password here',
    INVALID_PASSWORD: 'Password does not meet the required criteria',
    NEW_PASSWORD_ERROR:
      'The new password cannot be the same as your temporary password',
    CONFIRM_NEW_PASSWORD_ERROR:
      'The new password and confirmation password do not match',
    PASSWORD_NO_MATCH: 'Passwords do not match',
  },
  AUTHENTICATION_SETUP_FORM: {
    HEADING: 'Authentication Setup',
    SUB_HEADING: 'Multifactor authentication has been enabled on your account',
    SCAN_CODE: '1. Scan QR Code',
    SCAN_CODE_HELPER:
      'To complete your onboarding, scan the QR code below with the two-factor authentication application you have installed on your phone or device.',
    VERIFICATION_CODE: '2. Verification code',
    VERIFICATION_CODE_HELPER:
      'After scanning the QR code, enter the 6 digit code displayed to verify that onboarding was successful.',
    TEXT_FIELD_PLACEHOLDER: 'Enter the 6 digit verification code here',
    VERIFY_BUTTON_LABEL: 'Verify',
    VERIFICATION_FAILED: 'Verification failed',
    VERIFICATION_SUCCESS: 'Verification successful',
  },
  SAVING_FORM: {
      HEADING: 'Saving',
      ERROR_HEADING: 'Unable to Save',
      SUB_HEADING: 'Please wait while we onboard your account.',
  },
  BUTTON_TEXT: {
    GET_STARTED: 'Get Started',
    NEXT: 'Next',
    RETRY_AGAIN: 'Retry Again'
  }
};

export default onBoardingMessages;
