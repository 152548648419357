const helpMessages = {
  SEARCH_PLACEHOLDER: 'Search for topics or keywords',
  HEADING: 'Help Center',
  TOUR_HEADING: 'Take a Tour',
  TOUR_BODY:
    'Get step-by step instructions for the screen you are currently on.',
  FREQUENTLY_ASKED: 'Frequently Asked Questions',
  BACK: 'Back',
};

export default helpMessages;
