import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { getPolicyBlocks } from '../../../core-utils/Helper/helper';
import policyBuilderMessages from '../../../core-utils/messages/policyBuilder';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import PolicyBlock, {
  IPolicyBlockProps,
} from '../../atoms/DraggablePolicyBlock';
import PolicyBlockSearchBar from '../../molecules/PolicyBlockSearchBar';

export interface IPolicyBlocksPanelProps {
  policyBlocks: IPolicyBlockProps[];
}

const PolicyBlocksPanel = ({ policyBlocks }: IPolicyBlocksPanelProps) => {
  const [searchResults, setSearchResults] = useState<IPolicyBlockProps[]>(
    policyBlocks,
  );
  const handleChange = (e: any) => {
    const searchText = e.target.value;
    if (searchText == '') {
      setSearchResults(policyBlocks);
    } else {
      setSearchResults(getPolicyBlocks(searchText, policyBlocks));
    }
  };

  const resultBlocks = (
    <Grid container direction="column">
      {searchResults.map((policyBlock, index) => {
        return (
          <Grid item key={index}>
            <PolicyBlock {...policyBlock} />
          </Grid>
        );
      })}
    </Grid>
  );

  return (
    <Grid
      container
      width={'100%'}
      height={'100%'}
      px={theme.spacing(4)}
      pt={theme.spacing(3)}
      sx={{
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${EXTRA_COLORS.lightGrayishBlue}`,
      }}
      direction="column"
    >
      <Grid item paddingBottom={2.5}>
        <Typography
          variant="subtitle2"
          sx={{ color: theme.palette.text.primary }}
        >
          {policyBuilderMessages.POLICY_BLOCKS}
        </Typography>
      </Grid>
      {/* <Grid item paddingBottom={3}>
        <PolicyBlockSearchBar
          placeholder={
            policyBuilderMessages.SEARCH_PLACEHOLDER_FOR_POLICY_BLOCKS
          }
          sx={{ width: '100%' }}
          handleChange={handleChange}
        />
      </Grid> */}
      {resultBlocks}
    </Grid>
  );
};
export default PolicyBlocksPanel;
