import { SIDE_NAVIGATION_ITEM_NAMES } from '@utils//constants';
import DashboardActiveSvg from '@assets/dashboard-active.svg';
import DashboardInactiveSvg from '@assets/dashboard-inactive.svg';
import UserManagementActiveSvg from '@assets/user-management-active.svg';
import UserManagementInactiveSvg from '@assets/user-management-inactive.svg';
import AppManagementActiveSvg from '@assets/app-management-active.svg';
import AppManagementInactiveSvg from '@assets/app-management-inactive.svg';
import PoliciesActiveSvg from '@assets/policies-active.svg';
import PoliciesInactiveSvg from '@assets/policies-inactive.svg';
import SessionActiveSvg from '@assets/user-activity-active.svg';
import SessionInactiveSvg from '@assets/user-activity-inactive.svg';
import LogsActiveSvg from '@assets/logs-active.svg';
import LogsInactiveSvg from '@assets/logs-inactive.svg';
import SettingsInactiveSvg from '@assets/settings-inactive.svg';
import SettingsActiveSvg from '@assets/settings-active.svg';
import HelpInactiveSvg from '@assets/help-inactive.svg';
import HelpActiveSvg from '@assets/help-active.svg';
import HomeActiveSvg from '@assets/home-active.svg';
import HomeInactiveSvg from '@assets/home-inactive.svg';
import ReportsInactiveSvg from '@assets/reports-inactive.svg';
import ReportsActiveSvg from '@assets/reports-active.svg';

const {
  DASHBOARD,
  APP_MANANGEMENT,
  HELP,
  SETTINGS,
  POLICIES,
  SESSION_RECORDINGS,
  LOGS,
  USER_MANAGEMENT,
  MY_APPLICATIONS: END_USER_DASHBOARD,
  REPORTS
} = SIDE_NAVIGATION_ITEM_NAMES;

const sideNavImageGetter = (name: string, isActive: boolean) => {
  switch (name) {
    case DASHBOARD:
      return isActive ? DashboardActiveSvg : DashboardInactiveSvg;
    case USER_MANAGEMENT:
      return isActive ? UserManagementActiveSvg : UserManagementInactiveSvg;
    case APP_MANANGEMENT:
      return isActive ? AppManagementActiveSvg : AppManagementInactiveSvg;
    case POLICIES:
      return isActive ? PoliciesActiveSvg : PoliciesInactiveSvg;
    case SESSION_RECORDINGS:
      return isActive ? SessionActiveSvg : SessionInactiveSvg;
    case LOGS:
      return isActive ? LogsActiveSvg : LogsInactiveSvg;
    case SETTINGS:
      return isActive ? SettingsActiveSvg : SettingsInactiveSvg;
    case HELP:
      return isActive ? HelpActiveSvg : HelpInactiveSvg;
    case END_USER_DASHBOARD:
      return isActive ? HomeActiveSvg : HomeInactiveSvg;
    case REPORTS:
      return isActive ? ReportsActiveSvg : ReportsInactiveSvg;
  }
};

export default sideNavImageGetter;
