import React from 'react';

export default function Loader() {

  return (
    <div cy-data-id="loader" className="w-full flex justify-center items-center">
      <svg
        className="h-16 "
        viewBox="0 0 100 100"
        enableBackground="new 0 0 0 0"
      >
        <circle fill="#fff" stroke="none" cx="24" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.24" />
        </circle>
        <circle fill="#fff" stroke="none" cx="50" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.5" />
        </circle>
        <circle fill="#fff" stroke="none" cx="76" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.76" />
        </circle>
      </svg>
    </div>
  )

}