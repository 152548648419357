import {
  LocalizationProvider,
  DateRangePicker as MuiDateRangePicker,
} from '@mui/lab';
import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Grid, IconButton, Typography, useTheme } from '@mui/material';
import React from 'react';
import messages from '../../../core-utils/messages';
import IconFromSvg from '../../atoms/IconFromSvg';
import CalendarInactiveIconSvg from '../../../../public/assets/calendar-active.svg';

export interface IDateRangePickerProps {
  open: boolean;
  dates: DateRange<Date>;
  placeholder?: string;
  calendars?: 1 | 2 | 3;
  handleChange: (dates: DateRange<Date>) => void;
  handleClose: () => void;
  handleOpen: () => void;
  handleClickCapture?: () => void;
}

const DateRangePicker = ({
  open,
  dates,
  placeholder = messages.PICK_DATE_MESSAGE,
  calendars = 1,
  handleChange,
  handleClose,
  handleOpen,
  handleClickCapture,
}: IDateRangePickerProps) => {
  const theme = useTheme();
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MuiDateRangePicker
          data-testid="date-picker"
          open={open}
          onClose={handleClose}
          calendars={calendars}
          value={dates}
          maxDate={new Date()}
          onChange={handleChange}
          renderInput={() => (
            <Grid
              container
              alignItems="center"
              sx={{ paddingLeft: theme.spacing(2.5) }}
            >
              <IconButton
                onClick={handleOpen}
                onClickCapture={handleClickCapture}
              >
                <IconFromSvg path={CalendarInactiveIconSvg} alt="calendar" />
              </IconButton>
              <Grid
                item
                onClick={handleOpen}
                onClickCapture={handleClickCapture}
                sx={{ cursor: 'pointer' }}
              >
                {dates[0] && dates[1] ? (
                  <Typography
                    variant="caption"
                    color={theme.palette.primary.main}
                  >
                    {dates[0].toLocaleDateString()}-
                    {dates[1].toLocaleDateString()}
                  </Typography>
                ) : (
                  <Typography
                    variant="caption"
                    color={theme.palette.text.secondary}
                  >
                    {placeholder}
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
        />
      </LocalizationProvider>
    </>
  );
};

export default DateRangePicker;
