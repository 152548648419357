import * as React from 'react';
import Chip, { IChipProps } from '../../atoms/Chip';
import { Box } from '@mui/material';
import theme from '../../../core-utils/theme';

interface IChipsGroupProps {
  chips: IChipProps[];
  displayAsGreyChips?: boolean;
  showAllChips?: boolean;
  noOfChips?: number;
}

const ChipsGroup = ({
  chips,
  displayAsGreyChips,
  showAllChips,
  noOfChips,
}: IChipsGroupProps) => {
  const defaultNoOfChips = noOfChips ? noOfChips : 3;
  const limit = showAllChips ? chips.length : defaultNoOfChips;
  return (
    <Box display="flex" flexDirection="row">
      {chips.map((chipProps, index) => {
        return (
          index < limit && (
            <Box
              sx={{
                marginRight: index < chips.length - 1 ? theme.spacing(1) : 0,
              }}
              display="flex"
              key={index}
            >
              <Chip
                {...chipProps}
                shade={displayAsGreyChips ? 'grey' : chipProps.shade}
                hasBorder={displayAsGreyChips || chipProps.hasBorder}
              />
            </Box>
          )
        );
      })}
      {chips.length > limit && (
        <Chip
          label={`+${chips.length - defaultNoOfChips}`}
          shade="grey"
          hasBorder
        />
      )}
    </Box>
  );
};

export default ChipsGroup;
