import React, { useEffect, useState } from "react";
import { ringer } from './audioFile';

export default function CallInvitation(props: {
  name: string;
  onAccept: () => void;
  onReject: () => void;
}) {
  const [playSound, setPlaySound] = useState(true);

  useEffect(() => {
    const ringerSound = new Audio(ringer);
    ringerSound.loop = true;
    ringerSound.play().catch((error) =>
      console.error("Error playing sound:", error)
    );

    return () => {
      ringerSound.pause();
      ringerSound.currentTime = 0;
    };
  }, []);

  return (
    <div className="fixed top-4 right-4 h-36 w-96 rounded-lg shadow-lg bg-gradient-to-br from-gray-50 to-blue-100 flex items-center justify-center flex-col">
      <div className="flex flex-col items-center justify-center pb-2 text-gray-800">
        You are being invited to a teams meeting call{" "}
        <span className="text-blue-500">
          {props.name ? `by ${props.name}` : ""}
        </span>
      </div>
      <div className="flex items-center justify-around w-full py-1">
        <button
          onClick={props.onAccept}
          className="bg-green-500 text-white px-4 py-2 rounded-full h-14 w-14 text-xs font-light flex justify-center items-center border-none hover:scale-105 transition-all duration-300 ease-in-out cursor-pointer animate-pulse"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
            />
          </svg>
        </button>
        <button
          onClick={props.onReject}
          className="bg-red-500 text-white px-4 py-2 rounded-full h-14 w-14 text-xs font-light flex justify-center items-center border-none hover:scale-105 transition-all duration-300 ease-in-out cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}