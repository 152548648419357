import React, { SyntheticEvent } from 'react';
import { isTabSwitchLocked } from '../../../core-utils/Helper/helper';
import { useShowOrHideElement } from '../../../core-utils/Hooks/index.hook';

export const useTabs = (
  defaultIndex?: number,
  handleSwitchDenied?: (
    event: SyntheticEvent<Element, Event>,
    nextValue: any,
  ) => void,
  tabChangehandler?: (tabId: number) => any,
) => {
  const [activeTabValue, setActiveTabValue] = React.useState(defaultIndex ?? 0);

  const handleTabChange = (
    _event: SyntheticEvent<Element, Event>,
    value: any,
  ) => {
    if (!isTabSwitchLocked()) {
      setActiveTabValue(value);
    } else {
      handleSwitchDenied && handleSwitchDenied(_event, value);
      tabChangehandler && tabChangehandler(value);
    }
  };

  return {
    activeTabValue,
    setActiveTabValue,
    handleTabChange,
  };
};
