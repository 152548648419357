import axios from '../axios';
import Config from '../../../config/config';

const HelpService = {
  getHelpFaqs: async () => {
    try {
      const helpFaqResponse = await axios.get(
        `${Config.DASHBOARD_API_URL}help`,
      );
      return helpFaqResponse.data;
    } catch (error) {
      return error;
    }
  },
  getHtmlContent: async (questionId: string | number) => {
    try {
      const htmlContentResponse = await axios.get(
        `${Config.DASHBOARD_API_URL}help/htmlFile/${questionId}`,
      );
      return htmlContentResponse.data;
    } catch (error) {
      console.error(error);
    }
  },
};

export default HelpService;
