import { Box, Typography } from '@mui/material';
import onBoardingMessages from '@src/core-utils/messages/onBoarding';
import React, { useEffect, useState } from 'react';
import { RootStateOrAny } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthenticationSetupForm from 'src/_components/organisms/AuthenticationSetupForm';

import { sessionActions } from '@src/Redux/Reducers/session';
import LoadingAnimation from '@src/_components/atoms/LoadingAnimation';
import { updateHeaderData } from '../../../Redux/Reducers/header';
import { useAppDispatch, useAppSelector } from '../../../Redux/Store/store';
import SettingsService, { ISessionRecordingSettingsResponse } from '../../../api-service/settings-service/SettingsService';
import UserService from '../../../api-service/user-service/UserService';
import { getUserId } from '../../../core-utils/Helper/helper';
import { ILogSettingsResponse } from '../../../core-utils/constants';
import messages from '../../../core-utils/messages';
import ONBOARDING_FLOW_MESSAGES from '../../../core-utils/messages/onboarding_flow_messages';
import { ROUTES } from '../../../core-utils/routes';
import { LIST_OF_PROGRESS_BAR_LABELS } from '../../../core-utils/test-constants';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import AdministratorInformationForm from '../../organisms/AdministratorInformationForm';
import OnBoardingFooter from '../../organisms/OnBoardingFooter';
import OnBoardingHeader from '../../organisms/OnBoardingHeader';
import OnBoardingLeftPanel from '../../organisms/OnBoardingLeftPanel';
import OnboardingOrganisationForm from '../../organisms/OnboardingOrganisationForm';
import OnBoardingPasswordForm from '../../organisms/OnboardingPasswordForm';
import SystemConfigurationForm from '../../organisms/SystemConfigurationForm';
import OnBoardingTemplate from '../../templates/OnBoardingTemplate';
import useOnboardingPageData, { ORGANISATION_ADDRESS, ORGANISATION_LOGO, ORGANISATION_NAME, ORGANISATION_PHONE, ORGANISATION_PHONE_CODE } from './index.hook';
import errorMessages from '@utils/messages/error';

const style = {
  width: theme.spacing(178.5),
  height: theme.spacing(87.25),
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${EXTRA_COLORS.blue_100}`,
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[10],
};

type TSavingForm = {
  errorMessage: string | null;
}

const OnboardingPage = () => {
  const {
    isButtonDisabled,
    currentStep,
    setIsButtonDisabled,
    handlePasswordChange,
    passwordTooltipParams,
    passwordErrorMessage,
    validateOnBoardingPassword,
    onBoardingDetails,
    adminInformationFormDetails,
    adminRole,
    adminFormCheckbox,
    handleAdminFormCheckedValue,
    handleNameChange,
    handleJobTitleChange,
    handleUsernameChange,
    handleRoleChange,
    onBackClick,
    onNextClick,
    handleOrganisationValidation,
    handleOrganisationTextFieldChange,
    handleLogoChange,
    handlePhoneCodeChange,
    handleCustomPhoneCodeChange,
    organisationDetails,
    configurationDetails,
    handleLanguageChange,
    handleTimezoneChange,
    // Added By Devang
    isAgreeTerms,
    setTermsAndConditionAgree,
    authenticationData,
    handleChangeVerificationCode,
    handleVerifyTotpEnrolment,
    totpVerificationSuccess,
    setOrganisationDetail,
    isValidLogo,
    logoValidationMessage
  } = useOnboardingPageData();

  const [agreement, setAgreement] = useState(false);
  const [qrCode, setQrCode] = useState<string>();
  const [secretKey, setSecretKey] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const mainHeaderData = useAppSelector(
    (state: RootStateOrAny) => state.header,
  );

  useEffect(() => {
    const userId = getUserId() ?? '';
    UserService.getTotpLogo(userId).then((logo: string) => {
      setQrCode(logo);
    });
    UserService.getTotpSecretKey(userId).then((r) => {
      setSecretKey(r);
    })
    setOrganisationDetail(mainHeaderData);
  }, []);

  const { PASSWORD_LABEL } = messages.PASSWORD_FORM;
  const {
    NAME,
    USERNAME,
    JOB_TITLE,
  } = ONBOARDING_FLOW_MESSAGES.ADMIN_FORM_MESSAGES;


  const { NEXT, RETRY_AGAIN, GET_STARTED } = onBoardingMessages?.BUTTON_TEXT;



  const dispatch = useAppDispatch();
  const history = useHistory();

  const SavingForm = ({ errorMessage }: TSavingForm) => {
    const {
      HEADING,
      SUB_HEADING,
      ERROR_HEADING
    } = onBoardingMessages.SAVING_FORM;
    return (
      <Box
        sx={style}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={errorMessage ? {
          border: `1px solid ${theme.palette.error.main}`
        } : undefined}
      >
        <Typography
          variant="h3"
          color={theme.palette.text.primary}
          paddingBottom={1}
        >
          {errorMessage ? ERROR_HEADING : HEADING}
        </Typography>
        <Typography
          variant="body2"
          color={errorMessage ? theme?.palette?.error?.main : theme?.palette?.text?.secondary}
          paddingBottom={9.5}
        >
          {errorMessage ? errorMessage : SUB_HEADING}
        </Typography>
        {!errorMessage && <Box display="flex" flexDirection="column" rowGap={7.5}>
          <LoadingAnimation compact={true} />
        </Box>}
      </Box>
    );

  }



  const formComponentList = {
    0: (
      <OnBoardingPasswordForm
        handleTextFieldChange={handlePasswordChange}
        passwordTooltipParams={passwordTooltipParams}
        onBoardingDetails={onBoardingDetails}
        passwordErrorMessage={passwordErrorMessage}
        validateOnBoardingPassword={validateOnBoardingPassword}
      />
    ),
    1: (
      <AuthenticationSetupForm
        qrCodeImg={qrCode}
        textKey={secretKey}
        authenticationData={authenticationData}
        handleChangeVerificationCode={handleChangeVerificationCode}
        handleClickVerify={handleVerifyTotpEnrolment}
        verificationStatus={totpVerificationSuccess}
      />
    ),
    // 1: (
    //   <OnBoardingEUSA
    //     isAgree={isAgreeTerms}
    //     agreementAcceptance={setTermsAndConditionAgree}
    //   />
    // ),
    2: (
      <OnboardingOrganisationForm
        handleOrganisationTextFieldChange={handleOrganisationTextFieldChange}
        handleLogoChange={handleLogoChange}
        handlePhoneCodeChange={handlePhoneCodeChange}
        handleCustomPhoneCodeChange={handleCustomPhoneCodeChange}
        organisationDetails={organisationDetails}
        handleOrganisationValidation={handleOrganisationValidation}
        isValidLogo={isValidLogo}
        logoValidationMessage={logoValidationMessage}
      />
    ),
    3: (
      <AdministratorInformationForm
        adminInformationFormDetails={adminInformationFormDetails}
        handleCheckboxChange={handleAdminFormCheckedValue}
        handleJobTitleChange={handleJobTitleChange}
        handleUsernameChange={handleUsernameChange}
        handleNameChange={handleNameChange}
        adminFormCheckbox={adminFormCheckbox}
      />
    ),
    4: (
      <SystemConfigurationForm
        timezone={configurationDetails.TIMEZONE}
        handleChangeTimeZone={handleTimezoneChange}
      />
    ),
  };

  const TOTAL_STEPS = Object.keys(formComponentList).length;

  const getFormWithRespectToStepValue = (stepValue: number) => {
    switch (stepValue) {
      case 0:
        return formComponentList[0];
      case 1:
        return formComponentList[1];
      case 2:
        return formComponentList[2];
      case 3:
        return formComponentList[3];
      case 4:
        if (isSaving) {
          return <SavingForm errorMessage={errorMessage} />;
        }
        return formComponentList[4];
      default:
        return formComponentList[0];
    }
  };

  const handleSavePasswordForm = async () => {
    try {
      await UserService.updateUserPassword(
        mainHeaderData?.entities?.userName,
        onBoardingDetails[PASSWORD_LABEL],
      );
    } catch (error) {
      return error;
    }
  };

  const handleSaveOrganizationFormAndSystemconfigForm = async () => {
    try {
      const currentAccountSettings = await SettingsService.getAccountSettings();

      const payload = {
        ...currentAccountSettings,
        organizationName: organisationDetails[ORGANISATION_NAME],
        organizationAddress: organisationDetails[ORGANISATION_ADDRESS],
        organizationPhone: organisationDetails[ORGANISATION_PHONE],
        suppportEmailAddress: adminInformationFormDetails[USERNAME],
        organizationPhoneCode: organisationDetails[ORGANISATION_PHONE_CODE],
        organizationLogo: organisationDetails[ORGANISATION_LOGO] || '',
        language: configurationDetails.LANGUAGE?.name,
        timeZone: configurationDetails.TIMEZONE?.name,
        tempPassword: '',
      };
      await SettingsService.updateAccountSettings(payload);
    } catch (error) {
      throw error;
    }
  };

  const handleSaveNotificationDetails = async () => {
    const currentUser = getUserId();
    let notificationSettingsResponse = await SettingsService.getNotificationsSettings();
    const administratorNotificationDetail = notificationSettingsResponse.administratorNotifications;
    let flag = true;
    let nAdminNotificationDetails: any;
    if (administratorNotificationDetail) {
      nAdminNotificationDetails = administratorNotificationDetail.map((n: { userId: string | null; }) => n.userId === currentUser ? { ...n, userName: adminInformationFormDetails[USERNAME], notificationEnabled: adminFormCheckbox, fullName: adminInformationFormDetails[NAME] } : n)
      flag = !(nAdminNotificationDetails?.map((n: { userId: string | null; }) => n.userId === currentUser));
    }
    if (flag) {
      // If the user is new , providing default values
      console.log("New admin user onboarding")
      const payload = {
        administratorNotifications: [{
          userId: getUserId(),
          fullName: adminInformationFormDetails[NAME],
          userName: adminInformationFormDetails[USERNAME],
          notificationDetail: [
            { id: 'Critical', name: 'Critical' },
            { id: 'High', name: 'High' },
            { id: 'Error', name: 'Error' },
          ],
          notificationEnabled: adminFormCheckbox,
        }],
        userNotificationsEnabled: true,
      };
      await SettingsService.updateNotificationsSettings(payload);
    } else {
      //If the user already exists
      const requiredNotificationsPayload = {
        ...notificationSettingsResponse,
        administratorNotifications: nAdminNotificationDetails,
      };
      await SettingsService.updateNotificationsSettings(
        requiredNotificationsPayload,
      );
    }
  };

  const handleSaveAdminInformationForm = async () => {
    try {
      const requestBody: any = {
        // username: getUserNameFromEmail(adminInformationFormDetails[USERNAME]),
        password: onBoardingDetails[PASSWORD_LABEL],
        attributes: {
          'guac-organizational-role': adminInformationFormDetails[JOB_TITLE],
          'guac-full-name': adminInformationFormDetails[NAME],
        },
      };
      await UserService.updateUser(getUserId() ?? '', requestBody);
      await handleSaveNotificationDetails();
    } catch (error) {
      return error;
    }
  };

  const intializeUserSessionsData = async () => {
    try {
      const requestBody: ISessionRecordingSettingsResponse = {
        administratorUserSessionsEnabled: false,
        saveCredsAllowed: true,
        userSessionsPerPage: 100,
        authenticationTimeout: 60,
        rotationPeriod: {
          time: 7,
          diskSpace: null,
        },
        retentionPeriod: '90',
      };
      await SettingsService.updateUserSessionsSettings(requestBody);
    } catch (error) {
      console.warn(error);
    }
  };

  const intializeLogsSesstingsData = async () => {
    try {
      const requestBody: ILogSettingsResponse = {
        linesPerPage: 100,
        rotationPeriod: {
          time: 7,
          diskSpace: null,
        },
        retentionPeriod: {
          years: 1,
          months: 6,
          days: 7,
        },
        defaultExportFormat: ['csv', 'json'],
      };
      await SettingsService.updateLogsSettings(requestBody);
    } catch (error) {
      console.warn(error);
    }
  };

  const updateHeader = () => {
    dispatch(
      updateHeaderData({
        ...mainHeaderData.entities,
        userName: adminInformationFormDetails[NAME],
        organizationName: organisationDetails[ORGANISATION_NAME],
        organizationLogo: organisationDetails[ORGANISATION_LOGO] || '',
        organizationPhone: organisationDetails[ORGANISATION_PHONE],
        suppportEmailAddress: adminInformationFormDetails[USERNAME],
        organizationAddress: organisationDetails[ORGANISATION_ADDRESS],
        organizationPhoneCode: organisationDetails[ORGANISATION_PHONE_CODE],
        language: configurationDetails.LANGUAGE?.name,
        timeZone: configurationDetails.TIMEZONE?.name,
      }),
    );
  };

  const setAdminLoginStatus = () => {
    UserService.setAdminFirstTimeLoginStatus(getUserId() ?? '', false);
  };

  const handleSaveClick = () => {
    let msg = '';
    setErrorMessage(null);
    setIsButtonDisabled(true);
    setIsSaving(true);
    Promise.all([
      handleSaveOrganizationFormAndSystemconfigForm(),
      handleSaveAdminInformationForm(),
      updateHeader(),
      intializeUserSessionsData(),
      intializeLogsSesstingsData()
    ])
      .then((response) => {
        setAdminLoginStatus()
        dispatch(sessionActions.setFirstTimeUserOnboardingDone())
        history.replace(ROUTES.DASHBOARD);
      })
      .catch((error) => {
        msg = error?.message || `${errorMessages?.GENERIC_ERROR?.HEADING}, ${errorMessages?.GENERIC_ERROR?.SUB_TEXT}`;
        msg += '. ' + errorMessages?.GENERIC_ERROR?.TRY_AGAIN;
        setErrorMessage(msg);
      })
      .finally(() => {
        setIsButtonDisabled(false);
        setIsSaving(false);
      });
  };

  const requiredActionCall =
    currentStep === TOTAL_STEPS - 1 ? handleSaveClick : onNextClick;

  return (
    <OnBoardingTemplate
      target="admin"
      header={<OnBoardingHeader />}
      leftPanel={
        <OnBoardingLeftPanel
          listOfLabels={LIST_OF_PROGRESS_BAR_LABELS}
          organizationName={mainHeaderData?.entities?.organizationName}
          currentlyActiveStep={currentStep}
        />
      }
      content={getFormWithRespectToStepValue(currentStep)}
      footer={
        <OnBoardingFooter
          totalSteps={TOTAL_STEPS}
          currentStep={currentStep + 1}
          backButtonClick={onBackClick}
          actions={
            // currentStep === 1
            //   ? [
            //       {
            //         actionName: 'Next',
            //         actionCall: () => {
            //           onNextClick();
            //         },
            //         buttonVariant: 'contained',
            //         isDisabled: isAgreeTerms ? false : true,
            //       },
            //     ]
            //   : [
            [
              {
                actionName:
                  currentStep === TOTAL_STEPS - 1 ? (errorMessage ? RETRY_AGAIN : GET_STARTED) : NEXT,
                actionCall: requiredActionCall,
                buttonVariant: 'contained',
                isDisabled: isButtonDisabled,
              },
            ]
          }
        />
      }
    />
  );
};

export default OnboardingPage;
