import React from 'react';
import { Stack, Typography, Box, MenuItem } from '@mui/material';
import theme from '../../../core-utils/theme';
import messages from '../../../core-utils/messages';
import IconFromSvg from '../../atoms/IconFromSvg';
import SearchIcon from '../../../../public/assets/search-icon.svg';

type SearchResultsCardProps = {
  searchResults: string[];
  handleResultClick: any;
  recentSearches?: Array<string>;
};

const SearchResultsCard = ({
  searchResults,
  handleResultClick,
  recentSearches,
  ...props
}: SearchResultsCardProps) => {
  const getScrollValue: any = (searchData: any) => {
    if (searchData.length > 4) {
      return 'scroll';
    } else {
      return 'none';
    }
  };

  return (
    <Box
      sx={{
        border: '1px',
        borderColor: theme.palette.grey[400],
        boxSizing: 'border-box',
        boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.08)',
        borderRadius: theme.spacing(1.25),
        pt: 7,
        pb: 9,
        position: 'absolute',
        backgroundColor: theme.palette.common.white,
        zIndex: 20,
        width: '68%',
        height: searchResults.length > 4 ? '30%' : 'inherit',
        mt: 2.5,
        overflowY: getScrollValue(searchResults),
      }}
    >
      <Stack direction="column" spacing={4}>
        <Stack
          direction="column"
          spacing={4}
          sx={{ pl: 10 }}
          data-testid="searchResults"
        >
          <Typography variant="subtitle2">{messages.SEARCH_RESULTS}</Typography>
          {searchResults.map((searchResult: string, index: number) => {
            return (
              <MenuItem
                sx={{
                  pl: 0,
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
                key={index}
                onClick={(event) => handleResultClick(searchResult)}
              >
                <IconFromSvg path={SearchIcon} alt="search-icon" />
                <Typography
                  sx={{ paddingLeft: theme.spacing(2) }}
                  variant="body2"
                >
                  {searchResult}
                </Typography>
              </MenuItem>
            );
          })}

          {recentSearches && recentSearches.length > 0 && (
            <>
              <Typography variant="subtitle2">
                {messages.RECENT_SEARCHES}
              </Typography>
              {recentSearches?.map((recentSearch: string, index: number) => {
                return (
                  <MenuItem
                    sx={{
                      pl: 0,
                      '&:hover': {
                        backgroundColor: 'transparent',
                      },
                    }}
                    key={index}
                    onClick={(event) => handleResultClick(recentSearch)}
                  >
                    <Typography
                      sx={{ paddingLeft: theme.spacing(2) }}
                      variant="body2"
                    >
                      {recentSearch}
                    </Typography>
                  </MenuItem>
                );
              })}
            </>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default SearchResultsCard;
