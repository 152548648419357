import * as React from 'react';
import Box from '@mui/material/Box';
import SearchDropDown, { IDropDown } from '../SearchDropDown';
import { IChipProps } from '../../atoms/Chip';
import IconFromSvg from '../../atoms/IconFromSvg';
import { DROP_DOWN_ICON, IDropDownItem } from '../../../core-utils/constants';
import ChevronDownSvg from '../../../../public/assets/chevronDown-inactive.svg';
import ChevronUpSvg from '../../../../public/assets/chevron-up.svg';
import TextField from '../../atoms/TextField';
import ChipsGroup from '../../molecules/ChipsGroup';
import theme from '../../../core-utils/theme';
import { useShowOrHideElement } from '../../../core-utils/Hooks/index.hook';

export interface IMultipleSelectChip extends IDropDown {
  onChipDelete: (id: string) => void;
  textFieldLabel?: string;
  sortSelected?: Function;
  category?: 'users' | 'usergroups';
  isFieldDisabled?: boolean;
}

const MultipleSelectChip = (props: IMultipleSelectChip) => {
  const {
    open,
    toggleDisplay,
    hideElement,
    showElement,
  } = useShowOrHideElement();

  const [inputFieldValue, setInputFieldValue] = React.useState('');

  const clearInputField = () => {
    setInputFieldValue('');
  };

  const onTextFieldClick = (
    _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    toggleDisplay();
    props.sortSelected && props.sortSelected();
  };

  const onClickAway = (_event: MouseEvent | TouchEvent) => {
    hideElement();
    props.sortSelected && props.sortSelected();
  };

  const chips: IChipProps[] = props.checkedItems.map((item) => {
    return {
      label: item.name,
      hasBorder: true,
      shade: 'grey',
      onDelete: () => {
        props.onChipDelete(item.id);
      },
    } as IChipProps;
  });

  const SelectedChips = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 1,
          m: 2,
          maxWidth: `calc(100% - ${theme.spacing(4)})`,
        }}
      >
        {<ChipsGroup chips={chips} />}
        {props.checkedItems.length == 0 && <Box height={29} />}
      </Box>
    );
  };

  const [userGroups, setuserGroups] = React.useState<Array<IDropDownItem>>(
    props.dropDownList,
  );

  const handleChange = (event: any) => {
    showElement();
    setInputFieldValue(event.target.value);
    const searchValue = event.target.value;
    const searchValueInLowerCase = searchValue.trim().toLowerCase();
    if (searchValue.length > 0) {
      const filteredGroups = props.dropDownList.filter((dropdown) => {
        return dropdown.name
          ?.trim()
          .toLowerCase()
          .startsWith(searchValueInLowerCase);
      });
      setuserGroups(filteredGroups);
    } else {
      setuserGroups(props.dropDownList);
    }
  };

  return (
    <div>
      <TextField
        id="select-multiple-chip"
        autoComplete="off"
        label={props.textFieldLabel}
        value={inputFieldValue}
        endAdornment={
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {open ? (
              <IconFromSvg path={ChevronUpSvg} alt={DROP_DOWN_ICON} />
            ) : (
              <IconFromSvg path={ChevronDownSvg} alt={DROP_DOWN_ICON} />
            )}
          </Box>
        }
        onClick={onTextFieldClick}
        handleChange={handleChange}
        InputProps={{
          startAdornment: chips.length ? <SelectedChips /> : <></>,
        }}
        sx={{
          pb: 1,
          '& .MuiOutlinedInput-root': {
            '&:hover fieldset': {
              borderColor: theme.palette.text.secondary,
            },
            '& fieldset': {
              borderColor: open
                ? `${theme.palette.primary.main} !important`
                : theme.palette.grey[500],
            },
          },
        }}
        testId="select-multiple-chip"
        disabled={props.isFieldDisabled ? true : false}
      />
      {open && (
        <SearchDropDown
          usergroups={userGroups}
          dropDownList={props.dropDownList}
          checkedItems={props.checkedItems}
          handleCheckedItems={props.handleCheckedItems}
          handleClickAway={onClickAway}
          clearInputField={clearInputField}
          category={props.category}
        />
      )}
    </div>
  );
};

export default MultipleSelectChip;
