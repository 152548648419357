import React, { useEffect, useState } from 'react';
import UserService from '../../../api-service/user-service/UserService';
import AppSvgIcon from '../../../../public/assets/app-management-active.svg';
import UserManagementIcon from '../../../../public/assets/user-management-active.svg';
import DurationSvgIcon from '../../../../public/assets/duration-active.svg';
import PolicyChangesSvgIcon from '../../../../public/assets/policies-active.svg';
import { MOCK_OVERVIEW_USERS_DATA } from '../../molecules/OverviewUsersAndApps/testData';
import { handleError } from '../../../core-utils/Helper/helper';
import Config from '../../../../config/config';

const constructOverviewData = async (
  fromDate: string,
  toDate: string,
  prevFromDate: string,
  prevToDate: string,
  username: string,
) => {
  // TODO : remove this return once we have apis
  // return MOCK_OVERVIEW_USERS_DATA;

  if (!Config.DASHBOARD_API_URL) {
    return MOCK_OVERVIEW_USERS_DATA;
  }

  let result = [];

  const avgAppsCount = await UserService.getAvgDailyAppsCount(
    fromDate,
    toDate,
    username,
  );
  const prevAvgAppsCount = await UserService.getAvgDailyAppsCount(
    fromDate,
    toDate,
    username,
  );
  const avgAppsVariationCount = avgAppsCount - prevAvgAppsCount;
  result.push({
    name: 'Average Daily Applications',
    count: avgAppsCount,
    itemIcon: AppSvgIcon,
    overviewType: avgAppsVariationCount !== 0 && 'neutral',
    variationCount: avgAppsVariationCount !== 0 && avgAppsVariationCount,
  });

  const policyViolationsCount = await UserService.getPolicyViolationsCount(
    fromDate,
    toDate,
    username,
  );
  const prevPolicyViolationsCount = await UserService.getPolicyViolationsCount(
    prevFromDate,
    prevToDate,
    username,
  );
  const policyViolationsVariationCount =
    policyViolationsCount - prevPolicyViolationsCount;
  result.push({
    name: 'Policy Violations',
    count: policyViolationsCount,
    itemIcon: PolicyChangesSvgIcon,
    overviewType: policyViolationsVariationCount !== 0 && 'neutral',
    variationCount:
      policyViolationsVariationCount !== 0 && policyViolationsVariationCount,
  });

  const loginsCount = await UserService.getUserLogins(
    fromDate,
    toDate,
    username,
  );
  result.push({
    name: 'Number Of Logins',
    count: loginsCount,
    itemIcon: UserManagementIcon,
  });

  const totalSessionsCount = await UserService.getTotalSessions(
    fromDate,
    toDate,
    username,
  );
  const prevTotalSessionsCount = await UserService.getTotalSessions(
    prevFromDate,
    prevToDate,
    username,
  );
  const totalSessionsVariationCount =
    totalSessionsCount - prevTotalSessionsCount;
  result.push({
    name: 'Total Sessions',
    count: totalSessionsCount,
    itemIcon: DurationSvgIcon,
    overviewType: totalSessionsVariationCount !== 0 && 'neutral',
    variationCount:
      totalSessionsVariationCount !== 0 && totalSessionsVariationCount,
  });

  const totalSessionsDuration = await UserService.getTotalSessionsDuration(
    fromDate,
    toDate,
    username,
  );

  const averageSessionsDuration = await UserService.getAverageSessionsDuration(
    fromDate,
    toDate,
    username,
  );

  result.push({
    name: 'Average sessions duration (min)',
    count: Math.floor(averageSessionsDuration / 60),
    itemIcon: DurationSvgIcon,
  });

  result.push({
    name: 'Total sessions duration (min)',
    count: Math.floor(totalSessionsDuration / 60),
    itemIcon: DurationSvgIcon,
  });

  return result;
};

export const useStatsData = (
  fromDate: string,
  toDate: string,
  prevFromDate: string,
  prevToDate: string,
  username: string,
) => {
  const [appsAccessed, setAppsAccessed] = useState([]);
  const [categoryViolations, setCategoryViolations] = useState([]);
  const [overviewData, setOverviewData] = useState<any>([]);
  const [lastLoginTime, setLastLoginTime] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorProps, setErrorProps] = React.useState<{
    imagePath: string;
    text: string;
    helperText: string;
    helperSubText?: string;
    actionName: string;
    secondActionName?: string;
    onActionClick: () => void;
    onSecondActionClick?: () => void;
  }>();

  useEffect(() => {
    setLoading(true);
    UserService.getAppsAccessedByUser(
      fromDate,
      toDate,
      username,
    ).then((response) => setAppsAccessed(response));
    UserService.getCategoryViolationsByUser(fromDate, toDate, username).then(
      (response) => {
        response.sort((a: any, b: any) => b.violations - a.violations);
        setCategoryViolations(response);
      },
    );
    UserService.getUserLastLoginTime(username).then((response) =>
      setLastLoginTime(response),
    );
    constructOverviewData(fromDate, toDate, prevFromDate, prevToDate, username)
      .then((response) => setOverviewData(response))
      .catch((error: any) => {
        const {
          imagePath,
          HEADING,
          TEXT,
          SUB_TEXT,
          BACK,
          REFRESH,
          handleBackClick,
          handleRefreshClick,
        } = handleError(error.response.status);
        setErrorProps({
          imagePath: imagePath,
          text: HEADING,
          helperText: TEXT,
          helperSubText: SUB_TEXT,
          actionName: BACK,
          secondActionName: REFRESH,
          onActionClick: handleBackClick,
          onSecondActionClick: handleRefreshClick,
        });
      })
      .finally(() => setLoading(false));
  }, [fromDate, toDate]);

  return {
    appsAccessed,
    categoryViolations,
    loading,
    overviewData,
    lastLoginTime,
    errorProps,
  };
};
