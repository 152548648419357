import React, { useEffect, useState } from 'react';
import { Grid, Typography, useTheme } from '@mui/material';
import messages from '../../../core-utils/messages';
import Button from '../../atoms/Button';
import UserGroupsTable from '../UserGroupsTable';
import {
  useShowOrHideElement,
  useWindowSize,
} from '../../../core-utils/Hooks/index.hook';
import AddGroupsToUserOrAppModal from '../AddGroupsToUserOrAppModal';
import { getHeight } from '../../../core-utils/Helper/helper';

interface IGroupListUserDetailsProps {
  userGroups: any;
  username: string;
  updateUserGroupsOfUser?: any;
  updatePoliciesOfUser?: any;
}

const { HEADING, BUTTON_LABEL, ASSIGN_USERGROUP } = messages.USER_GROUPS_TAB;

const GroupListUserDetails = ({
  userGroups,
  username,
  updateUserGroupsOfUser,
  updatePoliciesOfUser,
}: IGroupListUserDetailsProps) => {
  const theme = useTheme();
  const { open, hideElement, showElement } = useShowOrHideElement();
  const [arrow, setArrow] = useState<boolean>(false);
  const [tabItemUserGroups, setTabItemUserGroups] = useState(userGroups);

  const reverseUserGroups = () => {
    tabItemUserGroups && setTabItemUserGroups([...tabItemUserGroups].reverse());
  };

  useEffect(() => {
    setTabItemUserGroups(userGroups);
    setArrow(!arrow);
  }, [userGroups]);

  const { width, height } = useWindowSize();

  return (
    <Grid container spacing={5}>
      <AddGroupsToUserOrAppModal
        userGroupsOfUserOrApp={userGroups}
        userOrappId={username}
        open={open}
        handleClose={hideElement}
        updateUserGroups={updateUserGroupsOfUser}
        updatePolicies={updatePoliciesOfUser}
      />
      <Grid item container justifyContent="space-between">
        <Grid item>
          <Typography variant="body1" color={theme.palette.text.disabled}>
            {HEADING}
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={showElement}>
            {ASSIGN_USERGROUP}
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{
          height: tabItemUserGroups.length ? getHeight(height) : 'fit-content',
        }}
        xs={12}
        container
      >
        {tabItemUserGroups.length && (
          <UserGroupsTable
            tabledata={tabItemUserGroups}
            onLabelClick={reverseUserGroups}
            arrow={arrow}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default GroupListUserDetails;
