import { Paper } from '@mui/material';
import React from 'react';
import { EXTRA_COLORS } from '../../../core-utils/theme';
import DetailsListItem, {
  IDetailsListItemProps,
} from '../../molecules/DetailsListItem';

interface IDetailsListProps {
  detailsList: Array<IDetailsListItemProps> | any;
}

const DetailList = ({ detailsList }: IDetailsListProps) => {
  return (
    <Paper sx={{ border: `1px solid ${EXTRA_COLORS.lightGrayishBlue}` }}>
      {detailsList.map((item: any, index: number) => {
        return (
          <DetailsListItem
            key={index}
            label={item.label}
            value={item.value}
            buttonLabel={item.buttonLabel}
            showButton={item.showButton}
            handleButtonClick={item.handleButtonClick}
            valueStartIcon={item.valueStartIcon}
          />
        );
      })}
    </Paper>
  );
};

export default DetailList;
