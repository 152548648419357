import { Grid, Box } from '@mui/material';
import { useWindowSize } from '@utils/Hooks/index.hook';
import { customStyles } from '@utils/theme';
import React, { useEffect, useState } from 'react';
import AppService from '../../../api-service/app-service/AppService';
import {
  APPLICATION_DETAILS_TAB,
  APPLICATION_PROTOCOLS,
  APPLICATION_TYPE_OPTIONS,
  GLOBAL_LABELS,
  IAppDetailsProps,
  IAppDetailsType,
  ITooltipValidationParams,
  URL_VALIDATION_PARAMETERS,
} from '../../../core-utils/constants';
import {
  applicationTypeToProtocol,
  getFreshToolTipItemList,
  getHeight,
  isEmptyValidation,
  validateDomainUrl,
  validateURLWithParameters,
} from '../../../core-utils/Helper/helper';
import messages from '../../../core-utils/messages';
import TextField from '../../atoms/TextField';
import EditDetailsModal from '../../molecules/EditDetailsModal';
import DetailList from '../DetailList';
import CustomDialog from '../Dialog';
import EditIconModal from '../EditIconModal';

const {
  APP_NAME_LABEL,
  CUSTOM_DOMAIN_LABEL,
  EDIT_APP_NAME,
  EDIT_CUSTOM_DOMAIN,
  APP_TYPE_LABEL,
  EDIT_APPLICATION_TYPE,
  DOMAIN_URLS,
  EDIT_DOMAIN_URLS,
  EDIT_APP_ICON,
  APP_ICON_LABEL,
  EDIT_DESCRIPTION,
  DESCRIPTION_LABEL,
} = messages.EDIT_APPLICATION_DETAILS;

// Add new keys here when we get a new detail list item from the API
const APP_DETAILS_KEYS = [
  'name',
  'applicationLogo',
  'customDomain',
  'rdpAppPath',
  //'idpAppUrl',
  'domainurls',
  'source',
  'createdBy',
  'createdOn',
  'description',
  'applicationType',
  'bookmarkUrl'
];

const SONET_APP_URL_PREFIX = 'apps.sonet.io';

const getApplicationTypeFromName = (appProtocol: string | undefined, isHttpOnly: boolean = false) => {
  if (appProtocol === 'browser-app') {
    if (isHttpOnly) {
      return APPLICATION_TYPE_OPTIONS[1];
    }
    return APPLICATION_TYPE_OPTIONS[0];
  }
  return APPLICATION_TYPE_OPTIONS.find(
    (appType) => appType.name?.toLowerCase() === appProtocol?.toLowerCase(),
  );
};

const AppDetailsTabItem = (props: IAppDetailsProps) => {
  const [appParams, setAppParams] = useState<any>({});
  const { appsDetails, appId, customDomain, domainUrls, getAppDetails, rdpAppPath } = props;


  useEffect(() => {
    AppService.getAppParameters(appId)
      .then(d => setAppParams(d));
  }, [])

  const [domainError, setDomainError] = useState('');
  const {
    APP_NAME,
    CREATED_ON,
    CREATED_BY,
    APPLICATION_TYPE,
    CUSTOM_DOMAIN,
    IDP_APP_URL,
    DOMAIN_URL,
    APP_ICON,
    DESCRIPTION,
    BOOKMARK_URL,
    RDP_APP_PATH,
    SOURCE
  } = APPLICATION_DETAILS_TAB(appsDetails.applicationType);

  const appFalseModal = {
    [APP_NAME.label]: false,
    [APP_ICON.label]: false,
    [CUSTOM_DOMAIN.label]: false,
    [APPLICATION_TYPE.label]: false,
    [DOMAIN_URL.label]: false,
    [DESCRIPTION.label]: false,
  };

  const isSonetAppUrl = () => customDomain?.toLowerCase().includes(SONET_APP_URL_PREFIX?.toLocaleLowerCase());

  const fetchAppObjectFromKeys = (key: string) => {
    switch (key) {
      case 'name':
        return APP_NAME;
      case 'applicationLogo':
        return APP_ICON;
      case 'customDomain':
        return CUSTOM_DOMAIN;
      case 'rdpAppPath':
        return RDP_APP_PATH;
      case 'domainurls':
        return DOMAIN_URL;
      case 'idpAppUrl':
        return IDP_APP_URL;
      case 'source':
        return SOURCE;
      case 'createdBy':
        return CREATED_BY;
      case 'createdOn':
        return CREATED_ON;
      case 'description':
        return DESCRIPTION;
      case 'applicationType':
        return APPLICATION_TYPE;
      case 'bookmarkUrl':
        return BOOKMARK_URL;
      default:
        return '';
    }
  };

  const fetchDetailListFromAppResponse = (
    appsDetailResponse: IAppDetailsType,
    handleButtonClick: (label: string) => void,
  ) => {
    const list = ['domainurls'];
    let requiredKeys: string[] = APP_DETAILS_KEYS;
    const doesSonetAppUrlExist = isSonetAppUrl();

    if (appsDetailResponse.applicationType !== APPLICATION_PROTOCOLS.RDP) {
      requiredKeys = requiredKeys.filter((keys) => keys !== 'rdpAppPath');
    }

    if (
      appsDetailResponse.applicationType !== APPLICATION_PROTOCOLS.BROWSER_APP
    ) {
      requiredKeys = requiredKeys.filter(
        (value: string) => !list.includes(value),
      );
    } else {
      requiredKeys = APP_DETAILS_KEYS;
      if (doesSonetAppUrlExist) {
        requiredKeys = requiredKeys.filter((keys) => keys !== 'domainurls');
      }
    }
    if (!appsDetails?.source) {
      requiredKeys = requiredKeys.filter((keys) => keys !== 'source');
    }
    return requiredKeys.map((key: string) => {
      const keyDetail = { ...fetchAppObjectFromKeys(key), handleButtonClick };
      if (key === 'name') {
        keyDetail.value = appsDetailResponse.name;
      } else if (key === 'applicationLogo') {
        keyDetail.value = appsDetailResponse.appLogo;
      } else if (key === 'customDomain') {
        keyDetail.value = customDomain;
        keyDetail.label = doesSonetAppUrlExist ? keyDetail?.label?.replace('URL', '') : keyDetail?.label;
      } else if (key === 'rdpAppPath') {
        keyDetail.value = rdpAppPath;
      } else if (key === 'domainurls') {
        keyDetail.value = domainUrls;
      } else if (key === 'idpAppUrl') {
        keyDetail.value = appsDetailResponse.idpAppUrl;
      } else if (key === 'source') {
        keyDetail.value = appsDetails?.source;
      } else if (key === 'createdBy') {
        keyDetail.value = appsDetailResponse.createdBy;
      } else if (key === 'createdOn') {
        keyDetail.value = appsDetailResponse.createdOn;
      } else if (key === 'applicationType') {
        keyDetail.value =
          appsDetailResponse.applicationType ===
            APPLICATION_PROTOCOLS.BROWSER_APP
            ? Boolean(JSON.parse(appParams?.isHttpOnly ?? 'false')) ? APPLICATION_TYPE_OPTIONS[1].name : APPLICATION_TYPE_OPTIONS[0].name
            : appsDetailResponse.applicationType;
      } else if (key === 'description') {
        keyDetail.value = appsDetailResponse.description;
      } else if (key === 'bookmarkUrl') {
        keyDetail.value = `https://${window.location.hostname}/sonet/#/client/${btoa([appId, 'c', 'postgresql'].join('\x00'))}`
      }
      // Add new if-else comparisons as we add new detail items in the API.
      return keyDetail;
    });
  };

  const initialAppDetails = {
    [APP_NAME.label]: appsDetails.name,
    [CUSTOM_DOMAIN.label]: customDomain,
    [APPLICATION_TYPE.label]: getApplicationTypeFromName(
      appsDetails.applicationType,
      Boolean(JSON.parse(appParams?.isHttpOnly ?? 'false'))
    ),
    [DOMAIN_URL.label]: domainUrls,
    [APP_ICON.label]: appsDetails.appLogo,
    [DESCRIPTION.label]: appsDetails.description,
  };

  const [appUpdatedDetails, setAppUpdatedDetails] = useState<any>(
    initialAppDetails,
  );

  const [appEditModal, setAppEditModal] = useState(appFalseModal);
  const urlParameterInitial = getFreshToolTipItemList(
    URL_VALIDATION_PARAMETERS,
  );

  const [urlToolTipParams, setUrlTooltipParams] = useState<
    Array<ITooltipValidationParams>
  >(urlParameterInitial);

  const [modalProps, setModalProps] = useState({
    isSaveDisabled: true,
  });

  const handleApplicationTypeSelect = (event: any, value: any) => {
    setAppUpdatedDetails((prevState: any) => ({
      ...prevState,
      [APPLICATION_TYPE.label]: value,
    }));
  };

  const handleButtonClick = (name: string) => {
    setAppEditModal({ ...appEditModal, [name]: true });
    if (name === DESCRIPTION.label) {
      setModalProps({ isSaveDisabled: false });
    }
  };
  const handleDetailValidation = (
    name: string | undefined,
    logoFile?: string,
  ) => {
    if (name === APP_ICON.label && logoFile !== '') {
      setModalProps({ isSaveDisabled: true });
      return true;
    }
    if (name === DESCRIPTION.label) {
      setModalProps({ isSaveDisabled: false });
      return true;
    }
    if (name === APP_ICON.label) {
      setModalProps({ isSaveDisabled: true });
      return false;
    }
    if (name === APP_NAME.label || name === CUSTOM_DOMAIN.label) {
      if (isEmptyValidation(appUpdatedDetails[name])) {
        setModalProps({ isSaveDisabled: true });
        return false;
      }
    }
    if (name === CUSTOM_DOMAIN.label) {
      if (!validateDomainUrl(appUpdatedDetails[CUSTOM_DOMAIN.label])) {
        setDomainError(messages.ADD_APPLICATION.ERROR_MESSAGES.INVALID_URL);
        setModalProps({ isSaveDisabled: true });
        return false;
      } else {
        setDomainError('');
        setModalProps({ isSaveDisabled: false });
        return true;
      }
    }

    setModalProps({ isSaveDisabled: false });
    return true;
  };

  const handleDetailUpdate = async (name: string | undefined) => {
    if (handleDetailValidation(name)) {
      //Rest of the fields to be updated in the payload accordingly when api provides us.
      const protocol = applicationTypeToProtocol(
        appUpdatedDetails[APPLICATION_TYPE.label]?.name,
      );

      let parameters = {};
      if (protocol === 'browser-app') {
        parameters = {
          ...appParams,
          url: appUpdatedDetails[CUSTOM_DOMAIN.label],
          domainurls: appUpdatedDetails[DOMAIN_URL.label],
        };
      }

      const requestPayload = {
        name: appUpdatedDetails[APP_NAME.label],
        protocol,
        parameters,
        attributes: {
          thumbnail: appUpdatedDetails[APP_ICON.label],
          description: appUpdatedDetails[DESCRIPTION.label],
        },
      };
      try {
        await AppService.updateApp(appId, requestPayload);
      } catch (error) {
        console.warn(error);
      } finally {
        setAppEditModal(appFalseModal);
        setModalProps({ isSaveDisabled: true });
        getAppDetails(appId);
      }
    }
  };
  const handleFieldChange = (name: any, event: any) => {
    if (name === APP_ICON.label) {
      setAppUpdatedDetails((prevState: any) => ({
        ...prevState,
        [name]: event,
      }));
    }
    if (name === DESCRIPTION.label) {
      setAppUpdatedDetails((prevState: any) => ({
        ...prevState,
        [name]: event?.target?.value,
      }));
    }
    if (name && name !== APP_ICON.label) {
      setAppUpdatedDetails((prevState: any) => ({
        ...prevState,
        [name]: event?.target?.value,
      }));
      if (name === CUSTOM_DOMAIN.label) {
        const inputUrl = event.target.value;
        const tempURLToolTipParams = validateURLWithParameters(
          inputUrl,
          urlToolTipParams,
        );
        setUrlTooltipParams(tempURLToolTipParams);
      }
    }
  };
  const handleCloseModal = () => {
    setAppEditModal(appFalseModal);
    setModalProps({ isSaveDisabled: true });
    setAppUpdatedDetails(initialAppDetails);
    setDomainError('');
  };

  const commonProps = {
    isSaveDisabled: modalProps.isSaveDisabled,
    handleSaveClick: handleDetailUpdate,
    handleFieldChange: handleFieldChange,
    handleValidation: handleDetailValidation,
    onClose: handleCloseModal,
  };

  const shouldSaveBeDisabled = () => {
    if (appEditModal[APP_NAME.label]) {
      if (handleDetailValidation(APP_NAME.label)) {
        setModalProps({ isSaveDisabled: false });
      }
    }
    if (appEditModal[CUSTOM_DOMAIN.label]) {
      if (handleDetailValidation(CUSTOM_DOMAIN.label)) {
        setModalProps({ isSaveDisabled: false });
      }
    }
    if (appEditModal[DOMAIN_URL.label]) {
      if (handleDetailValidation(DOMAIN_URL.label)) {
        setModalProps({ isSaveDisabled: false });
      }
    }
    if (appEditModal[APP_ICON.label]) {
      if (handleDetailValidation(APP_ICON.label)) {
        setModalProps({ isSaveDisabled: false });
      }
    }
    if (appEditModal[DESCRIPTION.label]) {
      if (handleDetailValidation(DESCRIPTION.label)) {
        setModalProps({ isSaveDisabled: false });
      }
    }
  };

  useEffect(() => {
    shouldSaveBeDisabled();
  }, [appUpdatedDetails]);

  const { height } = useWindowSize();

  return (
    <React.Fragment>
      <Box
        sx={{
          ...customStyles.scrollBar,
          overflowY: 'scroll',
          height: 'fit-content',
          maxHeight: getHeight(height, 'details'),
        }}
      >
        <DetailList
          detailsList={fetchDetailListFromAppResponse(
            appsDetails,
            handleButtonClick,
          )}
        />
      </Box>

      <EditDetailsModal
        open={appEditModal[APP_NAME.label]}
        fieldType="TEXTFIELD"
        textFieldValue={appUpdatedDetails[APP_NAME.label]}
        name={APP_NAME.label}
        heading={EDIT_APP_NAME}
        textFieldLabel={APP_NAME_LABEL}
        textFieldError=""
        {...commonProps}
      />

      <EditDetailsModal
        open={appEditModal[DESCRIPTION.label]}
        fieldType="TEXTFIELD"
        textFieldValue={appUpdatedDetails[DESCRIPTION.label]}
        name={DESCRIPTION.label}
        isRequired={false}
        heading={EDIT_DESCRIPTION}
        textFieldLabel={DESCRIPTION_LABEL}
        textFieldError=""
        {...commonProps}
      />

      <EditIconModal
        open={appEditModal[APP_ICON.label]}
        textFieldValue={appUpdatedDetails[APP_ICON.label]}
        name={APP_ICON.label}
        heading={EDIT_APP_ICON}
        textFieldLabel={APP_ICON_LABEL}
        {...commonProps}
      />

      <EditDetailsModal
        open={appEditModal[DOMAIN_URL.label]}
        fieldType="TEXTFIELD"
        textFieldValue={appUpdatedDetails[DOMAIN_URL.label]}
        name={DOMAIN_URL.label}
        heading={EDIT_DOMAIN_URLS}
        textFieldLabel={DOMAIN_URLS}
        textFieldError=""
        isRequired={false}
        {...commonProps}
      />

      <CustomDialog
        open={appEditModal[CUSTOM_DOMAIN.label]}
        heading={EDIT_CUSTOM_DOMAIN}
        buttonLabelOne={GLOBAL_LABELS.CANCEL}
        buttonLabelTwo={GLOBAL_LABELS.SAVE}
        isSaveDisabled={modalProps.isSaveDisabled}
        handleSaveClick={() => {
          handleDetailUpdate(CUSTOM_DOMAIN.label);
        }}
        onClose={commonProps.onClose}
      >
        <Grid item>
          {/* Tooltip can be removed as of now as it only checks spaces, can be added later */}
          {/* <TooltipForValidation
            validationItems={urlToolTipParams}
            heading={messages.ADD_APPLICATION.URL_PARAMETERS}
          > */}
          <TextField
            name={CUSTOM_DOMAIN.label}
            placeholder={
              messages.ADD_APPLICATION.TEXT_FIELD_LABELS
                .CUSTOM_DOMAIN_PLACEHOLDER
            }
            label={CUSTOM_DOMAIN_LABEL}
            required
            value={appUpdatedDetails[CUSTOM_DOMAIN.label]}
            handleChange={(event) =>
              commonProps.handleFieldChange(CUSTOM_DOMAIN.label, event)
            }
            onBlur={() => commonProps.handleValidation(CUSTOM_DOMAIN.label)}
            error={domainError !== ''}
            helperText={domainError}
          />
          {/* </TooltipForValidation> */}
        </Grid>
      </CustomDialog>

      <EditDetailsModal
        open={appEditModal[APPLICATION_TYPE.label]}
        fieldType="DROPDOWN"
        isSaveDisabled={modalProps.isSaveDisabled}
        name={APPLICATION_TYPE.label}
        heading={EDIT_APPLICATION_TYPE}
        dropdownOptions={APPLICATION_TYPE_OPTIONS}
        dropdownLabel={APP_TYPE_LABEL}
        dropdownValue={appUpdatedDetails[APPLICATION_TYPE.label]}
        handleSaveClick={handleDetailUpdate}
        onClose={handleCloseModal}
        handleValidation={handleDetailValidation}
        handleFieldChange={handleFieldChange}
        handleDropdownChange={handleApplicationTypeSelect}
      />
    </React.Fragment>
  );
};

export default AppDetailsTabItem;
