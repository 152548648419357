import WarningSvg from '@assets/warningIcon.svg';
import { useEffect, useState } from 'react';
import UserManagementSvg from '@assets/user-management-active.svg';
import UserGroupService from '../../../api-service/user-group-service/UserGroupService';
import {
    MOCK_CURRENTLY_ACTIVE_USERS
} from '@utils/test-constants';
import { getDate } from '../Dashboard/index.hook';
const constructActiveUsersData = async (
  groupId: string,
  fromDate: String,
  toDate: String,
  prevFromDate: String,
  prevToDate: String,
) => {
  // Remove this when we have apis
  return MOCK_CURRENTLY_ACTIVE_USERS;
  // const activeUsersCount = await UserGroupService.getActiveUsersCountInUserGroup(
  //   groupId,
  //   fromDate,
  //   toDate,
  // );
  // const prevActiveUsersCount = await UserGroupService.getActiveUsersCountInUserGroup(
  //   groupId,
  //   prevFromDate,
  //   prevToDate,
  // );

  // return [
  //   {
  //     id: 1,
  //     name: 'Currently Active Users',
  //     count: activeUsersCount,
  //     overviewItemicon: activeUsersCount,
  //     itemOverviewType:
  //       activeUsersCount - prevActiveUsersCount !== 0 && 'positive',
  //     variationCount:
  //       activeUsersCount - prevActiveUsersCount !== 0 &&
  //       activeUsersCount - prevActiveUsersCount,
  //   },
  // ];
};

const constructOverviewData = async (
  groupId: string,
  fromDate: String,
  toDate: String,
  prevFromDate: String,
  prevToDate: String,
) => {
  // Remove this when we have apis
  // return MOCK_USER_GROUPS_OVERVIEW_ITEMS;

  let result = [];

  const avgAppsCount = await UserGroupService.getAverageDailyAppsCount(
    groupId,
    fromDate,
    toDate,
  );
  const prevAvgAppsCount = await UserGroupService.getAverageDailyAppsCount(
    groupId,
    prevFromDate,
    prevToDate,
  );
  const diffAvgAppsCount = avgAppsCount - prevAvgAppsCount;
  result.push({
    id: 1,
    name: 'Average Daily Applications',
    count: avgAppsCount,
    overviewItemicon: UserManagementSvg,
    itemOverviewType: diffAvgAppsCount !== 0 && 'neutral',
    variationCount: diffAvgAppsCount !== 0 && diffAvgAppsCount,
  });

  const avgUsersCount = await UserGroupService.getAverageDailyUsersCount(
    groupId,
    fromDate,
    toDate,
  );
  const prevAvgUsersCount = await UserGroupService.getAverageDailyUsersCount(
    groupId,
    prevFromDate,
    prevToDate,
  );
  result.push({
    id: 2,
    name: 'Average Daily Users',
    count: avgUsersCount,
    overviewItemicon: UserManagementSvg,
    itemOverviewType: avgUsersCount - prevAvgUsersCount !== 0 && 'neutral',
    variationCount:
      avgUsersCount - prevAvgUsersCount !== 0 &&
      avgUsersCount - prevAvgUsersCount,
  });

  const currentToDate = getDate();
  let currentFromDate = getDate();
  currentFromDate.setDate(currentToDate.getDate() - 1);
  let currentToDateInISO = currentToDate.toISOString();
  let currentFromDateInISO = currentFromDate.toISOString();
  let previousToDateInISO = currentFromDate.toISOString();
  currentFromDate.setDate(currentFromDate.getDate() - 1);
  let previousFromDateInISO = currentFromDate.toISOString();
  const policyViolationsCountToday = await UserGroupService.getPolicyViolationsCount(
    groupId,
    currentFromDateInISO,
    currentToDateInISO,
  );

  const policyViolationsCountYesterday = await UserGroupService.getPolicyViolationsCount(
    groupId,
    previousFromDateInISO,
    previousToDateInISO,
  );

  result.push({
    id: 3,
    name: 'Policy Violations in the Last 24 hrs',
    count: policyViolationsCountToday,
    overviewItemicon: WarningSvg,
    itemOverviewType:
      policyViolationsCountToday - policyViolationsCountYesterday !== 0 &&
      'negative',
    variationCount:
      policyViolationsCountToday - policyViolationsCountYesterday !== 0 &&
      policyViolationsCountToday - policyViolationsCountYesterday,
  });

  const policyViolationsCount = await UserGroupService.getPolicyViolationsCount(
    groupId,
    fromDate,
    toDate,
  );

  result.push({
    id: 4,
    name: 'Total Policy violations',
    count: policyViolationsCount,
    overviewItemicon: WarningSvg,
  });

  return result;
};

export const useStatsData = (
  groupId: string,
  fromDate: String,
  toDate: String,
  prevFromDate: String,
  prevToDate: String,
) => {
  const [usersViolations, setUsersViolations] = useState([]);
  const [appsAccessed, setAppsAccessed] = useState([]);
  const [activeUsers, setActiveUsers] = useState<any>([]);
  const [overviewData, setOverviewData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    UserGroupService.getUsersViolationsInUserGroup(
      groupId,
      fromDate,
      toDate,
    ).then((response) => {
      const requiredUsers: any = [];
      const keys = Object.keys(response);
      const values: number[] = Object.values(response);
      keys.forEach((key, index) => {
        requiredUsers.push({ userName: key, violations: values[index] });
      });
      const filteredUsers = requiredUsers.filter(
        (user: any) => user.violations !== 0,
      );
      const slicedUsers = filteredUsers.slice(0, 5);
      setUsersViolations(slicedUsers);
    });
    UserGroupService.getAppsAccessedInUserGroup(
      groupId,
      fromDate,
      toDate,
    ).then((response) => setAppsAccessed(response));
    constructActiveUsersData(
      groupId,
      fromDate,
      toDate,
      prevFromDate,
      prevToDate,
    ).then((response) => setActiveUsers(response));

    constructOverviewData(groupId, fromDate, toDate, prevFromDate, prevToDate)
      .then((response) => setOverviewData(response))
      .finally(() => setLoading(false));
  }, [fromDate, toDate]);
  return {
    usersViolations,
    appsAccessed,
    overviewData,
    activeUsers,
    loading,
  };
};
