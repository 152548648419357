import React, { useState, useEffect } from 'react';
import VideoPlayer from 'react-video-player-extended';
import { Marker } from 'react-video-player-extended/dist/marker';
import SessionRecordingService from '@src/api-service/session-recording-service/sessionRecordingService';
import { getAuthToken } from '@src/core-utils/Helper/helper';


interface VideoPlayerProps {
  url: string;
  className?: string;
  timeStart?: number;
  markers?: Marker[];
  posterUrl?: string;
};

const SonetVideoPlayer = ({ url, className = '', timeStart = 0, markers = [], posterUrl = "" }: VideoPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [mp4StreamUrl, setMp4StreamUrl] = useState("");
  const [isStreamingError, setIsStreamingError] = useState(false);


  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };


  return (
    <div className="sonet-video-player flex flex-col">
      <div className={`${className} rounded-lg overflow-hidden relative`}>
        <VideoPlayer
          url={mp4StreamUrl || url}
          isPlaying={isPlaying}
          onPlay={handlePlay}
          onPause={handlePause}
          volume={0.7}
          markers={markers}
          markerConfiguration={{ color: '#f97316', selectionColor: '#a3e635' }}
          controls={['Play', 'Time', 'Progress', 'FullScreen']}
          height='auto'
          width="100%"
          timeStart={timeStart}
          posterUrl={(isStreamingError && isPlaying) ? "https://dummyimage.com/1200x800/0f172a/f00.jpg&text=Unable+to+stream+the+recorded+session" : posterUrl}
        />
        <a
          href={url}
          download="SessionRecording.m4v"
          rel="noreferrer"
          className="group absolute top-0 right-0 h-12 w-12 text-gray-500 opacity-40 hover:opacity-100 grid items-center justify-center hover:text-white hover:cursor-pointer transition-colors duration-200 ease-in-out ">
          <svg
            fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-8 h-8 group-hover:drop-shadow-[0_1px_1px_rgba(0,0,0,0.8)] transition-shadow ease-in-out">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
          </svg>
        </a>
        {
          isPlaying ? null : (
            <div className="absolute inset-0 h-full grid items-center justify-center pointer-events-none">
              <svg fill="none" viewBox="0 0 24 24" strokeWidth="1" stroke="currentColor" className=" h-32 w-32 text-blue-100/40">
                <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
              </svg>
            </div>
          )
        }
        {
          (isPlaying && isStreamingError) ? (
            <div className="absolute inset-0 h-full grid items-center justify-center pointer-events-none">
              <svg fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className=" h-32 w-32 text-red-500/40">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
              </svg>
            </div>
          ) : null
        }
      </div>
    </div>
  )
};

export default SonetVideoPlayer;