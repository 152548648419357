import React, { useEffect, useState } from 'react';
import { TOKEN } from '../constants';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../routes';

export const useShowOrHideElement = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDisplay = () => {
    setOpen(!open);
  };

  const showElement = () => {
    setOpen(true);
  };

  const hideElement = () => {
    setOpen(false);
  };

  return { open, setOpen, toggleDisplay, showElement, hideElement };
};

export const useSessionTimeOut = () => {
  const history = useHistory();
  const removeTokenFromLocalStorage = () => {
    localStorage.removeItem(TOKEN);
  };
  const logout = () => {
    removeTokenFromLocalStorage();
    history.push(ROUTES.SESSION_TIMEOUT);
  };
  return { removeTokenFromLocalStorage, logout };
};

export const useRecentSearches = (key: string) => {
  let recentSearchesForKey = JSON.parse(localStorage.getItem(key) ?? '[]');
  const [recentSearches, setRecentSearches] = useState<Array<string>>(
    recentSearchesForKey,
  );

  const addRecentSearch = (searches: Array<string>) => {
    let tempRecentSearches = [...recentSearches];
    tempRecentSearches = searches.concat(tempRecentSearches);
    while (tempRecentSearches.length > 5) {
      tempRecentSearches.pop();
    }
    setRecentSearches(Array.from(new Set(tempRecentSearches)));
  };

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(recentSearches));
  }, [recentSearches]);

  return { recentSearches, addRecentSearch };
};

export default function useFullscreen(elRef: any) {
  if (typeof document.fullscreenElement === 'undefined') {
    return [false, false, () => {}];
  }

  const [isFullscreen, setIsFullscreen] = React.useState(
    document.fullscreenElement != null,
  );

  const setFullscreen: any = () => {
    if (!elRef.current) return;

    elRef.current
      .requestFullscreen()
      .then(() => {
        setIsFullscreen(document.fullscreenElement != null);
      })
      .catch(() => {
        setIsFullscreen(false);
      });
  };

  React.useLayoutEffect(() => {
    document.onfullscreenchange = () =>
      setIsFullscreen(document.fullscreenElement != null);

    return () => {
      document.onfullscreenchange = null;
    };
  });

  return [true, isFullscreen, setFullscreen];
}

export const policiesHandleAddAttachClone = () => {
  const [isActive, setActive] = useState<boolean>(false);
  const [type, setType] = useState<'attach' | 'clone'>('clone');
  const { open, showElement, hideElement } = useShowOrHideElement();
  const history = useHistory();

  const handleAddPolicy = () => {
    history.push(ROUTES.CREATE_POLICY);
  };

  const handleClonePolicy = () => {
    setType('clone');
    showElement();
  };

  const handleAttachPolicy = () => {
    setType('attach');
    showElement();
  };

  const toggleActive = () => {
    setActive(!isActive);
  };

  return {
    handleAddPolicy,
    handleAttachPolicy,
    handleClonePolicy,
    toggleActive,
    type,
    isActive,
    showElement,
    open,
    hideElement,
  };
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};
