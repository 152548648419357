const notificationMessages = {
  USER_NOTIFICATION: {
    TITLE: 'User Notifications',
    SUBTITLE: 'This will enable users to recieve notifications',
  },
  ADMINISTRATOR_NOTIFICATION: {
    TITLE: 'Administrator Notifications',
    SUBTITLE: 'Admins enabled to receive notifications',
  },
  ADMINISTRATOR: 'Administrator',
  RECEIVE: 'Receive ',
  NOTIFICATION_TYPE: 'Notification Type',
  EDIT_ADMINISTRATOR_DETAILS_MODAL: {
    HEADING: 'Edit Administrator Details',
    SUB_HEADING: 'Edit the type of notifications this administrator should recieve',
    NAME: 'Name',
    PLACEHOLDER_NAME: 'Enter name',
    EMAIL_ID: 'Email Id',
    PLACEHOLDER_EMAILID: 'Enter Email Id',
    NOTIFICATION_TYPES: 'Notification Types',
    POLICY_VIOLATIONS: 'Policy Violations',
  },
  POLICY_VIOLATIONS_TYPES: [
    { id: 'Critical', name: 'Critical' },
    { id: 'High', name: 'High' },
    { id: 'Informational', name: 'Informational' },
    { id: 'Low', name: 'Low' },
    { id: 'Medium', name: 'Medium' },
    { id: 'Error', name: 'Error' },
  ],
};

export default notificationMessages;
