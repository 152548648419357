import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  ACCOUNT_SETTING_LANGUAGE_OPTIONS,
  ACCOUNT_SETTING_TIMEZONE_OPTIONS,
} from '../../../core-utils/constants';
import onBoardingMessages from '../../../core-utils/messages/onBoarding';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import TextField from '../../atoms/TextField';
import Dropdown, { IDropdownOptionProps } from '../../molecules/Dropdown';

const style = {
  width: theme.spacing(178.5),
  height: theme.spacing(87.25),
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${EXTRA_COLORS.blue_100}`,
  borderRadius: theme.spacing(1),
  boxShadow: theme.shadows[10],
};

export interface ISystemConfigurationFormProps {
  timezone: IDropdownOptionProps;
  handleChangeTimeZone: (value: any) => void;
}
const SystemConfigurationForm = (props: ISystemConfigurationFormProps) => {
  const { timezone, handleChangeTimeZone } = props;

  const {
    HEADING,
    SUB_HEADING,
    TIMEZONE,
    LANGUAGE,
  } = onBoardingMessages.SYSTEM_CONFIGURATION_FORM;
  return (
    <Box
      sx={style}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography
        variant="h3"
        color={theme.palette.text.primary}
        paddingBottom={1}
      >
        {HEADING}
      </Typography>
      <Typography
        variant="body2"
        color={theme.palette.text.secondary}
        paddingBottom={9.5}
      >
        {SUB_HEADING}
      </Typography>
      <Box display="flex" flexDirection="column" rowGap={7.5}>
        <Dropdown
          label={TIMEZONE}
          options={ACCOUNT_SETTING_TIMEZONE_OPTIONS}
          value={timezone}
          handleDropdownChange={(e, v) => {
            handleChangeTimeZone(v);
          }}
        />
      </Box>
    </Box>
  );
};
export default SystemConfigurationForm;
