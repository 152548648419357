import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import { styled } from '@mui/styles';
import Button from '../../atoms/Button';
import stepInfo from '../../../core-utils/messages/onBoarding';
interface StepperFooter {
  totalSteps: number;
  currentStep: number;
  actions: footerActionType[];
  backButtonClick?: () => void;
}

interface footerActionType {
  actionName: string;
  actionCall: () => void;
  buttonVariant: 'outlined' | 'contained';
  isDisabled: boolean;
}
const FooterBox = styled(Paper)({
  height: theme.spacing(13),
  width: '100%',
  display: 'flex',
});

const GridItemAlignLeft = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const GridItemAlignRight = styled(Grid)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
});

const GridItemAlignCenter = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const OutlineButton = styled(Button)({
  marginRight: `${theme.spacing(5)} !important`,
  width: theme.spacing(28),
  minHeight: theme.spacing(10),
  '&:hover': { backgroundColor: EXTRA_COLORS.lightBlue },
});

const ContainedButton = styled(Button)({
  width: 'fit-content',
  minHeight: theme.spacing(10),
  '&:hover': { backgroundColor: theme.palette.primary.main },
});

const OnBoardingFooter: React.FC<StepperFooter> = ({
  totalSteps,
  currentStep,
  backButtonClick,
  actions: actionList,
}) => {
  let backAction = null;
  if (currentStep !== 1) {
    backAction = (
      <Button
        variant="text"
        startIcon={<ArrowBackIcon />}
        children="Back"
        onClick={backButtonClick}
        sx={{
          '&:hover': {
            ...theme.typography.body1,
            backgroundColor: 'unset',
            color: theme.palette.primary.main,
          },
          ...theme.typography.body2,
          color: theme.palette.primary.main,
        }}
      />
    );
  }

  const stepperActionsList = actionList.map((action, index) => {
    const { actionName, actionCall, buttonVariant, isDisabled } = action;
    return buttonVariant === 'outlined' ? (
      <OutlineButton
        key={['attr', index].join('-')}
        variant={buttonVariant}
        color="primary"
        size="large"
        children={actionName}
        onClick={actionCall}
        disabled={isDisabled}
      />
    ) : (
      <ContainedButton
        key={['attr', index].join('-')}
        variant={buttonVariant}
        color="primary"
        size="large"
        children={actionName}
        onClick={actionCall}
        disabled={isDisabled}
      />
    );
  });
  return (
    <FooterBox>
      <Grid container spacing={0} direction="row">
        <GridItemAlignLeft item xs={4} md={4} xl={4} lg={4}>
          {backAction}
        </GridItemAlignLeft>
        <GridItemAlignCenter item xs={4} md={4} xl={4} lg={4}>
          <Typography variant="overline" color={theme.palette.text.disabled}>
            {/* STEP {currentStep} OUT OF {totalSteps} */}
            {stepInfo.STEP_INFO.replace(
              '{currentStep}',
              `${currentStep}`,
            ).replace('{totalSteps}', `${totalSteps}`)}
          </Typography>
        </GridItemAlignCenter>
        <GridItemAlignRight item xs={4} md={4} xl={4} lg={4}>
          {stepperActionsList}
        </GridItemAlignRight>
      </Grid>
    </FooterBox>
  );
};

export default OnBoardingFooter;
