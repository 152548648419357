import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import {
  EMPTY_STATE_ICONS,
  IUsersViolationsWidgetProps,
} from '../../../core-utils/constants';
import dashboardMessages from '../../../core-utils/messages/dashboard';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import Button from '../../atoms/Button';
import EmptyState from '../EmptyState';

const style = {
  width: '100%',
  maxHeight: theme.spacing(150),
  height: 'fit-content',
  borderRadius: theme.spacing(2),
  border: `1px solid ${theme.palette.grey[300]}`,
  backgroundColor: theme.palette.common.white,
};

const UsersViolationsWidget = ({
  usersViolationsData,
  handleViewAll,
  tableContext,
}: {
  usersViolationsData: IUsersViolationsWidgetProps[];
  handleViewAll?: () => void;
  tableContext: 'dashboard' | 'usergroups';
}) => {
  const table = tableContext;
  let title, tableHeading;

  if (table === 'dashboard') {
    title = dashboardMessages.USERS_VIOLATIONS_WIDGET_MESSAGE;
    tableHeading = dashboardMessages.USERS_WIDGET_VIOLATIONS;
  } else {
    title = dashboardMessages.MOST_ACTIVE_USERS_WIDGET_MESSAGE;
    tableHeading = dashboardMessages.USERS_WIDGET_APPLICATIONS;
  }

  const headerColor = (props: string) => {
    if (props === 'dashboard') return EXTRA_COLORS.grey;
    else return theme.palette.text.secondary;
  };

  return (
    <React.Fragment>
      <Grid container sx={style} flexDirection="column">
        <Grid item sx={{ padding: theme.spacing(4) }}>
          <Typography variant="body1">{title}</Typography>
        </Grid>
        {usersViolationsData.length == 0 ? (
          <Grid container justifyContent="center">
            <Divider
              sx={{ paddingTop: theme.spacing(1), width: '100%' }}
              light
            />
            <Grid item sx={{ mt: '35%' }}>
              <EmptyState
                content={dashboardMessages.NO_INFORMATION_MESSAGE}
                icon={EMPTY_STATE_ICONS.USERS_WITH_VIOLATIONS_ICON}
                width={300}
                height={200}
              />
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid
              container
              justifyContent="space-between"
              sx={{
                padding: theme.spacing(3),
                backgroundColor: theme.palette.grey[300],
                color: headerColor(tableContext),
              }}
            >
              <Grid item>
                <Typography variant="body2">
                  {dashboardMessages.USERS_WIDGET_NAME}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">{tableHeading}</Typography>
              </Grid>
            </Grid>
            {usersViolationsData.map((user, index) => {
              return (
                <Grid
                  container
                  key={index}
                  justifyContent="space-between"
                  sx={{
                    padding: theme.spacing(3),
                  }}
                >
                  <Grid
                    item
                    sx={{
                      color: headerColor(tableContext),
                    }}
                  >
                    <Typography variant="body2">{user.userName}</Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      color:
                        table === 'dashboard'
                          ? theme.palette.error.main
                          : theme.palette.text.disabled,
                      mr: 18,
                    }}
                  >
                    <Typography variant="body2">{user.violations}</Typography>
                  </Grid>
                </Grid>
              );
            })}
          </>
        )}
        {usersViolationsData.length > 10 && (
          <Grid container justifyContent="center">
            <Button
              variant="text"
              color="primary"
              sx={{
                '&:hover': { backgroundColor: EXTRA_COLORS.lightBlue },
                ...theme.typography.body2,
              }}
              onClick={handleViewAll}
            >
              {dashboardMessages.VIEW_ALL}
            </Button>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default UsersViolationsWidget;
