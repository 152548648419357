import { Box, Grid, Typography, useTheme } from '@mui/material';
import React, { useState, SyntheticEvent } from 'react';
import { useHistory } from 'react-router';
import { GLOBAL_LABELS, IAppDetailsProps } from '../../../core-utils/constants';
import messages from '../../../core-utils/messages';
import DeleteRedIcon from '../../../../public/assets/delete-red.svg';
import { EXTRA_COLORS } from '../../../core-utils/theme';
import Button from '../../atoms/Button';
import IconFromSvg from '../../atoms/IconFromSvg';
import AppDetailsTabItem from '../AppDetailsTabItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tabs from '../Tabs';
import UserGroupsOfAppTabItem from '../UserGroupsOfAppTabItem';
import DeleteOrLogoutUserPopUp from '../../molecules/DeleteUserPopUp';
import AppService from '../../../api-service/app-service/AppService';
import { ROUTES } from '../../../core-utils/routes';
import UsersOfAppTabItem from '../UsersOfAppTabItem';
import ApplicationStatsTabItem from '../ApplicationStatsTabItem';
import { useAppSelector, useAppDispatch } from 'src/Redux/Store/store';
import { RootStateOrAny } from 'react-redux';
import { sideNavActions } from 'src/Redux/Reducers/sideNav';

const AppDetailsDashboard = (props: IAppDetailsProps) => {
  const theme = useTheme();
  const history = useHistory();
  const {
    appsDetails,
    appId,
    userDetails,
    usergroups,
    customDomain,
    updateUsergroupsOfApp,
    updateUsersOfApp,
    domainUrls,
    getAppDetails,
    rdpAppPath,
  } = props;
  const {
    BACK_TO_APPLICATIONS,
    ACTIONS,
    DELETE_APPLICATION_HEADING,
  } = messages;

  const [isOpen, setIsOpen] = useState({
    [DELETE_APPLICATION_HEADING]: false,
  });

  const handleBackClick = () => {
    // Might change after the api integrates
    history.replace(ROUTES.APPS);
  };

  const closeDeletePopUp = () => {
    setIsOpen({
      ...isOpen,
      [DELETE_APPLICATION_HEADING]: !isOpen[DELETE_APPLICATION_HEADING],
    });
  };

  const confirmDeleteClick = async () => {
    try {
      await AppService.deleteAppById(appId);
    } catch (error) {
      console.warn(error);
    }
    closeDeletePopUp();
    history.replace(ROUTES.APPS);
  };

  const APP_DETAIL_ACTION_DROPDOWN = [
    {
      text: 'Delete',
      icon: DeleteRedIcon,
      activeIcon: DeleteRedIcon,
      isDanger: true,
      handleClick: closeDeletePopUp,
    },
  ];

  const appsTabData = [
    {
      tabId: 1,
      label: `${GLOBAL_LABELS.DETAILS}`,
      tabpanel: (
        <AppDetailsTabItem
          appId={appId}
          appsDetails={appsDetails}
          customDomain={customDomain}
          domainUrls={domainUrls}
          getAppDetails={getAppDetails}
          rdpAppPath={rdpAppPath}
        />
      ),
    },
    {
      label: `${GLOBAL_LABELS.USER_GROUPS}(${usergroups?.length ?? 0})`,
      tabpanel: (
        <UserGroupsOfAppTabItem
          usergroups={usergroups}
          appId={appId}
          updateUsergroupsOfApp={updateUsergroupsOfApp}
          updateUsersOfApp={updateUsersOfApp}
        />
      ),
    },
    {
      label: `${GLOBAL_LABELS.USERS}(${userDetails?.length ?? 0})`,
      tabpanel: (
        <UsersOfAppTabItem
          userDetails={userDetails}
          appId={appId}
          updateUsersOfApp={updateUsersOfApp}
        />
      ),
    },
    {
      tabId: 2,
      label: `${GLOBAL_LABELS.STATS}`,
      tabpanel: <ApplicationStatsTabItem appId={appId} />,
    },
    // {
    //   label: `${GLOBAL_LABELS.POLICIES}(${0})`,
    //   tabpanel: (
    //     <InfoLayout
    //       imgPath={ComingSoonSvg}
    //       alt="Coming soon"
    //       text={messages.COMING_SOON}
    //       subText={messages.PAGE_UNDER_CONSTRUCTION}
    //     />
    //   ),
    // },
  ];

  const getRequiredTabs = () => {
    if (process.env.RELEASE && process.env.RELEASE === 'beta1R1') {
      return appsTabData.filter((data) => data.tabId !== 2); //disabling policy screen  and stats for release 1
    }
    return appsTabData;
  };

  const selectedtabId = useAppSelector(
    (state: RootStateOrAny) => state.sideNavDrawer.selectedTab,
  );
  const dispatch = useAppDispatch();
  const tabChangehandler = (value: number) => {
    value && dispatch(sideNavActions.setApplicationDashboardSelectedTab(value));
  };

  return (
    <Box
      sx={{
        height: '100%',
        backgroundColor: EXTRA_COLORS.bluishBackground,
        paddingLeft: theme.spacing(25),
        pt: 8,
        paddingRight: theme.spacing(25),
      }}
    >
      <Grid item>
        <Button
          sx={{
            '&:hover': {
              ...theme.typography.body1,
              backgroundColor: 'unset',
              color: theme.palette.primary.main,
            },
            ...theme.typography.body2,
            color: theme.palette.text.secondary,
          }}
          startIcon={<ArrowBackIcon />}
          variant="text"
          onClick={handleBackClick}
        >
          {BACK_TO_APPLICATIONS}
        </Button>
      </Grid>

      <Box
        sx={{
          pt: 4,
          pb: 4,
          position: 'relative',
          left: '6px',
          alignItems: 'center',
        }}
        display="flex"
        flexDirection="row"
      >
        <IconFromSvg path={appsDetails.appLogo} height={35} alt="app-icon" />

        <Typography
          sx={{
            flexGrow: 1,
            marginLeft: theme.spacing(4),
          }}
          variant="h2"
        >
          {appsDetails.name}
        </Typography>
        {/* <Grid item>
          <DropDownMenu
            menuItems={APP_DETAIL_ACTION_DROPDOWN}
            itemForDropdownMenu={
              <Button variant="outlined" endIcon={<ExpandMoreIcon />}>
                {ACTIONS}
              </Button>
            }
          />
        </Grid> */}
      </Box>
      <Tabs
        tabs={getRequiredTabs()}
        selectedindex={selectedtabId}
        tabIdChangeHandler={tabChangehandler}
      ></Tabs>
      <DeleteOrLogoutUserPopUp
        headingText={DELETE_APPLICATION_HEADING}
        open={isOpen[DELETE_APPLICATION_HEADING]}
        content={messages.CUSTOM_DELETE_MESSAGE.replace(
          '{"The User"}',
          `"${appsDetails.name}"`,
        )}
        handleClose={closeDeletePopUp}
        onButtonClick={confirmDeleteClick}
      />
    </Box>
  );
};

export default AppDetailsDashboard;
