import { IUserGroupsAndAppsTableRow } from '../../core-utils/constants';
import { getSearchData } from '../../core-utils/Helper/helper';
import { IUserTableRow } from '../../_components/molecules/UserTableRow';

const SearchService = {
  searchEntity: (
    searchText: string,
    dataToSearch: string[],
  ) => {
    return getSearchData(searchText, dataToSearch);
  },
};

export default SearchService;
