import React, { useEffect } from 'react';
import { Redirect, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { MatchParams, TOKEN } from '@utils/constants';
import { getAuthToken } from '@utils/Helper/helper';
import { ROUTES } from '@utils/routes';


export interface BookmarkRedirectPageProps
  extends RouteComponentProps<MatchParams> { }

const BookmarkRedirect = (props?: BookmarkRedirectPageProps) => {
  const connectionId = props?.match?.params?.id ?? '';
  const history = useHistory();

  useEffect(() => {
    if (connectionId) {
      history.replace(`/sonet/#/client/${connectionId}`);
      history.go(0);
    }

  }, [connectionId])


  return <p className="text-center text-sonet py-4">Redirecting...</p>;
};

export default BookmarkRedirect;
