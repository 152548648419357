import CloseIcon from '@mui/icons-material/Close';
import { Box, Grid, IconButton, Modal, Radio, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'src/Redux/Store/store';

import ApplicationService from '../../../api-service/application-service/ApplicationService';
import applicationPasswordManagerModalMessages from '../../../core-utils/messages/applicationPasswordManager';
import theme, { EXTRA_COLORS, modalStyle } from '../../../core-utils/theme';
import Button from '../../atoms/Button';
import TextField from '../../atoms/TextField';
import PasswordTextField from '../../molecules/PasswordTextField';
import { useAppSelector } from 'src/Redux/Store/store';
import { RootStateOrAny } from 'react-redux';
import Switch from '@atoms/Switch';
import { deleteEmptyProps } from '@utils/Helper/helper';

type CredsFromServer = {
  password?: string;
  username?: string;
  userkey?: string;
  domain?: string;
  SFTPUsername?: string;
  SFTPPassword?: string
  enableSFTP?: string;
  useSystemCredsForSFTP?: string;
}

export type UserSettingsChangePasswordModalProps = {
  showModal: boolean;
  onClose: () => void;
  name: string;
  clearForm: boolean;
  appId?: string;
  onSubmitHandler?: any;
};

const enum PROTOCOLS {
  RDP = 'rdp',
  BROWSER_APP = 'browser-app'
}

const ApplicationPasswordmanagerModal = ({
  showModal,
  onClose,
  name,
  clearForm,
  appId,
  onSubmitHandler,
}: UserSettingsChangePasswordModalProps) => {
  const {
    MODAL_HEADER,
    MODAL_SUBHEADER,
    MODAL_HEADER_NOTE,
    USER_NAME,
    USER_NAME_PLACEHOLDER,
    CONFIRM_PASSWORD_ERROR,
    REQUIRED_FIELD,
    NEW_PASSWORD,
    PLACEHOLDER_NEW_PASSWORD,
    CONFIRM_PASSWORD,
    PLACEHOLDER_CONFIRM_PASSWORD,
    REQUIRED,
    SAVE,
    CANCEL,
    SYSTEM_CREDENTIAL,
    SPECIFY_SFTP_CREDENTIAL,
    SFTP_CREDENTIAL,
    PLACEHOLDER_SFTP_PASSWORD,
    PLACEHOLDER_SFTP_CONFIRM_PASSWORD,
    PLACEHOLDER_SFTP_USERNAME,
    SFTP_PASSWORD,
    SFTP_CONFIRM_PASSWORD,
    SFTP_USERNAME,
    DOMAIN,
    PLACEHOLDER_SFTP_DOMAIN_NAME,
    SFTP_CONFIRM_PASSWORD_ERROR
  } = applicationPasswordManagerModalMessages;
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [passWordmanagerForm, setPasswordManagerForm] = useState({
    [USER_NAME]: '',
    [NEW_PASSWORD]: '',
    [CONFIRM_PASSWORD]: '',
    [SFTP_USERNAME]: '',
    [SFTP_PASSWORD]: '',
    [SFTP_CONFIRM_PASSWORD]: '',
    [DOMAIN]: '',
  });
  const [usernameErrorMessage, setUsernameErrorMessage] = useState('');
  const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('');
  const [
    confirmPasswordErrorMessage,
    setConfirmPasswordErrorMessage,
  ] = useState('');

  const [
    sftpUsernameErrorMessage,
    setSFTPUsernameErrorMessage
  ] = useState('');

  const [
    sftpnewPasswordErrorMessage,
    setSFTPNewPasswordErrorMessage
  ] = useState('');
  const [
    sftpconfirmPasswordErrorMessage,
    setSFTPConfirmPasswordErrorMessage,
  ] = useState('');

  const [
    isEnableSFTP,
    setEnableSFTP
  ] = useState(false);

  const [
    isUsedSystemCred,
    setSFTPCredSetting
  ] = useState(true);

  const [credsFromServer, setCredsFromServer] = useState<CredsFromServer>({});

  const appDetails: any = useAppSelector((state: RootStateOrAny) => state.connections.details.find((d: any) => d?.identifier === appId));
  const isWebApp = () => appDetails?.protocol === 'browser-app' || appDetails?.applicationType === 'browser-app';

  const isRDPAPP = (appDetails?.applicationType  || appDetails?.protocol)=== PROTOCOLS?.RDP;

  useEffect(() => {
    const userName = passWordmanagerForm[USER_NAME];
    const passwordValue = passWordmanagerForm[NEW_PASSWORD];
    const confirmPasswordValue = passWordmanagerForm[CONFIRM_PASSWORD];

    if(userName?.trim() && passwordValue && confirmPasswordValue) {
      if (passwordValue === confirmPasswordValue) {
        setIsSaveDisabled(false);
        setConfirmPasswordErrorMessage('');
      } else {
        setIsSaveDisabled(true);
        setConfirmPasswordErrorMessage(CONFIRM_PASSWORD_ERROR);
      }
    } else {
      setIsSaveDisabled(true);
    }

  }, [passWordmanagerForm[USER_NAME], passWordmanagerForm[NEW_PASSWORD], passWordmanagerForm[CONFIRM_PASSWORD]]);

  useEffect(() => {
    const retriveUserCreds = async () => {
      const { pathname } = location;
      const appIdFromUrl = pathname.replace('/apps/', '');
      const usercreds = await ApplicationService.retriveCredsByUserIdAndAppId(
        appId ? appId : appIdFromUrl,
        name,
      );
      usercreds.hasOwnProperty('username')
        ? ( 
        setCredsFromServer(usercreds),
        isRDPAPP && setEnableSFTP(usercreds['enableSFTP'] && JSON.parse(usercreds['enableSFTP'])),
        (isRDPAPP && usercreds['useSystemCredsForSFTP']) && setSFTPCredSetting(JSON.parse(usercreds['useSystemCredsForSFTP'])),
        setPasswordManagerForm((prevState) => {
          return {
            ...prevState,
            [USER_NAME]: usercreds?.['username'],
            [NEW_PASSWORD]: usercreds?.['password'],
            [CONFIRM_PASSWORD]: usercreds?.['password'],
            [SFTP_USERNAME]: usercreds?.['SFTPUsername'],
            [SFTP_PASSWORD]: usercreds?.['SFTPPassword'],
            [SFTP_CONFIRM_PASSWORD]: usercreds?.['SFTPPassword'],
            [DOMAIN]: usercreds?.['domain'],
          };
        }))
        : null;
    };
    retriveUserCreds();
  }, []);

  const handlePasswordChange = (event: any) => {
    setPasswordManagerForm((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value.trim() };
    });
    handleValidation(event);
  };

  const handleValidation = (event: any) => {
    const { name } = event.target;
    if ([name].includes(NEW_PASSWORD)) {
      const passValue = passWordmanagerForm[NEW_PASSWORD];
      passValue && passValue.trim().length > 0
        ? setNewPasswordErrorMessage('')
        : setNewPasswordErrorMessage(REQUIRED_FIELD);
    }
    if ([name].includes(CONFIRM_PASSWORD)) {
      const confirmPassValue = passWordmanagerForm[CONFIRM_PASSWORD];
      confirmPassValue && confirmPassValue.trim().length > 0
        ? setConfirmPasswordErrorMessage('')
        : setConfirmPasswordErrorMessage(REQUIRED_FIELD);
    }
    if ([name].includes(SFTP_USERNAME)) {
      const value = passWordmanagerForm[SFTP_USERNAME];
      value && value.trim().length > 0
        ? setSFTPUsernameErrorMessage('')
        : setSFTPUsernameErrorMessage(REQUIRED_FIELD);
    }
    if ([name].includes(SFTP_PASSWORD)) {
      const value = passWordmanagerForm[SFTP_PASSWORD];
      value && value.trim().length > 0
        ? setSFTPNewPasswordErrorMessage('')
        : setSFTPNewPasswordErrorMessage(REQUIRED_FIELD);
    }
    if ([name].includes(SFTP_CONFIRM_PASSWORD)) {
      const value = passWordmanagerForm[SFTP_CONFIRM_PASSWORD];
      value && value.trim().length > 0
        ? setSFTPConfirmPasswordErrorMessage('')
        : setSFTPConfirmPasswordErrorMessage(REQUIRED_FIELD);
    }
  };

  const validateRequiredFields = () => {
    if (!passWordmanagerForm[USER_NAME]) {
      setUsernameErrorMessage(REQUIRED_FIELD);
      return false;
    }
    if (isWebApp()) {
      if (!passWordmanagerForm[NEW_PASSWORD]) {
        setNewPasswordErrorMessage(REQUIRED_FIELD);
        return false;
      }

      if (!passWordmanagerForm[CONFIRM_PASSWORD]) {
        setConfirmPasswordErrorMessage(REQUIRED_FIELD);
        return false;
      } else if (passWordmanagerForm[NEW_PASSWORD] !== passWordmanagerForm[CONFIRM_PASSWORD]) {
        setConfirmPasswordErrorMessage(CONFIRM_PASSWORD_ERROR);
        return false;
      }
    } else if (passWordmanagerForm[NEW_PASSWORD] && passWordmanagerForm[NEW_PASSWORD] !== passWordmanagerForm[CONFIRM_PASSWORD]) {
      setConfirmPasswordErrorMessage(CONFIRM_PASSWORD_ERROR);
      return false;
    }

    if (isRDPAPP && !isUsedSystemCred) {
      if (!passWordmanagerForm[SFTP_USERNAME]) {
        setSFTPUsernameErrorMessage(REQUIRED_FIELD);
        return false;
      }

      if (!passWordmanagerForm[SFTP_PASSWORD]) {
        setSFTPNewPasswordErrorMessage(REQUIRED_FIELD);
        return false;
      }

      if (!passWordmanagerForm[SFTP_CONFIRM_PASSWORD]) {
        setSFTPConfirmPasswordErrorMessage(REQUIRED_FIELD);
        return false;
      } else if (passWordmanagerForm[SFTP_PASSWORD] !== passWordmanagerForm[SFTP_CONFIRM_PASSWORD]) {
        setSFTPConfirmPasswordErrorMessage(SFTP_CONFIRM_PASSWORD_ERROR);
        return false;
      }
    }
    return true;
  }

  const getChangedCreds = (credsOnServer: CredsFromServer, credsOnForm: any) => {
    const changedCreds = deleteEmptyProps({
      userId: credsOnForm.userId,
      username: credsOnForm.username,
      password: credsOnForm.password,
      domain: credsOnForm.domain,
      SFTPUsername: isRDPAPP ? (!credsOnForm?.useSystemCredsForSFTP ? credsOnForm?.SFTPUsername : '') : undefined,
      SFTPPassword: isRDPAPP ? (!credsOnForm?.useSystemCredsForSFTP ? credsOnForm?.SFTPPassword : '') : undefined,
      enableSFTP: isRDPAPP ? credsOnForm?.enableSFTP : undefined,
      useSystemCredsForSFTP: isRDPAPP ? (credsOnForm?.enableSFTP ? credsOnForm?.useSystemCredsForSFTP : '') : undefined
    });

      if (!credsOnForm.username || (credsOnServer.username === credsOnForm.username)) {
        delete changedCreds.username;
      }
      if (!credsOnForm.password || (credsOnServer.password === credsOnForm.password)) {
        delete changedCreds.password;
      }
      if (!credsOnForm.domain || (credsOnServer.domain === credsOnForm.domain)) {
        delete changedCreds.domain;
      }
      if ((credsOnServer?.SFTPUsername === undefined && credsOnForm?.useSystemCredsForSFTP) || credsOnServer?.SFTPUsername === credsOnForm?.SFTPUsername) {
        delete changedCreds?.SFTPUsername;
      }
      if ((credsOnServer?.SFTPPassword === undefined && credsOnForm?.useSystemCredsForSFTP) || credsOnServer?.SFTPPassword === credsOnForm?.SFTPPassword) {
        delete changedCreds?.SFTPPassword;
      }
      if (credsOnServer?.enableSFTP 
        && JSON.parse(credsOnServer?.enableSFTP as string) === credsOnForm?.enableSFTP) {
        delete changedCreds?.enableSFTP;
      }
      if ((credsOnServer?.useSystemCredsForSFTP === undefined && !credsOnForm?.enableSFTP) || (credsOnForm?.enableSFTP && credsOnServer?.useSystemCredsForSFTP && JSON.parse(credsOnServer?.useSystemCredsForSFTP as string)) === credsOnForm?.useSystemCredsForSFTP) {
        delete changedCreds?.useSystemCredsForSFTP;
      }
    return changedCreds;
  }

  const handleSaveClick = async () => {
    if (!validateRequiredFields()) return;
    // API Call Can setup here
    const { pathname } = location;
    let obj = {
      username: passWordmanagerForm[USER_NAME],
      password: passWordmanagerForm[NEW_PASSWORD],
      userId: name,
    };
    
    const sftpProps: Record<string, any> = {};
    if (isRDPAPP) {
      sftpProps.enableSFTP = isEnableSFTP;
      sftpProps.useSystemCredsForSFTP = isUsedSystemCred;
      sftpProps.domain = passWordmanagerForm[DOMAIN];
      if (!isUsedSystemCred) {
        sftpProps.SFTPUsername = passWordmanagerForm[SFTP_USERNAME];
        sftpProps.SFTPPassword = passWordmanagerForm[SFTP_PASSWORD];
      }
    }

    obj = { ...obj, ...sftpProps };
    const appIdFromUrl = pathname.replace('/apps/', '');
    const changedCreds = getChangedCreds(credsFromServer, obj);
    const apiResponse = await ApplicationService.createNewCredentials(
      appId ? appId : appIdFromUrl,
      changedCreds,
    );
    onSubmitHandler && apiResponse.hasOwnProperty('userId')
      ? onSubmitHandler(true, appId)
      : null;
    handleClearForm();
    onClose();
  };

  const handleClearForm = () => {
    setPasswordManagerForm({
      [USER_NAME]: '',
      [NEW_PASSWORD]: '',
      [CONFIRM_PASSWORD]: '',
      [SFTP_USERNAME]: '',
      [SFTP_PASSWORD]: '',
      [SFTP_CONFIRM_PASSWORD]: '',
    });
    setNewPasswordErrorMessage('');
    setConfirmPasswordErrorMessage('');
    // setUserChangePasswordErrorMessages({
    //   [NEW_PASSWORD]: '',
    //   [CONFIRM_PASSWORD]: '',
    // });
    // setNewPasswordErrorMessage('');
    // PASSWORD_PARAMETERS.forEach((password) => {
    //   password.satisfied = false;
    // });
  };

  useEffect(() => {
    handleClearForm();
  }, [clearForm]);
  return (
    <Modal open={showModal} onClose={onClose}>
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        sx={{
          ...modalStyle,
          border: `1px solid ${EXTRA_COLORS.blue_100}`,
          borderRadius: 1,
          width: theme.spacing(155),
          height: 'auto',
          paddingLeft: theme.spacing(6),
        }}
      >
        <Grid item container justifyContent="space-between">
          <Grid item>
            <Typography variant="h3">{MODAL_HEADER}</Typography>
          </Grid>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          sx={{
            color: EXTRA_COLORS.greyFont,
            paddingBottom: theme.spacing(4),
          }}
        >
          <Typography variant="body2">{MODAL_SUBHEADER}</Typography>
          <Typography variant="caption">{MODAL_HEADER_NOTE}</Typography>
        </Grid>
        <Grid item sx={{ mt: theme.spacing(3), mb: theme.spacing(3) }}>
          <TextField
            name={USER_NAME}
            label={USER_NAME}
            value={passWordmanagerForm[USER_NAME]}
            testId={USER_NAME}
            placeholder={USER_NAME_PLACEHOLDER}
            required
            inputProps={{
              autoComplete: 'new-password',
            }}
            handleChange={handlePasswordChange}
            error={usernameErrorMessage.trim() !== ''}
            // onBlur={handleOrganisationValidation}
          />
        </Grid>
        {isRDPAPP 
          && <Grid item sx={{ mt: theme.spacing(3), mb: theme.spacing(3) }}>
              <TextField
                name={DOMAIN}
                label={DOMAIN}
                value={passWordmanagerForm[DOMAIN]}
                testId={DOMAIN}
                placeholder={PLACEHOLDER_SFTP_DOMAIN_NAME}
                inputProps={{
                  autoComplete: 'new-password',
                }}
                handleChange={handlePasswordChange}
              />
          </Grid>
        }
        <Grid item sx={{ mt: theme.spacing(3), mb: theme.spacing(3) }}>
          <PasswordTextField
            name={NEW_PASSWORD}
            label={NEW_PASSWORD}
            value={passWordmanagerForm[NEW_PASSWORD]}
            placeholder={PLACEHOLDER_NEW_PASSWORD}
            inputProps={{
              autoComplete: 'new-password',
            }}
            required={isWebApp() || isEnableSFTP}
            error={newPasswordErrorMessage.trim() !== ''}
            helperText={newPasswordErrorMessage}
            // onBlur={handleValidation}
            handleChange={handlePasswordChange}
          />
        </Grid>
        <Grid item sx={{ mt: theme.spacing(3), mb: theme.spacing(3) }}>
          <PasswordTextField
            name={CONFIRM_PASSWORD}
            label={CONFIRM_PASSWORD}
            placeholder={PLACEHOLDER_CONFIRM_PASSWORD}
            value={passWordmanagerForm[CONFIRM_PASSWORD]}
            inputProps={{ 'data-testid': 'confirm-new-password' }}
            required={isWebApp() || isEnableSFTP}
            autoComplete="off"
            error={confirmPasswordErrorMessage.trim() !== ''}
            helperText={confirmPasswordErrorMessage}
            // onBlur={handleValidation}
            handleChange={handlePasswordChange}
          />
        </Grid>
        {isRDPAPP &&
          <>
            <Grid item sx={{ mt: theme.spacing(3), mb: theme.spacing(3)}}>
              <Box display="flex" flexDirection="row">
                <Switch
                  checked={isEnableSFTP}
                  onChange={() => {
                    setEnableSFTP((prev) => !prev)
                    setSFTPCredSetting(true)
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.text.disabled,
                    paddingLeft: 2
                  }}
                >
                  {SFTP_CREDENTIAL}
                </Typography>
              </Box>
              {isEnableSFTP &&
                <>
                  <Box display="flex" flexDirection="row" sx={{ mt: theme.spacing(3) }}>
                    <Radio
                      name="enableSFTP"
                      size="small"
                      sx={{ padding: 0 }}
                      checked={isUsedSystemCred}
                      onChange={(event) => setSFTPCredSetting(JSON.parse(event?.target?.value))}
                      value={true}
                    />
                    <Typography
                      variant="body2"
                      sx={{ color: theme.palette.text.secondary, paddingLeft: 3 }}
                    >
                      {SYSTEM_CREDENTIAL}
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="row" sx={{ mt: theme.spacing(3) }}>
                    <Radio
                      name="enableSFTP"
                      size="small"
                      sx={{ padding: 0 }}
                      checked={!isUsedSystemCred}
                      onChange={(event) => setSFTPCredSetting(JSON.parse(event?.target?.value))}
                      value={false}
                    />
                    <Typography
                      variant="body2"
                      sx={{ color: theme.palette.text.secondary, paddingLeft: 3 }}
                    >
                      {SPECIFY_SFTP_CREDENTIAL}
                    </Typography>
                  </Box>
                </>}
            </Grid>

            {!isUsedSystemCred &&
              <>
                <Grid item sx={{ mt: theme.spacing(3), mb: theme.spacing(3) }}>
                  <TextField
                    name={SFTP_USERNAME}
                    label={SFTP_USERNAME}
                    value={passWordmanagerForm[SFTP_USERNAME]}
                    testId={SFTP_USERNAME}
                    placeholder={PLACEHOLDER_SFTP_USERNAME}
                    required
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                    handleChange={handlePasswordChange}
                    error={sftpUsernameErrorMessage.trim() !== ''}
                    helperText={sftpUsernameErrorMessage}
                    onBlur={handleValidation}
                  />
                </Grid>
                <Grid item sx={{ mt: theme.spacing(3), mb: theme.spacing(3) }}>
                  <PasswordTextField
                    name={SFTP_PASSWORD}
                    label={SFTP_PASSWORD}
                    value={passWordmanagerForm[SFTP_PASSWORD]}
                    placeholder={PLACEHOLDER_SFTP_PASSWORD}
                    inputProps={{
                      autoComplete: 'new-password',
                    }}
                    required
                    error={sftpnewPasswordErrorMessage.trim() !== ''}
                    helperText={sftpnewPasswordErrorMessage}
                    onBlur={handleValidation}
                    handleChange={handlePasswordChange}
                  />
                </Grid>
                <Grid item sx={{ mt: theme.spacing(3), mb: theme.spacing(3) }}>
                  <PasswordTextField
                    name={SFTP_CONFIRM_PASSWORD}
                    label={SFTP_CONFIRM_PASSWORD}
                    placeholder={PLACEHOLDER_SFTP_CONFIRM_PASSWORD}
                    value={passWordmanagerForm[SFTP_CONFIRM_PASSWORD]}
                    inputProps={{ 'data-testid': 'sftp-confirm-new-password' }}
                    required
                    autoComplete="off"
                    error={sftpconfirmPasswordErrorMessage.trim() !== ''}
                    helperText={sftpconfirmPasswordErrorMessage}
                    onBlur={handleValidation}
                    handleChange={handlePasswordChange}
                  />
                </Grid>
                </>}
          </>
        }
        <Grid
          item
          container
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography component="span" variant="body2">
              {REQUIRED}
            </Typography>
            <Typography component="span" variant="body2" color="error">
              *
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="text"
              color="primary"
              onClick={onClose}
              sx={{
                width: theme.spacing(28),
                height: theme.spacing(10),
                marginRight: 3,
                '&:hover': { backgroundColor: EXTRA_COLORS.lightBlue },
              }}
            >
              {CANCEL}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isSaveDisabled}
              onClick={handleSaveClick}
              sx={{
                width: theme.spacing(28),
                height: theme.spacing(10),
                textAlign: 'center',
              }}
            >
              {SAVE}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ApplicationPasswordmanagerModal;
