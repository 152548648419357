import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SonetLogoSvg from '@assets/sonet-logo.svg';
import { useAppDispatch, useAppSelector } from '../../../Redux/Store/store';
import { sessionActions } from 'src/Redux/Reducers/session';

import {
  getFreshToolTipItemList,
  getAuthToken,
  getRole,
  getSideNavEndItems,
  getSideNavItems,
  getUserNameFromEmail,
  isEmailValid,
  getUserId,
} from '../../../core-utils/Helper/helper';
import messages from '../../../core-utils/messages/mfaLoginMessages';
import { ROUTES } from '../../../core-utils/routes';

import UserMfaLoginForm from '../../organisms/UserMfaLoginForm';

import MainFooter from 'src/_components/molecules/MainFooter';
import { Box, Grid, Stack } from '@mui/material';
import UserService from 'src/api-service/user-service/UserService';
import LoginService from '@src/api-service/login-service/LoginService';
import { RootStateOrAny } from 'react-redux';

const UserMfaLoginPage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [totp, setTotp] = useState('');

  const [error, setError] = React.useState('');

  const role = useAppSelector(
    (state: RootStateOrAny) => state?.token?.role,
  );

  const handleTotpFieldChange = (event: any) => {
    setError('');
    setTotp(event.target.value);
  };

  const handleValidation = () => {
    return totp.length === 6;
  };

  const handleLoginClick = async () => {
    if (handleValidation()) {
      const verified = await UserService.authenticateTotp(
        getUserId() ?? '',
        totp,
      );
      if (verified === true) {
        await UserService.setTotpStatus();
        if (role !== 'admin') {
          history.replace(ROUTES.MY_APPLICATIONS);
        } else {
          history.replace(ROUTES.MY_APPLICATIONS);
        }
      } else {
        setError('Verification code is incorrect');
      }
    }
  };

  const handleCancel = () => {
    const token = getAuthToken();
    LoginService.deleteAuthToken(token)
    .then(() => {
      dispatch(sessionActions.clearSession())
    })
    history.replace(ROUTES.LOGIN);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      handleLoginClick();
    }
  };

  return (
    <Box>
      <UserMfaLoginForm
        iconHeading={messages.LOGIN_ICON_HEADING}
        icon={SonetLogoSvg}
        subHeading={messages.LOGIN_SUBHEADING}
        suggestionText={messages.LOGIN_SUGGESTION_TEXT}
        verificationCodeTextFieldlabel={messages.VERIFY_TEXT_FIELD_LABEL}
        verifyButtonText={messages.LOGIN_BUTTON_TEXTS.VERIFY}
        cancelButtonText={messages.LOGIN_BUTTON_TEXTS.CANCEL}
        handleTextFieldOneChange={handleTotpFieldChange}
        onVerifyButtonClick={handleLoginClick}
        onCancelButtonClick={handleCancel}
        error={error}
        handleKeyPress={handleKeyPress}
      />
      <Box position="fixed" bottom={0} width="100%">
        <MainFooter logoRequired={true} versionRequired={false}></MainFooter>
      </Box>
    </Box>
  );
};

export default UserMfaLoginPage;
