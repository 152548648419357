import { useState } from 'react';
import PolicyService, {
  IListPolicyResponse,
} from '../../../api-service/policy-service/PolicyService';
import { IPoliciesTableRowProps } from '../../molecules/PoliciesTableRow';

export const getPolicies = async () => {
  const listPolicies: IListPolicyResponse[] = await PolicyService.listPolicies();
  const { resultPolicyTableRows, resultSearchData } = getPolicyTableRows(
    listPolicies,
  );
  return { resultPolicyTableRows, resultSearchData };
};

export const getPolicyTableRows = (listPolicies: IListPolicyResponse[]) => {
  const resultPolicyTableRows: IPoliciesTableRowProps[] = [];
  const policiesSearchData: Set<string> = new Set();
  listPolicies.forEach((policy) => {
    resultPolicyTableRows.push({
      id: policy.id,
      policyName: policy.policyName,
      createdBy: policy.createdBy,
      dateTime: policy.lastPolicyHitTime ?? '',
      updatedBy: {
        name: policy.lastUpdatedBy,
        dateTime: policy.lastUpdated ?? '',
      },
      applications: policy.applications,
      hideStatus: true,
      status:
        policy.policyStatus.toLowerCase() === 'active' ? 'Active' : 'Inactive',
    });
    policiesSearchData.add(policy.policyName);
    policiesSearchData.add(policy.lastUpdatedBy);
    policy.applications.forEach(policiesSearchData.add, policiesSearchData);
  });
  resultPolicyTableRows.sort(function (a, b) {
    if (a.policyName === b.policyName) {
      return 0;
    } else if (a.policyName === null) {
      return 1;
    } else if (b.policyName === null) {
      return -1;
    } else {
      return a.policyName.toLowerCase() < b.policyName.toLowerCase() ? -1 : 1;
    }
  });
  const resultSearchData = Array.from(policiesSearchData);
  return { resultPolicyTableRows, resultSearchData };
};
