import { GLOBAL_LABELS } from '../constants';

const policyBuilderMessages = {
  TEST_RESULT: 'The test result for {policyName} is',
  ALLOW: 'Allow',
  DENY: 'Deny',
  TEST_POLICY: 'Test Policy',
  EDIT_Policy: 'Edit Policy',
  BUTTON_LABELS: {
    SAVE: GLOBAL_LABELS.SAVE,
    SAVED: 'Saved',
    DEPLOY: 'Deploy',
    LEAVE: 'Leave',
    CANCEL: 'Cancel',
  },
  CREATE_LIST: {
    HEADING: 'Add New List',
    SUB_HEADING:
      'Input multiple values for a condition by creating a pre-configured list.',
    REQUIRED: GLOBAL_LABELS.REQUIRED,
    TEXT_FIELD_LABELS: {
      LIST_NAME: 'List Name',
      CONDITION_TYPE: 'Condition Type',
      LIST_VALUES: 'List Values',
    },
    PLACEHOLDER_LABELS: {
      LIST_NAME_LABEL: 'Enter the name of the list here',
      CONDITION_TYPE_LABEL: 'Enter or select condition type here',
      LIST_VALUES_LABEL: 'Enter list value here',
    },
    ERROR_MESSAGES: {
      LIST_VALUE_ALREADY_EXISTS: 'list value already exists',
    },
    ADD_LIST: '+ Add List Value',
  },
  SNACKBAR_MESSAGES: {
    TITLE: 'Allow and Deny cannot be used for same condition',
    SUBHEADING: 'Uncheck the selected actions to select the disabled actions',
    DISABLE_AND_TITLE: `'And' action cannot be used for the last condition`,
    DISABLE_AND_SUBTITLE: `'And' action is disabled for last condition , please add another condition to use 'And' action`,
    CONDITION_NOT_APPLICABLE_TITLE:
      'This condition does not apply to the policy block',
    CONDITION_NOT_APPLICABLE_SUBTITLE:
      'Select conditions based on the highlighted policy block',
    SAVED_SUCCESSFULLY: 'Changes saved successfully!',
    FIELD_EMPTY_TITLE: 'Policy Block Field Empty',
    FIELD_EMPTY_SUBTITLE:
      'Please fill all the policy block fields before saving.',
  },
  POLICY_ERROR_MESSAGE: "policy name can't be empty",
  POLICY_NAME_ERROR_SPACES: 'policy name should not contain spaces',
  POLICY_NAME_ERROR_HYPHEN: 'policy name should not contain hyphen',
  POLICY_NAME_ERROR_ALPHANUMERIC_ONLY:
    'It can contain alphanumeric and _ characters only',
  DUPLICATE_POLICY_NAME: 'Policy with this name already exists',
  POLICY_NAME_OUT_OF_LIMITS: 'Policy name cannot be more than 50 characters',
  POLICY_BLOCKS: 'Policy Blocks',
  SEARCH_PLACEHOLDER_FOR_POLICY_BLOCKS: 'Search for policy blocks',
  CONDITIONS_AND_ACTIONS: 'Conditions & Actions',
  SEARCH_CONDITIONS: 'Search for conditions',
  SEARCH_ACTIONS: 'Search for actions',
  SEARCH_LISTS: 'Search for lists',
  CLOSE_BUILDER_LABELS: {
    HEADING: 'Save changes',
    SUBHEADING:
      "You haven't saved your changes. Are you sure you want to leave the policy builder?",
  },
  OPEN_CONDITIONS_PANEL: 'Open conditions and actions panel',
  TIME_PICKER: {
    START_TIME: 'Start Time',
    END_TIME: 'End Time',
    TIME_ZONE: 'Time Zone',
  },
  HELP_TOOLTIP: {
    AND: {
      HEADING: "'and' Operator",
      SUBHEADING:
        "When 'and' is selected, a new condition configuration is added to the previous condition.",
    },
    OR: {
      HEADING: "'or' Operator",
      SUBHEADING:
        "When 'OR' is selected, a new condition configuration is added separately",
    },
    POLICY_NAME: 'Policy Name',
    POLICY_NAME_HELP:
      'Provide a policy name which has an indication to the purpose of this policy. No two policies can have the same name. ',
    DELETE_BLOCK: 'Delete policy block',
    COLLAPSE_BLOCK: 'Collapse policy block',
    COLLAPSE_ALL_BLOCKS: 'Collapse all policy blocks',
    SAVE_POLICY: 'Save all edits made to this policy',
    EXIT_POLICY: 'Exit policy builder',
    COPY_BLOCK: 'Copy policy block',
  },
};
export default policyBuilderMessages;
