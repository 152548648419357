import { getUserId } from 'src/core-utils/Helper/helper';
import Config from '../../../config/config';
import { NOTIFICATIONS_LIMIT } from '../../core-utils/constants';
import axios from '../axios';

let count = 0;
const NotificationDashboardService = {
  getAllNotifications: async () => {
    try {
      const notifications = await axios.get(
        `http://localhost:3004/notificationDashboard`,
      );
      return notifications.data;
    } catch (error) {
      return error;
    }
  },
  getPaginatedNotifications: async (
    pageset: number,
    sorted: boolean,
    searchQuery?: string,
  ) => {
    try {
      const notifications = await axios.get(
        `${Config.DASHBOARD_API_URL}notifications2/${getUserId() ?? ''}`,
        {
          params: {
            pageOffset: pageset,
            pageSize: NOTIFICATIONS_LIMIT,
            orderBy: 'sentDate',
            asc: sorted,
            searchQuery: searchQuery,
          },
        },
      );
      return notifications.data;
    } catch (error) {
      return error;
    }
  },
  getNotificationFromChannel: async () => {
    try {
      const notifications = await axios.post(
        `http://localhost:3200/connection`,
        { count: count },
      );
      count++;
    } catch (error) {
      return error;
    }
  },
  updateViewStatus: async (notificationId: number) => {
    try {
      const viewStatusResponse = await axios.patch(
        `${Config.DASHBOARD_API_URL}notifications2/viewStatus/${notificationId}`,
      );
      return viewStatusResponse.data;
    } catch (error) {
      return error;
    }
  },
  deleteNotification: async (notificationId: number) => {
    try {
      const archiveNotificationResponse = await axios.delete(
        `${Config.DASHBOARD_API_URL}notifications2/${notificationId}`,
      );
      return archiveNotificationResponse.data;
    } catch (error) {
      return error;
    }
  },
  getNewNotifications: async () => {
    try {
      const newNotifications = await axios.get(
        `${Config.DASHBOARD_API_URL}notifications2/${getUserId() ?? ''}`,
        {
          params: {
            filterByViewed: true,
          },
        },
      );
      return newNotifications.data;
    } catch (error) {
      return error;
    }
  },
};

export default NotificationDashboardService;
