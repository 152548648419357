import { Box } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import Config from '../../../../config/config';

interface IHTMLRendererProps {
  html: any;
  isMinimized?: boolean;
}

const isValidNode = function () {
  return true;
};

const getHelpImagePrefixUrl = (url: string) => {
  return Config.DASHBOARD_API_URL + 'help/htmlFile/' + url;
};

const HTMLRenderer = (props: IHTMLRendererProps) => {
  const HtmlToReactParser = require('html-to-react').Parser;
  const HtmlToReact = require('html-to-react');

  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
  const processingInstructions = [
    {
      shouldProcessNode: function (node: any) {
        return node.name && node.name === 'body' && props.isMinimized;
      },
      processNode: function (node: any, children: any, index: any) {
        node.attribs = { style: { padding: '4px' } };
        return React.createElement('div', node.attribs, children);
      },
    },
    {
      shouldProcessNode: function (node: any) {
        return node.name && node.name === 'h2';
      },
      processNode: function (node: any, children: any, index: any) {
        node.attribs = { style: { paddingTop: '2px', marginTop: '2px' } };
        return React.createElement('h2', node.attribs, children);
      },
    },
    {
      shouldProcessNode: function (node: any) {
        return (
          node.name &&
          node.name === 'span' &&
          node.children.some((child: any) => {
            return child.name === 'img';
          })
        );
      },
      processNode: function (node: any, children: any, index: any) {
        console.log(node);
      },
    },
    {
      shouldProcessNode: function (node: any) {
        return node.name && node.name === 'img';
      },
      processNode: function (node: any, children: any, index: any) {
        const src = node.attribs?.src;
        const newSrc = getHelpImagePrefixUrl(src);
        return React.createElement('img', {
          src: newSrc,
          style: { width: props.isMinimized ? '300px' : 'inherit' },
        });
      },
    },
    {
      shouldProcessNode: function (node: any) {
        return true;
      },
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];

  const htmlToReactParser = new HtmlToReactParser();
  //const reactElement = htmlToReactParser.parse(props.html, { trim: true });
  // const reactElement = props.isMinimized
  //   ? htmlToReactParser.parseWithInstructions(
  //       props.html,
  //       isValidNode,
  //       processingInstructions,
  //     )
  //   : htmlToReactParser.parse(props.html, { trim: true });
  const reactElement = htmlToReactParser.parseWithInstructions(
    props.html,
    isValidNode,
    processingInstructions,
  );
  const _reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);

  console.log(reactElement);
  return (
    <Box
    //width={props.isMinimized ? 280 : '100%'}
    //height="inherit"
    //display="flex"
    //flexWrap="wrap"
    //sx={{ overflowWrap: 'break-word' }}
    >
      {reactElement}
    </Box>
  );
};

export default HTMLRenderer;
