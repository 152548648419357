import React, { useState } from 'react';
import { Stack, Typography, MenuItem } from '@mui/material';

import { IDropDownMenuItem } from '../../../core-utils/constants';
import { styled } from '@mui/material/styles';
import theme from '../../../core-utils/theme';

export const StyledMenuItem = styled(MenuItem)(({ ...props }) => ({
  '&:hover': {
    backgroundColor: theme.palette.grey[100],
  },
  backgroundColor: theme.palette.common.white,
  width: '100%',
  padding: theme.spacing(3),
}));
const DropDownItem = ({
  text,
  icon,
  activeIcon,
  isDanger,
  handleClick,
  handleClose,
  cyId,
  ...props
}: IDropDownMenuItem) => {
  const [hover, setHover] = useState(false);
  return (
    <React.Fragment>
      <StyledMenuItem
        data-testid={'action-'.concat(text)}
        onClick={handleClick}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        onClickCapture={handleClose}
        data-cyId={cyId}
      >
        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
          justifyContent="flex-start"
        >
          {icon && <img src={hover ? activeIcon : icon} alt="ic" />}
          <Typography
            variant="body2"
            sx={{
              color: isDanger ? theme.palette.error.main : 'text.disabled',
            }}
          >
            {text}
          </Typography>
        </Stack>
      </StyledMenuItem>
    </React.Fragment>
  );
};
export default DropDownItem;
