import { Grid, useTheme } from '@mui/material';
import React from 'react';
import DownArrowIcon from '../../../../public/assets/chevronDown-inactive.svg';
import { IDropdownOptionProps } from '../../molecules/Dropdown';
import PolicyBlockItem from '../../molecules/PolicyBlockItem';
import PolicyBlockSelectDropdown from '../../molecules/PolicyBlockSelectDropdown';
import SelectPolicyBlock from '../../molecules/SelectPolicyBlock';
import { IPolicyBlockProps } from '../../atoms/DraggablePolicyBlock';
import policyMessages from '../../../core-utils/messages/policyMessages';
import { IDropDownMenuItem } from '../../../core-utils/constants';
import { IPolicyMultipleSelectChip } from '../PolicyMultipleChipDropdown';

export enum Policy_Blocks {
  CONTENT = 'content',
  APP = 'app',
  ACCESS = 'access',
};

interface IPolicyConditionCardProps {
  policyCardData: any;
  userGroupDropdown: IDropdownOptionProps[];
  applicationDropdown: IDropdownOptionProps[];
  urlDropdown: IDropdownOptionProps[];
  handlePolicyChange: (cardd: number, id: number, value: any) => void;
  handleEquationChange: (cardId: number, id: number, value: any) => void;
  handleDeleteRow?: (cardId: number, id: number) => void;
  handlePolicyValueTextChange?: (
    cardId: number,
    id: number,
    event: any,
  ) => void;
  handlePolicyValueDropdownChange?: (
    cardId: number,
    id: number,
    value: any,
  ) => void;
  handlePolicyActionChange?: (cardId: number, value: any, id?: number) => void;
  handleAddCondition: (cardId: number) => void;
  handleJoinCondition: (cardId: number, rowId: number) => void;
  handleDeleteCard?: (cardId: number) => void;
  handleCopyCard?: (cardId: number) => void;
  handleSaveCard?: (cardId: number) => void;
  handleToggleCollapse: (cardId: number) => void;
  addPolicyBlockMolecule?: JSX.Element;
  addPolicyBlockEmptyMolecule?: JSX.Element;
  showSelectPolicyBlock?: boolean;
  handleSelectPolicyBlock: any;
  policyBlocks: IPolicyBlockProps[];
  handleDropConditions: (cardId: number, conditionId: string) => void;
  handleOpenSnackbar?: () => void;
  handleCloseSnackbar?: () => void;
  focusedCardId?: number;
  handleClickCard?: (cardItemId: number) => void;
  handleChangeTimeValue?: (
    cardId: number,
    rowId: number,
    startTime: string,
    endTime: string,
    timezone?: string,
  ) => void;
}

const PolicyConditionCard = ({
  focusedCardId,
  policyCardData,
  userGroupDropdown,
  applicationDropdown,
  urlDropdown,
  handleClickCard,
  handlePolicyChange,
  handleEquationChange,
  handlePolicyValueTextChange,
  handlePolicyValueDropdownChange,
  handlePolicyActionChange,
  handleAddCondition,
  handleDeleteRow,
  handleDeleteCard,
  handleCopyCard,
  handleSaveCard,
  handleToggleCollapse,
  addPolicyBlockMolecule,
  addPolicyBlockEmptyMolecule,
  showSelectPolicyBlock,
  policyBlocks,
  handleSelectPolicyBlock,
  handleDropConditions,
  handleOpenSnackbar,
  handleCloseSnackbar,
  handleChangeTimeValue,
  handleJoinCondition,
}: IPolicyConditionCardProps) => {
  const theme = useTheme();
  const handleSelected = (itemId: string | undefined) => {
    itemId && handleSelectPolicyBlock(itemId);
  };

  const constructMenuItems = () => {
    const policyDropDownItems: Array<IDropDownMenuItem> = policyBlocks.map(
      (policyBlock) => {
        return {
          text: policyBlock?.children,
          handleClick: () => {
            handleSelected(policyBlock.elementId);
          },
        };
      },
    );
    return policyDropDownItems;
  };

  return (
    <>
      {policyCardData?.length > 0 ? (
        policyCardData.map((policyCardItem: any, index: number) => {
          return (
            <div
              style={{
                marginBottom: '8px',
                paddingLeft: 50,
                paddingRight: 50,
                paddingBottom: 10,
                paddingTop: 10,
              }}
            >
              <PolicyBlockItem
                policyBlockIndex={index}
                cardId={policyCardItem.id}
                focused={focusedCardId === policyCardItem.id}
                expanded={policyCardItem.expanded}
                policyName={policyCardItem.name}
                policyType={policyCardItem.type}
                defaultAction={policyCardItem.defaultAction}
                defaultActionOptions={policyCardItem.defaultActionOptions}
                policyConditions={policyCardItem.policyConditions}
                userGroupDropdown={userGroupDropdown}
                applicationDropdown={applicationDropdown}
                urlDropdown={urlDropdown}
                handleClickCard={handleClickCard}
                handlePolicyChange={handlePolicyChange}
                handlePolicyActionChange={handlePolicyActionChange}
                handleToggleCollapse={handleToggleCollapse}
                handleEquationChange={handleEquationChange}
                handleDeleteRow={handleDeleteRow}
                handlePolicyValueTextChange={handlePolicyValueTextChange}
                handlePolicyValueDropdownChange={
                  handlePolicyValueDropdownChange
                }
                handleAddCondition={handleAddCondition}
                handleDeleteCard={handleDeleteCard}
                handleCopyCard={handleCopyCard}
                handleSaveCard={handleSaveCard}
                handleConditionDrop={handleDropConditions}
                handleOpenSnackbar={handleOpenSnackbar}
                handleCloseSnackbar={handleCloseSnackbar}
                handleChangeTimeValue={handleChangeTimeValue}
                handleJoinCondition={handleJoinCondition}
              />
            </div>
          );
        })
      ) : (
        <Grid container justifyContent="center" alignItems="center" className="p-16">
          {addPolicyBlockEmptyMolecule}
        </Grid>
      )}
      {
        <Grid container sx={{ padding: theme.spacing(7) }} alignItems="center">
          {policyCardData?.length > 0 && addPolicyBlockMolecule}
          {showSelectPolicyBlock && (
            <Grid
              container
              sx={{ padding: theme.spacing(5) }}
              alignItems="center"
            >
              <PolicyBlockSelectDropdown
                menuItems={constructMenuItems()}
                itemForDropdownMenu={
                  <SelectPolicyBlock
                    selectedItemText={
                      policyMessages.POLICY_BLOCK.POLICY_BLOCK_CHOICE
                    }
                    requiredIcon={DownArrowIcon}
                  />
                }
              />
            </Grid>
          )}
        </Grid>
      }
    </>
  );
};

export default PolicyConditionCard;
