import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { EMPTY_FUNCTION, GLOBAL_LABELS } from '../../../core-utils/constants';
import {
  createOperationRequest,
  getNamesFromObject,
} from '../../../core-utils/Helper/helper';
import messages from '../../../core-utils/messages';
import CustomDialog from '../Dialog';
import MultipleSelectChip from '../MultipleSelectChip';
import UserGroupService from '../../../api-service/user-group-service/UserGroupService';
import {
  IUserGroup,
  OperationTypes,
} from '../../../core-utils/Interfaces/entityInterfaces';
import { useMultipleSelections } from '../MultipleSelectChip/index.hook';
import UserService from '../../../api-service/user-service/UserService';
import LoadingAnimation from '../../atoms/LoadingAnimation';
import AppService, {
  IAddOrRemoveAppsToUserRequestBody,
} from '../../../api-service/app-service/AppService';
import { getAppById } from '../../pages/AppDetailsPage/index.hook';

interface IAddGroupsToUserModalProps {
  userOrappId: any;
  open: boolean;
  userGroupsOfUserOrApp: any;
  updateUsers?: any;
  handleClose: () => void;
  updateUserGroups?: any;
  updatePolicies?: any;
  context?: 'user' | 'app';
}

const {
  HEADING,
  SUB_HEADING,
  PLACEHOLDER,
  LABEL,
  SUB_HEADING_APP,
} = messages.ADD_USER_GROUP;
const { SAVE, CANCEL } = GLOBAL_LABELS;

const formatUserGroupResponse = (
  groupResponse: IUserGroup,
  userGroupsOfUser: any,
) => {
  const allGroups = Object.keys(groupResponse);
  const userGroups = userGroupsOfUser.map((item: any) => item?.name);

  const groupsUserIsNotPartOf = allGroups.filter(
    (item) => userGroups.indexOf(item) === -1,
  );
  return groupsUserIsNotPartOf.map((key, index) => {
    return { id: index, name: key };
  });
};

const AddGroupsToUserOrAppModal = ({
  userOrappId,
  open,
  userGroupsOfUserOrApp,
  handleClose,
  updateUserGroups,
  context,
  updateUsers,
  updatePolicies,
}: IAddGroupsToUserModalProps) => {
  const [userGroupList, setUserGroupList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getGroups = async () => {
    const getUserGroups: any = await UserGroupService.listUserGroups();
    const formattedUserGroupResponse = formatUserGroupResponse(
      getUserGroups,
      userGroupsOfUserOrApp,
    );
    return formattedUserGroupResponse;
  };

  const {
    currentSelectedItems,
    handleChange,
    removeItem,
    sortBySelectedItems,
    resetSelectedItems,
  } = useMultipleSelections(userGroupList, [], EMPTY_FUNCTION);

  const handleClear = () => {
    handleClose();
    resetSelectedItems();
  };

  useEffect(() => {
    getGroups();
  }, []);

  const handleSaveFromContextUser = async () => {
    const groupNames = getNamesFromObject(currentSelectedItems);
    const requestBody = groupNames.map((name) =>
      createOperationRequest(OperationTypes.add, name),
    );
    try {
      await UserService.addGroupsToUser(userOrappId, requestBody);
    } catch (error) {
      console.warn(error);
    } finally {
      updateUserGroups && updateUserGroups();
      updatePolicies && updatePolicies();
      handleClear();
    }
  };

  const handleSaveFromContextApp = async () => {
    const groupNames = getNamesFromObject(currentSelectedItems);
    const requestBody: IAddOrRemoveAppsToUserRequestBody[] = [
      createOperationRequest(
        OperationTypes.add,
        'READ',
        `/connectionPermissions/${userOrappId}`,
      ),
    ];
    try {
      const promises = groupNames.map(async (groupId) => {
        await AppService.addUserGroupsToApp(groupId, requestBody);
      });
      await Promise.all(promises);
      getAppById(userOrappId).then((response) => {
        updateUserGroups(response.userGroupMembers, response.name);
        updateUsers(
          response.userMembers,
          response.name,
          response.userGroupMembers,
        );
      });
    } catch (error) {
      console.warn(error);
    } finally {
      handleClear();
    }
  };

  const handleSave = async () => {
    context === 'app'
      ? handleSaveFromContextApp()
      : handleSaveFromContextUser();
  };

  React.useEffect(() => {
    setLoading(true);
    getGroups()
      .then((response) => setUserGroupList(response))
      .finally(() => setLoading(false));
  }, [userGroupsOfUserOrApp]);

  return (
    <>
      {loading && <LoadingAnimation />}
      <CustomDialog
        heading={HEADING}
        subHeading={context === 'app' ? SUB_HEADING_APP : SUB_HEADING}
        buttonLabelOne={CANCEL}
        buttonLabelTwo={SAVE}
        onClose={handleClear}
        isSaveDisabled={currentSelectedItems.length === 0}
        handleSaveClick={handleSave}
        open={open}
      >
        <Grid item>
          <MultipleSelectChip
            textFieldLabel={LABEL}
            placeholder={PLACEHOLDER}
            dropDownList={userGroupList}
            checkedItems={currentSelectedItems}
            handleCheckedItems={handleChange}
            onChipDelete={removeItem}
            sortSelected={sortBySelectedItems}
            category={'usergroups'}
          />
        </Grid>
      </CustomDialog>
    </>
  );
};

export default AddGroupsToUserOrAppModal;
