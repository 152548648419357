import {
  Dialog as MuiDialog,
  DialogProps,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import yellowWarning from '../../../../public/assets/yellow-warning.svg';
import theme, { EXTRA_COLORS } from '../../../core-utils/theme';
import Button from '../../atoms/Button';
import IconFromSvg from '../../atoms/IconFromSvg';

interface PolicyBuilderConfirmationProps extends DialogProps {
  leaveButtonLabel: string;
  cancelButtonLabel: string;
  heading: string;
  onLeave: () => void;
  onCancel: () => void;
  subHeading: string;
}

const PolicyBuilderConfirmation = ({
  leaveButtonLabel,
  cancelButtonLabel,
  heading,
  subHeading,
  onCancel,
  onLeave,
  ...rest
}: PolicyBuilderConfirmationProps) => {
  return (
    <>
      <MuiDialog maxWidth="xl" onClose={onCancel} {...rest}>
        <Grid
          container
          sx={{
            padding: theme.spacing(4),
            width: theme.spacing(112),
            height: theme.spacing(70),
            top: theme.spacing(63),
            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.06)',
          }}
          direction="column"
          alignItems="center"
          justifyContent="space-evenly"
        >
          <Grid
            item
            container
            alignItems="center"
            justifyContent="space-evenly"
            direction="column"
          >
            <Grid item>
              <IconFromSvg path={yellowWarning} alt="Success" />
            </Grid>

            <Grid item>
              <Typography variant="h3">{heading}</Typography>
            </Grid>

            <Divider
              style={{
                width: theme.spacing(58),
                border: '0.5px solid ' + EXTRA_COLORS.blue_100,
              }}
              sx={{ px: 13, mt: 3 }}
            />
          </Grid>
          <Grid item container justifyContent="center">
            <Grid item>
              <Typography
                variant="body1"
                align="center"
                color={theme.palette.text.secondary}
                sx={{ px: theme.spacing(6) }}
              >
                {subHeading}
              </Typography>
            </Grid>
          </Grid>

          <Grid item>
            <Button
              sx={{
                width: theme.spacing(28),
                height: theme.spacing(10),
              }}
              color="primary"
              variant="text"
              onClick={onCancel}
            >
              {cancelButtonLabel}
            </Button>
            <Button
              sx={{
                width: theme.spacing(28),
                height: theme.spacing(10),
                ml: theme.spacing(1),
              }}
              color="primary"
              variant="contained"
              onClick={onLeave}
            >
              {leaveButtonLabel}
            </Button>
          </Grid>
        </Grid>
      </MuiDialog>
    </>
  );
};

export default PolicyBuilderConfirmation;
