import { Grid, Box, Typography } from '@mui/material';
import React from 'react';
import messages from '../../../core-utils/messages';
import theme from '../../../core-utils/theme';
import LoadingAnimation from '../../atoms/LoadingAnimation';
import AppsAccessedMostWidget from '../../molecules/AppsAccessedMostWidget';
import CurrentlyActiveUsers from '../../molecules/CurrentlyActiveUsers';
import DashboardOverview from '../../molecules/DashboardOverview';
import UsersViolationsWidget from '../../molecules/UsersViolationsWidget';
import { useTimeSelector } from '../Dashboard/index.hook';
import DashboardTimeSelection from '../DashboardTimeSelection';
import { useStatsData } from './index.hook';

export interface IUserGroupStatsTabItemProps {
  groupId: string;
}

const STATS_UPDATE_PERIOD = 4;

const UserGroupStatsTabItem = ({ groupId }: IUserGroupStatsTabItemProps) => {
  const {
    TIME_SELECTION_ITEMS,
    fromDate,
    toDate,
    prevFromDate,
    prevToDate,
    handleDateSelect,
    dateRange,
  } = useTimeSelector();
  const {
    usersViolations,
    appsAccessed,
    overviewData,
    activeUsers,
    loading,
  } = useStatsData(groupId, fromDate, toDate, prevFromDate, prevToDate);

  return (
    <Grid container sx={{ pb: 10 }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        sx={{ mb: 7 }}
      >
        <Box display="flex" flex-direction="row" alignItems="center">
          <Typography variant="h2" sx={{ mr: 1 }}>
            {messages.STATS}
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: theme.palette.text.disabled, mt: 1 }}
          >
            {messages.UPDATED +
              ' ' +
              STATS_UPDATE_PERIOD +
              ' ' +
              messages.MINS_AGO}
          </Typography>
        </Box>
        <Grid item>
          <DashboardTimeSelection
            timeSelectionItems={TIME_SELECTION_ITEMS}
            handleDateSelect={handleDateSelect}
          />
        </Grid>
      </Grid>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <Grid container direction="column" rowGap={5.5}>
          <Grid container>
            <DashboardOverview dashboardOverviewItems={overviewData} />
          </Grid>
          <Grid
            item
            container
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="column"
              width="38.97%"
              rowGap={5.5}
            >
              <CurrentlyActiveUsers currentlyActiveUsersItems={activeUsers} />

              <UsersViolationsWidget
                usersViolationsData={usersViolations}
                tableContext="dashboard"
              />
            </Box>
            <Grid item width="57.91%">
              <AppsAccessedMostWidget appsAccessedData={appsAccessed} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default UserGroupStatsTabItem;
