import AppService from '../../../api-service/app-service/AppService';
import { IUserGroupsAndAppsTableRow } from '../../../core-utils/constants';
import { usersForUserGroup } from '../../organisms/UserGroupsDashboard/index.hook';
import { removeAppFromUserOrUserGroup } from '../UserDetailsPage/index.hook';

export const constructUserGroupRowsForApps = async (
  groupResponse: string[],
  appName: string,
  popUpUserGroup: any,
  appId: string,
  getUserGroupsOfApp: any,
  getUsersOfApp: any,
) => {
  const userGroupRows: IUserGroupsAndAppsTableRow[] = [];
  const promises = groupResponse.map(async (usergroupId: string) => {
    const userlistLength = await usersForUserGroup(usergroupId);
    userGroupRows.push({
      name: usergroupId,
      userLength: userlistLength,
      underTabItems: true,
      showClose: true,
      tabItemName: appName,
      handleTableRowClick: () => {
        popUpUserGroup(usergroupId, userlistLength);
      },
      handleClickMore: () => {
        removeAppFromUserOrUserGroup(
          appId,
          usergroupId,
          'usergroup',
          getUserGroupsOfApp,
          getUsersOfApp,
        );
      },
      target: 'usergroup',
      context: 'app',
    });
  });
  await Promise.all(promises);
  return userGroupRows;
};

export const getAppById = async (appId: string) => {
  const appResponse = await AppService.getAppById(appId);
  const appDetailsPayload = {
    name: appResponse?.['attributes']?.['displayName'] || appResponse?.['name'],
    // Hardcoding these two axs api does'nt has these three fields as of now.
    // to be replaced with api fields once we have them.
    createdBy: appResponse?.['attributes']?.['createdBy'] ?? '',
    createdOn: appResponse?.['attributes']?.['createdOn'] ?? '',
    description: appResponse?.['attributes']?.['description'] ?? '',
    applicationType: appResponse?.['protocol'],
    appLogo: appResponse?.['attributes']?.['thumbnail'],
    idpAppUrl:
      appResponse?.attributes?.idpAppUrl?.length === 0
        ? null
        : appResponse?.attributes?.idpAppUrl,
    userGroupMembers: appResponse.userGroupMembers
      ? appResponse.userGroupMembers
      : [],
    userMembers: appResponse.userMembers ? appResponse.userMembers : [],
    source: appResponse?.['attributes']?.['sonet-source'] ?? '',
  };
  return appDetailsPayload;
};
