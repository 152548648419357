  import React, { useEffect, useState } from 'react';
import { Grid, Modal, Typography } from '@mui/material';
import messages from '@src/core-utils/messages';
import PasswordTextField from '@src/_components/molecules/PasswordTextField';
import TextField from '@src/_components/atoms/TextField';
import Button from '@src/_components/atoms/Button';
import {default as GenericErrorMsg} from '@src/core-utils/messages/error';
import {
  checkPassword, isEmptyValidation,
  isPasswordValid,
  isEmailValid,
} from '@src/core-utils/Helper/helper';
import UserService from '@src/api-service/user-service/UserService';
import TooltipForValidation from '@src/_components/molecules/TooltipForValidation';
import {
  USERROLE_DROPDOWN_OPTIONS,
  SECONDARY_ADMIN,
  ADMIN,
  ITooltipValidationParams,
  PASSWORD_PARAMETERS,
} from '@utils/constants';
import theme, { EXTRA_COLORS, modalStyle } from '@src/core-utils/theme';
import userSettings from '@src/core-utils/messages/userSettings';
import { useImmer } from "use-immer";
import LoadingAnimation from '@src/_components/atoms/LoadingAnimation';
import Dropdown from '@molecules/Dropdown';
import settingsMessages from '@utils/messages/settings';
import { RootStateOrAny } from 'react-redux';
import { useAppSelector } from '@src/Redux/Store/store';


interface IUpdateUserModalProps {
  showModal: boolean;
  username: string;
  users?: any;
  handleToggleModal: () => void;
  getUserDetails: (username: string, isUserNameUpdate: boolean) => void;
}



const UpdateUserModal = ({
  showModal,
  username,
  handleToggleModal,
  users,
  getUserDetails
}: IUpdateUserModalProps) => {

  const {
    UPDATE_HEADING,
    SUB_HEADING,
    TEXT_FIELD_LABELS: {
      FULL_NAME,
      USER_NAME,
      JOB_TITLE,
      PASSWORD,
      CONFIRM_PASSWORD,
      DESCRIPTION,
      USER_ROLE,
    },
    PLACEHOLDER_LABELS: {
      USER_NAME_LABEL,
      FULL_NAME_LABEL,
      JOB_TITLE_LABEL,
      PASSWORD_LABEL,
      CONFIRM_PASSWORD_LABEL,
      DESCRIPTION_LABEL,
    },
    BUTTON_LABELS,
    ERROR_MESSAGES: {
      INVALID_EMAIL,
      PASSWORD_NO_MATCH,
      INVALID_PASSWORD,
      USER_ALREADY_EXISTS,
      ADD_USER_FAILED,
      UPDATE_USER_FAILED
    },
    REQUIRED,
  } = messages.CREATE_USER;

  const {
    NEW_PASSWORD,
    PLACEHOLDER_NEW_PASSWORD
  } = userSettings.changePasswordModalMessages;

  const [isSaveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [totalUserList, setTotalUserList] = useState<any>([]);
  const [isSubmitting, setSubmitting] = useState(false);

  const [userDetails, setUserDetails] = useImmer({
    [FULL_NAME]: users?.name || '',
    [USER_NAME]: users?.email || '',
    [JOB_TITLE]: users?.jobTitle || '',
    [USER_ROLE]: users?.role || '',
    [PASSWORD]: '',
    [CONFIRM_PASSWORD]: '',
    [DESCRIPTION]: users?.description || '',
  });

  const [errorMessages, setErrorMessages] = useImmer({
    [FULL_NAME]: '',
    [USER_NAME]: '',
    [JOB_TITLE]: '',
    [PASSWORD]: '',
    [CONFIRM_PASSWORD]: '',
  });

  const [userErrorMessage, setUserErrorMessage] = useState('');
  const [addUserErrorMessage, setAddUserErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [passwordTooltipParams, setPasswordTooltipParams] = useState<
    ITooltipValidationParams[]
  >(PASSWORD_PARAMETERS);

  const role = useAppSelector(
    (state: RootStateOrAny) => state?.token?.role,
  );


  const isOkta = process.env.ONLY_OKTA && process.env.ONLY_OKTA === 'true';

  const renderError = (errorMessage: string) => errorMessage ? <Typography
    sx={{
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      overflowWrap: 'break-word',
      wordBreak: 'break-all'
    }}
    variant="body2" color="error"
  >
    {errorMessage}
  </Typography> : null;

  const handleTextFieldChange = (event: any) => {
    setUserDetails((d) =>{
      d[event.target.name] = event.target.value
    });
    if (event.target.name === PASSWORD) {
      setPasswordTooltipParams(
        checkPassword(event.target.value, passwordTooltipParams),
      );
    }
  };

  const validateUserPassword = () => {
    if (userDetails[PASSWORD] === '') {
      return false;
    }
    if (!isPasswordValid(userDetails?.[PASSWORD])) {
      setPasswordErrorMessage(INVALID_PASSWORD);
      return false;
    } else {
      setPasswordErrorMessage('');
      return true;
    }
  };

  const validateUserConfirmPassword = () => {
    if (userDetails[CONFIRM_PASSWORD] !== userDetails[PASSWORD]) {
      setErrorMessages(d=> {
        d[CONFIRM_PASSWORD] = PASSWORD_NO_MATCH
      });
      return false;
    } else {
      setErrorMessages(d => { d[CONFIRM_PASSWORD] = '' });
      return true;
    }
  };

  const validateUserEmail = () => {
    if (userDetails[USER_NAME] === '') {
      return false;
    }
    if (!isEmailValid(userDetails?.[USER_NAME])) {
      setUserErrorMessage(INVALID_EMAIL);
      return false;
    } else {
      setUserErrorMessage('');
      return true;
    }
  };

  const handleFormClear = () => {
    setUserDetails((d) => {
      d[FULL_NAME]= '',
      d[USER_NAME]= '',
      d[JOB_TITLE]= '',
      d[PASSWORD]='',
      d[CONFIRM_PASSWORD]= '',
      d[USER_ROLE]= ''
    });
    setErrorMessages((d) => {
      d[FULL_NAME]= '',
      d[USER_NAME]= '',
      d[JOB_TITLE]= '',
      d[PASSWORD]='',
      d[CONFIRM_PASSWORD] = ''
    });
    setUserErrorMessage('');
    setPasswordErrorMessage('');
    PASSWORD_PARAMETERS.forEach((password) => {
      password.satisfied = false;
    });
    setPasswordTooltipParams(PASSWORD_PARAMETERS);
  };

  const handleValidation = () => {
    const isValid = Object.keys(userDetails).every((key) => {
      if (key === FULL_NAME) {
        if (isEmptyValidation(userDetails[key])) {
          return false;
        }
      }

      if (users?.role === ADMIN && key === USER_NAME && !validateUserEmail()) {
        return false;
      }

      if ((key === PASSWORD && !isEmptyValidation(userDetails[key]))
        || (key === CONFIRM_PASSWORD && !isEmptyValidation(userDetails[key]))) {
        if (key === PASSWORD && (!validateUserPassword() || !validateUserConfirmPassword())) {
          return false;
        }
        if (key === CONFIRM_PASSWORD && !validateUserConfirmPassword()) {
          return false;
        }
      }
      return true;
    });
    setSaveDisabled(!isValid);
    return isValid;
  };

  /* Check User has updated Username or Password*/
  const checkUpdateUsernamePassword = () => {
    if(userDetails[PASSWORD]) {
      return true
    }
    if(users?.role === ADMIN && username !== userDetails[USER_NAME]) {
      return true
    }
    return false;
  }

  const checkUsernameUpdate = () => {
    if (users?.role === ADMIN) {
      if(userDetails[USER_NAME] 
        && username !== userDetails[USER_NAME]) {
          return true;
      }
    }
    return false;
  } 

  const handleSaveClick = async () => {
    if (handleValidation()) {
      setSubmitting(true);
      setSaveDisabled(true);
      const requestBody: any = {
        password: userDetails[PASSWORD] || undefined,
        attributes: {
          'guac-email-address': users?.role === ADMIN ? userDetails[USER_NAME] : undefined,
          'guac-organizational-role': userDetails[JOB_TITLE],
          'guac-full-name': userDetails[FULL_NAME],
          'jobRole': userDetails[USER_ROLE] || '',
          description: userDetails[DESCRIPTION],
        },
      };
      
      /* Update Username  */
      if (users?.role === ADMIN) {
        requestBody.newUserName = userDetails[USER_NAME];
      }
      
      try {
        let updatedUserName = username;
        await UserService.updateUser(updatedUserName, requestBody);
        if (users?.role === ADMIN) {
          updatedUserName = userDetails[USER_NAME];
        }

        if (checkUpdateUsernamePassword()) {
          await UserService.setUserFirstTimeLoginStatus(updatedUserName, true);
          await UserService.sendUserPasswordChangeMail(
            updatedUserName,
            updatedUserName,
            userDetails[FULL_NAME],
            window.location.origin,
            userDetails[PASSWORD] ?  userDetails[PASSWORD] : undefined,
          );
        }
        setSaveDisabled(false);
        setSubmitting(false);
        handleToggleModal()
        getUserDetails(updatedUserName, checkUsernameUpdate())
      } catch (error: any) {
        setSaveDisabled(false);
        setSubmitting(false);
        setAddUserErrorMessage(error?.response?.data?.message ?? UPDATE_USER_FAILED);
        const {data: {message}, status} = error?.response;
        if(status === 500) {
          setAddUserErrorMessage(message);
        } else {
          setAddUserErrorMessage(GenericErrorMsg?.GENERIC_ERROR?.HEADING)
        }
      }
    }
  };

  const shouldSaveBeDisabled = () => {
    if (handleValidation()) {
      setSaveDisabled(false);
    }
  };

  useEffect(() => {
    shouldSaveBeDisabled();
  }, [userDetails]);

  return (
    <>
    {isSubmitting && <LoadingAnimation /> }
    <Modal open={showModal} onClose={handleToggleModal}>
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        sx={modalStyle}
      >
        <Grid item container direction="column">
          <Grid item>
            <Typography variant="h3">{UPDATE_HEADING}</Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.text.disabled }}
            >
              {SUB_HEADING}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            textFieldSize="full"
            name={FULL_NAME}
            label={FULL_NAME}
            value={userDetails[FULL_NAME]}
            placeholder={FULL_NAME_LABEL}
            inputProps={{ 'data-testid': FULL_NAME }}
            required
            autoComplete="off"
            handleChange={handleTextFieldChange}
            onBlur={handleValidation}
          />
        </Grid>
        {users?.role === ADMIN && <Grid item>
          <TextField
            textFieldSize="full"
            name={USER_NAME}
            label={USER_NAME}
            value={userDetails[USER_NAME]}
            inputProps={{ 'data-testid': USER_NAME }}
            required
            placeholder={USER_NAME_LABEL}
            autoComplete="off"
            error={userErrorMessage !== ''}
            helperText={userErrorMessage}
            handleChange={handleTextFieldChange}
            onBlur={validateUserEmail}
          />
          </Grid>}
        <Grid item>
          <TextField
            textFieldSize="full"
            name={JOB_TITLE}
            label={JOB_TITLE}
            placeholder={JOB_TITLE_LABEL}
            value={userDetails[JOB_TITLE]}
            inputProps={{ 'data-testid': JOB_TITLE }}
            handleChange={handleTextFieldChange}
            onBlur={handleValidation}
          />
        </Grid>
        <Grid item>
          <TextField
            textFieldSize="full"
            inputProps={{ 'data-testid': DESCRIPTION }}
            onBlur={handleValidation}
            placeholder={DESCRIPTION_LABEL}
            label={DESCRIPTION}
            name={DESCRIPTION}
            value={userDetails[DESCRIPTION]}
            handleChange={handleTextFieldChange}
          />
          </Grid>
        <Grid item>
          <TooltipForValidation
            validationItems={passwordTooltipParams}
            heading={messages.PASSWORD_PARAMETERS_MESSAGE}
          >
            <PasswordTextField
              textFieldSize="full"
              name={PASSWORD}
              label={NEW_PASSWORD}
              value={userDetails[PASSWORD]}
              placeholder={PLACEHOLDER_NEW_PASSWORD}
              inputProps={{ 'data-testid': PASSWORD }}
              autoComplete={'new-password'}
              error={passwordErrorMessage !== ''}
              helperText={passwordErrorMessage}
              handleChange={handleTextFieldChange}
              onBlur={validateUserPassword}
              validPassword={isPasswordValid(userDetails[PASSWORD])}
            />
          </TooltipForValidation>
        </Grid>
        <Grid item>
          <PasswordTextField
            textFieldSize="full"
            name={CONFIRM_PASSWORD}
            label={CONFIRM_PASSWORD}
            value={userDetails[CONFIRM_PASSWORD]}
            inputProps={{ 'data-testid': CONFIRM_PASSWORD }}
            placeholder={CONFIRM_PASSWORD_LABEL}
            autoComplete="off"
            error={errorMessages[CONFIRM_PASSWORD] !== ''}
            helperText={errorMessages[CONFIRM_PASSWORD]}
            handleChange={handleTextFieldChange}
            onBlur={validateUserConfirmPassword}
          />
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography component="span" variant="body2">
              {REQUIRED}
            </Typography>
            <Typography component="span" variant="body2" color="error">
              *
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="text"
              color="primary"
              onClick={handleToggleModal}
              onClickCapture={handleFormClear}
              sx={{
                width: theme.spacing(28),
                height: theme.spacing(10),
                marginRight: 3,
                '&:hover': { backgroundColor: EXTRA_COLORS.lightBlue },
              }}
            >
              {BUTTON_LABELS.CANCEL}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isSaveDisabled}
              onClick={handleSaveClick}
              sx={{
                width: theme.spacing(28),
                height: theme.spacing(10),
                textAlign: 'center',
              }}
            >
              {BUTTON_LABELS.SAVE}
            </Button>
          </Grid>
          {renderError(addUserErrorMessage)}
        </Grid>
      </Grid>
    </Modal>
    </>
  );
};

export default UpdateUserModal;
